import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CRow, CCol, CCard, CCardHeader, CCardBody, CCardFooter, CInput, CLabel, CFormGroup, CSwitch, CTooltip,
} from '@coreui/react';
import Checks from 'src/views/checks/Checks';
import { checkActions, housingActions, positionActions, projectActions, employeeActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import Select from 'react-select';


class ChecksContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            curent_date: new Date(),
            project_options: [],
            project_default_option: {},
            project_id: '',
            projects: [],
            mine: '',
            filter_projects: [],
        }
        this.handleChangeCurentDate = this.handleChangeCurentDate.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getProjects().then((projects) => {
            var project_options = [];
            //console.log(projects);
            if (!helps.isEmpty(projects) && projects.length > 0) {
                projects.map((project) => {
                    if (project.active === 1) {
                        project_options.push({
                            value: project.id,
                            label: project.name,
                        });
                    }
                });
                this.setState({ project_options: project_options, projects: projects, filter_projects: projects });
            }
        });
        this.getEmployees();
        this.getPositions();
        this.getHousings();
        this.getChecks({ curent_date: this.state.curent_date });
    }

    async getChecks(params) {
        let _checks = await this.props.getChecks(params, 10000);
        return _checks;
    }

    async getProjects() {
        const { projects } = this.props;
        if (helps.isEmpty(projects)) {
            let _projects = await this.props.getProjects();
            return _projects;
        } else {
            return projects;
        }
    }

    async getEmployees() {
        const { employees } = this.props;
        if (helps.isEmpty(employees)) {
            let _employees = await this.props.getEmployees();
            return _employees;
        }
    }

    async getPositions() {
        let _positions = await this.props.getPositions();
        return _positions;
    }

    async getHousings() {
        const { housings } = this.props;
        if (helps.isEmpty(housings)) {
            let _housings = await this.props.getHousings();
            return _housings;
        }
    }

    handleChangeCurentDate(e) {
        let curent_date = new Date(e.target.value);
        this.setState({ curent_date: curent_date });
        this.getChecks({ curent_date: curent_date });
        //console.log(curent_date);
    }

    handleChangeSelect(selectedOptions) {
        if (selectedOptions) {
            let filter_projects = this.state.projects.filter(project => project.id === selectedOptions.value);
            this.setState({ 'project_id': selectedOptions.value, filter_projects: filter_projects });
        } else {
            this.setState({ project_id: '', filter_projects: this.state.projects });
        }
    }

    handleChange(e, field) {
        const { user } = this.props;
        let value = e.target.value;
        this.setState({ [field]: value });

        if (field === 'mine' && value && e.target.checked) {
            let filter_projects = this.state.projects.filter((project) => {
                if (!helps.isEmpty(project.assigns) && project.assigns.length > 0) {
                    let found = helps.getItemFromArr(project.assigns, user.employee_id, 'value');
                    if (helps.isEmpty(found)) return false;
                    else return true;
                } else {
                    return false;
                }
            });
            this.setState({ filter_projects: filter_projects });
        } else {
            this.setState({ filter_projects: this.state.projects });
        }
    }

    render() {
        let today = new Date().toISOString().slice(0, 10);
        var date_val = today;
        if (this.state.curent_date) {
            date_val = helps.formatDate_for_input(this.state.curent_date.getTime(), 'YYYY-MM-DD');
        }
        return (
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol md={3} sm={12} className='mb-2'>
                            Quản Lý Check in - Check out
                        </CCol>
                        <CCol md={9} sm={12} className='mb-2 text-right'>
                            <CRow>
                                <CCol md={4} sm={12} className='text-center'>
                                    <CTooltip content={'Mục Tiêu tôi phụ trách'}>
                                        <div>
                                            <CSwitch
                                                color='primary'
                                                size='lg'
                                                variant='3d'
                                                value={1}
                                                onClick={(e) => { this.handleChange(e, 'mine') }}
                                            />
                                        </div>
                                    </CTooltip>
                                </CCol>
                                <CCol md={4} sm={12} className='mb-2'>
                                    <Select
                                        options={this.state.project_options}
                                        className='text-left'
                                        placeholder='Mục Tiêu ...'
                                        isClearable
                                        defaultValue={this.state.project_options.find(({ value }) => value === this.state.project_id)}
                                        onChange={(choice) => { this.handleChangeSelect(choice) }}
                                    >
                                    </Select>
                                </CCol>
                                <CCol md={4} sm={12} className='mb-2'>
                                    <CLabel htmlFor="curent_date">Ngày: </CLabel>
                                    <CFormGroup variant="custom-radio" inline>
                                        <CInput
                                            type='date'
                                            id='curent_date'
                                            name='curent_date'
                                            value={date_val}
                                            onChange={e => this.handleChangeCurentDate(e)}
                                            className='font-weight-bold h-100'
                                            style={{ fontSize: '18px' }}
                                        />
                                    </CFormGroup>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <Checks
                        key={'list-' + this.state.filter_projects.length}
                        {... this.props}
                        user={this.props.user}
                        projects={this.state.filter_projects}
                        curent_date={this.state.curent_date}
                    />
                </CCardBody>
                <CCardFooter>

                </CCardFooter>
            </CCard>
        );
    }
}

function mapStateToProps(state) {
    const { loading, checks } = state.checkReducer;
    const { projects } = state.projectReducer;
    const { employees } = state.employeeReducer;
    const { positions } = state.positionReducer;
    const { user } = state.authentication;
    const { housings } = state.housingReducer;
    return { loading, user, checks, projects, employees, positions, housings };
}

const actionCreators = {
    getChecks: checkActions.getAll,
    getProjects: projectActions.getAll,
    getEmployees: employeeActions.getAll,
    getPositions: positionActions.getAll,
    getHousings: housingActions.getAll,
    add: checkActions.add,
    _delete: checkActions._delete,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ChecksContainer);
export { connectedContainer as ChecksContainer };