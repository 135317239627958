import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { employeeActions, maneuverActions, fileActions, projectActions } from 'src/_actions';
import ManeuversForm from 'src/views/maneuver/ManeuversForm';
import { helps } from 'src/_helpers';


class ManeuverFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        if (helps.isEmpty(this.props.employees)) this.props.getEmployees();
    }

    async getManeuvers() {
        await this.props.getManeuvers(this.props.project);
    }

    async doAddManeuver(maneuver) {
        const { projects } = this.props;
        var from_projects = [];
        projects.forEach((project) => {
            if (!helps.isEmpty(project.employees) && project.employees.length > 0) {
                let _project_employee = helps.getItemFromArr(project.employees, maneuver.employee_id, 'employee_id');
                //console.log(_project_employee)
                if (!helps.isEmpty(_project_employee)) {
                    //console.log(project);
                    from_projects.push(project);
                }
            }
        });
        //console.log(from_projects)
        if (!helps.isEmpty(from_projects) && from_projects.length > 0) {
            from_projects.map(async (project) => {
                var _project_employees = project.employees.filter(employee => employee.employee_id !== maneuver.employee_id);
                maneuver['from_project_id'] = project.id;
                project['employees'] = _project_employees;
                await this.props.updateProject(project);
            })
        }
        let _maneuver = await this.doAdd(maneuver);
        //console.log(_maneuver)
        this.getManeuvers();
        await this.updateProject(_maneuver);
    }

    async doAdd(maneuver) {
        return await this.props.addManeuver(maneuver);
    }

    async updateProject(_maneuver) {
        let maneuver = _maneuver;
        if (!helps.isEmpty(maneuver)) {
            let project = this.props.project;
            let project_employees = project.employees ? project.employees : [];

            project_employees = project_employees.filter(_employee => _employee.employee_id !== maneuver.employee_id);
            let project_employee = {
                employee_id: maneuver.employee_id,
                maneuver_id: maneuver.id
            };
            if (!helps.isEmpty(project_employees) && project_employees.length > 0) {
                project_employees.push(project_employee);
            } else {
                project_employees = new Array(project_employee)
            }

            project['employees'] = project_employees;
            //console.log(project_employees);
            await this.props.updateProject(project);
        }
    }

    async updateEmployee() {
        const employee_id = this.state.employee_id;
        const { employees } = this.props;
        let employee = helps.getItemFromArr(employees, employee_id);
        if (!helps.isEmpty(employee)) {
            employee['project_id'] = this.props.project.id;
            await this.props.updateEmployee(employee);
        }
    }

    render() {
        /*
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        */
        return (
            <ManeuversForm
                {... this.props}
                project={this.props.project}
                toggleForm={() => { this.props.toggleForm() }}
                show_maneuver_form={this.props.show_maneuver_form}
                doAddManeuver={(naneuver) => { this.doAddManeuver(naneuver) }}
                updateProject={this.updateProject}
                updateEmployee={this.updateEmployee}
                user={this.props.user}
            />
        );
    }
}

function mapStateToProps(state) {
    const { loading, maneuvers, maneuver } = state.maneuverReducer;
    const { employees } = state.employeeReducer;
    const { projects } = state.projectReducer;
    return { loading, maneuvers, maneuver, employees, projects };
}

const actionCreators = {
    getManeuvers: maneuverActions.getAll,
    getEmployees: employeeActions.getAll,
    addManeuver: maneuverActions.add,
    uploadFile: fileActions.uploadFile,
    updateProject: projectActions.update,
    updateEmployee: employeeActions.updateEmployee,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ManeuverFormContainer);
export { connectedContainer as ManeuverFormContainer };