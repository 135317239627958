import { vendorConstants } from 'src/_constants';
import { vendorService } from 'src/_services';
import { alertActions } from '.';

export const vendorActions = {
    getAll,
    add,
    getById,
    update,
    updateField,
    _delete,
};


function getAll() {
    return dispatch => {
        dispatch(request());
        return vendorService.getAll()
            .then(
                vendors => {
                    dispatch(success(vendors));
                    return vendors;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: vendorConstants.GETALL_REQUEST } }
    function success(vendors) { return { type: vendorConstants.GETALL_SUCCESS, vendors } }
    function failure(error) { return { type: vendorConstants.GETALL_FAILURE, error } }
}

function getById(id) {

    return dispatch => {
        dispatch(request());
        vendorService.getById(id)
            .then(
                vendor => dispatch(success(vendor)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: vendorConstants.GET_REQUEST } }
    function success(vendor) { return { type: vendorConstants.GET_SUCCESS, vendor } }
    function failure(error) { return { type: vendorConstants.GET_FAILURE, error } }
}

function add(vendor) {
    return dispatch => {
        dispatch(request(vendor));

        return vendorService.add(vendor)
            .then(
                vendor => {
                    dispatch(success(vendor));
                    dispatch(alertActions.success('Thêm mới Nhà Cung Cấp thành công!'));
                    return vendor;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(vendor) { return { type: vendorConstants.ADD_REQUEST, vendor } }
    function success(vendor) { return { type: vendorConstants.ADD_SUCCESS, vendor } }
    function failure(error) { return { type: vendorConstants.ADD_FAILURE, error } }
}

function update(vendor) {
    return dispatch => {
        dispatch(request(vendor));
        vendorService.update(vendor)
            .then(
                vendor => {
                    dispatch(success(vendor));
                    dispatch(alertActions.success('Cập nhật thông tin Nhà Cung Cấp thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(vendor) { return { type: vendorConstants.UPDATE_REQUEST, vendor } }
    function success(vendor) { return { type: vendorConstants.UPDATE_SUCCESS, vendor } }
    function failure(error) { return { type: vendorConstants.UPDATE_FAILURE, error } }
}

function updateField(vendor, field) {
    return dispatch => {
        dispatch(request(vendor, field));
        vendorService.updateField(vendor, field)
            .then(
                vendor => {
                    dispatch(success(vendor));
                    dispatch(alertActions.success('Cập nhật thông tin Nhà Cung Cấp thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(vendor, field) { return { type: vendorConstants.UPDATE_FIELD_REQUEST, vendor, field } }
    function success(vendor) { return { type: vendorConstants.UPDATE_FIELD_SUCCESS, vendor } }
    function failure(error) { return { type: vendorConstants.UPDATE_FIELD_FAILURE, error } }
}

function _delete(vendor) {
    return dispatch => {
        dispatch(request(vendor));
        return vendorService._delete(vendor)
            .then(
                vendor => {
                    dispatch(success(vendor));
                    dispatch(alertActions.success('Xoá Nhà Cung Cấp thành công!'));
                    return vendor;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(vendor) { return { type: vendorConstants.DELETE_REQUEST, vendor } }
    function success(vendor) { return { type: vendorConstants.DELETE_SUCCESS, vendor } }
    function failure(error) { return { type: vendorConstants.DELETE_FAILURE, error } }
}


