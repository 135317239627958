import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CRow,
    CCol,
    CButton,
    CContainer,
    CDropdown,
    CDropdownToggle,
    CWidgetDropdown,
    CDropdownMenu,
    CDropdownItem,
    CLink,
    CTooltip,
    CPopover,
    CCallout,
    CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import Loading from 'src/containers/_loading';

class Projects extends Component {

    constructor(props) {
        super(props);

        this.state = {
            province: '',
        }

        this.pageChange = this.pageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const queryPage = this.props.location.search.match(/page=([0-9]+)/, '');
        const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
        this.setState({ currentPage: currentPage });
    }

    pageChange = (newPage) => {
        var currentPage = this.state.currentPage;
        currentPage !== newPage && this.props.history.push(`/users?page=${newPage}`)
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    render() {
        const { users, provinces, notes, positions } = this.props;
        const projects = this.props.filteredProjects();
        //console.log(projects)
        if (projects && projects.length > 0) {
            var _projects = [];
            projects.forEach((project, index) => {
                if (project.create_date) {
                    project['_create_date'] = helps.formatDate_from_Timestamp(project.create_date);
                }
                if (!project.tags || helps.isEmpty(project.tags)) {
                    project['tags'] = '';
                }

                project['create_by_alias'] = helps.getUserAlias(users, project.create_by);
                var province = helps.getItemFromArr(provinces, project.province_id);
                if (province) {
                    project['province_name'] = province.name;
                } else {
                    project['province_name'] = '';
                }
                _projects[index] = project;
            });
        }

        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        return (
            <CRow>
                {
                    (projects &&
                        projects.map((project) => {
                            let color = "primary";
                            let project_positions = !helps.isEmpty(positions) ? positions.filter(position => position.project_id === project.id) : [];
                            if (project.color) {
                                color = project.color;
                            }
                            var _notes = [];
                            if (!helps.isEmpty(notes) && notes.length > 0) {
                                _notes = notes.filter(note => note.object_id === project.id);
                            }
                            var note_content = '';
                            if (!helps.isEmpty(_notes) && _notes.length > 0) {
                                note_content = _notes.map((note) => {
                                    return (
                                        <div key={'note-' + note.id} className='border-bottom mb-2 pb-2'>
                                            {note.content}
                                            <div>
                                                <small><i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                            </div>
                                        </div>
                                    );
                                });
                            }
                            return (
                                <CCol sm={6} md={3} lg={3} key={"project_key_" + project.id}>
                                    <CWidgetDropdown
                                        color={"gradient-" + color}
                                        header={
                                            <CPopover
                                                header={<b>Thông Tin</b>}
                                                content={
                                                    <div>
                                                        <div>
                                                            <b>Vị trí:</b>
                                                            {!helps.isEmpty(project_positions) && project_positions.map((position) => {
                                                                return (
                                                                    <CRow key={project.id + '-position-' + position.id} className="mb-2">
                                                                        <CCol md={12} sm={12}>
                                                                            {!helps.isEmpty(position['shifts']) && position.shifts.map((shift, index) => {
                                                                                let _color = 'warning';
                                                                                if (shift.id === 'night') _color = 'dark';
                                                                                if (shift.id === '24') _color = 'primary';
                                                                                return (
                                                                                    <div key={'shift-' + shift.id + '-' + index} className='border mb-1 p-1 text-muted'>
                                                                                        <CRow>
                                                                                            <CCol>
                                                                                                <b>{position.name}</b> <i><CBadge color={_color} className='mr-1 pb-1'>ca: {shift.label}</CBadge></i>
                                                                                            </CCol>
                                                                                        </CRow>
                                                                                        <CRow>
                                                                                            <CCol>
                                                                                                <i>Thời gian: </i> <b>{shift.start} - {shift.end}</b> <i> (<b className='text-dark'>{shift.duration}</b>/24)</i>
                                                                                            </CCol>
                                                                                        </CRow>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </CCol>
                                                                    </CRow>
                                                                );
                                                            })}
                                                        </div>
                                                        <hr />
                                                        <div>
                                                            <b>Phụ trách:</b>
                                                            <ol>
                                                                {
                                                                    !helps.isEmpty(project.assigns) && project.assigns.length > 0 && project.assigns.map((assign) => {
                                                                        return (
                                                                            <li key={'assign-' + project.id + '-' + assign.value}>{assign.label}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ol>
                                                        </div>
                                                    </div>
                                                }>
                                                <CLink className='text-white' onClick={() => { this.props.history.push(`/projects/view/${project.id}`) }}>{project.name}</CLink>
                                            </CPopover>
                                        }
                                        text={
                                            <div className='mt-2'>
                                                <b className='ml-2'>{project.province_name}</b>
                                                <div className='mt-1 ml-1 font-italic'><CIcon name="cil-location-pin"></CIcon> {project.address}</div>
                                            </div>
                                        }
                                        footerSlot={
                                            <CContainer className="mt-1 mb-3">
                                                <ThePermission {... this.props} component="projects" action="view">
                                                    <CTooltip content="Quản Lý Mục Tiêu">
                                                        <CButton
                                                            size="sm"
                                                            className="btn btn-light btn-sm mr-2 mb-2"
                                                            onClick={() => { this.props.history.push(`/projects/view/${project.id}`) }}
                                                        >
                                                            <CIcon name="cil-monitor" className="mfe-1 mt-0"></CIcon>
                                                            Quản Lý
                                                        </CButton>
                                                    </CTooltip>
                                                </ThePermission>
                                                <ThePermission {... this.props} component="projects" action="view">
                                                    {!helps.isEmpty(note_content) && <>
                                                        <CPopover content={note_content}>
                                                            <CButton
                                                                size="sm"
                                                                className="btn btn-light btn-sm mr-2 mb-2"
                                                            >
                                                                <CIcon name="cil-list" className="mfe-1 mt-0"></CIcon>
                                                                Ghi Chú
                                                            </CButton>
                                                        </CPopover>
                                                    </>}
                                                    {helps.isEmpty(note_content) && <>
                                                        <CButton
                                                            size="sm"
                                                            className="btn btn-light btn-sm mr-2 mb-2"
                                                        >
                                                            <CIcon name="cil-list" className="mfe-1 mt-0"></CIcon>
                                                            Ghi Chú
                                                        </CButton>
                                                    </>}
                                                </ThePermission>
                                                <ThePermission {... this.props} component="projects" action="edit">
                                                    <CTooltip content="Chỉnh Sửa Thông Tin Mục Tiêu">
                                                        <CButton
                                                            size="sm"
                                                            className="btn btn-light btn-sm mr-2 mb-2"
                                                            onClick={() => { this.props.history.push(`/projects/add_edit/${project.id}`) }}
                                                        >
                                                            <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                            Sửa
                                                        </CButton>
                                                    </CTooltip>
                                                </ThePermission>
                                            </CContainer>
                                        }
                                    >
                                        <CDropdown>
                                            <CDropdownToggle color="transparent">
                                                <CIcon name="cil-settings" />
                                            </CDropdownToggle>
                                            <CDropdownMenu className="pt-0" placement="bottom-end">
                                                <CDropdownItem to={"/projects/view/" + project.id}><CIcon name="cil-monitor" className="mfe-1 mt-0 mr-2"></CIcon> Quản Lý</CDropdownItem>
                                                <CDropdownItem to={"/projects/add_edit/" + project.id}><CIcon name="cil-list" className="mfe-1 mt-0  mr-2"></CIcon>Ghi Chú</CDropdownItem>
                                                <CDropdownItem to={"/projects/add_edit/" + project.id}><CIcon name="cil-pen-nib" className="mfe-1 mt-0  mr-2"></CIcon> Sửa</CDropdownItem>
                                            </CDropdownMenu>
                                        </CDropdown>
                                    </CWidgetDropdown>
                                </CCol>
                            )
                        })
                    )
                }
            </CRow>

        )
    }
}


export default Projects;
