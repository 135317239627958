import React, { Component } from "react";
import { read, utils, writeFile } from 'xlsx';
import {
    CCard, CCardBody, CCardFooter, CCardHeader,
    CButton
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { helps } from "src/_helpers";

export default class CustomersImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
            cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */,
            upload_files: '',
        };
        this.handleFile = this.handleFile.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImport = this.handleImport.bind(this);
    }
    componentDidMount() {
        const { tags } = this.props;
        if (!helps.isEmpty(tags) && tags.length > 0) {
            tags.map((tag) => {
                if (typeof tag.name === 'object') {
                    console.log(tag.name);
                    this.props.deleteTag(tag);
                }
            });
        }
        //this.deleteLeads();
    }

    deleteLeads() {
        const { customers } = this.props;
        if (!helps.isEmpty(customers) && customers.length > 0) {
            customers.map((customer) => {
                if (customer.type === 'lead') {
                    this.props._delete(customer);
                }
            });
        }
    }

    make_cols = refstr => {
        let o = [], C = utils.decode_range(refstr).e.c + 1;
        for (var i = 0; i < C; ++i) o[i] = { name: utils.encode_col(i), key: i }
        return o;
    };

    handleFile() {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            console.log(rABS, wb);
            /* Convert array of arrays */
            const data = utils.sheet_to_json(ws, { header: 1 });
            /* Update state */
            let cols = this.make_cols(ws['!ref']);
            this.setState({ data: data, cols: cols });
            //console.log(cols)
        };
        if (rABS) reader.readAsBinaryString(this.state.upload_files);
        else reader.readAsArrayBuffer(this.state.upload_files);


    }
    exportFile() {
        /* convert state to workbook */
        const ws = utils.aoa_to_sheet(this.state.data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        writeFile(wb, "sheetjs.xlsx");
    }
    handleChange(e) {
        let upload_files = e.target.files[0];
        //console.log(upload_files);
        this.setState({ upload_files: upload_files });
        //console.log(upload_files);
    }

    handleImport() {
        const { user, tags, customers } = this.props;
        if (!helps.isEmpty(tags) && tags.length > 0) {
            tags.map((tag) => {
                if (typeof tag.name === 'string') {
                    console.log(tag.name);
                }
            });
        }
        let data = this.state.data;
        if (!helps.isEmpty(data) && data.length > 0) {
            data.map(async (cus) => {
                var customer = {};
                let tags_str = cus[4];
                let notes = cus[5];
                customer['name'] = cus[0] ? cus[0] : '';
                customer['legal_representative'] = cus[1] ? cus[1] : '';
                customer['email'] = cus[2] ? cus[2] : '';
                customer['phone'] = cus[3] ? cus[3] : '';
                customer['website'] = '';
                customer['address'] = '';
                customer['tax_number'] = '';
                customer['province_id'] = '';
                customer['create_by'] = user.id;
                customer['create_date'] = new Date();
                customer['type'] = 'lead';
                let found_cus = helps.getItemFromArr(customers, customer.name, 'name');
                if (helps.isEmpty(found_cus)) {
                    customer = await this.props.add(customer);
                }
                if (!helps.isEmpty(tags_str)) {
                    let import_tags = tags_str.split(",");
                    var _tags = [];
                    if (!helps.isEmpty(import_tags) && import_tags.length > 0) {
                        import_tags.map(async (tag, tag_i) => {
                            var _tag = {
                                name: import_tags[tag_i],
                                color: 'primary',
                                object: 'customers',
                            }
                            let found_tag = helps.getItemFromArr(tags, tag, 'name');
                            if (helps.isEmpty(found_tag)) {
                                _tag = await this.props.addTag(_tag);
                                _tags.push({
                                    value: _tag.id,
                                    label: _tag.name,
                                });
                            } else {
                                _tags.push({
                                    value: found_tag.id,
                                    label: found_tag.name,
                                });
                            }
                        })
                        if (_tags.length > 0) {
                            customer['tags'] = _tags;
                            await this.props.update(customer);
                        }
                    }
                }
                if (!helps.isEmpty(notes)) {
                    let note = {
                        content: notes,
                        note_by: user.id,
                        note_time: new Date(),
                        object: 'customers',
                        object_id: customer.id,
                    }
                    await this.props.addNote(note);
                }
                console.log(customer);
            })
        }
    }
    render() {
        return (
            <CCard>
                <CCardHeader>
                    Import Cơ Hội
                </CCardHeader>
                <CCardBody>
                    <div className="row">
                        <div className="col-xs-12">
                            <label htmlFor="upload">Upload File</label>
                            <input
                                type="file"
                                name="upload"
                                id="upload"
                                onChange={(e) => { this.handleChange(e) }}
                            />
                            <CButton type="button" color="primary" onClick={() => { this.handleFile() }}>
                                <CIcon name="cil-cloud-upload" className="mfe-1 mt-0"></CIcon>
                                Tải Lên
                            </CButton>
                            <CButton type="button" className='ml-2' color="primary" onClick={() => { this.handleImport() }}>
                                <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                Import
                            </CButton>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="table-responsive mt-3">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            {this.state.cols && this.state.cols.map(c => (
                                                <th key={c.key}>{c.name}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data && this.state.data.map((r, i) => (
                                            <tr key={i}>
                                                {this.state.cols && this.state.cols.map(c => (
                                                    <td key={c.key}>{r[c.key]}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </CCardBody>
                <CCardFooter>

                </CCardFooter>
            </CCard>
        );
    }
}