import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CDataTable,
    CButton,
    CSelect,
    CLink,
    CBadge,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import configs from 'src/appConfigs';
import Loading from 'src/containers/_loading';
import Select from 'react-select';

class Customers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            province: '',
            select_tags: '',
        }

        this.pageChange = this.pageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        const queryPage = this.props.location.search.match(/page=([0-9]+)/, '');
        const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
        this.setState({ currentPage: currentPage });
    }

    pageChange = (newPage) => {
        var currentPage = this.state.currentPage;
        currentPage !== newPage && this.props.history.push(`/users?page=${newPage}`)
    }

    handleChange(field, e) {
        this.setState({
            [field]: e.target.value
        });
    }

    handleSelect(selectOPtions, field) {
        this.setState({
            [field]: selectOPtions,
        });
    }

    render() {
        const { customers, users, provinces, projects, tags, notes } = this.props;

        var _customers = customers;

        if (!helps.isEmpty(this.state.province)) {
            _customers = _customers.filter((item) => {
                return item.province_id == this.state.province;
            });
        }
        let select_tags = this.state.select_tags;
        if (!helps.isEmpty(select_tags)) {
            _customers = _customers.filter((item) => {
                if (!helps.isEmpty(item.tags) && item.tags.length > 0) {
                    let found = helps.getItemFromArr(item.tags, select_tags['value'], 'value');
                    if (!helps.isEmpty(found)) return true;
                    else return false;
                } else {
                    return false;
                }
            });
        }

        var tag_options = [];
        if (!helps.isEmpty(tags)) {
            tags.map((tag) => {
                tag_options.push({
                    value: tag.id,
                    label: tag.name,
                });
            });
        }

        const fields = [
            { key: 'index', label: '#', _style: { width: '10px' }, filter: false, sorter: false },
            { key: 'name', label: 'Tên Khách Hàng', _classes: 'font-weight-bold', _style: { width: '15%' } },
            { key: 'projects', label: 'Mục Tiêu', _style: { width: '15%' } },
            { key: 'tax_number', label: 'MST', _style: { width: '10%' } },
            { key: 'legal_representative', label: 'Đại Diện', _style: { width: '10%' } },
            { key: 'tags', label: 'Nhãn', _style: { width: '20%' } },
            { key: 'phone', label: 'Điện Thoại' },
            { key: 'province_id', label: 'Tỉnh Thành', _style: { width: '10%' } },
            { key: 'address', label: 'Địa Chỉ', _style: { width: '15%' } },
            { key: 'create_by', label: 'Tạo Bởi', _style: { align: 'center' }, filter: false },
            { key: 'actions', label: 'Thao Tác', _style: { width: '15%' }, filter: false, sorter: false },
        ]
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        //console.log(_customers)
        return (
            <CDataTable
                key={'customers_' + _customers.length}
                items={_customers}
                fields={fields}
                columnFilter
                tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                itemsPerPageSelect={configs.itemsPerPageSelect}
                itemsPerPage={configs.per_page}
                hover
                sorter
                pagination
                striped
                columnFilterSlot={{
                    province_id: (
                        <>
                            <CSelect
                                name="province"
                                id="input-province"
                                className="form-control form-control-sm"
                                value={this.state.province}
                                onChange={(e) => { this.handleChange('province', e) }}
                            >
                                <option value="">-Tất cả-</option>
                                {provinces && provinces.map((province) => {
                                    return (
                                        <option value={province.id} key={province.id}>{province.name}</option>
                                    )
                                }
                                )}
                            </CSelect>
                        </>
                    ),
                    tags: (
                        <Select
                            name="select-tag"
                            placeholder='Nhãn ...'
                            isClearable
                            options={tag_options}
                            defaultValue={this.state.select_tags}
                            onChange={(selectOptions) => { this.handleSelect(selectOptions, 'select_tags') }}
                        >

                        </Select>
                    ),
                }}
                scopedSlots={{
                    'index': (item, index) => {
                        return (
                            <td className="py-2">
                                {index + 1}
                            </td>
                        )
                    },
                    'name': (item) => {
                        var _notes = [];
                        if (!helps.isEmpty(notes) && notes.length > 0) {
                            _notes = notes.filter(note => note.object_id === item.id);
                        }
                        var note_content = '';
                        if (!helps.isEmpty(_notes) && _notes.length > 0) {
                            note_content = _notes.map((note) => {
                                return (
                                    <div className='border-bottom mb-2 pb-2' key={note.id}>
                                        {note.content} <small>_<i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                    </div>
                                );
                            });
                        }
                        return (
                            <td className="py-2">
                                {!helps.isEmpty(note_content) &&
                                    <CPopover
                                        header='Ghi chú:'
                                        content={note_content}
                                    >
                                        <CLink className="text-dark" onClick={() => { this.props.history.push(`/customers/view/${item.id}`) }}>
                                            <b>{item.name}</b>
                                        </CLink>
                                    </CPopover>
                                }
                                {helps.isEmpty(note_content) &&
                                    <CLink className="text-dark" onClick={() => { this.props.history.push(`/customers/view/${item.id}`) }}>
                                        <b>{item.name}</b>
                                    </CLink>
                                }
                            </td>
                        )
                    },
                    'projects': (item) => {
                        let _projects = projects.filter(project => project.customer_id === item.id);

                        return (
                            <td className="py-2">
                                {!helps.isEmpty(_projects) && _projects.length > 0 && _projects.map((project) => {
                                    return (
                                        <div className='mb-2' key={project.id}>
                                            {'-'}
                                            <CPopover content={<div>{project.name}</div>}>
                                                <CLink className="text-primary" onClick={() => { this.props.history.push(`/projects/view/${project.id}`) }}>
                                                    {project.name}
                                                </CLink>
                                            </CPopover>
                                        </div>
                                    )
                                })}
                            </td>
                        )
                    },
                    'province_id': (item) => {
                        let province = helps.getItemFromArr(provinces, item.province_id);
                        return (
                            <td className="py-2">
                                {!helps.isEmpty(province) && <>{province.name}</>}
                            </td>
                        )
                    },
                    'tags': (item) => {
                        //console.log(item)
                        return (
                            <td className="py-2">
                                {item.tags && item.tags.length > 0 && item.tags.map((tag) => {
                                    let _tag = helps.getItemFromArr(tags, tag.value);
                                    //console.log(_tag)
                                    return (
                                        <CLink
                                            key={item.id + '_' + _tag.id}
                                            onClick={(e) => { e.preventDefault(); this.handleSelect({ value: _tag.id, label: _tag.name }, 'select_tags') }}
                                            title={'Lọc theo Nhãn: ' + _tag.name}
                                        >
                                            <CBadge color={_tag.color} className="p-1 m-1">
                                                {_tag.name}
                                            </CBadge>
                                        </CLink>
                                    )
                                })}
                            </td>
                        )
                    },
                    'create_by': (item) => {
                        return (
                            <td className="py-2">
                                <small>
                                    {item.create_by && helps.getUserAlias(users, item.create_by)}
                                    <div>{item.create_date && helps.formatDate_from_Timestamp(item.create_date)}</div>
                                </small>
                            </td>
                        )
                    },
                    'actions':
                        (item) => {
                            var _notes = [];
                            if (!helps.isEmpty(notes) && notes.length > 0) {
                                _notes = notes.filter(note => note.object_id === item.id);
                            }
                            var note_content = '';
                            if (!helps.isEmpty(_notes) && _notes.length > 0) {
                                note_content = _notes.map((note) => {
                                    return (
                                        <div className='border-bottom mb-2 pb-2' key={note.id}>
                                            {note.content} <small>_<i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                        </div>
                                    );
                                });
                            }
                            return (
                                <td className="py-2">
                                    <ThePermission {... this.props} component="customers" action="view">
                                        <CPopover
                                            header="Ghi chú"
                                            content={note_content}
                                        >
                                            <CButton
                                                color="info"
                                                size="sm mr-1"
                                            >
                                                <CIcon name="cil-list"></CIcon>
                                            </CButton>
                                        </CPopover>
                                    </ThePermission>
                                    <ThePermission {... this.props} component="customers" action="edit">
                                        <CButton
                                            color="primary"
                                            size="sm"
                                            title="Cập nhật thông tin"
                                            onClick={() => { this.props.history.push(`/customers/add_edit/${item.id}`) }}
                                        >
                                            <CIcon name="cil-pen-nib"></CIcon>
                                        </CButton>
                                    </ThePermission>
                                </td>
                            )
                        },
                }}
            />

        )
    }
}


export default Customers;
