import { db } from '../dbConfigs';

export const customerService = {
    getAll,
    add,
    getById,
    update,
    updateField,
    _delete,
};

async function getAll(type = 'customer') {
    var ref = db.collection('customers');
    var query = {};
    if (type === 'all') {
        query = ref.orderBy('create_date', 'desc');
    } else {
        query = ref.where('type', '==', type)
            .orderBy('create_date', 'desc');
    }
    const snapshot = await query.get();
    const customers = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));

    if (customers && customers.length > 0) {
        Promise.all(
            customers.map(async (customer, index) => {
                if (customer.create_date) {
                    var _date = customer['create_date'];
                    customer['create_date'] = _date.toDate();
                }
            })
        );
    }

    return customers;
}

async function getById(id) {
    try {
        const doc = await db.collection("customers").doc(id)
            .get();
        if (doc.exists) {
            return doc.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        console.log("Error getting document:", error);
    }
}

async function add(customer) {
    try {
        const docRef = await db.collection("customers").add(customer);
        customer.id = docRef.id;
        update(customer);
        console.log("Firebase: Customers has been added successfully!");
        return customer;
    } catch (error) {
        console.error("Error adding Customer: ", error);
    }
}

async function update(customer) {
    //console.log(customer);
    let dbRef = db.collection("customers").doc(customer['id']);

    try {
        //console.log(customer);
        await dbRef.update(customer).then(() => {
            console.log("Firebase: customer has been updated successfully!");
        });

        return customer;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function updateField(customer, field) {
    //console.log(customer);
    let dbRef = db.collection("customers").doc(customer['id']);

    try {
        console.log(field, customer[field]);
        await dbRef.update({
            [field]: customer[field]
        }).then(() => {
            console.log("Firebase: customer field has been updated successfully!");
        });

        return customer;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function _delete(customer) {
    try {
        await db.collection("customers").doc(customer.id).delete().then((customer) => {
            return customer;
        });
    } catch (error) {
        console.error("Error: ", error);
    }
}
