/** 
 * Quản lý thu chi
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
    CTooltip,
    CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane,
    CLabel, CFormGroup,
    CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { ThePermission } from '../ThePermission';
import { bookActions, customerActions, employeeActions, housingActions, projectActions, taxonomyActions, userActions, vendorActions } from 'src/_actions';
import Books from 'src/views/accounting/Books';
import { BookOutFormContainer } from './BookOutFormContainer';
import { BookInFormContainer } from './BookInFormContainer';
import { helps } from 'src/_helpers';
import DatePicker from 'react-datepicker';
import locale from 'date-fns/locale/vi';
import BooksOutApproval from 'src/views/accounting/BooksOutApproval';
import { bookService } from 'src/_services';

class BooksContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_header: 'Quản Lý Thu/Chi',
            search: '',
            params: {},
            modals: [],
            activeTab: 'out',
            book: {},
            month: new Date(),
            curent_date: new Date(),
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeMonth = this.handleChangeMonth.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getLastMonthBook = this.getLastMonthBook.bind(this);
    }

    componentDidMount() {
        if (!this.props.users) {
            this.props.getUsers();
        }
        this.getTaxonomies();
        this.getEmployees();
        this.getCustomers();
        this.getHousings();
        this.getVendors();
        this.getProjects();

        this.getBooks({ curent_date: this.state.month });

        let hash = window.location.hash.substring(1);
        //console.log(hash);
        if (!helps.isEmpty(hash)) this.setState({ activeTab: hash });
    }

    async getBooks(params = {}) {
        return await this.props.getAll(params, 10000);
    }

    async getTaxonomies() {
        return await this.props.getTaxonomies();
    }

    async getEmployees() {
        if (helps.isEmpty(this.props.employees)) return await this.props.getEmployees();
    }

    async getCustomers() {
        if (helps.isEmpty(this.props.customers)) return await this.props.getCustomers('customer');
    }

    async getHousings() {
        if (helps.isEmpty(this.props.housings)) return await this.props.getHousings();
    }

    async getVendors() {
        if (helps.isEmpty(this.props.vendors)) return await this.props.getVendors();
    }

    async getProjects() {
        if (helps.isEmpty(this.props.projects)) return await this.props.getProjects();
    }

    async getLastMonthBook(_month) {
        let month = _month ? _month : this.state.month;
        let last_month = new Date(month);
        last_month.setMonth(last_month.getMonth() - 1);
        let books = await bookService.getAll({ curent_date: last_month }, 10000);
        books.sort(function (a, b) {
            let b_count = b.count ? b.count : 0;
            let a_count = a.count ? a.count : 0;
            return b_count - a_count;
        });
        return books[0];
    }

    handleChange(field, e) {
        this.setState({
            [field]: e.target.value
        });
    }

    handleChangeSelect(selectedOptions) {
        if (selectedOptions) {
            this.setState({ 'province_id': selectedOptions.value });
        } else {
            this.setState({ province_id: '' });
        }
    }

    handleChangeMonth(value) {
        this.setState({ month: value });
        this.getBooks({ curent_date: value });
    }

    toggleModal = (item, book = {}) => {
        let modals = this.state.modals;
        const position = modals.indexOf(item);
        let newItems = modals.slice();
        if (position !== -1) {
            newItems.splice(position, 1)
        } else {
            newItems = [...modals, item]
        }
        //console.log(book)
        this.setState({ modals: newItems, book: book });

        console.log(modals);
    }

    render() {
        const { books } = this.props;
        var _books_approval = [];
        if (!helps.isEmpty(books) && books.length > 0) {
            _books_approval = books.filter(book => book.approval_required && !book.approved);
        }
        //console.log(_books_approval)
        let bagcolor = (!helps.isEmpty(_books_approval) && _books_approval.length > 0) ? 'danger' : 'secondary';
        return (
            <ThePermission {... this.props} component="accounting" action="view">
                <CRow>
                    <CCol xl={12}>
                        <CCard>
                            <CCardHeader>
                                <CRow>
                                    <CCol md={4} sm={12}>
                                        <h1 className='mb-1'>{this.state.page_header}</h1>
                                    </CCol>
                                    <CCol md={4} sm={12}>
                                        <CFormGroup variant='custom-radio' inline>
                                            <CLabel htmlFor="curent_date" className='mt-1'>tháng: </CLabel>
                                            <DatePicker
                                                locale={locale}
                                                selected={this.state.month}
                                                onChange={(date) => { this.handleChangeMonth(date) }}
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                                className='ml-2 form-control font-weight-bold'
                                            />
                                        </CFormGroup>
                                    </CCol>
                                    <CCol md={4} sm={12} className='text-right'>
                                        <CTooltip content={'Lập Phiếu Chi'}>
                                            <CButton type="button" color="primary" size="sm" onClick={() => { this.toggleModal('out_form') }}>
                                                <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                                Phiếu Chi
                                            </CButton>
                                        </CTooltip>
                                        <CTooltip content={'Lập Phiếu Thu'}>
                                            <CButton type="button" color="warning" className={'ml-2'} size="sm" onClick={() => { this.toggleModal('in_form') }}>
                                                <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                                Phiếu Thu
                                            </CButton>
                                        </CTooltip>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCardBody>
                                <CTabs activeTab={this.state.activeTab}>
                                    <CNav variant="tabs">
                                        <CNavItem>
                                            <CNavLink data-tab="out">
                                                <h6 className='font-weight-bold'>
                                                    Sổ Thu/Chi
                                                </h6>
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink data-tab="approval">
                                                <h6 className='font-weight-bold'>
                                                    Duyệt Chi
                                                    <small className='ml-1'>
                                                        <CBadge color={bagcolor}>{_books_approval.length}</CBadge>
                                                    </small>
                                                </h6>
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink data-tab="report">
                                                <h6 className='font-weight-bold'>
                                                    Báo Cáo
                                                </h6>
                                            </CNavLink>
                                        </CNavItem>
                                    </CNav>
                                    <CTabContent className="py-3">
                                        <CTabPane data-tab="out">
                                            <Books {... this.props} type='out' modals={this.state.modals} month={this.state.month} getLastMonthBook={this.getLastMonthBook} handleSubmit={this.handleSubmit} toggleModal={this.toggleModal} />
                                        </CTabPane>
                                        <CTabPane data-tab="approval">
                                            <BooksOutApproval {... this.props} type='out' modals={this.state.modals} month={this.state.month} getLastMonthBook={this.getLastMonthBook} handleSubmit={this.handleSubmit} toggleModal={this.toggleModal} />
                                        </CTabPane>
                                        <CTabPane data-tab="report">
                                        </CTabPane>
                                    </CTabContent>
                                </CTabs>
                                {this.state.modals.includes('out_form') && <BookOutFormContainer {...this.props} modals={this.state.modals} handleSubmit={this.handleSubmit} toggleModal={this.toggleModal} book={this.state.book} />}
                                {this.state.modals.includes('in_form') && <BookInFormContainer {...this.props} modals={this.state.modals} handleSubmit={this.handleSubmit} toggleModal={this.toggleModal} book={this.state.book} />}
                            </CCardBody >
                        </CCard >
                    </CCol >
                </CRow >
            </ThePermission >
        );
    }
}

function mapStateToProps(state) {
    const { loading } = state.projectReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    const { taxonomies } = state.taxonomyReducer;
    const { employees } = state.employeeReducer;
    const { customers } = state.customerReducer;
    const { housings } = state.housingReducer;
    const { vendors } = state.vendorReducer;
    const { projects } = state.projectReducer;
    const { books } = state.bookReducer;
    const { approvals } = state.generalReducer;
    return { loading, books, user, users, taxonomies, employees, customers, vendors, housings, projects, approvals };
}

const actionCreators = {
    getAll: bookActions.getAll,
    updateField: bookActions.updateField,
    getCountBooks: bookActions.getCountBooks,
    _delete: bookActions._delete,
    getUsers: userActions.getAll,
    getTaxonomies: taxonomyActions.getAll,
    getEmployees: employeeActions.getAll,
    getCustomers: customerActions.getAll,
    getHousings: housingActions.getAll,
    getVendors: vendorActions.getAll,
    getProjects: projectActions.getAll,
    updateTaxonomyField: taxonomyActions.updateField,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(BooksContainer);
export { connectedContainer as BooksContainer };