import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CCallout,
    CCollapse,
    CCard,
    CCardHeader,
    CCardBody,
    CCardFooter,
    CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';


class Notes extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    componentDidMount() {
    }

    render() {
        const { latest_notes, user } = this.props;
        if (latest_notes && latest_notes.length > 0) {
            latest_notes.map((note) => {
                note['_note_time'] = helps.formatDateTime_from_Timestamp(note.note_time);
                note['is_author'] = false;
                if (user.id == note.note_by) {
                    note['is_author'] = true;
                }
            })
        }
        return (
            <CCard>
                <CCardHeader>
                    Ghi Chú Mới
                </CCardHeader>
                <CCardBody>
                    <div className='notes-container'>
                        {!helps.isEmpty(latest_notes) && latest_notes.length > 0 && latest_notes.map((note) => {
                            let _note_time = helps.formatDateTime_from_Timestamp(note.note_time);
                            return (
                                <CCallout key={note.id} color="warning" className={'bg-white'}>
                                    <CRow>
                                        <CCol md={8}>
                                            <CLink onClick={() => { this.props.history.push(note.note_object_link) }}>
                                                <div className='text-wrap text-dark'>{note.content}</div>
                                            </CLink>
                                            <div><small className="text-muted font-italic">{_note_time} | {note.note_by_alias}</small></div>

                                        </CCol>
                                        <CCol md={4}>
                                            <div>{note.note_object_alias}</div>
                                            <div><small className="text-muted font-italic">{note.note_object_name}</small></div>
                                        </CCol>
                                    </CRow>
                                    <hr />
                                </CCallout>
                            );
                        })}
                    </div>
                </CCardBody>
            </CCard>
        )
    }
}


export default Notes;
