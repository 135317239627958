import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { helps } from 'src/_helpers';
import { customerActions } from 'src/_actions';
import { provinceActions } from 'src/_actions';
import CustomerForm from 'src/views/customers/CustomerForm';


class CustomersFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: {},
            form_header: "Thêm Mới Khách Hàng",
            action_status: 'add',
        }
    }

    componentDidMount() {
        this.getCustomers().then(() => {
            this.getCustomer();
        });
        if (!this.props.provinces) {
            this.props.getProvinces();
        }
    }

    getCustomer() {
        let customer_id = this.props.match.params.id;
        const customers = this.props.customers;
        if (customer_id) {
            let customer = customers.filter((_customer) => {
                return _customer.id === customer_id;
            });
            this.setState({ form_header: "Chỉnh Sửa Thông Tin Khách Hàng: " + customer[0].name, action_status: 'edit', customer: customer[0] });
        }
    }

    async getCustomers() {
        const customers = this.props.customers;
        if (!customers) await this.props.getAll();
    }

    handleSubmit = (customer) => {
        let customer_id = this.props.match.params.id;
        var url = '';
        if (helps.isEmpty(customer_id)) {
            this.props.add(customer);
        } else {
            this.props.update(customer);
        }
        switch (customer.type) {
            case 'lead':
                url = '/customers/leads';
                break;
            case 'potential':
                url = '/customers/potentials';
                break;
            default:
                url = '/customers';
        }
        this.props.history.push(url);
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        let customer_id = this.props.match.params.id;
        const { customer } = this.state;
        if (!helps.isEmpty(customer_id) && helps.isEmpty(customer)) return (<Loading></Loading>);

        return (
            <ThePermission {... this.props} component="customers" action="edit">
                <CustomerForm
                    {... this.props}
                    customer={this.state.customer}
                    form_header={this.state.form_header}
                    action_status={this.state.action_status}
                    handleSubmit={this.handleSubmit}
                />
            </ThePermission>
        );
    }
}
function mapStateToProps(state) {
    const { loading, customers } = state.customerReducer;
    const { provinces } = state.provinceReducer;
    const { user } = state.authentication;
    return { loading, customers, provinces, user };
}

const actionCreators = {
    getAll: customerActions.getAll,
    add: customerActions.add,
    update: customerActions.update,
    getProvinces: provinceActions.getAll,
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(CustomersFormContainer);
export { connectedFormContainer as CustomersFormContainer };