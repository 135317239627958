import { assetConstants } from 'src/_constants';
import { assetService } from 'src/_services';
import { alertActions } from 'src/_actions';

export const assetActions = {
    getAll,
    add,
    getById,
    update,
    updateField,
    _delete,
    uploadFile,
};


function getAll(params = {}) {
    return dispatch => {
        dispatch(request(params));
        return assetService.getAll(params)
            .then(
                assets => {
                    dispatch(success(assets, params));
                    return assets;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: assetConstants.GETALL_REQUEST, params } }
    function success(assets, params) { return { type: assetConstants.GETALL_SUCCESS, assets, params } }
    function failure(error) { return { type: assetConstants.GETALL_FAILURE, error } }
}

function getById(id) {

    return dispatch => {
        dispatch(request());
        assetService.getById(id)
            .then(
                asset => dispatch(success(asset)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: assetConstants.GET_REQUEST } }
    function success(asset) { return { type: assetConstants.GET_SUCCESS, asset } }
    function failure(error) { return { type: assetConstants.GET_FAILURE, error } }
}

function add(asset) {
    return dispatch => {
        dispatch(request(asset));

        return assetService.add(asset)
            .then(
                asset => {
                    dispatch(success(asset));
                    dispatch(alertActions.success('Thêm mới Tài Sản thành công!'));
                    return asset;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(asset) { return { type: assetConstants.ADD_REQUEST, asset } }
    function success(asset) { return { type: assetConstants.ADD_SUCCESS, asset } }
    function failure(error) { return { type: assetConstants.ADD_FAILURE, error } }
}

function update(asset) {
    return dispatch => {
        dispatch(request(asset));
        assetService.update(asset)
            .then(
                asset => {
                    dispatch(success(asset));
                    dispatch(alertActions.success('Cập nhật thông tin Tài Sản thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(asset) { return { type: assetConstants.UPDATE_REQUEST, asset } }
    function success(asset) { return { type: assetConstants.UPDATE_SUCCESS, asset } }
    function failure(error) { return { type: assetConstants.UPDATE_FAILURE, error } }
}

function updateField(asset, field) {
    return dispatch => {
        dispatch(request(asset, field));
        assetService.updateField(asset, field)
            .then(
                asset => {
                    dispatch(success(asset));
                    dispatch(alertActions.success('Cập nhật thông tin Tài Sản thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(asset, field) { return { type: assetConstants.UPDATE_FIELD_REQUEST, asset, field } }
    function success(asset) { return { type: assetConstants.UPDATE_FIELD_SUCCESS, asset } }
    function failure(error) { return { type: assetConstants.UPDATE_FIELD_FAILURE, error } }
}

function _delete(asset) {
    return dispatch => {
        dispatch(request(asset));
        return assetService._delete(asset)
            .then(
                asset => {
                    dispatch(success(asset));
                    dispatch(alertActions.success('Xoá Tài Sản thành công!'));
                    return asset;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(asset) { return { type: assetConstants.DELETE_REQUEST, asset } }
    function success(asset) { return { type: assetConstants.DELETE_SUCCESS, asset } }
    function failure(error) { return { type: assetConstants.DELETE_FAILURE, error } }
}

function uploadFile(asset, upload_files, object, object_id) {
    //console.log(asset, upload_files, object, object_id);

    return dispatch => {
        dispatch(request(asset, upload_files, object, object_id));
        console.log(asset, upload_files, object, object_id);
        return assetService.uploadFile(asset, upload_files, object, object_id)
            .then(
                asset => {
                    dispatch(success(asset));
                    dispatch(alertActions.success('Tải Biên Bản thành công!'));
                    return asset;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(asset, upload_files, object, object_id) { return { type: assetConstants.UPLOAD_FILE_REQUEST, asset, upload_files, object, object_id } }
    function success(asset) { return { type: assetConstants.UPLOAD_FILE_SUCCESS, asset } }
    function failure(error) { return { type: assetConstants.UPLOAD_FILE_FAILURE, error } }
}


