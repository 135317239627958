import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CModal, CModalBody, CModalHeader, CModalFooter, CContainer, CInput, CTextarea,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import Select from 'react-select';


class ManeuversForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            id: '',
            project_id: this.props.project.id,
            from_project_id: '',
            employee_id: '',
            maneuver_time: new Date(),
            maneuver_by: this.props.user.id,
            attachment: '',
            attachment_url: '',
            note: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
    }

    componentDidMount() {
        const { maneuver } = this.props;
        //console.log(maneuver);
        if (!helps.isEmpty(maneuver)) {
            /*
            this.setState({
                id: maneuver.id,
                note: maneuver.note,
                project_id: maneuver.project_id,
                from_project_id: maneuver.from_project_id,
                employee_id: maneuver.employee_id,
                maneuver_time: maneuver.maneuver_time,
                maneuver_by: maneuver.maneuver_by,
                attachment_url: maneuver.attachment_url,
            });
            */
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let maneuver = {
                id: this.state.id,
                project_id: this.state.project_id,
                from_project_id: this.state.from_project_id,
                employee_id: this.state.employee_id,
                maneuver_time: new Date(),
                maneuver_by: this.state.maneuver_by,
                note: this.state.note,
                attachment: this.state.attachment,
            }
            this.props.doAddManeuver(maneuver);
            this.props.toggleForm();
        } else {
            return false;
        }
    }

    handleChange(e, field) {
        if (e.target.type == 'file') {
            this.setState({
                attachment: e.target.files
            });
        } else {
            this.setState({
                [field]: e.target.value
            });
        }
    }

    handleChangeSelect(selectedOptions) {
        if (selectedOptions) {
            this.setState({ 'employee_id': selectedOptions.value });
        } else {
            this.setState({ employee_id: '' });
        }

    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.employee_id)) {
            formIsValid = false;
            errors["content"] = "Vui lòng chọn Nhân Viên để điều động.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        const { employees, project, projects } = this.props;
        if (helps.isEmpty(this.props.employees)) {
            return (
                <Loading></Loading>
            );
        }

        let show_maneuver_form = this.props.show_maneuver_form ? this.props.show_maneuver_form : false;
        var employee_options = [];

        if (!helps.isEmpty(employees) && employees.length > 0) {
            employees.map(employee => {
                let found = helps.getItemFromArr(project.employees, employee.employee_id, 'employee_id');
                if (helps.isEmpty(found)) {
                    let _option = {};
                    _option['value'] = employee.employee_id;
                    _option['label'] = employee.fullname + ' (' + employee.employee_id + ')';
                    var employee_project = {};
                    let _projects = projects.filter((project) => {
                        if (project.employees) {
                            let find_employee = helps.getItemFromArr(project.employees, employee.employee_id, 'employee_id');
                            if (!helps.isEmpty(find_employee)) {
                                employee_project = project;
                            }
                        }
                    })
                    if (!helps.isEmpty(employee_project)) {
                        _option['label'] = _option['label'] + ' - Đang trực tại: ' + employee_project.name;
                    }
                    if (employee.job_type === 'DNV') {
                        _option['label'] = _option['label'] + ' - (đã thôi việc)';
                    }
                    employee_options.push(_option);
                }
            });
        }
        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CModal
                            size='lg'
                            show={show_maneuver_form}
                            onClose={() => { this.props.toggleForm() }}
                        >
                            <CModalHeader closeButton><h3>Điều Động Nhân Sự</h3></CModalHeader>
                            <CModalBody className='text-left'>
                                <CContainer>
                                    <CRow className='mb-4'>
                                        <CCol md={12}>
                                            <Select
                                                id="employee_id"
                                                name="employee_id"
                                                placeholder="Chọn Nhân Viên để Điều Động ..."
                                                isClearable
                                                options={employee_options}
                                                defaultValue={employee_options.find(({ value }) => value === this.state.employee_id)}
                                                onChange={(choice) => { this.handleChangeSelect(choice) }}
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-4'>
                                        <CCol md={12}>
                                            <h4><small className='text-muted'><i>Đến Mục Tiêu:</i></small> <b>{this.props.project.name}</b></h4>
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-4'>
                                        <CCol md={12}>
                                            <CLabel htmlFor="input-note" className='font-weight-bold'>Ghi Chú:</CLabel>
                                            <CTextarea
                                                name='note'
                                                id='input-note'
                                                onChange={(e) => { this.handleChange(e, 'note') }}
                                            >
                                            </CTextarea>
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-4'>
                                        <CCol md={12}>
                                            <CLabel htmlFor="attachment" className='font-weight-bold'>Đính kèm Lệnh Điều Động:</CLabel>
                                            <CInput
                                                type="file"
                                                name='attachment'
                                                id='attachment'
                                                onChange={(e) => { this.handleChange(e, 'attachment') }}
                                            />
                                        </CCol>
                                    </CRow>
                                </CContainer>
                            </CModalBody>
                            <CModalFooter>
                                <CButton type="submit" size='sm' color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Điều Động
                                </CButton>
                                <CButton type="button" size='sm' color="secondary" onClick={(e) => { this.props.toggleForm() }} className="ml-3">
                                    <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                    Huỷ
                                </CButton>
                            </CModalFooter>
                        </CModal>
                    </CForm>
                </CCol>
            </CRow>
        )
    }
}
export default ManeuversForm;

