import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CInput,
    CSelect,
    CCard,
    CCardHeader,
    CCardBody,
    CCardFooter,
    CInputCheckbox,
    CFormGroup,
    CContainer,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import TimePicker from 'react-time-picker';

class PositionsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            form_header: 'Thêm Vị Trí',
            project_id: '',
            name: '',
            description: '',
            shifts: [],
            area_id: '',
            added_time: new Date(),
            added_by: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChangeShifts = this.handleChangeShifts.bind(this);
        this.handleChangeShiftAttrs = this.handleChangeShiftAttrs.bind(this);
    }

    componentDidMount() {
        const project = this.props.project;
        const added_by = this.props.user.id;

        if (added_by && project.id) {
            this.setState({
                errors: {},
                project_id: project.id,
                added_time: new Date(),
                added_by: added_by,
            });
        }
        const { position } = this.props;
        //console.log(position);
        if (!helps.isEmpty(position)) {
            this.setState({
                form_header: 'Chỉnh Sửa Vị Trí',
                id: position.id,
                name: position.name,
                description: position.description,
                project_id: position.project_id,
                area_id: position.area_id,
                shifts: position.shifts ? position.shifts : this.state.shifts,
                added_time: position.added_time,
                added_by: position.added_by,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            var shifts = this.state.shifts;
            shifts = shifts.filter(shift => typeof shift === 'object');

            if (!helps.isEmpty(shifts) && shifts.length > 0) {
                shifts.map((shift) => {
                    var config_shift = helps.getItemFromArr(configs.shifts, shift.id);
                    if (helps.isEmpty(shift.start)) {
                        shift['start'] = config_shift.start;
                    }
                    if (helps.isEmpty(shift.end)) {
                        shift['end'] = config_shift.end;
                    }
                    /*
                    if (helps.isEmpty(shift.number_employees)) {
                        shift['number_employees'] = config_shift.number_employees;
                    }*/
                    let duration = parseFloat(shift.duration);
                    if (duration === 0) {
                        if (shift.id === 'day') {
                            shift['duration'] = helps.calShiftDuration(shift.start, shift.end);
                        } else {
                            shift['duration'] = helps.calShiftDuration(shift.start, '24:00') + helps.calShiftDuration('0:00', shift.end);
                        }
                    }
                });
            }

            let position = {
                id: this.state.id,
                name: this.state.name,
                description: this.state.description,
                project_id: this.state.project_id,
                area_id: this.state.area_id,
                shifts: shifts,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
            }
            //console.log(position)
            this.props.handleSubmit(position);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeShifts(e) {
        var shifts = this.state.shifts;
        if (e.target.checked) {
            let id = e.target.value;
            var config_shift = helps.getItemFromArr(configs.shifts, id);
            let shift = {
                id: id,
                label: config_shift.label,
                start: config_shift.start,
                end: config_shift.end,
                duration: config_shift.duration,
                number_employees: config_shift.number_employees
            }
            shifts.push(shift);
        } else {
            shifts = shifts.filter(shift => shift.id !== e.target.value);
        }
        this.setState({ shifts: shifts });

    }

    handleChangeShiftAttrs(value, id, field) {
        var shifts = this.state.shifts;
        if (!helps.isEmpty(shifts) && shifts.length > 0) {
            shifts.map((shift) => {
                if (shift.id === id) {
                    if (field === 'number_employees' || field === 'duration') {
                        shift[[field]] = parseInt(value.target.value);
                        if (field === 'duration') {
                            shift[[field]] = parseFloat(value.target.value).toFixed(1);
                        }
                    } else {
                        shift[[field]] = value;
                        if (field === 'start' || field === 'end') {
                            if (shift.id === 'day') {
                                shift['duration'] = helps.calShiftDuration(shift.start, shift.end);
                            } else {
                                shift['duration'] = helps.calShiftDuration(shift.start, '24:00') + helps.calShiftDuration('0:00', shift.end);
                            }
                        }
                    }
                    console.log(shift, id, field, 'duration: ' + shift['duration'])
                }
            });
        }
        this.setState({ shifts: shifts });
        console.log(this.state.shifts)
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập tên Vị trí";
        }

        if (helps.isEmpty(this.state.shifts)) {
            formIsValid = false;
            errors["shifts"] = "Vui lòng chọn Ca làm việc";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCancel(e) {
        e.preventDefault();
        if (helps.isEmpty(this.state.id)) {
            this.props.handleCancelForm();
        } else {
            this.props.toggleEditForms();
        }

    }

    render() {
        const { project } = this.props;
        let areas = !helps.isEmpty(project.areas) ? project.areas : [];
        const shifts = this.state.shifts;
        var shift_ids = [];
        shifts.map(shift => {
            shift_ids.push(shift.id);
        });
        //console.log(Object.keys(configs.shifts));
        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader className={'bg-secondary text-dark'}>
                                {this.state.form_header}:
                            </CCardHeader>
                            <CCardBody>
                                <CRow className="mb-3">
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-name">Tên Vị Trí:</CLabel>
                                        <CInput
                                            type='text'
                                            name="name"
                                            id="input-name"
                                            value={this.state.name}
                                            onChange={(e) => { this.handleChange('name', e) }}
                                        />
                                        <small className="help-block text-danger">{this.state.errors['name']}</small>
                                    </CCol>
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-area_id">Khu Vực:</CLabel>
                                        <CSelect
                                            name="area_id"
                                            id="input-area_id"
                                            value={this.state.area_id}
                                            onChange={(e) => { this.handleChange('area_id', e) }}
                                        >
                                            <option value={''}> - Chọn Khu vực - </option>
                                            {!helps.isEmpty(areas) && areas.map(area => {
                                                return (
                                                    <option key={area.id} value={area.id}>{area.name}</option>
                                                );
                                            })}
                                        </CSelect>
                                        <small className="help-block text-danger">{this.state.errors['area_id']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-description">Mô Tả:</CLabel>
                                        <CInput
                                            type='text'
                                            name="description"
                                            id="input-description"
                                            defaultValue={this.state.description}
                                            onChange={(e) => { this.handleChange('description', e) }}
                                        />
                                        <small className="help-block text-danger">{this.state.errors['description']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12} sm={12}>
                                        <CLabel htmlFor="input-shifts">Ca Làm Việc:</CLabel>
                                        <CFormGroup id="input-shifts" className='ml-3'>
                                            {configs.shifts && configs.shifts.map(_shift => {
                                                var shift = shifts.filter(state_shift => state_shift.id == _shift.id);
                                                if (helps.isEmpty(shift)) {
                                                    shift = _shift;
                                                } else {
                                                    shift = shift[0];
                                                    if (!shift.start) shift['start'] = _shift.start;
                                                    if (!shift.end) shift['end'] = _shift.end;
                                                    if (!shift.duration) shift['duration'] = _shift.duration;
                                                    if (!shift.number_employees) shift['number_employees'] = _shift.number_employees;
                                                }
                                                return (
                                                    <CRow className='border mb-2' key={'shift-' + _shift.id}>
                                                        <CCol>
                                                            <CContainer className='mt-2 mb-2'>
                                                                <CRow>
                                                                    <CCol md={2} className="align-middle pt-4">
                                                                        <CInputCheckbox
                                                                            name="shifts"
                                                                            id={'shift_' + shift.id}
                                                                            value={shift.id}
                                                                            className='cursor-pointer'
                                                                            checked={shift_ids.includes(shift.id)}
                                                                            onChange={(e) => { this.handleChangeShifts(e) }}
                                                                        />
                                                                        <CLabel htmlFor={"shift_" + shift.id} className='cursor-pointer'>{shift.label}</CLabel>
                                                                    </CCol>
                                                                    <CCol>
                                                                        <CLabel htmlFor={"shift_start_" + shift.id} className='cursor-pointer font-weight-normal'>Bắt đầu Ca lúc: </CLabel>
                                                                        <div>
                                                                            <TimePicker
                                                                                id={'shift_start_' + shift.id}
                                                                                format="H:mm"
                                                                                value={shift.start}
                                                                                required
                                                                                disabled={!shift_ids.includes(shift.id)}
                                                                                onChange={(value) => { this.handleChangeShiftAttrs(value, shift.id, 'start') }}
                                                                            />
                                                                        </div>
                                                                    </CCol>
                                                                    <CCol>
                                                                        <CLabel htmlFor={"shift_end_" + shift.id} className='cursor-pointer font-weight-normal'>Kết thúc Ca lúc: </CLabel>
                                                                        <div>
                                                                            <TimePicker
                                                                                id={'shift_end_' + shift.id}
                                                                                format="HH:mm"
                                                                                value={shift.end}
                                                                                required
                                                                                disabled={!shift_ids.includes(shift.id)}
                                                                                onChange={(value) => { this.handleChangeShiftAttrs(value, shift.id, 'end') }}
                                                                            />
                                                                        </div>
                                                                    </CCol>
                                                                    <CCol>
                                                                        <CLabel htmlFor={"shift_duration_" + shift.id} className='cursor-pointer font-weight-normal'>Tổng thời gian trực: </CLabel>
                                                                        <CInputGroup>
                                                                            <CInput
                                                                                type="number"
                                                                                name="duration"
                                                                                id={"shift_duration_" + shift.id}
                                                                                value={shift.duration}
                                                                                step={'any'}
                                                                                required
                                                                                className='font-weight-bold'
                                                                                onChange={(e) => { this.handleChangeShiftAttrs(e, shift.id, 'duration') }}
                                                                            />
                                                                            <CInputGroupAppend>
                                                                                <CInputGroupText className={'bg-secondary'}>
                                                                                    /24
                                                                                </CInputGroupText>
                                                                            </CInputGroupAppend>
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>
                                                                        <CLabel htmlFor="input-number_employees" className='font-weight-normal'>Số lượng Nhân viên:</CLabel>
                                                                        <CSelect
                                                                            id="input-number_employees"
                                                                            name='number_employees'
                                                                            required
                                                                            disabled={!shift_ids.includes(shift.id)}
                                                                            value={shift.number_employees}
                                                                            onChange={(e) => { this.handleChangeShiftAttrs(e, shift.id, 'number_employees') }}
                                                                        >
                                                                            {Array.from({ length: 100 }, (_, i) => i + 1).map((x) => {
                                                                                return (<option value={x} key={x}>{x}</option>)
                                                                            })}
                                                                        </CSelect>

                                                                    </CCol>
                                                                </CRow>
                                                                <small className="help-block text-danger">{this.state.errors['shifts']}</small>
                                                            </CContainer>
                                                        </CCol>
                                                    </CRow>
                                                )
                                            })}
                                        </CFormGroup>
                                        <small className="help-block text-danger">{this.state.errors['shifts']}</small>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" size='sm' color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                                <CButton type="button" size='sm' color="secondary" onClick={(e) => { this.handleCancel(e) }} className="ml-3">
                                    <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                    Huỷ
                                </CButton>
                            </CCardFooter>
                        </CCard>


                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default PositionsForm;

