import { db } from 'src/dbConfigs';
import { helps } from 'src/_helpers';

export const itemService = {
    getAll,
    add,
    getById,
    update,
    updateField,
    _delete,
};

async function getAll() {
    var ref = db.collection('items');
    var query = ref.orderBy('create_date', 'desc');
    const snapshot = await query.get();
    const items = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));

    if (items && items.length > 0) {
        Promise.all(
            items.map(async (item, index) => {
                if (item.create_date) {
                    var _date = item['create_date'];
                    item['create_date'] = _date.toDate();
                }
                /*
                if (!helps.isEmpty(item.inventory)) {
                    item['inventory'] = helps.unigByKeepLast(item.inventory, it => it.store_id);
                    console.log(item.inventory);
                    updateField(item, 'inventory');
                }
                */
                item['index'] = index + 1;
            })
        );
    }

    return items;
}

async function getById(id) {
    try {
        const doc = await db.collection("items").doc(id)
            .get();
        if (doc.exists) {
            return doc.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        console.log("Error getting document:", error);
    }
}

async function add(item) {
    try {
        const docRef = await db.collection("items").add(item);
        item.id = docRef.id;
        update(item);
        console.log("Firebase: item has been added successfully!");
        return item;
    } catch (error) {
        console.error("Error adding item: ", error);
    }
}

async function update(item) {
    //console.log(item);
    let dbRef = db.collection("items").doc(item['id']);

    try {
        //console.log(item);
        await dbRef.update(item).then(() => {
            console.log("Firebase: item has been updated successfully!");
        });

        return item;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function updateField(item, field) {
    //console.log(item);
    let dbRef = db.collection("items").doc(item['id']);

    try {
        console.log(field, item[field]);
        await dbRef.update({
            [field]: item[field]
        }).then(() => {
            console.log("Firebase: item field has been updated successfully!");
        });

        return item;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function _delete(item) {
    try {
        await db.collection("items").doc(item.id).delete().then((item) => {
            return item;
        });
    } catch (error) {
        console.error("Error: ", error);
    }
}
