import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { tagActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import { CButton, CCol, CContainer, CRow, CTooltip } from '@coreui/react';
import TagsComponent from 'src/views/tags/TagsComponent';

class TagsComponentContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: {},
            tag_id: 0,
            object_value: {},
        }

    }

    componentDidMount() {
        this.doGetTags(this.props.object);
    }

    async doGetTags(_object) {
        const tags = await this.props.getAllTags(_object);
        this.setState({ tags: tags });
        return tags;
    }

    handleSubmit(tag) {
        let tags = this.props.tags;
        let _tag = tag;

        if (!helps.isEmpty(tag.id)) {
            _tag = this.props.updateTag(tag);
            tags.push(_tag);
        } else {
            _tag = this.props.addTag(tag);
        }
        this.setState({ tag: _tag, tags: tags });
    }

    handleEdit(tag) {
        this.setState({ tag: tag, tag_id: tag.id });
    }

    handleDelete(tag) {
        this.props.deleteTag(tag);
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        return (
            <CRow>
                <CCol md={12}>
                    <ThePermission {... this.props} component={this.props.object} action="view">
                        <TagsComponent
                            {... this.props}
                            handleEdit={(tag) => { this.handleEdit(tag) }}
                            handleDelete={(tag) => { this.handleDelete(tag) }}
                            onSubmit={this.props.onSubmit}
                        />
                    </ThePermission>
                </CCol>
            </CRow>
        );
    }
}

function mapStateToProps(state) {
    const { loading, tags } = state.tagReducer;
    return { loading, tags };
}

const actionCreators = {
    getAllTags: tagActions.getAll,
    deleteTag: tagActions._delete,
    addTag: tagActions.add,
    updateTag: tagActions.update,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(TagsComponentContainer);
export { connectedContainer as TagsComponentContainer };