import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCol,
    CRow,
    CButton,
    CForm,
    CCard,
    CCardHeader,
    CCardBody,
    CInputCheckbox,
    CCardFooter,
    CFormGroup,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import { contactService } from 'src/_services/contact.service';
import { contactActions } from 'src/_actions';

class ImportContactsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            cus_contacts: [],
            import_contacts: [],
            form_header: 'Thêm Liên Hệ từ Khách Hàng',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        this.getContacts();
    }

    async getContacts() {
        const customer_id = this.props.customer_id;
        return await contactService.getAll(customer_id, '').then(contacts => { this.setState({ cus_contacts: contacts }) });
    }

    handleSubmit(e) {
        e.preventDefault();
        let cus_contacts = this.state.cus_contacts;
        let import_contacts = this.state.import_contacts;
        cus_contacts.forEach(contact => {
            if (import_contacts.includes(contact.id)) {
                contact['project_id'] = this.props.project_id;
                this.props.update(contact);
            }
        })
    }

    handleChange(e) {
        var import_contacts = this.state.import_contacts;
        if (e.target.checked) {
            import_contacts.push(e.target.value);
        } else {
            import_contacts = import_contacts.filter((id, key) => { return id !== e.target.value });
        }
        this.setState({ import_contacts: import_contacts });
    }

    handleCancel(e) {
        this.props.handleCancelImportForm();
    }

    render() {
        var cus_contacts = this.state.cus_contacts;
        //console.log(cus_contacts);
        return (
            <CRow>
                <CCol xl={12} md={12}>
                    <CForm id={'form_' + this.state.id} onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader>
                                {this.state.form_header}:
                            </CCardHeader>
                            <CCardBody>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Thêm</th>
                                            <th>Tên</th>
                                            <th>Số ĐT</th>
                                            <th>Vị trí / Chức vụ</th>
                                            <th>Mô tả</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!helps.isEmpty(cus_contacts) && cus_contacts.map((contact) => {
                                            var isAvailable = true;
                                            if (contact.project_id == this.props.project_id) {
                                                isAvailable = false;
                                            }
                                            return (
                                                <tr className={!isAvailable && 'text-muted'}>
                                                    <td>
                                                        <CFormGroup>
                                                            <CInputCheckbox
                                                                className='ml-1 cursor-pointer'
                                                                disabled={!isAvailable}
                                                                value={contact.id}
                                                                defaultChecked={!isAvailable}
                                                                name='import'
                                                                onChange={(e) => { this.handleChange(e) }}
                                                            />
                                                        </CFormGroup>
                                                    </td>
                                                    <td>{contact.name}</td>
                                                    <td>{contact.phone}</td>
                                                    <td>{contact.position}</td>
                                                    <td>{contact.description}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </CCardBody>
                            <CCardFooter>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CButton type="submit" size='sm' color="primary">
                                            <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                            Lưu
                                        </CButton>
                                        <CButton type="button" size='sm' color="secondary" onClick={(e) => { this.handleCancel(e) }} className="ml-3">
                                            <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                            Huỷ
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow>
        )
    }
}
function mapStateToProps(state) {
    const { loading, contacts } = state.contactReducer;
    const { user } = state.authentication;
    return { loading, contacts, user };
}

const actionCreators = {
    getAll: contactActions.getAll,
    update: contactActions.update,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ImportContactsForm);
export { connectedContainer as ImportContactsForm };

