import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CCreateElement,
  CImg,
} from '@coreui/react'

import { generalConstants } from '../_constants';
import Logo from '../assets/logo-white.png';
import Logo_mini from '../assets/logo-shield-white.png';


// sidebar nav config
import navigation from './_nav'

const TheSidebar = () => {

  const dispatch = useDispatch()
  const show = useSelector(state => state.toggleSidebar.sidebarShow);
  let ckShow = 'responsive';
  if (!show) {
    ckShow = false;
  }

  return (
    <CSidebar
      show={ckShow}
      minimize={true}
      onShowChange={(val) => dispatch({ type: generalConstants.SET_SIDEBAR, sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <CImg src={Logo} className="c-sidebar-brand-full"></CImg>
        <CImg src={Logo_mini} className="c-sidebar-brand-minimized"></CImg>
      </CSidebarBrand>
      <CSidebarNav>

        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
