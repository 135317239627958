import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CCard,
    CCardHeader,
    CCardBody,
    CCardFooter,
    CInput,
    CSelect,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';

class taxonomysForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            form_header: 'Thêm Khoản Mục',
            id: '',
            name: '',
            code: '',
            category_id: 'section',
            added_time: new Date(),
            added_by: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        const { taxonomy } = this.props;
        //console.log(taxonomy);
        if (!helps.isEmpty(taxonomy)) {
            this.setState(Object.assign(this.state, taxonomy));
        } else {
            const category_id = this.props.category_id;
            const added_by = this.props.user.id;

            this.setState({
                errors: {},
                category_id: category_id,
                added_time: new Date(),
                added_by: added_by,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let taxonomy = {
                id: this.state.id,
                name: this.state.name,
                code: this.state.code,
                category_id: this.state.category_id,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
            }
            this.props.handleSubmit(taxonomy);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let value = e.target.value;
        this.setState({
            [field]: value
        });
        //console.log(this.state)
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập tên Khoản Mục";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        const { taxonomy, modals } = this.props;
        var show = false;

        if (helps.isEmpty(taxonomy)) {
            if (modals.includes('add')) {
                show = true;
            }
        } else {
            if (modals.includes(taxonomy.id)) {
                show = true;
            }
        }
        return (
            <CRow>
                <CCol xl={12}>
                    <CModal
                        size='lg'
                        show={show}
                        onClose={() => { this.props.toggleModal(!helps.isEmpty(taxonomy) ? taxonomy.id : 'add') }}
                    >
                        <CModalHeader closeButton>
                            <h3>{this.state.form_header}</h3>
                        </CModalHeader>
                        <CModalBody>
                            <CForm>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-name">Tên Khoản Mục:</CLabel>
                                        <CInput
                                            type='text'
                                            id='input-name'
                                            value={this.state.name}
                                            onChange={(e) => { this.handleChange('name', e) }}
                                        />
                                        <small className='text-danger'>{this.state.errors['name']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-code">Mã Khoản Mục:</CLabel>
                                        <CInput
                                            type='text'
                                            id='input-code'
                                            value={this.state.code}
                                            onChange={(e) => { this.handleChange('code', e) }}
                                        />
                                        <small className='text-danger'>{this.state.errors['code']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-category_id">Nhóm:</CLabel>
                                        <CSelect
                                            id='input-category_id'
                                            value={this.state.category_id}
                                            onChange={(e) => { this.handleChange('category_id', e) }}
                                        >
                                            {configs.acc_taxonomy_categories && configs.acc_taxonomy_categories.map((cat) => {
                                                return (
                                                    <option key={cat.id} value={cat.id}>{cat.label}</option>
                                                )
                                            })}
                                        </CSelect>
                                        <small className='text-danger'>{this.state.errors['category_id']}</small>
                                    </CCol>
                                </CRow>
                            </CForm>
                        </CModalBody>
                        <CModalFooter>
                            <CButton type="button" size='sm' color="primary" onClick={(e) => this.handleSubmit(e)}>
                                <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                Lưu
                            </CButton>
                            <CButton type="button" size='sm' color="secondary" onClick={() => { this.props.toggleModal(!helps.isEmpty(taxonomy) ? taxonomy.id : 'add') }} className="ml-3">
                                <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                Huỷ
                            </CButton>
                        </CModalFooter>
                    </CModal>
                </CCol>
            </CRow>
        )
    }
}
export default taxonomysForm;

