export const provinceConstants = {

    GET_LIST_REQUEST: 'PROVINCES_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'PROVINCES_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'PROVINCES_GET_LIST_FAILURE',

    ADD_REQUEST: 'PROVINCES_ADD_REQUEST',
    ADD_SUCCESS: 'PROVINCES_ADD_SUCCESS',
    ADD_FAILURE: 'PROVINCES_ADD_FAILURE',

    EDIT_REQUEST: 'PROVINCES_EDIT_REQUEST',
    EDIT_SUCCESS: 'PROVINCES_EDIT_SUCCESS',
    EDIT_FAILURE: 'PROVINCES_EDIT_FAILURE',

    DELETE_REQUEST: 'PROVINCES_DELETE_REQUEST',
    DELETE_SUCCESS: 'PROVINCES_DELETE_SUCCESS',
    DELETE_FAILURE: 'PROVINCES_DELETE_FAILURE',

}