import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CCallout,
    CTooltip,
    CPopover,
    CCollapse,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import Hint from 'src/_components/Hint';
import ViolationTermsForm from './ViolationTermsForm';

class ViolationTerms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            added_time: '',
            modals: [],
            is_show: true,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    handleDelete(e, term) {
        e.preventDefault();
        const { violation_type } = this.props;
        let _confirm = window.confirm('Bạn muốn xoá Hình thức Xử lý Vi Phạm: ' + term.title + ' ?');
        if (_confirm) {
            this.props._deleteTerm(violation_type, term);
        }
        return false;
    }

    handleSubmit(term) {
        const { violation_type } = this.props;

        if (helps.isEmpty(term.id)) {
            this.props.addTerm(violation_type, term);
            this.toggleModal('add');
        } else {
            this.props.updateTerm(violation_type, term);
            this.toggleModal(term.id);
        }
    }

    toggleModal = (item) => {
        let modals = this.state.modals;
        const position = modals.indexOf(item);
        let newItems = modals.slice();
        if (position !== -1) {
            newItems.splice(position, 1)
        } else {
            newItems = [...modals, item]
        }
        this.setState({ modals: newItems });
    }

    render() {
        const { violation_type, user } = this.props;
        if (violation_type.terms && violation_type.terms.length > 0) {
            violation_type.terms.map((term) => {
                term['_added_time'] = helps.formatDateTime_from_Timestamp(term.added_time);
                term['is_author'] = false;
                if (user.id == term.added_by) {
                    term['is_author'] = true;
                }
            })
        }

        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol xl={12} md={12} className="text-right">
                            <CTooltip content={'Thêm Hình thức Xử lý Vi Phạm'}>
                                <CButton type="button" color="primary" size="sm" onClick={() => { this.toggleModal('add') }}>
                                    <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                    Thêm
                                </CButton>
                            </CTooltip>
                            <CTooltip content={this.state.is_show ? 'Ẩn' : 'Hiện'}>
                                <CButton type="button" color="secondary" size="sm" onClick={() => { this.setState({ is_show: !this.state.is_show }) }} className='ml-2'>
                                    <CIcon name={this.state.is_show ? 'cil-chevron-top' : 'cil-chevron-bottom'} className="mfe-1 mt-0"></CIcon>
                                </CButton>
                            </CTooltip>
                            <ViolationTermsForm
                                key={'add-term'}
                                {... this.props}
                                modals={this.state.modals}
                                form_header="Thêm Hình Thức Xử Lý Vi Phạm"
                                item='add'
                                violation_type={violation_type}
                                toggleModal={() => { this.toggleModal('add') }}
                                handleSubmit={this.handleSubmit}
                            />

                        </CCol>
                    </CRow>
                    <CCollapse
                        show={this.state.is_show}

                    >
                        <hr />
                        <div className='violation-terms-container'>
                            {!helps.isEmpty(violation_type.terms) && violation_type.terms.length > 0 && violation_type.terms.map((term) => {
                                term['_fines'] = parseInt(term.fines).toLocaleString('vn-VN', {
                                    style: 'currency',
                                    currency: 'VND',
                                });
                                return (

                                    <CCallout key={term.id} color={term.color} className={'bg-white'}>
                                        <CRow className="mb-2">
                                            <CCol xl={4} md={4}>
                                                <h5>
                                                    <span>{term.title}</span>
                                                    <Hint content={term.description} />
                                                </h5>
                                            </CCol>
                                            <CCol xl={3} md={3}>
                                                Mức phạt: <b>{term._fines}</b>
                                            </CCol>
                                            <CCol xl={3} md={3}>
                                                Mô tả: <span dangerouslySetInnerHTML={{ __html: term.description }} />
                                            </CCol>
                                            <CCol xl={2} md={2}>
                                                <div className='text-right'>
                                                    <CTooltip
                                                        content={'Chỉnh sửa: ' + term.title}
                                                    >
                                                        <CButton type="button" size="sm" color="primary" title='Chỉnh sửa' onClick={() => { this.toggleModal(term.id); }}>
                                                            <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                        </CButton>
                                                    </CTooltip>
                                                    {term.is_author &&
                                                        <CTooltip
                                                            content={'bởi: ' + term.added_by_alias}
                                                        >
                                                            <CButton
                                                                color="danger"
                                                                size="sm"
                                                                title="Xoá"
                                                                className="ml-1"
                                                                onClick={(e) => { this.handleDelete(e, term) }}
                                                            >
                                                                <CIcon name="cil-trash"></CIcon>
                                                            </CButton>
                                                        </CTooltip>
                                                    }
                                                    <ViolationTermsForm
                                                        key={'edit-' + term.id}
                                                        {... this.props}
                                                        modals={this.state.modals}
                                                        form_header="Chỉnh Sửa Hình thức Xử lý Vi Phạm"
                                                        item={term.id}
                                                        term={term}
                                                        violation_type={violation_type}
                                                        toggleModal={() => { this.toggleModal(term.id) }}
                                                        handleSubmit={this.handleSubmit}
                                                    />
                                                </div>
                                            </CCol>
                                        </CRow>
                                        <hr />
                                    </CCallout>

                                );
                            })}
                        </div>
                    </CCollapse>
                </CCol >
            </CRow >
        )
    }
}

export default ViolationTerms;
