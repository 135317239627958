import { db, storage } from "src/dbConfigs";
import { helps } from "src/_helpers";
import { userService } from "./user.service";
import { fileService } from "./file.service";
import configs from "src/appConfigs";
export const contractService = {
    getAll,
    _delete,
    add,
    update,
}
async function getAll(customer, _limit = 1000) {
    try {
        let ref = db.collection('contracts');
        var _query = {};
        if (!helps.isEmpty(customer)) {
            _query = ref.where('customer_id', '==', customer.id)
                .orderBy("added_time", "desc")
                .limit(_limit);
        } else {
            _query = ref.orderBy("added_time", "desc")
                .limit(_limit);
        }

        const snapshot = await _query.get();

        const contracts = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        await Promise.all(
            contracts.map(async (contract) => {
                contract['type_alias'] = configs.contract_types ? configs.contract_types[contract.type] : '';
                if (contract.added_by) {
                    let user = await userService.getById(contract.added_by);
                    contract['added_by_alias'] = user.fullname + '-' + user.username;
                }
                if (contract.added_time) {
                    contract['added_time'] = contract['added_time'].toDate();
                }
                if (contract.sign_date) {
                    contract['sign_date'] = contract['sign_date'].toDate();
                }
                if (contract.start_date) {
                    contract['start_date'] = contract['start_date'].toDate();
                }
                if (contract.end_date) {
                    contract['end_date'] = contract['end_date'].toDate();
                }
            })
        );
        return contracts;
    } catch (error) {
        return error;
    }
}

async function add(contract) {
    try {
        var upload_files = contract.attachment;
        //console.log(typeof contract.attachment);
        contract['attachment'] = '';

        const docRef = await db.collection("contracts").add(contract);
        contract.id = docRef.id;

        if (!helps.isEmpty(upload_files) && typeof upload_files == 'object') {
            var _files = [];
            var _attachment = [];
            _files = await fileService.uploadFile(upload_files, 'customers/' + contract.customer_id + '/contracts', contract.id);

            if (!helps.isEmpty(_files) && _files.length > 0) {
                _files.map((file) => {
                    let _file = {
                        name: file.name,
                        url: file.url,
                    }
                    _attachment.push(_file);
                })
            }
            contract['attachment'] = _attachment;
        }

        var Ref = db.collection("contracts").doc(contract.id);
        await Ref.update(contract);

        console.log("Firebase: contract has been added successfully!");
        return contract;
    } catch (error) {
        console.error("Error adding contract: ", error);
    }
}

async function update(contract) {
    var Ref = db.collection("contracts").doc(contract.id);

    try {
        var upload_files = contract.attachment;
        if (!helps.isEmpty(upload_files) && upload_files instanceof FileList) {
            // Xoá các file cũ đi
            var storageRef = storage.ref('customers').child(contract.customer_id).child('contracts').child(contract.id);
            storageRef.listAll().then(function (result) {
                result.items.forEach(function (file) {
                    file.delete();
                });
            }).catch(function (error) {
                // Handle any errors
            });

            // upload files mới lên
            var _files = [];
            var _attachment = [];
            _files = await fileService.uploadFile(upload_files, 'customers/' + contract.customer_id + '/contracts', contract.id);

            if (!helps.isEmpty(_files) && _files.length > 0) {
                _files.map((file) => {
                    let _file = {
                        name: file.name,
                        url: file.url,
                    }
                    _attachment.push(_file);
                })
            }
            contract['attachment'] = _attachment;
        }

        await Ref.update(contract);
        console.log("Firebase: contract has been updated successfully!");
        return contract;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _delete(contract) {
    var Ref = db.collection("contracts").doc(contract.id);

    try {
        if (!helps.isEmpty(contract.attachment) && contract.attachment.length > 0) {
            Promise.all(
                contract.attachment.map(async (file) => {
                    file['object'] = 'customers/' + contract.customer_id + '/contracts';
                    file['object_id'] = contract.id;
                    let del_file = await fileService.deleteFile(file);
                    console.log(del_file);
                })
            );
        }

        await Ref.delete();
        console.log("Firebase: contract has been deleted successfully!");
        return contract;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}
