import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import ViolationsList from 'src/views/employees/violations/ViolationsList';
import { helps } from 'src/_helpers';
import { employeeActions, projectActions, violationActions } from 'src/_actions';
import { CCard, CCardBody, CCardFooter, CCardHeader, CCol, CRow, CButton, CTooltip } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import ViolationsForm from 'src/views/employees/violations/ViolationsForm';

class ViolationsListContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            violations: [],
            modals: [],
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getEmployees();
        this.getProjects();
        this.getTypes();
        this.getViolations();
    }

    async getViolations() {
        return await this.props.getAll();
    }

    async getTypes() {
        var { violation_types } = this.props;
        if (helps.isEmpty(violation_types)) {
            violation_types = await this.props.getAllTypes();
        }
        return violation_types;
    }

    async getEmployees() {
        if (helps.isEmpty(this.props.employees)) {
            await this.props.getEmployees();
        }
    }

    async getProjects() {
        if (helps.isEmpty(this.props.projects)) {
            await this.props.getProjects();
        }
    }

    toggleModal = (item) => {
        let modals = this.state.modals;
        const position = modals.indexOf(item);
        let newItems = modals.slice();
        if (position !== -1) {
            newItems.splice(position, 1)
        } else {
            newItems = [...modals, item]
        }
        this.setState({ modals: newItems });
        //console.log(this.state.modals)
    }

    handleSubmit(violation) {
        if (helps.isEmpty(violation.id)) {
            this.props.add(violation);
            this.toggleModal('add');
        } else {
            this.props.update(violation);
            this.toggleModal(violation.id);
        }
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol>
                            DANH SÁCH VI PHẠM
                        </CCol>
                        <CCol className={'text-right'}>
                            <CTooltip content={'Thêm Vi Phạm'}>
                                <CButton type="button" color="primary" size="sm" onClick={() => { this.toggleModal('add') }}>
                                    <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                    Thêm Vi Phạm
                                </CButton>
                            </CTooltip>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <ViolationsForm
                        {... this.props}
                        modals={this.state.modals}
                        form_header="Thêm Vi Phạm"
                        item='add'
                        toggleModal={() => { this.toggleModal('add') }}
                        handleSubmit={this.handleSubmit}
                    />
                    <ViolationsList {... this.props} />
                </CCardBody>
                <CCardFooter>

                </CCardFooter>
            </CCard>
        );
    }
}

function mapStateToProps(state) {
    const { loading, loading_type, violations, violation_types } = state.violationReducer;
    const { user } = state.authentication;
    const { employees } = state.employeeReducer;
    const { projects } = state.projectReducer;
    const { users } = state.userReducer;
    return { loading, loading_type, user, violations, violation_types, employees, projects, users };
}

const actionCreators = {
    getAll: violationActions.getAll,
    add: violationActions.add,
    update: violationActions.update,
    _delete: violationActions._delete,
    getAllTypes: violationActions.getAllTypes,
    getEmployees: employeeActions.getAll,
    getProjects: projectActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ViolationsListContainer);
export { connectedContainer as ViolationsListContainer };