import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CTooltip,
    CButton,
    CJumbotron,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';
import ExitLetter from './_ExitForms/ExitLetter';
import ExitHandover from './_ExitForms/ExitHandover';
import ExitDeductions from './_ExitForms/ExitDeductions';
import ExitConclusion from './_ExitForms/ExitConclusion';

class EmployeeExitForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            exit: {
                exit_type: 'letter',
                exit_date: '',
                exit_conclusion: '',
                exit_conclusion_value: '',
                exit_completed: false,
                exit_completed_by: '',
                letter: {
                    letter_receipt_date: new Date(),
                    letter_receipt_date_deadline: new Date(new Date().setDate(new Date().getDate() + 30)),
                    quit_note: '',
                    exit_interviewed: false,
                    suspension_decision_date: new Date(),
                    suspension_decision_name: '',
                    termination_decision_date: new Date(),
                    termination_decision_name: '',
                },
                handover: {
                    work_handovered: false,
                    assets_handovered: false,
                },
                deductions: {},
                conclusion: {
                    pnv_note: '',
                    phckt_note: '',
                    bgd_note: '',
                },
            },
            exit_status: 0,
            sh_s1: true,
            sh_s2: true,
            sh_s3: true,
            sh_assets: false,

        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleCheckboxTF = this.handleCheckboxTF.bind(this);
        this.handleChangeExitField = this.handleChangeExitField.bind(this);
        this.handleProcess = this.handleProcess.bind(this);
    }


    componentDidMount() {
        const { employee } = this.props;
        if (employee) {
            let exit = employee.exit ? employee.exit : this.state.exit;
            if (helps.isEmpty(exit.letter)) {
                exit.letter = this.state.exit.letter;
            }
            if (helps.isEmpty(exit.handover)) {
                exit.handover = this.state.exit.handover;
            }
            if (helps.isEmpty(exit.conclusion)) {
                exit.conclusion = this.state.exit.conclusion;
            } else {
                if (!helps.isEmpty(exit.conclusion.conclusion_phckt_note) && helps.isEmpty(exit.conclusion.phckt_note)) {
                    exit.conclusion.phckt_note = exit.conclusion.conclusion_phckt_note;
                }
                if (!helps.isEmpty(exit.conclusion.pnv_note) && helps.isEmpty(exit.conclusion.pnv_note)) {
                    exit.conclusion.pnv_note = exit.conclusion.pnv_note;
                }
                employee['exit'] = exit;
                this.setState({ exit: exit });
            }
            this.setState({
                exit_status: employee.exit_status ? employee.exit_status : 0,
                exit: exit,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.handleComplete();
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
        this.handleProcess();
    }

    handleChangeExitField(subgroup_name, e) {
        let employee = this.props.employee;
        let exit = employee.exit;
        let subgroup = exit[[subgroup_name]];
        let field = e.target.name;
        let value = e.target.value;

        if (!helps.isEmpty(subgroup_name)) {
            subgroup[[field]] = value;
            exit[[subgroup_name]] = subgroup;
        } else {
            exit[[field]] = value;
            if (field === 'exit_conclusion') {
                let conclusion = helps.getItemFromArr(configs.employee_exit_conditions, value);
                exit['exit_conclusion_value'] = conclusion.salary;
            }
        }
        employee['exit'] = exit;
        this.setState({ exit: exit });
        this.props.updateField(employee, 'exit');


        this.handleProcess();
    }

    handleCheckboxTF(field, subgroup_name) {
        let employee = this.props.employee;
        let exit = employee.exit;
        let subgroup = exit[[subgroup_name]] ? exit[[subgroup_name]] : {};
        subgroup[[field]] = !subgroup[[field]];
        exit[[subgroup_name]] = subgroup;
        employee['exit'] = exit;
        this.setState({ exit: exit });
        this.props.updateField(employee, 'exit');
        this.handleProcess();
    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [field]: selectedOptions.value });
    }

    handleChangeDate(_date, field, subgroup_name = '') {
        //console.log(_date, field)
        if (field === 'letter_receipt_date') {
            let employee = this.props.employee;
            let exit = employee.exit;
            let letter = exit.letter;
            letter['letter_receipt_date'] = _date;

            let letter_receipt_date_deadline = new Date(_date);
            letter_receipt_date_deadline.setDate(_date.getDate() + 30);
            //console.log(letter['letter_receipt_date'], letter_receipt_date_deadline);

            letter['letter_receipt_date_deadline'] = letter_receipt_date_deadline;
            exit['letter'] = letter;
            employee['exit'] = exit;

            this.setState({ exit: exit });
            this.props.updateField(employee, 'exit');
            return;
        }

        let employee = this.props.employee;
        let exit = employee.exit;
        let subgroup = exit[[subgroup_name]];
        if (!helps.isEmpty(subgroup_name)) {
            subgroup[[field]] = _date;
            exit[[subgroup_name]] = subgroup;
        } else {
            exit[[field]] = _date;
        }
        employee['exit'] = exit;
        this.setState({ exit: exit });
        this.props.updateField(employee, 'exit');
        this.handleProcess();
    }

    handleProcess() {
        const { employee } = this.props;
        let { exit } = this.state;
        let process = 1;
        if (exit.exit_date) {
            process += 1;
        }
        if (exit.handover.assets_handovered) {
            process += 1;
        }
        if (!helps.isEmpty(exit.exit_conclusion)) {
            process += 1;
        }
        this.setState({ exit_status: process });
        employee['exit_status'] = process;
        this.props.updateField(employee, 'exit_status');
    }

    handleComplete() {
        const { employee, user } = this.props;
        let { exit } = this.state;
        exit.exit_completed = true;
        exit.exit_completed_by = user.id;
        this.setState({ exit: exit });
        employee['exit'] = exit;
        this.props.updateField(employee, 'exit');
        employee['job_type'] = 'DNV';
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        let { exit } = this.state;

        if (!exit.exit_date) {
            formIsValid = false;
            errors["letter"] = "Điền thông tin ngày nghỉ việc.";
        }

        if (!exit.handover.assets_handovered) {
            formIsValid = false;
            errors["handover"] = "Kiểm tra lại công tác bàn giao.";
        }

        if (helps.isEmpty(exit.conclusion)) {
            formIsValid = false;
            errors["conclusion"] = "Kết luận, đánh giá Thôi việc.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    activeExit() {
        const { user } = this.props;
        this.setState({ exit_status: 1 });
        let employee = this.props.employee;
        employee['exit_status'] = 1;
        this.props.updateField(employee, 'exit_status');
        let exit = { kickoff_by: user.id, kickoff_date: new Date() }
        employee['exit'] = exit;
        this.props.updateField(employee, 'exit');
    }

    cancelExit(e) {
        e.preventDefault();
        let employee = this.props.employee;
        let _confirm = window.confirm('Hủy BB Xác Nhận Thôi Việc: ' + employee.fullname + ' ?');
        if (_confirm) {
            this.setState({ exit_status: 0 });
            employee['exit_status'] = 0;
            this.props.updateField(employee, 'exit_status');
            this.removeLetter(e);
            let exit = {}
            employee['exit'] = exit;
            this.props.updateField(employee, 'exit');
        }

    }

    render() {
        const { employee, user, users } = this.props;
        let exit = this.state.exit;

        return (
            <CRow>
                <CCol xl={12}>
                    {this.state.exit_status === 0 && <>
                        <CJumbotron>
                            <div className='text-center mt-3 mb-3'>
                                <CTooltip content={'Xác Nhận Thôi Việc'}>
                                    <CButton type="button" color="primary" size='lg' onClick={() => { this.activeExit() }} title={'Xác Nhận Thôi Việc'} className='ml-2'>
                                        <CIcon name={'cil-check'} className="mfe-1 mt-0"></CIcon>
                                        Làm Thủ Tục Thôi Việc
                                    </CButton>
                                </CTooltip>
                            </div>
                        </CJumbotron>
                    </>}
                    {this.state.exit_status !== 0 &&
                        <>
                            <CRow className={'mt-1 mb-3'}>
                                <CCol>
                                    <h5>
                                        Thủ Tục Xác Nhận Thôi Việc Đối Với Nhân Sự: <b>{employee.fullname} {employee.employee_id}</b>
                                    </h5>
                                    <small><i><span className='text-muted'>// Khởi tạo bởi:</span> {helps.getUserAlias(users, exit.kickoff_by)} <span className='text-muted'> | ngày: </span>{helps.formatDateTime_from_Timestamp(exit.kickoff_date)}</i></small>
                                </CCol>
                                <CCol className={'text-right'}>
                                    <CTooltip content={'Hủy Xác Nhận Thôi Việc'}>
                                        <CButton
                                            type="button"
                                            color="danger"
                                            size='sm'
                                            onClick={(e) => { this.cancelExit(e) }}
                                            title={'Hủy BB Xác Nhận Thôi Việc'}
                                            className='ml-2'
                                            disabled={exit.kickoff_by !== user.id || exit.exit_completed}
                                        >
                                            <CIcon name={'cil-x'} className="mfe-1 mt-0"></CIcon>
                                            Hủy Xác Nhận
                                        </CButton>
                                    </CTooltip>
                                </CCol>
                            </CRow>
                            <ExitLetter
                                {...this.props}
                                exit={this.state.exit}
                                handleSubmit={this.handleSubmit}
                                handleChange={this.handleChange}
                                handleChangeDate={this.handleChangeDate}
                                handleCheckboxTF={this.handleCheckboxTF}
                                handleChangeExitField={this.handleChangeExitField}
                                handleProcess={this.handleProcess}
                            />
                            <ExitHandover
                                {...this.props}
                                exit={this.state.exit}
                                handleSubmit={this.handleSubmit}
                                handleChange={this.handleChange}
                                handleChangeDate={this.handleChangeDate}
                                handleCheckboxTF={this.handleCheckboxTF}
                                handleChangeExitField={this.handleChangeExitField}
                                handleProcess={this.handleProcess}
                            />
                            <ExitDeductions
                                {...this.props}
                                exit={this.state.exit}
                                handleSubmit={this.handleSubmit}
                                handleChange={this.handleChange}
                                handleChangeDate={this.handleChangeDate}
                                handleCheckboxTF={this.handleCheckboxTF}
                                handleChangeExitField={this.handleChangeExitField}
                                handleProcess={this.handleProcess}
                            />
                            <ExitConclusion
                                {...this.props}
                                exit={this.state.exit}
                                handleSubmit={this.handleSubmit}
                                handleChange={this.handleChange}
                                handleChangeDate={this.handleChangeDate}
                                handleCheckboxTF={this.handleCheckboxTF}
                                handleChangeExitField={this.handleChangeExitField}
                                handleProcess={this.handleProcess}
                            />
                        </>
                    }
                </CCol>
            </CRow >
        )
    }
}
export default EmployeeExitForm;

