import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CDataTable,
    CButton,
    CSelect,
    CLink,
    CBadge,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import configs from 'src/appConfigs';
import Loading from 'src/containers/_loading';
import Select from 'react-select';

class Vendors extends Component {

    constructor(props) {
        super(props);

        this.state = {
            province: '',
            select_tags: [],
        }

        this.pageChange = this.pageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        const queryPage = this.props.location.search.match(/page=([0-9]+)/, '');
        const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
        this.setState({ currentPage: currentPage });
    }

    pageChange = (newPage) => {
        var currentPage = this.state.currentPage;
        currentPage !== newPage && this.props.history.push(`/users?page=${newPage}`)
    }

    handleChange(field, e) {
        var value = e.target.value;
        if (value === "true" || value === "false") {
            value = JSON.parse(value);
        }
        this.setState({
            [field]: value
        });
    }

    handleSelect(selectOPtions, field) {
        this.setState({
            [field]: selectOPtions,
        });
        console.log(this.state)
    }

    render() {
        const { vendors, users, provinces, notes, tags } = this.props;

        var _vendors = [];
        if (vendors && vendors.length > 0) {
            vendors.forEach((vendor, index) => {
                var province = helps.getItemFromArr(provinces, vendor.province_id);
                if (province) {
                    vendor['province_name'] = province.name;
                } else {
                    vendor['province_name'] = '';
                }

                if (!vendor.tags || helps.isEmpty(vendor.tags)) {
                    vendor['tags'] = '';
                }

                _vendors[index] = vendor;
            });
        }
        if (!helps.isEmpty(this.state.province)) {
            _vendors = _vendors.filter(item => item.province_id === this.state.province);
        }

        let select_tags = this.state.select_tags;
        if (!helps.isEmpty(select_tags)) {
            _vendors = _vendors.filter((item) => {
                if (!helps.isEmpty(item.tags) && item.tags.length > 0) {
                    let found = helps.getItemFromArr(item.tags, select_tags['value'], 'value');
                    if (!helps.isEmpty(found)) return true;
                    else return false;
                } else {
                    return false;
                }
            });
        }

        var tag_options = [];
        if (!helps.isEmpty(tags)) {
            tags.forEach((tag) => {
                tag_options.push({
                    value: tag.id,
                    label: tag.name,
                });
            });
        }

        //console.log(tags)

        const fields = [
            { key: 'index', label: '#', _style: { width: '10px' }, filter: false, sorter: false },
            { key: 'name', label: 'Tên', _classes: 'font-weight-bold', _style: { width: '15%' } },
            { key: 'tags', label: 'Nhãn', _style: { width: '15%' } },
            { key: 'address', label: 'Địa Chỉ', _style: { width: '15%' } },
            { key: 'province_name', label: 'Tỉnh Thành' },
            { key: 'phone', label: 'Điện Thoại' },
            { key: 'contact_info', label: 'Thông Tin Liên Hệ' },
            { key: 'website', label: 'Website', _style: { width: '12%' } },
            { key: 'create_by', label: 'Tạo Bởi', _style: { width: '10%' }, _classes: 'text-center', filter: false },
            { key: 'actions', label: 'Thao Tác', _style: { width: '10%' }, _classes: 'text-center', filter: false, sorter: false },
        ]
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        return (
            <CDataTable
                key={'vendors_' + _vendors.length}
                items={_vendors}
                fields={fields}
                columnFilter
                tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                itemsPerPageSelect={configs.itemsPerPageSelect}
                itemsPerPage={configs.per_page}
                hover
                sorter
                pagination
                striped
                columnFilterSlot={{
                    province_name: (
                        <>
                            <CSelect
                                name="province"
                                id="input-province"
                                className="form-control form-control-sm"
                                value={this.state.province}
                                onChange={(e) => { this.handleChange('province', e) }}
                            >
                                <option value="">-Tất cả-</option>
                                {provinces && provinces.map((province) => {
                                    return (
                                        <option value={province.id} key={province.id}>{province.name}</option>
                                    )
                                }
                                )}
                            </CSelect>
                        </>
                    ),
                    tags: (
                        <Select
                            name="select-tag"
                            isClearable
                            options={tag_options}
                            defaultValue={this.state.select_tags}
                            onChange={(selectOptions) => { this.handleSelect(selectOptions, 'select_tags') }}
                        >

                        </Select>
                    ),
                }}
                scopedSlots={{
                    'index': (item, index) => {
                        //console.log(item)
                        return (
                            <td className="py-2">
                                {index + 1}
                            </td>
                        )
                    },
                    'name': (item) => {
                        var _notes = [];
                        if (!helps.isEmpty(notes) && notes.length > 0) {
                            _notes = notes.filter(note => note.object_id === item.id);
                        }
                        var note_content = '';
                        if (!helps.isEmpty(_notes) && _notes.length > 0) {
                            note_content = _notes.map((note) => {
                                return (
                                    <div className='border-bottom mb-2 pb-2'>
                                        {note.content} <small>_<i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                    </div>
                                );
                            });
                        }
                        return (
                            <td className="py-2">
                                {!helps.isEmpty(note_content) &&
                                    <CPopover
                                        header='Ghi chú:'
                                        content={note_content}
                                    >
                                        <CLink className="text-dark" onClick={() => { this.props.history.push(`/vendors/view/${item.id}`) }}>
                                            <b>{item.name}</b>
                                        </CLink>
                                    </CPopover>
                                }
                                {helps.isEmpty(note_content) &&
                                    <CLink className="text-dark" onClick={() => { this.props.history.push(`/vendors/view/${item.id}`) }}>
                                        <b>{item.name}</b>
                                    </CLink>
                                }
                            </td>
                        )
                    },
                    'tags': (item) => {
                        //console.log(item)
                        return (
                            <td className="py-2">
                                {item.tags && item.tags.length > 0 && item.tags.map((tag) => {
                                    let _tag = helps.getItemFromArr(tags, tag.value);
                                    return (
                                        <CLink
                                            key={item.id + '_' + _tag.id}
                                            onClick={(e) => { e.preventDefault(); this.handleSelect({ value: _tag.id, label: _tag.name }, 'select_tags') }}
                                            title={'Lọc theo Nhãn: ' + _tag.name}
                                        >
                                            <CBadge key={_tag.id} color={_tag.color} className="p-1 m-1">
                                                {_tag.name}
                                            </CBadge>
                                        </CLink>
                                    )
                                })}
                            </td>
                        )
                    },
                    'website': (item) => {
                        return (
                            <td className="py-2">
                                {!helps.isEmpty(item.website) && <CLink href={item.website} target='_blank' rel='nofollow'>{item.website}</CLink>}
                            </td>
                        )
                    },
                    'create_by': (item) => {
                        return (
                            <td className="py-2">
                                <div>
                                    <small>
                                        {helps.getUserAlias(users, item.create_by)}<br />
                                        {helps.formatDate_from_Timestamp(item.create_date)}
                                    </small>
                                </div>
                            </td>
                        )
                    },
                    'actions':
                        (item) => {
                            var _notes = [];
                            if (!helps.isEmpty(notes) && notes.length > 0) {
                                _notes = notes.filter(note => note.object_id === item.id);
                            }
                            var note_content = '';
                            if (!helps.isEmpty(_notes) && _notes.length > 0) {
                                note_content = _notes.map((note) => {
                                    return (
                                        <div className='border-bottom mb-2 pb-2'>
                                            {note.content} <small>_<i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                        </div>
                                    );
                                });
                            }
                            return (
                                <td className="py-2 text-center">
                                    <ThePermission {... this.props} component="vendors" action="view">
                                        <CButton
                                            color="warning"
                                            size="sm mr-1"
                                            title="Xem chi tiết"
                                            onClick={() => { this.props.history.push(`/vendors/view/${item.id}`) }}
                                        >
                                            <CIcon name="cil-face"></CIcon>
                                        </CButton>
                                    </ThePermission>
                                    <ThePermission {... this.props} component="vendors" action="view">
                                        <CPopover
                                            header="Ghi chú"
                                            content={note_content}
                                        >
                                            <CButton
                                                color="info"
                                                size="sm mr-1"
                                            >
                                                <CIcon name="cil-list"></CIcon>
                                            </CButton>
                                        </CPopover>
                                    </ThePermission>
                                    <ThePermission {... this.props} component="vendors" action="edit">
                                        <CButton
                                            color="primary"
                                            size="sm"
                                            title="Cập nhật thông tin"
                                            onClick={() => { this.props.history.push(`/vendors/add_edit/${item.id}`) }}
                                        >
                                            <CIcon name="cil-pen-nib"></CIcon>
                                        </CButton>
                                    </ThePermission>
                                </td>
                            )
                        },
                }}
            />

        )
    }
}


export default Vendors;
