import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CTextarea,
    CCard,
    CCardHeader,
    CCardBody,
    CCardFooter,
    CInput,
    CInputRadio,
    CBadge,
    CFormGroup,
    CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Select from 'react-select';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';

class ContractsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            form_header: 'Thêm Hợp Đồng',
            contract_options: [],
            contract_default_option: {},
            id: '',
            code: '',
            contract_value: 0,
            sign_date: '',
            start_date: '',
            end_date: '',
            type: 'contract',
            parent_id: '',
            customer_id: '',
            added_time: new Date(),
            added_by: this.props.user.id,
            active: 0,
            note: '',
            attachment: '',
            old_attachment: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeFiles = this.handleChangeFiles.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {

        const { contract, contracts } = this.props;

        var contract_options = [];
        if (!helps.isEmpty(contracts) && contracts.length > 0) {
            contracts.map((contract) => {
                let option = {
                    value: contract.id,
                    label: contract.code
                }
                contract_options.push(option);
            });
        }
        this.setState({ contract_options: contract_options });

        if (!helps.isEmpty(contract)) {
            var contract_default_option = {};
            contract_default_option = contract_options.find((option) => option.value === contract.parent_id);

            this.setState({
                form_header: 'Chỉnh Sửa Hợp Đồng: ' + contract.code,
                id: contract.id,
                code: contract.code,
                contract_value: contract.contract_value,
                sign_date: contract.sign_date,
                start_date: contract.start_date,
                end_date: contract.end_date,
                type: contract.type,
                parent_id: contract.parent_id,
                note: contract.note,
                customer_id: contract.customer_id,
                added_time: contract.added_time,
                added_by: contract.added_by,
                active: contract.active,
                old_attachment: contract.attachment,
                contract_default_option: contract_default_option,
            });

        } else {
            const customer = this.props.customer;

            if (customer) {
                this.setState({
                    customer_id: customer.id,
                });
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let contract = {
                id: this.state.id,
                code: this.state.code,
                sign_date: this.state.sign_date ? new Date(this.state.sign_date) : new Date(),
                start_date: this.state.start_date ? new Date(this.state.start_date) : new Date(),
                end_date: this.state.end_date ? new Date(this.state.end_date) : new Date(),
                type: this.state.type,
                parent_id: this.state.parent_id,
                note: this.state.note,
                customer_id: this.state.customer_id,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
                active: this.state.active,
                contract_value: this.state.contract_value,
                attachment: this.state.attachment ? this.state.attachment : this.state.old_attachment,
            }
            //console.log(contract);
            this.props.handleSubmit(contract);
        } else {
            return false;
        }
    }

    handleChangeFiles(field, e) {
        let upload_files = e.target.files;
        this.setState({ [field]: upload_files });
    }

    handleChangeDate(e, field) {
        this.setState({ [field]: new Date(e.target.value).getTime() });
    }

    handleChange(field, e) {
        this.setState({
            [field]: e.target.value
        });
    }

    handleChangeSelect(selectedOptions) {
        if (selectedOptions) {
            this.setState({ 'parent_id': selectedOptions.value });
        } else {
            this.setState({ parent_id: '' });
        }

    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.code)) {
            formIsValid = false;
            errors["code"] = "Vui lòng nhập Số Hợp Đồng";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCancel(e) {
        e.preventDefault();
        if (helps.isEmpty(this.state.id)) {
            this.props.handleCancelForm();
        } else {
            this.props.toggleEditForms();
        }

    }

    render() {
        //console.log(this.state)
        let today = new Date().toISOString().slice(0, 10);
        var sign_date_val = today;
        if (this.state.sign_date) {
            sign_date_val = helps.formatDate_for_input(this.state.sign_date, 'YYYY-MM-DD');
        }
        var start_date_val = today;
        if (this.state.start_date) {
            start_date_val = helps.formatDate_for_input(this.state.start_date, 'YYYY-MM-DD');
        }
        var end_date_val = today;
        if (this.state.end_date) {
            end_date_val = helps.formatDate_for_input(this.state.end_date, 'YYYY-MM-DD');
        }
        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader>
                                {this.state.form_header}
                            </CCardHeader>
                            <CCardBody>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-type" className='mr-4'>Loại:</CLabel>
                                        {configs.contract_types && configs.contract_types.map((type) => {
                                            return (
                                                <CFormGroup key={'type-' + type.type} variant="custom-radio" className='mb-2' inline>
                                                    <CInputRadio
                                                        custom
                                                        id={"type_" + type.type}
                                                        value={type.type}
                                                        name='type'
                                                        defaultChecked={type.type == this.state.type}
                                                    />
                                                    <CLabel variant="custom-checkbox" htmlFor={"type_" + type.type} className="cursor-pointer">
                                                        <CBadge color={type.color} className="p-2">{type.label}</CBadge>
                                                    </CLabel>
                                                </CFormGroup>
                                            );
                                        })}
                                        <small className='text-danger'>{this.state.errors['type']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="parent_id" className='mr-4'>Phụ thuộc Hợp Đồng:</CLabel>
                                        <Select
                                            id="parent_id"
                                            name="parent_id"
                                            placeholder="Chọn Hợp Đồng ..."
                                            isClearable
                                            options={this.state.contract_options}
                                            value={this.state.contract_default_option}
                                            onChange={(choice) => { this.handleChangeSelect(choice) }}
                                        />
                                        <small className='text-danger'>{this.state.errors['parent_id']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-code">Số Hợp Đồng / Phụ Lục / Thoả Thuận:</CLabel>
                                        <CInput
                                            type='text'
                                            name='code'
                                            id='input-code'
                                            defaultValue={this.state.code}
                                            onChange={(e) => { this.handleChange('code', e) }}
                                        />
                                        <small className='text-danger'>{this.state.errors['code']}</small>
                                    </CCol>
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-code">Ngày Ký Kết:</CLabel>
                                        <CInput
                                            placeholder="dd/mm/yyyy"
                                            type='date'
                                            name='sign_date'
                                            id='input-sign_date'
                                            value={sign_date_val}
                                            onChange={e => this.handleChangeDate(e, 'sign_date')}
                                        />
                                        <small className='text-danger'>{this.state.errors['sign_date']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-contract_value">Giá trị Hợp Đồng:</CLabel>
                                        <CInput
                                            type='number'
                                            name='contract_value'
                                            id='input-contract_value'
                                            defaultValue={this.state.contract_value}
                                            onChange={(e) => { this.handleChange('contract_value', e) }}
                                        />
                                        <small className='text-danger'>{this.state.errors['contract_value']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-start_date">Hợp Đồng có hiệu lực từ ngày:</CLabel>
                                        <CInput
                                            placeholder="dd/mm/yyyy"
                                            type='date'
                                            name='start_date'
                                            id='input-start_date'
                                            value={start_date_val}
                                            onChange={(e) => { this.handleChange('start_date', e) }}
                                        />
                                        <small className='text-danger'>{this.state.errors['start_date']}</small>
                                    </CCol>
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-content">Hết hiệu lực ngày:</CLabel>
                                        <CInput
                                            placeholder="dd/mm/yyyy"
                                            type='date'
                                            name='end_date'
                                            id='input-end_date'
                                            value={end_date_val}
                                            onChange={(e) => { this.handleChange('end_date', e) }}
                                        />
                                        <small className='text-danger'>{this.state.errors['end_date']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-attachment">Đính kèm:</CLabel>
                                        {this.state.old_attachment && this.state.old_attachment.length > 0 && this.state.old_attachment.map(file => {
                                            return (
                                                <ul key={'attachment-' + file.name} className='mt-2 mb-3'>
                                                    <li><CLink href={file.url} target='_blank'>{file.name}</CLink></li>
                                                </ul>
                                            )
                                        })}
                                        <CInput
                                            type='file'
                                            name='attachment'
                                            id='input-attachment'
                                            multiple
                                            onChange={(e) => { this.handleChangeFiles('attachment', e) }}
                                        />
                                        <small className='text-danger'>{this.state.errors['end_date']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-content">Ghi Chú:</CLabel>
                                        <CTextarea
                                            rows={5}
                                            name="note"
                                            id="input-note"
                                            defaultValue={this.state.note}
                                            onChange={(e) => { this.handleChange('note', e) }}
                                        >
                                        </CTextarea>
                                        <small className="text-danger">{this.state.errors['note']}</small>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CButton type="submit" size='sm' color="primary">
                                            <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                            Lưu
                                        </CButton>
                                        <CButton type="button" size='sm' color="secondary" onClick={(e) => { this.handleCancel(e) }} className="ml-3">
                                            <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                            Huỷ
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow>
        )
    }
}
export default ContractsForm;

