import {
    CCard, CCardBody, CCardHeader,
    CCol,
    CRow,
    CButton,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import EmployeeCertificates from 'src/views/employees/EmployeeCertificates';

class EmployeeCertificatesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit_forms: [],
        }

        this.toggleEditForms = this.toggleEditForms.bind(this);
    }

    componentDidMount() {

    }

    toggleEditForms = (item) => {
        let forms = this.state.edit_forms;
        const position = forms.indexOf(item.id);
        let newForms = forms.slice();
        if (position !== -1) {
            newForms.splice(position, 1)
        } else {
            newForms = [...forms, item.id]
        }
        this.setState({ edit_forms: newForms });
        //console.log(newForms);
    }

    render() {
        const { employee } = this.props;
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol md={6}>
                            Chứng Chỉ
                        </CCol>
                        <CCol md={6} className="text-right">
                            <CTooltip content={'Thêm Chứng chỉ'}>
                                <CButton type="button" color="primary" size="sm" onClick={() => { this.toggleEditForms({ id: 'add_form' }) }}>
                                    <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                    Thêm
                                </CButton>
                            </CTooltip>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <EmployeeCertificates
                        {... this.props}
                        key={employee.employee_id}
                        edit_forms={this.state.edit_forms}
                        toggleEditForms={this.toggleEditForms}
                    />
                </CCardBody>
            </CCard>
        );
    }
}

function mapStateToProps(state) {
    const { loading } = state.employeeReducer;
    const { user } = state.authentication;
    const { files } = state.fileReducer;
    return { loading, files, user };
}

const actionCreators = {

};

const connectedContainer = connect(mapStateToProps, actionCreators)(EmployeeCertificatesContainer);
export { connectedContainer as EmployeeCertificatesContainer };