import React, { Component } from 'react'
import {
    CCol,
    CRow,
} from '@coreui/react';

import Tasks from './Tasks';
import Notes from './Notes';
import Employees from './Employees';

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <CRow>
                    <CCol xl={12} md={12} sm={12}>
                        <Tasks
                            {... this.props}
                        />
                    </CCol>

                </CRow>
                <CRow>
                    <CCol xl={6} md={6} sm={12}>
                        <Notes {... this.props} />
                    </CCol>
                    <CCol xl={6} md={6} sm={12}>
                        <Employees
                            {... this.props}
                        />
                    </CCol>
                </CRow>
            </>
        )
    }
}

export default Dashboard;
