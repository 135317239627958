import { CCol, CRow } from '@coreui/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { provinceActions } from 'src/_actions';
import { helps, PHP } from 'src/_helpers';

import Provinces from 'src/views/provinces/Provinces';
import configs from 'src/appConfigs';



class ProvincesContainer extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.doGetAll();
    }

    async doGetAll() {
        await this.props.getAll();
    }

    render() {
        /*
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        */
        return (
            <CRow>
                <CCol xl={12}>
                    <Provinces  {... this.props} provinces={this.props.provinces} />
                </CCol>
            </CRow>
        );
    }
}

function mapStateToProps(state) {
    const { loading, provinces } = state.provinceReducer;
    return { loading, provinces };
}

const actionCreators = {
    getAll: provinceActions.getAll,
    add: provinceActions.add,
    update: provinceActions.update,
    _delete: provinceActions._delete,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ProvincesContainer);
export { connectedContainer as ProvincesContainer };