import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CRow, CCol,
    CButton,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { ThePermission } from '../ThePermission';
import Maneuvers from 'src/views/maneuver/Maneuvers';
import { maneuverActions } from '../../_actions';
import { employeeActions } from '../../_actions';
import { ManeuverFormContainer } from './ManeuversFormContainer';

class ManeuversContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show_maneuver_form: false,
        }
    }

    componentDidMount() {
        this.getManeuvers();

    }

    async getManeuvers() {
        const project = this.props.project;
        //console.log(project)
        const maneuvers = await this.props.getManeuvers({ project: project }, 1000);
        return maneuvers;
    }

    toggleForm() {
        this.setState({ show_maneuver_form: !this.state.show_maneuver_form });
    }

    render() {
        return (
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol>
                            Lịch Sử Điều Động
                        </CCol>
                        <CCol className='text-right'>
                            <ThePermission {... this.props} component="projects" action="edit">
                                <CTooltip content={'Điều Động Nhân Sự tới: ' + this.props.project.name}>
                                    <CButton type="button" color="primary" size="sm" onClick={() => { this.toggleForm() }}>
                                        <CIcon name="cil-user" className="mfe-1 mt-0"></CIcon>
                                        Điều Động Nhân Sự
                                    </CButton>
                                </CTooltip>
                            </ThePermission>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <Maneuvers
                        key={'maneuvers_' + this.props.project_id}
                        {... this.props}
                        project={this.props.project}
                        maneuvers={this.props.maneuvers}
                        user={this.props.user}
                    />
                    <ManeuverFormContainer
                        {... this.props}
                        project={this.props.project}
                        show_maneuver_form={this.state.show_maneuver_form}
                        toggleForm={() => { this.toggleForm() }}
                        user={this.props.user}
                    />
                </CCardBody>
            </CCard>
        );
    }
}

function mapStateToProps(state) {
    const { loading, maneuvers } = state.maneuverReducer;
    const { user } = state.authentication;
    return { loading, user, maneuvers };
}

const actionCreators = {
    getManeuvers: maneuverActions.getAll,
    add: maneuverActions.add,
    updateEmployee: employeeActions.updateEmployee,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ManeuversContainer);
export { connectedContainer as ManeuversContainer };