import { vendorConstants } from "src/_constants";

export function vendorReducer(state = { vendors: [] }, actions) {
    switch (actions.type) {

        // get all vendor
        case vendorConstants.GETALL_REQUEST:
            return {
                loading: true,
            }
        case vendorConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                vendors: actions.vendors,
            }
        case vendorConstants.GETALL_FAILURE:
            return {
                ...state,
                error: actions.error
            }

        // get a vendor by ID    
        case vendorConstants.GET_REQUEST:
            return {
                loading: true
            }
        case vendorConstants.GET_SUCCESS:
            return {
                ...state,
                vendor: actions.vendor,
                loading: false
            }
        case vendorConstants.GET_FAILURE:
            return {
                error: actions.error
            };
        // add new vendor    
        case vendorConstants.ADD_REQUEST:
            return {
                loading: true
            }
        case vendorConstants.ADD_SUCCESS:
            var vendors = state.vendors ? state.vendors : [];
            vendors.push(actions.vendor);
            return {
                ...state,
                vendors: vendors,
                loading: false
            }
        case vendorConstants.ADD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update vendor    
        case vendorConstants.UPDATE_REQUEST:
            return {
                ...state,
                //loading: true
            }
        case vendorConstants.UPDATE_SUCCESS:
            var _vendors = state.vendors;
            var foundIndex = _vendors.findIndex(item => item.id === actions.vendor.id);
            actions.vendor['index'] = foundIndex;
            _vendors[foundIndex] = actions.vendor;
            return {
                ...state,
                //loading: false,
                vendors: _vendors,
                vendor: actions.vendor,
            }
        case vendorConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update vendor field    
        case vendorConstants.UPDATE_FIELD_REQUEST:
            return {
                ...state,
                loading: false
            }
        case vendorConstants.UPDATE_FIELD_SUCCESS:
            var _vendors = state.vendors;
            var foundIndex = _vendors.findIndex(item => item.id === actions.vendor.id);
            actions.vendor['index'] = foundIndex;
            _vendors[foundIndex] = actions.vendor;
            console.log(foundIndex);
            return {
                ...state,
                loading: false,
                vendors: _vendors,
                vendor: actions.vendor,
            }
        case vendorConstants.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        default:
            return state;
    }
}