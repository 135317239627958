export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    PERMISSION_REQUEST: 'USERS_PERMISSION_REQUEST',
    PERMISSION_SUCCESS: 'USERS_PERMISSION_SUCCESS',
    PERMISSION_FAILURE: 'USERS_PERMISSION_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    GETUSER_REQUEST: 'USERS_GETUSER_REQUEST',
    GETUSER_SUCCESS: 'USERS_GETUSER_SUCCESS',
    GETUSER_FAILURE: 'USERS_GETUSER_FAILURE',

    ADDUSER_REQUEST: 'USERS_ADDUSER_REQUEST',
    ADDUSER_SUCCESS: 'USERS_ADDUSER_SUCCESS',
    ADDUSER_FAILURE: 'USERS_ADDUSER_FAILURE',

    UPDATEUSER_REQUEST: 'USERS_UPDATEUSER_REQUEST',
    UPDATEUSER_SUCCESS: 'USERS_UPDATEUSER_SUCCESS',
    UPDATEUSER_FAILURE: 'USERS_UPDATEUSER_FAILURE',


};
