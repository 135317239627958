import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CPopover,
    CFormGroup,
    CSelect,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import Loading from 'src/containers/_loading';
import Select from 'react-select';

class HousingRecords extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    renderHeaders() {
        const { month } = this.props;
        let today = month ? month : new Date();
        var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        let ths = [];
        for (let i = 1; i <= lastDay.getDate(); i++) {
            let cur_day = new Date(today.getFullYear(), today.getMonth(), i);
            let weekday = cur_day.getDay();
            ths.push(<th key={'th-' + i} title={configs.weekday_full[weekday] + ', ' + helps.formatDate_from_Timestamp(cur_day)}>
                {i}<div><small className='text-muted'><i className={weekday === 0 ? 'text-danger' : ''}>{configs.weekday[weekday]}</i></small></div>
            </th>);
        }
        ths.push(<th>Tổng</th>);
        return ths;
    }

    handleChange(field, e) {
        let value = e.target.value;
        this.setState({
            [field]: value
        });
    }

    renderData(employee) {
        const { checks, month, projects, housings, housing } = this.props;
        var employee_checks = checks.filter(check => check.employee_id === employee.employee_id);
        var housing_checks = employee_checks.filter(check => check.housing_id === housing.id);
        var firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
        let today = month ? month : new Date();
        var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        let tds = [];
        var total = 0;
        for (let i = 1; i <= lastDay.getDate(); i++) {
            let cur_day = new Date(firstDay.getFullYear(), firstDay.getMonth(), i);
            var day_checks = [];
            day_checks = housing_checks.filter((check) => {
                let check_date = check.check_date;
                if (check_date.getFullYear() === cur_day.getFullYear() && check_date.getMonth() === cur_day.getMonth() && check_date.getDate() === cur_day.getDate()) return true;
                else return false;
            });
            day_checks = helps.unigByKeepLast(day_checks, it => it.employee_id)
            tds.push(
                <td key={'td-' + i} className={helps.isEmpty(day_checks) ? 'empty' : ''}>
                    {day_checks && day_checks.length > 0 && day_checks.map((check) => {
                        var duration = check.check_duration / 60;
                        if (!Number.isInteger(duration)) {
                            duration = parseFloat(check.check_duration / 60).toFixed(2);
                        } else {
                            duration = parseInt(duration);
                        }
                        total = total + 1;
                        let project = helps.getItemFromArr(projects, check.project_id);

                        let housing = helps.getItemFromArr(housings, check.housing_id);
                        return (
                            <CPopover
                                content={<div>
                                    <div className='border-bottom mb-2'>
                                        <div><b>{employee.fullname}</b></div>
                                        <small className='text-muted'>{helps.formatDate_from_Timestamp(cur_day)} | <b>{project.name}</b></small>
                                        <div><small className='text-muted'>Check ID: {check.id}</small></div>
                                        <div><small className='text-muted'>Nhà Đội: {housing && <b>{housing.name}</b>}</small></div>
                                    </div>
                                    {check.is_overtime && <b><i>Tăng ca</i></b>}
                                    <div>Check-in: <b>{helps.formatDateTime_from_Timestamp(check.checkin_time)}</b></div>
                                    <div>Check-out: <b>{!check.checkout_time && <span className='text-danger'>Chưa checkout</span>}{check.checkout_time && <>{helps.formatDateTime_from_Timestamp(check.checkout_time)}</>}</b></div>
                                    {!helps.isEmpty(check.note) && <small className='text-muted'><b><u>Ghi chú:</u></b> {check.note}</small>}
                                </div>}
                            >
                                <div key={check.id} id={check.id}>
                                    <CIcon name="cil-check" className='text-success'></CIcon>
                                </div>
                            </CPopover>
                        )
                    })}
                </td>
            );
        }
        tds.push(
            <td key={'total-' + employee.employee_id}>
                <CPopover content={total + ' ngày'}>
                    <b>{total}</b>
                </CPopover>
            </td>
        );
        return tds;
    }

    render() {
        const { projects, employees, positions, housing, checks } = this.props;

        if (helps.isEmpty(projects)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(positions)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(employees)) {
            return (
                <Loading></Loading>
            );
        }
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        var _employees = [];
        if (!helps.isEmpty(checks)) {
            checks.map((check) => {
                if (check.housing_id === housing.id) {
                    let employee = helps.getItemFromArr(employees, check.employee_id, 'employee_id');
                    _employees.push(employee);
                }
            })
        }
        _employees = helps.unigByKeepLast(_employees, it => it.employee_id);
        return (
            <CRow>
                <CCol xl={12} md={12}>
                    <div className='timekeeping-container'>
                        <table className='timekeeping mb-2'>
                            <thead className='border-bottom'>
                                <tr>
                                    <th>#</th>
                                    <th>Họ & Tên</th>
                                    {this.renderHeaders()}
                                </tr>
                            </thead>
                            <tbody>
                                {!helps.isEmpty(_employees) && _employees.map((employee, employee_index) => {
                                    return (
                                        <tr key={employee.employee_id}>
                                            <th>{employee_index + 1}</th>
                                            <th>
                                                <CPopover content={employee.employee_id}>
                                                    <b>{employee.fullname}</b>
                                                </CPopover>
                                            </th>
                                            {this.renderData(employee)}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div >
                </CCol>
            </CRow>
        )
    }
}

export default HousingRecords;
