import { storeConstants } from "src/_constants";

export function storeReducer(state = { stores: [] }, actions) {
    switch (actions.type) {

        // get all store
        case storeConstants.GETALL_REQUEST:
            return {
                loading: true,
            }
        case storeConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                stores: actions.stores,
            }
        case storeConstants.GETALL_FAILURE:
            return {
                ...state,
                error: actions.error
            }

        // get a store by ID    
        case storeConstants.GET_REQUEST:
            return {
                loading: true
            }
        case storeConstants.GET_SUCCESS:
            return {
                ...state,
                store: actions.store,
                loading: false
            }
        case storeConstants.GET_FAILURE:
            return {
                error: actions.error
            };
        // add new store    
        case storeConstants.ADD_REQUEST:
            return {
                loading: true
            }
        case storeConstants.ADD_SUCCESS:
            var stores = state.stores ? state.stores : [];
            stores.push(actions.store);
            return {
                ...state,
                stores: stores,
                loading: false
            }
        case storeConstants.ADD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update store    
        case storeConstants.UPDATE_REQUEST:
            return {
                ...state,
                //loading: true
            }
        case storeConstants.UPDATE_SUCCESS:
            var _stores = state.stores;
            var foundIndex = _stores.findIndex(store => store.id === actions.store.id);
            actions.store['index'] = foundIndex;
            _stores[foundIndex] = actions.store;
            console.log(foundIndex);
            return {
                ...state,
                //loading: false,
                stores: _stores,
                store: actions.store,
            }
        case storeConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update store field    
        case storeConstants.UPDATE_FIELD_REQUEST:
            return {
                ...state,
                loading: false
            }
        case storeConstants.UPDATE_FIELD_SUCCESS:
            var _stores = state.stores;
            var foundIndex = _stores.findIndex(store => store.id === actions.store.id);
            actions.store['index'] = foundIndex;
            _stores[foundIndex] = actions.store;
            console.log(foundIndex);
            return {
                ...state,
                loading: false,
                stores: _stores,
                store: actions.store,
            }
        case storeConstants.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        default:
            return state;
    }
}