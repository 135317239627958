import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, generalActions } from '../../_actions';
import UserForm from '../../views/users/UserForm';


class UserAddContainer extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        // if have not loaded Roles to State, so do load
        if (!this.props.roles)
            this.props.getAllRoles();
    }

    handleSubmit = (user) => {
        this.props.addUser(user);
        this.props.history.push('/users');
    }

    render() {

        return (
            <UserForm
                {... this.props}
                formHeader='Add new'
                submitLabel='Save'
                roles={this.props.roles}
                handleSubmit={this.handleSubmit}
            />
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.userReducer;
    const { roles } = state.rolesReducer;
    return { user, roles };
}

const actionCreators = {
    addUser: userActions.addUser,
    getAllRoles: generalActions.getAllRoles
};
const connectedContainer = connect(mapStateToProps, actionCreators)(UserAddContainer);
export { connectedContainer as UserAddContainer };