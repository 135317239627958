import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTooltip,
    CButton,
    CLabel,
    CFormGroup,
    CInputRadio,
    CInputCheckbox,
    CCollapse,
    CCallout,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText,
    CPopover,
    CImg,
    CLink,
    CTextarea,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';
import Hint from 'src/_components/Hint';
import Dropzone from 'react-dropzone';
import DateTimePicker from 'react-datetime-picker';


class ExitLetter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            sh_s1: true,
        }
        this.uploadLetter = this.uploadLetter.bind(this);
        this.removeLetter = this.removeLetter.bind(this);
    }


    componentDidMount() {

    }

    uploadLetter(upload_files, type = 'letter') {
        const { employee, user, exit } = this.props;
        if (helps.isEmpty(upload_files)) {
            alert("Vui lòng chọn file để tải lên!");
            return;
        }
        let keys = [];
        if (typeof upload_files !== 'undefined' && upload_files !== null) {
            keys = Object.keys(upload_files);
        }
        let today = new Date();
        keys.forEach(async (_key) => {
            let og_file = upload_files[_key];
            let _name = employee.fullname.replace(" ", '_') + "_" + employee.employee_id + '_' + today.getDate() + (today.getMonth() + 1) + today.getFullYear();
            let _prefix = '';
            switch (type) {
                case 'suspension_decision':
                    _prefix = "QĐ_Đình_Chỉ_Công_Tác_";
                    break;
                case 'termination_decision':
                    _prefix = "QĐ_Cho_Thôi_Việc_";
                    break;
                default:
                    _prefix = "Đơn_Thôi_Việc_";
            }
            let _filename = _prefix + _name;
            _filename = _filename.replace(" ", '_') + '.' + helps.getExt(og_file.name);
            let _newfile = new File([og_file], _filename);
            upload_files[_key] = _newfile;
        })
        this.props.uploadLetter(employee, upload_files, user, type);
    }

    removeLetter(e, type = 'letter') {
        e.preventDefault();
        const { employee, user } = this.props;
        let exit = employee.exit;
        if (exit.exit_type === 'layoff') {
            if (type === 'suspension_decision' && employee.exit.letter.suspension_decision_upload_by !== user.id) return;
            if (type === 'termination_decision' && employee.exit.letter.termination_decision_upload_by !== user.id) return;
        } else {
            if (employee.exit.letter.letter_upload_by !== user.id) return;
        }
        this.props.deleteLetter(employee, user, type);
    }

    render() {
        const { user, exit } = this.props;
        //console.log(employee.exit)
        if (exit.letter && exit.letter.letter_receipt_date && !exit.letter.letter_receipt_date_deadline) {
            let letter_receipt_date_deadline = exit.letter.letter_receipt_date;
            letter_receipt_date_deadline.setDate(exit.letter.letter_receipt_date.getDate() + 30);
            exit.letter['letter_receipt_date_deadline'] = letter_receipt_date_deadline;
        }

        return (
            <CRow>
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader style={{ backgroundColor: '#EEE' }}>
                            <CRow>
                                <CCol>
                                    I. ĐƠN THÔI VIỆC
                                    {this.state.errors['letter'] && <div className='mb-1 mt-1'><small className="text-danger">// {this.state.errors['letter']}</small></div>}
                                </CCol>
                                <CCol className={'text-right'}>
                                    <CTooltip content={this.state.sh_s1 ? 'Ẩn' : 'Hiện'}>
                                        <CButton type="button" color="secondary" size="sm" onClick={() => { this.setState({ sh_s1: !this.state.sh_s1 }) }} title={this.state.sh_s1 ? 'Ẩn' : 'Hiện'} className='ml-2'>
                                            <CIcon name={this.state.sh_s1 ? 'cil-chevron-top' : 'cil-chevron-bottom'} className="mfe-1 mt-0"></CIcon>
                                        </CButton>
                                    </CTooltip>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCollapse show={this.state.sh_s1}>
                            <CCardBody>
                                <CRow className={'align-middle'}>
                                    <CCol md={3}>
                                        <CCallout color='primary' className='mb-5 pb-3 pt-3'>
                                            <CTooltip content={'Nhân sự có đơn xin thôi việc.'}>
                                                <CFormGroup variant="custom-radio" inline>
                                                    <CInputRadio
                                                        custom
                                                        id={"exit_type_letter"}
                                                        name="exit_type"
                                                        value='letter'
                                                        checked={exit.exit_type === 'letter'}
                                                        onChange={(e) => { this.props.handleChangeExitField('', e) }}
                                                        disabled={exit.exit_completed}
                                                    />
                                                    <CLabel variant="custom-checkbox" htmlFor="exit_type_letter" className="cursor-pointer font-weight-bold">
                                                        Đơn Xin Thôi Việc
                                                    </CLabel>
                                                </CFormGroup>
                                            </CTooltip>
                                        </CCallout>
                                        <CCallout color='warning' className='mb-5 pb-3 pt-3'>
                                            <CTooltip content={'P.NV có QĐ đình chỉ công tác và đề xuất cho thôi việc.'}>
                                                <CFormGroup variant="custom-radio" inline>
                                                    <CInputRadio
                                                        custom
                                                        id={"exit_type_layoff"}
                                                        name="exit_type"
                                                        value='layoff'
                                                        checked={exit.exit_type === 'layoff'}
                                                        onChange={(e) => { this.props.handleChangeExitField('', e) }}
                                                        disabled={exit.exit_completed}
                                                    />
                                                    <CLabel variant="custom-checkbox" htmlFor="exit_type_layoff" className="cursor-pointer font-weight-bold">
                                                        Đình Chỉ Công Tác/Cho Thôi Việc
                                                    </CLabel>
                                                </CFormGroup>
                                            </CTooltip>
                                        </CCallout>
                                        <CCallout color='danger' className='mb-5 pb-3 pt-3'>
                                            <CTooltip content={'Các trường hợp bỏ việc ngang.'}>
                                                <CFormGroup variant="custom-radio" inline>
                                                    <CInputRadio
                                                        custom
                                                        id={"exit_type_quit"}
                                                        name="exit_type"
                                                        value='quit'
                                                        checked={exit.exit_type === 'quit'}
                                                        onChange={(e) => { this.props.handleChangeExitField('', e) }}
                                                        disabled={exit.exit_completed}
                                                    />
                                                    <CLabel variant="custom-checkbox" htmlFor="exit_type_quit" className="cursor-pointer font-weight-bold">
                                                        Bỏ Việc Ngang
                                                    </CLabel>
                                                </CFormGroup>
                                            </CTooltip>
                                        </CCallout>
                                    </CCol>
                                    <CCol md={9}>
                                        {exit.exit_type === 'letter' &&
                                            <CRow>
                                                <CCol>
                                                    {helps.isEmpty(exit.letter.letter_name) && !exit.exit_completed &&
                                                        <div className='mb-3'>
                                                            <CLabel htmlFor="input-letter">Đơn thôi việc:<Hint content={'P.NV'} /></CLabel>
                                                            <Dropzone
                                                                onDrop={(upload_files) => { this.uploadLetter(upload_files, 'letter') }}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className="container">
                                                                        <div {...getRootProps({ className: 'dropzone' })} style={{ padding: '10px' }}>
                                                                            <input {...getInputProps()} id='input-letter' />
                                                                            <p>Chọn hoặc kéo thả File tài liệu tại đây.</p>
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    }
                                                    {exit.letter && !helps.isEmpty(exit.letter) && !helps.isEmpty(exit.letter.letter_name) &&
                                                        <>
                                                            <div className='mb-3'>
                                                                {configs.img_ext.includes(helps.getExt(exit.letter.letter_name)) &&
                                                                    <CPopover content={<CImg src={exit.letter.letter_url} width={150} thumbnail={true}></CImg>}>
                                                                        <CImg src={exit.letter.letter_url} width={50} height={50} style={{ maxHeight: '50px' }} thumbnail={true}></CImg>
                                                                    </CPopover>
                                                                }
                                                                {!configs.img_ext.includes(helps.getExt(exit.letter.letter_name)) && <CIcon name="cil-file" size={'3xl'}></CIcon>}
                                                                <CLink className={'ml-2'} href={exit.letter.letter_url} target='_blank'>{exit.letter.letter_name}</CLink>
                                                                <CTooltip content={'Đổi file khác'}>
                                                                    <CButton
                                                                        type="button"
                                                                        color="white"
                                                                        size="sm"
                                                                        disabled={exit.letter.letter_upload_by !== user.id || exit.exit_completed}
                                                                        onClick={(e) => { this.removeLetter(e) }}

                                                                    >
                                                                        <CIcon name="cil-x" className='text-danger'></CIcon>
                                                                    </CButton>
                                                                </CTooltip>
                                                            </div>
                                                        </>
                                                    }
                                                    {!helps.isEmpty(exit.letter) && <CRow className='mb-3'>
                                                        <CCol>
                                                            <CLabel htmlFor="input-receipt_date">Ngày tiếp nhận đơn:<Hint content={'P.NV'} /></CLabel>
                                                            <CInputGroup>
                                                                <DateTimePicker
                                                                    value={exit.letter.letter_receipt_date}
                                                                    format={'dd/MM/yyyy'}
                                                                    onChange={(value) => { this.props.handleChangeDate(value, 'letter_receipt_date') }}
                                                                    disabled={exit.exit_completed}
                                                                />
                                                                {exit.letter.letter_receipt_date && <CInputGroupAppend>
                                                                    <CInputGroupText>
                                                                        Thời hạn đơn: <b> {helps.formatDate_from_Timestamp(exit.letter.letter_receipt_date_deadline)}</b>
                                                                    </CInputGroupText>
                                                                </CInputGroupAppend>}
                                                                <small className="text-danger">{this.state.errors['letter_receipt_date']}</small>
                                                            </CInputGroup>
                                                        </CCol>
                                                    </CRow>}
                                                </CCol>
                                                <CCol>
                                                    {!helps.isEmpty(exit.letter) && <>
                                                        <CRow className='mb-2'>
                                                            <CCol>
                                                                <CTooltip content={'Đã phỏng vấn Thôi việc đối với nhân sự.'}>
                                                                    <CFormGroup variant='custom-checkbox' inline>
                                                                        <CInputCheckbox

                                                                            size={'lg'}
                                                                            id='input-exit_interviewed'
                                                                            name='exit_interviewed'
                                                                            value={1}
                                                                            className={'cursor-pointer'}
                                                                            checked={this.state.exit_interviewed}
                                                                            onChange={(e) => { this.props.handleCheckboxTF('exit_interviewed', 'letter') }}
                                                                            disabled={exit.exit_completed}
                                                                        />
                                                                        <CLabel htmlFor="input-exit_interviewed">Đã phỏng vấn thôi việc<Hint content={'P.NV / Quản lý trực tiếp'} /></CLabel>
                                                                        <small className="text-danger">{this.state.errors['exit_interviewed']}</small>
                                                                    </CFormGroup>
                                                                </CTooltip>
                                                            </CCol>
                                                        </CRow>
                                                        <CRow className='mb-2'>
                                                            <CCol>
                                                                <CTooltip content={'Duyệt cho thôi việc trước thời hạn đơn.'}>
                                                                    <CFormGroup variant='custom-checkbox' inline>
                                                                        <CInputCheckbox
                                                                            size={'lg'}
                                                                            id='input-exit_interviewed'
                                                                            name='exit_interviewed'
                                                                            value={1}
                                                                            className={'cursor-pointer'}
                                                                            checked={this.state.exit_interviewed}
                                                                            onChange={(e) => { this.props.handleCheckboxTF('exit_interviewed', 'letter') }}
                                                                            disabled={exit.exit_completed}
                                                                        />
                                                                        <CLabel htmlFor="input-exit_interviewed">Xét duyệt cho thôi việc trước thời hạn đơn.<Hint content={'P.NV / Quản lý trực tiếp'} /></CLabel>
                                                                        <small className="text-danger">{this.state.errors['exit_interviewed']}</small>
                                                                    </CFormGroup>
                                                                </CTooltip>
                                                            </CCol>
                                                        </CRow>
                                                        <CRow className='mb-2'>
                                                            <CCol>
                                                                <CLabel htmlFor="input-exit_date">Ngày thôi việc:</CLabel>
                                                                <DateTimePicker
                                                                    id='input-exit_date'
                                                                    value={exit.exit_date}
                                                                    format={'dd/MM/yyyy'}
                                                                    className='ml-2'
                                                                    onChange={(value) => { this.props.handleChangeDate(value, 'exit_date') }}
                                                                    disabled={exit.exit_completed}
                                                                />
                                                            </CCol>
                                                        </CRow>
                                                    </>}
                                                </CCol>
                                            </CRow>
                                        }
                                        {exit.exit_type === 'layoff' && <CRow>
                                            <CCol>
                                                {helps.isEmpty(exit.letter.suspension_decision_name) && !exit.exit_completed &&
                                                    <>
                                                        <div className='mb-3'>
                                                            <CLabel htmlFor="input-suspension_decision">QĐ đình chỉ công tác:<Hint content={'P.NV'} /></CLabel>
                                                            <Dropzone
                                                                onDrop={(upload_files) => { this.uploadLetter(upload_files, 'suspension_decision') }}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className="container">
                                                                        <div {...getRootProps({ className: 'dropzone' })} style={{ padding: '10px' }}>
                                                                            <input {...getInputProps()} id='input-suspension_decision' />
                                                                            <p>Chọn hoặc kéo thả File tài liệu tại đây.</p>
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </>
                                                }
                                                {!helps.isEmpty(exit.letter.suspension_decision_name) &&
                                                    <>
                                                        <div className='mb-3'>
                                                            {configs.img_ext.includes(helps.getExt(exit.letter.suspension_decision_name)) &&
                                                                <CPopover content={<CImg src={exit.letter.suspension_decision_url} width={150} thumbnail={true}></CImg>}>
                                                                    <CImg src={exit.letter.suspension_decision_url} width={50} height={50} style={{ maxHeight: '50px' }} thumbnail={true}></CImg>
                                                                </CPopover>
                                                            }
                                                            {!configs.img_ext.includes(helps.getExt(exit.letter.suspension_decision_name)) && <CIcon name="cil-file" size={'3xl'}></CIcon>}
                                                            <CLink className={'ml-2'} href={exit.letter.suspension_decision_url} target='_blank'>{exit.letter.suspension_decision_name}</CLink>
                                                            <CTooltip content={'Đổi file khác'}>
                                                                <CButton
                                                                    type="button"
                                                                    color="white"
                                                                    size="sm"
                                                                    disabled={exit.letter.suspension_decision_upload_by !== user.id || exit.exit_completed}
                                                                    onClick={(e) => { this.removeLetter(e, 'suspension_decision') }}
                                                                >
                                                                    <CIcon name="cil-x" className='text-danger'></CIcon>
                                                                </CButton>
                                                            </CTooltip>
                                                        </div>
                                                    </>
                                                }
                                                <div className='mb-3'>
                                                    <CLabel htmlFor="input-suspension_decision">Ngày QĐ đình chỉ:</CLabel>
                                                    <DateTimePicker
                                                        id='input-suspension_decision'
                                                        value={exit.letter.suspension_decision_date}
                                                        format={'dd/MM/yyyy'}
                                                        className='ml-2'
                                                        onChange={(value) => { this.props.handleChangeDate(value, 'suspension_decision_date', 'letter') }}
                                                        disabled={exit.exit_completed}
                                                    />
                                                </div>
                                            </CCol>
                                            <CCol>
                                                {helps.isEmpty(exit.letter.termination_decision_name) && !exit.exit_completed &&
                                                    <>
                                                        <div className='mb-3'>
                                                            <CLabel htmlFor="input-termination_decision">QĐ cho thôi việc:<Hint content={'BGĐ'} /></CLabel>
                                                            <Dropzone
                                                                onDrop={(upload_files) => { this.uploadLetter(upload_files, 'termination_decision') }}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className="container">
                                                                        <div {...getRootProps({ className: 'dropzone' })} style={{ padding: '10px' }}>
                                                                            <input {...getInputProps()} id='input-termination_decision' />
                                                                            <p>Chọn hoặc kéo thả File tài liệu tại đây.</p>
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </>
                                                }
                                                {!helps.isEmpty(exit.letter.termination_decision_name) &&
                                                    <>
                                                        <div className='mb-3'>
                                                            {configs.img_ext.includes(helps.getExt(exit.letter.termination_decision_name)) &&
                                                                <CPopover content={<CImg src={exit.letter.termination_decision_url} width={150} thumbnail={true}></CImg>}>
                                                                    <CImg src={exit.letter.termination_decision_url} width={50} height={50} style={{ maxHeight: '50px' }} thumbnail={true}></CImg>
                                                                </CPopover>
                                                            }
                                                            {!configs.img_ext.includes(helps.getExt(exit.letter.termination_decision_name)) && <CIcon name="cil-file" size={'3xl'}></CIcon>}
                                                            <CLink className={'ml-2'} href={exit.letter.termination_decision_url} target='_blank'>{exit.letter.termination_decision_name}</CLink>
                                                            <CTooltip content={'Đổi file khác'}>
                                                                <CButton
                                                                    type="button"
                                                                    color="white"
                                                                    size="sm"
                                                                    disabled={exit.letter.termination_decision_upload_by !== user.id || exit.exit_completed}
                                                                    onClick={(e) => { this.removeLetter(e, 'termination_decision') }}
                                                                >
                                                                    <CIcon name="cil-x" className='text-danger'></CIcon>
                                                                </CButton>
                                                            </CTooltip>
                                                        </div>
                                                    </>
                                                }
                                                <div className='mb-3'>
                                                    <CLabel htmlFor="input-exit_date">Ngày cho thôi việc:</CLabel>
                                                    <DateTimePicker
                                                        id='input-exit_date'
                                                        value={exit.exit_date}
                                                        format={'dd/MM/yyyy'}
                                                        className='ml-2'
                                                        onChange={(value) => { this.props.handleChangeDate(value, 'exit_date') }}
                                                        disabled={exit.exit_completed}
                                                    />
                                                </div>
                                            </CCol>
                                        </CRow>}
                                        {exit.exit_type === 'quit' && <CRow className='mb-3'>
                                            <CCol>
                                                <CRow className='mb-2'>
                                                    <CCol>
                                                        <CLabel htmlFor="input-exit_date">Ngày bỏ việc ngang:</CLabel>
                                                        <DateTimePicker
                                                            id='input-exit_date'
                                                            value={exit.exit_date}
                                                            format={'dd/MM/yyyy'}
                                                            className='ml-2'
                                                            onChange={(value) => { this.props.handleChangeDate(value, 'exit_date') }}
                                                            disabled={exit.exit_completed}
                                                        />
                                                    </CCol>
                                                </CRow>
                                                <CRow className='mb-2'>
                                                    <CCol>
                                                        <CLabel htmlFor="input-quit_note">Ghi chú:<Hint content='Ghi chú về bỏ việc ngang' /></CLabel>
                                                        <CTextarea
                                                            id='input-quit_note'
                                                            name='quit_note'
                                                            defaultValue={exit.letter.quit_note}
                                                            rows={5}
                                                            onBlur={(e) => { this.props.handleChangeExitField('letter', e) }}
                                                            disabled={exit.exit_completed}
                                                        ></CTextarea>
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                        </CRow>}
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCollapse>
                    </CCard>
                </CCol>
            </CRow >
        )
    }
}
export default ExitLetter;

