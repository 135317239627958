import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CButton,
    CDataTable,
    CSelect,
    CBadge,
    CInput,
    CPopover,
    CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import Loading from 'src/containers/_loading';
import Select from 'react-select';
import { UploadBBContainer } from 'src/containers/assets/UploadBBContainer';

class QTTs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            item_id: '',
            store_id: '',
            item: {},
            from_to: '',
            asset_date: '',
            attachment_status: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [[field]]: selectedOptions });
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        if (field === 'asset_date') {
            fields[field] = new Date(fields[field]);
        }
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    render() {
        const { loading, assets, asset_items, users, stores, items, projects, vendors, employees, housings } = this.props;
        if (loading) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(items)) {
            return (
                <></>
            );
        }
        var _asset_items = [];
        if (asset_items && asset_items.length > 0) {
            asset_items.forEach((asset_item, index) => {
                let asset = helps.getItemFromArr(assets, asset_item.asset_id);
                asset_item['asset'] = asset;
                asset_item = Object.assign(asset_item, asset);
                asset_item['create_by_alias'] = helps.getUserAlias(users, asset.create_by);
                switch (asset_item.state) {
                    case 'in':
                        asset_item['state_in'] = asset_item.quantity;
                        asset_item['state_out'] = '';
                        break;
                    case 'out':
                        asset_item['state_out'] = asset_item.quantity;
                        asset_item['state_in'] = '';
                        break;
                    default:
                }
                let item = helps.getItemFromArr(items, asset_item.item_id);
                asset_item['item'] = item;

                let store = helps.getItemFromArr(stores, asset.store_id);
                asset_item['store_name'] = store.name;

                asset_item['object_name'] = '';
                if (!helps.isEmpty(asset.project_id)) {
                    let project = helps.getItemFromArr(projects, asset.project_id);
                    asset_item['object_name'] = project.name;
                    asset_item['project'] = project;
                    asset_item['object'] = 'projects';
                    asset_item['object_id'] = project.id;
                }
                if (!helps.isEmpty(asset.employee_id)) {
                    let employee = helps.getItemFromArr(employees, asset.employee_id, 'employee_id');
                    asset_item['object_name'] = employee.fullname;
                    asset_item['employee'] = employee;
                    asset_item['object'] = 'employees';
                    asset_item['object_id'] = employee.employee_id;
                }
                if (!helps.isEmpty(asset.vendor_id)) {
                    let vendor = helps.getItemFromArr(vendors, asset.vendor_id);
                    asset_item['object_name'] = vendor.name;
                    asset_item['vendor'] = vendor;
                    asset_item['object'] = 'vendors';
                    asset_item['object_id'] = vendor.id;
                }
                if (!helps.isEmpty(asset.housing_id)) {
                    let housing = helps.getItemFromArr(housings, asset.vendor_id);
                    asset_item['object_name'] = housing.name;
                    asset_item['housing'] = housing;
                    asset_item['object'] = 'housings';
                    asset_item['object_id'] = housing.id;
                }
                asset_item['index'] = index + 1;
                _asset_items[index] = asset_item;
            });
        }

        const duplicates = _asset_items.reduce((acc, item) => {
            let newItem = acc.find((i) => i.asset_id === item.asset_id);

            if (newItem) {
                newItem.count += 1;
                if (newItem.count === 1) {
                    newItem.start_count = item.index;
                }
            } else {
                acc.push({ asset_id: item.asset_id, count: 1, start_count: item.index });
            }

            return acc;
        }, []);
        _asset_items.map((asset_item) => {
            let _duplicate = helps.getItemFromArr(duplicates, asset_item.asset_id, 'asset_id');
            asset_item['count'] = _duplicate.count;
            asset_item['start_count'] = _duplicate.start_count;
        })

        if (!helps.isEmpty(this.state.item)) {
            _asset_items = _asset_items.filter((asset_item) => {
                return asset_item.item_id == this.state.item.value;
            });
        }
        if (!helps.isEmpty(this.state.store_id)) {
            _asset_items = _asset_items.filter((asset_item) => {
                return asset_item.store_id == this.state.store_id;
            });
        }
        if (!helps.isEmpty(this.state.from_to)) {
            _asset_items = _asset_items.filter((asset_item) => {
                return asset_item.from_to == this.state.from_to;
            });
        }

        if (!helps.isEmpty(this.state.attachment_status)) {
            if (parseInt(this.state.attachment_status) === 1) {
                _asset_items = _asset_items.filter((asset_item) => {
                    return helps.isEmpty(asset_item.attachment);
                });
            } else if (parseInt(this.state.attachment_status) === 2) {
                _asset_items = _asset_items.filter((asset_item) => {
                    return !helps.isEmpty(asset_item.attachment);
                });
            }
        }

        if (this.state.asset_date) {
            _asset_items = _asset_items.filter((asset_item) => {
                console.log(new Date(asset_item.asset_date), new Date(this.state.asset_date))
                return new Date(asset_item.asset_date).getTime() == new Date(this.state.asset_date).getTime();
            });
        }
        var item_options = [];
        if (items && items.length > 0) {
            items.map((item) => {
                item_options.push({ value: item.id, label: item.code + ' - ' + item.name });
            });
        }

        var asset_date_val = '';
        let asset_date = this.state.asset_date;
        if (!helps.isEmpty(asset_date) && typeof asset_date === 'object') {
            asset_date_val = helps.formatDate_for_input(asset_date.toDate(), 'YYYY-MM-DD');
        } else {
            asset_date_val = helps.formatDate_for_input(asset_date, 'YYYY-MM-DD');
        }
        //console.log(_asset_items)
        const fields = [
            { key: 'asset_date', label: 'Ngày', _style: { width: '10%' } },
            { key: 'attachment', label: 'Biên Bản', },
            { key: 'index', label: '#', _style: { width: '10px' }, filter: false, sorter: false },
            { key: 'store_name', label: 'Kho', },
            { key: 'item_name', label: 'Tài Sản', _style: { width: '15%' } },
            { key: 'from_to', label: 'Đối tượng', filter: false },
            { key: 'object_name', label: 'Từ/Đến', _style: { width: '10%' } },
            { key: 'state_in', label: 'Nhập / Thu Hồi', _classes: 'font-weight-bold', _style: { textAlign: 'center' }, filter: false },
            { key: 'state_out', label: 'Xuất / Bàn Giao', _classes: 'font-weight-bold', _style: { textAlign: 'center' }, filter: false },
            { key: 'inventory', label: 'Tồn Kho', _classes: 'font-weight-bold', _style: { textAlign: 'center' }, filter: false },
            { key: 'create_by_alias', label: 'Bởi', _style: { textAlign: 'right', width: '10%' }, filter: false },
            { key: 'actions', label: 'Thao Tác', _style: { width: '10%', textAlign: 'center' }, filter: false, sorter: false },
        ]

        return (
            <CDataTable
                key={'assets_' + _asset_items.length}
                items={_asset_items}
                fields={fields}
                columnFilter
                tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                itemsPerPageSelect={configs.itemsPerPageSelect}
                itemsPerPage={50}
                hover
                sorter
                pagination
                _style={{ minHeight: '500px' }}
                responsive={true}
                columnFilterSlot={{
                    asset_date: (
                        <>
                            <CInput
                                type='date'
                                id='input-asset_date'
                                name='asset_date'
                                className={'form-control-sm'}
                                value={asset_date_val}
                                onChange={(e) => { this.handleChange('asset_date', e) }}
                            />
                        </>
                    ),
                    attachment: (
                        <>
                            <CSelect
                                id='input-attachment_status'
                                name='attachment_status'
                                className={'form-control-sm'}
                                value={this.state.attachment_status}
                                onChange={(e) => { this.handleChange('attachment_status', e) }}
                            >
                                <option value={''}> - Tất cả - </option>
                                <option value={1}> Chưa tải BB lên</option>
                                <option value={2}> Đã tải BB lên</option>
                            </CSelect>
                        </>
                    ),
                    item_name: (
                        <>
                            <Select
                                id="input-items"
                                placeholder="Tài sản ..."
                                options={item_options}
                                value={this.state.item}
                                isClearable={true}
                                onChange={(choice) => { this.handleChangeSelect(choice, 'item') }}
                            />
                        </>
                    ),
                    store_name: (
                        <>
                            <CSelect
                                name="store_id"
                                id="input-store_id"
                                className="form-control-sm"
                                value={this.state.store_id}
                                onChange={(e) => { this.handleChange('store_id', e) }}
                            >
                                <option value="">-Tất cả-</option>
                                {!helps.isEmpty(stores) && stores.map((store) => {
                                    return (
                                        <option key={store.id} value={store.id}>{store.name}</option>
                                    );
                                })}
                            </CSelect>
                        </>
                    ),
                    from_to: (
                        <>
                            <CSelect
                                name="from_to"
                                id="input-from_to"
                                className="form-control-sm"
                                value={this.state.from_to}
                                onChange={(e) => { this.handleChange('from_to', e) }}
                            >
                                <option value="">-Tất cả-</option>
                                <option value={'employee'}>Nhân sự</option>
                                <option value={'project'}>Mục tiêu</option>
                                <option value={'vendor'}>Nhà cung cấp</option>
                            </CSelect>
                        </>
                    ),
                }}
                scopedSlots={{
                    'asset_date': (item) => {
                        let rowSpan = 1;
                        if (item.index === item.start_count) {
                            rowSpan = item.count;
                        } else {
                            return (<></>);
                        }
                        return (
                            <td className="py-2 font-weight-bold align-middle" rowSpan={rowSpan} title={'ASSET ID: ' + item.asset_id}>
                                {helps.formatDate_from_Timestamp(item.asset_date)}
                                <div><small className='font-italic text-muted'>{item.asset_id}</small></div>
                            </td>
                        )
                    },
                    'attachment': (item) => {
                        let rowSpan = 1;
                        if (item.index === item.start_count) {
                            rowSpan = item.count;
                        } else {
                            return (<></>);
                        }
                        return (
                            <td className="py-2 align-middle text-center" rowSpan={rowSpan}>
                                {!helps.isEmpty(item.attachment) && item.attachment.map(file => {
                                    return (
                                        <CPopover content={file.name}>
                                            <CLink href={file.url} target='_blank'>{file.name.substring(0, 10) + ' ...'}</CLink>
                                        </CPopover>
                                    );
                                })}
                                {helps.isEmpty(item.attachment) && <UploadBBContainer {...this.props} asset_item={item} />}
                            </td>
                        )
                    },
                    'index': (item, index) => {
                        return (
                            <td className="py-2">
                                {index + 1}
                            </td>
                        )
                    },
                    'item_name': (item) => {
                        return (
                            <td className="py-2 font-weight-bold">
                                <CLink className="text-dark" onClick={() => { this.props.history.push(`/assets/items/view/${item.item_id}`) }}>
                                    {item.item.code} - {item.item.name}
                                </CLink>
                            </td>
                        )
                    },
                    'state_in': (item) => {
                        return (
                            <td className="py-2 text-center font-weight-bold" title={'Nhập/Thu hồi: ' + item.state_in}>
                                {item.state_in}
                            </td>
                        )
                    },
                    'state_out': (item) => {
                        return (
                            <td className="py-2 text-center font-weight-bold" title={'Xuất/Bàn giao: ' + item.state_out}>
                                {item.state_out}
                            </td>
                        )
                    },
                    'inventory': (item) => {
                        let inventory_color = 'success';
                        let store_id = item.store_id;
                        let current_inventory = helps.getItemFromArr(item.item.inventory, store_id, 'store_id');
                        if (current_inventory.current_inventory <= 0) {
                            inventory_color = 'danger';
                        } else if (current_inventory.current_inventory <= configs.inventory_alert) {
                            inventory_color = 'warning';
                        } else {
                            inventory_color = 'success';
                        }

                        return (
                            <td className="py-2 text-center font-weight-bold" title={'Tồn kho: ' + current_inventory.current_inventory}>
                                <CPopover content={<>{'Tồn kho: ' + current_inventory.current_inventory}</>}>
                                    <CBadge color={inventory_color} className={'px-2 py-1'}>{current_inventory.current_inventory}</CBadge>
                                </CPopover>
                            </td>
                        )
                    },
                    'from_to': (item) => {
                        var from_to = '';
                        var color = 'primary';
                        let linkto = '';
                        switch (item.from_to) {
                            case 'project':
                                from_to = 'Mục tiêu';
                                color = 'success';
                                linkto = '/projects/view/' + item.project_id;
                                break;
                            case 'employee':
                                from_to = 'Nhân sự';
                                color = 'warning';
                                linkto = '/employees/view/' + item.employee_id;
                                break;
                            case 'housing':
                                from_to = 'Nhà Đội';
                                color = 'info';
                                linkto = '/housings/view/' + item.housing_id;
                                break;
                            case 'put':
                                from_to = 'Hủy / Lưu Kho';
                                color = 'danger';
                                linkto = '';
                                break;
                            default:
                                from_to = 'Nhà cung cấp';
                                color = 'primary';
                                linkto = '/vendors/view/' + item.vendor_id;
                        }

                        return (
                            <td className="py-2 align-middle">
                                <CBadge color={color}>{from_to}</CBadge>
                            </td>
                        )
                    },
                    'object_name': (item) => {
                        let linkto = '';
                        switch (item.from_to) {
                            case 'project':
                                linkto = '/projects/view/' + item.project_id;
                                break;
                            case 'employee':
                                linkto = '/employees/view/' + item.employee_id;
                                break;
                            case 'housing':
                                linkto = '/housings/view/' + item.housing_id;
                                break;
                            case 'put':
                                linkto = '';
                                break;
                            default:
                                linkto = '/vendors/view/' + item.vendor_id;
                        }

                        return (
                            <td className="py-2 align-middle">
                                {item.from_to !== 'put' &&
                                    <div>
                                        <CLink to={linkto}>
                                            {!helps.isEmpty(item.object_name) && <span title={item.object_name}>{item.object_name}</span>}
                                        </CLink>

                                    </div>
                                }
                            </td>
                        )
                    },
                    'create_by_alias': (item) => {
                        let rowSpan = 1;
                        if (item.index === item.start_count) {
                            rowSpan = item.count;
                        } else {
                            return (<></>);
                        }
                        return (
                            <td className="py-2 text-right align-middle" rowSpan={rowSpan}>
                                <small>
                                    {item.create_by_alias}<br />
                                    {helps.formatDateTime_from_Timestamp(item.create_date)}
                                </small>
                            </td>
                        )
                    },
                    'actions':
                        (item) => {
                            let rowSpan = 1;
                            if (item.index === item.start_count) {
                                rowSpan = item.count;
                            } else {
                                return (<></>);
                            }
                            let edit_link = '';
                            if (item.state === 'in') {
                                edit_link = `/assets/QTT/in/${item.id}`;
                            } else {
                                edit_link = `/assets/QTT/out/${item.id}`;
                            }
                            return (
                                <td className="py-2 align-middle text-center" rowSpan={rowSpan}>
                                    <ThePermission {... this.props} component="assets" action="edit">
                                        <CButton
                                            color="primary"
                                            size="sm"
                                            title="Cập nhật thông tin"
                                            disabled
                                            onClick={() => { this.props.history.push(edit_link) }}
                                        >
                                            <CIcon name="cil-pen-nib"></CIcon>
                                        </CButton>
                                    </ThePermission>
                                </td>
                            )
                        },
                }}
            />

        )
    }
}


export default QTTs;
