import React, { Component } from 'react'
import {
    CCol,
    CRow,
} from '@coreui/react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';

class Project extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    componentDidMount() {

    }


    render() {
        const { project } = this.props;
        if (helps.isEmpty(project)) {
            return (
                <Loading></Loading>
            );
        }
        //console.log(project)
        var create_date = '';
        if (project.create_date) {
            create_date = helps.formatDateTime_from_Timestamp(project.create_date);
        }
        let deploy_date = project.deploy_date ? helps.formatDateTime_from_Timestamp(project.deploy_date) : '';

        return (
            <CRow className="mt-3">
                <CCol xl={12}>
                    <CRow>
                        <CCol>
                            <hr />
                            <div className="mb-1 font-italic text-muted">Phụ trách Theo dõi, Giám sát:</div>
                            <ul className="mb-1 font-weight-bold text-muted">
                                {!helps.isEmpty(project.assigns) && project.assigns.length > 0 && project.assigns.map((assign) => {
                                    return (
                                        <li key={assign.value}>{assign.label}</li>
                                    )
                                })
                                }
                            </ul>
                            <hr />
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Ngày Triển Khai: <b>{deploy_date}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Ngày Khởi Tạo: <b>{create_date}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Tạo bởi: <b>{project.create_by_alias}</b>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        )
    }
}
export default Project;
