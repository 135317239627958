import React, { Component } from "react";
import { read, utils, writeFile } from 'xlsx';
import {
    CButton, CCol, CRow,
    CTooltip,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { helps } from "src/_helpers";
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';

export default class ProjectTimeKeepingExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            cols: [],
        };
        this.handleExport = this.handleExport.bind(this);
    }

    componentDidMount() {
        this.setData();
    }

    setData() {
        var data = [];
        var header = [];
        let subheader = [];
        const { checks, month, _employees } = this.props;
        if (!helps.isEmpty(_employees) && _employees.length > 0) {
            _employees.map((employee, index_employee) => {
                var item = [];
                header[0] = '#';
                header[1] = 'Họ & Tên';
                header[2] = 'MSNV';
                item[0] = index_employee + 1;
                item[1] = employee.fullname;
                item[2] = employee.employee_id;
                var employee_checks = checks.filter(check => check.employee_id === employee.employee_id);
                var firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
                let today = month ? month : new Date();
                var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                var total_duration = 0;
                for (let i = 1; i <= lastDay.getDate(); i++) {
                    let cur_day = new Date(firstDay.getFullYear(), firstDay.getMonth(), i);
                    var day_checks = [];
                    day_checks = employee_checks.filter((check) => {
                        let check_date = check.check_date;
                        if (check_date.getFullYear() === cur_day.getFullYear() && check_date.getMonth() === cur_day.getMonth() && check_date.getDate() === cur_day.getDate()) return true;
                        else return false;
                    });
                    var cur_duration = '';
                    if (!helps.isEmpty(day_checks) && day_checks.length > 0) {
                        day_checks.map((check) => {
                            var duration = check.check_duration / 60;
                            if (!Number.isInteger(duration)) {
                                duration = parseFloat(duration).toFixed(2);
                            } else {
                                duration = parseInt(duration);
                            }
                            if (check.is_overtime) {
                                cur_duration = cur_duration + '(' + duration + ')';
                            } else {
                                cur_duration = cur_duration + duration;
                            }
                        })
                    }
                    let _index = i + 2;
                    header[_index] = i;
                    item[_index] = cur_duration;
                }
                data.push(item);
            });
        }
        let _output = [];
        _output[0] = header;
        _output = _output.concat(data);
        //console.log(_output)
        this.setState({ data: _output });
    }

    make_cols = refstr => {
        let o = [], C = utils.decode_range(refstr).e.c + 1;
        for (var i = 0; i < C; ++i) o[i] = { name: utils.encode_col(i), key: i }
        return o;
    };

    loadFile(url, callback) {
        PizZipUtils.getBinaryContent(url, callback);
    }

    async handleExport() {
        const { project } = this.props;
        let today = new Date();
        let tpl_url = '/templates/tpl_bang_cham_cong.docx';
        this.loadFile(
            tpl_url,
            (error, content) => {
                if (error) {
                    throw error;
                }
                var zip = new PizZip(content);
                var doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });
                doc.setData({
                    table1: this.state.data,
                    project_name: project.name,
                    export_date: "ngày " + today.getDate() + " tháng " + (today.getMonth() + 1) + " năm " + today.getFullYear(),
                });
                try {
                    // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                    doc.render();
                } catch (error) {
                    // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                    function replaceErrors(key, value) {
                        if (value instanceof Error) {
                            return Object.getOwnPropertyNames(value).reduce(function (
                                error,
                                key
                            ) {
                                error[key] = value[key];
                                return error;
                            },
                                {});
                        }
                        return value;
                    }
                    //console.log(JSON.stringify({ error: error }, replaceErrors));

                    if (error.properties && error.properties.errors instanceof Array) {
                        const errorMessages = error.properties.errors
                            .map(function (error) {
                                return error.properties.explanation;
                            })
                            .join('\n');
                        console.log('errorMessages', errorMessages);
                        // errorMessages is a humanly readable message looking like this :
                        // 'The tag beginning with "foobar" is unopened'
                    }
                    throw error;
                }
                var out = doc.getZip().generate({
                    type: 'blob',
                    mimeType:
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                }); //Output the document using Data-URI
                var file_name = 'SEKIN_BẢNG_CHẤM_CÔNG_' + project.name + '.docx';

                saveAs(out, file_name);
            }
        );
    }

    render() {
        return (
            <CRow>
                <CCol xl={12} md={12}>
                    <CTooltip content={'Export to Excel'}>
                        <CButton
                            color="info"
                            size="sm"
                            title="Export"
                            className="ml-1"
                            onClick={(e) => { this.handleExport(e) }}
                        >
                            <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                            Export
                        </CButton>
                    </CTooltip>
                </CCol>
            </CRow>
        );
    }
}