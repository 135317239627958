import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CCollapse,
    CLink,
    CFormGroup,
    CInputRadio,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import ContractsForm from './ContractsForm';
import Loading from 'src/containers/_loading';


class Contracts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            showAddForm: false,
            edit_forms: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancelForm = this.handleCancelForm.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleActive = this.handleActive.bind(this);
    }

    componentDidMount() {
    }

    handleCancelForm() {
        this.setState({ showAddForm: false });
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
    }

    handleDelete(e, contract) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Hợp Đồng: ' + contract.code + ' ?');
        if (_confirm) {
            this.props._delete(contract);
        }
        return false;
    }

    handleSubmit(contract) {
        if (helps.isEmpty(contract.id)) {
            this.props.add(contract);
            this.handleCancelForm();
        } else {
            this.props.update(contract);
            this.toggleEditForms(contract);
        }
    }

    handleActive(e) {
        const { contracts } = this.props;
        let cur_active_contracts = contracts.filter(contract => contract.active > 0);
        if (!helps.isEmpty(cur_active_contracts) && cur_active_contracts.length > 0) {
            cur_active_contracts.map((contract) => {
                contract['active'] = 0;
                this.props.update(contract);
            });
        }
        var active_contract = helps.getItemFromArr(contracts, e.target.value);
        if (active_contract) {
            active_contract['active'] = 1;
            this.props.update(active_contract);
        }
    }

    toggleEditForms = (item) => {
        let forms = this.state.edit_forms;
        const position = forms.indexOf(item.id);
        let newForms = forms.slice();
        if (position !== -1) {
            newForms.splice(position, 1)
        } else {
            newForms = [...forms, item.id]
        }
        this.setState({ edit_forms: newForms });
    }

    render() {
        const { contracts, user } = this.props;
        console.log(typeof contracts)
        if (contracts && contracts.length > 0) {
            contracts.map((contract) => {
                contract['_contract_time'] = helps.formatDateTime_from_Timestamp(contract.contract_time);
                contract['is_author'] = false;
                if (user.id == contract.added_by) {
                    contract['is_author'] = true;
                }
            })
        }
        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol xl={12} md={12} className="text-right">
                            <CButton type="button" color="primary" size="sm" onClick={() => { this.setState({ showAddForm: !this.state.showAddForm }) }}>
                                <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                Thêm Hợp Đồng
                            </CButton>
                        </CCol>
                    </CRow>
                    {this.state.showAddForm && <ContractsForm
                        {...this.props}
                        contracts={contracts}
                        handleSubmit={this.handleSubmit}
                        handleCancelForm={() => { this.handleCancelForm() }} />
                    }
                    <hr />
                    <div className='contracts-container'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Hiệu Lực</th>
                                    <th>Số Hợp Đồng</th>
                                    <th>Ngày Ký</th>
                                    <th>Thời Hạn Hợp Đồng</th>
                                    <th>Giá Trị HĐ</th>
                                    <th>Đính Kèm</th>
                                    <th>Ghi Chú</th>
                                    <th>Thêm Bởi</th>
                                    <th className='text-center'>Thao Tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {helps.isEmpty(contracts) && <tr><td colSpan={8}><h5 className='text-secondary text-center mt-5'>Không tìm thấy Hợp Đồng nào!</h5></td></tr>}
                                {this.props.loading && <tr><td colSpan={8}><Loading></Loading></td></tr>}
                                {contracts && contracts.map((contract) => {
                                    return (
                                        <>
                                            <tr key={contract.id}>
                                                <td>
                                                    <CFormGroup variant="custom-radio" className='mb-2' inline>
                                                        <CInputRadio
                                                            id={"active_" + contract.id}
                                                            value={contract.id}
                                                            name='active'
                                                            className='cursor-pointer'
                                                            defaultChecked={contract.active == 1}
                                                            onChange={(e) => { this.handleActive(e) }}
                                                        />
                                                    </CFormGroup>
                                                </td>
                                                <td><b>{contract.code}</b></td>
                                                <td>{helps.formatDate_from_Timestamp(contract.sign_date)}</td>
                                                <td>{helps.formatDate_from_Timestamp(contract.start_date) + ' - ' + helps.formatDate_from_Timestamp(contract.end_date)}</td>
                                                <td><b>{helps.formatCurrency(contract.contract_value)}</b></td>
                                                <td>
                                                    {!helps.isEmpty(contract.attachment) && contract.attachment.map(file => {
                                                        return (
                                                            <li>
                                                                <CLink href={file.url} target='_blank'>{file.name}</CLink>
                                                            </li>
                                                        );
                                                    })}
                                                </td>
                                                <td>{contract.note}</td>
                                                <td>
                                                    <span className='text-muted'>
                                                        <span className="font-weight-bold">{contract.added_by_alias}</span>
                                                        <div>
                                                            {helps.formatDateTime_from_Timestamp(contract.added_time)}
                                                        </div>
                                                    </span>
                                                </td>
                                                <td className='text-center'>
                                                    <CButton type="button" size="sm" color="primary" title='Sửa' onClick={() => { this.toggleEditForms(contract); }}>
                                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                    </CButton>

                                                    {contract.is_author && <>
                                                        <CButton
                                                            color="danger"
                                                            size="sm"
                                                            title="Xoá"
                                                            className="ml-1"
                                                            onClick={(e) => { this.handleDelete(e, contract) }}
                                                        >
                                                            <CIcon name="cil-trash"></CIcon>
                                                        </CButton>
                                                    </>}
                                                </td>
                                            </tr>
                                            <tr><td colSpan={10}><CCollapse show={this.state.edit_forms.includes(contract.id)} key={"details_" + contract.id} style={{ overflow: 'hidden' }}>
                                                <ContractsForm
                                                    {...this.props}
                                                    contract={contract}
                                                    contracts={contracts}
                                                    handleSubmit={this.handleSubmit}
                                                    toggleEditForms={() => { this.toggleEditForms(contract) }}
                                                    handleCancelForm={() => { this.handleCancelForm() }}
                                                />
                                            </CCollapse></td></tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </CCol>
            </CRow>
        )
    }
}


export default Contracts;
