import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CBadge,
} from '@coreui/react';
import { customerActions, employeeActions, notificationActions, projectActions, taskActions, userActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import Task from 'src/views/tasks/Task';

class TaskContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            task: {},
        }
    }

    componentDidMount() {
        const { customers, users, projects } = this.props;
        this.setState({ object: this.props.object, object_id: this.props.object_id });
        this.getTasks();
        if (!customers) {
            this.props.getCuscomers('all');
        }

        if (!users) {
            this.props.getUsers();
        }

        if (!projects) {
            this.props.getProjects();
        }

        this.getTask();
    }

    async getTask() {
        let id = this.props.match.params.id;
        var { tasks } = this.props;
        if (helps.isEmpty(tasks)) {
            tasks = await this.getTasks();
        }
        let task = helps.getItemFromArr(tasks, id);
        this.setState({ task: task })
        //console.log(tasks);
        return task;
    }

    async getTasks() {
        const { user } = this.props;
        return await this.props.getUserTasks(user, 1000);
    }

    render() {
        const task = this.state.task;
        const priority = helps.getItemFromArr(configs.task_priorities, task.priority)
        return (
            <CCard>
                <CCardHeader>
                    Công Việc: <b>{task.title}</b>
                    <div><small><CBadge color={priority.color}>{priority.label}</CBadge></small></div>
                </CCardHeader>
                <CCardBody>
                    <Task {... this.props} task={task} />
                </CCardBody>
            </CCard>
        );
    }
}

function mapStateToProps(state) {
    const { loading, tasks } = state.taskReducer;
    const { customers } = state.customerReducer;
    const { projects } = state.projectReducer;
    const { employees } = state.employeeReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    return { loading, user, users, tasks, customers, projects, employees };
}

const actionCreators = {
    getUserTasks: taskActions.getUserTasks,
    add: taskActions.add,
    update: taskActions.update,
    _delete: taskActions._delete,
    getProjects: projectActions.getAll,
    getCuscomers: customerActions.getAll,
    getEmployees: employeeActions.getAll,
    getUsers: userActions.getAll,
    getComments: taskActions.getComments,
    addComment: taskActions.addComment,
    updateComment: taskActions.updateComment,
    deleteComment: taskActions.deleteComment,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(TaskContainer);
export { connectedContainer as TaskContainer };