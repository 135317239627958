export const positionConstants = {
    GET_LIST_REQUEST: 'POSITIONS_GET_REQUEST',
    GET_LIST_SUCCESS: 'POSITIONS_GET_SUCCESS',
    GET_LIST_FAILURE: 'POSITIONS_GET_FAILURE',

    ADD_REQUEST: 'POSITIONS_ADD_REQUEST',
    ADD_SUCCESS: 'POSITIONS_ADD_SUCCESS',
    ADD_FAILURE: 'POSITIONS_ADD_FAILURE',

    EDIT_REQUEST: 'POSITIONS_EDIT_REQUEST',
    EDIT_SUCCESS: 'POSITIONS_EDIT_SUCCESS',
    EDIT_FAILURE: 'POSITIONS_EDIT_FAILURE',

    DELETE_REQUEST: 'POSITIONS_DELETE_REQUEST',
    DELETE_SUCCESS: 'POSITIONS_DELETE_SUCCESS',
    DELETE_FAILURE: 'POSITIONS_DELETE_FAILURE',
}