import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CRow,
    CCol,
    CButton,
    CContainer,
    CDropdown,
    CDropdownToggle,
    CWidgetDropdown,
    CDropdownMenu,
    CDropdownItem,
    CLink,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';

class Assets extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    render() {
        const asset_categories = configs.asset_categories;

        return (
            <CRow>
                {
                    (asset_categories &&
                        asset_categories.map((category) => {
                            let color = "primary";

                            if (category.color) {
                                color = category.color;
                            }
                            return (
                                <CCol sm={6} md={3} lg={3} key={"category_key_" + category.id}>
                                    <CWidgetDropdown
                                        color={"gradient-" + color}
                                        header={
                                            <CLink className='text-white' onClick={() => { this.props.history.push(`/assets/${category.id}`) }}>{category.label}</CLink>
                                        }
                                        text={
                                            <div className='mt-2'>

                                            </div>
                                        }
                                        footerSlot={
                                            <CContainer className="mt-1 mb-3">
                                                <CTooltip content={"Quản Lý " + category.label}>
                                                    <CButton
                                                        size="sm"
                                                        className="btn btn-light btn-sm mr-2 mb-2"
                                                        onClick={() => { this.props.history.push(`/assets/${category.id}`) }}
                                                    >
                                                        <CIcon name="cil-monitor" className="mfe-1 mt-0"></CIcon>
                                                        Quản Lý
                                                    </CButton>
                                                </CTooltip>
                                            </CContainer>
                                        }
                                    >
                                        <CDropdown>
                                            <CDropdownToggle color="transparent">
                                                <CIcon name="cil-settings" />
                                            </CDropdownToggle>
                                            <CDropdownMenu className="pt-0" placement="bottom-end">
                                                <CDropdownItem to={"/assets/" + category.id}><CIcon name="cil-monitor" className="mfe-1 mt-0 mr-2"></CIcon> Quản Lý</CDropdownItem>
                                                <CDropdownItem to={"/assets/" + category.id + "/out"}><CIcon name="cil-list" className="mfe-1 mt-0  mr-2"></CIcon>Xuất/Bàn Giao</CDropdownItem>
                                                <CDropdownItem to={"/assets/" + category.id + "/in"}><CIcon name="cil-pen-nib" className="mfe-1 mt-0  mr-2"></CIcon>Nhập/Thu Hồi</CDropdownItem>
                                            </CDropdownMenu>
                                        </CDropdown>
                                    </CWidgetDropdown>
                                </CCol>
                            )
                        })
                    )
                }

            </CRow>
        )
    }
}


export default Assets;
