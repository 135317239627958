import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CCard, CCardBody, CCardHeader,
    CInput,
    CButton,
    CLink,
    CCardFooter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import { scrapeHtmlWeb } from 'scrape-html-web';
import Export from './Export';

class Crawl extends Component {

    constructor(props) {
        super(props);

        this.state = {
            urls: [],
            gindex_urls: [],
            main_urls: [],
            target_urls: [],
            reading_target_url: '',
            url: '',
            loading: false,
            loading_main_urls: false,
            loading_target_urls: false,
            data: [],
            province: '',
            customURI: "https://sekin.vn/readurl.php?url=",
        }

    }

    componentDidMount() {

    }

    async doScape() {
        this.setState({
            loading: true,
        });
        //await this.doGetTargetUrls();

        const target_urls = this.state.target_urls;

        var data = [];
        //let readfrom = 'https://sekin.vn/readurl.php?url=';
        let readfrom = '';
        //console.log(target_urls)
        if (!helps.isEmpty(target_urls) && target_urls.length > 0) {
            await Promise.all(target_urls.map(async (url) => {
                let num_pages = await this.doGetPages(url);
                if (num_pages > 0) {
                    for (let i = 1; i <= num_pages; i++) {
                        let readUrl = readfrom + url + '?page=' + i;
                        this.setState({ reading_target_url: readUrl });
                        this.fetchData(readUrl).then(page_data => {
                            if (!helps.isEmpty(page_data) && page_data.length > 0) {
                                data = data.concat(page_data);
                            }

                            if (!helps.isEmpty(data) && data.length > 0) {
                                this.setState({
                                    data: data,
                                });
                            }
                        })

                    }
                } else {
                    let readUrl = readfrom + url;
                    this.setState({ reading_target_url: readUrl });
                    this.fetchData(readUrl).then(page_data => {
                        if (!helps.isEmpty(page_data) && page_data.length > 0) {
                            data = data.concat(page_data);
                        }

                        if (!helps.isEmpty(data) && data.length > 0) {
                            this.setState({
                                data: data,
                            });
                        }
                    })
                }
            })
            )
        }
        this.setState({
            loading: false,
            reading_target_url: '',
        });
    }

    fetchData = async (url) => {
        try {
            let options = {
                url: url,
                bypassCors: {
                    customURI: this.state.customURI,
                },
                mainSelector: ".div_list_cty",
                list: true,
                childrenSelector: [
                    { key: "name", selector: "h2 > a", type: "text" },
                    { key: "phone", selector: ".listing_dienthoai", type: "text" },
                    { key: "email", selector: ".email_web_section a", attr: "href", },
                ],
            }
            let page_data = await scrapeHtmlWeb(options);
            page_data = this.verifyList(page_data);
            return page_data;
        }
        catch (error) { console.log(error) }
    }

    async doGetGindexUrls() {
        try {
            const urls = this.state.urls;
            var gindex_urls = [];
            //let readfrom = 'https://sekin.vn/readurl.php?url=';
            let readfrom = '';
            this.setState({ loading_gindex_urls: true });
            await Promise.all(
                urls.map(async (url) => {
                    let readUrl = readfrom + url;
                    //console.log(readUrl);
                    let options = {
                        url: readUrl,
                        bypassCors: {
                            customURI: this.state.customURI,
                        },
                        //mainSelector: ".div_list_cty .pb-3.bg-white.border-bottom",
                        mainSelector: ".div_nganhnghe_pc .bg-white",
                        list: true,
                        childrenSelector: [
                            { key: "url", selector: "a", attr: 'href' },
                            { key: "label", selector: "a", type: 'text' },
                        ],
                    }
                    let page_data = await scrapeHtmlWeb(options);
                    console.log('Gindex', page_data)
                    if (!helps.isEmpty(page_data) && page_data.length > 0) {
                        page_data = page_data.filter(url => helps.isURL(url.url));
                        gindex_urls = gindex_urls.concat(page_data);
                    }

                    if (!helps.isEmpty(gindex_urls) && gindex_urls.length > 0) {
                        gindex_urls = helps.getUniqueListBy(gindex_urls, 'url');
                        this.setState({
                            gindex_urls: gindex_urls,
                        });
                    }
                })
            )
            this.setState({ loading_gindex_urls: false });
            return gindex_urls;
        } catch (e) {
            console.log(e);
        }
    }

    async doGetMainUrls() {
        try {
            await this.doGetGindexUrls();
            const gindex_urls = this.state.gindex_urls;
            console.log(gindex_urls);
            var main_urls = [];
            //let readfrom = 'https://sekin.vn/readurl.php?url=';
            let readfrom = '';
            this.setState({ loading_main_urls: true });
            await Promise.all(
                gindex_urls.map(async (url) => {
                    let readUrl = readfrom + url.url;
                    //console.log(readUrl);
                    let options = {
                        url: readUrl,
                        bypassCors: {
                            customURI: this.state.customURI,
                        },
                        //mainSelector: ".div_list_cty .pb-3.bg-white.border-bottom",
                        mainSelector: "p.pb-3",
                        list: true,
                        childrenSelector: [
                            { key: "url", selector: "a", attr: 'href' },
                            { key: "label", selector: "a", type: 'text' },
                        ],
                    }
                    let page_data = await scrapeHtmlWeb(options);
                    console.log('main_urls', page_data)
                    if (!helps.isEmpty(page_data) && page_data.length > 0) {
                        page_data = page_data.filter(url => helps.isURL(url.url));
                        main_urls = main_urls.concat(page_data);
                    }

                    if (!helps.isEmpty(main_urls) && main_urls.length > 0) {
                        main_urls = helps.getUniqueListBy(main_urls, 'url');
                        this.setState({
                            main_urls: main_urls,
                        });
                    }
                })
            )
            this.setState({ loading_main_urls: false });
            return main_urls;
        } catch (e) {
            console.log(e);
        }
    }

    async doGetTargetUrls() {
        try {
            await this.doGetMainUrls();
            const main_urls = this.state.main_urls;
            var target_urls = this.state.target_urls;
            //let readfrom = 'https://sekin.vn/readurl.php?url=';
            let readfrom = '';
            this.setState({ loading_target_urls: true });
            //console.log(main_urls)
            await Promise.all(
                main_urls.map(async (url) => {
                    let readUrl = readfrom + url.url;
                    //console.log(readUrl);
                    let options = {
                        url: readUrl,
                        bypassCors: {
                            customURI: this.state.customURI,
                        },
                        mainSelector: ".div_nganhnghe_pc",
                        list: true,
                        childrenSelector: [
                            { key: "url", selector: "a", attr: "href" },
                            { key: "label", selector: "a", type: "text" },
                        ],
                    }
                    let page_data = await scrapeHtmlWeb(options);
                    //console.log('div_nganhnghe_pc', page_data)
                    if (!helps.isEmpty(page_data) && page_data.length > 0) {
                        let target_url = helps.getItemFromArr(page_data, this.state.province, 'label');
                        if (!helps.isEmpty(target_url) && target_url.url) {
                            if (!target_urls.includes(target_url.url)) {
                                target_urls.push(target_url.url);
                            }
                            //console.log(target_urls)
                            this.setState({
                                target_urls: target_urls,
                            });
                        }
                    }
                })
            )
            this.setState({ loading_target_urls: false });
            return target_urls;
        } catch (e) {
            console.log(e);
        }
    }

    async doGetPages(url) {
        try {
            let options = {
                url: url,
                bypassCors: {
                    customURI: this.state.customURI,
                },
                mainSelector: "#paging",
                list: true,
                childrenSelector: [
                    { key: "page", selector: "a", type: "text" },
                ],
            }
            const pages = await scrapeHtmlWeb(options);
            if (!helps.isEmpty(pages) && pages.length > 0) {
                return (pages.length - 3);
            } else return 0;
        } catch (e) {
            console.log(e);
        }
    }

    doClear() {
        this.setState({
            loading: false,
            loading_main_urls: false,
            loading_target_urls: false,
            urls: [],
            main_urls: [],
            target_urls: [],
            data: [],
        });
    }

    verifyList(data) {
        var newData = [];
        if (!helps.isEmpty(data) && data.length > 0) {
            data.forEach(item => {
                if (item.email) {
                    item['email'] = item.email.replaceAll('mailto:', '');
                }
                if (!helps.isEmpty(item.email)) {
                    newData.push(item);
                }
            })
            newData = newData.filter(item => {
                if (item.email && this.validateEmail(item.email)) {
                    return true;
                } else {
                    return false;
                }
            });
            newData = helps.getUniqueListBy(newData, 'email');
            return newData;
        } else return [];
    }

    validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    handleChange(e) {
        let value = decodeURIComponent(e.target.value);
        let name = e.target.name;
        this.setState({ [name]: value });
    }

    addUrl() {
        let { url, urls } = this.state;
        if (!helps.isEmpty(url) && !urls.includes(url)) {
            urls.push(url);
            this.setState({ urls: urls, url: '' });
        }
    }

    removeUrl(url) {
        let urls = this.state.urls;
        const position = urls.indexOf(url);
        let newUrls = urls.slice();
        if (position !== -1) {
            newUrls.splice(position, 1)
        } else {
            newUrls = [...urls, url]
        }
        this.setState({ urls: newUrls });
    }

    render() {
        //console.log(this.state.data)
        return (
            <CRow className="mt-3">
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader>
                            URL
                        </CCardHeader>
                        <CCardBody>
                            <CRow className={'mb-2'}>
                                <CCol md={1} className={'text-right'}>
                                    Tỉnh Thành:
                                </CCol>
                                <CCol md={3}>
                                    <CInput
                                        type='text'
                                        name='province'
                                        value={this.state.province}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol md={8}>
                                    <CInput
                                        type='text'
                                        name='url'
                                        value={this.state.url}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </CCol>
                                <CCol md={4}>
                                    <CButton
                                        size='md'
                                        color='primary'
                                        onClick={() => { this.addUrl() }}
                                    >Thêm</CButton>
                                </CCol>
                            </CRow>
                            <hr />
                            {!helps.isEmpty(this.state.urls) && this.state.urls.length > 0 && this.state.urls.map((url, index) => {
                                return (
                                    <CRow className={'mb-1'} key={'url-' + index}>
                                        <CCol md={1}>{index + 1}</CCol>
                                        <CCol md={8}><CLink href={url} target='_blank'>{url}</CLink></CCol>
                                        <CCol md={3}>
                                            <CButton
                                                color='danger'
                                                size='sm'
                                                onClick={() => { this.removeUrl(url) }}
                                            >
                                                <CIcon name="cil-x"></CIcon>
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                )
                            })}
                            <hr />
                            <CRow className={'mb-2'}>
                                <CCol>
                                    <CButton
                                        size='md'
                                        color='primary'
                                        onClick={() => { this.doGetTargetUrls() }}
                                    >Crawl Urls</CButton>
                                    {this.state.loading_main_urls && <Loading className="text-center" />}
                                    {this.state.loading_target_urls && <Loading className="text-center" />}
                                </CCol>
                            </CRow>
                            {!helps.isEmpty(this.state.main_urls) && this.state.main_urls.length > 0 && <div style={{ height: '200px', overflow: 'auto' }}>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Gindex Urls {this.state.gindex_urls && this.state.gindex_urls.length && <>({this.state.gindex_urls.length})</>}</th>
                                            <th>Main Urls {this.state.main_urls && this.state.main_urls.length && <>({this.state.main_urls.length})</>}</th>
                                            <th>Target Urls {this.state.target_urls && this.state.target_urls.length && <>({this.state.target_urls.length})</>}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!helps.isEmpty(this.state.main_urls) && this.state.main_urls.length > 0 && this.state.main_urls.map((url, index) => {
                                            return (
                                                <tr key={'main-urls-' + url.url + '-' + index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {this.state.gindex_urls[index] && <>
                                                            <CLink href={this.state.gindex_urls[index].url} target='_blank'>{this.state.gindex_urls[index].url}</CLink>
                                                            <br />
                                                            <small>{this.state.gindex_urls[index].label}</small>
                                                        </>}
                                                    </td>
                                                    <td>
                                                        <CLink href={url.url} target='_blank'>{url.url}</CLink>
                                                        <br />
                                                        <small>{url.label}</small>
                                                    </td>
                                                    <td><CLink href={this.state.target_urls[index]} target='_blank'>{this.state.target_urls[index]}</CLink></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>}
                        </CCardBody>
                        <CCardFooter>
                            <CRow>
                                <CCol md={2}>
                                    <CButton
                                        size='md'
                                        color='primary'
                                        onClick={() => { this.doScape() }}
                                    >Scrape</CButton>
                                </CCol>
                                <CCol md={8}>
                                    {!helps.isEmpty(this.state.reading_target_url) && <span>Đọc: <i>{this.state.reading_target_url}</i></span>}
                                </CCol>
                                <CCol md={2} className={'text-right'}>
                                    <CButton
                                        size='md'
                                        color='danger'
                                        onClick={() => { this.doClear() }}
                                    >Clear</CButton>
                                </CCol>
                            </CRow>
                        </CCardFooter>
                    </CCard>
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol>
                                    DATA: {this.state.data.length}
                                    {this.state.loading && <Loading className="text-center" />}
                                </CCol>
                                <CCol>
                                    {!helps.isEmpty(this.state.data) && this.state.data.length > 0 && <Export key={'export-' + this.state.data.length} {... this.props} data={this.state.data} />}
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Công Ty</th>
                                        <th>Email</th>
                                        <th>Điện thoại</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!helps.isEmpty(this.state.data) && this.state.data.length > 0 && this.state.data.map((company, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{company.name}</td>
                                                <td>{company.email}</td>
                                                <td>{company.valid}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        )
    }
}
export default Crawl;
