import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { tagActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import { CCol, CContainer, CRow } from '@coreui/react';
import Tags from 'src/views/tags/Tags';
import TagsForm from 'src/views/tags/TagsForm';

class TagsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: {},
            tag_id: 0,
        }

    }

    componentDidMount() {
        this.doGetTags(this.props.object);
    }

    async doGetTags(_object) {
        try {
            const tags = await this.props.getAllTags(_object);
            this.setState({ tags: tags });
            return tags;
        } catch (error) {
            console.log(error);
        }
    }

    handleSubmit(tag) {
        let tags = this.props.tags;
        let _tag = tag;

        if (!helps.isEmpty(tag.id)) {
            _tag = this.props.updateTag(tag);
            tags.push(_tag);
        } else {
            _tag = this.props.addTag(tag);
        }
        this.setState({ tag: _tag, tags: tags });
    }

    handleEdit(tag) {
        this.setState({ tag: tag, tag_id: tag.id });
    }

    handleDelete(tag) {
        this.props.deleteTag(tag);
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        return (
            <CRow>
                <CCol md={8}>
                    <ThePermission {... this.props} component="settings" action="view">
                        {!helps.isEmpty(this.props.tags) &&
                            <Tags
                                key={this.props.tags.length}
                                {... this.props}
                                tags={this.props.tags}
                                handleEdit={(tag) => { this.handleEdit(tag) }}
                                handleDelete={(tag) => { this.handleDelete(tag) }}
                            />
                        }
                    </ThePermission>

                </CCol>
                <CCol md={4}>
                    <ThePermission {... this.props} component="settings" action="edit">
                        <CContainer fluid>
                            <TagsForm
                                key={this.state.tag_id}
                                {... this.props}
                                tag={this.state.tag}
                                handleSubmit={(tag) => { this.handleSubmit(tag) }}
                            />
                        </CContainer>
                    </ThePermission>
                </CCol>
            </CRow>
        );
    }
}

function mapStateToProps(state) {
    const { loading, tags } = state.tagReducer;
    return { loading, tags };
}

const actionCreators = {
    getAllTags: tagActions.getAll,
    deleteTag: tagActions._delete,
    addTag: tagActions.add,
    updateTag: tagActions.update,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(TagsContainer);
export { connectedContainer as TagsContainer };