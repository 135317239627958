import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CFormGroup,
    CFormText,
    CInput,
    CInvalidFeedback,
    CTextarea,
    CSelect,
    CInputRadio,
    CBadge
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';

class CustomerForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            name: '',
            tax_number: '',
            legal_representative: '',
            create_by: this.props.user.id,
            create_date: new Date(),
            phone: '',
            email: '',
            website: '',
            address: '',
            assign_to: '',
            type: 'customer',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        const customer = this.props.customer;
        //console.log(customer);
        if (customer) {
            this.setState({
                id: customer.id,
                name: customer.name ? customer.name : this.state.name,
                tax_number: customer.tax_number ? customer.tax_number : this.state.tax_number,
                legal_representative: customer.legal_representative ? customer.legal_representative : this.state.legal_representative,
                create_by: customer.create_by ? customer.create_by : this.props.user.id,
                create_date: customer.create_date ? customer.create_date : new Date(),
                phone: customer.phone ? customer.phone : this.state.phone,
                email: customer.email ? customer.email : this.state.email,
                website: customer.website ? customer.website : this.state.website,
                address: customer.address ? customer.address : this.state.address,
                province_id: customer.province_id ? customer.province_id : this.state.province_id,
                assign_to: customer.assign_to ? customer.assign_to : this.state.assign_to,
                type: customer.type ? customer.type : this.state.type,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let customer = {
                id: this.state.id,
                name: this.state.name,
                tax_number: this.state.tax_number,
                legal_representative: this.state.legal_representative,
                create_by: this.state.create_by,
                create_date: new Date(this.state.create_date),
                phone: this.state.phone,
                email: this.state.email,
                website: this.state.website,
                address: this.state.address,
                province_id: this.state.province_id,
                assign_to: this.state.assign_to,
                type: this.state.type,
            }
            console.log(customer);
            this.props.handleSubmit(customer);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeFilterDate(_date) {
        let fields = this.state.fields;
        fields['create_date'] = _date;
        this.setState({ create_date: _date });
        this.setState({ fields });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập Tên";
        }

        if (helps.isEmpty(this.state.province_id)) {
            formIsValid = false;
            errors["province_id"] = "Vui lòng chọn Tỉnh thành/Khu Vực";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }


    render() {
        const provinces = this.props.provinces;

        var filter_date_val = '';
        if (this.state.create_date) {
            if (!helps.isEmpty(this.state.create_date) && typeof this.state.create_date === 'object') {
                filter_date_val = helps.formatDate_for_input(this.state.create_date.toDate(), 'YYYY-MM-DD');
            } else {
                filter_date_val = helps.formatDate_for_input(this.state.create_date, 'YYYY-MM-DD');
            }

        } else {
            filter_date_val = new Date().toISOString().slice(0, 10);
        }

        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader>
                                {this.props.form_header}
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-name" className='mr-3'>Loại:</CLabel>
                                            <CFormGroup variant="custom-radio" className='mb-2' inline>
                                                <CInputRadio
                                                    custom
                                                    id="type_lead"
                                                    name="type"
                                                    value='lead'
                                                    checked={this.state.type === 'lead'}
                                                    onChange={(e) => { this.handleChange('type', e) }}
                                                />
                                                <CLabel variant="custom-checkbox" htmlFor='type_lead' className="cursor-pointer">
                                                    <CBadge color='success' className="p-2">Cơ Hội</CBadge>
                                                </CLabel>
                                            </CFormGroup>
                                            <CFormGroup variant="custom-radio" className='mb-2' inline>
                                                <CInputRadio
                                                    custom
                                                    id="type_potential"
                                                    name="type"
                                                    value='potential'
                                                    checked={this.state.type === 'potential'}
                                                    onChange={(e) => { this.handleChange('type', e) }}
                                                />
                                                <CLabel variant="custom-checkbox" htmlFor='type_potential' className="cursor-pointer">
                                                    <CBadge color='warning' className="p-2">Tiềm Năng</CBadge>
                                                </CLabel>
                                            </CFormGroup>
                                            <CFormGroup variant="custom-radio" className='mb-2' inline>
                                                <CInputRadio
                                                    custom
                                                    id="type_customer"
                                                    name="type"
                                                    value='customer'
                                                    checked={this.state.type === 'customer'}
                                                    onChange={(e) => { this.handleChange('type', e) }}
                                                />
                                                <CLabel variant="custom-checkbox" htmlFor='type_customer' className="cursor-pointer">
                                                    <CBadge color='primary' className="p-2">Khách Hàng</CBadge>
                                                </CLabel>
                                            </CFormGroup>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-name">Tên:</CLabel>
                                            <CInput
                                                type="text"
                                                name="name"
                                                id="input-name"
                                                value={this.state.name}
                                                required
                                                className='font-weight-bold'
                                                onChange={(e) => { this.handleChange('name', e) }}
                                            />
                                            <CFormText className="help-block">Tên cá nhân / tổ chức / công ty</CFormText>
                                            <CInvalidFeedback className="help-block">{this.state.errors['name']}</CInvalidFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-tax_number">MST:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="tax_number"
                                                        id="input-tax_number"
                                                        value={this.state.tax_number}
                                                        className='font-weight-bold'
                                                        onChange={(e) => { this.handleChange('tax_number', e) }}
                                                    />
                                                    <CInvalidFeedback className="help-block">{this.state.errors['tax_number']}</CInvalidFeedback>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-phone">Số ĐT:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="phone"
                                                        id="input-phone"
                                                        value={this.state.phone}
                                                        onChange={(e) => { this.handleChange('phone', e) }}
                                                    />
                                                    <CInvalidFeedback className="help-block">{this.state.errors['phone']}</CInvalidFeedback>
                                                </CCol>
                                            </CRow>

                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-email">Người đại diện theo Pháp Luật:</CLabel>
                                            <CInput
                                                type="text"
                                                name="legal_representative"
                                                id="input-legal_representative"
                                                value={this.state.legal_representative}
                                                onChange={(e) => { this.handleChange('legal_representative', e) }}
                                            />
                                            <CInvalidFeedback className="help-block">{this.state.errors['legal_representative']}</CInvalidFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-email">Email:</CLabel>
                                            <CInput
                                                type="text"
                                                name="email"
                                                id="input-email"
                                                value={this.state.email}
                                                onChange={(e) => { this.handleChange('email', e) }}
                                            />
                                            <CInvalidFeedback className="help-block">{this.state.errors['email']}</CInvalidFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-website">Website:</CLabel>
                                            <CInput
                                                type="text"
                                                name="website"
                                                id="input-website"
                                                value={this.state.website}
                                                onChange={(e) => { this.handleChange('website', e) }}
                                            />
                                            <CInvalidFeedback className="help-block">{this.state.errors['website']}</CInvalidFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-address">Địa chỉ:</CLabel>
                                            <CTextarea
                                                type="text"
                                                name="address"
                                                id="input-address"
                                                value={this.state.address}
                                                onChange={(e) => { this.handleChange('address', e) }}
                                            />
                                            <CInvalidFeedback className="help-block">{this.state.errors['address']}</CInvalidFeedback>
                                        </div>
                                    </CCol>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-province_id">Tỉnh Thành / Khu Vực:</CLabel>
                                            <CSelect
                                                name="province_id"
                                                id="input-province_id"
                                                value={this.state.province_id}
                                                onChange={(e) => { this.handleChange('province_id', e) }}
                                            >
                                                {provinces && provinces.map((item) => {
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    );
                                                })}
                                            </CSelect>
                                            <CInvalidFeedback className="help-block">{this.state.errors['province_id']}</CInvalidFeedback>
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default CustomerForm;

