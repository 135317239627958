import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CDataTable,
    CButton,
    CSelect,
    CLink,
    CBadge,
    CPopover,
    CRow,
    CCol,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import configs from 'src/appConfigs';
import Loading from 'src/containers/_loading';
import Select from 'react-select';

class Items extends Component {

    constructor(props) {
        super(props);

        this.state = {
            category: '',
            status: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
    }

    handleChange(field, e) {
        var value = e.target.value;
        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        }
        this.setState({
            [field]: value
        });
    }

    handleSelect(selectOPtions, field) {
        this.setState({
            [field]: selectOPtions,
        });
        console.log(this.state)
    }

    render() {
        const { items, users, provinces, notes, stores } = this.props;

        var _items = [];
        if (items && items.length > 0) {
            items.forEach((item, index) => {
                item['create_by_alias'] = helps.getUserAlias(users, item.create_by);
                var province = helps.getItemFromArr(provinces, item.province_id);
                if (province) {
                    item['province_name'] = province.name;
                } else {
                    item['province_name'] = '';
                }
                item['index'] = index + 1;
                _items[index] = item;
            });
        }
        if (!helps.isEmpty(this.state.category)) {
            _items = _items.filter((item) => {
                return item.category == this.state.category;
            });
        }
        if (!helps.isEmpty(this.state.status)) {
            _items = _items.filter((item) => {
                return item.status == this.state.status;
            });
        }
        const fields = [
            { key: 'index', label: '#', _style: { width: '10px' }, filter: false, sorter: false },
            { key: 'code', label: 'Mã', _classes: 'font-weight-bold', _style: { width: '10%' } },
            { key: 'name', label: 'Tên', _classes: 'font-weight-bold', _style: { width: '15%' } },
            { key: 'dimension', label: 'Kích Thước' },
            { key: 'inventory', label: 'Tồn Kho', _style: { width: '15%' } },
            { key: 'category', label: 'Phân Loại' },
            { key: 'status', label: 'Tình Trạng' },
            { key: 'unit', label: 'Đơn Vị Tính', filter: false },
            { key: 'price', label: 'Đơn Giá', filter: false },
            { key: 'create_by_alias', label: 'Tạo Bởi', _style: { align: 'center' }, filter: false },
            { key: 'actions', label: 'Thao Tác', _style: { width: '10%' }, filter: false, sorter: false },
        ]
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        return (
            <CDataTable
                key={'items_' + _items.length}
                items={_items}
                fields={fields}
                columnFilter
                tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                itemsPerPageSelect={configs.itemsPerPageSelect}
                itemsPerPage={configs.per_page}
                hover
                sorter
                pagination
                striped
                columnFilterSlot={{
                    category: (
                        <>
                            <CSelect
                                name="category"
                                id="input-category"
                                className="form-control form-control-sm"
                                value={this.state.category}
                                onChange={(e) => { this.handleChange('category', e) }}
                            >
                                <option value="">-Tất cả-</option>
                                {configs.asset_categories && configs.asset_categories.map((category) => {
                                    return (
                                        <option value={category.id} key={category.id}>{category.label}</option>
                                    )
                                }
                                )}
                            </CSelect>
                        </>
                    ),
                    inventory: (
                        <>
                            <CSelect
                                name="store_id"
                                id="input-store_id"
                                className="form-control-sm"
                                value={this.state.category}
                                onChange={(e) => { this.handleChange('store_id', e) }}
                            >
                                <option value="">-Tất cả-</option>
                                {stores && stores.map((store) => {
                                    return (
                                        <option value={store.id} key={store.id}>{store.name}</option>
                                    )
                                }
                                )}
                            </CSelect>
                        </>
                    ),
                    status: (
                        <>
                            <CSelect
                                name="status"
                                id="input-status"
                                className="form-control form-control-sm"
                                value={this.state.status}
                                onChange={(e) => { this.handleChange('status', e) }}
                            >
                                <option value="">-Tất cả-</option>
                                {configs.asset_statuses && configs.asset_statuses.map((status) => {
                                    return (
                                        <option value={status.id} key={status.id}>{status.label}</option>
                                    )
                                }
                                )}
                            </CSelect>
                        </>
                    ),
                }}
                scopedSlots={{
                    'name': (item) => {
                        var _notes = [];
                        if (!helps.isEmpty(notes) && notes.length > 0) {
                            _notes = notes.filter(note => note.object_id === item.id);
                        }
                        var note_content = '';
                        if (!helps.isEmpty(_notes) && _notes.length > 0) {
                            note_content = _notes.map((note) => {
                                return (
                                    <div className='border-bottom mb-2 pb-2'>
                                        {note.content} <small>_<i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                    </div>
                                );
                            });
                        }
                        return (
                            <td className="py-2 font-weight-bold">
                                {!helps.isEmpty(note_content) &&
                                    <CPopover
                                        header='Ghi chú:'
                                        content={note_content}
                                    >
                                        <CLink className="text-dark" onClick={() => { this.props.history.push(`/assets/items/view/${item.id}`) }}>
                                            {item.name}
                                        </CLink>
                                    </CPopover>
                                }
                                {helps.isEmpty(note_content) &&
                                    <CLink className="text-dark" onClick={() => { this.props.history.push(`/assets/items/view/${item.id}`) }}>
                                        {item.name}
                                    </CLink>
                                }
                            </td>
                        )
                    },
                    'inventory': (item) => {
                        let inventory = item.inventory ? item.inventory : [];
                        return (
                            <td className="py-2">
                                {!helps.isEmpty(stores) && stores.map((store) => {
                                    let inventory_store = helps.getItemFromArr(inventory, store.id, 'store_id');
                                    let current_inventory = inventory_store.current_inventory ? inventory_store.current_inventory : 0;
                                    let color = 'success';
                                    if (current_inventory <= 0) color = 'danger';
                                    else if (current_inventory <= configs.inventory_alert) color = 'warning';
                                    return (
                                        <CRow>
                                            <CCol className={'text-right'}>{store.name}:</CCol>
                                            <CCol>
                                                <CBadge color={color}>{current_inventory}</CBadge>
                                            </CCol>
                                        </CRow>
                                    )
                                })}
                            </td>
                        )
                    },
                    'category': (item) => {
                        let category = helps.getItemFromArr(configs.asset_categories, item.category);
                        return (
                            <td className="py-2">
                                {category.label}
                            </td>
                        )
                    },
                    'status': (item) => {
                        let status = helps.getItemFromArr(configs.asset_statuses, item.status);
                        return (
                            <td className="py-2">
                                <CBadge color={status.color}>{status.label}</CBadge>
                            </td>
                        )
                    },
                    'unit': (item) => {
                        let unit = helps.getItemFromArr(configs.units, item.unit);
                        return (
                            <td className="py-2">
                                {unit.label}
                            </td>
                        )
                    },
                    'price': (item) => {
                        return (
                            <td className="py-2 text-danger">
                                {helps.formatCurrency(item.price)}
                            </td>
                        )
                    },
                    'create_by_alias': (item) => {
                        return (
                            <td className="py-2">
                                <small>
                                    {item.create_by_alias}<br />
                                    {helps.formatDate_from_Timestamp(item.create_date)}
                                </small>
                            </td>
                        )
                    },
                    'actions':
                        (item) => {
                            var _notes = [];
                            if (!helps.isEmpty(notes) && notes.length > 0) {
                                _notes = notes.filter(note => note.object_id === item.id);
                            }
                            var note_content = '';
                            if (!helps.isEmpty(_notes) && _notes.length > 0) {
                                note_content = _notes.map((note) => {
                                    return (
                                        <div className='border-bottom mb-2 pb-2'>
                                            {note.content} <small>_<i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                        </div>
                                    );
                                });
                            }
                            return (
                                <td className="py-2">
                                    <ThePermission {... this.props} component="assets" action="view">
                                        <CButton
                                            color="warning"
                                            size="sm mr-1"
                                            title="Xem chi tiết"
                                            onClick={() => { this.props.history.push(`/assets/items/view/${item.id}`) }}
                                        >
                                            <CIcon name="cil-face"></CIcon>
                                        </CButton>
                                    </ThePermission>
                                    <ThePermission {... this.props} component="assets" action="view">
                                        <CPopover
                                            header="Ghi chú"
                                            content={note_content}
                                        >
                                            <CButton
                                                color="info"
                                                size="sm mr-1"
                                            >
                                                <CIcon name="cil-list"></CIcon>
                                            </CButton>
                                        </CPopover>
                                    </ThePermission>
                                    <ThePermission {... this.props} component="assets" action="edit">
                                        <CButton
                                            color="primary"
                                            size="sm"
                                            title="Cập nhật thông tin"
                                            onClick={() => { this.props.history.push(`/assets/items/add_edit/${item.id}`) }}
                                        >
                                            <CIcon name="cil-pen-nib"></CIcon>
                                        </CButton>
                                    </ThePermission>
                                </td>
                            )
                        },
                }}
            />

        )
    }
}


export default Items;
