import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CDataTable,
    CButton,
    CSelect,
    CLink,
    CBadge,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import configs from 'src/appConfigs';
import Loading from 'src/containers/_loading';
import Select from 'react-select';

class Stores extends Component {

    constructor(props) {
        super(props);

        this.state = {
            province_id: '',
            status: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
    }

    handleChange(field, e) {
        var value = e.target.value;
        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        }
        this.setState({
            [field]: value
        });
    }

    handleSelect(selectOPtions, field) {
        this.setState({
            [field]: selectOPtions,
        });
        console.log(this.state)
    }

    render() {
        const { stores, users, provinces, notes } = this.props;

        var _stores = [];
        if (stores && stores.length > 0) {
            stores.forEach((store, index) => {
                store['create_by_alias'] = helps.getUserAlias(users, store.create_by);
                var province = helps.getItemFromArr(provinces, store.province_id);
                if (province) {
                    store['province_name'] = province.name;
                } else {
                    store['province_name'] = '';
                }
                store['index'] = index + 1;
                _stores[index] = store;
            });
        }
        if (!helps.isEmpty(this.state.province)) {
            _stores = _stores.filter((store) => {
                return store.province_id == this.state.province_id;
            });
        }
        if (!helps.isEmpty(this.state.status)) {
            _stores = _stores.filter((store) => {
                return store.status == this.state.status;
            });
        }
        const fields = [
            { key: 'index', label: '#', _style: { width: '10px' }, filter: false, sorter: false },
            { key: 'name', label: 'Tên', _classes: 'font-weight-bold', _style: { width: '15%' } },
            { key: 'branch', label: 'Chi Nhánh' },
            { key: 'status', label: 'Tình Trạng' },
            { key: 'province_id', label: 'Tỉnh Thành / Khu Vực', filter: false },
            { key: 'create_by_alias', label: 'Tạo Bởi', _style: { align: 'center' }, filter: false },
            { key: 'actions', label: 'Thao Tác', _style: { width: '10%' }, filter: false, sorter: false },
        ]
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        return (
            <CDataTable
                key={'stores_' + _stores.length}
                items={_stores}
                fields={fields}
                columnFilter
                tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                storesPerPageSelect={configs.storesPerPageSelect}
                storesPerPage={configs.per_page}
                hover
                sorter
                pagination
                striped
                columnFilterSlot={{
                    province_id: (
                        <>
                            <CSelect
                                name="province_id"
                                id="input-province_id"
                                className="form-control form-control-sm"
                                value={this.state.province_id}
                                onChange={(e) => { this.handleChange('province_id', e) }}
                            >
                                <option value=''> - Tất cả - </option>
                                {provinces && provinces.map((province) => {
                                    return (
                                        <option value={province.id} key={province.id}>{province.name}</option>
                                    )
                                }
                                )}
                            </CSelect>
                        </>
                    ),
                    status: (
                        <>
                            <CSelect
                                name="status"
                                id="input-status"
                                className="form-control form-control-sm"
                                value={this.state.status}
                                onChange={(e) => { this.handleChange('status', e) }}
                            >
                                <option value="">-Tất cả-</option>
                                <option value={true}>Hoạt động</option>
                                <option value={false}>Ngưng Hoạt động</option>
                            </CSelect>
                        </>
                    ),
                }}
                scopedSlots={{
                    'name': (item) => {
                        return (
                            <td className="py-2 font-weight-bold">
                                {item.name}
                            </td>
                        )
                    },
                    'branch': (item) => {
                        return (
                            <td className="py-2">
                                {configs.branches[item.branch]}
                            </td>
                        )
                    },
                    'status': (item) => {
                        let color = item.status ? 'success' : 'danger';
                        let label = item.status ? 'Hoạt động' : 'Ngưng hoạt động';
                        return (
                            <td className="py-2">
                                <CBadge color={color}>{label}</CBadge>
                            </td>
                        )
                    },
                    'province_id': (item) => {
                        let province = helps.getItemFromArr(provinces, item.province_id);
                        return (
                            <td className="py-2">
                                {province.name}
                            </td>
                        )
                    },
                    'create_by_alias': (item) => {
                        return (
                            <td className="py-2">
                                {item.create_by_alias}<br />
                                {helps.formatDate_from_Timestamp(item.create_date)}
                            </td>
                        )
                    },
                    'actions':
                        (item) => {
                            return (
                                <td className="py-2">
                                    <ThePermission {... this.props} component="assets" action="edit">
                                        <CButton
                                            color="primary"
                                            size="sm"
                                            title="Cập nhật thông tin"
                                            onClick={() => { this.props.history.push(`/assets/stores/add_edit/${item.id}`) }}
                                        >
                                            <CIcon name="cil-pen-nib"></CIcon>
                                        </CButton>
                                    </ThePermission>
                                </td>
                            )
                        },
                }}
            />

        )
    }
}


export default Stores;
