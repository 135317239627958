import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { helps } from 'src/_helpers';
import { employeeActions, violationActions, assetActions, asset_itemActions, itemActions } from 'src/_actions';
import EmployeeExitForm from 'src/views/employees/EmployeeExitForm';

class EmployeeExitContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            employee: {},
        }
    }

    componentDidMount() {
        const { employee } = this.props;
        this.getTypes();
        this.getViolations();
        let params = {};
        this.getAssets({ 'employee_id': employee.employee_id }).then(() => {
            let assets = this.props.assets;
            let assets_ids = [];
            if (!helps.isEmpty(assets)) {
                assets.map((asset) => {
                    assets_ids.push(asset.id);
                });
            }
            params['assets_ids'] = assets_ids;
            this.getAssetItems(params);
        });
    }

    handleSubmit = (employee) => {
        let employee_id = this.props.match.params.id;
        if (!helps.isEmpty(employee_id)) {
            this.props.updateEmployee(employee);
            this.props.history.push('/employees/view/' + employee_id);
        }
    }

    async getViolations() {
        return await this.props.getViolations(this.props.employee);
    }

    async getTypes() {
        var { violation_types } = this.props;
        if (helps.isEmpty(violation_types)) {
            violation_types = await this.props.getViolationTypes();
        }
        return violation_types;
    }

    async getAssets(params = {}) {
        await this.props.getAssets(params);
    }

    async getAssetItems(params = {}) {
        await this.props.getAssetItems(params);
    }

    render() {
        const { employee } = this.props;
        if (helps.isEmpty(employee)) {
            return (
                <Loading></Loading>
            );
        }
        let employee_id = employee.employee_id;

        if (!helps.isEmpty(employee_id) && helps.isEmpty(employee)) return (<Loading></Loading>);
        return (
            <ThePermission {... this.props} component="employees" action="edit">
                <EmployeeExitForm
                    {... this.props}
                    handleSubmit={this.handleSubmit}
                />
            </ThePermission>
        );
    }
}
function mapStateToProps(state) {
    const { loading, employees } = state.employeeReducer;
    const { user } = state.authentication;
    const { violations, violation_types } = state.violationReducer;
    const { items } = state.itemReducer;
    const { assets, asset_items } = state.assetReducer;
    return { loading, employees, user, violations, violation_types, assets, asset_items, items };
}

const actionCreators = {
    getAll: employeeActions.getAll,
    updateEmployee: employeeActions.updateEmployee,
    uploadLetter: employeeActions.uploadLetter,
    deleteLetter: employeeActions.deleteLetter,
    getViolations: violationActions.getAll,
    getViolationTypes: violationActions.getAllTypes,
    getAssets: assetActions.getAll,
    getAssetItems: asset_itemActions.getAllforComponent,
    getItems: itemActions.getAll,
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(EmployeeExitContainer);
export { connectedFormContainer as EmployeeExitContainer };