import React, { Component } from 'react'
import {
    CCard,
    CCardHeader,
    CCardBody,
    CDataTable,
    CCollapse,
    CButton,
    CRow,
    CCol,
    CCardFooter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import configs from 'src/appConfigs';
import ProvinceForm from './ProvinceForm';

class Provinces extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAddForm: false,
            edit_forms: [],
            isUpdated: false,
        }

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

    }

    handleCancelAddForm() {
        this.setState({ showAddForm: false });
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    toggleEditForms = (item) => {
        let forms = this.state.edit_forms;
        const position = forms.indexOf(item.id);
        let newForms = forms.slice();
        if (position !== -1) {
            newForms.splice(position, 1)
        } else {
            newForms = [...forms, item.id]
        }
        this.setState({ edit_forms: newForms });
    }

    handleEdit(e, province) {
        e.preventDefault();
        this.props.handleEdit(province);
    }

    handleDelete(e, province) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Tỉnh thành/Khu vực: "' + province.name + '-' + province.code + '"?');
        if (_confirm) {
            this.props._delete(province);
        }
        return false;
    }

    handleSubmit(province) {
        if (helps.isEmpty(province.id)) {
            this.props.add(province);
        } else {
            this.props.update(province);
        }
    }

    render() {

        const { provinces } = this.props;

        //if (helps.isEmpty(checkrules)) return (<></>);

        if (!helps.isEmpty(provinces)) {
            provinces.map((province, index) => {
                province['index'] = index + 1;
                return province;
            });

        }
        const fields = [
            { key: 'index', label: '#', _style: { width: '5%' }, filter: false },
            { key: 'name', label: 'Tên Tỉnh Thành/Khu Vực', _style: { width: '40%' } },
            { key: 'code', label: 'Viết Tắt', _classes: 'text-center font-weight-bold', _style: { width: '10%' } },
            { key: 'actions', label: 'Thao Tác', _classes: 'text-center', _style: { width: '15%' }, filter: false, sorter: false },
        ]

        return (
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol xl={6}>
                            Quản Lý Tỉnh Thành/Khu Vực
                        </CCol>
                        <CCol xl={6}>
                            <CButton color="primary" className="mr-2 float-right" size="sm" title='Thêm Mới' onClick={(e) => { this.setState({ showAddForm: !this.state.showAddForm }); }}>
                                <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                Thêm Mới
                            </CButton>

                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody className="provinces">

                    {this.state.showAddForm && <ProvinceForm {...this.props} handleSubmit={this.handleSubmit} handleCancelAddForm={() => { this.handleCancelAddForm() }} />}
                    <div className="provinces-list">
                        <CDataTable
                            items={provinces}
                            fields={fields}
                            itemsPerPage={configs.per_page}
                            itemsPerPageSelect
                            columnFilter
                            tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                            pagination
                            sorter
                            hover
                            scopedSlots={{
                                'actions':
                                    (item) => {
                                        return (
                                            <td className="text-center">
                                                <CButton
                                                    type="button"
                                                    color="primary"
                                                    size="sm mr-1"
                                                    title="Sửa"
                                                    onClick={() => { this.toggleEditForms(item); }}
                                                >
                                                    <CIcon name="cil-pen-nib"></CIcon>
                                                </CButton>
                                                <CButton
                                                    color="danger"
                                                    size="sm"
                                                    title="Xoá"
                                                    onClick={(e) => { this.handleDelete(e, item) }}
                                                >
                                                    <CIcon name="cil-trash"></CIcon>
                                                </CButton>

                                            </td>
                                        )
                                    },
                                'details':
                                    (item, index) => {
                                        let edit_forms = this.state.edit_forms;
                                        let _show = edit_forms.includes(item.id);
                                        return (
                                            <CCollapse show={_show} key={"details_" + item.id} style={{ overflow: 'hidden' }}>
                                                <ProvinceForm {...this.props} province={item} handleSubmit={this.handleSubmit} toggleEditForms={() => { this.toggleEditForms(item) }} />
                                            </CCollapse>
                                        )
                                    }
                            }}
                        />
                    </div>
                </CCardBody>
                <CCardFooter>
                    <CRow>
                        <CCol xl={12}>
                            Tổng: {provinces && <><b>{provinces.length}</b></>}
                        </CCol>
                    </CRow>
                </CCardFooter>
            </CCard >
        )
    }
}
export default Provinces;
