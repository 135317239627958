export const candidateConstants = {
    SET_PARAMS_REQUEST: 'CANDIDATES_SET_PARAMS_REQUEST',

    GETALL_REQUEST: 'CANDIDATES_GETALL_REQUEST',
    GETALL_SUCCESS: 'CANDIDATES_GETALL_SUCCESS',
    GETALL_FAILURE: 'CANDIDATES_GETALL_FAILURE',

    DELETE_REQUEST: 'CANDIDATES_DELETE_REQUEST',
    DELETE_SUCCESS: 'CANDIDATES_DELETE_SUCCESS',
    DELETE_FAILURE: 'CANDIDATES_DELETE_FAILURE',

    GET_REQUEST: 'CANDIDATES_GET_REQUEST',
    GET_SUCCESS: 'CANDIDATES_GET_SUCCESS',
    GET_FAILURE: 'CANDIDATES_GET_FAILURE',

    ADD_REQUEST: 'CANDIDATES_ADD_REQUEST',
    ADD_SUCCESS: 'CANDIDATES_ADD_SUCCESS',
    ADD_FAILURE: 'CANDIDATES_ADD_FAILURE',

    UPDATE_REQUEST: 'CANDIDATES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CANDIDATES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CANDIDATES_UPDATE_FAILURE',
}