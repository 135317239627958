export const noteConstants = {
    GET_LIST_REQUEST: 'NOTES_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'NOTES_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'NOTES_GET_LIST_FAILURE',

    GET_LATEST_REQUEST: 'NOTES_GET_LATEST_REQUEST',
    GET_LATEST_SUCCESS: 'NOTES_GET_LATEST_SUCCESS',
    GET_LATEST_FAILURE: 'NOTES_GET_LATEST_FAILURE',

    ADD_REQUEST: 'NOTES_ADD_REQUEST',
    ADD_SUCCESS: 'NOTES_ADD_SUCCESS',
    ADD_FAILURE: 'NOTES_ADD_FAILURE',

    EDIT_REQUEST: 'NOTES_EDIT_REQUEST',
    EDIT_SUCCESS: 'NOTES_EDIT_SUCCESS',
    EDIT_FAILURE: 'NOTES_EDIT_FAILURE',

    DELETE_REQUEST: 'NOTES_DELETE_REQUEST',
    DELETE_SUCCESS: 'NOTES_DELETE_SUCCESS',
    DELETE_FAILURE: 'NOTES_DELETE_FAILURE',
}