import React, { Component } from 'react'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTooltip, CImg, CModal, CModalHeader, CModalBody,
} from '@coreui/react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import Select from 'react-select';

class Housing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            housings: [],
        }

    }

    componentDidMount() {

    }

    getContractType() {
        const { housing } = this.props;
        var contract_type = 'tháng';
        switch (housing.contract_type) {
            case 'day':
                contract_type = 'ngày';
                break;
            case 'year':
                contract_type = 'năm';
                break;
            default:
                contract_type = 'tháng';
        }
        return contract_type;
    }

    handleEdit(e, tag) {
        e.preventDefault();
        this.props.handleEdit(tag);
    }

    handleChangeSelect = async (newValue, actionMeta) => {
        const { housing } = this.props;
        housing['employees'] = newValue;
        console.group("Value Changed");
        console.log(newValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        this.props.updateHousing(housing);
    };

    render() {
        const { housing, users, provinces, employees } = this.props;
        if (helps.isEmpty(housing)) {
            return (
                <Loading></Loading>
            );
        }
        var province = helps.getItemFromArr(provinces, housing.province_id);
        var province_name = '';
        if (province) {
            province_name = province.name;
        }

        var create_date = '';
        if (housing.create_date) {
            create_date = housing.create_date.toLocaleDateString();
        }

        var default_options = housing.employees;

        var employee_options = [];
        if (!helps.isEmpty(employees)) {
            employees.forEach((employee) => {
                employee_options.push({
                    value: employee.employee_id,
                    label: employee.fullname + '(' + employee.employee_id + ')',
                });
            });
        }
        return (
            <CRow className="mt-3">
                <CCol xl={12}>
                    <CRow>
                        <CCol md={6}>
                            <CRow className="mb-3">
                                <CCol>
                                    Tên: <b>{housing.name}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Ngày bắt đầu: <b>{helps.formatDate_from_Timestamp(housing.start_date)}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Thời Gian HĐ: <b>{housing.contract_length}<span> {this.getContractType()}</span></b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Liên hệ: <b>{housing.contact_name}</b>
                                </CCol>
                                <CCol>
                                    Điện thoại: <b>{housing.phone}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Đặt cọc: <b>{helps.formatCurrency(housing.deposit)}</b>
                                </CCol>
                                <CCol>
                                    Tiền thuê: <b>{helps.formatCurrency(housing.amount)}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    TK Ngân hàng: <b>{housing.bank_acc && <>{housing.bank_acc}</>}{housing.bank_name && <em> ({housing.bank_name})</em>}</b>
                                    {housing.bank_acc && housing.bank_name && <>
                                        <CTooltip content={<>BANK QR CODE: <b className='ml-2'> {housing.name}</b></>}>
                                            <CImg
                                                src={'https://img.vietqr.io/image/' + housing.bank_name + '-' + housing.bank_acc + '-compact.png'}
                                                width={20}
                                                onClick={(e) => { e.preventDefault(); this.setState({ show_qr: true }) }}
                                                className={'cursor-pointer border ml-1'}
                                            />
                                        </CTooltip>
                                        {housing.bank_note && <span className='ml-2 font-italic text-muted'>({housing.bank_note})</span>}
                                        <CModal
                                            show={this.state.show_qr}
                                            size='lg'
                                            className='text-left'
                                            onClose={() => { this.setState({ show_qr: false }); }}
                                        >
                                            <CModalHeader closeButton>
                                                BANK QR CODE: <b className='ml-2'> {housing.name}</b>
                                            </CModalHeader>
                                            <CModalBody className='text-center'>
                                                <CImg src={'https://img.vietqr.io/image/' + housing.bank_name + '-' + housing.bank_account_number + '-compact.png'} />
                                            </CModalBody>
                                        </CModal>
                                    </>}

                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Địa Chỉ: <b>{housing.address}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={4}>
                                    Tỉnh Thành / Khu Vực: <b>{province_name}</b>
                                </CCol>
                            </CRow>
                            <hr />
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Ngày Khởi Tạo: <b>{create_date}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Tạo bởi: <b>{helps.getUserAlias(users, housing.create_by)}</b>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol md={6}>
                            <CCard>
                                <CCardHeader>
                                    Danh Sách Nhân Viên Ở Nhà Đội
                                </CCardHeader>
                                <CCardBody>
                                    <Select
                                        id="input-employees"
                                        placeholder='Nhân Viên ...'
                                        isMulti
                                        options={employee_options}
                                        defaultValue={default_options}
                                        onChange={this.handleChangeSelect}
                                    >

                                    </Select>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        )
    }
}
export default Housing;
