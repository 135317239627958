import { checkConstants } from "src/_constants";
import { checkService } from "src/_services/check.service";
import { alertActions } from ".";

export const checkActions = {
    getAll,
    add,
    update,
    _delete,
}

function getAll(params, _limit = 1000) {
    return dispatch => {
        dispatch(request(params));
        return checkService.getAll(params, _limit)
            .then(
                checks => {
                    dispatch(success(checks, params));
                    return checks;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: checkConstants.GET_LIST_REQUEST, params: params } }
    function success(checks, params) { return { type: checkConstants.GET_LIST_SUCCESS, checks, params: params } }
    function failure(error) { return { type: checkConstants.GET_LIST_FAILURE, error } }
}

function add(check) {
    return dispatch => {
        dispatch(request(check));
        return checkService.add(check)
            .then(
                check => {
                    dispatch(success(check));
                    dispatch(alertActions.success('Check thành công!'));
                    return check;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(check) { return { type: checkConstants.ADD_REQUEST, check: check } }
    function success(check) { return { type: checkConstants.ADD_SUCCESS, check: check } }
    function failure(error) { return { type: checkConstants.ADD_FAILURE, error } }
}

function update(check) {
    return dispatch => {
        dispatch(request(check));
        checkService.update(check)
            .then(
                check => {
                    dispatch(success(check));
                    dispatch(alertActions.success('Chỉnh sửa Check thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(check) { return { type: checkConstants.EDIT_REQUEST, check: check } }
    function success(check) { return { type: checkConstants.EDIT_SUCCESS, check: check } }
    function failure(error) { return { type: checkConstants.EDIT_FAILURE, error } }
}

function _delete(check) {
    return dispatch => {
        dispatch(request(check));
        checkService._delete(check)
            .then(
                check => {
                    dispatch(success(check));
                    dispatch(alertActions.success('Xoá Check thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(check) { return { type: checkConstants.DELETE_REQUEST, check: check } }
    function success(check) { return { type: checkConstants.DELETE_SUCCESS, check: check } }
    function failure(error) { return { type: checkConstants.DELETE_FAILURE, error } }
}