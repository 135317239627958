import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CInput,
    CAlert,
    CSelect,
    CFormGroup,
    CInputRadio,
    CBadge,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';

import { helps } from '../../_helpers';
import configs from 'src/appConfigs';

class TagsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            id: '',
            name: '',
            object: 'customers',
            color: 'light',
            form_header: 'Thêm Nhãn',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    componentDidMount() {
        const { tag } = this.props;

        if (!helps.isEmpty(tag)) {
            this.setState({
                id: tag.id,
                name: tag.name,
                old_name: tag.name,
                object: tag.object,
                color: tag.color,
                form_header: 'Cập nhật Nhãn',
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        let tag = {
            name: this.state.name,
            object: this.state.object,
            color: this.state.color,
        }

        if (!helps.isEmpty(this.state.id)) {
            tag['id'] = this.state.id;
        }

        if (this.handleValidation()) {
            this.props.handleSubmit(tag);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleClear(e) {
        e.preventDefault();
        this.setState({
            name: '',
            object: 'customers',
            color: 'light',
            form_header: 'Thêm Nhãn',
            errors: {},
        });
    }

    handleValidation() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        //console.log(fields);

        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }
        this.setState({ errors: errors });

        return formIsValid;
    }

    render() {

        return (
            <CRow>
                <CCol xl={12}>
                    <CForm
                        onSubmit={(e) => this.handleSubmit(e)}
                        noValidate
                        validated={this.state.formIsValid}>
                        <CCard>
                            <CCardHeader>
                                {this.state.form_header}
                            </CCardHeader>
                            <CCardBody>

                                <div className="mb-3">
                                    <CLabel htmlFor="input-name">Tên:</CLabel>
                                    <CInput
                                        type="name"
                                        name="name"
                                        id="input-name"
                                        required
                                        value={this.state.name}
                                        onChange={(e) => { this.handleChange('name', e) }}
                                    />
                                    {this.state.errors['name'] && <CAlert className="help-block" color="danger">{this.state.errors['name']}</CAlert>}
                                </div>
                                <div className="mb-3">
                                    <CLabel htmlFor="input-object">Dùng Nhãn cho đối tượng:</CLabel>
                                    <CSelect
                                        name='object'
                                        id='input-object'
                                    >
                                        {configs.objects && Object.keys(configs.objects).map((_key) => {
                                            return (
                                                <option value={_key} key={_key}>{configs.objects[_key]}</option>
                                            )
                                        }
                                        )}
                                    </CSelect>
                                </div>
                                <div className="mb-3">
                                    <div>
                                        <CLabel>Flagged color</CLabel>
                                    </div>
                                    {configs.colors && Object.keys(configs.colors).map((_key) => {
                                        return (
                                            <CFormGroup variant="custom-radio" className='mb-2'>
                                                <CInputRadio
                                                    custom
                                                    id={"color_" + _key}
                                                    name="color"
                                                    value={_key}
                                                    checked={this.state.color === _key}
                                                    onChange={(e) => { this.handleChange('color', e) }}
                                                />
                                                <CLabel variant="custom-checkbox" htmlFor={"color_" + _key} className="cursor-pointer">
                                                    <CBadge color={_key} className="p-2">{configs.colors[_key]}</CBadge>
                                                </CLabel>
                                            </CFormGroup>
                                        )
                                    }
                                    )}

                                </div>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                                <CButton type="button" color="secondary" className=" ml-2" onClick={(e) => { this.handleClear(e) }}>
                                    <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                    Xoá
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default TagsForm;

