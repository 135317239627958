import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { helps } from 'src/_helpers';
import { employeeActions } from '../../_actions';
import { userActions } from '../../_actions';
import EmployeeAddForm from '../../views/employees/EmployeeAddForm';
import configs from 'src/appConfigs';


class EmployeesAddContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            employee: {},
            form_header: "Thêm Mới Liên Hệ",
            action_status: 'add',
        }
    }

    componentDidMount() {
        this.getEmployees().then(() => {
            this.getEmployee();
        });

    }

    getEmployee() {
        let employee_id = this.props.match.params.id;
        const employees = this.props.employees;
        if (employee_id) {
            let employee = employees.filter((_employee) => {
                return _employee.id === employee_id;
            });
            this.setState({ form_header: "Chỉnh Sửa Nhân Viên: " + employee_id, action_status: 'edit', employee: employee[0] });
        }
    }

    async getEmployees() {
        const employees = this.props.employees;
        if (!employees) await this.props.getAll();
    }

    handleSubmit = (employee) => {
        let employee_id = this.props.match.params.id;
        if (helps.isEmpty(employee_id)) {
            this.props.addEmployee(employee);
        } else {
            this.props.updateEmployee(employee);
        }
        if (this.state.action_status === 'add') {
            var user = {};
            user['fullname'] = employee.fullname;
            user['username'] = helps.generateUsername(employee.fullname, employee.phone);
            user['password'] = helps.generatePassword();
            user['group'] = configs.app_name;
            user['role'] = 'staff';
            user['employee_id'] = employee.employee_id;
            this.props.addUser(user);
        }
        this.props.history.push('/employees');
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        let employee_id = this.props.match.params.id;
        const { employee } = this.state;
        if (!helps.isEmpty(employee_id) && helps.isEmpty(employee)) return (<Loading></Loading>);
        return (
            <ThePermission {... this.props} component="employees" action="edit">
                <EmployeeAddForm
                    {... this.props}
                    employee={this.state.employee}
                    form_header={this.state.form_header}
                    action_status={this.state.action_status}
                    handleSubmit={this.handleSubmit}
                />
            </ThePermission>
        );
    }
}
function mapStateToProps(state) {
    const { loading, employees } = state.employeeReducer;
    return { loading, employees };
}

const actionCreators = {
    getAll: employeeActions.getAll,
    addEmployee: employeeActions.addEmployee,
    updateEmployee: employeeActions.updateEmployee,
    addUser: userActions.addUser,
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(EmployeesAddContainer);
export { connectedFormContainer as EmployeesAddContainer };