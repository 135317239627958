import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTooltip,
    CButton,
    CInputCheckbox,
    CCollapse,
    CBadge,
    CInput,
    CInputGroup,
    CInputGroupText,
    CInputGroupAppend,
    CPopover,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import CurrencyInput from 'react-currency-input-field';

class ExitDeductions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            sh_s2: true,
            modals: [],
            assets_remain: [],
            selected_violations: [],
            selected_assets: [],
            others: [],
            other_label: '',
            other_value: 0,
            total: 0,
            total_violations: 0,
            total_assets: 0,
            total_others: 0,
        }

        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        const { exit } = this.props;
        if (exit && exit.deductions) {
            this.setState({
                selected_violations: !helps.isEmpty(exit.deductions.violations) ? exit.deductions.violations : this.state.violations,
                selected_assets: !helps.isEmpty(exit.deductions.assets) ? exit.deductions.assets : this.state.assets,
                others: !helps.isEmpty(exit.deductions.others) ? exit.deductions.others : this.state.others,
                total: exit.deductions.total ? exit.deductions.total : this.state.total,
                total_violations: exit.deductions.total_violations ? exit.deductions.total_violations : this.state.total_violations,
                total_assets: exit.deductions.total_assets ? exit.deductions.total_assets : this.state.total_assets,
                total_others: exit.deductions.total_others ? exit.deductions.total_others : this.state.total_others,
            });
        }
        this.getAssetsRemain();

    }

    getAssetsRemain() {
        const { assets, asset_items, items } = this.props;

        var _asset_items_in = [];
        var _asset_items_out = [];
        if (asset_items && asset_items.length > 0) {
            let index_in = 0;
            let index_out = 0;
            asset_items.forEach((asset_item) => {
                let asset = helps.getItemFromArr(assets, asset_item.asset_id);
                asset_item = Object.assign(asset_item, asset);
                let item = helps.getItemFromArr(items, asset_item.item_id);
                asset_item['item'] = item;

                if (asset_item.state === 'in') {
                    index_in += 1;
                    asset_item['index'] = index_in;
                    _asset_items_in.push(asset_item);
                } else {
                    index_out += 1;
                    asset_item['index'] = index_out;
                    _asset_items_out.push(asset_item);
                }
            });
        }
        const duplicates_in = _asset_items_in.reduce((acc, item) => {
            let newItem = acc.find((i) => i.asset_id === item.asset_id);
            if (newItem) {
                newItem.count += 1;
                if (newItem.count === 1) {
                    newItem.start_count = item.index;
                }
            } else {
                acc.push({ asset_id: item.asset_id, count: 1, start_count: item.index });
            }
            return acc;
        }, []);
        _asset_items_in.forEach((asset_item) => {
            let _duplicate = helps.getItemFromArr(duplicates_in, asset_item.asset_id, 'asset_id');
            asset_item['count'] = _duplicate.count;
            asset_item['start_count'] = _duplicate.start_count;
        })
        const duplicates_out = _asset_items_out.reduce((acc, item) => {
            let newItem = acc.find((i) => i.asset_id === item.asset_id);
            if (newItem) {
                newItem.count += 1;
                if (newItem.count === 1) {
                    newItem.start_count = item.index;
                }
            } else {
                acc.push({ asset_id: item.asset_id, count: 1, start_count: item.index });
            }
            return acc;
        }, []);
        _asset_items_out.forEach((asset_item) => {
            let _duplicate = helps.getItemFromArr(duplicates_out, asset_item.asset_id, 'asset_id');
            asset_item['count'] = _duplicate.count;
            asset_item['start_count'] = _duplicate.start_count;
        });

        let compare = [];
        let items_for_compare = _asset_items_out;
        if (!helps.isEmpty(items_for_compare) && items_for_compare.length > 0) {
            items_for_compare.forEach((asset_item) => {
                let _found_in = helps.getItemFromArr(_asset_items_in, asset_item.item_id, 'item_id');
                let quantity = _found_in.item_id ? _found_in.quantity : 0;
                let remain = parseInt(quantity) - parseInt(asset_item.quantity);
                //console.log(asset_item)
                let remain_item = asset_item.item;
                remain_item['remain'] = remain;
                var foundIndex = compare.findIndex(item => item.id === remain_item.id);
                if (foundIndex < 0) {
                    if (remain < 0) {
                        compare.push(remain_item);
                    }
                } else {
                    if (remain < 0) {
                        let foundItem = compare[foundIndex];
                        foundItem['remain'] = remain_item['remain'] + foundItem['remain'];
                        compare[foundIndex] = foundItem;
                    }
                }

            });
        }
        this.setState({ assets_remain: compare });
    }

    handleSelectItem(e, id, type) {
        //e.preventDefault();
        //console.log(id, type)
        const { violations } = this.props;
        let selected_violations = !helps.isEmpty(this.state.selected_violations) ? this.state.selected_violations.filter(violation => violation.id !== id) : [];
        let selected_violation = helps.getItemFromArr(violations, id);
        let selected_assets = !helps.isEmpty(this.state.selected_assets) ? this.state.selected_assets.filter(asset => asset.id !== id) : [];
        let selected_asset = helps.getItemFromArr(this.state.assets_remain, id);

        if (e.target.checked) {
            switch (type) {
                case 'selected_violations':
                    if (!helps.isEmpty(selected_violation)) {
                        selected_violations.push(selected_violation);
                        e.target.checked = true;
                    }
                    this.setState({ selected_violations: selected_violations });
                    break;
                case 'selected_assets':
                    if (!helps.isEmpty(selected_asset)) {
                        selected_assets.push(selected_asset);
                        e.target.checked = true;
                    }
                    this.setState({ selected_assets: selected_assets });
                    break;
                default:
            }
        } else {
            switch (type) {
                case 'selected_violations':
                    this.setState({ selected_violations: selected_violations });
                    break;
                case 'selected_assets':
                    this.setState({ selected_assets: selected_assets });
                    break;
                default:
            }
        }
        let total_violations = 0;
        let total_assets = 0;
        let others = this.state.others;
        let total_others = 0;
        if (!helps.isEmpty(selected_violations)) {
            selected_violations.forEach(violation => {
                total_violations = parseFloat(total_violations) + parseFloat(violation.fines);
            })
        }
        if (!helps.isEmpty(selected_assets)) {
            selected_assets.forEach(item => {
                total_assets = parseFloat(total_assets) + parseFloat(item.price) * Math.abs(item.remain);
            })
        }
        if (!helps.isEmpty(others)) {
            others.forEach(other => {
                total_others = parseFloat(total_others) + parseFloat(other.value);
            })
        }
        let total = parseFloat(total_violations) + parseFloat(total_assets) + parseFloat(total_others);
        this.setState({ total_violations: total_violations, total_assets: total_assets, total_others: total_others, total: total });
    }

    handleChange(e) {
        let value = e.target.value;
        let name = e.target.name
        this.setState({
            [name]: value
        });
    }

    addOther() {
        let { others, other_label, other_value, total_assets, total_violations } = this.state;
        if (helps.isEmpty(other_label)) {
            return;
        } else {
            let total_others = 0;
            let other = {
                id: helps.convertToSlug(helps.toNonAccentVietnamese(other_label)),
                label: other_label,
                value: other_value,
            };

            others.push(other);
            this.setState({ others: others });

            if (!helps.isEmpty(others)) {
                others.forEach(other => {
                    total_others = parseFloat(total_others) + parseFloat(other.value);
                })
            }
            let total = parseFloat(total_violations) + parseFloat(total_assets) + parseFloat(total_others);
            this.setState({ total_others: total_others, total: total });

            this.handleUpdateExit();

            this.setState({ other_label: '', other_value: 0 });
        }
    }

    deleteOther(other) {
        let { others, total_assets, total_violations } = this.state;
        let total_others = 0;

        others = others.filter(_other => _other.id !== other.id);
        this.setState({ others: others });

        if (!helps.isEmpty(others)) {
            others.forEach(other => {
                total_others = parseFloat(total_others) + parseFloat(other.value);
            })
        }
        let total = parseFloat(total_violations) + parseFloat(total_assets) + parseFloat(total_others);
        this.setState({ total_others: total_others, total: total });

        this.handleUpdateExit();
    }

    handleUpdateExit() {
        const { employee, exit } = this.props;
        let deductions = {
            violations: this.state.selected_violations,
            assets: this.state.selected_assets,
            others: this.state.others,
            total: this.state.total,
            total_violations: this.state.total_violations,
            total_assets: this.state.total_assets,
            total_others: this.state.total_others,
        }
        exit['deductions'] = deductions;
        employee['exit'] = exit;
        this.props.updateField(employee, 'exit');
        this.props.handleProcess();
    }

    toggleModal = (item) => {
        let modals = this.state.modals;
        const position = modals.indexOf(item);
        let newItems = modals.slice();
        if (position !== -1) {
            newItems.splice(position, 1)
        } else {
            newItems = [...modals, item]
        }
        this.setState({ modals: newItems });
    }

    render() {
        const { employee, violations, } = this.props;
        return (
            <CRow>
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader style={{ backgroundColor: '#EEE' }}>
                            <CRow>
                                <CCol>
                                    III. CÁC KHOẢN ĐỐI TRỪ
                                    {this.state.errors['deductions'] && <div className='mb-1 mt-1'><small className="text-danger">{this.state.errors['deductions']}</small></div>}
                                </CCol>
                                <CCol className={'text-right'}>
                                    <CTooltip content={this.state.sh_s2 ? 'Ẩn' : 'Hiện'}>
                                        <CButton type="button" color="secondary" size="sm" onClick={() => { this.setState({ sh_s2: !this.state.sh_s2 }) }} title={this.state.sh_s2 ? 'Ẩn' : 'Hiện'} className='ml-2'>
                                            <CIcon name={this.state.sh_s2 ? 'cil-chevron-top' : 'cil-chevron-bottom'} className="mfe-1 mt-0"></CIcon>
                                        </CButton>
                                    </CTooltip>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCollapse show={this.state.sh_s2}>
                            <CCardBody>
                                <CRow className={'mb-2 mt-2'}>
                                    <CCol xs={3}>
                                        <h6 className='font-weight-bold'>Biên Bản Vi Phạm:</h6>
                                    </CCol>
                                    <CCol xs={6}>
                                        <CRow>
                                            <CCol>
                                                <div className='text-right mb-1'>
                                                    <CTooltip content={'Danh Sách BBVP'}>
                                                        <CButton type="button" color="primary" size="sm" onClick={() => { this.toggleModal('violations') }}>
                                                            <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                                            BBVP
                                                        </CButton>
                                                    </CTooltip>
                                                </div>
                                                <CModal
                                                    show={this.state.modals.includes('violations')}
                                                    size='lg'
                                                    onClose={() => { this.toggleModal('violations'); }}
                                                    onClosed={() => { this.handleUpdateExit(); }}
                                                    className='text-left'
                                                >
                                                    <CModalHeader closeButton>
                                                        <h5><b>Danh Sách BBVP</b> _ {employee.fullname} ({employee.employee_id})</h5>
                                                    </CModalHeader>
                                                    <CModalBody>
                                                        <div className='table-responsive'>
                                                            <table className='table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th className='text-center'>Chọn</th>
                                                                        <th>Tiêu đề</th>
                                                                        <th>Ngày vi phạm</th>
                                                                        <th>Hình thức xử lý</th>
                                                                        <th className='text-right'>Mức phạt</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {!helps.isEmpty(violations) && violations.map((violation) => {
                                                                        let term = violation.violation_term;
                                                                        let found = helps.getItemFromArr(this.state.selected_violations, violation.id);
                                                                        return (
                                                                            <tr key={'violations-list-' + violation.id}>
                                                                                <td className='text-center'>
                                                                                    <CTooltip content={'Chọn'}>
                                                                                        <CInputCheckbox
                                                                                            id={'select-violation-' + violation.id}
                                                                                            value={violation.id}
                                                                                            checked={!helps.isEmpty(found)}
                                                                                            className={'cursor-pointer'}
                                                                                            onChange={(e) => { this.handleSelectItem(e, violation.id, 'selected_violations') }}
                                                                                        />
                                                                                    </CTooltip>
                                                                                </td>
                                                                                <td>{violation.title}</td>
                                                                                <td>{helps.formatDateTime_from_Timestamp(violation.violation_date)}</td>
                                                                                <td><CBadge color={term.color} className='p-1'>{term.title}</CBadge></td>
                                                                                <td className='text-right font-weight-bold'>
                                                                                    {helps.formatCurrency(violation.fines)}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                    {helps.isEmpty(violations) && <tr><td colSpan={5}><span className='text-muted'>Chưa có BBVP nào!</span></td></tr>}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </CModalBody>
                                                    <CModalFooter>
                                                        <CTooltip content={'Đóng'}>
                                                            <CButton type="button" color="secondary" size="sm" onClick={() => { this.toggleModal('violations') }}>
                                                                <CIcon name="cil-x" className="mfe-1 mt-0"></CIcon>
                                                                Đóng
                                                            </CButton>
                                                        </CTooltip>
                                                    </CModalFooter>
                                                </CModal>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol>
                                                <div className='table-responsive'>
                                                    <table className='table'>
                                                        <tbody>
                                                            {!helps.isEmpty(this.state.selected_violations) && this.state.selected_violations.map((violation, index) => {
                                                                let term = violation.violation_term;
                                                                return (
                                                                    <tr key={'selected-violations-list-' + violation.id}>
                                                                        <td className='text-center'>{index + 1}</td>
                                                                        <td>{violation.title}</td>
                                                                        <td>{helps.formatDateTime_from_Timestamp(violation.violation_date)}</td>
                                                                        <td><CBadge color={term.color} className='p-1'>{term.title}</CBadge></td>
                                                                        <td className='text-right font-weight-bold'>
                                                                            {helps.formatCurrency(violation.fines)}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                    <CCol xs={3} className={'text-center'}>
                                        <b>{helps.formatCurrency(this.state.total_violations)}</b>
                                    </CCol>
                                </CRow>
                                <hr />
                                <CRow className={'mb-2'}>
                                    <CCol xs={3}>
                                        <h6 className='font-weight-bold'>QTT & CCHT:</h6>
                                    </CCol>
                                    <CCol xs={6}>
                                        <div className='text-right mb-1'>
                                            <CTooltip content={'Danh Sách BBVP'}>
                                                <CButton type="button" color="primary" size="sm" onClick={() => { this.toggleModal('assets') }}>
                                                    <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                                    QTT & CCHT
                                                </CButton>
                                            </CTooltip>
                                        </div>
                                        <CModal
                                            show={this.state.modals.includes('assets')}
                                            size='lg'
                                            onClose={() => { this.handleUpdateExit(); this.toggleModal('assets'); }}
                                            onClosed={() => { this.handleUpdateExit(); }}
                                            className='text-left'
                                        >
                                            <CModalHeader closeButton>
                                                <h5><b>Danh Sách QTT & CCHT Còn Thiếu</b> _ {employee.fullname} ({employee.employee_id})</h5>
                                            </CModalHeader>
                                            <CModalBody>
                                                <div className='table-responsive'>
                                                    <table className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th className='text-center'>Chọn</th>
                                                                <th>Mã</th>
                                                                <th>Tên</th>
                                                                <th className='text-right'>Đơn giá</th>
                                                                <th>Số lượng thiếu</th>
                                                                <th className='text-right'>Thành tiền</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!helps.isEmpty(this.state.assets_remain) && this.state.assets_remain.map((item) => {
                                                                let title = (item.remain === 0) ? 'Đủ số lượng' : 'Thiếu: ' + Math.abs(item.remain);
                                                                let _class = (item.remain === 0) ? 'text-dark' : 'text-danger font-weight-bold';
                                                                let found = helps.getItemFromArr(this.state.selected_assets, item.id);
                                                                let item_total = item.price * Math.abs(item.remain);
                                                                return (
                                                                    <tr key={'assets-list-' + item.id}>
                                                                        <td className='text-center'>
                                                                            <CTooltip content={'Chọn'}>
                                                                                <CInputCheckbox
                                                                                    id={'select-asset-' + item.id}
                                                                                    value={item.id}
                                                                                    checked={!helps.isEmpty(found)}
                                                                                    className={'cursor-pointer'}
                                                                                    onChange={(e) => { this.handleSelectItem(e, item.id, 'selected_assets'); }}
                                                                                />
                                                                            </CTooltip>
                                                                        </td>
                                                                        <td>
                                                                            {item.code}
                                                                        </td>
                                                                        <td>{item.name}</td>
                                                                        <td className='text-right'>
                                                                            {helps.formatCurrency(item.price)}
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <CPopover content={title}>
                                                                                <b className={_class}>{item.remain}</b>
                                                                            </CPopover>
                                                                        </td>
                                                                        <td className='text-right'>
                                                                            {helps.formatCurrency(item_total)}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            {helps.isEmpty(this.state.assets_remain) && <tr><td colSpan={5}><span className='text-muted'>Đã thu hồi đủ số lượng QTT!</span></td></tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CModalBody>
                                            <CModalFooter>
                                                <CTooltip content={'Đóng'}>
                                                    <CButton type="button" color="secondary" size="sm" onClick={() => { this.toggleModal('assets') }}>
                                                        <CIcon name="cil-x" className="mfe-1 mt-0"></CIcon>
                                                        Đóng
                                                    </CButton>
                                                </CTooltip>
                                            </CModalFooter>
                                        </CModal>
                                        <div className='table-responsive'>
                                            <table className='table'>
                                                {!helps.isEmpty(this.state.selected_assets) &&
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>#</th>
                                                            <th>Mã</th>
                                                            <th>Tên</th>
                                                            <th className='text-right'>Đơn giá</th>
                                                            <th className='text-center'>Số lượng thiếu</th>
                                                            <th className='text-right'>Thành tiền</th>
                                                        </tr>
                                                    </thead>}
                                                <tbody>
                                                    {!helps.isEmpty(this.state.selected_assets) && this.state.selected_assets.map((item, index) => {
                                                        let title = (item.remain === 0) ? 'Đủ số lượng' : 'Thiếu: ' + Math.abs(item.remain);
                                                        let _class = (item.remain === 0) ? 'text-dark' : 'text-danger font-weight-bold';
                                                        let item_total = item.price * Math.abs(item.remain);
                                                        return (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    {index + 1}
                                                                </td>
                                                                <td>
                                                                    {item.code}
                                                                </td>
                                                                <td>{item.name}</td>
                                                                <td className='text-right'>
                                                                    {helps.formatCurrency(item.price)}
                                                                </td>
                                                                <td className='text-center'>
                                                                    <CPopover content={title}>
                                                                        <b className={_class}>{item.remain}</b>
                                                                    </CPopover>
                                                                </td>
                                                                <td className='text-right'>{helps.formatCurrency(item_total)}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CCol>
                                    <CCol xs={3} className={'text-center'}>
                                        <b>{helps.formatCurrency(this.state.total_assets)}</b>
                                    </CCol>
                                </CRow>
                                <hr />
                                <CRow className={'mb-2'}>
                                    <CCol xs={3}>
                                        <h6 className='font-weight-bold'>Các khoản Đối Trừ khác:</h6>
                                    </CCol>
                                    <CCol xs={6}>
                                        <div className='text-right mb-1'>
                                            <CTooltip content={'Thêm khoản đối trừ khác'}>
                                                <CButton type="button" color="primary" size="sm" onClick={() => { this.toggleModal('others') }}>
                                                    <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                                    Thêm
                                                </CButton>
                                            </CTooltip>
                                        </div>
                                        <CModal
                                            show={this.state.modals.includes('others')}
                                            size='lg'
                                            onClose={() => { this.toggleModal('others'); }}
                                            onClosed={() => { this.addOther(); }}
                                            className='text-left'
                                        >
                                            <CModalHeader closeButton>
                                                <h5><b>Thêm Khoản Đối Trừ Khác</b> _ {employee.fullname} ({employee.employee_id})</h5>
                                            </CModalHeader>
                                            <CModalBody>
                                                <CRow>
                                                    <CCol xs={8}>
                                                        <CInput
                                                            type='text'
                                                            name='other_label'
                                                            placeholder='Nội dung ... '
                                                            defaultValue={this.state.other_label}
                                                            onChange={(e) => { this.handleChange(e) }}
                                                        />
                                                    </CCol>
                                                    <CCol xs={4}>
                                                        <CInputGroup>
                                                            <CurrencyInput
                                                                name="other_value"
                                                                id="input-other_value"
                                                                placeholder='Số tiền ... '
                                                                className='form-control text-dark'
                                                                decimalsLimit={2}
                                                                onValueChange={(value, name) => { this.setState({ [name]: value }) }}
                                                            />
                                                            <CInputGroupAppend>
                                                                <CInputGroupText className={'bg-secondary text-dark'}>
                                                                    ₫
                                                                </CInputGroupText>
                                                            </CInputGroupAppend>
                                                        </CInputGroup>
                                                    </CCol>
                                                </CRow>
                                            </CModalBody>
                                            <CModalFooter>
                                                <CTooltip content={'Đóng'}>
                                                    <CButton type="button" color="primary" size="sm" onClick={() => { this.toggleModal('others'); }}>
                                                        <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                                        Thêm
                                                    </CButton>
                                                </CTooltip>
                                            </CModalFooter>
                                        </CModal>
                                        <div className='table-responsive'>
                                            <table className='table'>
                                                <tbody>
                                                    {!helps.isEmpty(this.state.others) && this.state.others.map((other, index) => {
                                                        return (
                                                            <tr key={other.id}>
                                                                <td style={{ width: '50px' }}>
                                                                    {index + 1}
                                                                </td>
                                                                <td style={{ width: '40%' }}>{other.label}</td>
                                                                <td className='text-right'>
                                                                    {helps.formatCurrency(other.value)}
                                                                </td>
                                                                <td className='text-center' style={{ width: '50px' }}>
                                                                    <CTooltip content={'Xóa'}>
                                                                        <CButton type="button" color='white' size="sm" onClick={() => { this.deleteOther(other); }}>
                                                                            <CIcon name="cil-x" className='text-danger'></CIcon>
                                                                        </CButton>
                                                                    </CTooltip>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CCol>
                                    <CCol xs={3} className={'text-center'}>
                                        <b>{helps.formatCurrency(this.state.total_others)}</b>
                                    </CCol>
                                </CRow>
                                <hr />
                                <CRow className={'mb-2'}>
                                    <CCol xs={3}>

                                    </CCol>
                                    <CCol xs={6} className={'text-right'}>
                                        <h6 className='font-weight-bold'>Tổng:</h6>
                                    </CCol>
                                    <CCol xs={3} className={'text-center'}>
                                        <b>{helps.formatCurrency(this.state.total)}</b>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCollapse>
                    </CCard>
                </CCol>
            </CRow >
        )
    }
}
export default ExitDeductions;

