import { provinceConstants } from "src/_constants";
import { provinceService } from "src/_services/province.service";
import { alertActions } from ".";

export const provinceActions = {
    getAll,
    add,
    update,
    _delete,
}

function getAll(params) {
    return dispatch => {
        dispatch(request());
        provinceService.getAll(params)
            .then(
                provinces => dispatch(success(provinces)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: provinceConstants.GET_LIST_REQUEST } }
    function success(provinces) { return { type: provinceConstants.GET_LIST_SUCCESS, provinces } }
    function failure(error) { return { type: provinceConstants.GET_LIST_FAILURE, error } }
}

function add(province) {
    return dispatch => {
        dispatch(request(province));
        provinceService.add(province)
            .then(
                province => dispatch(success(province)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(province) { return { type: provinceConstants.ADD_REQUEST, province: province } }
    function success(province) { return { type: provinceConstants.ADD_SUCCESS, province: province } }
    function failure(error) { return { type: provinceConstants.ADD_FAILURE, error } }
}

function update(province) {
    return dispatch => {
        dispatch(request(province));
        provinceService.update(province)
            .then(
                province => dispatch(success(province)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(province) { return { type: provinceConstants.EDIT_REQUEST, province: province } }
    function success(province) { return { type: provinceConstants.EDIT_SUCCESS, province: province } }
    function failure(error) { return { type: provinceConstants.EDIT_FAILURE, error } }
}

function _delete(province) {
    return dispatch => {
        dispatch(request(province));
        provinceService.deleteprovince(province)
            .then(
                province => dispatch(success(province)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(province) { return { type: provinceConstants.DELETE_REQUEST, province: province } }
    function success(province) { return { type: provinceConstants.DELETE_SUCCESS, province: province } }
    function failure(error) { return { type: provinceConstants.DELETE_FAILURE, error } }
}