import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { toggleSidebar, rolesReducer, generalReducer } from './general.reducer';
import { userReducer } from './users.reducer';
import { employeeReducer, violationReducer, candidateReducer } from './employees';
import { noteReducer } from './note.reducer';
import { customerReducer } from './customer.reducer';
import { contractReducer } from './contract.reducer';
import { tagReducer } from './tag.reducer';
import { provinceReducer } from './provinces.reducer';
import { fileReducer } from './file.reducer';
import { contactReducer } from './contact.reducer';
import { projectReducer } from './project.reducer';
import { positionReducer } from './position.reducer';
import { maneuverReducer } from './maneuver.reducer';
import { checkReducer } from './check.reducer';
import { taskReducer } from './task.reducer';
import { taxonomyReducer, bookReducer } from './accounting';
import { notificationReducer } from './notification.reducer';
import { documentReducer } from './document.reducer';
import { housingReducer } from './housing.reducer';
import { vendorReducer } from './vendor.reducer';
import { itemReducer, storeReducer, assetReducer } from './assets';


const rootReducer = combineReducers({
  generalReducer,
  toggleSidebar,
  authentication,
  userReducer,
  alert,
  rolesReducer,
  employeeReducer,
  noteReducer,
  customerReducer,
  contractReducer,
  tagReducer,
  provinceReducer,
  fileReducer,
  contactReducer,
  projectReducer,
  positionReducer,
  maneuverReducer,
  checkReducer,
  taskReducer,
  taxonomyReducer,
  bookReducer,
  notificationReducer,
  violationReducer,
  candidateReducer,
  documentReducer,
  housingReducer,
  vendorReducer,
  itemReducer,
  storeReducer,
  assetReducer,
});

export default rootReducer;