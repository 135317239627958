import { storeConstants } from 'src/_constants';
import { storeService } from 'src/_services';
import { alertActions } from 'src/_actions';

export const storeActions = {
    getAll,
    add,
    getById,
    update,
    updateField,
    _delete,
};


function getAll() {
    return dispatch => {
        dispatch(request());
        return storeService.getAll()
            .then(
                stores => {
                    dispatch(success(stores));
                    return stores;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: storeConstants.GETALL_REQUEST } }
    function success(stores) { return { type: storeConstants.GETALL_SUCCESS, stores } }
    function failure(error) { return { type: storeConstants.GETALL_FAILURE, error } }
}

function getById(id) {

    return dispatch => {
        dispatch(request());
        storeService.getById(id)
            .then(
                store => dispatch(success(store)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: storeConstants.GET_REQUEST } }
    function success(store) { return { type: storeConstants.GET_SUCCESS, store } }
    function failure(error) { return { type: storeConstants.GET_FAILURE, error } }
}

function add(store) {
    return dispatch => {
        dispatch(request(store));

        return storeService.add(store)
            .then(
                store => {
                    dispatch(success(store));
                    dispatch(alertActions.success('Thêm mới Tài Sản thành công!'));
                    return store;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(store) { return { type: storeConstants.ADD_REQUEST, store } }
    function success(store) { return { type: storeConstants.ADD_SUCCESS, store } }
    function failure(error) { return { type: storeConstants.ADD_FAILURE, error } }
}

function update(store) {
    return dispatch => {
        dispatch(request(store));
        storeService.update(store)
            .then(
                store => {
                    dispatch(success(store));
                    dispatch(alertActions.success('Cập nhật thông tin Tài Sản thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(store) { return { type: storeConstants.UPDATE_REQUEST, store } }
    function success(store) { return { type: storeConstants.UPDATE_SUCCESS, store } }
    function failure(error) { return { type: storeConstants.UPDATE_FAILURE, error } }
}

function updateField(store, field) {
    return dispatch => {
        dispatch(request(store, field));
        storeService.updateField(store, field)
            .then(
                store => {
                    dispatch(success(store));
                    dispatch(alertActions.success('Cập nhật thông tin Tài Sản thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(store, field) { return { type: storeConstants.UPDATE_FIELD_REQUEST, store, field } }
    function success(store) { return { type: storeConstants.UPDATE_FIELD_SUCCESS, store } }
    function failure(error) { return { type: storeConstants.UPDATE_FIELD_FAILURE, error } }
}

function _delete(store) {
    return dispatch => {
        dispatch(request(store));
        return storeService._delete(store)
            .then(
                store => {
                    dispatch(success(store));
                    dispatch(alertActions.success('Xoá Tài Sản thành công!'));
                    return store;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(store) { return { type: storeConstants.DELETE_REQUEST, store } }
    function success(store) { return { type: storeConstants.DELETE_SUCCESS, store } }
    function failure(error) { return { type: storeConstants.DELETE_FAILURE, error } }
}


