import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CRow, CCol,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import Vendor from 'src/views/vendors/Vendor';
import { vendorActions, fileActions, provinceActions, employeeActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import { NotesContainer } from '../notes';
import { FilesContainer } from '../files';
import { TasksComponentContainer } from '../tasks/TasksComponentContainer';


class VendorContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: {},
            object: 'vendors',
        }
    }

    componentDidMount() {
        this.getEmployees();

        this.getVendors().then(vendors => {
            this.getVendor().then(() => {
                this.getFiles().then((files) => {
                    var vendor = this.state.vendor;
                    vendor['files'] = files;
                    this.setState({ vendor: vendor });
                });
            });
        });

    }

    async getVendor() {
        let vendor_id = this.props.match.params.id;
        const { vendors, provinces } = this.props;
        var vendor = {};
        if (vendor_id && vendors) {
            let vendor = helps.getItemFromArr(vendors, vendor_id);
            var province = helps.getItemFromArr(provinces, vendor.province_id);
            vendor['province'] = province.name;
            this.setState({ vendor: vendor });
        }
        return vendor;
    }

    async getFiles() {
        if (!helps.isEmpty(this.state.vendor)) {
            const files = await this.props.getFiles('vendors', this.state.vendor.id);

            return files;
        }
    }

    async getVendors() {
        return await this.props.getAll('all');
    }

    async getUsers() {
        const { users } = this.props;
        if (helps.isEmpty(users)) await this.props.getUsers();
    }

    async getEmployees() {
        const { employees } = this.props;
        if (helps.isEmpty(employees)) await this.props.getEmployees();
    }

    updateObjectTags(vendor) {
        this.props.updateField(vendor, 'tags');
    }


    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.props.vendors)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.state.vendor)) {
            return (
                <Loading></Loading>
            );
        }
        const vendor = this.state.vendor;
        return (
            <ThePermission {... this.props} component="vendors" action="view">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md={7} sm={12} className='mb-2'>
                                Nhà Cung Cấp: <b>{vendor.name}</b>
                                {vendor.address && <div><small className='text-muted' style={{ fontSize: '13px' }}><CIcon name="cil-location-pin"></CIcon> <i>{vendor.address}</i></small></div>}
                            </CCol>
                            <CCol md={5} sm={12} className='mb-2 text-right'>
                                <ThePermission {... this.props} component="vendors" action="edit">
                                    <CButton
                                        color="primary"
                                        size="sm"
                                        title="Chỉnh Sửa"
                                        onClick={() => { this.props.history.push(`/vendors/add_edit/${vendor.id}`) }}
                                    >
                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                        Chỉnh Sửa Nhà Cung Cấp
                                    </CButton>
                                </ThePermission>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CTabs activeTab="info">
                            <CNav variant="tabs">
                                <CNavItem>
                                    <CNavLink data-tab="info">
                                        <h5>Thông Tin</h5>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="tasks">
                                        <h5>Công Việc</h5>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="notes">
                                        <h5>Ghi Chú</h5>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="files">
                                        <h5>Tài Liệu</h5>
                                    </CNavLink>
                                </CNavItem>
                            </CNav>
                            <CTabContent className="py-3">
                                <CTabPane data-tab="info">
                                    <Vendor {... this.props} vendor={vendor} updateObjectTags={(object_value) => { this.updateObjectTags(object_value) }} />
                                </CTabPane>
                                <CTabPane data-tab="tasks">
                                    <TasksComponentContainer {... this.props} object={this.state.object} object_id={vendor.id} />
                                </CTabPane>
                                <CTabPane data-tab="notes">
                                    <NotesContainer {... this.props} object={this.state.object} object_id={vendor.id} />
                                </CTabPane>
                                <CTabPane data-tab="files">
                                    <FilesContainer {... this.props} object={this.state.object} object_id={vendor.id} />
                                </CTabPane>
                            </CTabContent>
                        </CTabs>
                    </CCardBody>
                </CCard>

            </ThePermission >

        );
    }
}

function mapStateToProps(state) {
    const { loading, vendors } = state.vendorReducer;
    const { users } = state.userReducer;
    const { provinces } = state.provinceReducer;
    const { notes } = state.noteReducer;
    const { files } = state.fileReducer;
    const { employees } = state.employeeReducer;
    return { loading, vendors, users, notes, files, provinces, employees };
}

const actionCreators = {
    getAll: vendorActions.getAll,
    updateVendor: vendorActions.update,
    updateField: vendorActions.updateField,
    getProvinces: provinceActions.getAll,
    uploadFile: fileActions.uploadFile,
    deleteFile: fileActions.deleteFile,
    getFiles: fileActions.getAll,
    getEmployees: employeeActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(VendorContainer);
export { connectedContainer as VendorContainer };