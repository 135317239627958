import { db } from '../dbConfigs';

export const housingService = {
    getAll,
    add,
    getById,
    update,
    updateField,
    _delete,
};

async function getAll() {
    var ref = db.collection('housings');
    var query = ref.orderBy('create_date', 'desc');
    const snapshot = await query.get();
    const housings = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));

    if (housings && housings.length > 0) {
        Promise.all(
            housings.map(async (housing, index) => {
                if (housing.create_date) {
                    var _date = housing['create_date'];
                    housing['create_date'] = _date.toDate();
                }
                housing['index'] = index + 1;
            })
        );
    }

    return housings;
}

async function getById(id) {
    try {
        const doc = await db.collection("housings").doc(id)
            .get();
        if (doc.exists) {
            return doc.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        console.log("Error getting document:", error);
    }
}

async function add(housing) {
    try {
        const docRef = await db.collection("housings").add(housing);
        housing.id = docRef.id;
        update(housing);
        console.log("Firebase: housing has been added successfully!");
        return housing;
    } catch (error) {
        console.error("Error adding housing: ", error);
    }
}

async function update(housing) {
    //console.log(housing);
    let dbRef = db.collection("housings").doc(housing['id']);

    try {
        //console.log(housing);
        await dbRef.update(housing).then(() => {
            console.log("Firebase: housing has been updated successfully!");
        });

        return housing;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function updateField(housing, field) {
    //console.log(housing);
    let dbRef = db.collection("housings").doc(housing['id']);

    try {
        console.log(field, housing[field]);
        await dbRef.update({
            [field]: housing[field]
        }).then(() => {
            console.log("Firebase: housing field has been updated successfully!");
        });

        return housing;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function _delete(housing) {
    try {
        await db.collection("housings").doc(housing.id).delete().then((housing) => {
            return housing;
        });
    } catch (error) {
        console.error("Error: ", error);
    }
}
