export const maneuverConstants = {
    GET_LIST_REQUEST: 'MANEUVERS_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'MANEUVERS_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'MANEUVERS_GET_LIST_FAILURE',

    ADD_REQUEST: 'MANEUVERS_ADD_REQUEST',
    ADD_SUCCESS: 'MANEUVERS_ADD_SUCCESS',
    ADD_FAILURE: 'MANEUVERS_ADD_FAILURE',

    EDIT_REQUEST: 'MANEUVERS_EDIT_REQUEST',
    EDIT_SUCCESS: 'MANEUVERS_EDIT_SUCCESS',
    EDIT_FAILURE: 'MANEUVERS_EDIT_FAILURE',

    DELETE_REQUEST: 'MANEUVERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'MANEUVERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'MANEUVERS_DELETE_FAILURE',
}