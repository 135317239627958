import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CInput,
    CSelect,
    CCard,
    CCardHeader,
    CCardBody,
    CCardFooter,
    CInputCheckbox,
    CFormGroup,
    CContainer,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText,
    CTextarea,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import TimePicker from 'react-time-picker';

class AreasForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            form_header: 'Thêm Khu Vực',
            id: '',
            name: '',
            description: '',
            added_time: new Date(),
            added_by: this.props.user.id,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        this.setState({
            errors: {},
        });

        const { area } = this.props;
        //console.log(area);
        if (!helps.isEmpty(area) && this.props.action === 'edit') {
            this.setState({
                form_header: 'Chỉnh Sửa Khu Vực',
                id: area.id,
                name: area.name,
                description: area.description,
                added_time: area.added_time,
                added_by: area.added_by,
            });
        }
    }

    handleSubmit(e) {
        const { project } = this.props;
        e.preventDefault();
        if (this.handleValidation()) {
            let area = {
                id: this.state.id,
                name: this.state.name,
                description: this.state.description,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
            }
            let areas = !helps.isEmpty(project.areas) ? project.areas : [];
            areas.push(area);
            project['areas'] = areas;
            this.props.updateField(project, 'areas');
            if (this.props.action === 'add') {
                this.props.handleCancelForm();
            } else {
                this.props.toggleEditForms();
            }
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
        if (field === 'name' && this.props.action === 'add') {
            let id = helps.convertToSlug(helps.toNonAccentVietnamese(fields[field]));
            this.setState({ id: id });
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập tên Khu Vực";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCancel(e) {
        e.preventDefault();
        if (helps.isEmpty(this.state.id)) {
            this.props.handleCancelForm();
        } else {
            this.props.toggleEditForms();
        }

    }

    render() {

        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader className={'bg-secondary text-dark'}>
                                {this.state.form_header}:
                            </CCardHeader>
                            <CCardBody>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-name">Tên Khu Vực:</CLabel>
                                        <CInput
                                            type='text'
                                            name="name"
                                            id="input-name"
                                            value={this.state.name}
                                            onChange={(e) => { this.handleChange('name', e) }}
                                        />
                                        <small className="help-block text-danger">{this.state.errors['name']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-description">Mô Tả:</CLabel>
                                        <CTextarea
                                            type='text'
                                            name="description"
                                            id="input-description"
                                            value={this.state.description}
                                            onChange={(e) => { this.handleChange('description', e) }}
                                        >
                                        </CTextarea>
                                        <small className="help-block text-danger">{this.state.errors['description']}</small>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" size='sm' color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                                <CButton type="button" size='sm' color="secondary" onClick={(e) => { this.handleCancel(e) }} className="ml-3">
                                    <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                    Huỷ
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default AreasForm;

