import { generalConstants } from '../_constants';
import { generalService } from '../_services';
import { userService } from 'src/_services/user.service';
import { alertActions } from './alert.actions';
import { helps } from 'src/_helpers';

export const generalActions = {
    toggleSidebar,
    getAllRoles,
    updateRoles,
    getPermission,

    getApprovals,
    addApproval,
    updateApproval,
}
/**
 * 
 * @param {boolean} sidebarShow 
 * @returns new sidebar status
 */
function toggleSidebar(sidebarShow) {
    return dispatch => {
        dispatch(request(sidebarShow));
        generalService.toggleSidebar(sidebarShow);
    };

    function request(sidebarShow) { return { type: generalConstants.SET_SIDEBAR, sidebarShow } }
}

function getPermission(roles, _object, _action, _alertOut = false) {
    return dispatch => {
        const user = JSON.parse(localStorage.getItem('user'));

        // check again, if user not found in Storage, force logout
        if (!user.id || !user.role) {
            userService.logout();
        }

        const _permission = helps.checkPermission(roles, user.role, _object, _action);

        if (_alertOut && !_permission) {

            dispatch(alertActions.error("You don't have permission to access this component."));

        }

        return _permission;
    }
}
/**
 * get all user roles from /roles/
 * @returns 
 */
function getAllRoles(isForced = false) {
    return dispatch => {
        dispatch(request(isForced));
        generalService.getAllRoles(isForced)
            .then(
                roles => dispatch(success(roles)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(isForced) { return { type: generalConstants.ROLES_GET_ALL_REQUEST, isForced } }
    function success(roles) { return { type: generalConstants.ROLES_GET_ALL_SUCCESS, roles } }
    function failure(error) { return { type: generalConstants.ROLES_UPDATE_ROLES_FAILURE, error } }
}
/**
 * update Roles to /roles/
 * 
 * @returns roles
 */
function updateRoles(roles) {
    return dispatch => {
        dispatch(request(roles));

        generalService.updateRoles(roles)
            .then(
                roles => {
                    dispatch(success(roles));
                    dispatch(alertActions.success('User Roles have been updated successfully.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(roles) { return { type: generalConstants.ROLES_UPDATE_ROLES_REQUEST, roles } }
    function success(roles) { return { type: generalConstants.ROLES_UPDATE_ROLES_SUCCESS, roles } }
    function failure(error) { return { type: generalConstants.ROLES_UPDATE_ROLES_FAILURE, error } }
}

function getApprovals() {
    return dispatch => {
        dispatch(request());
        return generalService.getApprovals()
            .then(
                approvals => dispatch(success(approvals)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: generalConstants.APPROVALS_GET_ALL_REQUEST } }
    function success(approvals) { return { type: generalConstants.APPROVALS_GET_ALL_SUCCESS, approvals } }
    function failure(error) { return { type: generalConstants.APPROVALS_GET_ALL_FAILURE, error } }
}

function addApproval(approval) {
    return dispatch => {
        dispatch(request(approval));
        generalService.addApproval(approval)
            .then(
                approval => {
                    dispatch(success(approval));
                    dispatch(alertActions.success('Thêm mới Phê Duyệt thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request(approval) { return { type: generalConstants.APPROVALS_ADD_REQUEST, approval } }
    function success(approval) { return { type: generalConstants.APPROVALS_ADD_SUCCESS, approval } }
    function failure(error) { return { type: generalConstants.APPROVALS_ADD_FAILURE, error } }
}

function updateApproval(approval) {
    return dispatch => {
        dispatch(request(approval));
        generalService.updateApproval(approval)
            .then(
                approval => {
                    dispatch(success(approval));
                    dispatch(alertActions.success('Cập nhật Phê Duyệt thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request(approval) { return { type: generalConstants.APPROVALS_UPDATE_REQUEST, approval: approval } }
    function success(approval) { return { type: generalConstants.APPROVALS_UPDATE_SUCCESS, approval: approval } }
    function failure(error) { return { type: generalConstants.APPROVALS_UPDATE_FAILURE, error } }
}
