import { customerConstants } from 'src/_constants';
import { customerService } from '../_services';
import { alertActions } from '.';

export const customerActions = {
    setParams,
    getAll,
    add,
    getById,
    update,
    updateField,
    _delete,
};

function setParams(params = {}) {
    return dispatch => {
        dispatch({ type: customerConstants.SET_PARAMS_REQUEST, params });
    }
}

function getAll(customer_type = 'customer') {
    return dispatch => {
        dispatch(request());
        return customerService.getAll(customer_type)
            .then(
                customers => {
                    dispatch(success(customers, customer_type));
                    return customers;
                },
                error => {
                    dispatch(failure(error.toString()), customer_type);
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(customer_type) { return { type: customerConstants.GETALL_REQUEST, customer_type } }
    function success(customers, customer_type) { return { type: customerConstants.GETALL_SUCCESS, customers, customer_type } }
    function failure(error, customer_type) { return { type: customerConstants.GETALL_FAILURE, error, customer_type } }
}

function getById(id) {

    return dispatch => {
        dispatch(request());
        customerService.getById(id)
            .then(
                customer => dispatch(success(customer)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: customerConstants.GET_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_FAILURE, error } }
}

function add(customer) {
    return dispatch => {
        dispatch(request(customer));

        return customerService.add(customer)
            .then(
                customer => {
                    dispatch(success(customer));
                    dispatch(alertActions.success('Thêm mới Khách Hàng thành công!'));
                    return customer;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(customer) { return { type: customerConstants.ADD_REQUEST, customer } }
    function success(customer) { return { type: customerConstants.ADD_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.ADD_FAILURE, error } }
}

function update(customer) {
    return dispatch => {
        dispatch(request(customer));
        customerService.update(customer)
            .then(
                customer => {
                    dispatch(success(customer));
                    dispatch(alertActions.success('Cập nhật thông tin Khách Hàng thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(customer) { return { type: customerConstants.UPDATE_REQUEST, customer } }
    function success(customer) { return { type: customerConstants.UPDATE_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.UPDATE_FAILURE, error } }
}

function updateField(customer, field) {
    return dispatch => {
        dispatch(request(customer, field));
        customerService.updateField(customer, field)
            .then(
                customer => {
                    dispatch(success(customer));
                    dispatch(alertActions.success('Cập nhật thông tin Khách Hàng thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(customer, field) { return { type: customerConstants.UPDATE_FIELD_REQUEST, customer, field } }
    function success(customer) { return { type: customerConstants.UPDATE_FIELD_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.UPDATE_FIELD_FAILURE, error } }
}

function _delete(customer) {
    return dispatch => {
        dispatch(request(customer));
        return customerService._delete(customer)
            .then(
                customer => {
                    dispatch(success(customer));
                    dispatch(alertActions.success('Xoá Khách Hàng thành công!'));
                    return customer;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(customer) { return { type: customerConstants.DELETE_REQUEST, customer } }
    function success(customer) { return { type: customerConstants.DELETE_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.DELETE_FAILURE, error } }
}


