export const tagConstants = {

    GET_LIST_REQUEST: 'TAGS_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'TAGS_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'TAGS_GET_LIST_FAILURE',

    SET_PARAMS_REQUEST: 'TAGS_SET_PARAMS_REQUEST',

    ADD_REQUEST: 'TAGS_ADD_REQUEST',
    ADD_SUCCESS: 'TAGS_ADD_SUCCESS',
    ADD_FAILURE: 'TAGS_ADD_FAILURE',
    UPDATE_REQUEST: 'TAGS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'TAGS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'TAGS_UPDATE_FAILURE',
    DELETE_REQUEST: 'TAGS_DELETE_REQUEST',
    DELETE_SUCCESS: 'TAGS_DELETE_SUCCESS',
    DELETE_FAILURE: 'TAGS_DELETE_FAILURE',
}