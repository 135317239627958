import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CDataTable,
    CButton,
    CSelect,
    CLink,
    CBadge,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import configs from 'src/appConfigs';
import Loading from 'src/containers/_loading';
import Select from 'react-select';

class Housings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            province: '',
            active: 'all',
        }

        this.pageChange = this.pageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        const queryPage = this.props.location.search.match(/page=([0-9]+)/, '');
        const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
        this.setState({ currentPage: currentPage });
    }

    pageChange = (newPage) => {
        var currentPage = this.state.currentPage;
        currentPage !== newPage && this.props.history.push(`/users?page=${newPage}`)
    }

    handleChange(field, e) {
        var value = e.target.value;
        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        }
        this.setState({
            [field]: value
        });
    }

    handleSelect(selectOPtions, field) {
        this.setState({
            [field]: selectOPtions,
        });
        console.log(this.state)
    }

    render() {
        const { housings, users, provinces, notes } = this.props;

        var _housings = [];
        if (housings && housings.length > 0) {
            housings.forEach((housing, index) => {
                housing['create_by_alias'] = helps.getUserAlias(users, housing.create_by);
                var province = helps.getItemFromArr(provinces, housing.province_id);
                if (province) {
                    housing['province_name'] = province.name;
                } else {
                    housing['province_name'] = '';
                }
                housing['index'] = index + 1;
                _housings[index] = housing;
            });
        }
        if (!helps.isEmpty(this.state.province)) {
            _housings = _housings.filter((item) => {
                return item.province_id == this.state.province;
            });
        }
        if (this.state.active !== 'all') {
            _housings = _housings.filter((item) => {
                return this.state.active === item.active;
            });
        }

        const fields = [
            { key: 'index', label: '#', _style: { width: '10px' }, filter: false, sorter: false },
            { key: 'name', label: 'Tên Nhà Đội', _classes: 'font-weight-bold', _style: { width: '15%' } },
            { key: 'active', label: 'Tình Trạng' },
            { key: 'start_date', label: 'Ngày Bắt Đầu', filter: false },
            { key: 'address', label: 'Địa Chỉ', _style: { width: '15%' } },
            { key: 'province_name', label: 'Tỉnh Thành' },
            { key: 'deposit', label: 'Đặt Cọc', filter: false },
            { key: 'amount', label: 'Chi Phí Thuê', filter: false },
            { key: 'contact_name', label: 'Liên Hệ' },
            { key: 'phone', label: 'Điện Thoại' },
            { key: 'create_by_alias', label: 'Tạo Bởi', _style: { align: 'center' }, filter: false },
            { key: 'actions', label: 'Thao Tác', _style: { width: '10%' }, filter: false, sorter: false },
        ]
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        return (
            <CDataTable
                key={'housings_' + _housings.length}
                items={_housings}
                fields={fields}
                columnFilter
                tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                itemsPerPageSelect={configs.itemsPerPageSelect}
                itemsPerPage={configs.per_page}
                hover
                sorter
                pagination
                striped
                columnFilterSlot={{
                    province_name: (
                        <>
                            <CSelect
                                name="province"
                                id="input-province"
                                className="form-control form-control-sm"
                                value={this.state.province}
                                onChange={(e) => { this.handleChange('province', e) }}
                            >
                                <option value="">-Tất cả-</option>
                                {provinces && provinces.map((province) => {
                                    return (
                                        <option value={province.id} key={province.id}>{province.name}</option>
                                    )
                                }
                                )}
                            </CSelect>
                        </>
                    ),
                    active: (
                        <>
                            <CSelect
                                name="active"
                                id="input-active"
                                className="form-control form-control-sm"
                                value={this.state.active}
                                onChange={(e) => { this.handleChange('active', e) }}
                            >
                                <option value="all">-Tất cả-</option>
                                <option value={true} >Đang hoạt động</option>
                                <option value={false} >Đã ngưng hoạt động</option>
                            </CSelect>
                        </>
                    ),
                }}
                scopedSlots={{
                    'name': (item) => {
                        var _notes = [];
                        if (!helps.isEmpty(notes) && notes.length > 0) {
                            _notes = notes.filter(note => note.object_id === item.id);
                        }
                        var note_content = '';
                        if (!helps.isEmpty(_notes) && _notes.length > 0) {
                            note_content = _notes.map((note) => {
                                return (
                                    <div className='border-bottom mb-2 pb-2'>
                                        {note.content} <small>_<i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                    </div>
                                );
                            });
                        }
                        return (
                            <td className="py-2">
                                {!helps.isEmpty(note_content) &&
                                    <CPopover
                                        header='Ghi chú:'
                                        content={note_content}
                                    >
                                        <CLink className="text-dark" onClick={() => { this.props.history.push(`/housings/view/${item.id}`) }}>
                                            {item.active && <b>{item.name}</b>}{!item.active && <b><del>{item.name}</del></b>}
                                        </CLink>
                                    </CPopover>
                                }
                                {helps.isEmpty(note_content) &&
                                    <CLink className="text-dark" onClick={() => { this.props.history.push(`/housings/view/${item.id}`) }}>
                                        {item.active && <b>{item.name}</b>}{!item.active && <b><del>{item.name}</del></b>}
                                    </CLink>
                                }
                            </td>
                        )
                    },
                    'active': (item) => {
                        var color = item.active ? 'success' : 'danger';
                        var title = item.active ? 'Đang hoạt động' : 'Đã ngừng hoạt động';
                        return (
                            <td className="py-2">
                                <CBadge color={color} title={title}>{item.active && <CIcon name='cil-check'></CIcon>}{!item.active && <CIcon name='cil-x'></CIcon>}</CBadge>
                            </td>
                        )
                    },
                    'start_date': (item) => {
                        return (
                            <td className="py-2">
                                <b>{helps.formatDate_from_Timestamp(item.start_date)}</b>
                            </td>
                        )
                    },
                    'deposit': (item) => {
                        return (
                            <td className="py-2">
                                <b>{helps.formatCurrency(item.deposit)}</b>
                            </td>
                        )
                    },
                    'amount': (item) => {
                        return (
                            <td className="py-2">
                                <b>{helps.formatCurrency(item.amount)}</b>
                            </td>
                        )
                    },
                    'create_by_alias': (item) => {
                        return (
                            <td className="py-2">
                                {item.create_by_alias}<br />
                                {helps.formatDate_from_Timestamp(item.create_date)}
                            </td>
                        )
                    },
                    'actions':
                        (item) => {
                            var _notes = [];
                            if (!helps.isEmpty(notes) && notes.length > 0) {
                                _notes = notes.filter(note => note.object_id === item.id);
                            }
                            var note_content = '';
                            if (!helps.isEmpty(_notes) && _notes.length > 0) {
                                note_content = _notes.map((note) => {
                                    return (
                                        <div className='border-bottom mb-2 pb-2'>
                                            {note.content} <small>_<i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                        </div>
                                    );
                                });
                            }
                            return (
                                <td className="py-2">
                                    <ThePermission {... this.props} component="housings" action="view">
                                        <CButton
                                            color="warning"
                                            size="sm mr-1"
                                            title="Xem chi tiết"
                                            onClick={() => { this.props.history.push(`/housings/view/${item.id}`) }}
                                        >
                                            <CIcon name="cil-face"></CIcon>
                                        </CButton>
                                    </ThePermission>
                                    <ThePermission {... this.props} component="housings" action="view">
                                        <CPopover
                                            header="Ghi chú"
                                            content={note_content}
                                        >
                                            <CButton
                                                color="info"
                                                size="sm mr-1"
                                            >
                                                <CIcon name="cil-list"></CIcon>
                                            </CButton>
                                        </CPopover>
                                    </ThePermission>
                                    <ThePermission {... this.props} component="housings" action="edit">
                                        <CButton
                                            color="primary"
                                            size="sm"
                                            title="Cập nhật thông tin"
                                            onClick={() => { this.props.history.push(`/housings/add_edit/${item.id}`) }}
                                        >
                                            <CIcon name="cil-pen-nib"></CIcon>
                                        </CButton>
                                    </ThePermission>
                                </td>
                            )
                        },
                }}
            />

        )
    }
}


export default Housings;
