import configs from "src/appConfigs";
import { db } from "src/dbConfigs";

export const provinceService = {
    getAll,
    getById,
    add,
    update,
    _delete,
}

async function getAll(params = {}) {
    try {
        let limit = configs.per_page;
        if (params.per_page) {
            limit = params.per_page;
        }
        const snapshot = await db.collection("provinces")
            .orderBy("name", "asc")
            .limit()
            .get();
        const provinces = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
        }));
        return provinces;
    } catch (error) {
        return error;
    }
}

async function getById(id) {
    try {
        const doc = await db.collection("provinces").doc(id)
            .get();
        if (doc.exists) {
            return doc.data();
        } else {
            console.log("No such document!");
        }
    } catch (error) {
        console.log("Error getting document:", error);
    }
}

async function add(province) {
    try {
        const docRef = await db.collection("provinces").add(province);
        province.id = docRef.id;
        update(province);
        console.log("Firebase: Province has been added successfully!");
        return province;
    } catch (error) {
        console.error("Error adding Province: ", error);
    }
}

async function update(province) {
    var Ref = db.collection("provinces").doc(province.id);

    try {
        await Ref.update(province);
        console.log("Firebase: Province has been updated successfully!");
        return province;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _delete(province) {
    var Ref = db.collection("provinces").doc(province.id);

    try {
        await Ref.delete();
        console.log("Firebase: Province has been deleted successfully!");
        return province;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}
