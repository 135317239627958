import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CFormText,
    CInput,
    CLink,
    CInvalidFeedback,
    CSelect,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';

import { helps } from '../../_helpers';

class UserForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            _header: "Thêm Mới",
            fields: {},
            errors: {},
            username: '',
            fullname: '',
            password: '',
            group: 'SEKIN',
            current_password: '',
            last_logged: null,
            role: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        const { user } = this.props;
        if (!helps.isEmpty(user)) {
            this.setState({
                username: user.username,
                password: user.password,
                fullname: user.fullname,
                current_password: user.password,
                role: user.role,
                errors: []
            });
        }

    }

    handleSubmit(e) {
        e.preventDefault();
        const curUser = this.props.user;
        let user = {
            username: this.state.username,
            password: this.state.password,
            fullname: this.state.fullname,
            role: this.state.role,
        }

        if (curUser && curUser.id) {
            if (curUser.password !== user.password) {
                user.password = helps.hashPassword(user.password);
            }
            user.last_logged = curUser.last_logged;
        } else {
            user.last_logged = null;
            user.password = helps.hashPassword(user.password);
        }

        if (this.handleValidation()) {
            this.props.handleSubmit(user);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleValidation() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        //console.log(fields);
        //Username
        if (!fields["username"]) {
            formIsValid = false;
            errors["username"] = "Cannot be empty";
        }
        //Password
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Cannot be empty";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        const roles = this.props.roles;

        return (
            <CRow>
                <CCol xl={6}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader>
                                {this.props.formHeader}
                            </CCardHeader>
                            <CCardBody>
                                <div className="mb-3">
                                    <CLabel htmlFor="input-username">TK Đăng Nhập: </CLabel>
                                    <CInput
                                        type="text"
                                        name="username"
                                        id="input-username"
                                        required
                                        value={this.state.username}
                                        onChange={(e) => { this.handleChange('username', e) }}
                                    />
                                    <CInvalidFeedback className="help-block">{this.state.errors['username']}</CInvalidFeedback>
                                </div>
                                <div className="mb-3">
                                    <CLabel htmlFor="input-password">Mật Khẩu: </CLabel>
                                    <CInput
                                        type="password"
                                        name="password"
                                        id="input-password"
                                        required
                                        defaultValue={this.state.password}
                                        onChange={(e) => { this.handleChange('password', e) }}
                                    />
                                    <CInvalidFeedback className="help-block">{this.state.errors['password']}</CInvalidFeedback>
                                    <CFormText className="help-block">Password will be encrypted by <CLink href="https://en.wikipedia.org/wiki/SHA-2" target="_blank"><b>SHA-2</b></CLink>.</CFormText>
                                </div>
                                <div className="mb-3">
                                    <CLabel htmlFor="input-fullname">Họ & Tên:</CLabel>
                                    <CInput
                                        type="text"
                                        name="fullname"
                                        id="input-fullname"
                                        required
                                        value={this.state.fullname}
                                        onChange={(e) => { this.handleChange('fullname', e) }}
                                    />
                                    <CInvalidFeedback className="help-block">{this.state.errors['fullname']}</CInvalidFeedback>
                                </div>
                                <div className="mb-3">
                                    <CLabel htmlFor="input-group">Nhóm:</CLabel>
                                    <CInput
                                        type="text"
                                        name="group"
                                        id="input-group"
                                        required
                                        value={this.state.group}
                                        onChange={(e) => { this.handleChange('group', e) }}
                                    />
                                    <CInvalidFeedback className="help-block">{this.state.errors['group']}</CInvalidFeedback>
                                </div>
                                <div className="mb-3">

                                    <CLabel htmlFor="role">Phân Quyền:</CLabel>
                                    <CSelect
                                        custom
                                        name="role"
                                        id="role"
                                        value={this.state.role}
                                        onChange={(e) => { this.handleChange('role', e) }}
                                    >
                                        {roles && roles.map((role) => {
                                            return (
                                                <option value={role.id} key={role.id}>{role.id}</option>
                                            );
                                        })}

                                    </CSelect>
                                </div>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default UserForm;

