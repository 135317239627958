import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CCallout,
    CCollapse,
    CCard,
    CCardHeader,
    CCardBody,
    CTooltip,
    CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import AreasForm from './AreasForm';


class Areas extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            showAddForm: false,
            show_list: true,
            edit_forms: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancelForm = this.handleCancelForm.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {

    }

    handleCancelForm() {
        this.setState({ showAddForm: false });
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
    }

    handleDelete(e, area) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Khu vực: ' + area.name + ' ?');
        if (_confirm) {
            this.props._delete(area);
        }
        return false;
    }

    handleSubmit(area) {
        if (helps.isEmpty(area.id)) {
            this.props.add(area);
            this.handleCancelForm();
        } else {
            this.props.update(area);
            this.toggleEditForms(area);
        }
    }

    toggleEditForms = (item) => {
        let forms = this.state.edit_forms;
        const area = forms.indexOf(item.id);
        let newForms = forms.slice();
        if (area !== -1) {
            newForms.splice(area, 1)
        } else {
            newForms = [...forms, item.id]
        }
        this.setState({ edit_forms: newForms });
    }

    render() {
        const { project, user } = this.props;
        let _areas = !helps.isEmpty(project.areas) ? project.areas : [];
        return (
            <CRow>
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol xl={6} md={6}>
                                    Khu Vực
                                </CCol>
                                <CCol xl={6} md={6} className="text-right">
                                    <CTooltip content={'Thêm Khu Vực'}>
                                        <CButton type="button" color="primary" size="sm" onClick={() => { this.setState({ showAddForm: !this.state.showAddForm }) }}>
                                            <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                            Thêm Khu Vực
                                        </CButton>
                                    </CTooltip>
                                    <CTooltip content={this.state.show_list ? 'Ẩn' : 'Hiện'}>
                                        <CButton type="button" color="secondary" size="sm" onClick={() => { this.setState({ show_list: !this.state.show_list }) }} title={this.state.show_list ? 'Ẩn' : 'Hiện'} className='ml-2'>
                                            <CIcon name={this.state.show_list ? 'cil-chevron-top' : 'cil-chevron-bottom'} className="mfe-1 mt-0"></CIcon>
                                        </CButton>
                                    </CTooltip>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCollapse show={this.state.show_list}>
                            <CCardBody>
                                {this.state.showAddForm && <AreasForm {...this.props} handleSubmit={this.handleSubmit} handleCancelForm={() => { this.handleCancelForm() }} action="add" />}
                                <div className='areas-container'>
                                    {!helps.isEmpty(_areas) && _areas.map((area) => {
                                        return (
                                            <div key={area.id}>
                                                <CCallout key={area.id} color="warning" className={'bg-white'}>
                                                    <CRow className="mb-2">
                                                        <CCol>
                                                            <h5 className='font-weight-bold'>{area.name}</h5>
                                                            {area.description && <span>{area.description}</span>}
                                                        </CCol>
                                                        <CCol xl={3} md={3} sm={3}>
                                                            <div className='text-right mt-2 mb-2'>
                                                                <CTooltip content="Sửa Khu vực">
                                                                    <CButton type="button" size="sm" color="primary" onClick={() => { this.toggleEditForms(area); }}>
                                                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                                    </CButton>
                                                                </CTooltip>
                                                                <CTooltip content="Hiện tại không xoá  Khu vực">
                                                                    <CButton
                                                                        color="danger"
                                                                        size="sm"
                                                                        className="ml-1"
                                                                        title="Hiện tại không xoá Khu vực."
                                                                        disabled
                                                                        onClick={(e) => { this.handleDelete(e, area) }}
                                                                    >
                                                                        <CIcon name="cil-trash"></CIcon>
                                                                    </CButton>
                                                                </CTooltip>
                                                            </div>
                                                        </CCol>
                                                    </CRow>
                                                    <CCollapse show={this.state.edit_forms.includes(area.id)} key={"details_" + area.id} style={{ overflow: 'hidden' }}>
                                                        <AreasForm
                                                            {...this.props}
                                                            area={area}
                                                            handleSubmit={this.handleSubmit}
                                                            toggleEditForms={() => { this.toggleEditForms(area) }}
                                                            handleCancelForm={() => { this.handleCancelForm() }}
                                                            action="edit"
                                                        />
                                                    </CCollapse>
                                                </CCallout>
                                                <hr />
                                            </div>
                                        );
                                    })}
                                </div>
                            </CCardBody>
                        </CCollapse>
                    </CCard>
                </CCol>
            </CRow>
        )
    }
}


export default Areas;
