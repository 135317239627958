import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { helps } from 'src/_helpers';
import { itemActions } from 'src/_actions';
import { provinceActions } from 'src/_actions';
import ItemsForm from 'src/views/assets/ItemsForm';


class ItemsFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            form_header: "Thêm Danh Mục Tài Sản Mới",
            action_status: 'add',
        }
    }

    componentDidMount() {
        this.getItems().then(() => {
            this.getItem();
        });
        if (!this.props.provinces) {
            this.props.getProvinces();
        }
    }

    getItem() {
        let item_id = this.props.match.params.id;
        const items = this.props.items;
        if (item_id) {
            let item = items.filter((_item) => {
                return _item.id === item_id;
            });
            this.setState({ form_header: "Chỉnh Sửa Thông Tin Danh Mục Tài Sản: " + item[0].name, action_status: 'edit', item: item[0] });
        }
    }

    async getItems() {
        const items = this.props.items;
        if (!items) await this.props.getAll();
    }

    handleSubmit = (item) => {
        let item_id = this.props.match.params.id;
        var url = '/assets/items';
        if (helps.isEmpty(item_id)) {
            this.props.add(item);
        } else {
            this.props.update(item);
        }
        this.props.history.push(url);
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        let item_id = this.props.match.params.id;
        const { item } = this.state;
        if (!helps.isEmpty(item_id) && helps.isEmpty(item)) return (<Loading></Loading>);

        return (
            <ThePermission {... this.props} component="assets" action="edit">
                <ItemsForm
                    {... this.props}
                    item={this.state.item}
                    form_header={this.state.form_header}
                    action_status={this.state.action_status}
                    handleSubmit={this.handleSubmit}
                />
            </ThePermission>
        );
    }
}
function mapStateToProps(state) {
    const { loading, items } = state.itemReducer;
    const { provinces } = state.provinceReducer;
    const { user } = state.authentication;
    return { loading, items, provinces, user };
}

const actionCreators = {
    getAll: itemActions.getAll,
    add: itemActions.add,
    update: itemActions.update,
    getProvinces: provinceActions.getAll,
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(ItemsFormContainer);
export { connectedFormContainer as ItemsFormContainer };