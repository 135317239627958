import { tagConstants } from "src/_constants/tags.constants";
import { helps } from "src/_helpers";

export function tagReducer(state = {}, actions) {
    switch (actions.type) {
        case tagConstants.GET_LIST_REQUEST:
            return {
                loading: true,
            }
        case tagConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                tags: actions.tags,
            }
        case tagConstants.GET_LIST_FAILURE:
            return {
                ...state,
                error: actions.error,
            }
        case tagConstants.ADD_REQUEST:
            return {
                ...state,
            }
        case tagConstants.ADD_SUCCESS:
            state.tags.push(actions.tag);
            return {
                ...state,
                tags: state.tags
            }
        case tagConstants.ADD_FAILURE:
            return {
                ...state,
                error: actions.error,
            }
        case tagConstants.UPDATE_REQUEST:

            return {
                ...state,
            }
        case tagConstants.UPDATE_SUCCESS:
            var _tags = state.tags.filter(function (element) {
                return element !== undefined;
            });
            ///console.log(_tags);
            var foundIndex = _tags.findIndex(tag => tag.id === actions.tag.id);
            _tags[foundIndex] = actions.tag;
            return {
                ...state,
                tags: _tags,
            }
        case tagConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: actions.error,
            }
        case tagConstants.DELETE_REQUEST:
            return {
                ...state,
                tag: actions.tag,
            }
        case tagConstants.DELETE_SUCCESS:
            return {
                ...state,
                tags: state.tags.filter(tag => tag.id !== state.tag.id)
            }
        case tagConstants.DELETE_FAILURE:
            return {
                ...state,
                error: actions.error,
            }

        default: return state;
    }
}