import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import Employees from '../../views/employees/Employees';

import { employeeActions, noteActions, projectActions } from '../../_actions';
import { helps } from 'src/_helpers';

class EmployeesContainer extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.getProjects();
        this.getNotes();

        if (!this.props.employees)
            this.props.getAll();
    }

    async getNotes() {
        await this.props.getNotes('employees');
    }

    async getProjects() {
        if (helps.isEmpty(this.props.projects)) await this.props.getProjects();
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        } else {
            return (
                <ThePermission {... this.props} component="employees" action="view">
                    <Employees {... this.props} employees={this.props.employees} />
                </ThePermission>

            );
        }
    }
}

function mapStateToProps(state) {
    const { loading, employees } = state.employeeReducer;
    const { projects } = state.projectReducer;
    const { notes } = state.noteReducer;
    return { loading, employees, projects, notes };
}

const actionCreators = {
    getAll: employeeActions.getAll,
    getProjects: projectActions.getAll,
    setParams: employeeActions.setParams,
    getNotes: noteActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(EmployeesContainer);
export { connectedContainer as EmployeesContainer };