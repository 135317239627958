import { violationConstants } from "src/_constants";
import { violationService } from "src/_services/employees/violation.service";
import { alertActions } from "..";

export const violationActions = {
    getAll,
    add,
    update,
    _delete,

    getAllTypes,
    addType,
    updateType,
    _deleteType,

    addTerm,
    updateTerm,
    _deleteTerm,
}
function getAll(employee = {}) {
    return dispatch => {
        dispatch(request(employee));
        return violationService.getAll(employee)
            .then(
                violations => {
                    dispatch(success(violations));
                    return violations;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(employee) { return { type: violationConstants.GET_LIST_REQUEST, employee: employee } }
    function success(violations, employee) { return { type: violationConstants.GET_LIST_SUCCESS, violations, employee: employee } }
    function failure(error) { return { type: violationConstants.GET_LIST_FAILURE, error } }
}

function add(violation) {
    return dispatch => {
        dispatch(request(violation));
        return violationService.add(violation)
            .then(
                violation => {
                    dispatch(success(violation));
                    dispatch(alertActions.success('Thêm mới Vi Phạm thành công!'));
                    return violation;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(violation) { return { type: violationConstants.ADD_REQUEST, violation: violation } }
    function success(violation) { return { type: violationConstants.ADD_SUCCESS, violation: violation } }
    function failure(error) { return { type: violationConstants.ADD_FAILURE, error } }
}

function update(violation) {
    return dispatch => {
        dispatch(request(violation));
        return violationService.update(violation)
            .then(
                violation => {
                    dispatch(success(violation));
                    dispatch(alertActions.success('Chỉnh sửa Vi Phạm thành công!'));
                    return violation;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(violation) { return { type: violationConstants.EDIT_REQUEST, violation: violation } }
    function success(violation) { return { type: violationConstants.EDIT_SUCCESS, violation: violation } }
    function failure(error) { return { type: violationConstants.EDIT_FAILURE, error } }
}

function _delete(violation) {
    return dispatch => {
        dispatch(request(violation));
        return violationService._delete(violation)
            .then(
                violation => {
                    dispatch(success(violation));
                    dispatch(alertActions.success('Xoá Vi Phạm thành công!'));
                    return violation;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(violation) { return { type: violationConstants.DELETE_REQUEST, violation: violation } }
    function success(violation) { return { type: violationConstants.DELETE_SUCCESS, violation: violation } }
    function failure(error) { return { type: violationConstants.DELETE_FAILURE, error } }
}
// Violation Types
function getAllTypes() {
    return dispatch => {
        dispatch(request());
        return violationService.getAllTypes()
            .then(
                violation_types => {
                    dispatch(success(violation_types));
                    return violation_types;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: violationConstants.GET_LIST_TYPES_REQUEST } }
    function success(violation_types) { return { type: violationConstants.GET_LIST_TYPES_SUCCESS, violation_types } }
    function failure(error) { return { type: violationConstants.GET_LIST_TYPES_FAILURE, error } }
}

function addType(violation_type) {
    return dispatch => {
        dispatch(request(violation_type));
        return violationService.addType(violation_type)
            .then(
                violation_type => {
                    dispatch(success(violation_type));
                    dispatch(alertActions.success('Thêm mới Danh Mục Vi Phạm thành công!'));
                    return violation_type;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request(violation_type) { return { type: violationConstants.ADD_TYPES_REQUEST, violation_type: violation_type } }
    function success(violation_type) { return { type: violationConstants.ADD_TYPES_SUCCESS, violation_type: violation_type } }
    function failure(error) { return { type: violationConstants.ADD_TYPES_FAILURE, error } }
}

function updateType(violation_type) {
    return dispatch => {
        dispatch(request(violation_type));
        return violationService.updateType(violation_type)
            .then(
                type => {
                    dispatch(success(type));
                    dispatch(alertActions.success('Chỉnh sửa Danh Mục Vi Phạm thành công!'));
                    return violation_type;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(violation_type) { return { type: violationConstants.EDIT_TYPES_REQUEST, violation_type: violation_type } }
    function success(violation_type) { return { type: violationConstants.EDIT_TYPES_SUCCESS, violation_type: violation_type } }
    function failure(error) { return { type: violationConstants.EDIT_TYPES_FAILURE, error } }
}

function _deleteType(violation_type) {
    return dispatch => {
        dispatch(request(violation_type));
        return violationService._deleteType(violation_type)
            .then(
                violation_type => {
                    dispatch(success(violation_type));
                    dispatch(alertActions.success('Xoá Danh Mục Vi Phạm thành công!'));
                    return violation_type;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(violation_type) { return { type: violationConstants.DELETE_TYPES_REQUEST, violation_type: violation_type } }
    function success(violation_type) { return { type: violationConstants.DELETE_TYPES_SUCCESS, violation_type: violation_type } }
    function failure(error) { return { type: violationConstants.DELETE_TYPES_FAILURE, error } }
}

// Violation Terms
function addTerm(violation_type, term) {
    return dispatch => {
        dispatch(request());
        return violationService.addTerm(violation_type, term)
            .then(
                term => {
                    dispatch(success(violation_type, term));
                    dispatch(alertActions.success('Thêm mới Hình thức Xử lý Vi Phạm thành công!'));
                    return term;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request(violation_type, term) { return { type: violationConstants.ADD_TERMS_REQUEST, violation_type: violation_type, term: term } }
    function success(violation_type, term) { return { type: violationConstants.ADD_TERMS_SUCCESS, violation_type: violation_type, term: term } }
    function failure(error) { return { type: violationConstants.ADD_TERMS_FAILURE, error } }
}

function updateTerm(violation_type, term) {
    return dispatch => {
        dispatch(request(violation_type));
        return violationService.updateTerm(violation_type, term)
            .then(
                term => {
                    dispatch(success(violation_type, term));
                    dispatch(alertActions.success('Chỉnh sửa Hình thức Xử lý Vi Phạm thành công!'));
                    return term;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(violation_type, term) { return { type: violationConstants.EDIT_TERMS_REQUEST, violation_type: violation_type, term: term } }
    function success(violation_type, term) { return { type: violationConstants.EDIT_TERMS_SUCCESS, violation_type: violation_type, term: term } }
    function failure(error) { return { type: violationConstants.EDIT_TERMS_FAILURE, error } }
}

function _deleteTerm(violation_type, term) {
    return dispatch => {
        dispatch(request(violation_type));
        return violationService._deleteTerm(violation_type, term)
            .then(
                term => {
                    dispatch(success(violation_type, term));
                    dispatch(alertActions.success('Xoá Hình thức Xử lý Vi Phạm thành công!'));
                    return term;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(violation_type, term) { return { type: violationConstants.DELETE_TERMS_REQUEST, violation_type: violation_type, term: term } }
    function success(violation_type, term) { return { type: violationConstants.DELETE_TERMS_SUCCESS, violation_type: violation_type, term: term } }
    function failure(error) { return { type: violationConstants.DELETE_TERMS_FAILURE, error } }
}