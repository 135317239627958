import { employeeConstants } from '../../_constants';
import { employeeService } from '../../_services';
import { alertActions } from '..';

export const employeeActions = {
    setParams,
    getAll,
    addEmployee,
    getEmployee,
    updateEmployee,
    updateField,
    uploadLetter,
    deleteLetter,
};

function setParams(params = {}) {
    return dispatch => {
        dispatch({ type: employeeConstants.SET_PARAMS_REQUEST, params });
    }
}

function getAll() {
    return dispatch => {
        dispatch(request());
        return employeeService.getAll()
            .then(
                employees => dispatch(success(employees)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: employeeConstants.GETALL_REQUEST } }
    function success(employees) { return { type: employeeConstants.GETALL_SUCCESS, employees } }
    function failure(error) { return { type: employeeConstants.GETALL_FAILURE, error } }
}

/**
 * 
 * @param {string} employee_id 
 * @returns {Object} employee 
 */
function getEmployee(employee_id) {

    return dispatch => {
        dispatch(request());
        employeeService.getById(employee_id)
            .then(
                employee => dispatch(success(employee)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: employeeConstants.GET_REQUEST } }
    function success(employee) { return { type: employeeConstants.GET_SUCCESS, employee } }
    function failure(error) { return { type: employeeConstants.GET_FAILURE, error } }
}

function addEmployee(employee) {
    return dispatch => {
        dispatch(request(employee));

        employeeService.addEmployee(employee)
            .then(
                employee => {
                    dispatch(success(employee));
                    dispatch(alertActions.success('Thêm mới Nhân viên thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(employee) { return { type: employeeConstants.ADD_REQUEST, employee } }
    function success(employee) { return { type: employeeConstants.ADD_SUCCESS, employee } }
    function failure(error) { return { type: employeeConstants.ADD_FAILURE, error } }
}

function updateEmployee(employee) {
    return dispatch => {
        dispatch(request(employee));
        employeeService.updateEmployee(employee)
            .then(
                employee => {
                    dispatch(success(employee));
                    dispatch(alertActions.success('Cập nhật thông tin Nhân viên thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(employee) { return { type: employeeConstants.UPDATE_REQUEST, employee } }
    function success(employee) { return { type: employeeConstants.UPDATE_SUCCESS, employee } }
    function failure(error) { return { type: employeeConstants.UPDATE_FAILURE, error } }
}

function updateField(employee, field) {
    return dispatch => {
        dispatch(request(employee, field));
        employeeService.updateField(employee, field)
            .then(
                employee => {
                    dispatch(success(employee));
                    dispatch(alertActions.success('Cập nhật thông tin Nhân Sự thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request(employee, field) { return { type: employeeConstants.UPDATE_FIELD_REQUEST, employee, field } }
    function success(employee) { return { type: employeeConstants.UPDATE_FIELD_SUCCESS, employee } }
    function failure(error) { return { type: employeeConstants.UPDATE_FIELD_FAILURE, error } }
}

function uploadLetter(employee, upload_files, user, type = 'letter') {
    return dispatch => {
        dispatch(request(employee, upload_files, user, type));
        employeeService.uploadLetter(employee, upload_files, user, type)
            .then(
                employee => {
                    dispatch(success(employee, upload_files, user, type));
                    dispatch(alertActions.success('Đăng tải Đơn thôi việc thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request(employee, upload_files, user, _type) { return { type: employeeConstants.UPLOAD_LETTER_REQUEST, employee, upload_files, user, _type } }
    function success(employee, upload_files, user, _type) { return { type: employeeConstants.UPLOAD_LETTER_SUCCESS, employee, upload_files, user, _type } }
    function failure(error) { return { type: employeeConstants.UPLOAD_LETTER_FAILURE, error } }
}

function deleteLetter(employee, user, type = 'letter') {
    return dispatch => {
        dispatch(request(employee, user, type));
        employeeService.deleteLetter(employee, user, type)
            .then(
                employee => {
                    dispatch(success(employee, user, type));
                    dispatch(alertActions.success('Xóa Đơn thôi việc thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request(employee, user, _type) { return { type: employeeConstants.DELETE_LETTER_REQUEST, employee, user, _type } }
    function success(employee, user, _type) { return { type: employeeConstants.DELETE_LETTER_SUCCESS, employee, user, _type } }
    function failure(error) { return { type: employeeConstants.DELETE_LETTER_FAILURE, error } }
}


