import { notificationConstants } from "src/_constants";
import { notificationService } from "src/_services/notification.service";
import { alertActions } from ".";

export const notificationActions = {
    getAll,
    add,
    update,
    _delete,
    _read,
}

function getAll(user, is_read = false, limit = 100) {
    return dispatch => {
        dispatch(request());
        return notificationService.getAll(user, is_read, limit)
            .then(
                notifications => {
                    dispatch(success(notifications));
                    return notifications;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: notificationConstants.GET_LIST_REQUEST, user: user, limit: limit } }
    function success(notifications) { return { type: notificationConstants.GET_LIST_SUCCESS, user: user, limit: limit, notifications } }
    function failure(error) { return { type: notificationConstants.GET_LIST_FAILURE, error } }
}

function add(notification) {
    return dispatch => {
        dispatch(request(notification));
        notificationService.add(notification)
            .then(
                notification => {
                    dispatch(success(notification));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(notification) { return { type: notificationConstants.ADD_REQUEST, notification: notification } }
    function success(notification) { return { type: notificationConstants.ADD_SUCCESS, notification: notification } }
    function failure(error) { return { type: notificationConstants.ADD_FAILURE, error } }
}

function update(notification) {
    return dispatch => {
        dispatch(request(notification));
        notificationService.update(notification)
            .then(
                notification => {
                    dispatch(success(notification));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(notification) { return { type: notificationConstants.EDIT_REQUEST, notification: notification } }
    function success(notification) { return { type: notificationConstants.EDIT_SUCCESS, notification: notification } }
    function failure(error) { return { type: notificationConstants.EDIT_FAILURE, error } }
}

function _delete(notification) {
    return dispatch => {
        dispatch(request(notification));
        notificationService._delete(notification)
            .then(
                notification => {
                    dispatch(success(notification));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(notification) { return { type: notificationConstants.DELETE_REQUEST, notification: notification } }
    function success(notification) { return { type: notificationConstants.DELETE_SUCCESS, notification: notification } }
    function failure(error) { return { type: notificationConstants.DELETE_FAILURE, error } }
}

function _read(notification) {
    return dispatch => {
        dispatch(request(notification));
        return notificationService._read(notification)
            .then(
                notification => {
                    dispatch(success(notification));
                    return notification;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(notification) { return { type: notificationConstants.READ_REQUEST, notification: notification } }
    function success(notification) { return { type: notificationConstants.READ_SUCCESS, notification: notification } }
    function failure(error) { return { type: notificationConstants.READ_FAILURE, error } }
}