import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { helps } from 'src/_helpers';
import { vendorActions, provinceActions } from 'src/_actions';
import VendorForm from 'src/views/vendors/VendorForm';


class VendorsFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: {},
            form_header: "Thêm Nhà Cung Cấp Mới",
            action_status: 'add',
        }
    }

    componentDidMount() {
        this.getVendors().then(() => {
            this.getVendor();
        });
        if (!this.props.provinces) {
            this.props.getProvinces();
        }
    }

    getVendor() {
        let vendor_id = this.props.match.params.id;
        const vendors = this.props.vendors;
        if (vendor_id) {
            let vendor = vendors.filter((_vendor) => {
                return _vendor.id === vendor_id;
            });
            this.setState({ form_header: "Chỉnh Sửa Thông Tin Nhà Cung Cấp: " + vendor[0].name, action_status: 'edit', vendor: vendor[0] });
        }
    }

    async getVendors() {
        const vendors = this.props.vendors;
        if (!vendors) await this.props.getAll();
    }

    handleSubmit = (vendor) => {
        let vendor_id = this.props.match.params.id;
        var url = '/vendors';
        if (helps.isEmpty(vendor_id)) {
            this.props.add(vendor);
        } else {
            this.props.update(vendor);
        }
        this.props.history.push(url);
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        let vendor_id = this.props.match.params.id;
        const { vendor } = this.state;
        if (!helps.isEmpty(vendor_id) && helps.isEmpty(vendor)) return (<Loading></Loading>);

        return (
            <ThePermission {... this.props} component="vendors" action="edit">
                <VendorForm
                    {... this.props}
                    vendor={this.state.vendor}
                    form_header={this.state.form_header}
                    action_status={this.state.action_status}
                    handleSubmit={this.handleSubmit}
                />
            </ThePermission>
        );
    }
}
function mapStateToProps(state) {
    const { loading, vendors } = state.vendorReducer;
    const { provinces } = state.provinceReducer;
    const { user } = state.authentication;
    return { loading, vendors, provinces, user };
}

const actionCreators = {
    getAll: vendorActions.getAll,
    add: vendorActions.add,
    update: vendorActions.update,
    getProvinces: provinceActions.getAll,
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(VendorsFormContainer);
export { connectedFormContainer as VendorsFormContainer };