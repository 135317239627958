import { db } from "src/dbConfigs";
import { helps } from "src/_helpers";
import { userService } from "./user.service";
import { employeeService } from "./employees/employee.service";
import { customerService } from "./customer.service";
import { projectService } from "./project.service";
import configs from "src/appConfigs";
import { candidateService } from "./employees";
export const noteService = {
    getAll,
    getLatest,
    _delete,
    add,
    update,
}

async function getAll(_object = '', _object_id = '', _limit = 1000) {
    try {
        let ref = db.collection('notes');
        var _query = {};
        if (!helps.isEmpty(_object) && !helps.isEmpty(_object_id)) {
            _query = ref.where('object', '==', _object)
                .where('object_id', '==', _object_id)
                .orderBy("note_time", "desc")
                .limit(_limit);
        } else {
            if (!helps.isEmpty(_object) && helps.isEmpty(_object_id)) {
                _query = ref.where('object', '==', _object)
                    .orderBy("note_time", "desc")
                    .limit(_limit);
            } else {
                _query = ref.orderBy("note_time", "desc")
                    .limit(_limit);
            }
        }

        //console.log(_object, _object_id)

        const snapshot = await _query.get();

        var notes = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        if (!helps.isEmpty(notes)) {
            await Promise.all(
                notes.map(async (note) => {
                    if (note.note_time) {
                        let _note_time = note.note_time;
                        note['note_time'] = _note_time.toDate();
                    }
                    let user = await userService.getById(note.note_by);
                    note['note_object_alias'] = configs.objects[note.object];
                    note['note_by_alias'] = user.fullname + '-' + user.username;

                    switch (note.object) {
                        case 'employees':
                            let employee = await employeeService.getById(note.object_id);
                            if (employee) note['note_object_name'] = employee.fullname;
                            note['note_object_link'] = '/employees/view/' + note.object_id;
                            break;
                        case 'candidates':
                            let candidate = await candidateService.getById(note.object_id);
                            if (candidate) note['note_object_name'] = candidate.fullname;
                            note['note_object_link'] = '/employees/candidates/view/' + note.object_id;
                            break;
                        case 'customers':
                            let customer = await customerService.getById(note.object_id);
                            note['note_object_name'] = customer.name;
                            note['note_object_link'] = '/customers/view/' + customer.id;
                            break;
                        case 'projects':
                            let project = await projectService.getById(note.object_id);
                            note['note_object_name'] = project.name;
                            note['note_object_link'] = '/projects/view/' + note.object_id;
                            break;

                        default:
                    }
                })
            )
        }
        return notes;
    } catch (error) {
        return error;
    }
}

async function getLatest(_limit = 1000) {
    try {
        let ref = db.collection('notes');
        var _query = {};
        var notes = [];
        let allow_objects = ['employees', 'projects', 'housings', 'candidates'];
        _query = ref.where('object', 'in', allow_objects)
            .orderBy("note_time", "desc")
            .limit(_limit);

        await _query.onSnapshot((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                let note = doc.data();
                let user = await userService.getById(note.note_by);
                note['note_object_alias'] = configs.objects[note.object];
                note['note_by_alias'] = user.fullname + '-' + user.username;
                note['note_time'] = note['note_time'].toDate();
                switch (note.object) {
                    case 'employees':
                        let employee = await employeeService.getById(note.object_id);
                        if (employee) note['note_object_name'] = employee.fullname;
                        note['note_object_link'] = '/employees/view/' + note.object_id;
                        break;
                    case 'customers':
                        let customer = await customerService.getById(note.object_id);
                        note['note_object_name'] = customer.name;
                        note['note_object_link'] = '/customers/view/' + customer.id;
                        break;
                    case 'projects':
                        let project = await projectService.getById(note.object_id);
                        note['note_object_name'] = project.name;
                        note['note_object_link'] = '/projects/view/' + note.object_id;
                        break;

                    default:
                }
                notes.push(note);
            })
        });
        /*
        await Promise.all(
            notes.map(async (note) => {
                let user = await userService.getById(note.note_by);
                note['note_object_alias'] = configs.objects[note.object];
                note['note_by_alias'] = user.fullname + '-' + user.username;
                note['note_time'] = note['note_time'].toDate();
                switch (note.object) {
                    case 'employees':
                        let employee = await employeeService.getById(note.object_id);
                        note['note_object_name'] = employee.fullname;
                        note['note_object_link'] = '/employees/view/' + note.object_id;
                        break;
                    case 'customers':
                        let customer = await customerService.getById(note.object_id);
                        note['note_object_name'] = customer.name;
                        note['note_object_link'] = '/customers/view/' + customer.id;
                        break;
                    case 'projects':
                        let project = await projectService.getById(note.object_id);
                        note['note_object_name'] = project.name;
                        note['note_object_link'] = '/projects/view/' + note.object_id;
                        break;

                    default:
                }

            })
        );
        */
        //notes.sort((a, b) => b.note_time - a.note_time);
        //console.log(notes);
        return notes;
    } catch (error) {
        return error;
    }
}

async function add(note) {
    try {
        const docRef = await db.collection("notes").add(note);
        note.id = docRef.id;
        update(note);
        console.log("Firebase: note has been added successfully!");
        return note;
    } catch (error) {
        console.error("Error adding note: ", error);
    }
}

async function update(note) {
    var Ref = db.collection("notes").doc(note.id);

    try {
        await Ref.update(note);
        console.log("Firebase: note has been updated successfully!");
        return note;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _delete(note) {
    var Ref = db.collection("notes").doc(note.id);

    try {
        await Ref.delete();
        console.log("Firebase: note has been deleted successfully!");
        return note;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}
