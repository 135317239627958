export const customerConstants = {
    SET_PARAMS_REQUEST: 'CUSTOMERS_SET_PARAMS_REQUEST',

    GETALL_REQUEST: 'CUSTOMERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'CUSTOMERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'CUSTOMERS_GETALL_FAILURE',

    DELETE_REQUEST: 'CUSTOMERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'CUSTOMERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'CUSTOMERS_DELETE_FAILURE',

    GET_REQUEST: 'CUSTOMERS_GET_REQUEST',
    GET_SUCCESS: 'CUSTOMERS_GET_SUCCESS',
    GET_FAILURE: 'CUSTOMERS_GET_FAILURE',

    ADD_REQUEST: 'CUSTOMERS_ADD_REQUEST',
    ADD_SUCCESS: 'CUSTOMERS_ADD_SUCCESS',
    ADD_FAILURE: 'CUSTOMERS_ADD_FAILURE',

    UPDATE_REQUEST: 'CUSTOMERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CUSTOMERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CUSTOMERS_UPDATE_FAILURE',

    UPDATE_FIELD_REQUEST: 'CUSTOMERS_UPDATE_FIELD_REQUEST',
    UPDATE_FIELD_SUCCESS: 'CUSTOMERS_UPDATE_FIELD_SUCCESS',
    UPDATE_FIELD_FAILURE: 'CUSTOMERS_UPDATE_FIELD_FAILURE',
}