import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import Contracts from 'src/views/customers/contracts/Contracts';
import { contractActions } from 'src/_actions';

class ContractsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.getcontracts();

    }

    async getcontracts() {
        const { customer } = this.props;
        return await this.props.getAll(customer);
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <Contracts key={this.props.customer.id} {... this.props} contracts={this.props.contracts} user={this.props.user} />
        );
    }
}

function mapStateToProps(state) {
    const { loading, contracts } = state.contractReducer;
    const { user } = state.authentication;
    return { loading, user, contracts };
}

const actionCreators = {
    getAll: contractActions.getAll,
    add: contractActions.add,
    update: contractActions.update,
    _delete: contractActions._delete,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ContractsContainer);
export { connectedContainer as ContractsContainer };