import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CLink,
    CImg,
    CTooltip,
    CInput,
    CContainer,
    CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';

class UploadBB extends Component {

    constructor(props) {
        super(props);

        this.state = {
            upload_files: '',
            files: '',
            do_upload: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {

    }

    handleChange(e) {
        let upload_files = e.target.files;
        //console.log(upload_files);
        this.setState({ upload_files: upload_files });
    }

    handleUpload() {
        let upload_files = this.state.upload_files;
        if (helps.isEmpty(upload_files)) {
            alert("Vui lòng chọn file để tải lên!");
            return;
        }
        const { asset_item } = this.props;
        //console.log(asset_item.object, asset_item.object_id)
        this.setState({ do_upload: true });
        this.props.uploadFile(asset_item.asset, upload_files, asset_item.object, asset_item.object_id);
    }


    render() {
        //const ext = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'svgz', 'heic', 'heif', 'webp', 'tiff', 'tif', 'psd', 'bmp'];
        const { asset_item } = this.props;
        if (helps.isEmpty(asset_item)) return (<></>);
        let _state = (asset_item.state === 'in') ? 'BB Nhập / Thu Hồi' : 'BB Xuất / Bàn Giao';
        let config_object = configs.objects[[asset_item.object]];
        //console.log(configs.objects, config_object)
        return (
            <CContainer>
                <CRow>
                    <CCol md={4} className={'text-right'}>
                        ASSET_ID:
                    </CCol>
                    <CCol><b>{asset_item.id}</b></CCol>
                </CRow>
                <CRow>
                    <CCol md={4} className={'text-right'}>
                        Biên Bản:
                    </CCol>
                    <CCol><b>{_state}</b></CCol>
                </CRow>
                <CRow>
                    <CCol md={4} className={'text-right'}>
                        Ngày:
                    </CCol>
                    <CCol><b>{helps.formatDate_from_Timestamp(asset_item.asset_date)}</b></CCol>
                </CRow>
                <CRow>
                    <CCol md={4} className={'text-right'}>
                        {config_object}:
                    </CCol>
                    <CCol><b>{asset_item.object_name}</b> ({asset_item.object_id})</CCol>
                </CRow>
                <hr />
                <CRow>
                    <CCol xl={12}>
                        <div>
                            <CLabel htmlFor="upload_file"><b>{this.props.form_label}</b></CLabel>
                        </div>
                        <CInput
                            type="file"
                            name='upload_file'
                            id='upload_file'
                            className={'mb-3'}
                            multiple
                            onChange={(e) => { this.handleChange(e) }}
                            accept=""
                        />
                        <CTooltip content={'Tải Lên'}>
                            <CButton
                                type="button"
                                color="primary"
                                disabled={this.state.do_upload}
                                onClick={() => { this.handleUpload() }}
                            >
                                <CIcon name="cil-cloud-upload" className="mfe-1 mt-0"></CIcon>
                                Tải Lên
                                {this.state.do_upload && <CSpinner color="warning" size="sm" className="ml-1" />}
                            </CButton>
                        </CTooltip>
                        {this.props.upload_loading && <div className='mt-4'><Loading></Loading></div>}
                    </CCol>
                </CRow>
            </CContainer>
        )
    }
}
export default UploadBB;
