import { Component } from 'react';
import {
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

class Hint extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }


    render() {
        return (
            <CPopover
                header={this.props.header}
                content={<div dangerouslySetInnerHTML={{ __html: this.props.content }} />}
            >
                <CIcon color='warning' name="cil-lightbulb" style={{ cursor: 'help', color: 'orange' }} />
            </CPopover>
        );
    }

}

export default Hint;