import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CCallout,
    CCollapse,
    CCard,
    CCardHeader,
    CCardBody,
    CTooltip,
    CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import TaskCommentsForm from './TaskCommentsForm';
import { notificationService } from 'src/_services';

class TaskComments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            object: '',
            object_id: '',
            added_time: '',
            content: '',
            showAddForm: false,
            edit_forms: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancelForm = this.handleCancelForm.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
    }

    handleCancelForm() {
        this.setState({ showAddForm: false });
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
    }

    handleDelete(e, comment) {
        e.preventDefault();
        const { task } = this.props;
        let _confirm = window.confirm('Bạn muốn xoá Thảo Luận ?');
        if (_confirm) {
            this.props.deleteComment(task, comment);
        }
        return false;
    }

    handleSubmit(comment) {
        const { task, user } = this.props;
        if (helps.isEmpty(comment.id)) {
            this.props.addComment(task, comment);
            const content = '<b>' + user.fullname + '-' + user.username + '</b> đã thêm Thảo Luận vào công việc <b>' + task.title + '</b>';
            const notification = {
                content: content,
                added_time: new Date(),
                object: 'tasks',
                object_id: task.id,
                object_link: 'tasks/view/' + task.id
            }
            notification['user_id'] = task.added_by;
            //console.log(notification)
            notificationService.add(notification);
            var _users = [];
            if (!helps.isEmpty(task.assigns) && task.assigns.length > 0) {
                task.assigns.map((assign) => {
                    if (assign.value !== task.added_by) {
                        _users.push(assign);
                    }
                });
            }

            if (!helps.isEmpty(task.followers) && task.followers.length > 0) {
                task.followers.map((follower) => {
                    let found = helps.getItemFromArr(task.assigns, follower.value, 'value');
                    if (helps.isEmpty(found) && follower.value !== task.added_by) {
                        _users.push(follower);
                    }
                });
            }

            if (!helps.isEmpty(_users) && _users.length > 0) {
                _users.map((_user) => {
                    let _notification = {
                        content: content,
                        added_time: new Date(),
                        object: 'tasks',
                        object_id: task.id,
                        object_link: 'tasks/view/' + task.id,
                        user_id: _user.value,
                    }
                    notificationService.add(_notification);
                })
            }

            this.handleCancelForm();
        } else {
            this.props.updateComment(task, comment);
            this.toggleEditForms(comment);
        }
    }

    doLikeComment(comment) {
        const { user, task } = this.props;
        var likes = comment.likes ? comment.likes : [];
        const position = likes.indexOf(user.id);
        let newLikes = likes.slice();
        if (position !== -1) {
            newLikes.splice(position, 1)
        } else {
            newLikes = [...likes, user.id]
        }
        comment['likes'] = newLikes;
        this.props.updateComment(task, comment);
    }

    toggleEditForms = (item) => {
        let forms = this.state.edit_forms;
        const position = forms.indexOf(item.id);
        let newForms = forms.slice();
        if (position !== -1) {
            newForms.splice(position, 1)
        } else {
            newForms = [...forms, item.id]
        }
        this.setState({ edit_forms: newForms });
    }

    render() {
        const { task, user } = this.props;
        if (!helps.isEmpty(task.comments) && task.comments.length > 0) {
            task.comments.map((comment) => {
                comment['_added_time'] = helps.formatDateTime_from_Timestamp(comment.added_time);
                comment['is_author'] = false;
                if (user.id == comment.added_by) {
                    comment['is_author'] = true;
                }
            })
            task.comments.sort((a, b) => b.added_time - a.added_time);
        }
        return (
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol xl={6} md={6} sm={12}>
                            Thảo Luận
                        </CCol>
                        <CCol xl={6} md={6} sm={12} className="text-right">
                            <CTooltip content='Thêm Thảo Luận'>
                                <CButton type="button" color="primary" size="sm" onClick={() => { this.setState({ showAddForm: !this.state.showAddForm }) }}>
                                    <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                    Thêm Thảo Luận
                                </CButton>
                            </CTooltip>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    {this.state.showAddForm && <TaskCommentsForm {...this.props} handleSubmit={this.handleSubmit} handleCancelForm={() => { this.handleCancelForm() }} />}
                    <div className='comments-container'>
                        {!helps.isEmpty(task.comments) && task.comments.length > 0 && task.comments.map((comment) => {
                            return (
                                <CCallout key={comment.id} color="secondary" className={'bg-white'}>
                                    <CRow className="mb-2">
                                        <CCol>
                                            <div dangerouslySetInnerHTML={{ __html: comment.content }} />
                                        </CCol>
                                    </CRow>
                                    {comment.is_author && <CCollapse show={this.state.edit_forms.includes(comment.id)} key={"details_" + comment.id} style={{ overflow: 'hidden' }}>
                                        <TaskCommentsForm
                                            {...this.props}
                                            comment={comment}
                                            handleSubmit={this.handleSubmit}
                                            toggleEditForms={() => { this.toggleEditForms(comment) }}
                                            handleCancelForm={() => { this.handleCancelForm() }}
                                        />
                                    </CCollapse>}
                                    <CRow className="mb-2">
                                        <CCol md={12}>
                                            {comment.attachment && comment.attachment.length > 0 && comment.attachment.map(file => {
                                                return (
                                                    <div>
                                                        <small><CIcon name='cil-file' size='sm'></CIcon> <CLink href={file.url} target='_blank'>{file.name}</CLink></small>
                                                    </div>
                                                )
                                            })}
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol xl={6} md={6}>
                                            <small className='text-muted font-italic'>{comment._added_time} | <i className="font-weight-bold">{comment.added_by_alias}</i></small>
                                        </CCol>
                                        <CCol xl={6} md={6}>
                                            <div className='text-right'>
                                                <CButton
                                                    type="button"
                                                    size="sm"
                                                    title='Thả tim'
                                                    onClick={() => { this.doLikeComment(comment); }}
                                                    className='mr-2'
                                                >
                                                    <CIcon
                                                        name="cil-heart"
                                                        className={"mfe-1 mt-0" + (!helps.isEmpty(comment.likes)) ? 'liked' : ''}
                                                    ></CIcon>
                                                </CButton>
                                                <CButton
                                                    disabled={!comment.is_author}
                                                    type="button"
                                                    size="sm"
                                                    color={comment.is_author ? "primary" : "secondary"}
                                                    title='Sửa' onClick={() => { this.toggleEditForms(comment); }}
                                                >
                                                    <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                </CButton>
                                                <CButton
                                                    disabled={!comment.is_author}
                                                    color={comment.is_author ? "danger" : "secondary"}
                                                    size="sm"
                                                    title="Xoá"
                                                    className="ml-1"
                                                    onClick={(e) => { this.handleDelete(e, comment) }}
                                                >
                                                    <CIcon name="cil-trash"></CIcon>
                                                </CButton>
                                            </div>
                                        </CCol>
                                    </CRow>
                                    <hr />
                                </CCallout>
                            );
                        })}
                    </div>
                </CCardBody>
            </CCard>
        )
    }
}


export default TaskComments;
