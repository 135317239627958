import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import Notes from 'src/views/notes/Notes';
import { noteActions } from '../../_actions';

class NotesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.getNotes();
    }

    async getNotes() {
        await this.props.getAll(this.props.object, this.props.object_id);
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <Notes key={this.props.object + '-' + this.props.object_id} {... this.props} />
        );
    }
}

function mapStateToProps(state) {
    const { loading, notes } = state.noteReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    return { loading, user, notes, users };
}

const actionCreators = {
    getAll: noteActions.getAll,
    add: noteActions.add,
    update: noteActions.update,
    _delete: noteActions._delete,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(NotesContainer);
export { connectedContainer as NotesContainer };