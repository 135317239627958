import React, { Component } from 'react'
import {
    CRow,
    CCol,
    CButton,
    CLink,
    CTooltip,
    CBadge,
    CPopover,
    CCard,
    CCardHeader,
    CCardBody,
    CCollapse,
    CLabel,
    CForm,
    CInput,
    CFormGroup,
    CSelect,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import Loading from 'src/containers/_loading';
import configs from 'src/appConfigs';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import Select from 'react-select';
import BookOutExport from './BookOutExport';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import locale from 'date-fns/locale/vi';
import DatePicker from 'react-datepicker';

class BooksOutApproval extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show_filter: false,
            book_date: '',
            book_id: '',
            book_object: '',
            book_object_ids: [],
            book_period_from: '',
            book_period_to: '',
            book_account: '',
            book_section: '',
        }

        this.handleDelete = this.handleDelete.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleApprove = this.handleApprove.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {
        const queryPage = this.props.location.search.match(/page=([0-9]+)/, '');
        const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
        this.setState({ currentPage: currentPage });
    }

    pageChange = (newPage) => {
        var currentPage = this.state.currentPage;
        currentPage !== newPage && this.props.history.push(`/users?page=${newPage}`)
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    async handleApprove(book) {
        const { user, taxonomies, books } = this.props;
        let _books = books.filter(book => !book.approval_required || (book.approval_required && book.approved));
        var maxCount = _books.map(x => +x['count']).reduce((a, b) => a > b ? a : b, 0);
        let _books_out = _books.filter(book => book.type === 'out');
        var prev_book = _books.find(book => book['count'] === maxCount);
        if (helps.isEmpty(prev_book)) {
            prev_book = await this.props.getLastMonthBook(this.props.month);
        }
        let prev_stock = prev_book.stock ? parseFloat(prev_book.stock) : 0;

        let taxonomy = helps.getItemFromArr(taxonomies, book.book_account);
        let stock = taxonomy.stock ? taxonomy.stock : 0;

        book.approved = true;
        this.props.updateField(book, 'approved');
        book['approved_by'] = user.id;
        this.props.updateField(book, 'approved_by');
        book['approved_time'] = new Date();
        this.props.updateField(book, 'approved_time');

        book['book_date'] = new Date();
        this.props.updateField(book, 'book_date');

        book['stock'] = prev_stock - parseFloat(book.amount);
        this.props.updateField(book, 'stock');

        book['count'] = maxCount + 1;
        this.props.updateField(book, 'count');

        let _index = _books_out ? _books_out.length : 1;
        book['index'] = _index + 1;
        this.props.updateField(book, 'index');

        let book_date = book['book_date'];
        let year = book_date.getFullYear();
        let month = (book_date.getMonth() + 1).toString().padStart(2, "0");
        book['book_id'] = 'SAC-' + month + year + '-' + book['index'];
        this.props.updateField(book, 'book_id');

        taxonomy['stock'] = parseFloat(stock) - parseFloat(book.amount);
        this.props.updateTaxonomyField(taxonomy, 'stock');
    }

    handleChangeDate(_date, field) {
        this.setState({ [field]: _date });
    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [field]: selectedOptions });
    }

    handleSelectObject(object, object_id) {
        this.setState({
            book_object: object,
            book_object_ids: new Array(object_id),
        });
    }

    handleChangeMonth(value) {
        var firstDay = new Date(value.getFullYear(), value.getMonth(), 1);
        var lastDay = new Date(value.getFullYear(), value.getMonth() + 1, 0);
        this.setState({ month: value, book_period_from: firstDay, book_period_to: lastDay });
    }

    handleDelete(e, item) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Khoản Chi: "' + item.id + '"?');
        if (_confirm) {
            this.props._delete(item);
        }
        return false;
    }

    render() {
        const { books, taxonomies, user, users, employees, projects, customers, vendors, housings, approvals } = this.props;

        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        let employees_options = [];
        if (!helps.isEmpty(employees)) {
            employees.forEach((employee) => {
                employees_options.push({ value: employee.employee_id, label: employee.fullname + ' (' + employee.employee_id + ')' });
            })
        }

        let customers_options = [];
        if (!helps.isEmpty(customers)) {
            customers.forEach((customer) => {
                customers_options.push({ value: customer.id, label: customer.name });
            })
        }

        let housings_options = [];
        if (!helps.isEmpty(housings)) {
            housings.forEach((housing) => {
                housings_options.push({ value: housing.id, label: housing.name + ' (' + housing.address + ')' });
            })
        }

        let vendors_options = [];
        if (!helps.isEmpty(vendors)) {
            vendors.forEach((vendor) => {
                vendors_options.push({ value: vendor.id, label: vendor.name });
            })
        }

        let projects_options = [];
        if (!helps.isEmpty(projects)) {
            projects.forEach((project) => {
                projects_options.push({ value: project.id, label: project.name });
            })
        }

        var _books = [];
        if (!helps.isEmpty(books) && books.length > 0) {
            _books = books.filter(book => book.approval_required && !book.approved);
        }

        //console.log(_books);

        // book date
        if (this.state.book_date) {
            _books = _books.filter((book) => book.book_date.getDate() === this.state.book_date.getDate());
        }

        // book id
        if (!helps.isEmpty(this.state.book_id)) {
            _books = _books.filter((book) => book.id === this.state.book_id);
        }

        // book account
        if (!helps.isEmpty(this.state.book_account)) {
            _books = _books.filter((book) => book.book_account === this.state.book_account);
        }

        // book section
        if (!helps.isEmpty(this.state.book_section)) {
            _books = _books.filter((book) => book.book_section === this.state.book_section);
        }

        // book object
        if (!helps.isEmpty(this.state.book_object)) {
            _books = _books.filter((book) => book.book_object === this.state.book_object);
        }

        // book object ids
        if (!helps.isEmpty(this.state.book_object_ids)) {
            _books = _books.filter((book) => {
                if (helps.isEmpty(book.book_object_ids)) return false;
                else {
                    let result = book.book_object_ids.filter(({ value }) => this.state.book_object_ids.some(x => x.value === value));
                    console.log(result)
                    if (!helps.isEmpty(result)) return true;
                    else return false;
                }
            });
        }

        // book period
        if (this.state.book_period_from && this.state.book_period_to) {
            //console.log(this.state.book_period_from, this.state.book_period_to)
            _books = _books.filter((book) => {
                if (book.book_period_from && book.book_period_to)
                    return book.book_period_from.getTime() >= this.state.book_period_from.getTime() && book.book_period_to.getTime() <= this.state.book_period_to.getTime();
                else return false;
            });
        }

        _books = _books.sort(function (a, b) {
            return new Date(b.addd_time).getTime() - new Date(a.addd_time).getTime()
        });

        let total_amount = 0;
        return (
            <CRow>
                <CCol xl={12} md={12}>
                    <CForm>
                        <CCard>
                            <CCardHeader>
                                <CRow>
                                    <CCol>
                                        Lọc:
                                    </CCol>
                                    <CCol className={'text-right'}>
                                        <CTooltip content={this.state.sh_s1 ? 'Ẩn' : 'Hiện'}>
                                            <CButton type="button" color="secondary" size="sm" onClick={() => { this.setState({ show_filter: !this.state.show_filter }) }} title={this.state.show_filter ? 'Ẩn' : 'Hiện'} className='ml-2'>
                                                <CIcon name={this.state.show_filter ? 'cil-chevron-top' : 'cil-chevron-bottom'} className="mfe-1 mt-0"></CIcon>
                                            </CButton>
                                        </CTooltip>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCollapse show={this.state.show_filter}>
                                <CCardBody>
                                    <CRow>
                                        <CCol md={3}>
                                            <CRow className={'mb-1'}>
                                                <CCol className='text-right'>
                                                    <CLabel htmlFor='filter-book_id' className='mt-1'>Số chứng từ:</CLabel>
                                                </CCol>
                                                <CCol>
                                                    <CInput
                                                        type='text'
                                                        id='filter-book_id'
                                                        name='book_id'
                                                        value={this.state.book_id}
                                                        placeholder='...'
                                                        className="form-control-sm"
                                                        onChange={(e) => { this.handleChange('book_id', e) }}
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow className={'mb-1'}>
                                                <CCol className='mb-2 text-right'>
                                                    <CLabel htmlFor='filter-book_date' className='mt-1'>Ngày chứng từ:</CLabel>
                                                </CCol>
                                                <CCol>
                                                    <DateTimePicker
                                                        id='filter-book_date'
                                                        value={this.state.book_date}
                                                        format={'dd/MM/yyyy'}
                                                        onChange={(value) => { this.handleChangeDate(value, 'book_date') }}
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow className={'mb-1'}>
                                                <CCol className='mb-2 text-right'>
                                                    <CLabel htmlFor='filter-book_account' className='mt-1'>Tài khoản:</CLabel>
                                                </CCol>
                                                <CCol>
                                                    <CSelect
                                                        id='filter-book_account'
                                                        name='book_account'
                                                        value={this.state.book_account}
                                                        className="form-control-sm"
                                                        onChange={(e) => { this.handleChange('book_account', e) }}
                                                    >
                                                        <option value={''}> - chọn Tài khoản - </option>
                                                        {!helps.isEmpty(taxonomies) && taxonomies.map((taxonomy) => {
                                                            if (taxonomy.category_id === 'account') {
                                                                return (
                                                                    <option key={'book_account_' + taxonomy.id} value={taxonomy.id}>{taxonomy.code} {taxonomy.name}</option>
                                                                )
                                                            } else return (<></>);
                                                        })}
                                                    </CSelect>
                                                </CCol>
                                            </CRow>
                                            <CRow className={'mb-1'}>
                                                <CCol className='mb-2 text-right'>
                                                    <CLabel htmlFor='filter-book_section' className='mt-1'>Khoản:</CLabel>
                                                </CCol>
                                                <CCol>
                                                    <CSelect
                                                        id='filter-book_section'
                                                        name='book_section'
                                                        className="form-control-sm"
                                                        value={this.state.book_section}
                                                        onChange={(e) => { this.handleChange('book_section', e) }}
                                                    >
                                                        <option value={''}> - chọn Khoản - </option>
                                                        {!helps.isEmpty(taxonomies) && taxonomies.map((taxonomy) => {
                                                            if (taxonomy.category_id === 'section') {
                                                                return (
                                                                    <option key={'book_section_' + taxonomy.id} value={taxonomy.id}>{taxonomy.code} {taxonomy.name}</option>
                                                                )
                                                            } else return (<></>);
                                                        })}
                                                    </CSelect>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        <CCol md={5}>
                                            <CRow className={'mb-1'}>
                                                <CCol className='mb-2 text-right' md={4}>
                                                    <CLabel htmlFor='filter-book_object' className='mt-1'>Đối tượng:</CLabel>
                                                </CCol>
                                                <CCol md={8}>
                                                    <CSelect
                                                        id='filter-book_object'
                                                        name='book_object'
                                                        className="form-control-sm"
                                                        value={this.state.book_object}
                                                        onChange={(e) => { this.handleChange('book_object', e) }}
                                                    >
                                                        <option value={''}> - chọn Đối tượng - </option>
                                                        {configs.acc_objects && Object.keys(configs.acc_objects).map((_key) => {
                                                            return (
                                                                <option key={_key} value={_key}>{configs.acc_objects[_key]}</option>
                                                            )
                                                        }
                                                        )}
                                                    </CSelect>

                                                </CCol>
                                            </CRow>
                                            {this.state.book_object === 'employees' &&
                                                <CRow className={'mb-1'}>
                                                    <CCol className='mb-2 text-right' md={4}>
                                                        <CLabel htmlFor='filter-book_object_ids' className='mt-1'>Nhân Sự:</CLabel>
                                                    </CCol>
                                                    <CCol>
                                                        <Select
                                                            id="input-book_object_ids"
                                                            options={employees_options}
                                                            value={this.state.book_object_ids}
                                                            isMulti
                                                            placeholder='chọn Nhân viên'
                                                            onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids'); }}
                                                        />
                                                    </CCol>
                                                </CRow>
                                            }
                                            {this.state.book_object === 'customers' &&
                                                <CRow className={'mb-1'}>
                                                    <CCol className='mb-2 text-right' md={4}>
                                                        <CLabel htmlFor='filter-book_object_ids' className='mt-1'>Khách hàng:</CLabel>
                                                    </CCol>
                                                    <CCol>
                                                        <Select
                                                            id="input-customer_id"
                                                            options={customers_options}
                                                            value={this.state.book_object_ids}
                                                            isMulti
                                                            placeholder='chọn Khách hàng'
                                                            onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                        />
                                                    </CCol>
                                                </CRow>
                                            }
                                            {this.state.book_object === 'housings' &&
                                                <CRow className={'mb-1'}>
                                                    <CCol className='mb-2 text-right' md={4}>
                                                        <CLabel htmlFor='filter-book_object_ids' className='mt-1'>Nhà đội:</CLabel>
                                                    </CCol>
                                                    <CCol>
                                                        <Select
                                                            id="input-customer_id"
                                                            options={housings_options}
                                                            value={this.state.book_object_ids}
                                                            isMulti
                                                            placeholder='chọn Nhà đội'
                                                            onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                        />
                                                    </CCol>
                                                </CRow>
                                            }

                                            {this.state.book_object === 'projects' &&
                                                <CRow className={'mb-1'}>
                                                    <CCol className='mb-2 text-right' md={4}>
                                                        <CLabel htmlFor='filter-book_object_ids' className='mt-1'>Mục tiêu:</CLabel>
                                                    </CCol>
                                                    <CCol>
                                                        <Select
                                                            id="input-customer_id"
                                                            options={projects_options}
                                                            value={this.state.book_object_ids}
                                                            isMulti
                                                            placeholder='chọn Mục tiêu'
                                                            onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                        />
                                                    </CCol>
                                                </CRow>
                                            }

                                            {this.state.book_object === 'vendors' &&
                                                <CRow className={'mb-1'}>
                                                    <CCol className='mb-2 text-right' md={4}>
                                                        <CLabel htmlFor='filter-book_object_ids' className='mt-1'>Nhà cung cấp:</CLabel>
                                                    </CCol>
                                                    <CCol>
                                                        <Select
                                                            id="input-customer_id"
                                                            options={vendors_options}
                                                            value={this.state.book_object_ids}
                                                            isMulti
                                                            placeholder='chọn Nhà cung cấp'
                                                            onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                        />
                                                    </CCol>
                                                </CRow>
                                            }
                                        </CCol>
                                        <CCol md={4}>
                                            <div className="mb-3">
                                                <CLabel htmlFor="input-book_period_from">Kỳ Thanh Toán:</CLabel>
                                            </div>
                                            <CRow className="mb-3">
                                                <CCol>
                                                    <CFormGroup variant='custom-radio' inline>
                                                        <CLabel htmlFor='filter-month' className='mt-1'>Tháng:</CLabel>
                                                        <DatePicker
                                                            id='filter-month'
                                                            locale={locale}
                                                            selected={this.state.month}
                                                            onChange={(date) => { this.handleChangeMonth(date) }}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                            className='ml-2 form-control'
                                                        />
                                                    </CFormGroup>
                                                </CCol>
                                            </CRow>
                                            <CRow className="mb-3">
                                                <CCol className='mb-2 text-right'>
                                                    <CFormGroup variant='custom-radio' inline>
                                                        <CLabel htmlFor='filter-book_period_from' className='mt-1'>từ ngày:</CLabel>
                                                        <DateTimePicker
                                                            id='filter-book_period_from'
                                                            value={this.state.book_period_from}
                                                            format={'dd/MM/yyyy'}
                                                            className='ml-2'
                                                            maxDate={this.state.book_period_to ? this.state.book_period_to : null}
                                                            onChange={(value) => { this.handleChangeDate(value, 'book_period_from') }}
                                                        />
                                                    </CFormGroup>
                                                </CCol>
                                                <CCol>
                                                    <CFormGroup variant='custom-radio' inline>
                                                        <CLabel htmlFor='filter-book_period_to' className='mt-1'>đến ngày:</CLabel>
                                                        <DateTimePicker
                                                            id='filter-book_period_to'
                                                            value={this.state.book_period_to}
                                                            minDate={this.state.book_period_from ? this.state.book_period_from : null}
                                                            format={'dd/MM/yyyy'}
                                                            className='ml-2'
                                                            onChange={(value) => { this.handleChangeDate(value, 'book_period_to') }}
                                                        />
                                                    </CFormGroup>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCollapse>
                        </CCard>
                    </CForm>
                    <div className="table-responsive">
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th className='text-center' style={{ width: '10%' }}>Duyệt</th>
                                    <th>Tài Khoản</th>
                                    <th>Nội Dung</th>
                                    <th>Khoản</th>
                                    <th className='text-center'>Số Tiền</th>
                                    <th>Đối Tượng</th>
                                    <th>Kỳ Thanh Toán</th>
                                    <th>Ghi Chú</th>
                                    <th>Tạo Bởi</th>
                                    <th className='text-center' style={{ width: '10%' }}>Thao Tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!helps.isEmpty(_books) && _books.map((book, index) => {
                                    let book_account = helps.getItemFromArr(taxonomies, book.book_account);
                                    let book_section = helps.getItemFromArr(taxonomies, book.book_section);
                                    total_amount += parseFloat(book.amount);
                                    let book_objects = [];
                                    let object_color = 'secondary';
                                    switch (book.book_object) {
                                        case 'employees':
                                            book.book_object_ids.forEach((object_id) => {
                                                let item = helps.getItemFromArr(employees, object_id.value, 'employee_id');
                                                book_objects.push(item);
                                            })
                                            object_color = 'warning';
                                            break;
                                        case 'projects':
                                            book.book_object_ids.forEach((object_id) => {
                                                let item = helps.getItemFromArr(projects, object_id.value);
                                                book_objects.push(item);
                                            })
                                            object_color = 'success';
                                            break;
                                        case 'customers':
                                            book.book_object_ids.forEach((object_id) => {
                                                let item = helps.getItemFromArr(customers, object_id.value);
                                                book_objects.push(item);
                                            })
                                            object_color = 'primary';
                                            break;
                                        default:

                                    }
                                    let is_author = (book.added_by === user.id) ? true : false;
                                    //console.log(book.approved_time)
                                    return (
                                        <tr key={book.id} id={book.id}>
                                            <td>{index + 1}</td>
                                            <td className='text-center'>
                                                {book.approval_required && !book.approved && helps.checkApproval(approvals, user, 'expenses_approver') && <>
                                                    <CTooltip content={'Duyệt Chi'}>
                                                        <CButton
                                                            color="primary"
                                                            size="sm mr-1"
                                                            onClick={() => { this.handleApprove(book) }}
                                                        >
                                                            <CIcon name="cil-check" className='mr-1'></CIcon>
                                                            Duyệt
                                                        </CButton>
                                                    </CTooltip>
                                                </>}
                                                {book.approval_required && !book.approved && !helps.checkApproval(approvals, user, 'expenses_approver') && <>
                                                    <CPopover content={'Chưa Duyệt Chi'}>
                                                        <CBadge color='secondary'>
                                                            <CIcon name="cil-x-circle" size='sm'></CIcon>
                                                        </CBadge>
                                                    </CPopover>
                                                </>}
                                                {book.approval_required && book.approved && <>
                                                    <CPopover header={'Đã Duyệt'} content={<div><div><i><u>Người duyệt:</u></i> {helps.getUserAlias(users, book.approved_by)}</div><div><i><u>Thời gian:</u></i> {helps.formatDateTime_from_Timestamp(book.approved_time)}</div></div>}>
                                                        <CBadge color='success'>
                                                            <CIcon name="cil-check" size='sm'></CIcon>
                                                        </CBadge>
                                                    </CPopover>
                                                </>}
                                            </td>
                                            <td>
                                                <CPopover content={<div>{helps.formatCurrency(book_account.stock)}</div>}>
                                                    <div>{book_account.code && <>{book_account.code} </>}{book_account.name}</div>
                                                </CPopover>
                                            </td>
                                            <td>{book.description}</td>
                                            <td>
                                                {book_section.name}
                                            </td>
                                            <td className='text-right'><b>{helps.formatCurrency(book.amount)}</b></td>
                                            <td>
                                                <CBadge color={object_color} className={'mr-1'}>{configs.acc_objects[[book.book_object]]}:</CBadge>
                                                {book.book_object_ids && book.book_object_ids.map((object_id) => {
                                                    return (
                                                        <CLink key={book.id + '-' + object_id.value} className='ml-1' onClick={() => { this.handleSelectObject(book.book_object, object_id) }} title={'Lọc theo: ' + object_id.label}>{object_id.label}</CLink>
                                                    );
                                                })}
                                                {book.book_object === 'other' && book.book_object_content && <span className='ml-1'>{book.book_object_content}</span>}
                                            </td>
                                            <td>
                                                {book.book_period_from && <span>{helps.formatDate_from_Timestamp(book.book_period_from)}</span>}
                                                <span> - </span>
                                                {book.book_period_to && <span>{helps.formatDate_from_Timestamp(book.book_period_to)}</span>}
                                            </td>
                                            <td>
                                                {book.note}
                                            </td>
                                            <td>
                                                <small>{helps.getUserAlias(users, book.added_by)}</small>
                                                <div><small><i>{helps.formatDateTime_from_Timestamp(book.added_time)}</i></small></div>
                                            </td>
                                            <td className='text-center'>
                                                {!book.approved && <>
                                                    <CTooltip content={'Cập nhật thông tin: ' + book.book_id}>
                                                        <CButton
                                                            color="primary"
                                                            size="sm mr-1"
                                                            title={'Cập nhật thông tin: ' + book.book_id}
                                                            onClick={() => { this.props.toggleModal('out_form', book) }}
                                                        >
                                                            <CIcon name="cil-pen-nib"></CIcon>
                                                        </CButton>
                                                    </CTooltip>
                                                </>}
                                                <CTooltip content={'Xoá: ' + book.id}>
                                                    <CButton
                                                        color={is_author ? "danger" : "secondary"}
                                                        size="sm mr-1"
                                                        title={!is_author ? "Bạn không có quyền xóa!" : "Xoá"}
                                                        disabled={!is_author}
                                                        onClick={(e) => { this.handleDelete(e, book) }}
                                                    >
                                                        <CIcon name="cil-trash"></CIcon>
                                                    </CButton>
                                                </CTooltip>
                                                <BookOutExport {... this.props} book={book} />
                                            </td>
                                        </tr>
                                    )
                                })}
                                {helps.isEmpty(_books) && <tr><td colSpan={20} className='text-center m-3'><h5 className='text-secondary m-3 p-3'><b>Không tìm thấy dữ liệu!</b></h5></td></tr>}
                            </tbody>
                            <tfoot className='bg-secondary text-dark'>
                                <tr>
                                    <th colSpan={5} className='text-right'>Tổng:</th>
                                    <th className='text-right'>{helps.formatCurrency(total_amount)}</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </CCol>
            </CRow >
        )
    }
}


export default BooksOutApproval;
