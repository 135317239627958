import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
    CCol,
    CRow,
    CBadge,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import allLocales from '@fullcalendar/core/locales-all'


class TasksCalendar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            object: '',
            object_id: '',
            filter_by_priority: '',
        }
        this.handleEventClick = this.handleEventClick.bind(this);
    }

    componentDidMount() {

    }

    handleChange(e, field) {
        let value = e.target.value;
        this.setState({ [field]: value });
    }

    handleDelete(e, task) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Công Việc: ' + task.title + ' ?');
        if (_confirm) {
            this.props._delete(task);
        }
        return false;
    }

    handleEventClick(clickInfo) {
        let event = clickInfo.event;
        console.log(clickInfo.event.id);
        this.props.history.push('/tasks/view/' + event.id);
    }

    renderEventContent(eventInfo) {
        let task = eventInfo.event.extendedProps;
        var added_time = '';
        if (task.added_time) {
            added_time = helps.formatDateTime_from_Timestamp(task.added_time);
        }
        let priority = helps.getItemFromArr(configs.task_priorities, task.priority);
        var _class = 'text-wrap';
        if (task.status === 'finished') {
            _class = 'text-success text-wrap';
        }
        return (
            <div>
                <CPopover
                    content={
                        <div>
                            <h6 className='border-bottom pb-1'><b>{eventInfo.event.title}</b></h6>
                            {task.object &&
                                <div className='mt-1'>
                                    <i>{task.object_alias}</i>{task.object_name && <span>: {task.object_name}</span>}
                                </div>
                            }
                            <div className='mt-1'>
                                <small className='text-muted'><i><u>Giao bởi:</u></i> <b>{task.added_by_alias}</b> {added_time && <>| {added_time}</>}</small>
                            </div>
                        </div>
                    }
                >
                    <span className='cursor-pointer mt-1 mb-1 swap-text'>
                        <CBadge color={priority.color}>{priority.label}</CBadge> <span className={_class} style={{
                            textDecoration: (task.status === 'finished') ? 'line-through' : 'none',
                        }}>{eventInfo.event.title}</span>
                    </span>
                </CPopover>
            </div>
        );
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        const { tasks, user, filter } = this.props;
        const now = new Date();
        var _tasks = [];
        var _tasks_assigns = [];
        var _tasks_owns = [];
        var _tasks_follows = [];
        if (!helps.isEmpty(tasks) && tasks.length > 0) {
            _tasks_assigns = tasks.filter(task => task.filter === 'assigns');
            _tasks_owns = tasks.filter(task => task.filter === 'owns');
            _tasks_follows = tasks.filter(task => task.filter === 'follows');
        }
        _tasks = _tasks.concat(_tasks_assigns, _tasks_owns, _tasks_follows);
        _tasks = _tasks.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);

        if (_tasks && _tasks.length > 0) {
            _tasks.map((task, index) => {
                task['is_author'] = false;
                if (user.id == task.added_by) {
                    task['is_author'] = true;
                }
                task['over_deadline'] = '';
                if (task.deadline) {
                    if (task.status === 'unfinished' && now > task.deadline) {
                        task['over_deadline'] = helps.secondsToDhm((now - task.deadline) / 1000);
                    }
                }
                if (task.deadline) {
                    task['start'] = helps.formatDate_for_input(task.deadline, "YYYY-MM-DD");
                    task['end'] = helps.formatDate_for_input(task.deadline, "YYYY-MM-DD");
                }

            })
        }

        if (!helps.isEmpty(this.state.filter_by_priority)) {
            _tasks = _tasks.filter(task => task.priority == this.state.filter_by_priority);
        }
        //console.log(_tasks);
        return (
            <CRow>
                <CCol xl={12}>
                    <FullCalendar
                        locales={allLocales}
                        locale={'vn'}
                        themeSystem="bootstrap"
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        weekends={true}
                        events={_tasks}
                        eventContent={this.renderEventContent}
                        eventBackgroundColor={'#FFF'}
                        eventColor={'#FFF'}
                        eventTextColor={'#333'}
                        eventClick={this.handleEventClick}
                    />
                </CCol>
            </CRow >
        )
    }
}

export default TasksCalendar;
