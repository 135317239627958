import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CCardFooter,
    CInput,
    CTooltip,
    CSwitch,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CFormGroup,
    CBadge,
    CLabel,
    CInputRadio,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { ThePermission } from '../ThePermission';
import Projects from 'src/views/projects/Projects';
import { employeeActions, noteActions, positionActions, projectActions, provinceActions, userActions } from '../../_actions';
import { helps } from 'src/_helpers';
import Select from 'react-select'
import configs from 'src/appConfigs';

class ProjectsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_header: 'Quản Lý Mục Tiêu',
            project_type: 'project',
            search: '',
            province_id: '',
            active: 1,
            mine: '',
            managed_by: '',
            color: '',
            projects: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {
        if (!this.props.users) {
            this.props.getUsers();
        }

        if (!this.props.provinces) {
            this.props.getProvinces();
        }

        this.getPositions();

        this.getNotes();

        this.getEmployees();

        this.filteredProjects();
    }

    async getNotes() {
        await this.props.getNotes('projects');
    }

    async getEmployees() {
        const { employees } = this.props;
        if (helps.isEmpty(employees)) return await this.props.getEmployees();
        else return employees;
    }

    async getPositions() {
        const { positions } = this.props;
        if (helps.isEmpty(positions)) return await this.props.getPositions();
        else return positions;
    }

    filteredProjects() {
        if (helps.isEmpty(this.props.projects)) this.props.getAll();
        const { projects, user } = this.props;
        const search = this.state.search.toLocaleLowerCase();
        const province_id = this.state.province_id;
        const active = parseInt(this.state.active);
        var result = projects ? projects : [];
        if (!helps.isEmpty(search)) {
            result = result.filter(project => project.name.toLocaleLowerCase().includes(search));
        }
        if (!helps.isEmpty(province_id)) {
            result = result.filter(project => project.province_id === province_id);
        }
        if (this.state.color !== '') {
            result = result.filter(project => project.color === this.state.color);
        }
        if (this.state.managed_by) {
            result = result.filter((project) => {
                if (!helps.isEmpty(project.assigns) && project.assigns.length > 0) {
                    let found = helps.getItemFromArr(project.assigns, this.state.managed_by, 'value');
                    if (helps.isEmpty(found)) return false;
                    else return true;
                } else {
                    return false;
                }
            });
        }
        if (this.state.mine) {
            result = result.filter((project) => {
                if (!helps.isEmpty(project.assigns) && project.assigns.length > 0) {
                    let found = helps.getItemFromArr(project.assigns, user.employee_id, 'value');
                    if (helps.isEmpty(found)) return false;
                    else return true;
                } else {
                    return false;
                }
            });
        }
        if (active === 1) {
            result = result.filter(project => project.active === 1);
        } else {
            result = result.filter(project => project.active === 1 || project.active === 0);
        }
        //console.log(active)
        //console.log(search, province_id, result)
        //this.setState({ projects: result });
        return result;
    }

    handleChange(field, e) {
        let value = e.target.value;
        this.setState({
            [field]: value
        });
        if (field === 'active' && e.target.checked) {
            this.setState({ active: 0 });
        } else {
            this.setState({ active: 1 });
        }
        if (field === 'mine' && e.target.checked) {
            this.setState({ mine: true });
        } else {
            this.setState({ mine: '' });
        }

    }

    handleChangeSelect(selectedOptions, field) {
        if (selectedOptions) {
            this.setState({ [field]: selectedOptions.value });
        } else {
            this.setState({ [field]: '' });
        }
    }

    render() {
        const { provinces, employees } = this.props;
        var province_options = [];
        var default_province_option = [];
        if (provinces && provinces.length > 0) {
            provinces.map((province) => {
                province_options.push({ value: province.id, label: province.name });
            });
            default_province_option = province_options.find(({ value }) => value === this.state.province_id);
        }

        var manage_options = [];
        var default_manage_option = [];
        if (employees && employees.length > 0) {
            employees.map((employee) => {
                if (employee.group === 'VP' && employee.job_type === 'NVCT') {
                    manage_options.push({ value: employee.employee_id, label: employee.fullname });
                }
            });
            default_manage_option = manage_options.find(({ value }) => value === this.state.managed_by);
        }

        let projects = this.filteredProjects();
        return (
            <ThePermission {... this.props} component="projects" action="view">
                <CRow key={helps.convertToSlug(this.state.page_header)} className={'projects'}>
                    <CCol xl={12}>
                        <CCard>
                            <CCardHeader>
                                <CRow>
                                    <CCol md={2} sm={12}>
                                        <h1 className='mb-1'>{this.state.page_header}</h1>
                                    </CCol>
                                    <CCol md={3} sm={12}>
                                        <CInput
                                            type='text'
                                            name='search'
                                            placeholder='Tìm kiếm ...'
                                            className='header-searchbox mb-1'
                                            defaultValue={this.state.search}
                                            onChange={(e) => { this.handleChange('search', e) }}
                                        />
                                    </CCol>
                                    <CCol md={2} sm={12} className={'text-center'}>
                                        <Select
                                            id="input-managed_by"
                                            name="managed_by"
                                            placeholder="Phụ Trách ..."
                                            className={'text-left'}
                                            isClearable
                                            options={manage_options}
                                            defaultValue={manage_options.find(({ value }) => value === this.state.managed_by)}
                                            onChange={(choice) => { this.handleChangeSelect(choice, 'managed_by') }}
                                        />
                                    </CCol>
                                    <CCol md={2} sm={12}>
                                        <Select
                                            id="input-province_id"
                                            name="province_id"
                                            placeholder="Tỉnh thành / Khu vực"
                                            isClearable
                                            options={province_options}
                                            defaultValue={province_options.find(({ value }) => value === this.state.province_id)}
                                            onChange={(choice) => { this.handleChangeSelect(choice, 'province_id') }}
                                        />
                                    </CCol>

                                    <CCol md={1} sm={12}>
                                        <CDropdown tag={'span'} className='mr-3 ml-3'>
                                            <CTooltip content='Lọc theo màu sắc'>
                                                <CDropdownToggle caret color="secondary" >
                                                    <CIcon name="cil-filter" className='mr-1' />
                                                    Màu Sắc
                                                </CDropdownToggle>
                                            </CTooltip>
                                            <CDropdownMenu className="pt-0" placement="bottom-end">
                                                {configs.colors && Object.keys(configs.colors).map((_key) => {
                                                    return (
                                                        <CDropdownItem header key={_key}>
                                                            <CFormGroup variant="custom-radio" inline>
                                                                <CInputRadio
                                                                    custom
                                                                    id={"color_" + _key}
                                                                    name="color"
                                                                    value={_key}
                                                                    checked={this.state.color === _key}
                                                                    onChange={(e) => { this.handleChange('color', e) }}
                                                                />
                                                                <CLabel variant="custom-checkbox" htmlFor={"color_" + _key} className="cursor-pointer">
                                                                    <CBadge color={_key} className="p-2">{configs.colors[_key]}</CBadge>
                                                                </CLabel>
                                                            </CFormGroup>
                                                        </CDropdownItem>
                                                    )
                                                }
                                                )}
                                                <CDropdownItem divider></CDropdownItem>
                                                <CDropdownItem header>
                                                    <CFormGroup variant="custom-radio" inline>
                                                        <CInputRadio
                                                            custom
                                                            id={"color_all"}
                                                            name="color"
                                                            value={''}
                                                            checked={this.state.color === ''}
                                                            onChange={(e) => { this.handleChange('color', e) }}
                                                        />
                                                        <CLabel variant="custom-checkbox" htmlFor={"color_all"} className="cursor-pointer">
                                                            - tất cả -
                                                        </CLabel>
                                                    </CFormGroup>
                                                </CDropdownItem>
                                            </CDropdownMenu>
                                        </CDropdown>
                                    </CCol>
                                    <CCol md={1} sm={12} className={'text-center'}>
                                        <CTooltip content={'Mục Tiêu tôi phụ trách.'}>
                                            <div>
                                                <CSwitch
                                                    color='primary'
                                                    size='lg'
                                                    className={'mt-2'}
                                                    variant='3d'
                                                    value={0}
                                                    onClick={(e) => { this.handleChange('mine', e) }}
                                                />
                                            </div>
                                        </CTooltip>
                                    </CCol>
                                    <CCol md={1} sm={12} className={'text-center'}>
                                        <CTooltip content={'Hiển thị bao gồm cả các mục tiêu đã kết thúc.'}>
                                            <div>
                                                <CSwitch
                                                    color='primary'
                                                    size='lg'
                                                    className={'mt-2'}
                                                    variant='3d'
                                                    value={0}
                                                    onClick={(e) => { this.handleChange('active', e) }}
                                                />
                                            </div>
                                        </CTooltip>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCardBody>
                                <Projects {... this.props} filteredProjects={() => { return this.filteredProjects() }} />
                            </CCardBody >
                            <CCardFooter>{!helps.isEmpty(projects) && <h4>Tổng: <b>{projects.length}</b></h4>}</CCardFooter>
                        </CCard >
                    </CCol >
                </CRow >
            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { loading, projects } = state.projectReducer;
    const { provinces } = state.provinceReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    const { notes } = state.noteReducer;
    const { positions } = state.positionReducer;
    const { employees } = state.employeeReducer;
    return { loading, projects, provinces, user, users, notes, positions, employees };
}

const actionCreators = {
    getAll: projectActions.getAll,
    setParams: projectActions.setParams,
    getProvinces: provinceActions.getAll,
    getUsers: userActions.getAll,
    getNotes: noteActions.getAll,
    getPositions: positionActions.getAll,
    getEmployees: employeeActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ProjectsContainer);
export { connectedContainer as ProjectsContainer };