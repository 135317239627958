import { notificationConstants } from "src/_constants";

export function notificationReducer(state = {}, actions) {
    switch (actions.type) {
        // GET LIST    
        case notificationConstants.GET_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case notificationConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                notifications: actions.notifications,
                loading: false,
            }
        case notificationConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Add
        case notificationConstants.ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case notificationConstants.ADD_SUCCESS:
            state.notifications.push(actions.notification);
            return {
                ...state,
                loading: false,
            }
        case notificationConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Edit
        case notificationConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case notificationConstants.EDIT_SUCCESS:
            var _notifications = state.notifications;
            var foundIndex = _notifications.findIndex(notification => notification.id === actions.notification.id);
            _notifications[foundIndex] = actions.notification;
            console.log(foundIndex);
            return {
                ...state,
                notifications: _notifications,
                loading: false,
            }
        case notificationConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        // Delete 
        case notificationConstants.DELETE_REQUEST:
            return {
                ...state,
                notifications: state.notifications.map(notification =>
                    notification.id === actions.notification.id
                        ? { ...notification, deleting: true }
                        : notification
                )
            };
        case notificationConstants.DELETE_SUCCESS:
            return {
                notifications: state.notifications.filter(notification => notification.id !== actions.notification.id)
            };
        case notificationConstants.DELETE_FAILURE:
            return {
                ...state,
                notifications: state.notifications.map(notification => {
                    if (notification.id === actions.notification.id) {
                        const { deleting, ...notificationCopy } = notification;
                        return { ...notificationCopy, deleteError: actions.error };
                    }
                    return notification;
                })
            };

        // Read 
        case notificationConstants.READ_REQUEST:
            return {
                ...state,
                notifications: state.notifications.map(notification =>
                    notification.id === actions.notification.id
                        ? { ...notification, deleting: true }
                        : notification
                )
            };
        case notificationConstants.READ_SUCCESS:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.id !== actions.notification.id)
            };
        case notificationConstants.READ_FAILURE:
            return {
                ...state,
                notifications: state.notifications.map(notification => {
                    if (notification.id === actions.notification.id) {
                        const { deleting, ...notificationCopy } = notification;
                        return { ...notificationCopy, deleteError: actions.error };
                    }
                    return notification;
                })
            };


        default: return state;
    }
}