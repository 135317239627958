import { helps } from 'src/_helpers';
import { db } from 'src/dbConfigs';

export const asset_itemService = {
    getAll,
    getAllforComponent,
    add,
    getById,
    update,
    updateField,
    _delete,
};

async function getAll(params = {}) {
    var ref = db.collection('asset_items');
    var query = ref.orderBy('create_date', 'desc');
    var asset_items = [];
    var snapshot = {};

    if (!helps.isEmpty(params) && !helps.isEmpty(params.assets_ids)) {
        const batches = [];
        while (params.assets_ids.length) {
            // firestore limits batches to 10
            const batch = params.assets_ids.splice(0, 10);
            // add the batch request to to a queue
            batches.push(
                ref
                    .where(
                        "asset_id",
                        'in',
                        [...batch]
                    )
                    .orderBy("create_date", "desc")
                    .get()
                    .then(results => results.docs.map(result => ({ ...result.data() })))
            )
        }
        asset_items = Promise.all(batches)
            .then(content => content.flat());
    } else {
        snapshot = await query.get();
        asset_items = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
    }

    if (asset_items && asset_items.length > 0) {
        Promise.all(
            asset_items.map(async (asset_item, index) => {
                if (asset_item.create_date) {
                    var _date = asset_item['create_date'];
                    asset_item['create_date'] = _date.toDate();
                }
                /*
                if (asset_item.item_id) {
                    let item = await itemService.getById(asset_item.item_id);
                    asset_item['item'] = item;
                }*/
            })
        );
    }

    return asset_items;
}

async function getAllforComponent(params = {}) {
    var ref = db.collection('asset_items');
    var query = ref.orderBy('create_date', 'desc');
    var asset_items = [];

    if (!helps.isEmpty(params)) {
        console.log(params.assets_ids.length);
        if (!helps.isEmpty(params.assets_ids)) {
            const batches = [];
            while (params.assets_ids.length) {
                // firestore limits batches to 10
                const batch = params.assets_ids.splice(0, 10);
                // add the batch request to to a queue
                batches.push(
                    ref
                        .where(
                            "asset_id",
                            'in',
                            [...batch]
                        )
                        .orderBy("create_date", "desc")
                        .get()
                        .then(results => results.docs.map(result => ({ ...result.data() })))
                )
            }
            asset_items = Promise.all(batches)
                .then(content => content.flat());
        } else {
            return asset_items;
        }
    } else {
        return asset_items;
    }

    if (asset_items && asset_items.length > 0) {
        Promise.all(
            asset_items.map(async (asset_item, index) => {
                if (asset_item.create_date) {
                    var _date = asset_item['create_date'];
                    asset_item['create_date'] = _date.toDate();
                }
            })
        );
    }
    console.log(asset_items)
    return asset_items;
}

async function getById(id) {
    try {
        const doc = await db.collection("asset_items").doc(id)
            .get();
        if (doc.exists) {
            return doc.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        console.log("Error getting document:", error);
    }
}

async function add(item) {
    try {
        const docRef = await db.collection("asset_items").add(item);
        item.id = docRef.id;
        update(item);
        console.log("Firebase: asset item has been added successfully!");
        return item;
    } catch (error) {
        console.error("Error adding asset item: ", error);
    }
}

async function update(item) {
    //console.log(asset);
    let dbRef = db.collection("asset_items").doc(item['id']);

    try {
        //console.log(asset);
        await dbRef.update(item).then(() => {
            console.log("Firebase: asset item has been updated successfully!");
        });

        return item;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function updateField(item, field) {
    //console.log(asset);
    let dbRef = db.collection("asset_items").doc(item['id']);

    try {
        console.log(field, item[field]);
        await dbRef.update({
            [field]: item[field]
        }).then(() => {
            console.log("Firebase: asset item field has been updated successfully!");
        });

        return item;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function _delete(item) {
    try {
        await db.collection("asset_items").doc(item.id).delete().then((item) => {
            return item;
        });
    } catch (error) {
        console.error("Error: ", error);
    }
}
