import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { helps } from 'src/_helpers';
import { positionActions } from '../../_actions';
import PositionsForm from 'src/views/positions/PositionsForm';


class PositionsFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            position: {},
            project: {},
            form_header: "Thêm Mới Vị Trí",
            action_status: 'add',
        }
    }

    componentDidMount() {
        this.setState({ project: this.props.project });
        this.getpositions().then(() => {
            this.getposition();
        });

    }

    getposition() {
        let position_id = this.props.match.params.id;
        const positions = this.props.positions;
        if (position_id) {
            let position = positions.filter((_position) => {
                return _position.id === position_id;
            });
            this.setState({ form_header: "Chỉnh Sửa Vị Trí: " + position.name, action_status: 'edit', position: position[0] });
        }
    }

    async getpositions() {
        const positions = this.props.positions;
        if (!positions) await this.props.getAll();
    }

    handleSubmit = (position) => {
        let position_id = this.props.match.params.id;
        if (helps.isEmpty(position_id)) {
            this.props.add(position);
        } else {
            this.props.update(position);
        }
        this.props.history.push('/positions');
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        let position_id = this.props.match.params.id;
        const { position } = this.state;
        if (!helps.isEmpty(position_id) && helps.isEmpty(position)) return (<Loading></Loading>);
        return (
            <ThePermission {... this.props} component="positions" action="edit">
                <PositionsForm
                    {... this.props}
                    position={this.state.position}
                    project={this.state.project}
                    form_header={this.state.form_header}
                    action_status={this.state.action_status}
                    handleSubmit={this.handleSubmit}
                />
            </ThePermission>
        );
    }
}
function mapStateToProps(state) {
    const { loading, positions } = state.positionReducer;
    return { loading, positions };
}

const actionCreators = {
    getAll: positionActions.getAll,
    add: positionActions.add,
    updat: positionActions.update,
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(PositionsFormContainer);
export { connectedFormContainer as PositionsFormContainer };