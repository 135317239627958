import { documentConstants } from '../_constants';
import { documentService } from '../_services';
import { alertActions } from '.';

export const documentActions = {
    getAll,
    upload,
    _delete,
};

function getAll(path = '') {
    return dispatch => {
        dispatch(request(path));
        return documentService.getAll(path)
            .then(
                res => {
                    dispatch(success(res));
                    return res;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(path) { return { type: documentConstants.GET_LIST_REQUEST, path } }
    function success(res) { return { type: documentConstants.GET_LIST_SUCCESS, res } }
    function failure(error) { return { type: documentConstants.GET_LIST_FAILURE, error } }
}

function upload(documents, _cur_dir) {
    return dispatch => {
        dispatch(request(documents));
        documentService.uploaddocument(documents, _cur_dir)
            .then(
                documents => dispatch(success(documents)),
                error => dispatch(failure(documents, error.toString()))
            );
    };

    function request(documents) { return { type: documentConstants.UPLOAD_REQUEST, documents } }
    function success(documents) { return { type: documentConstants.UPLOAD_SUCCESS, documents } }
    function failure(documents, error) { return { type: documentConstants.UPLOAD_FAILURE, documents, error } }
}


function _delete(document) {
    return dispatch => {
        dispatch(request(document));

        documentService.deletedocument(document)
            .then(
                document => dispatch(success(document)),
                error => dispatch(failure(document, error.toString()))
            );
    };

    function request(document) { return { type: documentConstants.DELETE_REQUEST, document } }
    function success(document) { return { type: documentConstants.DELETE_SUCCESS, document } }
    function failure(document, error) { return { type: documentConstants.DELETE_FAILURE, document, error } }
}


