import configs from '../appConfigs';
import moment from 'moment';

export const helps = {
    formatDateTime_from_Timestamp,
    formatDate_from_Timestamp,
    formatDate_for_input,
    formatTime_from_Timestamp,
    hashPassword,
    comparePasswords,
    checkApproval,
    checkPermission,
    isEmpty,
    isURL,
    addOrUpdate,
    removeItemOnce,
    removeItemAll,
    correctLastSlash,
    convertToSlug,
    updateQueryStringParameter,
    formatDate,
    getFirstLetters,
    toNonAccentVietnamese,
    get_Employee_ID,
    generateUsername,
    generatePassword,
    getUserAlias,
    getItemFromArr,
    getExt,
    formatBytes,
    calShiftDuration,
    convertTimetoMinutes,
    calDiffTimeToMinutes,
    setTimetoDate,
    secondsToDhm,
    secondsToYmd,
    formatCurrency,
    fixDigit,
    unigByKeepLast,
    getUniqueListBy,
    addMonths,
    DocSo3ChuSo,
    DocTienBangChu,
}

function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

function unigByKeepLast(data, key) {
    return [
        ...new Map(
            data.map(x => [key(x), x])
        ).values()
    ];
}

function fixDigit(val) {
    return val.toString().length === 1 ? "0" + val : val;
}

function addMonths(input, months) {
    const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();
    const date = new Date(input)
    date.setDate(1)
    date.setMonth(date.getMonth() + months)
    date.setDate(Math.min(input.getDate(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1)))
    return date
}

function formatDateTime_from_Timestamp(timeStamp) {
    var datetime = moment(timeStamp).format(configs.datetime_format);
    return datetime;
}

function formatDate_from_Timestamp(timeStamp) {
    let date = new moment(timeStamp).format(configs.dateFormat);
    return date;
}

function formatDate_for_input(timeStamp, _format = "MM/DD/YYYY") {
    let date = new moment(timeStamp).format(_format);
    return date;
}

function formatTime_from_Timestamp(timeStamp) {
    let date = new moment(timeStamp).format(configs.timeFormat);
    return date;
}

function secondsToDhm(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    //var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + " ngày, " : "";
    var hDisplay = h > 0 ? h + " giờ, " : "";
    var mDisplay = m > 0 ? m + " phút " : "";
    return dDisplay + hDisplay + mDisplay;
}

function secondsToYmd(fromdate, todate) {
    //console.log(fromdate, todate)
    if (todate) todate = new Date(todate);
    else todate = new Date();

    var yearNow = todate.getYear();
    var monthNow = todate.getMonth();
    var dateNow = todate.getDate();

    var dob = new Date(fromdate);

    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearString = "";
    var monthString = "";
    var dayString = "";


    let yearAge = yearNow - yearDob;
    var monthAge = 0;
    var dateAge = 0;
    if (monthNow >= monthDob)
        monthAge = monthNow - monthDob;
    else {
        yearAge--;
        monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob)
        dateAge = dateNow - dateDob;
    else {
        monthAge--;
        dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
            monthAge = 11;
            yearAge--;
        }
    }

    age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
    };

    if (age.years > 1) yearString = " năm";
    else yearString = " năm";
    if (age.months > 1) monthString = " tháng";
    else monthString = " tháng";
    if (age.days > 1) dayString = " ngày";
    else dayString = " ngày";


    if ((age.years > 0) && (age.months > 0) && (age.days > 0))
        ageString = age.years + yearString + ", " + age.months + monthString + ", " + age.days + dayString;
    else if ((age.years === 0) && (age.months === 0) && (age.days > 0))
        ageString = age.days + dayString;
    else if ((age.years > 0) && (age.months === 0) && (age.days === 0))
        ageString = age.years + yearString;
    else if ((age.years > 0) && (age.months > 0) && (age.days === 0))
        ageString = age.years + yearString + ", " + age.months + monthString;
    else if ((age.years === 0) && (age.months > 0) && (age.days > 0))
        ageString = age.months + monthString + ", " + age.days + dayString;
    else if ((age.years > 0) && (age.months === 0) && (age.days > 0))
        ageString = age.years + yearString + ", " + age.days + dayString;
    else if ((age.years === 0) && (age.months > 0) && (age.days === 0))
        ageString = age.months + monthString;
    else ageString = "Oops!";

    return ageString;
}

function hashPassword(input_password) {
    return input_password;
    //return sha256(input_password);
}

async function comparePasswords(compared_password, hashed_password) {
    let _compared_password = compared_password;
    if (_compared_password === hashed_password) return true;
    else return false;
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    console.log(d);
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function formatCurrency(_number = 0) {
    return parseInt(_number).toLocaleString('vn-VN', {
        style: 'currency',
        currency: 'VND',
    });
}

function checkApproval(approvals, user, key) {
    let permission = false;
    let approval = getItemFromArr(approvals, key, 'key');
    if (!isEmpty(approval) && !isEmpty(approval.approvers)) {
        let approver = getItemFromArr(approval.approvers, user.id, 'value');
        if (!isEmpty(approver)) permission = true;
    }
    return permission;
}

function checkPermission(roles, user_role, _object, _action) {

    let _getRole = getItemFromArr(roles, user_role);

    if (helps.isEmpty(_getRole)) return false;

    let _getObject = _getRole[[_object]];
    if (!_getObject || helps.isEmpty(_getObject)) return false;

    if (_getObject.includes(_action)) return true;
    else return false;
}

function setTimetoDate(_date, _time) {
    var date = new Date(_date)
    if (helps.isEmpty(_time)) return;
    let _arr = _time.split(":");
    let _h = parseInt(_arr[0]);
    let _m = parseInt(_arr[1]);
    return new Date(date.setHours(_h, _m, 0));
}

function convertTimetoMinutes(_time) {
    if (helps.isEmpty(_time)) return 0;
    let _arr = _time.split(":");
    let _h = parseInt(_arr[0]);
    let _m = parseInt(_arr[1]);
    let _to_m = _h * 60 + _m;
    return _to_m;
}

function calDiffTimeToMinutes(_start, _end) {
    let start_to_m = convertTimetoMinutes(_start);
    let end_to_m = convertTimetoMinutes(_end);
    let duration_m = start_to_m - end_to_m;
    return duration_m;
}

function calShiftDuration(_start, _end) {
    let start_to_m = convertTimetoMinutes(_start);
    let end_to_m = convertTimetoMinutes(_end);
    let duration_m = end_to_m - start_to_m;
    let duration_h = duration_m / 60;
    return duration_h;
}

// Speed up calls to hasOwnProperty
var hasOwnProperty = Object.prototype.hasOwnProperty;

function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}
function get_Employee_ID(fullname, id_number) {
    const prefix = 'SS';
    const space = "-";
    var name = '';
    var _id_number = '';
    var ID = prefix + space;

    if (isEmpty(fullname)) {
        return '';
    } else {
        name = getFirstLetters(fullname);
        name = name.toUpperCase();
        name = toNonAccentVietnamese(name);

        ID = ID + name + space;
        if (!isEmpty(id_number)) {
            _id_number = id_number.substr(id_number.length - 4);
            ID = ID + _id_number;
        }
    }

    return ID;
}
function generateUsername(fullname, phone) {
    var username = '';
    if (isEmpty(phone)) {
        if (!isEmpty(fullname)) {
            username = fullname.toLowerCase();
            username = toNonAccentVietnamese(username);
            username = username.replace(/\s/g, '');
        }
    } else {
        username = phone.replace(/\s/g, '');
    }
    return username;
}
function generatePassword() {
    return Math.floor(100000 + Math.random() * 900000);
}
function toNonAccentVietnamese(str) {
    str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng 
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
}
function getFirstLetters(str) {
    const firstLetters = str
        .split(' ')
        .map(word => word.charAt(0))
        .join('');

    return firstLetters;
}
function isURL(str) {
    //console.log(str);
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
}

function getUserAlias(users, id) {
    if (isEmpty(users) || isEmpty(id)) return '';
    var user = getItemFromArr(users, id);
    return user.fullname + '-' + user.username;
}

function getItemFromArr(arr, id, field = 'id') {
    var _result = {};
    if (isEmpty(arr) || isEmpty(id)) return _result;
    var item = arr.filter(_item => _item[field] === id);
    if (item && item.length > 0) {
        _result = item[0];
    }
    return _result;
}

function addOrUpdate(array, item) {
    const i = array.findIndex(_item => _item.id === item.id);
    if (i > -1) array[i] = item; // (2)
    else array.push(item);
}

function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arr;
}

function correctLastSlash(url) {
    if (!isEmpty(url)) {
        if (url.lastIndexOf('/') !== (url.length - 1)) return url + '/';
        else return url;
    } else {
        return '';
    }
}

function convertToSlug(Text) {
    if (Text) {
        return Text
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
            ;
    }
    return '';
}

function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}

function getExt(filepath = '') {
    if (isEmpty(filepath)) return '';
    else return filepath.split("?")[0].split("#")[0].split('.').pop();
}

function formatBytes(a, b = 2) { if (!+a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]}` }

var ChuSo = new Array(" không ", " một ", " hai ", " ba ", " bốn ", " năm ", " sáu ", " bảy ", " tám ", " chín ");
var Tien = new Array("", " nghìn", " triệu", " tỷ", " nghìn tỷ", " triệu tỷ");

//1. Hàm đọc số có ba chữ số;
function DocSo3ChuSo(baso) {
    var tram;
    var chuc;
    var donvi;
    var KetQua = "";
    tram = parseInt(baso / 100);
    chuc = parseInt((baso % 100) / 10);
    donvi = baso % 10;
    if (tram == 0 && chuc == 0 && donvi == 0) return "";
    if (tram != 0) {
        KetQua += ChuSo[tram] + " trăm ";
        if ((chuc == 0) && (donvi != 0)) KetQua += " linh ";
    }
    if ((chuc != 0) && (chuc != 1)) {
        KetQua += ChuSo[chuc] + " mươi";
        if ((chuc == 0) && (donvi != 0)) KetQua = KetQua + " linh ";
    }
    if (chuc == 1) KetQua += " mười ";
    switch (donvi) {
        case 1:
            if ((chuc != 0) && (chuc != 1)) {
                KetQua += " mốt ";
            }
            else {
                KetQua += ChuSo[donvi];
            }
            break;
        case 5:
            if (chuc == 0) {
                KetQua += ChuSo[donvi];
            }
            else {
                KetQua += " lăm ";
            }
            break;
        default:
            if (donvi != 0) {
                KetQua += ChuSo[donvi];
            }
            break;
    }
    return KetQua;
}

//2. Hàm đọc số thành chữ (Sử dụng hàm đọc số có ba chữ số)

function DocTienBangChu(SoTien) {
    var lan = 0;
    var i = 0;
    var so = 0;
    var KetQua = "";
    var tmp = "";
    var ViTri = new Array();
    if (SoTien < 0) return "Số tiền âm !";
    if (SoTien == 0) return "Không đồng !";
    if (SoTien > 0) {
        so = SoTien;
    }
    else {
        so = -SoTien;
    }
    if (SoTien > 8999999999999999) {
        //SoTien = 0;
        return "Số quá lớn!";
    }
    ViTri[5] = Math.floor(so / 1000000000000000);
    if (isNaN(ViTri[5]))
        ViTri[5] = "0";
    so = so - parseFloat(ViTri[5].toString()) * 1000000000000000;
    ViTri[4] = Math.floor(so / 1000000000000);
    if (isNaN(ViTri[4]))
        ViTri[4] = "0";
    so = so - parseFloat(ViTri[4].toString()) * 1000000000000;
    ViTri[3] = Math.floor(so / 1000000000);
    if (isNaN(ViTri[3]))
        ViTri[3] = "0";
    so = so - parseFloat(ViTri[3].toString()) * 1000000000;
    ViTri[2] = parseInt(so / 1000000);
    if (isNaN(ViTri[2]))
        ViTri[2] = "0";
    ViTri[1] = parseInt((so % 1000000) / 1000);
    if (isNaN(ViTri[1]))
        ViTri[1] = "0";
    ViTri[0] = parseInt(so % 1000);
    if (isNaN(ViTri[0]))
        ViTri[0] = "0";
    if (ViTri[5] > 0) {
        lan = 5;
    }
    else if (ViTri[4] > 0) {
        lan = 4;
    }
    else if (ViTri[3] > 0) {
        lan = 3;
    }
    else if (ViTri[2] > 0) {
        lan = 2;
    }
    else if (ViTri[1] > 0) {
        lan = 1;
    }
    else {
        lan = 0;
    }
    for (i = lan; i >= 0; i--) {
        tmp = DocSo3ChuSo(ViTri[i]);
        KetQua += tmp;
        if (ViTri[i] > 0) KetQua += Tien[i];
        if ((i > 0) && (tmp.length > 0)) KetQua += ',';//&& (!string.IsNullOrEmpty(tmp))
    }
    if (KetQua.substring(KetQua.length - 1) == ',') {
        KetQua = KetQua.substring(0, KetQua.length - 1);
    }
    KetQua = KetQua.substring(1, 2).toUpperCase() + KetQua.substring(2) + " đồng";
    KetQua.replaceAll("  ", " ");
    return KetQua;//.substring(0, 1);//.toUpperCase();// + KetQua.substring(1);
}
