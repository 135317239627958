import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CFormGroup,
    CInput,
    CTextarea,
    CSelect,
    CInputRadio,
    CModal, CModalBody, CModalHeader, CModalFooter, CInputGroup, CInputGroupAppend, CInputGroupText, CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import Hint from 'src/_components/Hint';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import locale from 'date-fns/locale/vi';

class BookInForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            id: '',
            book_date: new Date(),
            book_id: '',
            book_account: '',
            book_section: '',
            amount: 0,
            description: '',
            book_object: 'employees',
            book_object_ids: [],
            book_object_content: '',
            book_bank_info: '',
            book_period_from: '',
            book_period_to: '',
            added_time: new Date(),
            added_by: this.props.user.id,
            type: 'in',
            note: '',
            index: 0,
            count: 0,
            processed: true,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleGetBankInfo = this.handleGetBankInfo.bind(this);
        this.handleSelectDateRange = this.handleSelectDateRange.bind(this);

    }

    componentDidMount() {
        const { book, taxonomies, user } = this.props;
        //console.log(book);
        if (!helps.isEmpty(book)) {
            this.setState({
                id: book.id,
                book_date: book.book_date ? book.book_date : this.state.book_date,
                book_id: book.book_id,
                book_account: book.book_account,
                book_section: book.book_section,
                amount: parseInt(book.amount),
                description: book.description,
                note: book.note ? book.note : this.state.note,
                book_object: book.book_object ? book.book_object : this.state.book_object,
                book_object_ids: book.book_object_ids,
                book_object_content: book.book_object_content,
                book_bank_info: book.book_bank_info,
                book_period_from: book.book_period_from,
                book_period_to: book.book_period_to,
                added_time: book.added_time ? book.added_time : this.state.added_time,
                added_by: book.added_by ? book.added_by : this.state.added_by,
                index: book.index ? book.index : this.state.index,
                count: book.count ? book.count : this.state.count,
                processed: book.processed ? book.processed : this.state.processed,
            });
        } else {
            this.setState({
                added_by: user.id,
            });
            this.getBook_id();
        }

        let default_book_account = taxonomies.filter((taxonomy) => taxonomy.category_id === 'account' && taxonomy.is_default);
        default_book_account = default_book_account[0];
        if (helps.isEmpty(this.state.book_account)) {
            this.setState({ book_account: default_book_account.id });
        }

        if (helps.isEmpty(this.state.book_section)) {
            let default_book_section = taxonomies.filter((taxonomy) => taxonomy.category_id === 'section' && taxonomy.is_default);
            default_book_section = default_book_section[0];
            if (!helps.isEmpty(default_book_section)) {
                this.setState({ book_section: default_book_section.id });
            } else {
                default_book_section = taxonomies.filter((taxonomy) => taxonomy.category_id === 'section');
                default_book_section = default_book_section[0];
                this.setState({ book_section: default_book_section.id });
            }

        }
    }

    async getBook_id() {
        let book_id = await this.generateBook_id();
        this.setState({ book_id: book_id })
    }

    async generateBook_id() {
        let book_date = this.state.book_date;
        //console.log(book_date);
        let year = book_date.getFullYear();
        let month = (book_date.getMonth() + 1).toString().padStart(2, "0");
        let count = await this.props.getCountBooks(book_date, 'in') + 1;
        this.setState({ index: count });
        let book_id = 'SAT-' + month + year + '-' + count;
        //console.log(book_id);
        return book_id;
    }

    async handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            const { books } = this.props;
            let _books = books.filter(book => !book.approval_required || (book.approval_required && book.approved));
            var maxCount = _books.map(x => +x['count']).reduce((a, b) => a > b ? a : b, 0);
            let _books_in = books.filter(book => book.type === 'in');
            var maxIndex = _books_in.map(x => +x['index']).reduce((a, b) => a > b ? a : b, 0);

            let book = {
                id: this.state.id,
                book_date: this.state.book_date,
                book_id: this.state.book_id,
                book_account: this.state.book_account,
                book_section: this.state.book_section,
                description: this.state.description,
                amount: parseInt(this.state.amount),
                book_object: this.state.book_object,
                book_object_ids: this.state.book_object_ids,
                book_object_content: this.state.book_object_content,
                book_bank_info: this.state.book_bank_info,
                book_period_from: this.state.book_period_from,
                book_period_to: this.state.book_period_to,
                approved: false,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
                type: this.state.type,
                note: this.state.note,
                processed: this.state.processed,
            }
            if (helps.isEmpty(book.id)) {
                book['count'] = maxCount + 1;
                book['index'] = maxIndex + 1;
            }
            //console.log(project);
            this.props.handleSubmit(book);
            this.props.toggleModal('in_form');
        } else {
            return false;
        }
    }

    cancelForm() {
        let result = window.confirm("Hủy phiếu thu này?");
        if (result) {
            this.props.toggleModal('in_form');
        }
    }

    handleChange(field, e) {
        this.setState({
            [field]: e.target.value
        });
    }

    handleChangeDate(_date, field) {
        this.setState({ [field]: _date });
        //console.log(this.state.book_date)
        if (field === 'book_date') {
            this.getBook_id();
        }

    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [field]: selectedOptions });
    }

    handleSelectDateRange(date) {
        console.log(date.selection); // native Date object
        this.setState({
            book_period_from: date.selection.startDate,
            book_period_to: date.selection.endDate,
        })
    }

    handleGetBankInfo(selectedOptions) {
        const { employees } = this.props;
        let book_object = this.state.book_object;
        let book_object_ids = selectedOptions;
        let book_bank_info = '';
        if (!helps.isEmpty(book_object_ids) && book_object_ids.length > 0) {
            switch (book_object) {
                case 'employees':
                    book_object_ids.map((book_object_id) => {
                        let employee = helps.getItemFromArr(employees, book_object_id.value, 'employee_id');
                        book_bank_info += employee.fullname + '\n';
                        book_bank_info += 'TK: ' + employee.bank_account_number + '\n';
                        book_bank_info += 'NH: ' + employee.bank_name + '\n';
                        book_bank_info += '------------------------------------' + '\n';
                    })
                    break;
            }
        }
        console.log(book_bank_info)
        this.setState({ book_bank_info: book_bank_info });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (!this.state.book_date) {
            formIsValid = false;
            errors["book_date"] = "Chọn ngày Chứng từ.";
        }

        if (!this.state.book_account) {
            formIsValid = false;
            errors["book_account"] = "Chọn Tài khoản.";
        }

        if (!this.state.description) {
            formIsValid = false;
            errors["description"] = "Nhập nội dung chứng từ.";
        }

        if (this.state.book_object !== 'other' && helps.isEmpty(this.state.book_object_ids)) {
            formIsValid = false;
            errors["book_object_ids"] = "Chọn đối tượng.";
        }

        if (this.state.book_object === 'other' && helps.isEmpty(this.state.book_object_content)) {
            formIsValid = false;
            errors["book_object_content"] = "Nhập tên đối tượng.";
        }

        if (this.state.book_period_from) {
            if (this.state.book_period_to && this.state.book_period_from > this.state.book_period_to) {
                formIsValid = false;
                errors["book_period_to"] = "Cuối kỳ là ngày sau hoặc cùng ngày đầu kỳ.";
            }
            if (!this.state.book_period_to) {
                formIsValid = false;
                errors["book_period_to"] = "Chọn ngày cuối kỳ.";
            }
        }

        if (this.state.book_period_to) {
            if (this.state.book_period_from && this.state.book_period_from > this.state.book_period_to) {
                formIsValid = false;
                errors["book_period_from"] = "Đầu kỳ là ngày trước hoặc cùng ngày cuối kỳ.";
            }
            if (!this.state.book_period_from) {
                formIsValid = false;
                errors["book_period_from"] = "Chọn ngày đầu kỳ.";
            }
        }

        this.setState({ errors: errors });

        return formIsValid;
    }

    renderStaticRangeLabel(staticRange) {
        console.log(staticRange);
    }

    render() {
        const { employees, customers, housings, vendors, projects, taxonomies } = this.props;
        let employees_options = [];
        if (!helps.isEmpty(employees)) {
            employees.map((employee) => {
                employees_options.push({ value: employee.employee_id, label: employee.fullname + ' (' + employee.employee_id + ')' });
            })
        }

        let customers_options = [];
        if (!helps.isEmpty(customers)) {
            customers.map((customer) => {
                customers_options.push({ value: customer.id, label: customer.name });
            })
        }

        let housings_options = [];
        if (!helps.isEmpty(housings)) {
            housings.map((housing) => {
                housings_options.push({ value: housing.id, label: housing.name + ' (' + housing.address + ')' });
            })
        }

        let vendors_options = [];
        if (!helps.isEmpty(housings)) {
            vendors.map((vendor) => {
                vendors_options.push({ value: vendor.id, label: vendor.name });
            })
        }

        let projects_options = [];
        if (!helps.isEmpty(projects)) {
            projects.map((project) => {
                projects_options.push({ value: project.id, label: project.name });
            })
        }

        const selectionRange = {
            startDate: this.state.book_period_from,
            endDate: this.state.book_period_to,
            key: 'selection',
        }

        return (
            <CForm onSubmit={(e) => this.handleSubmit(e)}>
                <CModal
                    show={this.props.modals.includes('in_form')}
                    size='xl'
                    onClose={() => { this.props.toggleModal('in_form') }}
                    className='text-left'
                >
                    <CModalHeader closeButton>
                        <h3><b>Phiếu Thu</b></h3>
                    </CModalHeader>
                    <CModalBody>
                        <CRow className={'book_form'}>
                            <CCol xl={12}>
                                <CRow>
                                    <CCol md={6}>
                                        <CRow className="mb-3">
                                            <CCol>
                                                <CLabel htmlFor="input-book_date">Ngày Chứng Từ:<Hint content='Ngày thu' /></CLabel>
                                                <div>
                                                    <DateTimePicker
                                                        value={this.state.book_date}
                                                        format={'dd/MM/yyyy'}
                                                        onChange={(value) => { this.handleChangeDate(value, 'book_date') }}
                                                        onBlur={() => { this.getBook_id() }}
                                                    />
                                                </div>
                                                <small className="text-danger">{this.state.errors['book_date']}</small>
                                            </CCol>
                                            <CCol>
                                                <CLabel htmlFor="input-book_id">Số Chứng Từ:<Hint content="Số Chứng Từ được tạo tự động" /></CLabel>
                                                <CInput
                                                    key={this.state.book_date.toDateString()}
                                                    type="text"
                                                    id='input-book_id'
                                                    value={this.state.book_id}
                                                    required
                                                    readOnly
                                                    className="form-control font-weight-bold"
                                                    title='Số Chứng Từ được tạo tự động'
                                                />
                                                <small className="text-danger">{this.state.errors['book_id']}</small>
                                            </CCol>
                                        </CRow>
                                        <CRow className="mb-3">
                                            <CCol>
                                                <CLabel htmlFor="input-book_account">Tài Khoản:</CLabel>
                                                <CSelect
                                                    id='input-book_account'
                                                    name='book_account'
                                                    value={this.state.book_account}
                                                    onChange={(e) => { this.handleChange('book_account', e) }}
                                                    required
                                                >
                                                    {!helps.isEmpty(taxonomies) && taxonomies.map((taxonomy) => {
                                                        if (taxonomy.category_id === 'account') {
                                                            return (
                                                                <option key={'book_account_' + taxonomy.id} value={taxonomy.id}>{taxonomy.code} {taxonomy.name}</option>
                                                            )
                                                        }
                                                    })}
                                                </CSelect>
                                                <small className="text-danger">{this.state.errors['book_account']}</small>
                                            </CCol>
                                            <CCol>
                                                <CLabel htmlFor="input-book_section">Khoản:</CLabel>
                                                <CSelect
                                                    id='input-book_section'
                                                    name='book_section'
                                                    value={this.state.book_section}
                                                    onChange={(e) => { this.handleChange('book_section', e) }}
                                                >
                                                    <option value=''> - chọn Khoản - </option>
                                                    {!helps.isEmpty(taxonomies) && taxonomies.map((taxonomy) => {
                                                        if (taxonomy.category_id === 'section') {
                                                            return (
                                                                <option key={'book_section_' + taxonomy.id} value={taxonomy.id}>{taxonomy.code} {taxonomy.name}</option>
                                                            )
                                                        }
                                                    })}
                                                </CSelect>
                                                <small className="text-danger">{this.state.errors['book_section']}</small>
                                            </CCol>
                                        </CRow>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-name">Số Tiền:</CLabel>
                                            <CInputGroup>
                                                <CurrencyInput
                                                    name="amount"
                                                    id="input-amount"
                                                    required
                                                    className='form-control font-weight-bold lead text-dark'
                                                    style={{ fontSize: '150%' }}
                                                    value={this.state.amount}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => { this.setState({ [name]: value }) }}
                                                />
                                                <CInputGroupAppend>
                                                    <CInputGroupText className={'bg-danger text-white'}>
                                                        ₫
                                                    </CInputGroupText>
                                                </CInputGroupAppend>
                                            </CInputGroup>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-description">Nội Dung:</CLabel>
                                            <CTextarea
                                                type="text"
                                                name="address"
                                                id="input-description"
                                                rows={5}
                                                value={this.state.description}
                                                onChange={(e) => { this.handleChange('description', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['description']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-note">Ghi Chú:</CLabel>
                                            <CTextarea
                                                type="text"
                                                name="note"
                                                id="input-note"
                                                value={this.state.note}
                                                onChange={(e) => { this.handleChange('note', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['note']}</small>
                                        </div>
                                    </CCol>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <div>
                                                <CLabel>Đối tượng giao dịch:</CLabel>
                                            </div>
                                            {configs.acc_objects && Object.keys(configs.acc_objects).map((_key) => {
                                                return (
                                                    <CFormGroup variant="custom-radio" key={_key} className={'mb-2'} inline>
                                                        <CInputRadio
                                                            custom
                                                            id={"book_object_" + _key}
                                                            name="book_object"
                                                            value={_key}
                                                            checked={this.state.book_object === _key}
                                                            onChange={(e) => { this.handleChange('book_object', e) }}
                                                        />
                                                        <CLabel variant="custom-checkbox" htmlFor={"book_object_" + _key} className="cursor-pointer">
                                                            {configs.acc_objects[_key]}
                                                        </CLabel>
                                                    </CFormGroup>
                                                )
                                            }
                                            )}

                                        </div>
                                        {this.state.book_object === 'employees' &&
                                            <div className="mb-3">
                                                <CLabel htmlFor="input-book_object_ids">Nhân Sự:</CLabel>
                                                <Select
                                                    id="input-book_object_ids"
                                                    options={employees_options}
                                                    value={this.state.book_object_ids}
                                                    isMulti
                                                    placeholder='chọn Nhân viên'
                                                    onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids'); this.handleGetBankInfo(choice); }}
                                                />
                                                <small className="text-danger">{this.state.errors['book_object_ids']}</small>
                                            </div>
                                        }
                                        {this.state.book_object === 'customers' &&
                                            <div className="mb-3">
                                                <CLabel htmlFor="input-customer_id">Khách Hàng:</CLabel>
                                                <Select
                                                    id="input-customer_id"
                                                    options={customers_options}
                                                    value={this.state.book_object_ids}
                                                    isMulti
                                                    placeholder='chọn Khách hàng'
                                                    onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                />
                                                <small className="text-danger">{this.state.errors['book_object_ids']}</small>
                                            </div>
                                        }

                                        {this.state.book_object === 'housings' &&
                                            <div className="mb-3">
                                                <CLabel htmlFor="input-customer_id">Nhà Đội:</CLabel>
                                                <Select
                                                    id="input-customer_id"
                                                    options={housings_options}
                                                    value={this.state.book_object_ids}
                                                    isMulti
                                                    placeholder='chọn Nhà đội'
                                                    onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                />
                                                <small className="text-danger">{this.state.errors['book_object_ids']}</small>
                                            </div>
                                        }

                                        {this.state.book_object === 'projects' &&
                                            <div className="mb-3">
                                                <CLabel htmlFor="input-customer_id">Mục Tiêu:</CLabel>
                                                <Select
                                                    id="input-customer_id"
                                                    options={projects_options}
                                                    value={this.state.book_object_ids}
                                                    isMulti
                                                    placeholder='chọn Mục tiêu'
                                                    onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                />
                                                <small className="text-danger">{this.state.errors['book_object_ids']}</small>
                                            </div>
                                        }

                                        {this.state.book_object === 'vendors' &&
                                            <div className="mb-3">
                                                <CLabel htmlFor="input-customer_id">Nhà Cung Cấp:</CLabel>
                                                <Select
                                                    id="input-customer_id"
                                                    options={vendors_options}
                                                    value={this.state.book_object_ids}
                                                    isMulti
                                                    placeholder='chọn Nhà cung cấp'
                                                    onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                />
                                                <small className="text-danger">{this.state.errors['book_object_ids']}</small>
                                            </div>
                                        }

                                        {this.state.book_object === 'other' &&
                                            <div className="mb-3">
                                                <CLabel htmlFor="input-other">Chi khác: <Hint content="Tên người thụ hưởng" /></CLabel>
                                                <CTextarea
                                                    name="note"
                                                    id="input-other"
                                                    value={this.state.book_object_content}
                                                    onChange={(e) => { this.handleChange('book_object_content', e) }}
                                                />
                                                <small className="text-danger">{this.state.errors['book_object_content']}</small>
                                            </div>
                                        }

                                        <div className="mb-3">
                                            <CLabel htmlFor="input-book_bank_info">Tài khoản NH thụ hưởng:</CLabel>
                                            <CTextarea
                                                name="book_bank_info"
                                                id="input-book_bank_info"
                                                value={this.state.book_bank_info}
                                                onChange={(e) => { this.handleChange('book_bank_info', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['book_bank_info']}</small>
                                        </div>

                                        <hr />
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-book_period_from">Kỳ Thanh Toán:</CLabel>
                                        </div>
                                        <CRow className="mb-3">
                                            <CCol>
                                                <DateRangePicker
                                                    locale={locale}
                                                    ranges={[selectionRange]}
                                                    rangeColors={['#3d91ff', '#3ecf8e', '#fed14c']}
                                                    onChange={this.handleSelectDateRange}
                                                    inputRanges={[]}
                                                />
                                                <small className="text-danger">{this.state.errors['book_period_from']} {this.state.errors['book_period_to']}</small>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow >
                    </CModalBody>
                    <CModalFooter>
                        <CTooltip content={'Tạo Phiếu chi'}>
                            <CButton type="submit" color="primary">
                                <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                Tạo
                            </CButton>
                        </CTooltip>
                        <CTooltip content={'Hủy Phiếu chi'}>
                            <CButton type="button" color="secondary" className={'ml-3'} onClick={() => { this.cancelForm() }}>
                                <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                Hủy
                            </CButton>
                        </CTooltip>
                    </CModalFooter>
                </CModal>
            </CForm >
        )
    }
}
export default BookInForm;

