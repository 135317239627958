import React, { Component } from 'react'
import {
    CRow,
    CCol,
    CButton,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';

class BookOutExport extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.loadFile = this.loadFile.bind(this);
        this.generateDocument = this.generateDocument.bind(this);
    }

    componentDidMount() {
    }

    loadFile(url, callback) {
        PizZipUtils.getBinaryContent(url, callback);
    }

    generateDocument() {
        const { book, employees, user, items, projects, housings } = this.props;


        let tpl_url = '/templates/';
        tpl_url = tpl_url + 'tpl_phieu_chi.docx';
        let book_name = '......................................';
        let book_date = new Date(book.book_date);
        switch (book.book_object) {
            case 'employees':
                if (!helps.isEmpty(book.book_object_ids) && book.book_object_ids.length > 0) {
                    book_name = '';
                    let book_names = [];
                    book.book_object_ids.forEach(object_id => {
                        let item = helps.getItemFromArr(employees, object_id.value, 'employee_id');
                        book_names.push(item.fullname + ' (' + item.employee_id + ')');
                    });
                    book_name = book_names.join('; ');
                }
                break;
        }
        this.loadFile(
            tpl_url,
            (error, content) => {
                if (error) {
                    throw error;
                }
                var zip = new PizZip(content);
                var doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });
                doc.setData({
                    book_id: book.book_id,
                    address: configs.address,
                    book_day: book_date.getDate(),
                    book_month: book_date.getMonth() + 1,
                    book_year: book_date.getFullYear(),
                    book_name: book_name,
                    book_desciption: book.description,
                    book_amount: helps.formatCurrency(book.amount),
                    amount_to_words: helps.DocTienBangChu(book.amount),
                });
                try {
                    // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                    doc.render();
                } catch (error) {
                    // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                    function replaceErrors(key, value) {
                        if (value instanceof Error) {
                            return Object.getOwnPropertyNames(value).reduce(function (
                                error,
                                key
                            ) {
                                error[key] = value[key];
                                return error;
                            },
                                {});
                        }
                        return value;
                    }
                    //console.log(JSON.stringify({ error: error }, replaceErrors));

                    if (error.properties && error.properties.errors instanceof Array) {
                        const errorMessages = error.properties.errors
                            .map(function (error) {
                                return error.properties.explanation;
                            })
                            .join('\n');
                        console.log('errorMessages', errorMessages);
                        // errorMessages is a humanly readable message looking like this :
                        // 'The tag beginning with "foobar" is unopened'
                    }
                    throw error;
                }
                var out = doc.getZip().generate({
                    type: 'blob',
                    mimeType:
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                }); //Output the document using Data-URI
                var file_name = 'SEKIN_PHIEU_CHI_' + book.book_id + '.docx';

                saveAs(out, file_name);
            }
        );
    };

    render() {
        const { book } = this.props;
        return (
            <CTooltip content={'Tải về: ' + book.book_id}>
                <CButton
                    type='button'
                    size='sm'
                    color="warning"
                    onClick={() => { this.generateDocument() }}
                >
                    <CIcon name="cil-cloud-download"></CIcon>
                </CButton>
            </CTooltip>
        )
    }
}

export default BookOutExport;
