export const taskConstants = {
    GET_LIST_REQUEST: 'TASKS_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'TASKS_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'TASKS_GET_LIST_FAILURE',

    GET_USER_TASKS_REQUEST: 'TASKS_GET_USER_TASKS_REQUEST',
    GET_USER_TASKS_SUCCESS: 'TASKS_GET_USER_TASKS_SUCCESS',
    GET_USER_TASKS_FAILURE: 'TASKS_GET_USER_TASKS_FAILURE',

    ADD_REQUEST: 'TASKS_ADD_REQUEST',
    ADD_SUCCESS: 'TASKS_ADD_SUCCESS',
    ADD_FAILURE: 'TASKS_ADD_FAILURE',

    EDIT_REQUEST: 'TASKS_EDIT_REQUEST',
    EDIT_SUCCESS: 'TASKS_EDIT_SUCCESS',
    EDIT_FAILURE: 'TASKS_EDIT_FAILURE',

    DELETE_REQUEST: 'TASKS_DELETE_REQUEST',
    DELETE_SUCCESS: 'TASKS_DELETE_SUCCESS',
    DELETE_FAILURE: 'TASKS_DELETE_FAILURE',

    GET_COMMENTS_REQUEST: 'TASKS_GET_COMMENTS_REQUEST',
    GET_COMMENTS_SUCCESS: 'TASKS_GET_COMMENTS_SUCCESS',
    GET_COMMENTS_FAILURE: 'TASKS_GET_COMMENTS_FAILURE',

    ADD_COMMENT_REQUEST: 'TASKS_ADD_COMMENT_REQUEST',
    ADD_COMMENT_SUCCESS: 'TASKS_ADD_COMMENT_SUCCESS',
    ADD_COMMENT_FAILURE: 'TASKS_ADD_COMMENT_FAILURE',

    EDIT_COMMENT_REQUEST: 'TASKS_EDIT_COMMENT_REQUEST',
    EDIT_COMMENT_SUCCESS: 'TASKS_EDIT_COMMENT_SUCCESS',
    EDIT_COMMENT_FAILURE: 'TASKS_EDIT_COMMENT_FAILURE',

    DELETE_COMMENT_REQUEST: 'TASKS_DELETE_COMMENT_REQUEST',
    DELETE_COMMENT_SUCCESS: 'TASKS_DELETE_COMMENT_SUCCESS',
    DELETE_COMMENT_FAILURE: 'TASKS_DELETE_COMMENT_FAILURE',
}