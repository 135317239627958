import React, { Component } from 'react'
import {
    CCol,
    CRow,
} from '@coreui/react';
import { helps } from 'src/_helpers';
import { taskService } from 'src/_services';
import Select, { components } from 'react-select';
import Loading from 'src/containers/_loading';

class TaskFollowers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user_options: [],
        }
        this.handleChangeFollowers = this.handleChangeFollowers.bind(this);
    }

    componentDidMount() {
        const { users, task } = this.props;
        var user_options = [];
        if (!helps.isEmpty(users)) {
            users.map((_user) => {
                var isFixed = false;
                if (task.added_by === _user.id) {
                    isFixed = true;
                }
                let _option = {
                    label: _user.fullname + '-' + _user.username,
                    value: _user.id,
                    isFixed: isFixed,
                }
                user_options.push(_option);
            });
        }
        this.setState({ user_options: user_options });
    }

    addFollow() {
        const { task, user } = this.props;
        var followers = task.followers ? task.followers : [];
        let found_me = helps.getItemFromArr(followers, user.id, 'value');
        if (helps.isEmpty(found_me)) {
            let follower = {
                value: user.id,
                label: user.fullname + '-' + user.username
            };
            taskService.addFollower(task, follower);
        }

    }

    handleChangeFollowers(selectedOptions, actionMeta) {
        const { task } = this.props;
        console.log(selectedOptions, actionMeta);
        var follower = {};
        switch (actionMeta.action) {
            case "select-option":
                follower = { value: actionMeta.option.value, label: actionMeta.option.label }
                taskService.addFollower(task, follower);
                break;
            case "remove-value":
                follower = { value: actionMeta.removedValue.value, label: actionMeta.removedValue.label }
                taskService.removeFollower(task, follower);
                break;
            default:
        }
        this.setState({ default_options: selectedOptions });
    }

    render() {
        const { is_author, task } = this.props;
        if (helps.isEmpty(task) || helps.isEmpty(task.followers)) {
            return (<Loading></Loading>);
        }
        if (task.followers && task.followers.length > 0) {
            task.followers.map((follower) => {
                if (task.added_by === follower.value) {
                    follower['isFixed'] = true;
                } else {
                    follower['isFixed'] = false;
                }
            });
        }
        return (
            <CRow>
                <CCol xl={12}>
                    <h5><b>Theo Dõi: </b></h5>
                    <Select
                        id="input-followers"
                        placeholder='...'
                        isMulti
                        isDisabled={!is_author}
                        isClearable={false}
                        options={this.state.user_options}
                        defaultValue={task.followers}
                        components={{ MultiValueRemove }}
                        onChange={this.handleChangeFollowers}
                    >
                    </Select>
                </CCol>
            </CRow >
        )
    }
}
export default TaskFollowers;

const MultiValueRemove = (props) => {
    if (props.data.isFixed) {
        return null;
    }
    return <components.MultiValueRemove {...props} />;
};