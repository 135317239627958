import { violationConstants } from "src/_constants";
import { helps } from "src/_helpers";

export function violationReducer(state = {}, actions) {
    switch (actions.type) {
        // GET LIST    
        case violationConstants.GET_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case violationConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                violations: actions.violations,
                loading: false,
            }
        case violationConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }
        // Add
        case violationConstants.ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case violationConstants.ADD_SUCCESS:
            let violations = state.violations;
            if (actions.violation) {
                violations.push(actions.violation);
            }
            //console.log(actions.violation)
            violations = violations.sort(function (a, b) {
                let b_violation_date = b.violation_date ? b.violation_date : new Date();
                let a_violation_date = a.violation_date ? a.violation_date : new Date();
                return b_violation_date - a_violation_date;
            });
            return {
                ...state,
                violations: violations,
                loading: false,
            }
        case violationConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Edit
        case violationConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case violationConstants.EDIT_SUCCESS:
            var _violations = state.violations;
            var foundIndex = _violations.findIndex(violation => violation.id === actions.violation.id);
            _violations[foundIndex] = actions.violation;
            //console.log(foundIndex);
            return {
                ...state,
                violations: _violations,
                loading: false,
            }
        case violationConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        // Delete 
        case violationConstants.DELETE_REQUEST:
            return {
                ...state,
                violations: state.violations.map(violation =>
                    violation.id === actions.violation.id
                        ? { ...violation, deleting: true }
                        : violation
                )
            };
        case violationConstants.DELETE_SUCCESS:
            return {
                violations: state.violations.filter(violation => violation.id !== actions.violation.id)
            };
        case violationConstants.DELETE_FAILURE:
            return {
                ...state,
                violations: state.violations.map(violation => {
                    if (violation.id === actions.violation.id) {
                        const { deleting, ...violationCopy } = violation;
                        return { ...violationCopy, deleteError: actions.error };
                    }
                    return violation;
                })
            };

        // GET LIST TYPES    
        case violationConstants.GET_LIST_TYPES_REQUEST:
            return {
                ...state,
                loading_type: true,
            }
        case violationConstants.GET_LIST_TYPES_SUCCESS:
            return {
                ...state,
                violation_types: actions.violation_types,
                loading_type: false,
            }
        case violationConstants.GET_LIST_TYPES_FAILURE:
            return {
                ...state,
                loading_type: true,
                error: actions.error,
            }
        // Add Type
        case violationConstants.ADD_TYPES_REQUEST:
            return {
                ...state,
                loading_type: false,
            }
        case violationConstants.ADD_TYPES_SUCCESS:
            var violation_types = state.violation_types ? state.violation_types : [];
            violation_types.push(actions.violation_type);
            return {
                ...state,
                violation_types: violation_types,
                loading_type: false,
            }
        case violationConstants.ADD_TYPES_FAILURE:
            return {
                ...state,
                loading_type: false,
                error: actions.error,
            }
        // Edit Type
        case violationConstants.EDIT_TYPES_REQUEST:
            return {
                ...state,
                loading_type: false,
            }
        case violationConstants.EDIT_TYPES_SUCCESS:
            var _types = state.violation_types;
            var foundIndex = _types.findIndex(type => type.id === actions.type.id);
            _types[foundIndex] = actions.type;
            return {
                ...state,
                violation_types: _types,
                loading_type: false,
            }
        case violationConstants.EDIT_TYPES_FAILURE:
            return {
                ...state,
                loading_type: false,
                error: actions.error,
            }
        // Delete 
        case violationConstants.DELETE_TYPES_REQUEST:
            return {
                ...state,
                violation_types: state.violation_types.map(type =>
                    type.id === actions.violation_type.id
                        ? { ...type, deleting: true }
                        : type
                )
            };
        case violationConstants.DELETE_TYPES_SUCCESS:
            return {
                violation_types: state.violation_types.filter(type => type.id !== actions.violation_type.id)
            };
        case violationConstants.DELETE_TYPES_FAILURE:
            return {
                ...state,
                violation_types: state.violation_types.map(type => {
                    if (type.id === actions.violation_type.id) {
                        const { deleting, ...typeCopy } = type;
                        return { ...typeCopy, deleteError: actions.error };
                    }
                    return type;
                })
            };
        // Add Term
        case violationConstants.ADD_TERMS_REQUEST:
            return {
                ...state,
                loading_term: false,
            }
        case violationConstants.ADD_TERMS_SUCCESS:
            var violation_types = state.violation_types;
            var _type = actions.violation_type;
            var _terms = _type.terms ? _type.terms : [];
            _terms.push(actions.term);
            _type['terms'] = _terms;
            var foundIndex = violation_types.findIndex(type => type.id === actions.type.id);
            violation_types[foundIndex] = _type;
            return {
                ...state,
                violation_types: violation_types,
                loading_term: false,
            }
        case violationConstants.ADD_TERMS_FAILURE:
            return {
                ...state,
                loading_term: false,
                error: actions.error,
            }
        // Edit Term
        case violationConstants.EDIT_TERMS_REQUEST:
            return {
                ...state,
                loading_term: true,
            }
        case violationConstants.EDIT_TERMS_SUCCESS:
            var _types = state.violation_types;
            var _terms = actions.violation_type.terms;
            var foundTermIndex = _terms.findIndex(term => term.id === actions.term.id);
            _terms[foundTermIndex] = actions.term;
            actions.violation_type['terms'] = _terms;
            var foundIndex = _types.findIndex(type => type.id === actions.violation_type.id);
            _types[foundIndex] = actions.violation_type;
            return {
                ...state,
                violation_types: _types,
                loading_term: false,
            }
        case violationConstants.EDIT_TERMS_FAILURE:
            return {
                ...state,
                loading_term: false,
                error: actions.error,
            }
        // Delete 
        case violationConstants.DELETE_TERMS_REQUEST:
            return {
                ...state,
                loading_term: true,
            };
        case violationConstants.DELETE_TERMS_SUCCESS:
            var _types = state.violation_types;
            var _terms = actions.violation_type.terms.filter(term => term.id !== actions.term.id);
            var foundIndex = _types.findIndex(type => type.id === actions.violation_type.id);
            _types[foundIndex] = actions.violation_type;
            return {
                ...state,
                violation_types: _types,
                loading_term: false,
            };
        case violationConstants.DELETE_TERMS_FAILURE:
            return {
                ...state,
                loading_term: false,
            };
        default: return state;
    }
}