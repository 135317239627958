import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CRow, CCol,
    CButton,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { ThePermission } from 'src/containers/ThePermission';
import Candidate from 'src/views/employees/candidates/Candidate';
import { candidateActions, fileActions, noteActions, userActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import { NotesContainer } from 'src/containers/notes';
import { FilesContainer } from 'src/containers/files';
import { TasksComponentContainer } from 'src/containers/tasks/TasksComponentContainer';

class CandidateContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            candidate: {},
            object: 'candidates',
            object_id: '',
        }
    }

    componentDidMount() {
        this.getCandidates().then(() => {
            this.getUsers().then(() => {
                this.getCandidate().then(() => {
                    this.getFiles().then((files) => {
                        var candidate = this.state.candidate;
                        candidate['files'] = files;
                        this.setState({ candidate: candidate });
                    });
                });
            });
        });
    }

    async getCandidate() {
        let candidate_id = this.props.match.params.id;
        const { candidates } = this.props;
        var candidate = {};
        if (candidate_id && candidates) {
            let candidate = helps.getItemFromArr(candidates, candidate_id);
            this.setState({ candidate: candidate });
        }
        return candidate;
    }

    async getFiles() {
        if (!helps.isEmpty(this.state.candidate)) {
            const files = await this.props.getFiles('candidates', this.state.candidate.id);
            return files;
        }
    }

    async getCandidates() {
        const { candidates } = this.props;
        if (helps.isEmpty(candidates)) await this.props.getAll();
    }

    async getUsers() {
        const { users } = this.props;
        if (helps.isEmpty(users)) await this.props.getUsers();
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.props.candidates)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.state.candidate)) {
            return (
                <Loading></Loading>
            );
        }
        const candidate = this.state.candidate;
        return (
            <ThePermission {... this.props} component="employees" action="view">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol>
                                Ứng Viên: <b>{candidate.fullname} - {candidate.phone}</b>
                            </CCol>
                            <CCol className='text-right'>
                                <ThePermission {... this.props} component="customers" action="edit">
                                    <CTooltip content={'Chỉnh Sửa Ứng Viên: ' + candidate.fullname}>
                                        <CButton
                                            color="primary"
                                            size="sm"
                                            title="Cập nhật thông tin"
                                            onClick={() => { this.props.history.push(`/employees/candidates/add_edit/${candidate.id}`) }}
                                        >
                                            <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                            Chỉnh Sửa
                                        </CButton>
                                    </CTooltip>
                                </ThePermission>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CTabs activeTab="info">
                            <CNav variant="tabs">
                                <CNavItem>
                                    <CNavLink data-tab="info">
                                        <h6>Thông Tin</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="tasks">
                                        <h6>Công Việc</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="notes">
                                        <h6>Ghi Chú</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="files">
                                        <h6>Tài Liệu</h6>
                                    </CNavLink>
                                </CNavItem>
                            </CNav>
                            <CTabContent className="py-3">
                                <CTabPane data-tab="info">
                                    <Candidate {... this.props} candidates={this.props.candidates} candidate={this.state.candidate} />
                                </CTabPane>
                                <CTabPane data-tab="tasks">
                                    <TasksComponentContainer {... this.props} object={this.state.object} object_id={candidate.id} />
                                </CTabPane>
                                <CTabPane data-tab="notes">
                                    <NotesContainer {... this.props} object={this.state.object} object_id={candidate.id} />
                                </CTabPane>
                                <CTabPane data-tab="files">
                                    <FilesContainer key={'files_' + this.state.object + '_' + this.state.object_id} {... this.props} object={this.state.object} object_id={candidate.id} />
                                </CTabPane>
                            </CTabContent>
                        </CTabs>
                    </CCardBody>
                </CCard>
            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { loading, candidates } = state.candidateReducer;
    const { users } = state.userReducer;
    const { notes } = state.noteReducer;
    const { user } = state.authentication;
    return { loading, candidates, users, user, notes };
}

const actionCreators = {
    getAll: candidateActions.getAll,
    deleteFile: fileActions.deleteFile,
    getFiles: fileActions.getAll,
    getUsers: userActions.getAll,
    getNotes: noteActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(CandidateContainer);
export { connectedContainer as CandidateContainer };