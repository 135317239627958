import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CTextarea,
    CInput,
    CLink,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CTooltip,
    CCollapse,
    CPopover,
    CImg,
    CSelect,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';

class EmployeeContractsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            form_header: 'Thêm HĐLĐ',
            id: '',
            start_date: new Date(),
            end_date: new Date(),
            added_time: new Date(),
            added_by: this.props.user.id,
            active: 0,
            note: '',
            attachment: [],
            show_existing_files: false,
            period: 12,
            upload_files: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeFiles = this.handleChangeFiles.bind(this);
    }

    componentDidMount() {

        const { contract } = this.props;

        if (!helps.isEmpty(contract) && contract.id !== 'add_form') {
            this.setState({
                form_header: 'Chỉnh Sửa HĐLĐ: ' + contract.id,
                id: contract.id,
                start_date: contract.start_date,
                end_date: contract.end_date,
                note: contract.note,
                added_time: contract.added_time,
                added_by: contract.added_by,
                active: contract.active,
                attachment: contract.attachment,
            });
        } else {
            this.doChangeEnd_date();
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let contract = {
                id: this.state.id.replaceAll(' ', ''),
                start_date: this.state.start_date ? new Date(this.state.start_date) : new Date(),
                end_date: this.state.end_date ? new Date(this.state.end_date) : new Date(),
                note: this.state.note,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
                active: this.state.active,
                attachment: this.state.attachment,
                upload_files: this.state.upload_files,
            }
            //console.log(contract);
            this.props.handleSubmit(contract);
        } else {
            return false;
        }
    }

    handleChangeFiles(field, e) {
        let upload_files = e.target.files;
        this.setState({ [field]: upload_files });
    }

    handleChange(field, e) {
        let value = e.target.value;
        if (field === 'id') {
            value.replaceAll(' ', '');
        }
        this.setState({
            [field]: value
        });

        if (field === 'period') {
            this.doChangeEnd_date();
        }
    }

    handleChangeDate(_date, field) {
        this.setState({ [field]: _date });

        if (field === 'start_date') {
            this.doChangeEnd_date();
        }
    }

    handleSelectAttachment(_file) {
        let attachment = this.state.attachment;
        attachment.push({
            name: _file.name,
            url: _file.url,
        });
        this.setState({
            attachment: attachment
        });
    }

    handleRemoveAttachment(_file) {
        let items = this.state.attachment;
        let newItems = items.filter(item => item.name !== _file.name);
        this.setState({
            attachment: newItems
        });
    }


    doChangeEnd_date() {
        let period = parseInt(this.state.period);
        let _start_date = new Date(this.state.start_date);
        //console.log(_start_date, period);
        let end_date = new Date(_start_date);
        end_date.setMonth(_start_date.getMonth() + period);

        //console.log(end_date);
        this.setState({ end_date: end_date });
    }

    cancelForm() {
        let result = window.confirm("Hủy form này?");
        if (result) {
            this.props.toggleEditForms(this.props.item);
        } else return false;
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.id)) {
            formIsValid = false;
            errors["id"] = "Vui lòng nhập Số Hợp Đồng";
        }

        if (!this.state.start_date) {
            formIsValid = false;
            errors["start_date"] = "Vui lòng nhập Ngày bắt đầu HĐLĐ";
        }

        if (!this.state.end_date) {
            formIsValid = false;
            errors["end_date"] = "Vui lòng nhập Ngày kết thúc HĐLĐ";
        }

        if (this.state.start_date && this.state.end_date && new Date(this.state.start_date).getTime() > new Date(this.state.end_date).getTime()) {
            formIsValid = false;
            errors["start_date"] = "Ngày bắt đầu HĐLĐ là ngày trước Ngày kết thúc HĐLĐ";
            errors["end_date"] = errors["start_date"];
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        const { employee, files } = this.props;
        const ext = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'svgz', 'heic', 'heif', 'webp', 'tiff', 'tif', 'psd', 'bmp'];
        //console.log(files)
        return (
            <CModal
                show={this.props.edit_forms.includes(this.props.item.id)}
                size='lg'
                onClose={() => { this.cancelForm(); }}
                className='text-left'
            >
                <CModalHeader closeButton>
                    <h4><b>{this.state.form_header}</b>: <small> {employee.fullname + ' (' + employee.employee_id + ')'}</small></h4>
                </CModalHeader>
                <CModalBody>
                    <CForm>
                        <CRow className="mb-3">
                            <CCol md={6}>
                                <CLabel htmlFor="input-id">Số HĐLĐ:</CLabel>
                                <CInput
                                    type='text'
                                    name='id'
                                    id='input-id'
                                    value={this.state.id}
                                    onChange={(e) => { this.handleChange('id', e); }}
                                />
                                <small className='text-danger'>{this.state.errors['id']}</small>
                            </CCol>
                        </CRow>
                        <CRow className="mb-3">
                            <CCol md={2}>
                                <CLabel htmlFor="input-period">Thời hạn:</CLabel>
                                <CSelect
                                    id='input-period'
                                    value={this.state.period}
                                    onChange={(e) => { this.handleChange('period', e); }}
                                    onBlur={() => { this.doChangeEnd_date(); }}
                                >
                                    <option value={1}> 1 tháng </option>
                                    <option value={3}> 3 tháng </option>
                                    <option value={6}> 6 tháng </option>
                                    <option value={12}> 12 tháng </option>
                                    <option value={24}> 24 tháng </option>
                                    <option value={24}> 24 tháng </option>
                                </CSelect>
                            </CCol>
                            <CCol md={5}>
                                <CLabel htmlFor="input-start_date">Ngày bắt đầu HĐLĐ:</CLabel>
                                <div>
                                    <DateTimePicker
                                        id='input-start_date'
                                        value={this.state.start_date}
                                        format={'dd/MM/yyyy'}
                                        className='ml-2'
                                        maxDate={this.state.end_date}
                                        onChange={(value) => { this.handleChangeDate(value, 'start_date'); this.doChangeEnd_date(); }}
                                        onCalendarClose={() => { this.doChangeEnd_date(); }}
                                    />
                                    <div><small className='text-danger'>{this.state.errors['start_date']}</small></div>
                                </div>
                            </CCol>

                            <CCol md={5}>
                                <CLabel htmlFor="input-end_date">Ngày kết thúc HĐLĐ:</CLabel>
                                <div>
                                    <DateTimePicker
                                        key={this.state.period + new Date(this.state.start_date).getTime()}
                                        id='input-end_date'
                                        value={this.state.end_date}
                                        format={'dd/MM/yyyy'}
                                        className='ml-2'
                                        minDate={this.state.start_date}
                                        onChange={(value) => { this.handleChangeDate(value, 'end_date') }}

                                    />
                                    <div><small className='text-danger'>{this.state.errors['end_date']}</small></div>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CRow className="mb-1">
                                    <CCol md={3}>
                                        <CLabel htmlFor="input-attachment">Đính kèm:</CLabel>
                                    </CCol>
                                    <CCol md={9}>
                                        {this.state.attachment && this.state.attachment.length > 0 && <ol className='bg-light p-3 text-dark'>
                                            {this.state.attachment.map(file => {
                                                return (
                                                    <li key={'attachment-' + file.name} className='ml-2'>
                                                        <CLink href={file.url} target='_blank'>{file.name}</CLink>
                                                        <CTooltip content={'Loại bỏ'}>
                                                            <CButton
                                                                type="button" size='sm' color="danger"
                                                                className={'ml-3'}
                                                                onClick={() => { this.handleRemoveAttachment(file) }}
                                                            >
                                                                <CIcon name="cil-x" className="mfe-1 mt-0"></CIcon>
                                                            </CButton>
                                                        </CTooltip>
                                                    </li>
                                                )
                                            })}
                                        </ol>}
                                    </CCol>
                                </CRow>
                                <CRow className="mb-1">
                                    <CCol md={3}>
                                        Tải lên:
                                    </CCol>
                                    <CCol md={9}>
                                        <CInput
                                            type='file'
                                            name='upload_files'
                                            id='input-upload_files'
                                            multiple
                                            onChange={(e) => { this.handleChangeFiles('upload_files', e) }}
                                        />
                                        <small className='text-danger'>{this.state.errors['upload_files']}</small>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol md={3}>
                                        <CTooltip content={<>{'Chọn từ kho Tài liệu nhân sự: ' + employee.fullname}</>}>
                                            <CButton type="button" size='sm' color="primary" onClick={() => { this.setState({ show_existing_files: !this.state.show_existing_files }); }}>
                                                <CIcon name="cil-folder-open" className="mfe-1 mt-0"></CIcon>
                                                Chọn từ kho Tài liệu
                                            </CButton>
                                        </CTooltip>
                                    </CCol>
                                    <CCol md={9}>
                                        <CCollapse show={this.state.show_existing_files}>
                                            <table className='table table-hover'>
                                                <tbody>
                                                    {!helps.isEmpty(files) && files.map((_file) => {
                                                        let found_file = helps.getItemFromArr(this.state.attachment, _file.name, 'name');
                                                        if (!helps.isEmpty(found_file)) {
                                                            return (<></>);
                                                        }
                                                        let _metadata = _file['_metadata'];
                                                        return (
                                                            <tr key={_file.name}>
                                                                <td>
                                                                    {ext.includes(_file['ext']) &&
                                                                        <CPopover content={<CImg src={_file.url} width={150} thumbnail={true}></CImg>}>
                                                                            <CImg src={_file.url} width={20} height={20} style={{ maxHeight: '20px' }} thumbnail={true} title={_file.name} />
                                                                        </CPopover>
                                                                    }
                                                                    {!ext.includes(_file['ext']) && <CIcon name="cil-file" size={'1xl'} title={_file.name}></CIcon>}
                                                                </td>
                                                                <td >
                                                                    <CPopover content={<div>{_metadata && <>{helps.formatDateTime_from_Timestamp(new Date(_metadata.updated))}</>}</div>}>
                                                                        <CLink href={_file.url} target='_blank'>{_file.name}</CLink>
                                                                    </CPopover>
                                                                </td>
                                                                <td >
                                                                    <CTooltip content={_file.name}>
                                                                        <CButton
                                                                            type="button" size='sm' color="primary"
                                                                            onClick={() => { this.handleSelectAttachment(_file) }}
                                                                        >
                                                                            <CIcon name="cil-check" className="mfe-1 mt-0"></CIcon>
                                                                            Chọn
                                                                        </CButton>
                                                                    </CTooltip>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </CCollapse>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CLabel htmlFor="input-content">Ghi Chú:</CLabel>
                                <CTextarea
                                    rows={3}
                                    name="note"
                                    id="input-note"
                                    defaultValue={this.state.note}
                                    onChange={(e) => { this.handleChange('note', e) }}
                                >
                                </CTextarea>
                                <small className="text-danger">{this.state.errors['note']}</small>
                            </CCol>
                        </CRow>

                    </CForm>
                </CModalBody>
                <CModalFooter>
                    <CTooltip content={'Lưu'}>
                        <CButton type="submit" size='sm' color="primary" onClick={(e) => this.handleSubmit(e)}>
                            <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                            Lưu
                        </CButton>
                    </CTooltip>
                    <CTooltip content={'Hủy'}>
                        <CButton type="button" size='sm' color="secondary" onClick={() => { this.cancelForm(); }} className="ml-3">
                            <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                            Huỷ
                        </CButton>
                    </CTooltip>
                </CModalFooter>
            </CModal>
        )
    }
}
export default EmployeeContractsForm;

