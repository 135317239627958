import { fileConstants } from "src/_constants";

export function fileReducer(state = { files: [] }, actions) {
    switch (actions.type) {
        case fileConstants.GET_LIST_REQUEST:
            return {
                loading: true,
            }
        case fileConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                files: actions.files,
                loading: false,
            }
        case fileConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Upload
        case fileConstants.UPLOAD_REQUEST:
            return {
                ...state,
                upload_loading: true,
            }
        case fileConstants.UPLOAD_SUCCESS:
            let files = [];

            if (state.files && state.files.length > 0) {
                files = state.files.concat(actions.files);
            } else {
                files = actions.files;
            }
            return {
                ...state,
                upload_loading: false,
                files: files,
            }
        case fileConstants.UPLOAD_FAILURE:
            return {
                ...state,
                upload_loading: false,
                error: actions.error,
            }
        // Delete 
        case fileConstants.DELETE_REQUEST:
            return {
                ...state,
                files: state.files.map(file =>
                    file._metadata.name === actions.file._metadata.name
                        ? { ...file, deleting: true }
                        : file
                )
            };
        case fileConstants.DELETE_SUCCESS:
            return {
                files: state.files.filter(file => file._metadata.name !== actions.file._metadata.name)
            };
        case fileConstants.DELETE_FAILURE:
            return {
                ...state,
                files: state.files.map(file => {
                    if (file._metadata.name === actions.file._metadata.name) {
                        const { deleting, ...noteCopy } = file;
                        return { ...noteCopy, deleteError: actions.error };
                    }
                    return file;
                })
            };

        default: return state;
    }
}