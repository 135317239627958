import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTooltip,
    CButton,
    CLabel,
    CFormGroup,
    CTextarea,
    CCollapse,
    CInputRadio,
    CInput,
    CCardFooter,
    CPopover,
    CForm,
    CFormText,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Hint from 'src/_components/Hint';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';

class ExitConclusion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            sh_s3: true,
        }
    }


    componentDidMount() {

    }

    render() {
        const { employee, user, exit } = this.props;

        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.props.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader style={{ backgroundColor: '#EEE' }}>
                                <CRow>
                                    <CCol>
                                        IV. ĐÁNH GIÁ / KẾT LUẬN
                                        {this.state.errors['conclusion'] && <div className='mb-1 mt-1'><small className="text-danger">// {this.state.errors['conclusion']}</small></div>}
                                    </CCol>
                                    <CCol className={'text-right'}>
                                        <CTooltip content={this.state.sh_s3 ? 'Ẩn' : 'Hiện'}>
                                            <CButton type="button" color="secondary" size="sm" onClick={() => { this.setState({ sh_s3: !this.state.sh_s3 }) }} title={this.state.sh_s3 ? 'Ẩn' : 'Hiện'} className='ml-2'>
                                                <CIcon name={this.state.sh_s3 ? 'cil-chevron-top' : 'cil-chevron-bottom'} className="mfe-1 mt-0"></CIcon>
                                            </CButton>
                                        </CTooltip>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCollapse show={this.state.sh_s3}>
                                <CCardBody>
                                    <CRow className={'mb-3'}>
                                        <CCol>
                                            <CLabel htmlFor="input-quit_note">Ý kiến của P.NV:<Hint content='P.NV' /></CLabel>
                                            <CTextarea
                                                id='input-pnv_note'
                                                name='pnv_note'
                                                defaultValue={exit.conclusion.pnv_note}
                                                rows={5}
                                                onBlur={(e) => { this.props.handleChangeExitField('conclusion', e) }}
                                                disabled={exit.exit_completed}
                                            ></CTextarea>
                                        </CCol>
                                        <CCol>
                                            <CLabel htmlFor="input-phckt_note">Ý kiến của P.HC-KT:<Hint content='P.HC-KT' /></CLabel>
                                            <CTextarea
                                                id='input-phckt_note'
                                                name='phckt_note'
                                                defaultValue={exit.conclusion.phckt_note}
                                                rows={5}
                                                onBlur={(e) => { this.props.handleChangeExitField('conclusion', e) }}
                                                disabled={exit.exit_completed}
                                            ></CTextarea>
                                        </CCol>
                                        <CCol>
                                            <CLabel htmlFor="input-bgd_note">Ý kiến của BGĐ:<Hint content='BGĐ' /></CLabel>
                                            <CTextarea
                                                id='input-bgd_note'
                                                name='bgd_note'
                                                defaultValue={exit.conclusion.bgd_note}
                                                rows={5}
                                                onBlur={(e) => { this.props.handleChangeExitField('conclusion', e) }}
                                                disabled={exit.exit_completed}
                                            ></CTextarea>
                                        </CCol>
                                    </CRow>
                                    <CRow className={'mb-3'}>
                                        <CCol>
                                            <CLabel htmlFor="input-exit_conclusion">Kết luận:<Hint content='P.HC-KT' /></CLabel>
                                            <div className='table-responsive mb-3'>
                                                <table className='table table-hover'>
                                                    <thead>
                                                        <tr>
                                                            <th>Thời gian nghỉ việc</th>
                                                            <th>Điều kiện</th>
                                                            <th>Lương nhận</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!helps.isEmpty(configs.employee_exit_conditions) && configs.employee_exit_conditions.map((condition) => {
                                                            return (
                                                                <tr key={condition.id}>
                                                                    <td>
                                                                        <CFormGroup variant='custom-checkbox' inline>
                                                                            <CInputRadio
                                                                                custom
                                                                                id={"input-exit_conclusion-" + condition.id}
                                                                                name="exit_conclusion"
                                                                                value={condition.id}
                                                                                checked={exit.exit_conclusion === condition.id}
                                                                                onChange={(e) => { this.props.handleChangeExitField('', e) }}
                                                                                disabled={exit.exit_completed}
                                                                            />
                                                                            <CLabel variant="custom-checkbox" htmlFor={"input-exit_conclusion-" + condition.id} className="cursor-pointer">
                                                                                {condition.label}
                                                                            </CLabel>
                                                                        </CFormGroup>
                                                                    </td>
                                                                    <td>
                                                                        {condition.description}
                                                                    </td>
                                                                    <td className='text-center font-weight-bold'>
                                                                        {condition.salary}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <hr />
                                            <CRow className='mb-3'>
                                                <CCol md={2}>
                                                    <CLabel htmlFor="input-exit_conclusion_value">Lương Nhận:<Hint content={'P.HC-KT'} /></CLabel>
                                                </CCol>
                                                <CCol md={1}>
                                                    <CInput
                                                        type='text'
                                                        id='input-exit_conclusion_value'
                                                        name='exit_conclusion_value'
                                                        value={exit.exit_conclusion_value}
                                                        onChange={(e) => { this.props.handleChangeExitField('', e) }}
                                                        disabled={exit.exit_completed}
                                                        className={'text-dark font-weight-bold'}
                                                    />
                                                    <small className="text-danger">{this.state.errors['conclusion']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CFormText className="help-block">// sau khi đã trừ các khoản Đối Trừ vào lương.</CFormText>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCollapse>
                            <CCardFooter>
                                {this.state.errors['letter'] && <div className='mb-2'><small className="text-danger">// {this.state.errors['letter']}</small></div>}
                                {this.state.errors['handover'] && <div className='mb-2'><small className="text-danger">// {this.state.errors['handover']}</small></div>}
                                {this.state.errors['conclusion'] && <div className='mb-2'><small className="text-danger">// {this.state.errors['conclusion']}</small></div>}
                                {!exit.exit_completed && <CTooltip content={'Hoàn tất thủ tục thôi việc nhân sự: ' + employee.fullname + ' (' + employee.employee_id + ')'}>
                                    <CButton
                                        type="submit"
                                        color="primary"
                                    >
                                        <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                        Hoàn Tất
                                    </CButton>
                                </CTooltip>}
                                {exit.exit_completed && <CPopover content={'Đã hoàn tất thủ tục thôi việc nhân sự: ' + employee.fullname + ' (' + employee.employee_id + ')'}>
                                    <CButton
                                        type="button"
                                        color="success"
                                        readOnly={true}
                                    >
                                        <CIcon name="cil-check" className="mfe-1 mt-0"></CIcon>
                                        Đã Hoàn Tất
                                    </CButton>
                                </CPopover>}
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default ExitConclusion;

