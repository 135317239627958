import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';

const TheHeaderDropdownTasks = (props) => {
  const tasks = props.tasks;
  var _tasks = [];
  if (!helps.isEmpty(tasks) && tasks.length > 0) {
    _tasks = tasks.filter(task => task.status !== 'finished');
  }
  return (
    <CDropdown
      inNav
      className="c-header-nav-item mx-2"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CIcon name="cil-list" />
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end" className="pt-0">
        <CDropdownItem
          header
          tag="div"
          color="light"
        >
          <h4>Công Việc</h4>
        </CDropdownItem>
        {!helps.isEmpty(_tasks) && _tasks.length > 0 && _tasks.map((task, key) => {
          let priority = helps.getItemFromArr(configs.task_priorities, task.priority);
          return (
            <CDropdownItem key={task.id + '-' + key} className="d-block" onClick={() => { props.history.push('/tasks/view/' + task.id) }}>
              <div className="small mb-1 pb-2 border-bottom">
                <CBadge color={priority.color}>{priority.label}</CBadge>
                {task.title}
              </div>
            </CDropdownItem>
          );
        })
        }
        <CDropdownItem className="text-center border-top" onClick={() => { props.history.push('/tasks') }}><strong>Quản lý công việc</strong></CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdownTasks