export const fileConstants = {
    SET_PARAMS_REQUEST: 'FILES_SET_PARAMS_REQUEST',

    GET_LIST_REQUEST: 'FILES_LIST_REQUEST',
    GET_LIST_SUCCESS: 'FILES_LIST_SUCCESS',
    GET_LIST_FAILURE: 'FILES_LIST_FAILURE',

    GET_REQUEST: 'FILES_GET_REQUEST',
    GET_SUCCESS: 'FILES_GET_SUCCESS',
    GET_FAILURE: 'FILES_GET_FAILURE',

    UPLOAD_REQUEST: 'FILES_UPLOAD_REQUEST',
    UPLOAD_SUCCESS: 'FILES_UPLOAD_SUCCESS',
    UPLOAD_FAILURE: 'FILES_UPLOAD_FAILURE',

    UPDATE_REQUEST: 'FILES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'FILES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'FILES_UPDATE_FAILURE',

    DELETE_REQUEST: 'FILES_DELETE_REQUEST',
    DELETE_SUCCESS: 'FILES_DELETE_SUCCESS',
    DELETE_FAILURE: 'FILES_DELETE_FAILURE',
}