export const generalConstants = {
    SET_SIDEBAR: 'SET_SIDEBAR',
    SHOW_SIDEBAR: 'SHOW_SIDEBAR',
    HIDE_SIDEBAR: 'HIDE_SIDEBAR',

    GET_SUBNAV: 'GET_SUBNAV',
    UPDATE_SUBNAV: 'UPDATE_SUBNAV',

    ROLES_GET_ALL_REQUEST: 'ROLES_GET_ALL_REQUEST',
    ROLES_GET_ALL_SUCCESS: 'ROLES_GET_ALL_SUCCESS',
    ROLES_GET_ALL_FAILURE: 'ROLES_GET_ALL_FAILURE',
    ROLES_UPDATE_ROLES_REQUEST: 'ROLES_UPDATE_ROLES_REQUEST',
    ROLES_UPDATE_ROLES_SUCCESS: 'ROLES_UPDATE_ROLES_SUCCESS',
    ROLES_UPDATE_ROLES_FAILURE: 'ROLES_UPDATE_ROLES_FAILURE',

    APPROVALS_GET_ALL_REQUEST: 'APPROVALS_GET_ALL_REQUEST',
    APPROVALS_GET_ALL_SUCCESS: 'APPROVALS_GET_ALL_SUCCESS',
    APPROVALS_GET_ALL_FAILURE: 'APPROVALS_GET_ALL_FAILURE',
    APPROVALS_ADD_REQUEST: 'APPROVALS_ADD_REQUEST',
    APPROVALS_ADD_SUCCESS: 'APPROVALS_ADD_SUCCESS',
    APPROVALS_ADD_FAILURE: 'APPROVALS_ADD_FAILURE',
    APPROVALS_UPDATE_REQUEST: 'APPROVALS_UPDATE_REQUEST',
    APPROVALS_UPDATE_SUCCESS: 'APPROVALS_UPDATE_SUCCESS',
    APPROVALS_UPDATE_FAILURE: 'APPROVALS_UPDATE_FAILURE',
}