import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { helps } from 'src/_helpers';
import { housingActions } from 'src/_actions';
import { provinceActions } from 'src/_actions';
import HousingForm from 'src/views/housings/HousingForm';


class HousingsFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            housing: {},
            form_header: "Thêm Nhà Đội Mới",
            action_status: 'add',
        }
    }

    componentDidMount() {
        this.getHousings().then(() => {
            this.getHousing();
        });
        if (!this.props.provinces) {
            this.props.getProvinces();
        }
    }

    getHousing() {
        let housing_id = this.props.match.params.id;
        const housings = this.props.housings;
        if (housing_id) {
            let housing = housings.filter((_housing) => {
                return _housing.id === housing_id;
            });
            this.setState({ form_header: "Chỉnh Sửa Thông Tin Nhà Đội: " + housing[0].name, action_status: 'edit', housing: housing[0] });
        }
    }

    async getHousings() {
        const housings = this.props.housings;
        if (!housings) await this.props.getAll();
    }

    handleSubmit = (housing) => {
        let housing_id = this.props.match.params.id;
        var url = '/housings';
        if (helps.isEmpty(housing_id)) {
            this.props.add(housing);
        } else {
            this.props.update(housing);
        }
        this.props.history.push(url);
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        let housing_id = this.props.match.params.id;
        const { housing } = this.state;
        if (!helps.isEmpty(housing_id) && helps.isEmpty(housing)) return (<Loading></Loading>);

        return (
            <ThePermission {... this.props} component="housings" action="edit">
                <HousingForm
                    {... this.props}
                    housing={this.state.housing}
                    form_header={this.state.form_header}
                    action_status={this.state.action_status}
                    handleSubmit={this.handleSubmit}
                />
            </ThePermission>
        );
    }
}
function mapStateToProps(state) {
    const { loading, housings } = state.housingReducer;
    const { provinces } = state.provinceReducer;
    const { user } = state.authentication;
    return { loading, housings, provinces, user };
}

const actionCreators = {
    getAll: housingActions.getAll,
    add: housingActions.add,
    update: housingActions.update,
    getProvinces: provinceActions.getAll,
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(HousingsFormContainer);
export { connectedFormContainer as HousingsFormContainer };