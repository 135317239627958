import { db } from "src/dbConfigs";
import { helps } from "src/_helpers";
export const notificationService = {
    getAll,
    _delete,
    add,
    update,
    _read,
}

async function getAll(user, is_read = false, _limit = 1000) {
    try {
        const reads = localStorage.getItem('notifincations') ? JSON.parse(localStorage.getItem('notifincations')) : [];
        var read_ids = [];
        var notifications = [];
        if (!helps.isEmpty(reads) && reads.length > 0) {
            reads.map((notification) => {
                read_ids.push(notification.id);
            })
        }
        let ref = db.collection('notifications');
        var _query = {};
        if (!helps.isEmpty(user)) {
            if (is_read) {
                _query = ref.where('user_id', '==', user.id)
                    .orderBy("added_time", "desc")
                    .limit(_limit);
            } else {
                if (!helps.isEmpty(read_ids) && read_ids.length > 0) {
                    _query = ref.where('user_id', '==', user.id)
                        //.where('id', 'not-in', read_ids)
                        //.orderBy('id')
                        .orderBy("added_time", "desc")
                        .limit(_limit);
                } else {
                    _query = ref.where('user_id', '==', user.id)
                        .orderBy("added_time", "desc")
                        .limit(_limit);
                }

            }
        } else {
            if (is_read) {
                _query = ref.orderBy("added_time", "desc")
                    .limit(_limit);
            } else {
                _query = ref.where('id', 'not-in', read_ids)
                    .orderBy("added_time", "desc")
                    .limit(_limit);
            }
        }
        await _query.onSnapshot((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let notification = doc.data();
                if (!read_ids.includes(notification.id)) {
                    notification['added_time'] = notification['added_time'].toDate();
                    let found = helps.getItemFromArr(reads, notification.id);
                    if (!helps.isEmpty(found)) {
                        notification['is_read'] = true;
                    } else {
                        notification['is_read'] = false;
                    }
                    notifications.push(notification);
                }
            });
        });
        await Promise.all(
            notifications.map(async (notification) => {
                notification['added_time'] = notification['added_time'].toDate();
                let found = helps.getItemFromArr(reads, notification.id);
                if (!helps.isEmpty(found)) {
                    notification['is_read'] = true;
                } else {
                    notification['is_read'] = false;
                }
            })
        );
        return notifications;
    } catch (error) {
        return error;
    }
}

async function add(notification) {
    try {
        const docRef = await db.collection("notifications").add(notification);
        notification.id = docRef.id;
        update(notification);
        console.log("Firebase: notification has been added successfully!");
        return notification;
    } catch (error) {
        console.error("Error adding notification: ", error);
    }
}

async function update(notification) {
    var Ref = db.collection("notifications").doc(notification.id);

    try {
        await Ref.update(notification);
        console.log("Firebase: notification has been updated successfully!");
        return notification;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _delete(notification) {
    var Ref = db.collection("notifications").doc(notification.id);
    try {
        await Ref.delete();
        console.log("Firebase: notification has been deleted successfully!");
        return notification;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _read(notification) {
    console.log(notification)
    try {
        var reads = localStorage.getItem('notifincations') ? JSON.parse(localStorage.getItem('notifincations')) : [];
        const found = helps.getItemFromArr(reads, notification.id);
        if (helps.isEmpty(found)) {
            reads.push(notification);
        }
        localStorage.setItem('notifincations', JSON.stringify(reads));
        console.log("Storage: notification has been read successfully!");
        return notification;
    } catch (error) {
        console.error("Error read notification: ", error);
    }
}
