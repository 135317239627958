import { db } from "src/dbConfigs";
import { userService } from "./user.service";
import { employeeService } from "./employees/employee.service";
import { projectService } from "./project.service";
import { fileService } from 'src/_services';
import { helps } from "src/_helpers";

export const maneuverService = {
    getAll,
    _delete,
    add,
    update,
}

async function getAll(param = {}, _limit = 1000) {

    try {
        let ref = db.collection('maneuvers');
        var _query = {};
        var maneuvers = [];
        var maneuvers_from = [];
        var _query_from = {};
        //console.log(param.project)
        if (!helps.isEmpty(param) && !helps.isEmpty(param.project)) {
            _query = ref.where('project_id', '==', param.project.id)
                .orderBy("maneuver_time", "desc")
                .limit(_limit);
            _query_from = ref.where('from_project_id', '==', param.project.id)
                .orderBy("maneuver_time", "desc")
                .limit(_limit);
            const snapshot_from = await _query_from.get();
            maneuvers_from = snapshot_from.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
        } else if (!helps.isEmpty(param) && !helps.isEmpty(param.employee)) {
            _query = ref.where('employee_id', '==', param.employee.employee_id)
                .orderBy("maneuver_time", "desc")
                .limit(_limit);
        } else {
            _query = ref.orderBy("maneuver_time", "desc")
                .limit(_limit);
        }

        const snapshot = await _query.get();
        maneuvers = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        if (!helps.isEmpty(maneuvers_from) && maneuvers_from.length > 0) {
            maneuvers_from.filter(maneuver => maneuver.project_id !== param.project.id);
            maneuvers = maneuvers.concat(maneuvers_from);
            maneuvers.sort((a, b) => { return b.maneuver_time - a.maneuver_time });
        }
        if (!helps.isEmpty(maneuvers) && maneuvers.length > 0) {
            await Promise.all(
                maneuvers.map(async (maneuver) => {
                    if (maneuver.maneuver_time) {
                        maneuver['maneuver_time'] = maneuver['maneuver_time'].toDate();
                    }

                })
            );
        }
        return maneuvers;
    } catch (error) {
        return error;
    }
}

async function add(maneuver) {
    var upload_files = maneuver.attachment;
    try {
        if (!helps.isEmpty(upload_files) && typeof upload_files == 'object') {
            var _files = [];
            var _attachment = [];
            _files = await fileService.uploadFile(upload_files, 'maneuvers', maneuver.employee_id);

            if (!helps.isEmpty(_files) && _files.length > 0) {
                _files.map((file) => {
                    let _file = {
                        name: file.name,
                        url: file.url,
                    }
                    _attachment.push(_file);
                })
            }
            maneuver['attachment'] = _attachment;
        }

        const docRef = await db.collection("maneuvers").add(maneuver);

        maneuver.id = docRef.id;
        console.log(maneuver);

        console.log("Firebase: maneuver has been added successfully!");
        await update(maneuver);
        /*
        maneuver['maneuver_time'] = maneuver['maneuver_time'].toDate();
        
        let user = await userService.getById(maneuver.maneuver_by);
        maneuver['maneuver_by_alias'] = user.fullname + '-' + user.username;

        if (!helps.isEmpty(maneuver.from_project_id)) {
            let from_project = await projectService.getById(maneuver.from_project_id);
            maneuver['maneuver_from_project_name'] = from_project.name;
        }

        let project = await projectService.getById(maneuver.project_id);
        maneuver['maneuver_project_name'] = project.name;

        let employee = await employeeService.getById(maneuver.employee_id);
        maneuver['maneuver_employee_name'] = employee.fullname;
        maneuver['maneuver_employee_id'] = employee.employee_id;
        */
        return maneuver;

    } catch (error) {
        console.error("Error adding maneuver: ", error);
    }
}

async function update(maneuver) {
    var Ref = db.collection("maneuvers").doc(maneuver.id);

    try {
        await Ref.update(maneuver);
        console.log("Firebase: maneuver has been updated successfully!");
        return maneuver;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _delete(maneuver) {
    var Ref = db.collection("maneuvers").doc(maneuver.id);

    try {
        await Ref.delete();
        console.log("Firebase: maneuver has been deleted successfully!");
        return maneuver;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}
