import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CDataTable,
    CCollapse,
    CSelect,
    CTooltip,
    CInputRadio,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import TaxonomiesForm from './TaxonomiesForm';

class Taxonomies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            filter_category: '',
            modals: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSetDefault = this.handleSetDefault.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    handleChange(field, e) {
        let value = e.target.value;
        this.setState({
            [field]: value
        });
    }

    handleDelete(e, taxonomy) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Khoản Mục: ' + taxonomy.name + ' ?');
        if (_confirm) {
            this.props._delete(taxonomy);
        }
        return false;
    }

    handleSubmit(taxonomy) {
        if (helps.isEmpty(taxonomy.id)) {
            this.props.add(taxonomy);
            this.toggleModal('add');
        } else {
            this.props.update(taxonomy);
            this.toggleModal(taxonomy.id);
        }
    }

    handleSetDefault(e, taxonomy) {
        const { taxonomies } = this.props;
        let cur_taxonomies = taxonomies.filter((_taxonomy) => _taxonomy.category_id === taxonomy.category_id);
        if (!helps.isEmpty(cur_taxonomies) && cur_taxonomies.length > 0) {
            cur_taxonomies.map((_taxonomy) => {
                _taxonomy.is_default = false;
                this.props.updateField(_taxonomy, 'is_default');
            })
        }
        taxonomy['is_default'] = true;
        this.props.updateField(taxonomy, 'is_default');
        e.target.checked = true;
    }

    toggleModal = (item) => {
        let modals = this.state.modals;
        const position = modals.indexOf(item);
        let newItems = modals.slice();
        if (position !== -1) {
            newItems.splice(position, 1)
        } else {
            newItems = [...modals, item]
        }
        this.setState({ modals: newItems });

        console.log(modals);
    }

    render() {
        const { taxonomies, user, users } = this.props;
        if (taxonomies && taxonomies.length > 0) {
            taxonomies.map((taxonomy) => {

            })
        }
        var _taxonomies = taxonomies;
        if (!helps.isEmpty(this.state.filter_category)) {
            _taxonomies = _taxonomies.filter(tax => tax.category_id === this.state.filter_category);
        }
        const fields = [
            { key: 'code', label: 'Mã Khoản Mục', _classes: 'font-weight-bold', _style: { width: '20%' } },
            { key: 'name', label: 'Tên Khoản Mục', _classes: 'font-weight-bold', _style: { width: '30%' } },
            { key: 'category_id', label: 'Nhóm', _classes: 'font-weight-bold', _style: { width: '10%' } },
            { key: 'is_default', label: 'Mặc Định', _classes: 'text-center', _style: { width: '10%' } },
            { key: 'added_by', label: 'Bởi', _style: { width: '10%' } },
            { key: 'actions', label: 'Thao Tác', _classes: 'text-center', _style: { width: '15%' }, filter: false, sorter: false },
        ]
        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol xl={12} md={12} className="text-right">
                            <CButton type="button" color="primary" size="sm" onClick={() => { this.toggleModal('add') }}>
                                <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                Thêm Khoản Mục
                            </CButton>
                        </CCol>
                    </CRow>
                    <TaxonomiesForm {...this.props} modals={this.state.modals} taxonomy={{}} handleSubmit={this.handleSubmit} toggleModal={(item) => { this.toggleModal(item) }} />
                    <hr />
                    <div className='taxonomies-container'>
                        <CDataTable
                            items={_taxonomies}
                            fields={fields}
                            itemsPerPage={configs.per_page}
                            itemsPerPageSelect={configs.itemsPerPageSelect}
                            columnFilter
                            tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                            pagination
                            sorter
                            hover
                            columnFilterSlot={{
                                category_id: (
                                    <>
                                        <CSelect
                                            id='filter_category'
                                            name='filter_category'
                                            value={this.state.filter_category}
                                            onChange={(e) => { this.handleChange('filter_category', e) }}
                                        >
                                            <option value=''>- Tất cả -</option>
                                            {configs.acc_taxonomy_categories && configs.acc_taxonomy_categories.map((cat) => {
                                                return (
                                                    <option key={cat.id} value={cat.id}>{cat.label}</option>
                                                );
                                            })}
                                        </CSelect>
                                    </>
                                )
                            }}
                            scopedSlots={{
                                'category_id':
                                    (item) => {
                                        let category = helps.getItemFromArr(configs.acc_taxonomy_categories, item.category_id);
                                        return (
                                            <td className='font-weight-bold'>
                                                {category.label}
                                            </td>
                                        )
                                    },
                                'is_default':
                                    (item) => {
                                        return (
                                            <td className='text-center'>
                                                <CTooltip content={'Đặt mặc định: ' + item.name}>
                                                    <CInputRadio
                                                        key={item.id}
                                                        name={'is_default_' + item.category_id}
                                                        checked={item.is_default}
                                                        className={'cursor-pointer'}
                                                        onChange={(e) => { this.handleSetDefault(e, item) }}
                                                    >

                                                    </CInputRadio>
                                                </CTooltip>
                                            </td>
                                        )
                                    },
                                'added_by':
                                    (item) => {
                                        return (
                                            <td>
                                                {helps.getUserAlias(users, item.added_by)}
                                                <div>
                                                    <small>
                                                        <i>{helps.formatDateTime_from_Timestamp(item.added_time)}</i>
                                                    </small>
                                                </div>
                                            </td>
                                        )
                                    },
                                'actions':
                                    (item) => {
                                        let is_author = (user.id === item.added_by) ? true : false;
                                        let author = helps.getUserAlias(users, item.added_by);
                                        return (
                                            <td className="text-center">
                                                {is_author && <>
                                                    <CTooltip content={'Sửa: ' + item.name}>
                                                        <CButton type="button" size="sm" color="primary" title='Sửa' onClick={() => { this.toggleModal(item.id); }}>
                                                            <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                        </CButton>
                                                    </CTooltip>
                                                    <CTooltip content={'Xoá: ' + item.name + ' | thêm bởi: ' + author}>
                                                        <CButton
                                                            disabled={!is_author}
                                                            color="danger"
                                                            size="sm"
                                                            title="Xoá"
                                                            className="ml-1"
                                                            onClick={(e) => { this.handleDelete(e, item) }}
                                                        >
                                                            <CIcon name="cil-trash"></CIcon>
                                                        </CButton>
                                                    </CTooltip>
                                                </>}
                                            </td>
                                        )
                                    },
                                'details':
                                    (item) => {
                                        return (
                                            <TaxonomiesForm
                                                {...this.props}
                                                taxonomy={item}
                                                modals={this.state.modals}
                                                handleSubmit={this.handleSubmit}
                                                toggleModal={() => { this.toggleModal(item.id) }}
                                            />
                                        )
                                    }
                            }}
                        />
                    </div>
                </CCol>
            </CRow>
        )
    }
}


export default Taxonomies;
