import React, { Component } from "react";
import { read, utils, writeFile } from 'xlsx';
import {
    CButton, CCol, CRow,
    CTooltip,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { helps } from "src/_helpers";

export default class Export extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
            cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */,
        };
        this.handleExport = this.handleExport.bind(this);
    }

    componentDidMount() {
        var header = [];
        const { data } = this.props;
        var output_data = [];
        header[0] = '#';
        header[1] = 'Công Ty';
        header[2] = 'Email';
        header[3] = 'Điện Thoại';
        if (!helps.isEmpty(data) && data.length > 0) {
            data.map((_item, index) => {
                var item = [];
                item[0] = index + 1;
                item[1] = _item.name;
                item[2] = _item.email;
                item[3] = _item.phone;
                output_data.push(item);
            });
        }
        let _output = [];
        _output[0] = header;
        _output = _output.concat(output_data);
        console.log(_output)
        this.setState({ data: _output });
    }

    make_cols = refstr => {
        let o = [], C = utils.decode_range(refstr).e.c + 1;
        for (var i = 0; i < C; ++i) o[i] = { name: utils.encode_col(i), key: i }
        return o;
    };

    handleExport() {
        const { data } = this.props;
        /* convert state to workbook */
        const ws = utils.aoa_to_sheet(this.state.data);
        const wb = utils.book_new();
        let today = new Date();
        //var name = today.getDate() + '-' + today.getMonth() + '-' + today.getFullYear() + '_' + Date.now();
        var name = helps.convertToSlug(today.toLocaleDateString() + '-' + today.toLocaleTimeString()) + '__(' + data.length + ')';
        //console.log(name)
        utils.book_append_sheet(wb, ws, name);
        /* generate XLSX file and send to client */
        writeFile(wb, name + ".xlsx");
    }

    render() {
        return (
            <CRow>
                <CCol xl={12} md={12}>
                    <CTooltip content={'Export to Excel'}>
                        <CButton
                            color="info"
                            size="sm"
                            title="Export"
                            className="ml-1"
                            onClick={(e) => { this.handleExport(e) }}
                        >
                            <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                            Export
                        </CButton>
                    </CTooltip>
                </CCol>
            </CRow>
        );
    }
}