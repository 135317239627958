import { checkConstants } from "src/_constants";

export function checkReducer(state = { checks: [], check: {} }, actions) {
    switch (actions.type) {
        // GET LIST    
        case checkConstants.GET_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case checkConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                checks: actions.checks,
                loading: false,
            }
        case checkConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Add
        case checkConstants.ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case checkConstants.ADD_SUCCESS:
            state.checks.push(actions.check);
            return {
                ...state,
                check: actions.check,
                loading: false,
            }
        case checkConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Edit
        case checkConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case checkConstants.EDIT_SUCCESS:
            var _checks = state.checks;
            var foundIndex = _checks.findIndex(check => check.id === actions.check.id);
            _checks[foundIndex] = actions.check;
            console.log(foundIndex);
            return {
                ...state,
                check: actions.check,
                checks: _checks,
                loading: false,
            }
        case checkConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        // Delete 
        case checkConstants.DELETE_REQUEST:
            return {
                ...state,
                checks: state.checks.map(check =>
                    check.id === actions.check.id
                        ? { ...check, deleting: true }
                        : check
                )
            };
        case checkConstants.DELETE_SUCCESS:
            return {
                checks: state.checks.filter(check => check.id !== actions.check.id)
            };
        case checkConstants.DELETE_FAILURE:
            return {
                ...state,
                checks: state.checks.map(check => {
                    if (check.id === actions.check.id) {
                        const { deleting, ...checkCopy } = check;
                        return { ...checkCopy, deleteError: actions.error };
                    }
                    return check;
                })
            };

        default: return state;
    }
}