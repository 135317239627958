import React, { Component } from 'react'
import {
    CCard,
    CCardHeader,
    CCardBody,
    CCardFooter,
    CSelect,
    CTooltip,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import Loading from 'src/containers/_loading';

class ProjectEmployees extends Component {

    constructor(props) {
        super(props);

        this.state = {
            project_employees: [],
            project_employee: {},
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeShift = this.handleChangeShift.bind(this);
    }

    componentDidMount() {

    }

    handleChange(e, employee_id, maneuver_id) {
        const { project } = this.props;
        let project_employees = project.employees;
        let position_id = e.target.value;

        project_employees = project_employees.filter(_employee => _employee.employee_id !== employee_id);
        let project_employee = {
            employee_id: employee_id,
            maneuver_id: maneuver_id,
            position_id: position_id,
        };
        this.setState({ project_employee: project_employee });

        project_employees.push(project_employee);

        project['employees'] = project_employees;
        console.log(project_employees);
        this.props.updateProject(project);
    }

    handleChangeShift(e, employee_id, maneuver_id, position_id) {
        const { project } = this.props;
        let project_employees = project.employees;
        let shift_id = e.target.value;

        project_employees = project_employees.filter(_employee => _employee.employee_id !== employee_id);
        let project_employee = {
            employee_id: employee_id,
            maneuver_id: maneuver_id,
            position_id: position_id,
            shift_id: shift_id
        };
        project_employees.push(project_employee);

        project['employees'] = project_employees;
        console.log(project_employees);
        this.props.updateProject(project);
    }

    async handleUpdateProjectEmployees(e, employee) {
        e.preventDefault();
        const { project } = this.props;
        let project_employees = project.employees.filter(_employee => _employee.employee_id !== employee.employee_id);
        project['employees'] = project_employees;
        await this.props.updateField(project, 'employees');
        this.props.history.push(`/employees/view/${employee.employee_id}#exit`)
    }

    render() {
        const { project, employees, positions } = this.props;
        let project_positions = !helps.isEmpty(positions) ? positions.filter(position => position.project_id === project.id) : [];
        if (!helps.isEmpty(project.employees) && project.employees.length > 0) {
            project.employees.sort((a, b) => { return a.fullname - b.fullname });
        }
        let is_default = (configs.default_project_id === project.id) ? true : false;
        if (helps.isEmpty(project)) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <CCard>
                <CCardHeader>
                    Danh Sách Nhân Viên Trực Mục Tiêu
                </CCardHeader>
                <CCardBody>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th style={{ width: '5%' }}>#</th>
                                <th>Họ & Tên</th>
                                <th>Chức vụ</th>
                                <th style={{ width: '20%' }}>Vị trí trực</th>
                                <th style={{ width: '20%' }}>Ca trực</th>
                                {is_default && <th className='text-center'>X</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {helps.isEmpty(project.employees) && <tr><td colSpan={5}></td></tr>}
                            {!helps.isEmpty(project.employees) && project.employees.map((_employee, cnt) => {
                                let employee = helps.getItemFromArr(employees, _employee.employee_id, 'employee_id');
                                let position = helps.getItemFromArr(positions, _employee.position_id);
                                let shift = helps.getItemFromArr(position.shifts, _employee.shift_id);
                                return (
                                    <tr key={'project-employees-' + _employee.employee_id}>
                                        <td>{cnt + 1}</td>
                                        <td><b>{employee.fullname}</b><div>({employee.employee_id})</div></td>
                                        <td>{configs.positions[employee.position]}</td>
                                        <td>
                                            <CTooltip content={<>{position.name} {position.description ? position.description : ''}</>}>
                                                <CSelect
                                                    name='position_id'
                                                    value={_employee.position_id}
                                                    onChange={(e) => { this.handleChange(e, _employee.employee_id, _employee.maneuver_id) }}
                                                >
                                                    <option value=''>-- Vị trí --</option>
                                                    {project_positions && project_positions.map((position) => {
                                                        var description = '';
                                                        if (!helps.isEmpty(position.description)) {
                                                            description = ' - ' + position.description;
                                                        }
                                                        return (
                                                            <option value={position.id} key={_employee.employee_id + 'position_' + position.id}>
                                                                {position.name} {description}
                                                            </option>
                                                        );
                                                    })}
                                                </CSelect>
                                            </CTooltip>
                                        </td>
                                        <td>
                                            <CTooltip content={<>{shift.label} - {shift.start}-{shift.end}({shift.duration}/24)</>}>
                                                <CSelect
                                                    name='shift_id'
                                                    value={_employee.shift_id}
                                                    onChange={(e) => { this.handleChangeShift(e, _employee.employee_id, _employee.maneuver_id, position.id) }}
                                                >
                                                    <option value=''>-- Ca --</option>
                                                    {position.shifts && position.shifts.map((shift) => {
                                                        return (
                                                            <option value={shift.id} key={_employee.employee_id + 'shift_' + shift.id}>
                                                                {shift.label} - {shift.start}-{shift.end}({shift.duration}/24)
                                                            </option>
                                                        );
                                                    })}
                                                </CSelect>
                                            </CTooltip>
                                        </td>
                                        {is_default &&
                                            <td className='text-center'>
                                                <CTooltip
                                                    content='Làm thủ tục Thôi việc'
                                                >
                                                    <CButton
                                                        color="secondary"
                                                        size="sm"
                                                        className="ml-1"
                                                        onClick={(e) => { this.handleUpdateProjectEmployees(e, _employee) }}
                                                    >
                                                        <CIcon name="cil-exit-to-app"></CIcon>
                                                    </CButton>
                                                </CTooltip>
                                            </td>
                                        }
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </CCardBody>
                <CCardFooter>
                    {project.employees && <>Tổng số Nhân Viên: <b>{project.employees.length}</b></>}
                </CCardFooter>
            </CCard>
        )
    }
}
export default ProjectEmployees;
