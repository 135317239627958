import { positionConstants } from "src/_constants";
import { positionService } from "src/_services/position.service";
import { alertActions } from ".";

export const positionActions = {
    getAll,
    add,
    update,
    _delete,
}

function getAll(project_id = '') {
    return dispatch => {
        dispatch(request());
        positionService.getAll(project_id)
            .then(
                positions => dispatch(success(positions)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: positionConstants.GET_LIST_REQUEST } }
    function success(positions) { return { type: positionConstants.GET_LIST_SUCCESS, positions } }
    function failure(error) { return { type: positionConstants.GET_LIST_FAILURE, error } }
}

function add(position) {
    return dispatch => {
        dispatch(request(position));
        positionService.add(position)
            .then(
                position => {
                    dispatch(success(position));
                    dispatch(alertActions.success('Thêm mới Vị Trí thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(position) { return { type: positionConstants.ADD_REQUEST, position: position } }
    function success(position) { return { type: positionConstants.ADD_SUCCESS, position: position } }
    function failure(error) { return { type: positionConstants.ADD_FAILURE, error } }
}

function update(position) {
    return dispatch => {
        dispatch(request(position));
        positionService.update(position)
            .then(
                position => {
                    dispatch(success(position));
                    dispatch(alertActions.success('Chỉnh sửa Vị Trí thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(position) { return { type: positionConstants.EDIT_REQUEST, position: position } }
    function success(position) { return { type: positionConstants.EDIT_SUCCESS, position: position } }
    function failure(error) { return { type: positionConstants.EDIT_FAILURE, error } }
}

function _delete(position) {
    return dispatch => {
        dispatch(request(position));
        positionService._delete(position)
            .then(
                position => {
                    dispatch(success(position));
                    dispatch(alertActions.success('Xoá Vị Trí thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(position) { return { type: positionConstants.DELETE_REQUEST, position: position } }
    function success(position) { return { type: positionConstants.DELETE_SUCCESS, position: position } }
    function failure(error) { return { type: positionConstants.DELETE_FAILURE, error } }
}