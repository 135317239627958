import { helps } from 'src/_helpers';
import { storage } from '../dbConfigs';
import "firebase/storage";


export const documentService = {
    getAll,
    _delete,
    upload,
};

async function getAll(path = '') {
    var _dir = path ? path : 'documents';
    console.log(path);
    var _documents = [];
    var _folders = [];
    var prefixes = [];
    await storage.ref(_dir).listAll().then((res) => {
        _documents = res.items;
        prefixes = res.prefixes;
    }).catch((error) => {
        console.log(error);
    });
    if (_documents && _documents.length > 0) {
        await Promise.all(
            _documents.map(async (document) => {
                document['ext'] = helps.getExt(document.name);
                document['path'] = path;
                document['url'] = await document.getDownloadURL();
                document['_metadata'] = await document.getMetadata();
            })
        );
    }
    if (prefixes && prefixes.length > 0) {
        await Promise.all(
            prefixes.map(async (prefix) => {
                let folder = {};
                folder['name'] = prefix.name;
                folder['path'] = prefix.fullPath;
                _folders.push(folder);
            })
        );
    }
    return { documents: _documents, folders: _folders, prefixes: prefixes };
}

async function upload(documents, _cur_dir) {
    try {
        var _documents = [];
        let keys = [];
        if (typeof documents !== 'undefined' && documents !== null) {
            keys = Object.keys(documents);
        }
        await Promise.all(
            keys.map(async (_key) => {
                let document = documents[_key];
                //console.log(document);
                await storage.ref(`/${_cur_dir}/${document.name}`).put(document).then(async (snapshot) => {
                    //console.log(snapshot);
                    let _document = snapshot.ref;
                    await _document.getDownloadURL().then((url) => {
                        _document['url'] = url;
                    }).catch((error) => {
                        console.error(error);
                    });
                    await _document.getMetadata().then((metadata) => {
                        _document['_metadata'] = metadata;
                    });
                    _document['ext'] = helps.getExt(_document.name);
                    _document['dir'] = _cur_dir;
                    _documents.push(_document);
                });
            })
        );
        return _documents;
    } catch (error) {
        console.error("Error uploading documents: ", error);
    }
}

async function _delete(document) {
    const Ref = storage.ref(document['object']).child(document.name);
    try {
        await Ref.delete();
        console.log("Firebase: document has been deleted successfully!");
        return document;
    } catch (error) {
        console.error("Error deleting document: ", error);
    }
}
