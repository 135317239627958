import { provinceConstants } from "src/_constants";

const initialState = { crawling: [] };
export function provinceReducer(state = initialState, actions) {
    switch (actions.type) {
        // Get list 
        case provinceConstants.GET_LIST_REQUEST:
            return {
                loading: true,
            }
        case provinceConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                provinces: actions.provinces,
                loading: false,
            }
        case provinceConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }
        // Add
        case provinceConstants.ADD_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case provinceConstants.ADD_SUCCESS:
            state.provinces.push(actions.province);
            return {
                ...state,
                loading: false,
            }
        case provinceConstants.ADD_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }
        // Edit
        case provinceConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case provinceConstants.EDIT_SUCCESS:
            var _provinces = state.provinces;
            var foundIndex = _provinces.findIndex(province => province.id === actions.province.id);
            _provinces[foundIndex] = actions.province;
            console.log(foundIndex);
            return {
                ...state,
                provinces: _provinces,
                loading: false,
            }
        case provinceConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }
        // Delete 
        case provinceConstants.DELETE_REQUEST:
            return {
                ...state,
                provinces: state.provinces.map(province =>
                    province.id === actions.province.id
                        ? { ...province, deleting: true }
                        : province
                )
            };
        case provinceConstants.DELETE_SUCCESS:
            return {
                provinces: state.provinces.filter(province => province.id !== actions.province.id)
            };
        case provinceConstants.DELETE_FAILURE:
            return {
                ...state,
                provinces: state.provinces.map(province => {
                    if (province.id === actions.province.id) {
                        const { deleting, ...provinceCopy } = province;
                        return { ...provinceCopy, deleteError: actions.error };
                    }
                    return province;
                })
            };

        default: return state;
    }
}