import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { helps } from 'src/_helpers';
import { storeActions } from 'src/_actions';
import { provinceActions } from 'src/_actions';
import StoresForm from 'src/views/assets/StoresForm';


class StoresFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            store: {},
            form_header: "Thêm Kho Mới",
            action_status: 'add',
        }
    }

    componentDidMount() {
        this.getstores().then(() => {
            this.getstore();
        });
        if (!this.props.provinces) {
            this.props.getProvinces();
        }
    }

    getstore() {
        let store_id = this.props.match.params.id;
        const stores = this.props.stores;
        if (store_id) {
            let store = stores.filter((_store) => {
                return _store.id === store_id;
            });
            this.setState({ form_header: "Chỉnh Sửa Thông Tin Kho: " + store[0].name, action_status: 'edit', store: store[0] });
        }
    }

    async getstores() {
        const stores = this.props.stores;
        if (!stores) await this.props.getAll();
    }

    handleSubmit = (store) => {
        let store_id = this.props.match.params.id;
        var url = '/assets/stores';
        if (helps.isEmpty(store_id)) {
            this.props.add(store);
        } else {
            this.props.update(store);
        }
        this.props.history.push(url);
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        let store_id = this.props.match.params.id;
        const { store } = this.state;
        if (!helps.isEmpty(store_id) && helps.isEmpty(store)) return (<Loading></Loading>);

        return (
            <ThePermission {... this.props} component="assets" action="edit">
                <StoresForm
                    {... this.props}
                    store={this.state.store}
                    form_header={this.state.form_header}
                    action_status={this.state.action_status}
                    handleSubmit={this.handleSubmit}
                />
            </ThePermission>
        );
    }
}
function mapStateToProps(state) {
    const { loading, stores } = state.storeReducer;
    const { provinces } = state.provinceReducer;
    const { user } = state.authentication;
    return { loading, stores, provinces, user };
}

const actionCreators = {
    getAll: storeActions.getAll,
    add: storeActions.add,
    update: storeActions.update,
    getProvinces: provinceActions.getAll,
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(StoresFormContainer);
export { connectedFormContainer as StoresFormContainer };