import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CDataTable,
    CRow,
    CButton,
    CLink,
    CSelect,
    CCardFooter,
    CPopover,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import DatePicker from 'react-datepicker';
import locale from 'date-fns/locale/vi';
import InterviewCalendar from './InterviewCalendar';

class Candidates extends Component {

    constructor(props) {
        super(props);

        this.state = {
            gen: '',
            selected_interview_result: '',
            selected_interview_date: '',
            view: 'list',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChangeInterviewResult = this.handleChangeInterviewResult.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
    }

    componentDidMount() {
    }

    handleChangeInterviewResult(item, e) {
        const { candidates } = this.props;
        var candidate = helps.getItemFromArr(candidates, item.id);
        let value = e.target.value;
        candidate['interview_result'] = value;
        this.props.update(candidate);
    }

    handleChange(field, e) {
        this.setState({
            [field]: e.target.value
        });
    }

    handleChangeDate(field, date) {
        this.setState({
            [field]: date
        });
        //console.log(date)
    }

    handleDelete(e, item) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Ứng Viên: "' + item.fullname + '"?');
        if (_confirm) {
            this.props._delete(item);
        }
        return false;
    }

    render() {

        const { candidates, notes, user, users } = this.props;

        if (candidates && candidates.length > 0) {
            var _candidates = [];
            candidates.forEach((candidate, index) => {
                if (helps.isEmpty(candidate.interview_result)) {
                    candidate['interview_result'] = 'not_interviewed_yet';
                }
                _candidates[index] = candidate;
            });
        }

        if (!helps.isEmpty(this.state.gen)) {
            _candidates = _candidates.filter((item) => {
                return item.gen == this.state.gen;
            });
        }
        if (!helps.isEmpty(this.state.selected_interview_result)) {
            _candidates = _candidates.filter((item) => {
                return item.interview_result == this.state.selected_interview_result;
            });
        }
        if (this.state.selected_interview_date) {
            let idate = new Date(this.state.selected_interview_date);
            _candidates = _candidates.filter((candidate) => {
                let cur_date = new Date(candidate.interview_time);
                if (idate.getFullYear() === cur_date.getFullYear() && idate.getMonth() === cur_date.getMonth() && idate.getDate() === cur_date.getDate()) {
                    return true;
                } else {
                    return false;
                }
            });
        }
        const fields = [
            { key: 'index', label: '#', _style: { width: '1%' }, filter: false, sorter: false },
            { key: 'fullname', label: 'Họ & Tên', _classes: 'font-weight-bold', _style: { width: '15%' } },
            { key: 'gen', label: 'GT' },
            { key: 'phone', label: 'Điện Thoại', _classes: 'font-weight-bold' },
            { key: 'interview_time', label: 'Lịch Phỏng Vấn', _classes: 'font-weight-bold', _style: { width: '12%', minWidth: '12%' } },
            { key: 'interview_result', label: 'KQ Phỏng Vấn', _style: { width: '12%', minWidth: '12%' } },
            { key: 'interview_by', label: 'Người Phỏng Vấn', _style: { width: '12%', minWidth: '12%' }, filter: false },
            { key: 'birthyear', label: 'Năm Sinh', _style: { align: 'center' } },
            { key: 'height', label: 'Chiều Cao', _style: { align: 'center' }, filter: false },
            { key: 'weight', label: 'Cân Nặng', _style: { align: 'center' }, filter: false },
            { key: 'position', label: 'Vị trí Ứng tuyển' },
            { key: 'information', label: 'Thông Tin Thêm', filter: false, _style: { width: '12%', minWidth: '12%' } },
            { key: 'candidate_source', label: 'Kênh Tuyển Dụng' },
            { key: 'candidate_from', label: 'Nguồn Ứng Viên' },
            { key: 'added_by', label: 'Thêm Bởi', filter: false },
            { key: 'actions', label: 'Thao Tác', _style: { width: '13%', align: 'center' }, filter: false, sorter: false },
        ]
        const view = this.state.view;
        return (
            <CRow>
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol>
                                    Quản Lý Ứng Viên - {view === 'list' ? 'Danh Sách' : 'Lịch Phỏng Vấn'}
                                </CCol>
                                <CCol className={'text-right'}>
                                    <CTooltip content={"Danh sách Ứng viên"}>
                                        <CButton
                                            color={(view === 'list') ? 'primary' : "secondary"}
                                            size="sm"
                                            className="mr-2"
                                            onClick={() => { this.setState({ view: 'list' }) }}
                                        >
                                            <CIcon name="cil-list" className="mfe-1 mt-0"></CIcon>
                                        </CButton>
                                    </CTooltip>
                                    <CTooltip content={"Lịch phỏng vấn"}>
                                        <CButton
                                            color={(view === 'calendar') ? 'primary' : "secondary"}
                                            size="sm"
                                            className="mr-2"
                                            onClick={() => { this.setState({ view: 'calendar' }) }}
                                        >
                                            <CIcon name="cil-calendar" className="mfe-1 mt-0"></CIcon>
                                        </CButton>
                                    </CTooltip>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            {this.state.view === 'calendar' && <InterviewCalendar {... this.props} />}
                            {this.state.view === 'list' &&
                                <CDataTable
                                    items={_candidates}
                                    fields={fields}
                                    columnFilter
                                    tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                                    itemsPerPageSelect={configs.itemsPerPageSelect}
                                    itemsPerPage={configs.per_page}
                                    hover
                                    sorter
                                    pagination
                                    striped
                                    columnFilterSlot={{
                                        gen: (
                                            <>
                                                <CSelect
                                                    name="gen"
                                                    id="input-gen"
                                                    className="form-control form-control-sm"
                                                    value={this.state.gen}
                                                    onChange={(e) => { this.handleChange('gen', e) }}
                                                >
                                                    <option value="">-Tất cả-</option>
                                                    {configs.gens && Object.keys(configs.gens).map((_key) => {
                                                        return (
                                                            <option value={_key} key={'gen' + _key}>{configs.gens[_key]}</option>
                                                        )
                                                    }
                                                    )}
                                                </CSelect>
                                            </>
                                        ),
                                        interview_time: (
                                            <div>
                                                <DatePicker
                                                    showIcon
                                                    locale={locale}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.selected_interview_date}
                                                    onChange={(_date) => { this.handleChangeDate('selected_interview_date', _date) }}
                                                    className="form-control form-control-sm"
                                                    style={{ position: 'relative', zIndex: 99 }}
                                                />
                                            </div>
                                        ),
                                        interview_result: (
                                            <>
                                                <CSelect
                                                    name="selected_interview_result"
                                                    id="input-selected_interview_result"
                                                    className="form-control form-control-sm"
                                                    value={this.state.selected_interview_result}
                                                    onChange={(e) => { this.handleChange('selected_interview_result', e) }}
                                                >
                                                    <option value="">- Tất cả -</option>
                                                    {configs.gens && configs.interview_results.map((result) => {
                                                        return (
                                                            <option value={result.id} key={'selected_result_' + result.id} className={'pl-2 text-' + result.color}>- {result.label} -</option>
                                                        )
                                                    }
                                                    )}
                                                </CSelect>
                                            </>
                                        ),
                                    }}
                                    scopedSlots={{
                                        'index': (item, index) => {
                                            return (<td className="py-2 text-center">
                                                {index + 1}
                                            </td>)
                                        },
                                        'fullname': (item) => {
                                            var _notes = [];
                                            if (!helps.isEmpty(notes) && notes.length > 0) {
                                                _notes = notes.filter(note => note.object_id === item.id);
                                            }
                                            var note_content = '';
                                            if (!helps.isEmpty(_notes) && _notes.length > 0) {
                                                note_content = _notes.map((note) => {
                                                    return (
                                                        <div className='border-bottom mb-2 pb-2'>
                                                            <div dangerouslySetInnerHTML={{ __html: note.content }} /> <small>_<i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                                        </div>
                                                    );
                                                });
                                            }
                                            let interview_result = item.interview_result ? item.interview_result : 'not_interviewed_yet';
                                            let _the_result = helps.getItemFromArr(configs.interview_results, interview_result);
                                            return (
                                                <td className="py-2">
                                                    {!helps.isEmpty(note_content) &&
                                                        <CPopover
                                                            header='Ghi chú: '
                                                            content={note_content}
                                                        >
                                                            <CLink className={"text-" + _the_result.color} onClick={() => { this.props.history.push(`/employees/candidates/view/${item.id}`) }}>
                                                                <b>{item.fullname}</b>
                                                            </CLink>
                                                        </CPopover>
                                                    }
                                                    {helps.isEmpty(note_content) &&
                                                        <CLink className={"text-" + _the_result.color} onClick={() => { this.props.history.push(`/employees/candidates/view/${item.id}`) }}>
                                                            <b>{item.fullname}</b>
                                                        </CLink>
                                                    }
                                                </td>
                                            )
                                        },
                                        'gen': (item) => {
                                            return (
                                                <td className="py-2">
                                                    {configs.gens[item.gen]}
                                                </td>
                                            )
                                        },
                                        'interview_time': (item) => {
                                            return (
                                                <td className="py-2 font-weight-bold">
                                                    {item.interview_time && <>{helps.formatDateTime_from_Timestamp(item.interview_time)}</>}
                                                </td>
                                            )
                                        },
                                        'interview_result': (item) => {
                                            let interview_result = item.interview_result ? item.interview_result : 'not_interviewed_yet';
                                            let _the_result = helps.getItemFromArr(configs.interview_results, interview_result);
                                            let author_ids = [];
                                            if (!helps.isEmpty(item.added_by)) {
                                                author_ids.push(item.added_by);
                                            }
                                            if (!helps.isEmpty(item.interview_by) && item.interview_by.length > 0) {
                                                item.interview_by.forEach(_interview_by => {
                                                    let _employee_id = _interview_by.value;
                                                    let _user = helps.getItemFromArr(users, _employee_id, 'employee_id');
                                                    if (!helps.isEmpty(_user)) {
                                                        author_ids.push(_user.id);
                                                    }
                                                })
                                            }
                                            return (
                                                <td className="py-2">
                                                    <CTooltip content={_the_result.label}>
                                                        <CSelect
                                                            name='interview_result'
                                                            value={interview_result}
                                                            className={'text-' + _the_result.color}
                                                            onChange={(e) => { this.handleChangeInterviewResult(item, e) }}
                                                            disabled={!author_ids.includes(user.id)}
                                                            title={_the_result.label}
                                                        >
                                                            {configs.interview_results && configs.interview_results.map((result) => {
                                                                return (
                                                                    <option key={result.id} value={result.id} className={'text-' + result.color} title={result.label}>- {result.label} -</option>
                                                                )
                                                            })}
                                                        </CSelect>
                                                    </CTooltip>
                                                </td>
                                            )
                                        },
                                        'interview_by': (item) => {
                                            return (
                                                <td className="py-2">
                                                    {!helps.isEmpty(item.interview_by) && item.interview_by.length > 0 && item.interview_by.map((_by) => {
                                                        return (
                                                            <div key={_by.value}>{_by.label}</div>
                                                        );
                                                    })}
                                                </td>
                                            )
                                        },
                                        'position': (item) => {
                                            let position_text = '';
                                            if (!helps.isEmpty(item.position)) {
                                                if (item.position.length > 10) {
                                                    position_text = item.position.substr(0, 10) + '...';
                                                } else {
                                                    position_text = item.position;
                                                }
                                            }
                                            return (
                                                <td className="py-2">
                                                    {item.position && <CPopover content={item.position}><span>{position_text}</span></CPopover>}
                                                </td>
                                            )
                                        },
                                        'information': (item) => {
                                            let information_text = '';
                                            if (!helps.isEmpty(item.information)) {
                                                if (item.information.length > 30) {
                                                    information_text = item.information.substr(0, 30) + '...';
                                                } else {
                                                    information_text = item.information;
                                                }
                                            }
                                            return (
                                                <td className="py-2">
                                                    {item.information && <CPopover content={item.information}><span>{information_text}</span></CPopover>}
                                                </td>
                                            )
                                        },
                                        'candidate_from': (item) => {
                                            return (
                                                <td className="py-2">
                                                    {!helps.isEmpty(item.candidate_from) && <CLink onClick={() => { this.props.history.push('/employees/view/' + item.candidate_from.value) }} key={item.candidate_from.value} className='mr-2'>{item.candidate_from.label}</CLink>}
                                                </td>
                                            )
                                        },
                                        'added_by': (item) => {
                                            return (
                                                <td className="py-2">
                                                    {helps.getUserAlias(users, item.added_by)}
                                                    <div><small><i>{helps.formatDateTime_from_Timestamp(item.added_date)}</i></small></div>
                                                </td>
                                            )
                                        },
                                        'actions':
                                            (item) => {
                                                let is_author = (user.id === item.added_by) ? true : false;
                                                return (
                                                    <td className="py-2 text-center">
                                                        <ThePermission {... this.props} component="employees" action="edit">
                                                            <CTooltip content={'Cập nhật thông tin Ứng Viên: ' + item.fullname}>
                                                                <CButton
                                                                    color="primary"
                                                                    size="sm mr-1"
                                                                    title="Cập nhật thông tin"
                                                                    onClick={() => { this.props.history.push(`/employees/candidates/add_edit/${item.id}`) }}
                                                                >
                                                                    <CIcon name="cil-pen-nib"></CIcon>
                                                                </CButton>
                                                            </CTooltip>
                                                        </ThePermission>
                                                        <ThePermission {... this.props} component="employees" action="edit">
                                                            <CTooltip content={'Xoá Ứng Viên: ' + item.fullname}>
                                                                <CButton
                                                                    color={is_author ? "danger" : "secondary"}
                                                                    size="sm mr-1"
                                                                    title={!is_author ? "Bạn không có quyền xóa! UV được thêm bởi: " + helps.getUserAlias(users, item.added_by) : "Xoá"}
                                                                    disabled={!is_author}
                                                                    onClick={(e) => { this.handleDelete(e, item) }}
                                                                >
                                                                    <CIcon name="cil-trash"></CIcon>
                                                                </CButton>
                                                            </CTooltip>
                                                        </ThePermission>
                                                    </td>
                                                )
                                            },
                                    }}
                                />}
                        </CCardBody>
                        <CCardFooter>
                            Tổng: <b>{_candidates && <>{_candidates.length}</>}</b>
                        </CCardFooter>
                    </CCard>
                </CCol>
            </CRow>
        )
    }
}


export default Candidates;
