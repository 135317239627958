
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"


const firebaseConfig = {
  apiKey: "AIzaSyAYZNAEek1wNDEfUtE-eYB5SXbQgfxmhbo",
  authDomain: "sekin-360004.firebaseapp.com",
  projectId: "sekin-360004",
  storageBucket: "sekin-360004.appspot.com",
  messagingSenderId: "333175799312",
  appId: "1:333175799312:web:989c23b622d50d2ea75495",
  measurementId: "G-E7T3BT4QZC"
};

if (firebase.app.length) {
  firebase.initializeApp(firebaseConfig);
}
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
db.settings({ ignoreUndefinedProperties: true });
export default firebase;

