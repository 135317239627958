import { db } from "src/dbConfigs";
import { userService } from "./user.service";
import { helps } from "src/_helpers";
export const positionService = {
    getAll,
    getById,
    _delete,
    add,
    update,
}

async function getAll(project_id = '', _limit = 1000) {
    //console.log(project_id)
    try {
        let ref = db.collection('positions');
        var _query = {};
        if (!helps.isEmpty(project_id)) {
            _query = ref.where('project_id', '==', project_id)
                .limit(_limit);
        } else {
            _query = ref.limit(_limit);
        }
        const snapshot = await _query.get();

        const positions = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        await Promise.all(
            positions.map(async (position) => {
                let user = await userService.getById(position.added_by);
                position['added_by_alias'] = user.fullname + ' - ' + user.username;
                position['added_time'] = position['added_time'].toDate();
            })
        );

        return positions;
    } catch (error) {
        return error;
    }
}

async function getById(id) {
    try {
        const doc = await db.collection("positions").doc(id)
            .get();
        if (doc.exists) {
            return doc.data();
        } else {
            console.log("No such document!");
        }
    } catch (error) {
        console.log("Error getting document:", error);
    }
}

async function add(position) {
    try {
        const docRef = await db.collection("positions").add(position);
        position.id = docRef.id;
        update(position);
        console.log("Firebase: position has been added successfully!");
        return position;
    } catch (error) {
        console.error("Error adding position: ", error);
    }
}

async function update(position) {
    var Ref = db.collection("positions").doc(position.id);

    try {
        await Ref.update(position);
        console.log("Firebase: position has been updated successfully!");
        return position;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _delete(position) {
    var Ref = db.collection("positions").doc(position.id);

    try {
        await Ref.delete();
        console.log("Firebase: position has been deleted successfully!");
        return position;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}
