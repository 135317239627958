import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from 'src/containers/ThePermission';
import Candidates from 'src/views/employees/candidates/Candidates';

import { candidateActions, noteActions, userActions } from 'src/_actions';

class CandidatesContainer extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getCandidates();

        this.getNotes();

        this.getUsers();
    }

    async getNotes() {
        await this.props.getNotes('candidates');
    }

    async getCandidates() {
        await this.props.getAll();
    }

    async getUsers() {
        if (!this.props.users) {
            await this.props.getUsers();
        }
    }

    render() {
        /*
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        if (this.props.loading_notes) {
            return (
                <Loading></Loading>
            );
        }
        */
        return (
            <ThePermission {... this.props} component="employees" action="view">
                <Candidates {... this.props} />
            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { loading, candidates } = state.candidateReducer;
    const { projects } = state.projectReducer;
    const { notes } = state.noteReducer;
    const loading_notes = state.noteReducer.loading;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    return { loading, candidates, projects, notes, loading_notes, user, users };
}

const actionCreators = {
    getAll: candidateActions.getAll,
    setParams: candidateActions.setParams,
    getNotes: noteActions.getAll,
    _delete: candidateActions._delete,
    update: candidateActions.update,
    getUsers: userActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(CandidatesContainer);
export { connectedContainer as CandidatesContainer };