const configs = {
    app_name: "SEKIN",
    app_port: 3000,
    address: 'Số 10, Ngách 95/86, Phố Vũ Xuân Thiều, Long Biên, Hà Nội',
    tel: '024 6688 3236',
    dateFormat: "DD/MM/YYYY",
    timeFormat: "HH:mm",
    datetime_format: "DD/MM/YYYY HH:mm",
    weekday: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
    weekday_full: ["Chủ Nhật", "Thứ Hai", "Thứ Ba", "Thứ Tư", "Thứ Năm", "Thứ Sáu", "Thứ Bảy"],
    per_page: 30,
    itemsPerPageSelect: { label: 'Số mục trên mỗi trang:', values: [5, 10, 20, 30, 50, 100] },
    img_ext: ['png', 'jpg', 'jpeg', 'gif', 'svg', 'svgz', 'heic', 'heif', 'webp', 'tiff', 'tif', 'psd', 'bmp'],
    colors: {
        'primary': 'Chính',
        'secondary': 'Phụ',
        'success': 'Thành Công',
        'warning': 'Cảnh Báo',
        'info': 'Thông Tin',
        'danger': 'Nguy Hiểm',
        'dark': 'Tối',
        'light': 'Sáng',
    },
    branches: {
        HO: 'Trụ sở',
        CNHP: 'Chi nhánh Hải Phòng',
    },
    groups: {
        VP: 'Văn Phòng',
        MT: 'Mục Tiêu'
    },
    departments: {
        BOD: 'B.O.D',
        PNV: 'P.Nghiệp vụ',
        PHCKT: 'P.HC-KT',
        PSM: 'P. Sales - Marketing',
        CDTT: 'Nhân viên Cơ động',
        CDGS: 'Cơ động Giám sát',
        TVP: 'Trực VP',
        TMT: 'Trực MT'
    },
    positions: {
        CB: 'Cán bộ',
        CV: 'Chuyên viên',
        CH: 'Chỉ huy',
        DT: 'Đội trưởng',
        CT: 'Ca trưởng',
        NV: 'Nhân viên'
    },
    job_types: {
        NVCT: 'Chính thức',
        NVTV: 'Thời vụ',
        CTV: 'Cộng tác viên',
        DNV: 'Đã nghỉ việc',
    },
    id_types: {
        CCCD: 'CCCD',
        CMT: 'CMT',
        HC: 'Hộ Chiếu'
    },
    gens: {
        NAM: 'Nam',
        NU: 'Nữ'
    },
    employee_certificates: [
        { id: 'CC-NV', label: 'Chứng chỉ Nghiệp vụ', color: 'primary' },
        { id: 'CC-NV-PCCC', label: 'Chứng chỉ Nghiệp vụ & PCCC', color: 'primary' },
        { id: 'CC-PCCC-CNCH', label: 'Chứng chỉ PCCC & Cứu nạn Cứu hộ', color: 'primary' },
        { id: 'CC-SCC-CB', label: 'Chứng chỉ Sơ cấp cứu cơ bản', color: 'primary' },
        { id: 'CC-CCHT', label: 'Chứng chỉ Quản lý và Sử dụng Công cụ hỗ trợ', color: 'primary' },
    ],
    customer_types: {
        lead: 'Cơ Hội',
        potential: 'Tiềm Năng',
        customer: 'Khách Hàng',
    },
    role_objects: ['tasks', 'employees', 'admin-acc', 'accounting', 'administration', 'customers', 'projects', 'users', 'settings', 'documents', 'housings', 'vendors', 'assets'],
    role_actions: ['view', 'add', 'edit', 'delete'],
    role_groups: {
        'administrator': 'Quản Trị',
        'professional': 'Nghiệp Vụ',
        'accounting': 'Kế Toán',
        'administration': 'Hành Chính',
        'sales': 'Kinh Doanh',
        'staff': 'Nhân Viên',
        'partner': 'Đối Tác',
    },
    objects: {
        'customers': 'Khách Hàng',
        'candidates': 'Ứng Viên',
        'employees': 'Nhân Sự',
        'accounting': 'Kế Toán',
        'projects': 'Mục Tiêu',
        'tasks': 'Công Việc',
        'requests': 'Yêu Cầu',
        'vendors': 'Nhà Cung Cấp',
    },
    field_types: ['string', 'text', 'number', 'array', 'object', 'switch_on_off', 'switch_1_0', 'switch_true_false', 'switch_yes_no'],
    field_input_types: ['input-text', 'date', 'datetime', 'file', 'textarea', 'richtext', 'input-checkbox', 'input-radio', 'select-box'],
    shifts: [
        {
            id: 'day',
            label: 'Ngày',
            start: '07:00',
            end: '19:00',
            duration: 12,
            number_employees: 1,
        },
        {
            id: 'night',
            label: 'Đêm',
            start: '19:00',
            end: '07:00',
            duration: 12,
            number_employees: 1,
        },
        {
            id: '24',
            label: '24/24',
            start: '07:00',
            end: '07:00',
            duration: 24,
            number_employees: 1,
        }
    ],
    default_project_id: '9F9yhyDh3ReScZQ2Qxo1',
    contract_types: [
        { type: 'contract', label: 'Hợp Đồng', color: 'info' },
        { type: 'appendix', label: 'Phụ Lục', color: 'warning' },
        { type: 'agreement', label: 'Thoả Thuận', color: 'success' },
    ],
    task_repeats: {
        'daily': 'Hàng Ngày',
        'weekly': 'Hàng Tuần',
        'monthy': 'Hàng Tháng',
        'yearly': 'Hàng Năm',
    },
    task_priorities: [
        { id: 'low', label: 'Thấp', color: 'dark' },
        { id: 'normal', label: 'Bình Thường', color: 'primary' },
        { id: 'hight', label: 'Cao', color: 'warning' },
        { id: 'x-hight', label: 'Khẩn Cấp', color: 'danger' },
    ],
    task_assign_statuses: {
        'unapproved': "Chưa Nhận",
        'approved': "Đã Nhận",
    },
    task_statuses: {
        'unfinished': 'Chưa Hoàn Thành',
        'finished': "Đã Hoàn Thành"
    },
    acc_taxonomy_categories: [
        { id: 'account', label: 'Tài Khoản' },
        { id: 'group', label: 'Nhóm' },
        { id: 'section', label: 'Khoản' },
    ],
    interview_results: [
        { id: 'not_interviewed_yet', label: 'Chưa Phỏng vấn', color: 'dark' },
        { id: 'interviewed', label: 'Đã Phỏng vấn - Chờ xét tuyển', color: 'info' },
        { id: 'passed', label: 'Đã Phỏng vấn - Đạt', color: 'success' },
        { id: 'failed', label: 'Đã Phỏng vấn - Không Đạt', color: 'danger' },
        { id: 'not_come_yet', label: 'Không Tham gia Phỏng vấn', color: 'secondary' },
    ],
    asset_categories: [
        { id: 'QTT', label: 'Quân Tư Trang', color: 'primary', component: 'qtt' },
        { id: 'CCHT_DCHT', label: 'CCHT/DCHT', color: 'warning', component: 'ccht' },
        { id: 'PT', label: 'Phương Tiện', color: 'success', component: 'pt' },
        { id: 'TSCD', label: 'Tài Sản Cố Định', color: 'info', component: 'tscd' },
    ],
    asset_statuses: [
        { id: 'new_in_use', label: 'Mới, sử dụng tốt', color: 'success' },
        { id: 'new_unusable', label: 'Mới, không thể sử dụng', color: 'danger' },
        { id: 'old_in_use', label: "Cũ, sử dụng tốt", color: 'warning' },
        { id: 'old_unusable', label: "Cũ, không thể sử dụng", color: 'dark' },
    ],
    units: [
        { id: 'C', label: 'Chiếc' },
        { id: 'B', label: 'Bộ' },
        { id: 'KG', label: 'Kg' },
        { id: 'H', label: 'Hộp' },
        { id: 'T', label: 'Thùng' },
        { id: 'K', label: 'Kiện' },
        { id: 'L', label: 'Lít' },
    ],
    asset_put_solutions: [
        { id: 'storage', label: 'Lưu kho', color: 'success' },
        { id: 'sell_off', label: 'Bán thanh lý', color: 'danger' },
    ],
    inventory_alert: 5,
    employees_columns: [
        { id: 'job_type', label: 'Loại Hình' },
        { id: '_joint_date', label: 'Ngày Vào Cty', filter: false, sorter: true },
        { id: 'group', label: 'Khối' },
        { id: 'department', label: 'Bộ Phận' },
        { id: 'position', label: 'Chức Vụ' },
        { id: 'gen', label: 'GT' },
        { id: 'phone', label: 'ĐT' },
        { id: 'project', label: 'Mục Tiêu', _style: { width: '15%' } },
        { id: '_birthdate', label: 'Năm Sinh', filter: false },
        { id: 'height', label: 'Chiều Cao', _style: { align: 'center' }, filter: false },
        { id: 'weight', label: 'Cân Nặng', _style: { align: 'center' }, filter: false },
        { id: 'id_number', label: 'Số CCCD' },
        { id: '_bank', label: 'TK Ngân Hàng', filter: false },
        { id: 'permanent_address', label: 'Thường Trú', _style: { width: '20%' } },
        { id: 'contracts', label: 'HĐLĐ', _style: { width: '10%' } },
        { id: 'recruited_by_employee', label: 'Tuyển Dụng Bởi', _style: { width: '20%' } },
    ],
    employee_exit_conditions: [
        {
            id: 'TH1',
            label: 'Nghỉ trước thời hạn đơn',
            description: 'Có trách nhiệm hoàn thành tốt công việc trong thời gian hiệu lực của đơn. Được xét duyệt vào chế độ nghỉ trước thời hạn đơn.',
            salary: '100%',
        },
        {
            id: 'TH2',
            label: 'Nghỉ trước thời hạn đơn',
            description: 'Thiếu trách nhiệm hoàn thành tốt công việc trong thời gian hiệu lực của đơn. Được xét duyệt vào chế độ nghỉ trước thời hạn đơn.',
            salary: '30%',
        },
        {
            id: 'TH3',
            label: 'Nghỉ trước thời hạn đơn',
            description: 'Thiếu trách nhiệm hoàn thành tốt công việc trong thời gian hiệu lực của đơn. Không được xét duyệt vào chế độ nghỉ trước thời hạn đơn.',
            salary: '0%',
        },
        {
            id: 'TH4',
            label: 'Làm việc đủ 30 ngày hiệu lực đơn',
            description: 'Đủ thời hạn đơn',
            salary: '100%',
        },
        {
            id: 'TH5',
            label: 'Trường hợp nghỉ việc không đơn, bỏ việc ngang',
            description: 'Không nằm trong các trường hợp gặp vấn đề đặc biệt vệ gia cảnh, sức khỏe,...',
            salary: '0%',
        },
        {
            id: 'TH6',
            label: 'Trường hợp nghỉ việc không đơn',
            description: 'Nằm trong các trường hợp gặp vấn đề đặc biệt vệ gia cảnh, sức khỏe,... Có giải trình và đã được xác minh và xét duyệt có được hưởng lương, theo từng trường hợp cụ thể.',
            salary: '70%',
        },
        {
            id: 'TH7',
            label: 'Bị sa thải',
            description: 'Cố ý vi phạm nghiêm trọng các nội quy, quy định của công ty cũng như các nội quy, quy định tại mục tiêu.',
            salary: '0%',
        },
        {
            id: 'TH8',
            label: 'Bị sa thải',
            description: 'Cố ý vi phạm nghiêm trọng các nội quy, quy định của công ty cũng như các nội quy, quy định tại mục tiêu. Nhưng do bị tác động bởi các yêu tố ngoại cảnh, có biên bản tường trình.',
            salary: '50%',
        },
        {
            id: 'TH9',
            label: 'Cho thôi việc',
            description: 'Cho thôi việc vì các lý do khách quan hoặc chủ quan.',
            salary: '100%',
        },
    ],
    acc_objects: {
        'employees': 'Nhân Sự',
        'customers': 'Khách Hàng',
        'projects': 'Mục Tiêu',
        'vendors': 'Nhà Cung Cấp',
        'housings': 'Nhà Đội',
        'other': '- Khác -',
    },
    acc_period: {
        'one_time': 'Một lần',
        'contract': 'Hợp đồng',
        'time_arrangement': 'Khoảng thời gian',
    },
    recruitment_kpi: {
        recruiter_kpi: 10,
        none_recruiter_kpi: 3,
        ratio: 0.8,
    }
}
export default configs;