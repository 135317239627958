export const notificationConstants = {
    GET_LIST_REQUEST: 'NOTIFICATIONS_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'NOTIFICATIONS_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'NOTIFICATIONS_GET_LIST_FAILURE',

    ADD_REQUEST: 'NOTIFICATIONS_ADD_REQUEST',
    ADD_SUCCESS: 'NOTIFICATIONS_ADD_SUCCESS',
    ADD_FAILURE: 'NOTIFICATIONS_ADD_FAILURE',

    EDIT_REQUEST: 'NOTIFICATIONS_EDIT_REQUEST',
    EDIT_SUCCESS: 'NOTIFICATIONS_EDIT_SUCCESS',
    EDIT_FAILURE: 'NOTIFICATIONS_EDIT_FAILURE',

    DELETE_REQUEST: 'NOTIFICATIONS_DELETE_REQUEST',
    DELETE_SUCCESS: 'NOTIFICATIONS_DELETE_SUCCESS',
    DELETE_FAILURE: 'NOTIFICATIONS_DELETE_FAILURE',

    READ_REQUEST: 'NOTIFICATIONS_READ_REQUEST',
    READ_SUCCESS: 'NOTIFICATIONS_READ_SUCCESS',
    READ_FAILURE: 'NOTIFICATIONS_READ_FAILURE',
}