import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CCallout,
    CCollapse,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import NotesForm from './NotesForm';

class Notes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            object: '',
            object_id: '',
            note_time: '',
            content: '',
            showAddForm: false,
            edit_forms: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancelForm = this.handleCancelForm.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
    }

    handleCancelForm() {
        this.setState({ showAddForm: false });
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
    }

    handleDelete(e, note) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Ghi Chú: ' + note.content + ' ?');
        if (_confirm) {
            this.props._delete(note);
        }
        return false;
    }

    handleSubmit(note) {
        if (helps.isEmpty(note.id)) {
            this.props.add(note);
            this.handleCancelForm();
        } else {
            this.props.update(note);
            this.toggleEditForms(note);
        }
    }

    toggleEditForms = (item) => {
        let forms = this.state.edit_forms;
        const position = forms.indexOf(item.id);
        let newForms = forms.slice();
        if (position !== -1) {
            newForms.splice(position, 1)
        } else {
            newForms = [...forms, item.id]
        }
        this.setState({ edit_forms: newForms });
    }

    render() {
        const { notes, user, users } = this.props;
        let _notes = [];
        if (!helps.isEmpty(notes)) {
            _notes = notes.sort(function (a, b) {
                return new Date(b.note_time).getTime() - new Date(a.note_time).getTime()
            });
        }

        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol xl={12} md={12} className="text-right">
                            <CButton type="button" color="primary" size="sm" onClick={() => { this.setState({ showAddForm: !this.state.showAddForm }) }}>
                                <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                Thêm Ghi Chú
                            </CButton>
                        </CCol>
                    </CRow>
                    {this.state.showAddForm && <NotesForm {...this.props} handleSubmit={this.handleSubmit} handleCancelForm={() => { this.handleCancelForm() }} />}
                    <hr />
                    <div className='notes-container'>
                        {!helps.isEmpty(_notes) && _notes.length > 0 && _notes.map((note) => {
                            let is_author = (user.id == note.note_by) ? true : false;
                            let author = helps.getUserAlias(users, note.note_by);
                            let color = note.color ? note.color : 'warning';
                            return (
                                <CCallout key={note.id} color={color} className={'bg-white'}>
                                    <CRow className="mb-2">
                                        <CCol>{note.content}</CCol>
                                    </CRow>
                                    {is_author && <CCollapse show={this.state.edit_forms.includes(note.id)} key={"details_" + note.id} style={{ overflow: 'hidden' }}>
                                        <NotesForm
                                            {...this.props}
                                            note={note}
                                            handleSubmit={this.handleSubmit}
                                            toggleEditForms={() => { this.toggleEditForms(note) }}
                                            handleCancelForm={() => { this.handleCancelForm() }}
                                        />
                                    </CCollapse>}
                                    <CRow>
                                        <CCol xl={6} md={6}>
                                            <small className='text-muted font-italic'>{helps.formatDateTime_from_Timestamp(new Date(note.note_time))} | <i className="font-weight-bold">{author && <>{author}</>}</i></small>
                                        </CCol>
                                        <CCol xl={6} md={6}>
                                            <div className='text-right'>
                                                <CTooltip content={'Sửa Ghi chú'}>
                                                    <CButton
                                                        type="button"
                                                        size="sm"
                                                        color={is_author ? "primary" : "secondary"}
                                                        disabled={!is_author}
                                                        title='Sửa'
                                                        onClick={() => { this.toggleEditForms(note); }}
                                                    >
                                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                    </CButton>
                                                </CTooltip>
                                                <CTooltip content={'Xoá Ghi chú'}>
                                                    <CButton
                                                        color={is_author ? "danger" : "secondary"}
                                                        size="sm"
                                                        title="Xoá"
                                                        className="ml-1"
                                                        disabled={!is_author}
                                                        onClick={(e) => { this.handleDelete(e, note) }}
                                                    >
                                                        <CIcon name="cil-trash"></CIcon>
                                                    </CButton>
                                                </CTooltip>
                                            </div>
                                        </CCol>
                                    </CRow>
                                    <hr />
                                </CCallout>
                            );
                        })}
                    </div>
                </CCol>
            </CRow>
        )
    }
}


export default Notes;
