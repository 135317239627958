import { projectConstants } from 'src/_constants';
import { projectService } from '../_services';
import { alertActions } from '.';

export const projectActions = {
    setParams,
    getAll,
    add,
    getById,
    update,
    updateField,
};

function setParams(params = {}) {
    return dispatch => {
        dispatch({ type: projectConstants.SET_PARAMS_REQUEST, params });
    }
}

function getAll(active = 1) {
    return dispatch => {
        dispatch(request());
        return projectService.getAll(active)
            .then(
                projects => {
                    dispatch(success(projects, active));
                    return projects;
                },
                error => {
                    dispatch(failure(error.toString()), active);
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(active) { return { type: projectConstants.GETALL_REQUEST, active } }
    function success(projects, active) { return { type: projectConstants.GETALL_SUCCESS, projects, active } }
    function failure(error, active) { return { type: projectConstants.GETALL_FAILURE, error, active } }
}

function getById(id) {

    return dispatch => {
        dispatch(request());
        projectService.getById(id)
            .then(
                project => dispatch(success(project)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: projectConstants.GET_REQUEST } }
    function success(project) { return { type: projectConstants.GET_SUCCESS, project } }
    function failure(error) { return { type: projectConstants.GET_FAILURE, error } }
}

function add(project) {
    return dispatch => {
        dispatch(request(project));

        projectService.add(project)
            .then(
                project => {
                    dispatch(success(project));
                    dispatch(alertActions.success('Thêm mới Mục Tiêu thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(project) { return { type: projectConstants.ADD_REQUEST, project } }
    function success(project) { return { type: projectConstants.ADD_SUCCESS, project } }
    function failure(error) { return { type: projectConstants.ADD_FAILURE, error } }
}

function update(project) {
    return dispatch => {
        dispatch(request(project));
        projectService.update(project)
            .then(
                project => {
                    dispatch(success(project));
                    dispatch(alertActions.success('Cập nhật thông tin Mục Tiêu thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(project) { return { type: projectConstants.UPDATE_REQUEST, project } }
    function success(project) { return { type: projectConstants.UPDATE_SUCCESS, project } }
    function failure(error) { return { type: projectConstants.UPDATE_FAILURE, error } }
}

function updateField(project, field) {
    return dispatch => {
        dispatch(request(project));
        projectService.updateField(project, field)
            .then(
                project => {
                    dispatch(success(project, field));
                    dispatch(alertActions.success('Cập nhật thông tin Mục Tiêu thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request(project, field) { return { type: projectConstants.UPDATE_FIELD_REQUEST, project, field } }
    function success(project, field) { return { type: projectConstants.UPDATE_FIELD_SUCCESS, project, field } }
    function failure(error) { return { type: projectConstants.UPDATE_FIELD_FAILURE, error } }
}



