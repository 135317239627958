import { db } from '../../dbConfigs';
import { userService } from '../user.service';

export const candidateService = {
    getAll,
    add,
    getById,
    update,
    _delete,
};

async function getAll(isForced = false) {
    const snapshot = await db.collection('candidates')
        .orderBy('added_date', 'desc')
        .get();
    const candidates = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));
    await Promise.all(
        candidates.map(async (candidate) => {
            if (candidate.added_date) {
                let _date = candidate['added_date'];
                candidate['added_date'] = _date.toDate();
            }
            if (candidate.interview_time) {
                let _date = candidate['interview_time'];
                candidate['interview_time'] = _date.toDate();
            }
            if (candidate.added_by) {
                let user = await userService.getById(candidate.added_by);
                candidate['added_by_alias'] = user.fullname + '-' + user.username;
            }

        })
    );

    return candidates;
}

async function getById(id, isForced = false) {
    try {
        const doc = await db.collection("candidates").doc(id)
            .get();
        if (doc.exists) {
            return doc.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        console.log("Error getting document:", error);
    }
}

async function add(candidate) {
    try {
        const docRef = await db.collection("candidates").add(candidate);
        candidate['id'] = docRef.id;
        console.log("Firebase: candidate has been added successfully!");
        update(candidate);
        return candidate;
    } catch (error) {
        console.error("Error adding candidate: ", error);
    }
}

async function update(candidate) {
    let dbRef = db.collection("candidates").doc(candidate.id);

    try {
        //console.log(candidate);
        await dbRef.update(candidate).then(() => {
            console.log("Firebase: candidate has been updated successfully!");
        });
        if (candidate.added_by) {
            let user = await userService.getById(candidate.added_by);
            candidate['added_by_alias'] = user.fullname + '-' + user.username;
        }
        return candidate;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function _delete(candidate) {
    try {
        await db.collection("candidates").doc(candidate.id).delete().then((candidate) => {
            console.log("Firebase: candidate has been deleted successfully!");
            return candidate;
        });
    } catch (error) {
        console.error("Error deleting Tag: ", error);
    }
}
