import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CLink,
    CInput,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import Editor from 'src/_components/Editor';

class TaskCommentsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            form_header: 'Thêm Thảo Luận',
            id: '',
            added_time: new Date(),
            added_by: '',
            content: '',
            attachment: '',
            old_attachment: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeEditor = this.handleChangeEditor.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChangeFiles = this.handleChangeFiles.bind(this);
    }

    componentDidMount() {
        const { comment } = this.props;
        //console.log(comment);
        if (!helps.isEmpty(comment)) {
            this.setState({
                form_header: 'Sửa Thảo Luận',
                id: comment.id,
                content: comment.content,
                added_time: comment.added_time,
                added_by: comment.added_by,
            });
        } else {
            const added_by = this.props.user.id;

            this.setState({
                errors: {},
                added_time: new Date(),
                added_by: added_by,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let comment = {
                id: this.state.id,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
                content: this.state.content,
                attachment: this.state.attachment,
            }
            this.props.handleSubmit(comment);
        } else {
            return false;
        }
    }

    handleChangeFiles(field, e) {
        let upload_files = e.target.files;
        this.setState({ [field]: upload_files });
        console.log(upload_files)
    }

    handleChangeEditor(field, value) {
        //console.log(field, value);
        this.setState({ [field]: value });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.content)) {
            formIsValid = false;
            errors["content"] = "Vui lòng nhập nội dung Thảo Luận";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCancel(e) {
        e.preventDefault();
        if (helps.isEmpty(this.state.id)) {
            this.props.handleCancelForm();
        } else {
            this.props.toggleEditForms();
        }

    }

    render() {
        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CLabel htmlFor="input-content">{this.state.form_header}:</CLabel>
                                <Editor
                                    {... this.props}
                                    name="content"
                                    id="input-content"
                                    defaultValue={this.state.content}
                                    handleChangeEditor={this.handleChangeEditor}
                                />
                                <small className="help-block text-danger">{this.state.errors['content']}</small>
                            </CCol>
                        </CRow>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CLabel htmlFor="input-attachment">Tài liệu đính kèm:</CLabel>
                                {this.state.old_attachment && this.state.old_attachment.length > 0 && this.state.old_attachment.map(file => {
                                    return (
                                        <ul key={'attachment-' + file.name} className='mt-2 mb-3'>
                                            <li><CLink href={file.url} target='_blank'>{file.name}</CLink></li>
                                        </ul>
                                    )
                                })}
                                <CInput
                                    type='file'
                                    name='attachment'
                                    id='input-attachment'
                                    multiple
                                    onChange={(e) => { this.handleChangeFiles('attachment', e) }}
                                />
                                <small className="help-block text-danger">{this.state.errors['attachment']}</small>
                            </CCol>
                        </CRow>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CButton type="submit" size='sm' color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                                <CButton type="button" size='sm' color="secondary" onClick={(e) => { this.handleCancel(e) }} className="ml-3">
                                    <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                    Huỷ
                                </CButton>
                            </CCol>
                        </CRow>
                    </CForm>
                </CCol>
            </CRow>
        )
    }
}
export default TaskCommentsForm;

