import { helps } from 'src/_helpers';
import { db } from 'src/dbConfigs';
import { asset_itemService } from './asset_item.service';
import { fileService } from '../file.service';

export const assetService = {
    getAll,
    add,
    getById,
    update,
    updateField,
    _delete,
    uploadFile,
};

async function getAll(params = {}) {
    var ref = db.collection('assets');
    var query = query = ref.orderBy('create_date', 'desc');
    if (!helps.isEmpty(params)) {
        if (!helps.isEmpty(params.employee_id)) {
            query = ref.where('employee_id', '==', params.employee_id).orderBy('create_date', 'desc');
        }
        if (!helps.isEmpty(params.vendor_id)) {
            query = ref.where('vendor_id', '==', params.vendor_id).orderBy('create_date', 'desc');
        }
        if (!helps.isEmpty(params.project_id)) {
            query = ref.where('project_id', '==', params.project_id).orderBy('create_date', 'desc');
        }
        if (!helps.isEmpty(params.store_id)) {
            query = ref.where('store_id', '==', params.store_id).orderBy('create_date', 'desc');
        }
        if (!helps.isEmpty(params.housing_id)) {
            query = ref.where('housing_id', '==', params.housing_id).orderBy('create_date', 'desc');
        }
    }
    const snapshot = await query.get();
    const assets = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));

    if (assets && assets.length > 0) {
        Promise.all(
            assets.map(async (asset, index) => {
                if (asset.create_date) {
                    var _date = asset['create_date'];
                    asset['create_date'] = _date.toDate();
                }
                if (asset.asset_date) {
                    var _asset_date = asset['asset_date'];
                    if (typeof _asset_date === 'object') {
                        asset['asset_date'] = _asset_date.toDate();
                    }
                }
            })
        );
    }

    return assets;
}

async function getById(id) {
    try {
        const doc = await db.collection("assets").doc(id)
            .get();
        if (doc.exists) {
            return doc.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        console.log("Error getting document:", error);
    }
}

async function add(asset) {
    try {
        var asset_items = asset.asset_items;
        delete asset.asset_items;
        const docRef = await db.collection("assets").add(asset);
        asset.id = docRef.id;
        update(asset);
        console.log("Firebase: asset has been added successfully!");
        if (!helps.isEmpty(asset_items) && asset_items.length > 0) {
            asset_items.map((item) => {
                item['asset_id'] = asset.id;
                asset_itemService.add(item);
            });
        }
        return asset;
    } catch (error) {
        console.error("Error adding asset: ", error);
    }
}

async function update(asset) {
    //console.log(asset);
    let dbRef = db.collection("assets").doc(asset['id']);

    try {
        var asset_items = asset.asset_items;
        delete asset.asset_items;

        //console.log(asset);
        await dbRef.update(asset).then(() => {
            console.log("Firebase: asset has been updated successfully!");
        });

        if (!helps.isEmpty(asset_items) && asset_items.length > 0) {
            asset_items.map((item) => {
                item['asset_id'] = asset.id;
                asset_itemService.add(item);
            });
        }

        return asset;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function updateField(asset, field) {
    //console.log(asset);
    let dbRef = db.collection("assets").doc(asset['id']);

    try {
        console.log(field, asset[field]);
        await dbRef.update({
            [field]: asset[field]
        }).then(() => {
            console.log("Firebase: asset field has been updated successfully!");
        });

        return asset;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function _delete(asset) {
    try {
        await db.collection("assets").doc(asset.id).delete().then((asset) => {
            return asset;
        });
    } catch (error) {
        console.error("Error: ", error);
    }
}

async function uploadFile(asset, upload_files, object, object_id) {
    console.log(asset, upload_files, object, object_id);

    try {
        if (!helps.isEmpty(upload_files) && upload_files instanceof FileList) {
            var _files = [];
            var _attachment = [];
            _files = await fileService.uploadFile(upload_files, object, object_id);
            if (!helps.isEmpty(_files) && _files.length > 0) {
                _files.map((file) => {
                    let _file = {
                        name: file.name,
                        url: file.url,
                    }
                    _attachment.push(_file);
                })
            }
            asset['attachment'] = _attachment;
        }
        updateField(asset, 'attachment');
        console.log("Firebase: asset attachment has been added successfully!");
        return asset;
    } catch (error) {
        console.error("Error uploading asset attachment: ", error);
    }
}
