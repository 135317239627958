import React, { Component } from 'react'
import {
    CRow,
    CCol,
    CButton,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';

class PUTexport extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.loadFile = this.loadFile.bind(this);
        this.generateDocument = this.generateDocument.bind(this);
    }

    componentDidMount() {
    }

    loadFile(url, callback) {
        PizZipUtils.getBinaryContent(url, callback);
    }

    generateDocument() {
        const { employees, data, user, items, stores } = this.props;
        const asset_date = data.asset_date ? new Date(data.asset_date) : new Date();
        const asset_day = helps.fixDigit(asset_date.getDate());
        const asset_month = helps.fixDigit(asset_date.getMonth() + 1);
        const employee = helps.getItemFromArr(employees, user.employee_id);
        const report_id = asset_day.toString() + asset_month.toString() + asset_date.getFullYear().toString().substring(-2);
        var selected_items = data.selected_items;
        if (!helps.isEmpty(selected_items)) {
            selected_items.map((item, index) => {
                let selected_item_status = helps.getItemFromArr(configs.asset_statuses, item.status);
                let selected_item = helps.getItemFromArr(items, item.item_id);
                let put_solution = helps.getItemFromArr(configs.asset_put_solutions, item.put_solution);
                item['index'] = index + 1;
                item['item_name'] = selected_item.name;
                item['item_code'] = selected_item.code;
                item['item_status'] = selected_item_status.label;
                item['put_reason'] = item.put_reason;
                item['put_solution'] = put_solution.label;
            })
        }
        let store = helps.getItemFromArr(stores, data.store_id);
        let tpl_url = '/templates/';
        tpl_url = tpl_url + 'tpl_huy.docx';
        this.loadFile(
            tpl_url,
            (error, content) => {
                if (error) {
                    throw error;
                }
                var zip = new PizZip(content);
                var doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });
                doc.setData({
                    report_id: report_id,
                    asset_day: asset_day.toString(),
                    asset_month: asset_month.toString(),
                    asset_year: asset_date.getFullYear(),
                    put_by_fullname: employee.fullname,
                    put_store_name: store.name,
                    put_store_branch: configs.branches[store.branch],
                    put_by_id: employee.employee_id + ' / ' + configs.departments[employee.department] + ' / ' + configs.branches[employee.branch],
                    items: selected_items,
                });
                try {
                    // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                    doc.render();
                } catch (error) {
                    // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                    function replaceErrors(key, value) {
                        if (value instanceof Error) {
                            return Object.getOwnPropertyNames(value).reduce(function (
                                error,
                                key
                            ) {
                                error[key] = value[key];
                                return error;
                            },
                                {});
                        }
                        return value;
                    }
                    console.log(JSON.stringify({ error: error }, replaceErrors));

                    if (error.properties && error.properties.errors instanceof Array) {
                        const errorMessages = error.properties.errors
                            .map(function (error) {
                                return error.properties.explanation;
                            })
                            .join('\n');
                        console.log('errorMessages', errorMessages);
                        // errorMessages is a humanly readable message looking like this :
                        // 'The tag beginning with "foobar" is unopened'
                    }
                    throw error;
                }
                var out = doc.getZip().generate({
                    type: 'blob',
                    mimeType:
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                }); //Output the document using Data-URI
                var file_name = 'SEKIN_BBHLK_' + employee.fullname.replace(' ', '_') + '_' + employee.employee_id + '_' + report_id + '.docx';

                saveAs(out, file_name);
            }
        );
    };

    render() {
        return (
            <CRow>
                <CCol>
                    <CTooltip content={'Tải về Biên Bản Hủy / Lưu Kho'}>
                        <CButton
                            type='button'
                            size='sm'
                            color="warning"
                            onClick={() => { this.generateDocument() }}
                        >
                            <CIcon name="cil-cloud-download" className="mfe-1 mt-0"></CIcon>
                            Biên Bản Hủy / Lưu Kho
                        </CButton>
                    </CTooltip>
                </CCol>
            </CRow>
        )
    }
}

export default PUTexport;
