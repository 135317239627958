import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import Violations from 'src/views/employees/violations/Violations';
import { helps } from 'src/_helpers';
import { projectActions, violationActions } from 'src/_actions';

class ViolationsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            violations: [],
        }
    }

    componentDidMount() {
        this.getProjects();
        this.getTypes();
        this.getViolations();
    }

    async getViolations() {
        return await this.props.getAll(this.props.employee);
    }

    async getTypes() {
        var { violation_types } = this.props;
        if (helps.isEmpty(violation_types)) {
            violation_types = await this.props.getAllTypes();
        }
        return violation_types;
    }

    async getProjects() {
        if (helps.isEmpty(this.props.projects)) {
            await this.props.getProjects();
        }
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <Violations {... this.props} />
        );
    }
}

function mapStateToProps(state) {
    const { loading, loading_type, violations, violation_types } = state.violationReducer;
    const { user } = state.authentication;
    const { projects } = state.projectReducer;
    const { users } = state.userReducer;
    return { loading, loading_type, user, violations, violation_types, projects, users };
}

const actionCreators = {
    getAll: violationActions.getAll,
    add: violationActions.add,
    update: violationActions.update,
    _delete: violationActions._delete,
    getAllTypes: violationActions.getAllTypes,
    getProjects: projectActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ViolationsContainer);
export { connectedContainer as ViolationsContainer };