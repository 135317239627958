import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CInput,
    CSelect,
    CFormGroup,
    CInputRadio,
    CBadge,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';

import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import Select from 'react-select';

class ApprovalsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            id: '',
            name: '',
            key: '',
            approvers: [],
            form_header: 'Thêm Phê Duyệt',
            approvers_options: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {
        const { approval, users } = this.props;

        var approvers_options = [];
        if (!helps.isEmpty(users)) {
            users.map((user) => {
                approvers_options.push({
                    value: user.id,
                    label: helps.getUserAlias(users, user.id),
                });
            });
        }
        this.setState({ approvers_options: approvers_options })


        if (!helps.isEmpty(approval)) {

            this.setState({
                id: approval.id,
                name: approval.name,
                key: approval.key,
                approvers: approval.approvers,
                form_header: 'Cập nhật Phê Duyệt',
            });
        }
        console.log(this.state)
    }

    handleSubmit(e) {
        e.preventDefault();
        let approval = {
            name: this.state.name,
            key: this.state.key,
            approvers: this.state.approvers,
        }

        if (!helps.isEmpty(this.state.id)) {
            approval['id'] = this.state.id;
        }

        if (this.handleValidation()) {
            this.props.handleSubmit(approval);
            this.handleClear();
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        let value = e.target.value;
        if (field === 'key') {
            value = helps.toNonAccentVietnamese(value);
            value = value.replace(/[^a-zA-Z ]/g, " ");
            value = value.replaceAll("  ", " ");
            value = value.replaceAll(" ", "_");
            value = value.toLowerCase();
        }
        fields[field] = value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [field]: selectedOptions });
        //console.log(this.state);
    }

    handleClear() {
        this.setState({
            name: '',
            key: '',
            approvers: [],
            form_header: 'Thêm Phê Duyệt',
            errors: {},
        });
    }

    handleValidation() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        //console.log(fields);

        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }

        if (!fields["key"]) {
            formIsValid = false;
            errors["key"] = "Cannot be empty";
        }

        this.setState({ errors: errors });

        return formIsValid;
    }

    render() {

        return (
            <CRow>
                <CCol xl={12}>
                    <CForm
                        onSubmit={(e) => this.handleSubmit(e)}
                        noValidate
                        validated={this.state.formIsValid}>
                        <CCard>
                            <CCardHeader>
                                {this.state.form_header}
                            </CCardHeader>
                            <CCardBody>

                                <div className="mb-3">
                                    <CLabel htmlFor="input-name">Tên:</CLabel>
                                    <CInput
                                        type="name"
                                        name="name"
                                        id="input-name"
                                        required
                                        value={this.state.name}
                                        onChange={(e) => { this.handleChange('name', e) }}
                                    />
                                    {this.state.errors['name'] && <small className="text-danger">{this.state.errors['name']}</small>}
                                </div>
                                <div className="mb-3">
                                    <CLabel htmlFor="input-key">Key:</CLabel>
                                    <CInput
                                        type="key"
                                        name="key"
                                        id="input-key"
                                        required
                                        value={this.state.key}
                                        onChange={(e) => { this.handleChange('key', e) }}
                                    />
                                    {this.state.errors['key'] && <small className="text-danger">{this.state.errors['key']}</small>}
                                </div>
                                <div className="mb-3">
                                    <CLabel htmlFor="input-approvers">Người Phê Duyệt:</CLabel>
                                    <Select
                                        id="input-approvers"
                                        isMulti
                                        options={this.state.approvers_options}
                                        value={this.state.approvers}
                                        onChange={(choice) => { this.handleChangeSelect(choice, 'approvers') }}
                                    />

                                </div>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                                <CButton type="button" color="secondary" className=" ml-2" onClick={() => { this.handleClear() }}>
                                    <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                    Xoá
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default ApprovalsForm;

