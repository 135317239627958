import React, { Component } from 'react';
import {
    CButton,
    CLink,
    CModal,
    CModalHeader,
    CMediaBody,
    CModalFooter,
    CContainer,
    CBadge,
    CRow,
    CCol,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import Hint from 'src/_components/Hint';



class Violation extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {
        const { violation, projects } = this.props;
        const employee = violation.employee;
        let project = {};
        if (!helps.isEmpty(violation.project_id)) {
            project = helps.getItemFromArr(projects, violation.project_id);
        }
        if (helps.isEmpty(employee)) return (<></>);
        return (
            <CModal
                size='xl'
                show={this.props.modals.includes(this.props.item)}
                onClose={() => { this.props.toggleModal(this.props.item) }}
                className='text-left'
            >
                <CModalHeader closeButton>
                    <div>
                        <h4>Vi Phạm: {violation.title}</h4>
                        <div className='clearfix'>
                            <CRow>
                                <CCol>
                                    <small className='text-muted'>Nhân viên: <b>{employee.fullname} {employee.employee_id}</b></small>
                                </CCol>
                            </CRow>
                        </div>
                    </div>
                </CModalHeader>
                <CMediaBody>
                    <CContainer className='table-responsive'>
                        <table className="table mt-2">
                            <tbody>
                                <tr>
                                    <td width={'30%'}>
                                        Mục tiêu:
                                    </td>
                                    <td width={'70%'}>
                                        <b>{!helps.isEmpty(project) && <>{project.name}</>}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td width={'30%'}>
                                        Ngày Vi phạm:
                                    </td>
                                    <td width={'70%'}>
                                        <b>{helps.formatDateTime_from_Timestamp(violation.violation_date)}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Danh mục Vi phạm:
                                    </td>
                                    <td>
                                        <b>{violation.violation_type.name}</b><Hint content={violation.violation_type.description} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Hình thức Xử lý:
                                    </td>
                                    <td>
                                        <CBadge color={violation.violation_term.color} className='p-1'>{violation.violation_term.title}</CBadge>
                                        <Hint content={violation.violation_term.description} />
                                        | Mức phạt: <b>{helps.formatCurrency(violation.fines)}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Nội dung Vi phạm:
                                    </td>
                                    <td>
                                        <div dangerouslySetInnerHTML={{ __html: violation.content }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Nguyên nhân / Lý do:
                                    </td>
                                    <td>
                                        {violation.reason}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Hậu quả / Thiệt hại:
                                    </td>
                                    <td>
                                        {violation.damages}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Ghi chú:
                                    </td>
                                    <td>
                                        <div dangerouslySetInnerHTML={{ __html: violation.note }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        BBVP / BBSV / Các văn bản QĐ:
                                    </td>
                                    <td>
                                        {violation.attachment && violation.attachment.length > 0 && violation.attachment.map(file => {
                                            return (
                                                <div key={'attachment-' + file.name + '-' + new Date().getTime()}>
                                                    <small><CIcon name='cil-file' size='sm'></CIcon> <CLink href={file.url} target='_blank'>{file.name}</CLink></small>
                                                </div>
                                            )
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </CContainer>
                </CMediaBody>
                <CModalFooter>
                    <CButton type="button" size='sm' color="secondary" onClick={this.props.toggleModal} className="ml-3">
                        <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                        Đóng
                    </CButton>
                </CModalFooter>
            </CModal >
        )
    }
}
export default Violation;
