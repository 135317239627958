import { housingConstants } from 'src/_constants';
import { housingService } from 'src/_services';
import { alertActions } from '.';

export const housingActions = {
    getAll,
    add,
    getById,
    update,
    updateField,
    _delete,
};


function getAll() {
    return dispatch => {
        dispatch(request());
        return housingService.getAll()
            .then(
                housings => {
                    dispatch(success(housings));
                    return housings;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: housingConstants.GETALL_REQUEST } }
    function success(housings) { return { type: housingConstants.GETALL_SUCCESS, housings } }
    function failure(error) { return { type: housingConstants.GETALL_FAILURE, error } }
}

function getById(id) {

    return dispatch => {
        dispatch(request());
        housingService.getById(id)
            .then(
                housing => dispatch(success(housing)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: housingConstants.GET_REQUEST } }
    function success(housing) { return { type: housingConstants.GET_SUCCESS, housing } }
    function failure(error) { return { type: housingConstants.GET_FAILURE, error } }
}

function add(housing) {
    return dispatch => {
        dispatch(request(housing));

        return housingService.add(housing)
            .then(
                housing => {
                    dispatch(success(housing));
                    dispatch(alertActions.success('Thêm mới Khách Hàng thành công!'));
                    return housing;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(housing) { return { type: housingConstants.ADD_REQUEST, housing } }
    function success(housing) { return { type: housingConstants.ADD_SUCCESS, housing } }
    function failure(error) { return { type: housingConstants.ADD_FAILURE, error } }
}

function update(housing) {
    return dispatch => {
        dispatch(request(housing));
        housingService.update(housing)
            .then(
                housing => {
                    dispatch(success(housing));
                    dispatch(alertActions.success('Cập nhật thông tin Nhà Đội thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(housing) { return { type: housingConstants.UPDATE_REQUEST, housing } }
    function success(housing) { return { type: housingConstants.UPDATE_SUCCESS, housing } }
    function failure(error) { return { type: housingConstants.UPDATE_FAILURE, error } }
}

function updateField(housing, field) {
    return dispatch => {
        dispatch(request(housing, field));
        housingService.updateField(housing, field)
            .then(
                housing => {
                    dispatch(success(housing));
                    dispatch(alertActions.success('Cập nhật thông tin Nhà Đội thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(housing, field) { return { type: housingConstants.UPDATE_FIELD_REQUEST, housing, field } }
    function success(housing) { return { type: housingConstants.UPDATE_FIELD_SUCCESS, housing } }
    function failure(error) { return { type: housingConstants.UPDATE_FIELD_FAILURE, error } }
}

function _delete(housing) {
    return dispatch => {
        dispatch(request(housing));
        return housingService._delete(housing)
            .then(
                housing => {
                    dispatch(success(housing));
                    dispatch(alertActions.success('Xoá Nhà Đội thành công!'));
                    return housing;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(housing) { return { type: housingConstants.DELETE_REQUEST, housing } }
    function success(housing) { return { type: housingConstants.DELETE_SUCCESS, housing } }
    function failure(error) { return { type: housingConstants.DELETE_FAILURE, error } }
}


