import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CDataTable,
    CCollapse,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import configs from 'src/appConfigs';
import ContactsForm from './ContactsForm';
import { ImportContactsForm } from './ImportContactsForm';


class Contacts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            showAddForm: false,
            showImportForm: false,
            edit_forms: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitImport = this.handleSubmitImport(this);
        this.handleCancelForm = this.handleCancelForm.bind(this);
        this.handleCancelImportForm = this.handleCancelImportForm.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
    }

    handleCancelForm() {
        this.setState({ showAddForm: false });
    }

    handleCancelImportForm() {
        this.setState({ showImportForm: false });
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
    }

    handleDelete(e, contact) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Liên Hệ: ' + contact.name + ' ?');
        if (_confirm) {
            this.props._delete(contact);
        }
        return false;
    }

    handleSubmitImport(contacts) {

    }
    handleSubmit(contact) {
        if (helps.isEmpty(contact.id)) {
            this.props.add(contact);
            this.handleCancelForm();
        } else {
            this.props.update(contact);
            this.toggleEditForms(contact);
        }
        console.log(contact);
        this.doAddUser();
    }

    doAddUser() {
        const { contact } = this.props;
        if (contact && contact.auto_generate_user) {
            var user = {};
            user['fullname'] = contact.name;
            user['username'] = helps.generateUsername(contact.name, contact.phone);
            user['password'] = helps.generatePassword();
            user['group'] = 'PARTNER';
            user['role'] = 'partner';
            user['contact_id'] = contact.id;
            this.props.addUser(user);
        }
    }

    toggleEditForms = (item) => {
        let forms = this.state.edit_forms;
        const position = forms.indexOf(item.id);
        let newForms = forms.slice();
        if (position !== -1) {
            newForms.splice(position, 1)
        } else {
            newForms = [...forms, item.id]
        }
        this.setState({ edit_forms: newForms });
    }

    render() {
        const { contacts, user, users } = this.props;
        const fields = [
            { key: 'index', label: '#', _style: { width: '50px', align: 'center' }, filter: false, sorter: false },
            { key: 'name', label: 'Tên', _classes: 'font-weight-bold', _style: { width: '20%' } },
            { key: 'position', label: 'Vị trí / Chức vụ' },
            { key: 'phone', label: 'Số ĐT', _classes: 'font-weight-bold', _style: { width: '15%' } },
            { key: 'email' },
            { key: 'description', label: 'Mô tả' },
            { key: 'added_by', label: 'Tạo Bởi', _style: { align: 'center' }, filter: false },
            { key: 'actions', label: 'Thao Tác', _style: { width: '10%', align: 'center' }, filter: false, sorter: false },
        ]
        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol xl={12} md={12} className="text-right mb-2">
                            <CButton type="button" color="primary" size="sm" onClick={() => { this.setState({ showAddForm: !this.state.showAddForm }) }}>
                                <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                Thêm Liên Hệ
                            </CButton>
                            {this.props.project_id && <CButton className='ml-2' type="button" color="warning" size="sm" onClick={() => { this.setState({ showImportForm: !this.state.showImportForm }) }}>
                                <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                Thêm Liên Hệ từ Khách Hàng
                            </CButton>}
                        </CCol>
                    </CRow>
                    {this.state.showImportForm && <ImportContactsForm
                        {...this.props}
                        status='import'
                        key='import_contact'
                        handleSubmitImport={this.handleSubmitImport}
                        handleCancelImportForm={() => { this.handleCancelImportForm() }}
                    />}
                    {this.state.showAddForm && <ContactsForm
                        {...this.props}
                        status='add'
                        key='add_contact'
                        handleSubmit={this.handleSubmit}
                        handleCancelForm={() => { this.handleCancelForm() }}
                    />}
                    <hr />
                    <div className='contacts-container table-responsive'>
                        <CDataTable
                            items={contacts}
                            fields={fields}
                            columnFilter
                            tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                            itemsPerPageSelect={configs.itemsPerPageSelect}
                            itemsPerPage={configs.per_page}
                            hover
                            sorter
                            pagination
                            scopedSlots={{
                                'index': (item, index) => {
                                    return (
                                        <td className="py-2 text-center">
                                            {index + 1}
                                        </td>
                                    )
                                },
                                'name': (item) => {
                                    return (
                                        <td className="py-2">
                                            <b>{item.name}</b>
                                        </td>
                                    )
                                },
                                'added_by': (item) => {
                                    return (
                                        <td className="py-2">
                                            <span>{helps.getUserAlias(users, item.added_by)}</span>
                                            <div><small>{helps.formatDateTime_from_Timestamp(item.added_time)}</small></div>
                                        </td>
                                    )
                                },
                                'actions':
                                    (item) => {
                                        let is_author = (user.id === item.added_by || user.role === 'administrator') ? true : false;
                                        return (
                                            <td className="py-2 text-center">
                                                <CTooltip content={'Chỉnh sửa liên hệ: ' + item.name}>
                                                    <CButton type="button" size="sm" color="primary" title='Sửa' onClick={() => { this.toggleEditForms(item); }}>
                                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                    </CButton>
                                                </CTooltip>
                                                <CTooltip content={'Xóa liên hệ: ' + item.name}>
                                                    <CButton
                                                        color={is_author ? "danger" : "secondary"}
                                                        size="sm"
                                                        title="Xoá"
                                                        className="ml-1"
                                                        disabled={!is_author}
                                                        onClick={(e) => { this.handleDelete(e, item) }}
                                                    >
                                                        <CIcon name="cil-trash"></CIcon>
                                                    </CButton>
                                                </CTooltip>
                                            </td>
                                        )
                                    },
                                'details':
                                    (item) => {
                                        return (
                                            <CCollapse show={this.state.edit_forms.includes(item.id)} key={"details_" + item.id} style={{ overflow: 'hidden' }}>
                                                <ContactsForm
                                                    {...this.props}
                                                    contact={item}
                                                    status='edit'
                                                    key={item.id}
                                                    handleSubmit={this.handleSubmit}
                                                    toggleEditForms={() => { this.toggleEditForms(item) }}
                                                    handleCancelForm={() => { this.handleCancelForm() }}
                                                />
                                            </CCollapse>
                                        );
                                    }
                            }}
                        />
                    </div>
                </CCol>
            </CRow>
        )
    }
}


export default Contacts;
