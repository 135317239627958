import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CTextarea,
    CInput,
    CLink,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CTooltip,
    CCollapse,
    CPopover,
    CImg,
    CSelect,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';

class EmployeeCertificatesForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            form_header: 'Thêm Chứng chỉ',
            id: '',
            name: '',
            added_time: new Date(),
            added_by: this.props.user.id,
            category_id: 'CC-NV',
            active: 0,
            note: '',
            attachment: [],
            show_existing_files: false,
            period: 12,
            upload_files: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFiles = this.handleChangeFiles.bind(this);
    }

    componentDidMount() {

        const { certificate } = this.props;

        if (!helps.isEmpty(certificate) && this.props.action === 'edit') {
            this.setState({
                form_header: 'Chỉnh Sửa Chứng chỉ: ' + certificate.name,
                id: certificate.id,
                name: certificate.name,
                note: certificate.note,
                category_id: certificate.category_id,
                added_time: certificate.added_time,
                added_by: certificate.added_by,
                active: certificate.active,
                attachment: certificate.attachment,
            });
        } else {
            let category = helps.getItemFromArr(configs.employee_certificates, this.state.category_id);
            //console.log(category)
            this.setState({ name: category.label });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let certificate = {
                id: this.state.id,
                name: this.state.name,
                note: this.state.note,
                category_id: this.state.category_id,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
                active: this.state.active,
                attachment: this.state.attachment,
                upload_files: this.state.upload_files,
            }
            //console.log(certificate);
            this.props.handleSubmit(certificate, this.props.action);
        } else {
            return false;
        }
    }

    handleChangeFiles(field, e) {
        let upload_files = e.target.files;
        this.setState({ [field]: upload_files });
    }

    handleChange(field, e) {
        let value = e.target.value;
        if (field === 'category_id') {
            let category = helps.getItemFromArr(configs.employee_certificates, value);
            this.setState({ name: category.label });
        }
        this.setState({
            [field]: value
        });
    }

    handleSelectAttachment(_file) {
        let attachment = this.state.attachment;
        attachment.push({
            name: _file.name,
            url: _file.url,
        });
        this.setState({
            attachment: attachment
        });
    }

    handleRemoveAttachment(_file) {
        let items = this.state.attachment;
        let newItems = items.filter(item => item.name !== _file.name);
        this.setState({
            attachment: newItems
        });
    }

    cancelForm() {
        let result = window.confirm("Hủy form này?");
        if (result) {
            this.props.toggleEditForms(this.props.item);
        } else return false;
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập Tên Chứng chỉ!";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        const { employee, files } = this.props;
        const ext = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'svgz', 'heic', 'heif', 'webp', 'tiff', 'tif', 'psd', 'bmp'];
        //console.log(files)
        return (
            <CModal
                show={this.props.edit_forms.includes(this.props.item.id)}
                size='lg'
                onClose={() => { this.cancelForm(); }}
                className='text-left'
            >
                <CModalHeader closeButton>
                    <h4><b>{this.state.form_header}</b>: <small> {employee.fullname + ' (' + employee.employee_id + ')'}</small></h4>
                </CModalHeader>
                <CModalBody>
                    <CForm>
                        <CRow className="mb-3">
                            <CCol md={6}>
                                <CLabel htmlFor="input-category_id">Loại Chứng chỉ:</CLabel>
                                <CSelect
                                    id='input-category_id'
                                    name='category_id'
                                    value={this.state.category_id}
                                    onChange={(e) => { this.handleChange('category_id', e); }}
                                >
                                    {configs.employee_certificates && configs.employee_certificates.map(certificate => {
                                        return (
                                            <option key={certificate.id} value={certificate.id} title={certificate.label}>{certificate.label}</option>
                                        )
                                    })}
                                </CSelect>
                                <small className='text-danger'>{this.state.errors['category_id']}</small>
                            </CCol>
                            <CCol md={6}>
                                <CLabel htmlFor="input-name">Tên Chứng chỉ:</CLabel>
                                <CInput
                                    type='text'
                                    name='name'
                                    id='input-name'
                                    value={this.state.name}
                                    onChange={(e) => { this.handleChange('name', e); }}
                                />
                                <small className='text-danger'>{this.state.errors['name']}</small>
                            </CCol>
                        </CRow>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CRow className="mb-1">
                                    <CCol md={3}>
                                        <CLabel htmlFor="input-attachment">Đính kèm:</CLabel>
                                    </CCol>
                                    <CCol md={9}>
                                        {this.state.attachment && this.state.attachment.length > 0 && <ol className='bg-light p-3 text-dark'>
                                            {this.state.attachment.map(file => {
                                                return (
                                                    <li key={'attachment-' + file.name} className='ml-2'>
                                                        <CLink href={file.url} target='_blank'>{file.name}</CLink>
                                                        <CTooltip content={'Loại bỏ'}>
                                                            <CButton
                                                                type="button" size='sm' color="danger"
                                                                className={'ml-3'}
                                                                onClick={() => { this.handleRemoveAttachment(file) }}
                                                            >
                                                                <CIcon name="cil-x" className="mfe-1 mt-0"></CIcon>
                                                            </CButton>
                                                        </CTooltip>
                                                    </li>
                                                )
                                            })}
                                        </ol>}
                                    </CCol>
                                </CRow>
                                <CRow className="mb-1">
                                    <CCol md={3}>
                                        Tải lên:
                                    </CCol>
                                    <CCol md={9}>
                                        <CInput
                                            type='file'
                                            name='upload_files'
                                            id='input-upload_files'
                                            multiple
                                            onChange={(e) => { this.handleChangeFiles('upload_files', e) }}
                                        />
                                        <small className='text-danger'>{this.state.errors['upload_files']}</small>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol md={3}>
                                        <CTooltip content={<>{'Chọn từ kho Tài liệu nhân sự: ' + employee.fullname}</>}>
                                            <CButton type="button" size='sm' color="primary" onClick={() => { this.setState({ show_existing_files: !this.state.show_existing_files }); }}>
                                                <CIcon name="cil-folder-open" className="mfe-1 mt-0"></CIcon>
                                                Chọn từ kho Tài liệu
                                            </CButton>
                                        </CTooltip>
                                    </CCol>
                                    <CCol md={9}>
                                        <CCollapse show={this.state.show_existing_files}>
                                            <table className='table table-hover'>
                                                <tbody>
                                                    {!helps.isEmpty(files) && files.map((_file) => {
                                                        let found_file = helps.getItemFromArr(this.state.attachment, _file.name, 'name');
                                                        if (!helps.isEmpty(found_file)) {
                                                            return (<></>);
                                                        }
                                                        let _metadata = _file['_metadata'];
                                                        return (
                                                            <tr key={_file.name}>
                                                                <td>
                                                                    {ext.includes(_file['ext']) &&
                                                                        <CPopover content={<CImg src={_file.url} width={150} thumbnail={true}></CImg>}>
                                                                            <CImg src={_file.url} width={20} height={20} style={{ maxHeight: '20px' }} thumbnail={true} title={_file.name} />
                                                                        </CPopover>
                                                                    }
                                                                    {!ext.includes(_file['ext']) && <CIcon name="cil-file" size={'1xl'} title={_file.name}></CIcon>}
                                                                </td>
                                                                <td >
                                                                    <CPopover content={<div>{_metadata && <>{helps.formatDateTime_from_Timestamp(new Date(_metadata.updated))}</>}</div>}>
                                                                        <CLink href={_file.url} target='_blank'>{_file.name}</CLink>
                                                                    </CPopover>
                                                                </td>
                                                                <td >
                                                                    <CTooltip content={_file.name}>
                                                                        <CButton
                                                                            type="button" size='sm' color="primary"
                                                                            onClick={() => { this.handleSelectAttachment(_file) }}
                                                                        >
                                                                            <CIcon name="cil-check" className="mfe-1 mt-0"></CIcon>
                                                                            Chọn
                                                                        </CButton>
                                                                    </CTooltip>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </CCollapse>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CLabel htmlFor="input-content">Ghi Chú:</CLabel>
                                <CTextarea
                                    rows={3}
                                    name="note"
                                    id="input-note"
                                    defaultValue={this.state.note}
                                    onChange={(e) => { this.handleChange('note', e) }}
                                >
                                </CTextarea>
                                <small className="text-danger">{this.state.errors['note']}</small>
                            </CCol>
                        </CRow>

                    </CForm>
                </CModalBody>
                <CModalFooter>
                    <CTooltip content={'Lưu'}>
                        <CButton type="submit" size='sm' color="primary" onClick={(e) => this.handleSubmit(e)}>
                            <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                            Lưu
                        </CButton>
                    </CTooltip>
                    <CTooltip content={'Hủy'}>
                        <CButton type="button" size='sm' color="secondary" onClick={() => { this.cancelForm(); }} className="ml-3">
                            <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                            Huỷ
                        </CButton>
                    </CTooltip>
                </CModalFooter>
            </CModal>
        )
    }
}
export default EmployeeCertificatesForm;

