import { db } from '../dbConfigs';
import { helps } from 'src/_helpers';

export const tagService = {
    getAll,
    add,
    update,
    _delete,
};

async function getAll(_object) {
    try {
        var snapshot = {};
        if (helps.isEmpty(_object)) {
            snapshot = await db.collection('tags')
                .get();
        } else {
            snapshot = await db.collection('tags')
                .where('object', '==', _object)
                .get();
        }
        const tags = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        //console.log(notes);
        tags.sort((a, b) => {
            return new Date(a.name) - new Date(b.name);
        });

        return tags;
    } catch (error) {
        return error;
    }
}

async function add(tag) {
    try {
        const docRef = await db.collection("tags").add(tag);
        tag.id = docRef.id;
        update(tag);
        return tag;
    } catch (error) {
        console.error("Error adding Check Rule: ", error);
    }
}

async function update(tag) {
    var Ref = db.collection("tags").doc(tag.id);

    try {
        await Ref.update(tag);
        console.log("Firebase: Rule has been updated successfully!");
        return tag;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _delete(tag) {
    try {
        await db.collection("tags").doc(tag.id).delete().then((tag) => {
            return tag;
        });
    } catch (error) {
        console.error("Error adding Tag: ", error);
    }
}
