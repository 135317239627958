import React, { Component } from 'react';
import { ThePermission } from 'src/containers/ThePermission';
import {
    CCol,
    CRow,
    CButton,
    CDataTable,
    CSelect,
    CLink,
    CInput,
    CFormGroup,
    CLabel,
    CBadge,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import DatePicker from 'react-datepicker';
import locale from 'date-fns/locale/vi';
import Violation from './Violation';
import Loading from 'src/containers/_loading';


class ViolationsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            violation_type: '',
            violation_term: '',
            violation_date: '',
            month: '',
            terms: [],
            modals: [],
            view_modals: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeMonth = this.handleChangeMonth.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleViewModal = this.toggleViewModal.bind(this);
    }

    componentDidMount() {
    }

    handleChange(field, e) {
        let value = e.target.value;
        this.setState({
            [field]: value
        });
        if (field === 'violation_type') {
            const { violation_types } = this.props;
            let type = helps.getItemFromArr(violation_types, value);
            this.setState({ terms: type.terms });
        }
    }

    handleChangeMonth(value) {
        console.log(value)
        this.setState({ month: value });
    }

    handleDelete(e, violation) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Vi phạm: ' + violation.title + ' ?');
        if (_confirm) {
            this.props._delete(violation);
        }
        return false;
    }

    handleSubmit(violation) {
        if (helps.isEmpty(violation.id)) {
            this.props.add(violation);
            this.toggleModal('add');
        } else {
            this.props.update(violation);
            this.toggleModal(violation.id);
        }
    }

    toggleModal = (item) => {
        let modals = this.state.modals;
        const position = modals.indexOf(item);
        let newItems = modals.slice();
        if (position !== -1) {
            newItems.splice(position, 1)
        } else {
            newItems = [...modals, item]
        }
        this.setState({ modals: newItems });
        console.log(this.state.modals)
    }

    toggleViewModal = (item) => {
        let modals = this.state.view_modals;
        const position = modals.indexOf(item);
        let newItems = modals.slice();
        if (position !== -1) {
            newItems.splice(position, 1)
        } else {
            newItems = [...modals, item]
        }
        this.setState({ view_modals: newItems });
    }


    render() {
        const { loading, violations, user, violation_types, employees, projects, users } = this.props;

        if (loading) {
            return (<Loading></Loading>);
        }

        var _violations = violations ? violations : [];
        let today = new Date();
        let this_month = today.getMonth();
        let this_year = today.getYear();
        if (_violations && _violations.length > 0) {
            _violations.forEach((violation) => {
                violation.violation_term['count'] = 1;
                let month = violation.violation_date.getMonth();
                let year = violation.violation_date.getYear();
                if (year === this_year && month === this_month) {
                    violation.violation_term['count'] = violation.violation_term['count'] + 1;
                }
                violation['project_id'] = violation.project_id ? violation.project_id : '';
            });
        }
        if (!helps.isEmpty(this.state.violation_type)) {
            _violations = _violations.filter(violation => violation.violation_type.id === this.state.violation_type);
        }
        if (!helps.isEmpty(this.state.violation_term)) {
            _violations = _violations.filter(violation => violation.violation_term.id === this.state.violation_term);
        }
        if (this.state.month) {
            let lastDayOfMonth = new Date(this.state.month.getFullYear(), this.state.month.getMonth() + 1, 0);
            _violations = _violations.filter((violation) => {
                if (violation.violation_date >= this.state.month && violation.violation_date <= lastDayOfMonth) {
                    return true;
                } else {
                    return false;
                }
            });
        }
        if (this.state.violation_date) {
            let violation_date = new Date(this.state.violation_date);
            _violations = _violations.filter(violation => violation.violation_date.getDate() === violation_date.getDate());
        }
        const fields = [
            { key: 'index', label: '#', _style: { width: '3%' }, filter: false, sorter: false },
            { key: 'employee', label: 'Nhân viên', _classes: 'font-weight-bold', _style: { width: '10%' } },
            { key: 'title', label: 'Tiêu đề', _classes: 'font-weight-bold', _style: { width: '10%' } },
            { key: 'violation_date', label: 'Ngày', _classes: 'font-weight-bold', _style: { width: '10%' } },
            { key: 'violation_type', label: 'Danh mục' },
            { key: 'project_id', label: 'Mục tiêu', filter: false, _style: { width: '10%' } },
            { key: 'violation_term', label: 'Hình thức Xử lý', _style: { width: '12%' } },
            { key: 'fines', label: 'Mức phạt' },
            { key: 'attachment', label: 'Biên bản' },
            { key: 'added_by', label: 'Tạo bởi', filter: false, sorter: false },
            { key: 'actions', label: 'Thao Tác', _style: { width: '10%', textAlign: 'center' }, filter: false, sorter: false },
        ]
        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol md={4} xl={4}>
                            <CFormGroup variant='custom-radio' inline>
                                <CLabel className='font-weight-bold mt-1'>Tháng:</CLabel>
                                <DatePicker
                                    locale={locale}
                                    selected={this.state.month}
                                    onChange={(date) => { this.handleChangeMonth(date) }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className='ml-2 form-control font-weight-bold'
                                />
                            </CFormGroup>
                        </CCol>
                    </CRow>
                    <hr />
                    <div className='violations-container'>
                        <CDataTable
                            items={_violations}
                            fields={fields}
                            columnFilter
                            tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                            itemsPerPageSelect={configs.itemsPerPageSelect}
                            itemsPerPage={configs.per_page}
                            hover
                            sorter
                            pagination
                            striped
                            columnFilterSlot={{
                                violation_date: (
                                    <>
                                        <CInput
                                            type='date'
                                            name='violation_date'
                                            onChange={(e) => { this.handleChange('violation_date', e) }}
                                        />
                                    </>
                                ),
                                violation_type: (
                                    <>
                                        <CSelect
                                            name="violation_type"
                                            id="input-violation_type"
                                            className="form-control form-control-sm"
                                            value={this.state.violation_type}
                                            onChange={(e) => { this.handleChange('violation_type', e) }}
                                        >
                                            <option value="">-Tất cả-</option>
                                            {violation_types && violation_types.map((type) => {
                                                return (
                                                    <option value={type.id} key={type.id}>{type.name}</option>
                                                )
                                            }
                                            )}
                                        </CSelect>
                                    </>
                                ),
                                violation_term: (
                                    <>
                                        <CSelect
                                            name="violation_term"
                                            id="input-violation_term"
                                            className="form-control form-control-sm"
                                            value={this.state.violation_term}
                                            onChange={(e) => { this.handleChange('violation_term', e) }}
                                        >
                                            <option value="">-Tất cả-</option>
                                            {this.state.terms && this.state.terms.map((term) => {
                                                return (
                                                    <option value={term.id} key={term.id}>{term.title}</option>
                                                )
                                            }
                                            )}
                                        </CSelect>
                                    </>
                                ),
                            }}
                            scopedSlots={{
                                'index': (item, index) => {
                                    return (
                                        <td className="py-2" id={item.id}>
                                            {index + 1}
                                        </td>
                                    )
                                },
                                'employee': (item) => {
                                    let employee = helps.getItemFromArr(employees, item.employee_id, 'employee_id');
                                    return (
                                        <td className="py-2">
                                            <CPopover content={employee.employee_id}>
                                                <div>
                                                    <CLink className='text-dark font-weight-bold' onClick={() => { this.props.history.push(`/employees/view/${employee.employee_id}`) }}>{employee.fullname}</CLink>
                                                    <div><small className='text-muted'>({employee.employee_id})</small></div>
                                                </div>
                                            </CPopover>
                                        </td>
                                    )
                                },
                                'title': (item) => {
                                    return (
                                        <td className="py-2">
                                            <CLink className='text-dark font-weight-bold' onClick={() => { this.toggleViewModal(item.id) }}>{item.title}</CLink>
                                        </td>
                                    )
                                },
                                'violation_date': (item) => {
                                    return (
                                        <td className="py-2">
                                            {helps.formatDateTime_from_Timestamp(item.violation_date)}
                                        </td>
                                    )
                                },
                                'violation_type': (item) => {
                                    return (
                                        <td className="py-2">
                                            {item.violation_type.name}
                                        </td>
                                    )
                                },
                                'project_id': (item) => {
                                    let employee_project = !helps.isEmpty(item.project_id) ? helps.getItemFromArr(projects, item.project_id) : {};
                                    return (
                                        <td className="py-2">
                                            {!helps.isEmpty(item.project_id) && <CLink className='text-dark' onClick={() => { this.props.history.push(`/projects/view/${employee_project.id}`) }}>{employee_project.name}</CLink>}
                                        </td>
                                    )
                                },
                                'violation_term': (item) => {
                                    let term = item.violation_term;
                                    return (
                                        <td className="py-2">
                                            <CBadge color={term.color} className='p-1'>{term.title}</CBadge>
                                        </td>
                                    )
                                },
                                'fines': (item) => {
                                    return (
                                        <td className="py-2 font-weight-bold">
                                            {helps.formatCurrency(item.fines)}
                                        </td>
                                    )
                                },
                                'attachment': (item) => {
                                    return (
                                        <td className="py-2">
                                            {item.attachment && item.attachment.length > 0 && item.attachment.map(file => {
                                                return (
                                                    <div key={'attachment-' + file.name + '-' + new Date().getTime()}>
                                                        <small><CIcon name='cil-file' size='sm'></CIcon> <CLink href={file.url} target='_blank'>{file.name}</CLink></small>
                                                    </div>
                                                )
                                            })}
                                        </td>
                                    )
                                },
                                'added_by': (item) => {
                                    return (
                                        <td className="py-2">
                                            <div><small>{helps.getUserAlias(users, item.added_by)}</small></div>
                                            <div><small>{helps.formatDateTime_from_Timestamp(item.added_time)}</small></div>
                                        </td>
                                    )
                                },
                                'actions':
                                    (item) => {
                                        let is_author = (user.id === item.added_by) ? true : false;
                                        return (
                                            <td className="py-2 text-center">
                                                <ThePermission {... this.props} component="employees" action="view">
                                                    <CButton
                                                        color="warning"
                                                        size="sm mr-1"
                                                        title='Xem chi tiết Vi phạm'
                                                        onClick={() => { this.toggleViewModal(item.id) }}
                                                    >
                                                        <CIcon name="cil-face"></CIcon>
                                                    </CButton>
                                                </ThePermission>
                                                <ThePermission {... this.props} component="employees" action="edit">
                                                    <CButton
                                                        type="button"
                                                        size="sm"
                                                        color={is_author ? "primary" : "secondary"}
                                                        title={'Sửa | Thêm bởi: ' + helps.getUserAlias(users, item.added_by)}
                                                        disabled={!is_author}
                                                        onClick={() => { this.toggleModal(item.id); }}
                                                    >
                                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                    </CButton>
                                                    <CButton
                                                        color={is_author ? "danger" : "secondary"}
                                                        size="sm"
                                                        title={'Xoá | Thêm bởi: ' + helps.getUserAlias(users, item.added_by)}
                                                        disabled={!is_author}
                                                        className="ml-1"
                                                        onClick={(e) => { this.handleDelete(e, item) }}
                                                    >
                                                        <CIcon name="cil-trash"></CIcon>
                                                    </CButton>
                                                </ThePermission>
                                                <Violation
                                                    {... this.props}
                                                    violation={item}
                                                    modals={this.state.view_modals}
                                                    item={item.id}
                                                    toggleModal={() => { this.toggleViewModal(item.id) }}
                                                />
                                            </td>
                                        )
                                    },
                            }}
                        />
                        <hr />
                        <h5>Tổng: <b>{_violations.length}</b></h5>
                    </div>
                </CCol >
            </CRow >
        )
    }
}


export default ViolationsList;
