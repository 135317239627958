import React from 'react'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import CIcon from '@coreui/icons-react';

import { userActions } from '../_actions';
import { history } from '../_helpers';

const doLogout = () => {
  userActions.logout();
  history.push('/login');
}

const TheHeaderDropdown = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          {user.fullname} - {user.username} <CIcon name={'cil-chevron-bottom'} size={'sm'} className="ml-2 mt-1" />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Tài Khoản</strong>
        </CDropdownItem>

        <CDropdownItem>
          <CIcon name="cil-user" className="mfe-2" />
          Trang cá nhân
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-settings" className="mfe-2" />
          Cài đặt
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem onClick={(e) => doLogout()}>
          <CIcon name="cil-account-logout" className="mfe-2" />
          Đăng xuất
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdown
