export const storeConstants = {
    SET_PARAMS_REQUEST: 'STORES_SET_PARAMS_REQUEST',

    GETALL_REQUEST: 'STORES_GETALL_REQUEST',
    GETALL_SUCCESS: 'STORES_GETALL_SUCCESS',
    GETALL_FAILURE: 'STORES_GETALL_FAILURE',

    DELETE_REQUEST: 'STORES_DELETE_REQUEST',
    DELETE_SUCCESS: 'STORES_DELETE_SUCCESS',
    DELETE_FAILURE: 'STORES_DELETE_FAILURE',

    GET_REQUEST: 'STORES_GET_REQUEST',
    GET_SUCCESS: 'STORES_GET_SUCCESS',
    GET_FAILURE: 'STORES_GET_FAILURE',

    ADD_REQUEST: 'STORES_ADD_REQUEST',
    ADD_SUCCESS: 'STORES_ADD_SUCCESS',
    ADD_FAILURE: 'STORES_ADD_FAILURE',

    UPDATE_REQUEST: 'STORES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'STORES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'STORES_UPDATE_FAILURE',

    UPDATE_FIELD_REQUEST: 'STORES_UPDATE_FIELD_REQUEST',
    UPDATE_FIELD_SUCCESS: 'STORES_UPDATE_FIELD_SUCCESS',
    UPDATE_FIELD_FAILURE: 'STORES_UPDATE_FIELD_FAILURE',
}