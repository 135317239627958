import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CLink,
    CImg,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import Dropzone from 'react-dropzone';

class UploadFile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            upload_files: '',
            files: '',
        }
        this.onDrop = (files) => {
            this.setState({ files: files })
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {

    }

    handleChange(e) {
        let upload_files = e.target.files;
        //console.log(upload_files);
        this.setState({ upload_files: upload_files });
    }

    handleUpload() {
        const { user } = this.props;
        let upload_files = this.state.upload_files;
        if (helps.isEmpty(upload_files)) {
            alert("Vui lòng chọn file để tải lên!");
            return;
        }
        const object = this.props.object;
        const object_id = this.props.object_id;
        this.props.uploadFile(upload_files, object, object_id, user);
    }


    render() {
        const ext = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'svgz', 'heic', 'heif', 'webp', 'tiff', 'tif', 'psd', 'bmp'];

        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
        ));

        return (
            <CRow>
                <CCol xl={12}>
                    <div>
                        <CLabel htmlFor="input-files"><b>{this.props.form_label}</b></CLabel>
                    </div>
                    <Dropzone
                        onDrop={this.uploadLetter}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} id='input-files' />
                                    <p>Chọn hoặc kéo thả Files tài liệu việc tại đây.</p>
                                </div>
                                <aside>
                                    <h4>Đã chọn: </h4>
                                    <ul>{files}</ul>
                                </aside>
                            </section>
                        )}
                    </Dropzone>
                    <CTooltip content={'Tải Lên'}>
                        <CButton type="button" color="primary" onClick={() => { this.handleUpload() }}>
                            <CIcon name="cil-cloud-upload" className="mfe-1 mt-0"></CIcon>
                            Tải Lên
                        </CButton>
                    </CTooltip>
                    {this.props.upload_loading && <div className='mt-4'><Loading></Loading></div>}
                </CCol>
            </CRow>
        )
    }
}
export default UploadFile;
