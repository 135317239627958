import { taxonomyConstants } from "src/_constants";
import { taxonomyService } from "src/_services/accounting";
import { alertActions } from "src/_actions/alert.actions";

export const taxonomyActions = {
    getAll,
    add,
    update,
    _delete,
    updateField,
}

function getAll(category_id) {
    return dispatch => {
        dispatch(request());
        return taxonomyService.getAll(category_id)
            .then(
                taxonomies => {
                    dispatch(success(taxonomies));
                    return taxonomies;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: taxonomyConstants.GET_LIST_REQUEST } }
    function success(taxonomies) { return { type: taxonomyConstants.GET_LIST_SUCCESS, taxonomies } }
    function failure(error) { return { type: taxonomyConstants.GET_LIST_FAILURE, error } }
}

function add(taxonomy) {
    return dispatch => {
        dispatch(request(taxonomy));
        taxonomyService.add(taxonomy)
            .then(
                taxonomy => {
                    dispatch(success(taxonomy));
                    dispatch(alertActions.success('Thêm mới Khoản Mục thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(taxonomy) { return { type: taxonomyConstants.ADD_REQUEST, taxonomy: taxonomy } }
    function success(taxonomy) { return { type: taxonomyConstants.ADD_SUCCESS, taxonomy: taxonomy } }
    function failure(error) { return { type: taxonomyConstants.ADD_FAILURE, error } }
}

function update(taxonomy) {
    return dispatch => {
        dispatch(request(taxonomy));
        taxonomyService.update(taxonomy)
            .then(
                taxonomy => {
                    dispatch(success(taxonomy));
                    dispatch(alertActions.success('Chỉnh sửa Khoản Mục thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(taxonomy) { return { type: taxonomyConstants.EDIT_REQUEST, taxonomy: taxonomy } }
    function success(taxonomy) { return { type: taxonomyConstants.EDIT_SUCCESS, taxonomy: taxonomy } }
    function failure(error) { return { type: taxonomyConstants.EDIT_FAILURE, error } }
}

function updateField(taxonomy, field) {
    return dispatch => {
        dispatch(request(taxonomy, field));
        return taxonomyService.updateField(taxonomy, field)
            .then(
                taxonomy => {
                    dispatch(success(taxonomy, field));
                    dispatch(alertActions.success('Chỉnh sửa trường Khoản Mục thành công!'));
                    return taxonomy;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(taxonomy, field) { return { type: taxonomyConstants.UPDATE_FIELD_REQUEST, taxonomy: taxonomy, field: field } }
    function success(taxonomy, field) { return { type: taxonomyConstants.UPDATE_FIELD_SUCCESS, taxonomy: taxonomy, field: field } }
    function failure(error) { return { type: taxonomyConstants.UPDATE_FIELD_FAILURE, error } }
}

function _delete(taxonomy) {
    return dispatch => {
        dispatch(request(taxonomy));
        taxonomyService._delete(taxonomy)
            .then(
                taxonomy => {
                    dispatch(success(taxonomy));
                    dispatch(alertActions.success('Xoá Khoản Mục thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(taxonomy) { return { type: taxonomyConstants.DELETE_REQUEST, taxonomy: taxonomy } }
    function success(taxonomy) { return { type: taxonomyConstants.DELETE_SUCCESS, taxonomy: taxonomy } }
    function failure(error) { return { type: taxonomyConstants.DELETE_FAILURE, error } }
}