import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
} from '@coreui/react';
import { ThePermission } from '../ThePermission';
import { userActions, storeActions, employeeActions, vendorActions, projectActions, itemActions, assetActions, asset_itemActions, tagActions, housingActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import QTTIN from 'src/views/assets/QTTIN';
import configs from 'src/appConfigs';

class QTTINContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_header: 'Nhập / Thu Hồi',
            categoty_id: 'QTT',
            asset_id: '',

        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let category_id = this.props.match.params.category_id;
        let category = helps.getItemFromArr(configs.asset_categories, category_id);
        this.setState({ page_header: category.label + ": " + this.state.page_header, categoty_id: category_id });
        if (!this.props.users) {
            this.props.getUsers();
        }

        this.getEmployees();
        this.getStores();
        this.getVendors();
        this.getHousings();
        this.getTags();
        this.getItems();
        this.getProjects();

        let asset_id = this.props.match.params.asset_id;
        this.setState({ asset_id: asset_id });
        /*
        let params = {};
        this.getAssets().then(() => {
            let assets = this.props.assets;
            let assets_ids = [];
            if (!helps.isEmpty(assets)) {
                assets.map((asset) => {
                    assets_ids.push(asset.id);
                });
            }
            if (!helps.isEmpty(assets_ids)) {
                params['asssets_ids'] = assets_ids;
            }
            this.getAssetItems(params);
        });
        */
    }

    async getAssets(params = {}) {
        if (helps.isEmpty(this.props.assets)) {
            await this.props.getAssets(params);
        }
    }

    async getAssetItems(params = {}) {
        await this.props.getAssetItems(params);
    }

    async getEmployees() {
        if (helps.isEmpty(this.props.employees)) {
            await this.props.getEmployees();
        }
    }

    async getStores() {
        if (helps.isEmpty(this.props.stores)) {
            await this.props.getStores();
        }
    }

    async getVendors() {
        if (helps.isEmpty(this.props.vendors)) {
            await this.props.getVendors();
        }
    }

    async getHousings() {
        if (helps.isEmpty(this.props.housings)) {
            await this.props.getHousings();
        }
    }

    async getItems() {
        if (helps.isEmpty(this.props.items)) {
            await this.props.getItems();
        }
    }

    async getProjects() {
        if (helps.isEmpty(this.props.projects)) {
            await this.props.getProjects();
        }
    }

    async getTags() {
        await this.props.getTags('vendors');
    }

    handleSubmit(record) {
        const { items, stores } = this.props;
        var url = '/assets/QTT';
        if (helps.isEmpty(record.id)) {
            var asset = {
                create_by: this.props.user.id,
                create_date: new Date(),
                from_to: record.from_to,
                asset_date: record.asset_date,
                state: record.state,
                store_id: record.store_id,
                project_id: record.project_id,
                employee_id: record.employee_id,
                vendor_id: record.vendor_id,
                housing_id: record.housing_id,
                category_id: record.category_id,
            }
            if (!helps.isEmpty(record.selected_items) && record.selected_items.length > 0) {
                let asset_items = [];
                record.selected_items.map((selected_item) => {
                    let asset_item = {
                        create_by: this.props.user.id,
                        create_date: new Date(),
                        item_id: selected_item.item_id,
                        dimension: selected_item.dimension,
                        quantity: selected_item.quantity,
                    }
                    var item = helps.getItemFromArr(items, selected_item.item_id);
                    if (helps.isEmpty(item.inventory)) {
                        item['inventory'] = [];
                        if (!helps.isEmpty(stores)) {
                            stores.map((store) => {
                                item['inventory'].push({
                                    store_id: store.id,
                                    current_inventory: (store.id === asset_item.store_id) ? asset_item.quantity : 0,
                                });
                            })
                        }
                        asset_item['pre'] = 0;
                        asset_item['inventory'] = asset_item.quantity;
                    } else {
                        let found_index = item.inventory.findIndex(_inventory => _inventory.store_id === asset.store_id);
                        let item_store = helps.getItemFromArr(item.inventory, asset.store_id, 'store_id');
                        if (found_index < 0) {
                            asset_item['pre'] = 0;
                            asset_item['inventory'] = asset_item['pre'] + parseInt(asset_item.quantity);
                            item['inventory'].push({
                                store_id: asset.store_id,
                                current_inventory: asset_item.inventory,
                            });
                        } else {
                            let updated_inventory = parseInt(item_store.current_inventory) + parseInt(asset_item.quantity);
                            item.inventory[found_index] = { store_id: asset.store_id, current_inventory: updated_inventory };
                            asset_item['pre'] = parseInt(item_store.current_inventory);
                            asset_item['inventory'] = updated_inventory;
                        }
                    }
                    item['inventory'] = helps.unigByKeepLast(item.inventory, it => it.store_id);
                    this.props.updateItemField(item, 'inventory');
                    asset_items.push(asset_item);
                });
                asset['asset_items'] = asset_items;
                this.props.addAsset(asset);
            }
        } else {
            this.props.update(record);
        }
        this.props.history.push(url);
    }

    render() {

        return (
            <ThePermission {... this.props} component="assets" action="edit">
                <CRow key={helps.convertToSlug(this.state.page_header)}>
                    <CCol xl={12}>
                        <CCard>
                            <CCardHeader>
                                <CRow>
                                    <CCol md={4} sm={12}>
                                        <h1 className='mb-1'>{this.state.page_header}</h1>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCardBody>
                                <QTTIN {... this.props} handleSubmit={this.handleSubmit} category_id={this.state.categoty_id} asset_id={this.state.asset_id} />
                            </CCardBody >
                        </CCard >
                    </CCol >
                </CRow >
            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { loading, assets, asset_items } = state.assetReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    const { vendors } = state.vendorReducer;
    const { stores } = state.storeReducer;
    const { employees } = state.employeeReducer;
    const { projects } = state.projectReducer;
    const { housings } = state.housingReducer;
    const { items } = state.itemReducer;
    const { tags } = state.tagReducer;

    return { loading, assets, asset_items, user, users, vendors, stores, employees, projects, items, tags, housings };
}

const actionCreators = {
    getAssets: assetActions.getAll,
    getAssetItems: asset_itemActions.getAll,
    addAsset: assetActions.add,
    getUsers: userActions.getAll,
    getEmployees: employeeActions.getAll,
    getVendors: vendorActions.getAll,
    getTags: tagActions.getAll,
    getStores: storeActions.getAll,
    getProjects: projectActions.getAll,
    getHousings: housingActions.getAll,
    getItems: itemActions.getAll,
    updateItemField: itemActions.updateField,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(QTTINContainer);
export { connectedContainer as QTTINContainer };