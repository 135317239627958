import React from "react";
import PropTypes from "prop-types";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


class Editor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editorHtml: '',
            theme: 'snow',
            isReady: false,
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.setState({
            editorHtml: this.props.defaultValue,
            defaultValue: this.props.defaultValue,
            isReady: true,
        });
    }

    handleChange(html) {
        this.setState({ editorHtml: html });
        this.props.handleChangeEditor(this.props.name, html);
    }

    render() {

        return (
            this.state.isReady && <ReactQuill
                theme={this.state.theme}
                onChange={this.handleChange}
                defaultValue={this.props.defaultValue}
                modules={Editor.modules}
                formats={Editor.formats}
                bounds={'.app'}
                placeholder={this.props.placeholder ? this.props.placeholder : ''}
            />
        )
    }
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
]

/* 
 * PropType validation
 */
Editor.propTypes = {
    placeholder: PropTypes.string,
}

export default Editor;
