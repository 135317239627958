import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
} from '@coreui/react';
import { ThePermission } from '../ThePermission';
import { userActions, fileActions, employeeActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import Assets from 'src/views/assets/Assets';

class AssetsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_header: 'Quản Lý Tài Sản',
            search: '',
            province_id: '',
            projects: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {
        if (!this.props.users) {
            this.props.getUsers();
        }
    }

    handleChange(field, e) {
        this.setState({
            [field]: e.target.value
        });
    }

    handleChangeSelect(selectedOptions) {
        if (selectedOptions) {
            this.setState({ 'province_id': selectedOptions.value });
        } else {
            this.setState({ province_id: '' });
        }

    }

    render() {

        return (
            <ThePermission {... this.props} component="assets" action="view">
                <CRow key={helps.convertToSlug(this.state.page_header)}>
                    <CCol xl={12}>
                        <CCard>
                            <CCardHeader>
                                <CRow>
                                    <CCol md={4} sm={12}>
                                        <h1 className='mb-1'>{this.state.page_header}</h1>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCardBody>
                                <Assets {... this.props} />
                            </CCardBody >
                        </CCard >
                    </CCol >
                </CRow >
            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { users } = state.userReducer;
    return { user, users };
}

const actionCreators = {
    getUsers: userActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(AssetsContainer);
export { connectedContainer as AssetsContainer };