import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import { customerActions, employeeActions, projectActions, taskActions, userActions } from 'src/_actions';
import TasksForm from 'src/views/tasks/TasksForm';

class TasksFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            task: {},
            task_id: '',
            form_header: "Thêm Mới Công Việc",
            action_status: 'add',
        }
    }

    componentDidMount() {
        this.getData().then(() => {
            let task_id = this.props.match.params.id;
            if (!helps.isEmpty(task_id)) {
                this.setState({
                    task_id: task_id,
                });
                this.getTask();
            }
        });
    }

    async getData() {
        const { users, tasks, projects, customers } = this.props;
        if (helps.isEmpty(users)) {
            this.props.getUsers();
        }
        if (helps.isEmpty(tasks)) {
            let _data = await this.getTasks();
        }
        if (helps.isEmpty(projects)) {
            let _data = await this.props.getProjects();
        }
        if (helps.isEmpty(customers)) {
            let _data = await this.props.getCuscomers('all');
        }
    }

    async getTask() {
        let task_id = this.state.task_id;
        const { tasks } = this.props;
        if (!helps.isEmpty(tasks)) {
            let task = helps.getItemFromArr(tasks, task_id);
            //console.log(tasks.owns);
            this.setState({ form_header: "Chỉnh Sửa Công Việc: " + task.title, action_status: 'edit', task: task });
        } else {
            return {};
        }
    }

    async getTasks() {
        const { tasks, user } = this.props;
        if (!tasks) await this.props.getUserTasks(user, 1000);
    }

    handleSubmit = (task) => {
        let task_id = this.props.match.params.id;
        if (helps.isEmpty(task_id)) {
            this.props.add(task);
        } else {
            this.props.update(task);
        }
        this.props.history.push('/tasks');
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        let task_id = this.props.match.params.id;

        const { task } = this.state;
        //console.log(task)
        if (!helps.isEmpty(task_id) && helps.isEmpty(task)) return (<Loading></Loading>);
        return (
            <TasksForm
                {... this.props}
                task={this.state.task}
                form_header={this.state.form_header}
                action_status={this.state.action_status}
                handleSubmit={this.handleSubmit}
            />
        );
    }
}
function mapStateToProps(state) {
    const { loading, tasks } = state.taskReducer;
    const { customers } = state.customerReducer;
    const { projects } = state.projectReducer;
    const { employees } = state.employeeReducer;
    const { users } = state.userReducer;
    const { user } = state.authentication;
    return { loading, tasks, user, users, customers, projects, employees };
}

const actionCreators = {
    getUserTasks: taskActions.getUserTasks,
    add: taskActions.add,
    update: taskActions.update,
    _delete: taskActions._delete,
    getProjects: projectActions.getAll,
    getCuscomers: customerActions.getAll,
    getEmployees: employeeActions.getAll,
    getUsers: userActions.getAll,
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(TasksFormContainer);
export { connectedFormContainer as TasksFormContainer };