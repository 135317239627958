import { Component } from 'react';
import ReactLoading from 'react-loading';
import { CContainer } from '@coreui/react';

class Loading extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        var _width = "100px";
        var _height = "20px";
        var _type = "cylon";
        if (this.props.width) {
            _width = this.props.width;
        }
        if (this.props.height) {
            _height = this.props.height;
        }
        if (this.props.type) {
            _type = this.props.type;
        }
        return (
            <CContainer>
                <ReactLoading type={_type} color="#0d6efd" height={_height} width={_width} className="mx-auto"></ReactLoading>
            </CContainer>

        );
    };
}
export default Loading;
