export const violationConstants = {
    GET_LIST_REQUEST: 'VIOLATIONS_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'VIOLATIONS_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'VIOLATIONS_GET_LIST_FAILURE',

    ADD_REQUEST: 'VIOLATIONS_ADD_REQUEST',
    ADD_SUCCESS: 'VIOLATIONS_ADD_SUCCESS',
    ADD_FAILURE: 'VIOLATIONS_ADD_FAILURE',

    EDIT_REQUEST: 'VIOLATIONS_EDIT_REQUEST',
    EDIT_SUCCESS: 'VIOLATIONS_EDIT_SUCCESS',
    EDIT_FAILURE: 'VIOLATIONS_EDIT_FAILURE',

    DELETE_REQUEST: 'VIOLATIONS_DELETE_REQUEST',
    DELETE_SUCCESS: 'VIOLATIONS_DELETE_SUCCESS',
    DELETE_FAILURE: 'VIOLATIONS_DELETE_FAILURE',

    GET_LIST_TYPES_REQUEST: 'VIOLATIONS_GET_LIST_TYPES_REQUEST',
    GET_LIST_TYPES_SUCCESS: 'VIOLATIONS_GET_LIST_TYPES_SUCCESS',
    GET_LIST_TYPES_FAILURE: 'VIOLATIONS_GET_LIST_TYPES_FAILURE',

    ADD_TYPES_REQUEST: 'VIOLATIONS_ADD_TYPES_REQUEST',
    ADD_TYPES_SUCCESS: 'VIOLATIONS_ADD_TYPES_SUCCESS',
    ADD_TYPES_FAILURE: 'VIOLATIONS_ADD_TYPES_FAILURE',

    EDIT_TYPES_REQUEST: 'VIOLATIONS_EDIT_TYPES_REQUEST',
    EDIT_TYPES_SUCCESS: 'VIOLATIONS_EDIT_TYPES_SUCCESS',
    EDIT_TYPES_FAILURE: 'VIOLATIONS_EDIT_TYPES_FAILURE',

    DELETE_TYPES_REQUEST: 'VIOLATIONS_DELETE_TYPES_REQUEST',
    DELETE_TYPES_SUCCESS: 'VIOLATIONS_DELETE_TYPES_SUCCESS',
    DELETE_TYPES_FAILURE: 'VIOLATIONS_DELETE_TYPES_FAILURE',

    ADD_TERMS_REQUEST: 'VIOLATIONS_ADD_TERMS_REQUEST',
    ADD_TERMS_SUCCESS: 'VIOLATIONS_ADD_TERMS_SUCCESS',
    ADD_TERMS_FAILURE: 'VIOLATIONS_ADD_TERMS_FAILURE',

    EDIT_TERMS_REQUEST: 'VIOLATIONS_EDIT_TERMS_REQUEST',
    EDIT_TERMS_SUCCESS: 'VIOLATIONS_EDIT_TERMS_SUCCESS',
    EDIT_TERMS_FAILURE: 'VIOLATIONS_EDIT_TERMS_FAILURE',

    DELETE_TERMS_REQUEST: 'VIOLATIONS_DELETE_TERMS_REQUEST',
    DELETE_TERMS_SUCCESS: 'VIOLATIONS_DELETE_TERMS_SUCCESS',
    DELETE_TERMS_FAILURE: 'VIOLATIONS_DELETE_TERMS_FAILURE',

}