import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CLink,
    CTooltip,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import EmployeeContractsForm from './EmployeeContractsForm';
import Loading from 'src/containers/_loading';
import { fileService } from 'src/_services';


class EmployeeContracts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleActive = this.handleActive.bind(this);
    }

    componentDidMount() {
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
    }

    handleDelete(e, contract) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá HĐLĐ: ' + contract.id + ' ?');
        if (_confirm) {
            const { employee } = this.props;
            let contracts = !helps.isEmpty(employee.contracts) ? employee.contracts : [];
            let _contracts = contracts.filter(_contract => _contract.id !== contract.id);
            employee['contracts'] = _contracts;

            this.props.updateField(employee, 'contracts');
        }
        return false;
    }

    async handleSubmit(contract) {
        const { employee, user } = this.props;
        let contracts = !helps.isEmpty(employee.contracts) ? employee.contracts : [];
        var uploaded_files = [];
        let is_new = true;

        let found_contract = helps.getItemFromArr(contracts, contract.id);
        if (!helps.isEmpty(found_contract)) {
            is_new = false;
        }

        if (typeof contract.uploaded_files !== 'undefined' && contract.uploaded_files !== null) {
            uploaded_files = await fileService.uploadFile(contract.upload_files, 'employees', employee.employee_id, user)
        }

        let attachment = !helps.isEmpty(contract.attachment) ? contract.attachment : [];
        if (!helps.isEmpty(uploaded_files) && uploaded_files.length > 0) {
            uploaded_files.forEach((uploaded_file) => {
                attachment.push({
                    name: uploaded_file.name,
                    url: uploaded_file.url,
                });
            });
        }
        contract['attachment'] = attachment;
        delete contract.upload_files;
        contracts.push(contract);
        employee.contracts = contracts;

        this.props.updateField(employee, 'contracts');

        if (is_new) {
            this.props.toggleEditForms({ id: 'add_form' });
        } else {
            this.props.toggleEditForms(contract);
        }
    }

    handleActive(e) {
        const { contracts } = this.props;
        let cur_active_contracts = contracts.filter(contract => contract.active > 0);
        if (!helps.isEmpty(cur_active_contracts) && cur_active_contracts.length > 0) {
            cur_active_contracts.forEach((contract) => {
                contract['active'] = 0;
                this.props.update(contract);
            });
        }
        var active_contract = helps.getItemFromArr(contracts, e.target.value);
        if (active_contract) {
            active_contract['active'] = 1;
            this.props.update(active_contract);
        }
    }



    render() {
        const { employee, user, users } = this.props;
        let contracts = employee.contracts ? employee.contracts : [];
        let today = new Date();
        if (!helps.isEmpty(contracts) && contracts.length > 0) {
            contracts = contracts.sort(function (a, b) {
                let b_end_date = b.end_date ? new Date(b.end_date).getTime() : today.getTime();
                let a_end_date = a.end_date ? new Date(a.end_date).getTime() : today.getTime();
                return b_end_date - a_end_date;
            });
        }
        let count = contracts.length ? contracts.length : 0;

        return (
            <CRow>
                <CCol xl={12}>
                    <EmployeeContractsForm
                        {...this.props}
                        contracts={contracts}
                        handleSubmit={this.handleSubmit}
                        toggleEditForms={this.props.toggleEditForms}
                        item={{ id: 'add_form' }}
                        contract={{}}
                    />
                    <div className='contracts-container table-responsive'>
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Số HĐLĐ</th>
                                    <th style={{ width: '25%' }}>Thời Hạn HĐLĐ</th>
                                    <th>Đính Kèm</th>
                                    <th>Ghi Chú</th>
                                    <th>Bởi</th>
                                    <th className='text-center' style={{ width: '15%' }}>Thao Tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {helps.isEmpty(contracts) && <tr><td colSpan={8}><h5 className='text-secondary text-center mt-5'> Không tìm thấy HĐLĐ nào!</h5></td></tr>}
                                {this.props.loading && <tr><td colSpan={8}><Loading></Loading></td></tr>}
                                {contracts && contracts.map((contract, index) => {
                                    let end_date = contract.end_date ? new Date(contract.end_date) : today;
                                    let remaining = helps.secondsToYmd(today, end_date);
                                    return (
                                        <tr key={contract.id}>
                                            <td>
                                                {count - index}
                                            </td>
                                            <td>{contract.id}</td>
                                            <td>
                                                <CPopover content={'hạn còn: ' + remaining}>
                                                    <span>
                                                        <b>{helps.formatDate_from_Timestamp(contract.start_date) + ' - ' + helps.formatDate_from_Timestamp(contract.end_date)}</b>
                                                        <div><small><i>(hạn còn: {remaining})</i></small></div>
                                                    </span>
                                                </CPopover>
                                            </td>
                                            <td>
                                                {!helps.isEmpty(contract.attachment) && contract.attachment.map(file => {
                                                    return (
                                                        <li>
                                                            <CLink href={file.url} target='_blank'>{file.name}</CLink>
                                                        </li>
                                                    );
                                                })}
                                            </td>
                                            <td>{contract.note}</td>
                                            <td>
                                                <small className='text-muted'>
                                                    <span >{helps.getUserAlias(users, contract.added_by)}</span>
                                                    <div>
                                                        {helps.formatDateTime_from_Timestamp(contract.added_time)}
                                                    </div>
                                                </small>
                                            </td>
                                            <td className='text-center'>
                                                <CTooltip content={'Sửa HĐLĐ: ' + contract.id}>
                                                    <CButton type="button" size="sm" color="primary" title='Sửa' onClick={() => { this.props.toggleEditForms(contract) }}>
                                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                    </CButton>
                                                </CTooltip>
                                                {(user.id === contract.added_by || user.role === 'administrator') && <>
                                                    <CTooltip content={'Xóa HĐLĐ: ' + contract.id}>
                                                        <CButton
                                                            color="danger"
                                                            size="sm"
                                                            title="Xoá"
                                                            className="ml-1"
                                                            onClick={(e) => { this.handleDelete(e, contract) }}
                                                        >
                                                            <CIcon name="cil-trash"></CIcon>
                                                        </CButton>
                                                    </CTooltip>
                                                </>}
                                                <EmployeeContractsForm
                                                    {...this.props}
                                                    contract={contract}
                                                    item={contract}
                                                    handleSubmit={this.handleSubmit}
                                                    toggleEditForms={this.props.toggleEditForms}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </CCol>
            </CRow>
        )
    }
}


export default EmployeeContracts;
