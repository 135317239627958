import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CCallout,
    CTooltip,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import ViolationSettingsForm from './ViolationSettingsForm';
import Loading from 'src/containers/_loading';
import ViolationTerms from './ViolationTerms';
import Hint from 'src/_components/Hint';


class ViolationSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            added_time: '',
            modals: [],
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    handleDelete(e, violation_type) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Danh mục Chế Tài: ' + violation_type.title + ' ?');
        if (_confirm) {
            this.props._deleteType(violation_type);
        }
        return false;
    }

    handleSubmit(violation_type) {
        if (helps.isEmpty(violation_type.id)) {
            this.props.addType(violation_type);
            this.toggleModal('add');
        } else {
            this.props.updateType(violation_type);
            this.toggleModal(violation_type.id);
        }
    }

    toggleModal = (item) => {
        let modals = this.state.modals;
        const position = modals.indexOf(item);
        let newItems = modals.slice();
        if (position !== -1) {
            newItems.splice(position, 1)
        } else {
            newItems = [...modals, item]
        }
        this.setState({ modals: newItems });
    }

    render() {
        const { loading_type, violation_types, user } = this.props;
        if (violation_types && violation_types.length > 0) {
            violation_types.map((type) => {
                type['_added_time'] = helps.formatDateTime_from_Timestamp(type.added_time);
                type['is_author'] = false;
                if (user.id == type.added_by) {
                    type['is_author'] = true;
                }
            })
        }

        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol xl={12} md={12} className="text-right">
                            <CButton type="button" color="primary" size="sm" onClick={() => { this.toggleModal('add') }}>
                                <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                Thêm Danh mục Chế Tài
                            </CButton>
                            <ViolationSettingsForm
                                {... this.props}
                                modals={this.state.modals}
                                form_header="Thêm Danh Mục Vi Phạm"
                                item='add'
                                toggleModal={() => { this.toggleModal('add') }}
                                handleSubmit={this.handleSubmit}
                            />

                        </CCol>
                    </CRow>
                    <hr />
                    <div className='violations-container'>
                        {loading_type && <Loading></Loading>}
                        {!helps.isEmpty(violation_types) && violation_types.length > 0 && violation_types.map((type) => {
                            return (
                                <CCallout key={type.id} color="dark" className={'bg-white'}>
                                    <CRow className="mb-2">
                                        <CCol xl={10} md={10}>
                                            <h4>
                                                <span><b>{type.name}</b></span>
                                                <Hint header={type.name} content={type.description} />
                                            </h4>
                                            <ViolationTerms {... this.props} violation_type={type} />
                                        </CCol>
                                        <CCol xl={2} md={2}>
                                            <div className='text-right'>
                                                <CTooltip
                                                    content={'Chỉnh sửa: ' + type.name}
                                                >
                                                    <CButton type="button" size="sm" color="primary" title='Sửa' onClick={() => { this.toggleModal(type.id); }}>
                                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                    </CButton>
                                                </CTooltip>
                                                {type.is_author &&
                                                    <CTooltip
                                                        content={'bởi: ' + type.added_by_alias}
                                                    >
                                                        <CButton
                                                            color="danger"
                                                            size="sm"
                                                            title="Xoá"
                                                            className="ml-1"
                                                            onClick={(e) => { this.handleDelete(e, type) }}
                                                        >
                                                            <CIcon name="cil-trash"></CIcon>
                                                        </CButton>
                                                    </CTooltip>
                                                }
                                                <ViolationSettingsForm
                                                    {... this.props}
                                                    modals={this.state.modals}
                                                    form_header="Chỉnh Sửa Danh Mục Vi Phạm"
                                                    item={type.id}
                                                    violation_type={type}
                                                    toggleModal={() => { this.toggleModal(type.id) }}
                                                    handleSubmit={this.handleSubmit}
                                                />
                                            </div>
                                        </CCol>
                                    </CRow>
                                    <hr />
                                </CCallout>
                            );
                        })}
                    </div>
                </CCol >
            </CRow >
        )
    }
}

export default ViolationSettings;
