import { maneuverConstants } from "src/_constants";

export function maneuverReducer(state = { maneuvers: [], maneuver: {} }, actions) {
    switch (actions.type) {
        // GET LIST    
        case maneuverConstants.GET_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case maneuverConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                maneuvers: actions.maneuvers,
                loading: false,
            }
        case maneuverConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Add
        case maneuverConstants.ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case maneuverConstants.ADD_SUCCESS:
            state.maneuvers.push(actions.maneuver);
            return {
                ...state,
                maneuver: actions.maneuver,
                loading: false,
            }
        case maneuverConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Edit
        case maneuverConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case maneuverConstants.EDIT_SUCCESS:
            var _maneuvers = state.maneuvers;
            var foundIndex = _maneuvers.findIndex(maneuver => maneuver.id === actions.maneuver.id);
            _maneuvers[foundIndex] = actions.maneuver;
            console.log(foundIndex);
            return {
                ...state,
                maneuver: actions.maneuver,
                maneuvers: _maneuvers,
                loading: false,
            }
        case maneuverConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        // Delete 
        case maneuverConstants.DELETE_REQUEST:
            return {
                ...state,
                maneuvers: state.maneuvers.map(maneuver =>
                    maneuver.id === actions.maneuver.id
                        ? { ...maneuver, deleting: true }
                        : maneuver
                )
            };
        case maneuverConstants.DELETE_SUCCESS:
            return {
                maneuvers: state.maneuvers.filter(maneuver => maneuver.id !== actions.maneuver.id)
            };
        case maneuverConstants.DELETE_FAILURE:
            return {
                ...state,
                maneuvers: state.maneuvers.map(maneuver => {
                    if (maneuver.id === actions.maneuver.id) {
                        const { deleting, ...maneuverCopy } = maneuver;
                        return { ...maneuverCopy, deleteError: actions.error };
                    }
                    return maneuver;
                })
            };

        default: return state;
    }
}