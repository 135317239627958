import React from 'react';
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { helps } from 'src/_helpers';


const TheHeaderDropdownNotif = (props) => {
  const { notifications } = props;
  var itemsCount = notifications ? notifications.length : 0;
  const readClick = (notification) => {
    props._read(notification);
    props.history.push(notification.object_link);
  }
  return (
    <CDropdown
      inNav
      className="c-header-nav-item mx-2"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CIcon name="cil-bell" />
        <CBadge shape="pill" color="danger">{itemsCount > 0 && <>{itemsCount}</>}</CBadge>
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end" className="pt-0 header-nav-notifications">
        <CDropdownItem
          header
          tag="div"
          color="light"
        >
          <h5><b>Thông Báo</b></h5>
        </CDropdownItem>
        {!helps.isEmpty(notifications) && notifications.length > 0 && notifications.map((notification) => {
          return (

            <CDropdownItem onClick={() => { readClick(notification) }} key={notification.id}>
              <CBadge shape="pill" color="danger" className='p-1 mr-2'> </CBadge> <div dangerouslySetInnerHTML={{ __html: notification.content }} className='mr-1' />
              <small className='text-muted'><i> _{helps.formatDateTime_from_Timestamp(notification.added_time)}</i></small>
            </CDropdownItem>
          )
        })}

        {itemsCount === 0 && <div className='text-center p-5 text-secondary'><h5>Không có thông báo mới!</h5></div>}

        <CDropdownItem
          color="light"
          onClick={() => { props.history.push('/notifications') }}
        >
          <i>Xem tất cả thông báo</i>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdownNotif