import { db } from '../../dbConfigs';
import { helps } from 'src/_helpers';
import { storage } from 'src/dbConfigs';
import "firebase/storage";

export const employeeService = {
    getAll,
    addEmployee,
    getById,
    updateEmployee,
    updateField,
    uploadLetter,
    deleteLetter,
};

async function getAll(isForced = false) {
    const snapshot = await db.collection('employees')
        .orderBy('joint_date', 'desc')
        .get();
    let employees = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));
    await Promise.all(
        employees.map(async (employee) => {
            if (employee.birthdate) {
                var _date = employee['birthdate'];
                employee['birthdate'] = _date.toDate();
            }
            if (employee.joint_date) {
                let _date = employee['joint_date'];
                employee['joint_date'] = _date.toDate();
            }
            if (employee.added_date) {
                let _date = employee['added_date'];
                employee['added_date'] = _date.toDate();
            }
            if (employee.id_issuance_date) {
                let _date = employee['id_issuance_date'];
                employee['id_issuance_date'] = _date.toDate();
            }
            if (employee.contracts && employee.contracts.length > 0) {
                employee.contracts.forEach(contract => {
                    if (contract.start_date) {
                        let _date = contract.start_date;
                        contract['start_date'] = _date.toDate();
                    }
                    if (contract.end_date) {
                        let _date = contract.end_date;
                        contract['end_date'] = _date.toDate();
                    }
                    if (contract.added_time) {
                        let _date = contract.added_time;
                        contract['added_time'] = _date.toDate();
                    }
                })
            }

            if (employee.exit) {
                if (employee.exit.exit_date) {
                    let _date = employee.exit.exit_date;
                    employee.exit['exit_date'] = _date.toDate();
                }
                if (employee.exit.kickoff_date) {
                    let _date = employee.exit.kickoff_date;
                    employee.exit['kickoff_date'] = _date.toDate();
                }
                if (employee.exit.letter) {
                    if (employee.exit.letter.letter_receipt_date) {
                        let _date = employee.exit.letter.letter_receipt_date;
                        employee.exit.letter['letter_receipt_date'] = _date.toDate();
                    }
                    if (employee.exit.letter.letter_receipt_date_deadline) {
                        let _date = employee.exit.letter.letter_receipt_date_deadline;
                        employee.exit.letter['letter_receipt_date_deadline'] = _date.toDate();
                    }
                    if (employee.exit.letter.suspension_decision_date) {
                        let _date = employee.exit.letter.suspension_decision_date;
                        employee.exit.letter['suspension_decision_date'] = _date.toDate();
                    }
                }
            }

        })
    );
    //employees = correctInvalidID(employees);
    return employees;
}

async function correctInvalidID(employees) {
    console.log(employees)
    await Promise.all(employees.forEach(async (employee, index) => {
        if (employee.id !== employee.employee_id) {
            let old_employee = employee;
            let getByID = await getById(employee.id);
            if (helps.isEmpty(getByID)) {
                let inserted = await addEmployee(employee);
                employees[index] = inserted;
            }
            await deleteEmployee(old_employee);
        }
    }));
    return employees;
}

async function getById(id, isForced = false) {
    try {
        const query = await db.collection("employees").where('id', '==', id).get();
        if (!query.empty) {
            const snapshot = query.docs[0];
            return snapshot.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        console.log("Error getting document:", error);
    }
}

async function addEmployee(employee) {
    try {
        const docRef = await db.collection("employees").doc(employee.employee_id).set(employee);
        console.log(docRef)
        if (docRef) {
            employee.id = docRef.id;
            updateField(employee, 'id');
            return employee;
        }
    } catch (error) {
        console.error("Error adding employee: ", error);
    }
}

async function updateEmployee(employee) {
    console.log(employee);
    let dbRef = db.collection("employees").doc(employee.id);

    try {
        //console.log(employee);
        await dbRef.update(employee).then(() => {
            console.log("Firebase: employee has been updated successfully!");
        });

        return employee;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function deleteEmployee(employee) {
    var Ref = db.collection("employees").doc(employee.id);
    try {
        await Ref.delete();
        console.log("Firebase: Employee has been deleted successfully!");
        return employee;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function updateField(employee, field) {
    //console.log(employee)
    let dbRef = db.collection("employees").doc(employee.employee_id);

    try {
        await dbRef.update({
            [field]: employee[field]
        }).then(() => {
            console.log("Firebase: employee field has been updated successfully!");
        });

        return employee;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function uploadLetter(employee, upload_files, user, type = 'letter') {
    try {
        if (!helps.isEmpty(upload_files)) {
            let exit = employee.exit;
            let exit_letter = exit.letter ? exit.letter : {};
            var _files = [];
            let keys = [];
            if (typeof upload_files !== 'undefined' && upload_files !== null) {
                keys = Object.keys(upload_files);
            }
            await Promise.all(
                keys.map(async (_key) => {
                    let og_file = upload_files[_key];
                    //console.log(file);
                    var newMetadata = {
                        customMetadata: {
                            'user_id': (user) ? user.id : ''
                        }
                    }

                    await storage.ref(`/employees/${employee.employee_id}/${og_file.name}`).put(og_file, newMetadata).then(async (snapshot) => {
                        //console.log(snapshot);
                        let _file = snapshot.ref;

                        await _file.getDownloadURL().then((url) => {
                            _file['url'] = url;
                        }).catch((error) => {
                            console.error(error);
                        });

                        await _file.getMetadata().then((metadata) => {
                            _file['_metadata'] = metadata;
                        });

                        _files.push(_file);
                    });
                })
            );
            if (!helps.isEmpty(_files) && _files.length > 0) {
                let letter = _files[0];
                //console.log(letter)
                switch (type) {
                    case 'suspension_decision':
                        exit_letter = {
                            suspension_decision_name: letter.name,
                            suspension_decision_url: letter.url,
                            suspension_decision_upload_by: user.id,

                            termination_decision_name: exit_letter.termination_decision_name ? exit_letter.termination_decision_name : '',
                            termination_decision_url: exit_letter.termination_decision_url ? exit_letter.termination_decision_url : '',
                            termination_decision_upload_by: exit_letter.termination_decision_upload_by ? exit_letter.termination_decision_upload_by : '',
                        }
                        break;
                    case 'termination_decision':
                        exit_letter = {
                            suspension_decision_name: exit_letter.suspension_decision_name ? exit_letter.suspension_decision_name : '',
                            suspension_decision_url: exit_letter.suspension_decision_url ? exit_letter.suspension_decision_url : '',
                            suspension_decision_upload_by: exit_letter.suspension_decision_upload_by ? exit_letter.suspension_decision_upload_by : '',

                            termination_decision_name: letter.name,
                            termination_decision_url: letter.url,
                            termination_decision_upload_by: user.id,
                        }
                        break;
                    default:
                        exit_letter = {
                            letter_name: letter.name,
                            letter_url: letter.url,
                            letter_upload_by: user.id,
                        }
                }

                exit['letter'] = exit_letter;
                updateField(employee, 'exit');
                console.log("Firebase: Exit Letter has been added successfully!");
            }
        }
        return employee;
    } catch (error) {
        console.error("Error uploading: ", error);
    }
}

async function deleteLetter(employee, user, type = 'letter') {
    try {
        let exit = employee.exit;
        let letter = exit.letter;
        let letter_name = letter.letter_name;
        switch (type) {
            case 'suspension_decision':
                letter_name = letter.suspension_decision_name;
                break;
            case 'termination_decision':
                letter_name = letter.termination_decision_name;
                break;
            default:
                letter_name = letter.letter_name;
        }

        if (!helps.isEmpty(letter)) {
            const Ref = storage.ref('employees').child(employee.employee_id).child(letter_name);
            await Ref.delete().then(() => {
                console.log("Firebase: File has been deleted successfully!");
                switch (type) {
                    case 'suspension_decision':
                        letter = {
                            suspension_decision_name: '',
                            suspension_decision_url: '',
                            suspension_decision_upload_by: '',

                            termination_decision_name: letter.termination_decision_name ? letter.termination_decision_name : '',
                            termination_decision_url: letter.termination_decision_url ? letter.termination_decision_url : '',
                            termination_decision_upload_by: letter.termination_decision_upload_by ? letter.termination_decision_upload_by : '',
                        }
                        break;
                    case 'termination_decision':
                        letter = {
                            suspension_decision_name: letter.suspension_decision_name ? letter.suspension_decision_name : '',
                            suspension_decision_url: letter.suspension_decision_url ? letter.suspension_decision_url : '',
                            suspension_decision_upload_by: letter.suspension_decision_upload_by ? letter.suspension_decision_upload_by : '',

                            termination_decision_name: '',
                            termination_decision_url: '',
                            termination_decision_upload_by: '',
                        }
                        break;
                    default:
                        letter = {
                            suspension_decision_name: letter.suspension_decision_name ? letter.suspension_decision_name : '',
                            suspension_decision_url: letter.suspension_decision_url ? letter.suspension_decision_url : '',
                            suspension_decision_upload_by: letter.suspension_decision_upload_by ? letter.suspension_decision_upload_by : '',

                            termination_decision_name: letter.termination_decision_name ? letter.termination_decision_name : '',
                            termination_decision_url: letter.termination_decision_url ? letter.termination_decision_url : '',
                            termination_decision_upload_by: letter.termination_decision_upload_by ? letter.termination_decision_upload_by : '',

                            letter_name: '',
                            letter_url: '',
                            letter_upload_by: '',

                        }
                }
                exit['letter'] = letter;
                employee['exit'] = exit;
                updateField(employee, 'exit');
            });
            return employee;
        }
    } catch (error) {
        console.error("Error deleting file: ", error);
    }
}

