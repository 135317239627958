import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import RecruitmentKPI from 'src/views/employees/RecruitmentKPI';
import { employeeActions } from 'src/_actions';
import { helps } from 'src/_helpers';

class RecruitmentKPIContainer extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        if (helps.isEmpty(this.props.employees))
            this.props.getAll();
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        return (
            <ThePermission {... this.props} component="employees" action="view">
                <RecruitmentKPI {... this.props} />
            </ThePermission>
        );
    }
}

function mapStateToProps(state) {
    const { loading, employees } = state.employeeReducer;
    const { projects } = state.projectReducer;
    const { notes } = state.noteReducer;
    return { loading, employees, projects, notes };
}

const actionCreators = {
    getAll: employeeActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(RecruitmentKPIContainer);
export { connectedContainer as RecruitmentKPIContainer };