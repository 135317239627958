import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkActions, noteActions, housingActions } from 'src/_actions';
import ChecksForm from 'src/views/checks/ChecksForm';

class ChecksFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }

    async getChecks(params) {
        await this.props.getChecks(params);
    }

    async doAddcheck(check) {
        let _check = await this.doAdd(check);
        this.getChecks({ curent_date: check.added_time });
    }

    async doAdd(check) {
        return await this.props.add(check);
    }

    render() {
        /*
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        */
        return (
            <ChecksForm
                {... this.props}
                project={this.props.project}
                employee={this.props.employee}
                action={this.props.action}
                toggleForm={(item) => { this.props.toggleForm(item) }}
                show_forms={this.props.show_forms}
                check={this.props.check}
                user={this.props.user}
            />
        );
    }
}

function mapStateToProps(state) {
    const { loading } = state.checkReducer;
    return { loading };
}

const actionCreators = {
    getChecks: checkActions.getAll,
    add: checkActions.add,
    update: checkActions.update,
    addNote: noteActions.add,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ChecksFormContainer);
export { connectedContainer as ChecksFormContainer };