import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CInput,
    CSelect,
    CFormText,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import Editor from 'src/_components/Editor';

class StoresForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            name: '',
            branch: 'HO',
            status: true,
            description: '',
            province_id: '',
            create_by: this.props.user.id,
            create_date: new Date(),
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEditor = this.handleChangeEditor.bind(this);
    }

    componentDidMount() {
        const store = this.props.store;
        //console.log(store);
        if (store) {
            this.setState({
                id: store.id,
                name: store.name ? store.name : this.state.name,
                branch: store.branch ? store.branch : this.state.branch,
                create_by: store.create_by ? store.create_by : this.props.user.id,
                create_date: store.create_date ? store.create_date : new Date(),
                status: store.status ? store.status : this.state.status,
                description: store.description ? store.description : this.state.description,
                province_id: store.description ? store.description : this.state.province_id,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let store = {
                id: this.state.id,
                name: this.state.name,
                branch: this.state.branch,
                create_by: this.state.create_by,
                create_date: new Date(this.state.create_date),
                status: this.state.status,
                description: this.state.description,
                province_id: this.state.province_id,
            }
            this.props.handleSubmit(store);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeEditor(field, value) {
        console.log(field, value);
        this.setState({
            [field]: value
        });
    }


    handleChangeFilterDate(_date) {
        let fields = this.state.fields;
        fields['create_date'] = _date;
        this.setState({ create_date: _date });
        this.setState({ fields });
    }


    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập Tên";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }


    render() {
        const { provinces } = this.props;

        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader>
                                {this.props.form_header}
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-name">Tên Kho:</CLabel>
                                            <CInput
                                                type="text"
                                                name="name"
                                                id="input-name"
                                                value={this.state.name}
                                                required
                                                className='font-weight-bold'
                                                onChange={(e) => { this.handleChange('name', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['name']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-branch">Chi Nhánh:</CLabel>
                                                    <CSelect
                                                        id="input-branch"
                                                        defaultValue={this.state.branch}
                                                        required
                                                        onChange={(e) => { this.handleChange('branch', e) }}
                                                    >
                                                        {configs.branches && Object.keys(configs.branches).map((_key) => {
                                                            return (
                                                                <option key={"_branch_" + _key} value={_key}>{configs.branches[_key]}</option>
                                                            );
                                                        })}
                                                    </CSelect>
                                                    <small className="text-danger">{this.state.errors['branch']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-province_id">Tỉnh Thành / Khu Vực:</CLabel>
                                                    <CSelect
                                                        name="province_id"
                                                        id="input-province_id"
                                                        value={this.state.province_id}
                                                        onChange={(e) => { this.handleChange('province_id', e) }}
                                                    >
                                                        {provinces && provinces.map((item) => {
                                                            return (
                                                                <option value={item.id} key={item.id}>{item.name}</option>
                                                            );
                                                        })}
                                                    </CSelect>
                                                    <small className="text-danger">{this.state.errors['branch']}</small>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-description">Mô tả:</CLabel>
                                            <Editor
                                                {... this.props}
                                                id="input-description"
                                                name="description"
                                                defaultValue={this.state.description}
                                                handleChangeEditor={this.handleChangeEditor}
                                            />
                                        </div>
                                    </CCol>
                                    <CCol md={6}>

                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default StoresForm;

