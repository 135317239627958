import { employeeConstants } from "src/_constants";

export function employeeReducer(state = {}, actions) {
    switch (actions.type) {

        // get all employee
        case employeeConstants.GETALL_REQUEST:
            return {
                loading: true
            }
        case employeeConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                employees: actions.employees
            }
        case employeeConstants.GETALL_FAILURE:
            return {
                ...state,
                error: actions.error
            }

        // get a employee by ID    
        case employeeConstants.GET_REQUEST:
            return {
                loading: true
            }
        case employeeConstants.GET_SUCCESS:
            return {
                ...state,
                employee: actions.employee,
                loading: false
            }
        case employeeConstants.GET_FAILURE:
            return {
                error: actions.error
            };
        // add new employee    
        case employeeConstants.ADD_REQUEST:
            return {
                loading: true
            }
        case employeeConstants.ADD_SUCCESS:
            state.employees.push(actions.employee);
            actions.employee.sort((a, b) => b.joint_date - a.joint_date);
            return {
                employees: actions.employees,
                loading: false
            }
        case employeeConstants.ADD_FAILURE:
            return {
                error: actions.error
            };
        // update employee    
        case employeeConstants.UPDATE_REQUEST:
            return {
                ...state,
                employee: actions.employee,
                loading: true
            }
        case employeeConstants.UPDATE_SUCCESS:
            var _employees = state.employees;
            var foundIndex = _employees.findIndex(item => item.id === actions.employee.id);
            _employees[foundIndex] = actions.employee;
            _employees.sort((a, b) => b.joint_date - a.joint_date);
            //console.log(foundIndex);
            return {
                ...state,
                loading: false,
                employees: _employees,
                employee: actions.employee,
            }
        case employeeConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update employee field   
        case employeeConstants.UPDATE_FIELD_REQUEST:
            return {
                ...state,
                employee: actions.employee,
            }
        case employeeConstants.UPDATE_FIELD_SUCCESS:
            var _employees = state.employees;
            var foundIndex = _employees.findIndex(item => item.id === actions.employee.id);
            _employees[foundIndex] = actions.employee;
            return {
                ...state,
                employees: _employees,
                employee: actions.employee,
            }
        case employeeConstants.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // upload letter   
        case employeeConstants.UPLOAD_LETTER_REQUEST:
            return {
                ...state,
                employee: actions.employee,
                loading_upload_letter: true
            }
        case employeeConstants.UPLOAD_LETTER_SUCCESS:
            var _employees = state.employees;
            var foundIndex = _employees.findIndex(item => item.id === actions.employee.id);
            _employees[foundIndex] = actions.employee;
            return {
                ...state,
                loading_upload_letter: false,
                employees: _employees,
                employee: actions.employee,
            }
        case employeeConstants.UPLOAD_LETTER_FAILURE:
            return {
                ...state,
                loading_upload_letter: false,
                error: actions.error
            };
        // delete letter   
        case employeeConstants.DELETE_LETTER_REQUEST:
            return {
                ...state,
                employee: actions.employee,
            }
        case employeeConstants.DELETE_LETTER_SUCCESS:
            var _employees = state.employees;
            var foundIndex = _employees.findIndex(item => item.id === actions.employee.id);
            _employees[foundIndex] = actions.employee;
            return {
                ...state,
                employees: _employees,
                employee: actions.employee,
            }
        case employeeConstants.DELETE_LETTER_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        default:
            return state;
    }
}