import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CRow, CCol, CCard, CCardHeader, CCardBody, CCardFooter, CLabel, CFormGroup,
    CSelect,
} from '@coreui/react';
import { checkActions, housingActions, positionActions, projectActions } from '../../_actions';
import { employeeActions } from '../../_actions';
import { helps } from 'src/_helpers';
import ProjectTimekeeping from 'src/views/projects/ProjectTimekeeping';
import DatePicker from 'react-datepicker';
import locale from 'date-fns/locale/vi';
import ProjectTimeKeepingExport from 'src/views/projects/ProjectTimeKeepingExport';


class ProjectTimekeepingContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            curent_date: new Date(),
            month: new Date(),
            area_id: '',
        }
        this.handleChangeMonth = this.handleChangeMonth.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { project } = this.props;
        this.getEmployees();
        this.getPositions();
        this.getHousings();
        this.getChecks({ curent_date: this.state.month, project: project });
    }

    async getChecks(params = {}) {
        let _checks = await this.props.getChecks(params, 10000);
        return _checks;
    }

    async getEmployees() {
        const { employees } = this.props;
        if (helps.isEmpty(employees)) {
            let _employees = await this.props.getEmployees();
            return _employees;
        }
    }

    async getPositions() {
        const { positions } = this.props;
        if (helps.isEmpty(positions)) {
            let _positions = await this.props.getPositions();
            return _positions;
        }
    }

    async getHousings() {
        const { housings } = this.props;
        if (helps.isEmpty(housings)) {
            let _housings = await this.props.getHousings();
            return _housings;
        }
    }

    handleChangeMonth(value) {
        this.setState({ month: value });
        this.getChecks({ curent_date: value, project: this.props.project });
    }

    handleChange(e) {
        e.preventDefault();
        let value = e.target.value;
        let name = e.target.name;
        this.setState({ [name]: value });
    }

    render() {
        const { project, checks, positions } = this.props;
        let areas = !helps.isEmpty(project.areas) ? project.areas : [];
        let today = new Date().toISOString().slice(0, 10);
        var date_val = today;
        if (this.state.curent_date) {
            date_val = helps.formatDate_for_input(this.state.curent_date.getTime(), 'YYYY-MM-DD');
        }
        let _checkes = checks;
        if (!helps.isEmpty(this.state.area_id)) {
            let _positions = positions.filter(position => position.area_id === this.state.area_id);
            if (!helps.isEmpty(_positions)) {
                _checkes = checks.filter(check => {
                    let found = helps.getItemFromArr(_positions, check.position.id);
                    if (!helps.isEmpty(found)) return true;
                    else return false;
                });
            }
        }

        return (
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol md={5} sm={12} className='mb-2'>
                            Bảng Chấm Công Mục Tiêu: <b>{project.name}</b>
                        </CCol>
                        <CCol md={3} sm={12} className='mb-2'>
                            <CSelect
                                name='area_id'
                                value={this.state.area_id}
                                onChange={(e) => { this.handleChange(e) }}
                            >
                                <option value={''}> - Chọn Khu Vực - </option>
                                {!helps.isEmpty(areas) && areas.map(area => {
                                    return (
                                        <option key={area.id} value={area.id}>{area.name}</option>
                                    );
                                })}
                            </CSelect>
                        </CCol>
                        <CCol md={4} sm={12} className='mb-2 text-right'>
                            <CFormGroup variant='custom-radio' inline>
                                <CLabel htmlFor="curent_date" className='mt-1'>tháng: </CLabel>
                                <DatePicker
                                    locale={locale}
                                    selected={this.state.month}
                                    onChange={(date) => { this.handleChangeMonth(date) }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className='ml-2 form-control font-weight-bold'
                                />
                            </CFormGroup>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <ProjectTimekeeping
                        key={'list-' + this.state.month.toDateString()}
                        {... this.props}
                        month={this.state.month}
                        project={this.props.project}
                        area_id={this.state.area_id}
                        checks={_checkes}
                    />
                </CCardBody>
                <CCardFooter>
                    <ProjectTimeKeepingExport {... this.props} />
                </CCardFooter>
            </CCard>
        );
    }
}

function mapStateToProps(state) {
    const { loading, checks } = state.checkReducer;
    const { projects } = state.projectReducer;
    const { employees } = state.employeeReducer;
    const { positions } = state.positionReducer;
    const { user } = state.authentication;
    const { housings } = state.housingReducer;
    return { loading, user, checks, projects, employees, positions, housings };
}

const actionCreators = {
    getChecks: checkActions.getAll,
    getProjects: projectActions.getAll,
    getEmployees: employeeActions.getAll,
    getPositions: positionActions.getAll,
    getHousings: housingActions.getAll,
    add: checkActions.add,
    _delete: checkActions._delete,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ProjectTimekeepingContainer);
export { connectedContainer as ProjectTimekeepingContainer };