import { taxonomyConstants } from "src/_constants";

export function taxonomyReducer(state = {}, actions) {
    switch (actions.type) {
        // GET LIST    
        case taxonomyConstants.GET_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case taxonomyConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                taxonomies: actions.taxonomies,
                loading: false,
            }
        case taxonomyConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Add
        case taxonomyConstants.ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case taxonomyConstants.ADD_SUCCESS:
            state.taxonomies.push(actions.taxonomy);
            return {
                ...state,
                loading: false,
            }
        case taxonomyConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Edit
        case taxonomyConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case taxonomyConstants.EDIT_SUCCESS:
            var _taxonomies = state.taxonomies;
            var foundIndex = _taxonomies.findIndex(taxonomy => taxonomy.id === actions.taxonomy.id);
            _taxonomies[foundIndex] = actions.taxonomy;
            console.log(foundIndex);
            return {
                ...state,
                taxonomies: _taxonomies,
                loading: false,
            }
        case taxonomyConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        // Update Field
        case taxonomyConstants.UPDATE_FIELD_REQUEST:
            return {
                ...state,
            }
        case taxonomyConstants.UPDATE_FIELD_SUCCESS:
            var _taxonomies = state.taxonomies;
            var foundIndex = _taxonomies.findIndex(taxonomy => taxonomy.id === actions.taxonomy.id);
            _taxonomies[foundIndex] = actions.taxonomy;
            return {
                ...state,
                taxonomies: _taxonomies,
            }
        case taxonomyConstants.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                error: actions.error,
            }
        // Delete 
        case taxonomyConstants.DELETE_REQUEST:
            return {
                ...state,
                taxonomies: state.taxonomies.map(taxonomy =>
                    taxonomy.id === actions.taxonomy.id
                        ? { ...taxonomy, deleting: true }
                        : taxonomy
                )
            };
        case taxonomyConstants.DELETE_SUCCESS:
            return {
                taxonomies: state.taxonomies.filter(taxonomy => taxonomy.id !== actions.taxonomy.id)
            };
        case taxonomyConstants.DELETE_FAILURE:
            return {
                ...state,
                taxonomies: state.taxonomies.map(taxonomy => {
                    if (taxonomy.id === actions.taxonomy.id) {
                        const { deleting, ...taxonomyCopy } = taxonomy;
                        return { ...taxonomyCopy, deleteError: actions.error };
                    }
                    return taxonomy;
                })
            };

        default: return state;
    }
}