import { asset_itemConstants } from 'src/_constants';
import { asset_itemService } from 'src/_services';
import { alertActions } from 'src/_actions';

export const asset_itemActions = {
    getAll,
    getAllforComponent,
    add,
    getById,
    update,
    updateField,
    _delete,
};


function getAll(params = {}) {
    return dispatch => {
        dispatch(request(params));
        return asset_itemService.getAll(params)
            .then(
                asset_items => {
                    dispatch(success(asset_items));
                    return asset_items;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: asset_itemConstants.GETALL_REQUEST, params } }
    function success(asset_items) { return { type: asset_itemConstants.GETALL_SUCCESS, asset_items } }
    function failure(error) { return { type: asset_itemConstants.GETALL_FAILURE, error } }
}

function getAllforComponent(params = {}) {
    return dispatch => {
        dispatch(request(params));
        return asset_itemService.getAllforComponent(params)
            .then(
                asset_items => {
                    dispatch(success(asset_items));
                    return asset_items;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: asset_itemConstants.GETALL_REQUEST, params } }
    function success(asset_items) { return { type: asset_itemConstants.GETALL_SUCCESS, asset_items } }
    function failure(error) { return { type: asset_itemConstants.GETALL_FAILURE, error } }
}

function getById(id) {

    return dispatch => {
        dispatch(request());
        asset_itemService.getById(id)
            .then(
                asset_item => dispatch(success(asset_item)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: asset_itemConstants.GET_REQUEST } }
    function success(asset_item) { return { type: asset_itemConstants.GET_SUCCESS, asset_item } }
    function failure(error) { return { type: asset_itemConstants.GET_FAILURE, error } }
}

function add(asset_item) {
    return dispatch => {
        dispatch(request(asset_item));

        return asset_itemService.add(asset_item)
            .then(
                asset_item => {
                    dispatch(success(asset_item));
                    dispatch(alertActions.success('Thêm mới nhập/xuất Tài Sản thành công!'));
                    return asset_item;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(asset) { return { type: asset_itemConstants.ADD_REQUEST, asset } }
    function success(asset) { return { type: asset_itemConstants.ADD_SUCCESS, asset } }
    function failure(error) { return { type: asset_itemConstants.ADD_FAILURE, error } }
}

function update(asset_item) {
    return dispatch => {
        dispatch(request(asset_item));
        asset_itemService.update(asset_item)
            .then(
                asset_item => {
                    dispatch(success(asset_item));
                    dispatch(alertActions.success('Cập nhật thông tin nhập/xuất Tài Sản thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(asset_item) { return { type: asset_itemConstants.UPDATE_REQUEST, asset_item } }
    function success(asset_item) { return { type: asset_itemConstants.UPDATE_SUCCESS, asset_item } }
    function failure(error) { return { type: asset_itemConstants.UPDATE_FAILURE, error } }
}

function updateField(asset_item, field) {
    return dispatch => {
        dispatch(request(asset_item, field));
        asset_itemService.updateField(asset_item, field)
            .then(
                asset_item => {
                    dispatch(success(asset_item));
                    dispatch(alertActions.success('Cập nhật thông tin nhập/xuất Tài Sản thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(asset_item, field) { return { type: asset_itemConstants.UPDATE_FIELD_REQUEST, asset_item, field } }
    function success(asset_item) { return { type: asset_itemConstants.UPDATE_FIELD_SUCCESS, asset_item } }
    function failure(error) { return { type: asset_itemConstants.UPDATE_FIELD_FAILURE, error } }
}

function _delete(asset_item) {
    return dispatch => {
        dispatch(request(asset_item));
        return asset_itemService._delete(asset_item)
            .then(
                asset_item => {
                    dispatch(success(asset_item));
                    dispatch(alertActions.success('Xoá nhập/xuất Tài Sản thành công!'));
                    return asset_item;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(asset_item) { return { type: asset_itemConstants.DELETE_REQUEST, asset_item } }
    function success(asset_item) { return { type: asset_itemConstants.DELETE_SUCCESS, asset_item } }
    function failure(error) { return { type: asset_itemConstants.DELETE_FAILURE, error } }
}


