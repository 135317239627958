import React, { Component } from 'react'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CDataTable,
    CRow,
    CLink,
    CSelect,
    CCardFooter,
    CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import configs from 'src/appConfigs';
import { CChart } from '@coreui/react-chartjs';

class Employees extends Component {

    constructor(props) {
        super(props);

        this.state = {
            job_type: '',
            group: '',
            position: '',
            department: '',
            gen: '',
        }

        this.pageChange = this.pageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const queryPage = this.props.location.search.match(/page=([0-9]+)/, '');
        const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
        this.setState({ currentPage: currentPage });
    }

    pageChange = (newPage) => {
        var currentPage = this.state.currentPage;
        currentPage !== newPage && this.props.history.push(`/users?page=${newPage}`)
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    render() {
        const { employees, projects } = this.props;
        var _employees = [];
        var _today = new Date();
        let month = _today.getMonth();
        var cntNVCT = 0;
        var cntDNV = 0;
        var cntNVTV = 0;
        var cntCTV = 0;
        var months_data = [];
        for (let i = 0; i < 12; i++) {
            months_data[i] = 0;
        }
        if (employees && employees.length > 0) {
            employees.forEach((employee, index) => {
                switch (employee.job_type) {
                    case 'NVCT':
                        cntNVCT = cntNVCT + 1;
                        break;
                    case 'DNV':
                        cntDNV = cntDNV + 1;
                        break;
                    case 'NVTV':
                        cntNVTV = cntNVTV + 1;
                        break;
                    case 'CTV':
                        cntCTV = cntCTV + 1;
                        break;
                    default:
                }
                if (employee.joint_date) {
                    let join_date = employee.joint_date;
                    if (join_date.getFullYear() === _today.getFullYear()) {
                        months_data[join_date.getMonth()] = months_data[join_date.getMonth()] + 1;
                    }
                }
                let birthdate = employee.birthdate;
                if (month === birthdate.getMonth() && employee.job_type === 'NVCT') {
                    if (employee.joint_date) {
                        employee['_joint_date'] = helps.formatDate_from_Timestamp(employee.joint_date);
                    }
                    if (employee.birthdate) {
                        employee['_birthdate'] = helps.formatDate_from_Timestamp(employee.birthdate);
                    }
                    if (employee.bank_account_number) {
                        employee['_bank'] = employee.bank_account_number;//.'<br>('.
                        if (employee.bank_name) {
                            employee['_bank'] = employee['_bank'] + ' (' + employee.bank_name + ')';
                        }
                    } else {
                        employee['_bank'] = '';
                    }
                    if (employee.basic_salary) {
                        employee['_basic_salary'] = parseInt(employee.basic_salary).toLocaleString('en');
                    } else {
                        employee['_basic_salary'] = '';
                    }
                    var employee_projects = [];
                    employee_projects = projects.filter((project) => {
                        if (project.employees) {
                            var found = '';
                            found = helps.getItemFromArr(project.employees, employee.employee_id, 'employee_id');
                            if (!helps.isEmpty(found)) return true;
                            else return false;
                        } else {
                            return false;
                        }
                    })
                    //console.log(employee_projects)
                    employee['project'] = '';
                    if (!helps.isEmpty(employee_projects)) {
                        let employee_project = employee_projects[0];
                        employee['project'] = employee_project.name;
                        employee['project_id'] = employee_project.id;
                    }
                    employee['index'] = index + 1;
                    _employees.push(employee);
                }
            });
        }
        if (!helps.isEmpty(this.state.group)) {
            _employees = _employees.filter((item) => {
                return item.group == this.state.group;
            });
        }
        if (!helps.isEmpty(this.state.department)) {
            _employees = _employees.filter((item) => {
                return item.department == this.state.department;
            });
        }
        if (!helps.isEmpty(this.state.gen)) {
            _employees = _employees.filter((item) => {
                return item.gen == this.state.gen;
            });
        }
        if (!helps.isEmpty(this.state.position)) {
            _employees = _employees.filter((item) => {
                return item.position == this.state.position;
            });
        }
        if (!helps.isEmpty(this.state.job_type)) {
            _employees = _employees.filter((item) => {
                return item.job_type == this.state.job_type;
            });
        }
        const fields = [
            { key: 'index', label: '#', _style: { width: '10px' }, filter: false, sorter: false },
            { key: 'fullname', label: 'Họ & Tên', _classes: 'font-weight-bold', _style: { width: '15%' } },
            { key: 'employee_id', label: 'MSNV', _classes: 'font-weight-bold', _style: { width: '12%' } },
            { key: '_joint_date', label: 'Ngày Vào Cty', filter: false, sorter: true },
            { key: 'phone', label: 'ĐT' },
            { key: '_birthdate', label: 'Năm Sinh', _classes: 'font-weight-bold', filter: false },
            { key: 'job_type', label: 'Loại Hình' },
        ]
        const pieChart = {
            labels: [
                'Nhân Viên chính thức',
                'Nhân Viên thời vụ',
                'Cộng tác viên',
                'Đã nghỉ việc',
            ],
            datasets: [
                {
                    data: [cntNVCT, cntNVTV, cntCTV, cntDNV],
                    backgroundColor: [
                        '#3399ff',
                        '#f9b115',
                        '#ced2d8',
                        '#e55353',
                    ],
                    hoverBackgroundColor: [
                        '#3399ff',
                        '#f9b115',
                        '#ced2d8',
                        '#e55353',
                    ],
                }],
        };

        const barChart = {
            labels: ['Tháng 01', 'Tháng 02', 'Tháng 03', 'Tháng 04', 'Tháng 05', 'Tháng 06', 'Tháng 08', 'Tháng 09', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
            datasets: [
                {
                    label: 'Tuyển Dụng ' + _today.getFullYear(),
                    backgroundColor: 'rgba(239,121,6,0.5)',
                    borderColor: 'rgba(239,121,6,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(198,98,0,0.4)',
                    hoverBorderColor: 'rgba(198,98,0,1)',
                    data: months_data,
                },
            ],
        };
        const options = {
            // tooltips: {
            //   enabled: false,
            //   custom: customTooltips
            // },
            maintainAspectRatio: false
        }

        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol xl={12} md={12}>
                            <CCard>
                                <CCardHeader>
                                    Thống Kê Nhân Sự
                                </CCardHeader>
                                <CCardBody>
                                    <CRow>
                                        <CCol xl={6} md={6} sm={12}>
                                            <CChart type="pie" datasets={pieChart.datasets} labels={pieChart.labels} />
                                        </CCol>
                                        <CCol xl={6} md={6} sm={12}>
                                            <CChart type="bar" datasets={barChart.datasets} options={options} labels="months" />
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol xl={12} md={12}>
                            <CCard>
                                <CCardHeader>
                                    <CRow>
                                        <CCol>
                                            Sinh Nhật Trong Tháng {_today.getMonth() + 1}
                                        </CCol>
                                        <CCol className='text-right'>
                                            <CLink onClick={() => { this.props.history.push('/employees') }}><small>Quản Lý Nhân Sự</small></CLink>
                                        </CCol>
                                    </CRow>
                                </CCardHeader>
                                <CCardBody>
                                    <CDataTable
                                        items={_employees}
                                        fields={fields}
                                        columnFilter={false}
                                        tableFilter={false}
                                        itemsPerPageSelect={false}
                                        itemsPerPage={configs.per_page}
                                        hover
                                        sorter={false}
                                        pagination={false}
                                        striped
                                        columnFilterSlot={{
                                            group: (
                                                <>
                                                    <CSelect
                                                        name="group"
                                                        id="input-group"
                                                        className="form-control form-control-sm"
                                                        value={this.state.group}
                                                        onChange={(e) => { this.handleChange('group', e) }}
                                                    >
                                                        <option value="">-Tất cả-</option>
                                                        {configs.groups && Object.keys(configs.groups).map((_key) => {
                                                            return (
                                                                <option value={_key} key={'group_' + _key}>{configs.groups[_key]}</option>
                                                            )
                                                        }
                                                        )}
                                                    </CSelect>
                                                </>
                                            ),
                                            department: (
                                                <>
                                                    <CSelect
                                                        name="department"
                                                        id="input-department"
                                                        className="form-control form-control-sm"
                                                        value={this.state.department}
                                                        onChange={(e) => { this.handleChange('department', e) }}
                                                    >
                                                        <option value="">-Tất cả-</option>
                                                        {configs.departments && Object.keys(configs.departments).map((_key) => {
                                                            return (
                                                                <option value={_key} key={'department' + _key}>{configs.departments[_key]}</option>
                                                            )
                                                        }
                                                        )}
                                                    </CSelect>
                                                </>
                                            ),
                                            position: (
                                                <>
                                                    <CSelect
                                                        name="position"
                                                        id="input-position"
                                                        className="form-control form-control-sm"
                                                        value={this.state.position}
                                                        onChange={(e) => { this.handleChange('position', e) }}
                                                    >
                                                        <option value="">-Tất cả-</option>
                                                        {configs.positions && Object.keys(configs.positions).map((_key) => {
                                                            return (
                                                                <option value={_key} key={'positions' + _key}>{configs.positions[_key]}</option>
                                                            )
                                                        }
                                                        )}
                                                    </CSelect>
                                                </>
                                            ),
                                            gen: (
                                                <>
                                                    <CSelect
                                                        name="gen"
                                                        id="input-gen"
                                                        className="form-control form-control-sm"
                                                        value={this.state.gen}
                                                        onChange={(e) => { this.handleChange('gen', e) }}
                                                    >
                                                        <option value="">-Tất cả-</option>
                                                        {configs.gens && Object.keys(configs.gens).map((_key) => {
                                                            return (
                                                                <option value={_key} key={'gen' + _key}>{configs.gens[_key]}</option>
                                                            )
                                                        }
                                                        )}
                                                    </CSelect>
                                                </>
                                            ),
                                            job_type: (
                                                <>
                                                    <CSelect
                                                        name="job_type"
                                                        id="input-job_type"
                                                        className="form-control form-control-sm"
                                                        value={this.state.job_type}
                                                        onChange={(e) => { this.handleChange('job_type', e) }}
                                                    >
                                                        <option value="">-Tất cả-</option>
                                                        {configs.job_types && Object.keys(configs.job_types).map((_key) => {
                                                            return (
                                                                <option value={_key} key={'job_type' + _key}>{configs.job_types[_key]}</option>
                                                            )
                                                        }
                                                        )}
                                                    </CSelect>
                                                </>
                                            )
                                        }}
                                        scopedSlots={{
                                            'fullname': (item) => {
                                                return (
                                                    <td className="py-2">
                                                        <CLink className="text-dark" onClick={() => { this.props.history.push(`/employees/view/${item.id}`) }}>
                                                            <b>{item.fullname}</b>
                                                        </CLink>
                                                    </td>
                                                )
                                            },
                                            'employee_id': (item) => {
                                                return (
                                                    <td className="py-2">
                                                        <CLink className="text-dark" onClick={() => { this.props.history.push(`/employees/view/${item.id}`) }}>
                                                            <b>{item.employee_id}</b>
                                                        </CLink>
                                                    </td>
                                                )
                                            },
                                            'department': (item) => {
                                                return (
                                                    <td className="py-2">
                                                        {configs.departments[item.department]}
                                                    </td>
                                                )
                                            },
                                            'position': (item) => {
                                                return (
                                                    <td className="py-2">
                                                        {configs.positions[item.position]}
                                                    </td>
                                                )
                                            },
                                            'gen': (item) => {
                                                return (
                                                    <td className="py-2">
                                                        {configs.gens[item.gen]}
                                                    </td>
                                                )
                                            },
                                            'job_type': (item) => {
                                                var color = 'primary';
                                                switch (item.job_type) {
                                                    case 'DNV':
                                                        color = 'danger';
                                                        break;
                                                    case 'NVTV':
                                                        color = 'warning';
                                                        break;
                                                    case 'CTV':
                                                        color = 'secondary';
                                                        break;
                                                    default:
                                                        color = 'primary';
                                                }
                                                return (
                                                    <td className="py-2">
                                                        <CBadge color={color}>{configs.job_types[item.job_type]}</CBadge>
                                                    </td>
                                                )
                                            },
                                            'project': (item) => {
                                                return (
                                                    <td className="py-2">
                                                        <CLink className="text-dark" onClick={() => { this.props.history.push(`/projects/view/${item.project_id}`) }}>
                                                            <b>{item.project}</b>
                                                        </CLink>
                                                    </td>
                                                )
                                            },
                                        }}
                                    />
                                </CCardBody>
                                <CCardFooter>
                                    Tổng: <b>{_employees && <>{_employees.length}</>}</b>
                                </CCardFooter>
                            </CCard>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        )
    }
}


export default Employees;
