import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from 'src/containers/ThePermission';
import { helps } from 'src/_helpers';
import { candidateActions, employeeActions, userActions } from 'src/_actions';
import CandidatesForm from 'src/views/employees/candidates/CandidatesForm';
import configs from 'src/appConfigs';


class CandidatesFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            candidate: {},
            form_header: "Thêm Ứng Viên Mới",
            action_status: 'add',
        }
    }

    componentDidMount() {
        this.getEmployees();
        this.getCandidates().then(() => {
            this.getCandidate();
        });

    }

    getCandidate() {
        let candidate_id = this.props.match.params.id;
        const { candidates } = this.props;
        if (!helps.isEmpty(candidate_id)) {
            let candidate = helps.getItemFromArr(candidates, candidate_id);
            this.setState({ form_header: "Chỉnh Sửa Ứng Viên: " + candidate.fullname, action_status: 'edit', candidate: candidate });
        }
    }

    async getCandidates() {
        const candidates = this.props.candidates;
        if (!candidates) await this.props.getAll();
    }

    async getEmployees() {
        const { employees } = this.props;
        if (helps.isEmpty(employees)) return await this.props.getEmployees();
    }

    handleSubmit = (candidate) => {
        let id = this.props.match.params.id;
        if (helps.isEmpty(id)) {
            this.props.add(candidate);
        } else {
            this.props.update(candidate);
        }
        this.props.history.push('/employees/candidates');
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        let candidate_id = this.props.match.params.id;
        const { candidate } = this.state;

        if (!helps.isEmpty(candidate_id) && helps.isEmpty(candidate)) return (<Loading></Loading>);
        return (
            <ThePermission {... this.props} component="employees" action="edit">
                <CandidatesForm
                    {... this.props}
                    candidate={this.state.candidate}
                    form_header={this.state.form_header}
                    action_status={this.state.action_status}
                    handleSubmit={this.handleSubmit}
                />
            </ThePermission>
        );
    }
}
function mapStateToProps(state) {
    const { loading, candidates } = state.candidateReducer;
    const { employees } = state.employeeReducer;
    const { user } = state.authentication;
    return { loading, candidates, user, employees };
}

const actionCreators = {
    getAll: candidateActions.getAll,
    add: candidateActions.add,
    update: candidateActions.update,
    getEmployees: employeeActions.getAll,
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(CandidatesFormContainer);
export { connectedFormContainer as CandidatesFormContainer };