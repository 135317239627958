import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CTooltip,
    CButton,
    CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';
import { taskService } from 'src/_services';

class TaskFollowStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    componentDidMount() {

    }

    doFollow() {
        const { task, user } = this.props;
        var followers = task.followers ? task.followers : [];
        let found_me = helps.getItemFromArr(followers, user.id, 'value');
        if (helps.isEmpty(found_me)) {
            let follower = {
                value: user.id,
                label: user.fullname + '-' + user.username
            };
            taskService.addFollower(task, follower);
        }

    }

    render() {
        const { task, user } = this.props;
        let is_followed = false;
        if (!helps.isEmpty(task) && !helps.isEmpty(task.followers)) {
            let found_me = helps.getItemFromArr(task.followers, user.id, 'value');
            if (!helps.isEmpty(found_me)) {
                is_followed = true;
            }

        }
        //console.log(this.state.is_followed)
        return (
            <CRow>
                <CCol xl={12}>
                    {!is_followed && task &&
                        <CTooltip content="Theo Dõi">
                            <CButton
                                size="sm"
                                className="btn btn-primary btn-sm mr-2 mb-2"
                                onClick={() => { this.doFollow() }}
                            >
                                <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                Theo Dõi
                            </CButton>
                        </CTooltip>
                    }
                    {is_followed && task &&
                        <CBadge color='secondary' className='p-2'>Đang theo dõi</CBadge>
                    }
                </CCol>
            </CRow >
        )
    }
}
export default TaskFollowStatus;
