import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CRow,
    CCol,
    CButton,
    CForm,
    CTooltip,
    CInputRadio,
    CFormGroup,
    CLabel,
    CCallout,
    CSelect,
    CCard,
    CCardHeader,
    CCardBody,
    CCardFooter,
    CInput,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import Select from 'react-select';
import { helps } from 'src/_helpers';
import INexport from './INexport';

class QTTIN extends Component {

    constructor(props) {
        super(props);

        this.state = {
            head_text: {},
            from_to: 'employee',
            store_id: '',
            employee_id: '',
            project_id: '',
            vendor_id: '',
            housing_id: '',
            employee: {},
            vendor: {},
            housing: {},
            asset_date: new Date(),
            errors: [],
            selected_items: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeSelectItems = this.handleChangeSelectItems.bind(this);
        this.handleChangeItems = this.handleChangeItems.bind(this);
    }

    componentDidMount() {
        this.setState({
            head_text: {
                'employee': 'Thu Hồi Từ Nhân Sự',
                'vendor': 'Nhập Từ Nhà Cung Cấp',
                'project': 'Thu Hồi Từ Mục Tiêu',
                'housing': 'Thu Hồi Từ Nhà Đội',
            }
        })
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeSelect(selectedOptions, field) {
        switch (field) {
            case 'employee':
                this.setState({ employee_id: selectedOptions['value'] });
                break;
            case 'vendor':
                this.setState({ vendor_id: selectedOptions['value'] });
                break;
            case 'project':
                this.setState({ project_id: selectedOptions['value'] });
                break;
            case 'housing':
                this.setState({ housing_id: selectedOptions['value'] });
                break;
            default:
        }
        this.setState({ [[field]]: selectedOptions });
        //console.log(selectedOptions);
    }

    handleChangeSelectItems(selectedOptions, index) {
        const { items } = this.props;
        var selected_items = this.state.selected_items;
        let item = helps.getItemFromArr(items, selectedOptions.value);
        selected_items[index].item_id = selectedOptions.value;
        selected_items[index].dimension = item.dimension;
        selected_items[index].status = item.status;
        this.setState({ selected_items: selected_items });
        console.log(selected_items);
    }

    handleChangeItems(field, index, e) {
        var selected_items = this.state.selected_items;
        var selected_item = selected_items[index];
        selected_item[[field]] = e.target.value;
        if (field === 'quantity') selected_item[[field]] = parseInt(e.target.value);
        selected_items[index] = selected_item;
        this.setState({ selected_items: selected_items });
        console.log(selected_items);
    }

    removeItem(index) {
        var selected_items = this.state.selected_items;
        if (index > -1) { // only splice array when item is found
            selected_items.splice(index, 1); // 2nd parameter means remove one item only
        }
        this.setState({ selected_items: selected_items });
        console.log(selected_items);
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let record = {
                id: this.state.id,
                asset_date: this.state.asset_date,
                category_id: this.props.category_id,
                from_to: this.state.from_to,
                state: 'in',
                store_id: this.state.store_id,
                project_id: this.state.project_id,
                vendor_id: this.state.vendor_id,
                employee_id: this.state.employee_id,
                housing_id: this.state.housing_id,
                selected_items: this.state.selected_items,
            }
            this.props.handleSubmit(record);
        } else {
            return false;
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.store_id)) {
            formIsValid = false;
            errors["store_id"] = "Vui lòng chọn Kho.";
        }

        if (this.state.from_to === 'employee' && helps.isEmpty(this.state.employee_id)) {
            formIsValid = false;
            errors["employee"] = "Vui lòng chọn Nhân sự.";
        }

        if (this.state.from_to === 'project' && helps.isEmpty(this.state.project_id)) {
            formIsValid = false;
            errors["project"] = "Vui lòng chọn Mục tiêu.";
        }

        if (this.state.from_to === 'vendor' && helps.isEmpty(this.state.vendor_id)) {
            formIsValid = false;
            errors["vendor"] = "Vui lòng chọn Nhà cung cấp.";
        }

        if (this.state.from_to === 'housing' && helps.isEmpty(this.state.housing_id)) {
            formIsValid = false;
            errors["housing"] = "Vui lòng chọn Nhà đội.";
        }

        if (helps.isEmpty(this.state.selected_items)) {
            formIsValid = false;
            errors["selected_items"] = "Vui lòng thêm danh sách Tài sản.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    addMoreItem() {
        var selected_items = this.state.selected_items;
        selected_items.push({
            item_id: '',
            dimension: '',
            quantity: 1,
        });
        this.setState({ selected_items: selected_items });
    }

    renderItems() {
        const { items } = this.props;
        var item_options = [];
        if (items && items.length > 0) {
            items.map((item) => {
                item_options.push({ value: item.id, label: item.code + ' - ' + item.name });
            });
        }

        var trs = [];
        if (!helps.isEmpty(this.state.selected_items)) {
            this.state.selected_items.map((item, index) => {
                let found_item = helps.getItemFromArr(items, item.item_id);
                let inventory = found_item.inventory ? found_item.inventory : [];
                let found_inventory = helps.getItemFromArr(inventory, this.state.store_id, 'store_id');
                let recent_inventory = 0;
                //console.log(found_item);
                if (!helps.isEmpty(found_inventory)) {
                    recent_inventory = found_inventory.current_inventory;
                }
                let inventory_color = 'danger';
                if (recent_inventory > 0) {
                    inventory_color = 'success';
                }
                let selected_option = {
                    value: found_item.id,
                    label: found_item.name,
                }
                trs.push(
                    <tr key={'item-' + index}>
                        <td style={{ textAlign: 'center' }}>{index + 1}</td>
                        <td>
                            <Select
                                id={"input-vendors-" + item.id}
                                placeholder="Tài sản ..."
                                options={item_options}
                                value={selected_option}
                                onChange={(choice) => { this.handleChangeSelectItems(choice, index) }}
                            />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <CInput
                                type='text'
                                name='dimension'
                                value={item.dimension}
                                onChange={(e) => { this.handleChangeItems('dimension', index, e) }}
                            />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <CInputGroup>
                                <CInput
                                    type='number'
                                    name='quantity'
                                    value={item.quantity}
                                    onChange={(e) => { this.handleChangeItems('quantity', index, e) }}
                                />
                                <CInputGroupAppend>
                                    <CPopover content={'Tồn kho hiện tại: ' + recent_inventory}>
                                        <CInputGroupText className={'bg-' + inventory_color + ' text-white'}>
                                            {recent_inventory}
                                        </CInputGroupText>
                                    </CPopover>
                                </CInputGroupAppend>
                            </CInputGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <CSelect
                                name='status'
                                value={item.status}
                                onChange={(e) => { this.handleChangeItems('status', index, e) }}
                            >
                                {configs.asset_statuses && configs.asset_statuses.map((status) => {
                                    return (
                                        <option key={index + '-' + status.id} value={status.id}>{status.label}</option>
                                    )
                                })}
                            </CSelect>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <CTooltip content={'Xóa'}>
                                <CButton
                                    type='button'
                                    size='sm'
                                    color="danger"
                                    onClick={() => { this.removeItem(index) }}
                                >
                                    <CIcon name="cil-x" className="mfe-1 mt-0"></CIcon>
                                </CButton>
                            </CTooltip>
                        </td>
                    </tr>
                );
            })
        }
        return trs;
    }

    render() {
        const { employees, vendors, stores, projects, housings } = this.props;
        var employee_options = [];
        if (employees && employees.length > 0) {
            employees.map((employee) => {
                employee_options.push({ value: employee.employee_id, label: employee.fullname + ' (' + employee.employee_id + ')' });
            });
        }
        var vendor_options = [];
        if (vendors && vendors.length > 0) {
            vendors.map((vendor) => {
                let tags_name = [];
                if (!helps.isEmpty(vendor.tags)) {
                    vendor.tags.map((tag) => {
                        tags_name.push(tag.label);
                    })
                }
                let tags_str = tags_name.join(' / ');
                let vendor_name = helps.isEmpty(tags_name) ? vendor.name : vendor.name + ' (' + tags_str + ')';
                vendor_options.push({ value: vendor.id, label: vendor_name });
            });
        }
        var project_options = [];
        if (projects && projects.length > 0) {
            projects.map((project) => {
                project_options.push({ value: project.id, label: project.name });
            });
        }

        var housing_options = [];
        if (housings && housings.length > 0) {
            housings.map((housing) => {
                housing_options.push({ value: housing.id, label: housing.name });
            });
        }

        var filter_date_val = '';
        let asset_date = this.state.asset_date;
        if (this.state.asset_date) {
            if (!helps.isEmpty(asset_date) && typeof asset_date === 'object') {
                filter_date_val = helps.formatDate_for_input(asset_date.toDate(), 'YYYY-MM-DD');
            } else {
                filter_date_val = helps.formatDate_for_input(asset_date, 'YYYY-MM-DD');
            }

        } else {
            filter_date_val = new Date().toISOString().slice(0, 10);
        }

        return (
            <CForm onSubmit={(e) => this.handleSubmit(e)}>
                <CRow>
                    <CCol md={3}>
                        <CCallout color='warning' className='mb-5 pb-3 pt-3'>
                            <CFormGroup variant="custom-radio" inline>
                                <CInputRadio
                                    custom
                                    id={"from_employee"}
                                    name="from_to"
                                    value='employee'
                                    checked={this.state.from_to === 'employee'}
                                    onChange={(e) => { this.handleChange('from_to', e) }}
                                />
                                <CLabel variant="custom-checkbox" htmlFor="from_employee" className="cursor-pointer font-weight-bold">
                                    {this.state.head_text['employee']}
                                </CLabel>
                            </CFormGroup>
                        </CCallout>
                        <CCallout color='success' className='mb-5 pb-3 pt-3'>
                            <CFormGroup variant="custom-radio" inline>
                                <CInputRadio
                                    custom
                                    id={"from_project"}
                                    name="from_to"
                                    value='project'
                                    checked={this.state.from_to === 'project'}
                                    onChange={(e) => { this.handleChange('from_to', e) }}
                                />
                                <CLabel variant="custom-checkbox" htmlFor="from_project" className="cursor-pointer font-weight-bold">
                                    {this.state.head_text['project']}
                                </CLabel>
                            </CFormGroup>
                        </CCallout>
                        <CCallout color='primary' className='mb-5 pb-3 pt-3'>
                            <CFormGroup variant="custom-radio" inline>
                                <CInputRadio
                                    custom
                                    id={"from_vendor"}
                                    name="from_to"
                                    value='vendor'
                                    checked={this.state.from_to === 'vendor'}
                                    onChange={(e) => { this.handleChange('from_to', e) }}
                                />
                                <CLabel variant="custom-checkbox" htmlFor="from_vendor" className="cursor-pointer font-weight-bold">
                                    {this.state.head_text['vendor']}
                                </CLabel>
                            </CFormGroup>
                        </CCallout>
                        <CCallout color='info' className='mb-5 pb-3 pt-3'>
                            <CFormGroup variant="custom-radio" inline>
                                <CInputRadio
                                    custom
                                    id={"from_housing"}
                                    name="from_to"
                                    value='housing'
                                    checked={this.state.from_to === 'housing'}
                                    onChange={(e) => { this.handleChange('from_to', e) }}
                                />
                                <CLabel variant="custom-checkbox" htmlFor="from_housing" className="cursor-pointer font-weight-bold">
                                    {this.state.head_text['housing']}
                                </CLabel>
                            </CFormGroup>
                        </CCallout>
                    </CCol>
                    <CCol md={9}>
                        <CCard>
                            <CCardHeader>
                                {this.state.head_text[this.state.from_to]}
                            </CCardHeader>
                            <CCardBody>
                                <div className='mb-3'>
                                    <div className='mb-3'>
                                        <CRow>
                                            <CCol>
                                                <CLabel htmlFor='input-stores'>Kho:</CLabel>
                                                <CSelect
                                                    id='input-stores'
                                                    onChange={(e) => { this.handleChange('store_id', e) }}
                                                    required
                                                >
                                                    <option value=''> - Chọn Kho - </option>
                                                    {!helps.isEmpty(stores) && stores.map((store) => {
                                                        return (
                                                            <option key={store.id} value={store.id}>{store.name}</option>
                                                        );
                                                    })}
                                                </CSelect>
                                                <small className="text-danger">{this.state.errors['store_id']}</small>
                                            </CCol>
                                            <CCol>
                                                <CLabel htmlFor='asset-date'>Ngày Nhập / Thu Hồi:</CLabel>
                                                <CInput
                                                    type='date'
                                                    id='asset-date'
                                                    name='asset_date'
                                                    value={filter_date_val}
                                                    onChange={(e) => { this.handleChange('asset_date', e) }}
                                                />
                                            </CCol>
                                        </CRow>
                                    </div>
                                    <CRow>
                                        <CCol>
                                            {this.state.from_to === 'employee' && <>
                                                <CLabel htmlFor='input-employees'>Nhân Sự:</CLabel>
                                                <Select
                                                    id="input-employees"
                                                    placeholder="Nhân sự ..."
                                                    options={employee_options}
                                                    value={this.state.employee}
                                                    isClearable={true}
                                                    onChange={(choice) => { this.handleChangeSelect(choice, 'employee') }}
                                                />
                                                <small className="text-danger">{this.state.errors['employee']}</small>
                                            </>}
                                            {this.state.from_to === 'project' && <>
                                                <CLabel htmlFor='input-projects'>Mục tiêu:</CLabel>
                                                <Select
                                                    id="input-projects"
                                                    placeholder="Mục tiêu ..."
                                                    options={project_options}
                                                    value={this.state.project}
                                                    isClearable={true}
                                                    onChange={(choice) => { this.handleChangeSelect(choice, 'project') }}
                                                />
                                                <small className="text-danger">{this.state.errors['project']}</small>
                                            </>}
                                            {this.state.from_to === 'vendor' && <>
                                                <CLabel htmlFor='input-vendors'>Nhà Cung Cấp:</CLabel>
                                                <Select
                                                    id="input-vendors"
                                                    placeholder="Nhà Cung Cấp ..."
                                                    options={vendor_options}
                                                    value={this.state.vendor}
                                                    isClearable={true}
                                                    onChange={(choice) => { this.handleChangeSelect(choice, 'vendor') }}
                                                />
                                                <small className="text-danger">{this.state.errors['vendor']}</small>
                                            </>}
                                            {this.state.from_to === 'housing' && <>
                                                <CLabel htmlFor='input-housings'>Nhà Đội:</CLabel>
                                                <Select
                                                    id="input-housings"
                                                    placeholder="Nhà Đội ..."
                                                    options={housing_options}
                                                    value={this.state.housing}
                                                    isClearable={true}
                                                    onChange={(choice) => { this.handleChangeSelect(choice, 'housing') }}
                                                />
                                                <small className="text-danger">{this.state.errors['housing']}</small>
                                            </>}
                                        </CCol>
                                        {this.state.from_to === 'project' || this.state.from_to === 'housing' && <CCol>
                                            <CLabel htmlFor='input-revoked'>Thu hồi từ:</CLabel>
                                            <Select
                                                id="input-revoked"
                                                placeholder="Thu hồi từ ..."
                                                options={employee_options}
                                                value={this.state.employee}
                                                isClearable={true}
                                                onChange={(choice) => { this.handleChangeSelect(choice, 'employee') }}
                                            />
                                            <small className="text-danger">{this.state.errors['selected_revoked']}</small>
                                        </CCol>}
                                    </CRow>
                                </div>
                                <div className='mb-3'>
                                    <div className='mb-3'>
                                        <CRow>
                                            <CCol>
                                                <b>Danh sách tài sản:</b>
                                            </CCol>
                                        </CRow>
                                        <small className="text-danger">{this.state.errors['selected_items']}</small>
                                    </div>
                                    <table class="table table-bordered">
                                        <thead>
                                            <th style={{ width: '5%', textAlign: 'center' }}>#</th>
                                            <th style={{ width: '40%' }}>
                                                Tài sản
                                            </th>
                                            <th style={{ width: '20%', textAlign: 'center' }}>
                                                Kích thước/chủng loại
                                            </th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>
                                                Số lượng
                                            </th>
                                            <th style={{ width: '15%', textAlign: 'center' }}>
                                                Tình trạng
                                            </th>
                                            <th style={{ width: '5%', textAlign: 'center' }}>
                                                X
                                            </th>
                                        </thead>
                                        <tbody>
                                            {this.renderItems()}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={6} className='text-right'>
                                                    <CTooltip content={'Thêm'}>
                                                        <CButton
                                                            type='button'
                                                            size='sm'
                                                            color="primary"
                                                            onClick={() => { this.addMoreItem() }}
                                                        >
                                                            <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                                            Thêm
                                                        </CButton>
                                                    </CTooltip>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </CCardBody>
                            <CCardFooter>
                                <CRow>
                                    <CCol>
                                        <CTooltip content={'Nhập / Thu Hồi'}>
                                            <CButton type="submit" color="primary">
                                                <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                                Nhập / Thu Hồi
                                            </CButton>
                                        </CTooltip>
                                    </CCol>
                                    <CCol className={'text-right'}>
                                        <INexport {... this.props} data={this.state} />
                                    </CCol>
                                </CRow>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                </CRow>
            </CForm>
        )
    }
}

export default QTTIN;
