import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CTextarea,
    CInput,
    CModal,
    CModalHeader,
    CModalBody,
    CFormGroup,
    CInputRadio,
    CBadge,
    CModalFooter,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import Editor from 'src/_components/Editor';
import configs from 'src/appConfigs';

class ViolationSettingsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            id: '',
            added_time: new Date(),
            added_by: '',
            name: '',
            description: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEditor = this.handleChangeEditor.bind(this);
    }

    componentDidMount() {
        const { violation_type } = this.props;
        if (!helps.isEmpty(violation_type)) {
            this.setState(Object.assign(this.state, violation_type));
        } else {
            const added_by = this.props.user.id;

            this.setState({
                errors: {},
                added_time: new Date(),
                added_by: added_by,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let violation_type = {
                id: this.state.id,
                name: this.state.name,
                description: this.state.description,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
            }
            this.props.handleSubmit(violation_type);
        } else {
            return false;
        }
    }

    handleChangeEditor(field, value) {
        this.setState({
            [field]: value
        });
    }

    handleChange(field, e) {
        let value = e.target.value;
        this.setState({
            [field]: value
        });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập Tiêu đề Danh mục Vi Phạm.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        return (
            <CForm onSubmit={(e) => this.handleSubmit(e)}>
                <CModal
                    size='lg'
                    show={this.props.modals.includes(this.props.item)}
                    onClose={() => { this.props.toggleModal(this.props.item) }}
                    className='text-left'
                >
                    <CModalHeader closeButton>
                        <div>
                            <h3>{this.props.form_header}</h3>
                        </div>
                    </CModalHeader>
                    <CModalBody>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CLabel htmlFor="input-name">Tiêu đề Danh mục Chế Tài:</CLabel>
                                <CInput
                                    type='text'
                                    id='input-name'
                                    name='name'
                                    defaultValue={this.state.name}
                                    onChange={(e) => this.handleChange('name', e)}
                                />
                                <small className="text-danger">{this.state.errors['name']}</small>
                                <small className='text-muted'><i>VD: Vi Phạm về Trang phục, Đồng phục</i></small>
                            </CCol>
                        </CRow>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CLabel htmlFor="input-description">Mô tả:</CLabel>
                                <Editor
                                    {... this.props}
                                    id="input-description"
                                    name="description"
                                    defaultValue={this.state.description}
                                    handleChangeEditor={this.handleChangeEditor}
                                />
                                <small className="text-danger">{this.state.errors['description']}</small>
                            </CCol>
                        </CRow>
                    </CModalBody>
                    <CModalFooter>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CTooltip content={'Lưu'}>
                                    <CButton type="submit" size='sm' color="primary">
                                        <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                        Lưu
                                    </CButton>
                                </CTooltip>
                                <CTooltip content={'Đóng'}>
                                    <CButton type="button" size='sm' color="secondary" onClick={this.props.toggleModal} className="ml-3">
                                        <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                        Huỷ
                                    </CButton>
                                </CTooltip>
                            </CCol>
                        </CRow>
                    </CModalFooter>
                </CModal>
            </CForm>
        )
    }
}
export default ViolationSettingsForm;

