export const checkConstants = {
    GET_LIST_REQUEST: 'CHECKS_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'CHECKS_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'CHECKS_GET_LIST_FAILURE',

    ADD_REQUEST: 'CHECKS_ADD_REQUEST',
    ADD_SUCCESS: 'CHECKS_ADD_SUCCESS',
    ADD_FAILURE: 'CHECKS_ADD_FAILURE',

    EDIT_REQUEST: 'CHECKS_EDIT_REQUEST',
    EDIT_SUCCESS: 'CHECKS_EDIT_SUCCESS',
    EDIT_FAILURE: 'CHECKS_EDIT_FAILURE',

    DELETE_REQUEST: 'CHECKS_DELETE_REQUEST',
    DELETE_SUCCESS: 'CHECKS_DELETE_SUCCESS',
    DELETE_FAILURE: 'CHECKS_DELETE_FAILURE',
}