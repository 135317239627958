export const asset_itemConstants = {
    SET_PARAMS_REQUEST: 'ASSET_ITEMS_SET_PARAMS_REQUEST',

    GETALL_REQUEST: 'ASSET_ITEMS_GETALL_REQUEST',
    GETALL_SUCCESS: 'ASSET_ITEMS_GETALL_SUCCESS',
    GETALL_FAILURE: 'ASSET_ITEMS_GETALL_FAILURE',

    DELETE_REQUEST: 'ASSET_ITEMS_DELETE_REQUEST',
    DELETE_SUCCESS: 'ASSET_ITEMS_DELETE_SUCCESS',
    DELETE_FAILURE: 'ASSET_ITEMS_DELETE_FAILURE',

    GET_REQUEST: 'ASSET_ITEMS_GET_REQUEST',
    GET_SUCCESS: 'ASSET_ITEMS_GET_SUCCESS',
    GET_FAILURE: 'ASSET_ITEMS_GET_FAILURE',

    ADD_REQUEST: 'ASSET_ITEMS_ADD_REQUEST',
    ADD_SUCCESS: 'ASSET_ITEMS_ADD_SUCCESS',
    ADD_FAILURE: 'ASSET_ITEMS_ADD_FAILURE',

    UPDATE_REQUEST: 'ASSET_ITEMS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ASSET_ITEMS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ASSET_ITEMS_UPDATE_FAILURE',

    UPDATE_FIELD_REQUEST: 'ASSET_ITEMS_UPDATE_FIELD_REQUEST',
    UPDATE_FIELD_SUCCESS: 'ASSET_ITEMS_UPDATE_FIELD_SUCCESS',
    UPDATE_FIELD_FAILURE: 'ASSET_ITEMS_UPDATE_FIELD_FAILURE',
}