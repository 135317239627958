export const documentConstants = {
    SET_PARAMS_REQUEST: 'DOCUMENTS_SET_PARAMS_REQUEST',

    GET_LIST_REQUEST: 'DOCUMENTS_LIST_REQUEST',
    GET_LIST_SUCCESS: 'DOCUMENTS_LIST_SUCCESS',
    GET_LIST_FAILURE: 'DOCUMENTS_LIST_FAILURE',

    GET_REQUEST: 'DOCUMENTS_GET_REQUEST',
    GET_SUCCESS: 'DOCUMENTS_GET_SUCCESS',
    GET_FAILURE: 'DOCUMENTS_GET_FAILURE',

    UPLOAD_REQUEST: 'DOCUMENTS_UPLOAD_REQUEST',
    UPLOAD_SUCCESS: 'DOCUMENTS_UPLOAD_SUCCESS',
    UPLOAD_FAILURE: 'DOCUMENTS_UPLOAD_FAILURE',

    UPDATE_REQUEST: 'DOCUMENTS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'DOCUMENTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'DOCUMENTS_UPDATE_FAILURE',

    DELETE_REQUEST: 'DOCUMENTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'DOCUMENTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'DOCUMENTS_DELETE_FAILURE',
}