import { taskConstants } from "src/_constants";
import { helps } from "src/_helpers";

export function taskReducer(state = {}, actions) {
    switch (actions.type) {
        // GET USER TASKS
        case taskConstants.GET_USER_TASKS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case taskConstants.GET_USER_TASKS_SUCCESS:
            return {
                ...state,
                tasks: actions.tasks,
                loading: false,
            }
        case taskConstants.GET_USER_TASKS_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }
        // GET LIST    
        case taskConstants.GET_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case taskConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                tasks: actions.tasks,
                loading: false,
            }
        case taskConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Add
        case taskConstants.ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case taskConstants.ADD_SUCCESS:
            state.tasks.push(actions.task);
            return {
                ...state,
                loading: false,
            }
        case taskConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Edit
        case taskConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case taskConstants.EDIT_SUCCESS:
            var _tasks = state.tasks;
            var foundIndex = _tasks.findIndex(task => task.id === actions.task.id);
            console.log(foundIndex);
            return {
                ...state,
                tasks: _tasks,
                loading: false,
            }
        case taskConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        // Delete 
        case taskConstants.DELETE_REQUEST:
            var tasks = state.tasks;
            tasks = tasks.map(task =>
                task.id === actions.task.id
                    ? { ...task, deleting: true }
                    : task
            );
            return {
                ...state,
                task: actions.task,
                tasks: tasks
            };
        case taskConstants.DELETE_SUCCESS:
            var tasks = state.tasks;
            tasks = tasks.filter(task => task.id !== actions.task.id);
            return {
                ...state,
                task: actions.task,
                tasks: tasks
            };
        case taskConstants.DELETE_FAILURE:
            var tasks = state.tasks;
            tasks = tasks.map(task => {
                if (task.id === actions.task.id) {
                    const { deleting, ...taskCopy } = task;
                    return { ...taskCopy, deleteError: actions.error };
                }
                return task;
            });
            return {
                ...state,
                tasks: tasks
            };

        // Comments    
        case taskConstants.ADD_COMMENT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case taskConstants.ADD_COMMENT_SUCCESS:
            var tasks = state.tasks;
            var task = actions.task;
            if (helps.isEmpty(task['comments'])) {
                task['comments'].push(actions.comment);
            } else {
                let found_comment = helps.getItemFromArr(task['comments'], actions.comment.id);
                if (helps.isEmpty(found_comment)) {
                    task['comments'].push(actions.comment);
                }
            }

            var foundIndex = tasks.findIndex(item => item.id === task.id);
            tasks[foundIndex] = task;
            return {
                ...state,
                loading: false,
                tasks: tasks,
            }
        case taskConstants.ADD_COMMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        case taskConstants.EDIT_COMMENT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case taskConstants.EDIT_COMMENT_SUCCESS:
            var tasks = state.tasks;
            var task = actions.task;
            var found_comment = task.comments.findIndex(item => item.id === actions.comment.id);
            task.comments[found_comment] = actions.comment;
            var foundIndex = tasks.findIndex(item => item.id === task.id);
            tasks[foundIndex] = task;
            return {
                ...state,
                loading: false,
                tasks: tasks,
            }
        case taskConstants.EDIT_COMMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        case taskConstants.DELETE_COMMENT_REQUEST:
            var comments = actions.task.comments;
            comments = comments.map(comment =>
                comment.id === actions.comment.id
                    ? { ...comment, deleting: true }
                    : comment
            );
            return {
                ...state,
                task: actions.task,
            };
        case taskConstants.DELETE_COMMENT_SUCCESS:
            var tasks = state.tasks;
            var task = actions.task;
            var comments = task.comments;
            comments = comments.filter(comment => comment.id !== actions.comment.id);
            task['comments'] = comments;
            var foundIndex = tasks.findIndex(item => item.id === task.id);
            tasks[foundIndex] = task;
            return {
                ...state,
                task: actions.task,
                tasks: tasks
            };
        case taskConstants.DELETE_COMMENT_FAILURE:
            var comments = actions.task.comments;
            var task = actions.task;
            comments = comments.map(comment => {
                if (comment.id === actions.comment.id) {
                    const { deleting, ...commentCopy } = comment;
                    return { ...commentCopy, deleteError: actions.error };
                }
                return comment;
            });
            task['comments'] = comments;
            var foundIndex = tasks.findIndex(item => item.id === task.id);
            tasks[foundIndex] = task;
            return {
                ...state,
                tasks: tasks
            };

        default: return state;
    }
}