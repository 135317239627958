import { db } from '../dbConfigs';
import { provinceService } from './province.service';

export const projectService = {
    getAll,
    add,
    getById,
    update,
    updateField,
};

async function getAll(active = 1) {
    try {
        const snapshot = await db.collection('projects')
            .orderBy('name', 'asc')
            .get();
        const projects = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        if (projects && projects.length > 0) {
            Promise.all(
                projects.map(async (project) => {
                    if (project.create_date) {
                        var _date = project['create_date'];
                        project['create_date'] = _date.toDate();
                    }

                    if (project.deploy_date) {
                        var _date = project['deploy_date'];
                        project['deploy_date'] = _date.toDate();
                    }
                    if (project.province_id) {
                        let province = await provinceService.getById(project.province_id);
                        project['province'] = province.name;
                    }
                })
            );
        }
        return projects;
    } catch (error) {
        console.log("Error getting projects:", error);
    }
}

async function getById(id) {
    try {
        const doc = await db.collection("projects").doc(id)
            .get();
        if (doc.exists) {
            return doc.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        console.log("Error getting document:", error);
    }
}

async function add(project) {
    try {
        const docRef = await db.collection("projects").add(project);
        project.id = docRef.id;
        update(project);
        console.log("Firebase: projects has been added successfully!");
        return project;
    } catch (error) {
        console.error("Error adding project: ", error);
    }
}

async function update(project) {
    //console.log(project);
    let dbRef = db.collection("projects").doc(project['id']);

    try {
        //console.log(project);
        await dbRef.update(project).then(() => {
            console.log("Firebase: project has been updated successfully!");
        });

        return project;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function updateField(project, field) {
    //console.log(project)
    let dbRef = db.collection("projects").doc(project.id);

    try {
        await dbRef.update({
            [field]: project[field]
        }).then(() => {
            console.log("Firebase: project field has been updated successfully!");
        });

        return project;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

