import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CCallout,
    CLink,
    CButton,
    CTooltip,
    CPopover,
    CSelect,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import Loading from 'src/containers/_loading';
import { ThePermission } from 'src/containers/ThePermission';
import { ChecksFormContainer } from 'src/containers/checks';


class Checks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show_forms: [],
            employees: [],
            selected_areas: [],
        }

        this.handleDelete = this.handleDelete.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
        this.handleChangeAreas = this.handleChangeAreas.bind(this);
    }

    componentDidMount() {
    }

    handleDelete(e, check) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Checkin-Checkout: ' + check.id + ' ?');
        if (_confirm) {
            this.props._delete(check);
        }
        return false;
    }

    handleChangeAreas(e, project_id) {
        e.preventDefault();
        let area_id = e.target.value;
        let selected_areas = this.state.selected_areas;
        selected_areas = selected_areas.filter(item => item.project_id !== project_id);
        if (!helps.isEmpty(area_id)) {
            selected_areas.push({ area_id: area_id, project_id: project_id });
        }
        //console.log(selected_areas)
        this.setState({ selected_areas: selected_areas })
    }

    toggleForm(id) {
        let show_forms = this.state.show_forms;
        const _position = show_forms.indexOf(id);
        let newForms = show_forms.slice();
        if (_position !== -1) {
            newForms.splice(_position, 1)
        } else {
            newForms = [...show_forms, id]
        }
        this.setState({ show_forms: newForms });
        //console.log(this.state.show_forms, _position);
    }

    render() {
        const { checks, projects, employees, positions, curent_date, user } = this.props;
        let _cur_date = new Date(curent_date);
        if (helps.isEmpty(positions)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(employees)) {
            return (
                <Loading></Loading>
            );
        }
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        var _projects = [];
        if (!helps.isEmpty(projects)) {
            _projects = projects.filter(project => project.active === 1);

            _projects.map((project) => {
                project['positions'] = positions.filter(position => position.project_id === project.id);
                if (!helps.isEmpty(project.employees) && project.employees.length > 0) {
                    project.employees.map((project_employee, index) => {
                        let found_employee = helps.getItemFromArr(employees, project_employee.employee_id, 'employee_id');

                        if (!helps.isEmpty(found_employee)) {
                            project.employees[index] = Object.assign(project.employees[index], { employee: found_employee })
                        }

                        let found_position = helps.getItemFromArr(positions, project_employee.position_id);

                        if (!helps.isEmpty(found_position)) {
                            //found_shift = helps.getItemFromArr(found_position.shifts, project_employee.shift_id);
                            //found_position['shift'] = found_shift;
                            project.employees[index] = Object.assign(project.employees[index], { position: found_position });
                        }
                        let found_checks = checks.filter(check => {
                            if (check.employee_id === project_employee.employee_id) {
                                let check_date = new Date(check.check_date);

                                if (check_date.getFullYear() === _cur_date.getFullYear() && check_date.getMonth() === _cur_date.getMonth() && check_date.getDate() === _cur_date.getDate()) {
                                    return true;
                                } else {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        });
                        if (!helps.isEmpty(found_checks) && found_checks.length > 0) {
                            project.employees[index] = Object.assign(project.employees[index], { checks: found_checks })
                        } else {
                            project.employees[index] = Object.assign(project.employees[index], { checks: [] });
                        }
                    });
                }
            });
        }
        return (
            <div className='checks-container'>
                <table className='table table-hover'>
                    <thead className='border-bottom'>
                        <tr>
                            <th style={{ width: '20%' }}>Mục Tiêu</th>
                            <th>Nhân Sự</th>
                        </tr>
                    </thead>
                    <tbody>
                        {helps.isEmpty(_projects) && <tr><td className='text-center p-5' colSpan={4}><h4 className='text-secondary mt-3'>Không tìm thấy Mục Tiêu nào!</h4></td></tr>}
                        {!helps.isEmpty(_projects) && _projects.map((project) => {
                            var project_checks = checks.filter(check => check.position.project_id === project.id);
                            let areas = !helps.isEmpty(project.areas) ? project.areas : [];
                            if (!helps.isEmpty(project_checks)) {
                                project_checks = project_checks.filter((check) => {
                                    let found_in_project = helps.getItemFromArr(project.employees, check.employee_id, 'employee_id');
                                    if (helps.isEmpty(found_in_project)) return true;
                                    else return false;
                                });
                                project_checks = project_checks.filter((check) => {
                                    let check_date = new Date(check.check_date);
                                    if (check_date.getFullYear() === _cur_date.getFullYear() && check_date.getMonth() === _cur_date.getMonth() && check_date.getDate() === _cur_date.getDate()) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                });
                                //console.log(project_checks)
                                if (project_checks.length > 0) {
                                    project_checks.forEach((check) => {
                                        check['employee'] = helps.getItemFromArr(employees, check.employee_id);
                                    })
                                }
                            }
                            var total_duration = 0;
                            let selected_area = helps.getItemFromArr(this.state.selected_areas, project.id, 'project_id');
                            let project_employees = project.employees;
                            //console.log(selected_area)
                            if (!helps.isEmpty(selected_area)) {
                                let _positions = positions.filter(position => position.area_id === selected_area.area_id);
                                //console.log(_positions)
                                if (!helps.isEmpty(_positions)) {
                                    project_checks = project_checks.filter(check => {
                                        let found = helps.getItemFromArr(_positions, check.position.id);
                                        if (!helps.isEmpty(found)) return true;
                                        else return false;
                                    });
                                    project_employees = project_employees.filter(employee => {
                                        let found = helps.getItemFromArr(_positions, employee.position_id);
                                        if (!helps.isEmpty(found)) return true;
                                        else return false;
                                    });
                                }
                            }
                            //console.log(project_employees)
                            return (
                                <tr key={project.id}>
                                    <td>
                                        <CCallout key={project.id} color={project.color} className={'h-100 align-middle'} style={{ minHeight: '40px' }}>
                                            <CLink
                                                id={project.id}
                                                className='text-dark mt-2 font-weight-bold'
                                                style={{ fontSize: '18px' }}
                                                onClick={() => { this.props.history.push(`/projects/view/${project.id}`) }}
                                            >
                                                {project.name}
                                            </CLink>
                                            {!helps.isEmpty(project.assigns) && <div className='text-muted'>
                                                <u><i>Phụ Trách: </i></u>
                                                <ul>
                                                    {project.assigns.length > 0 && project.assigns.map((assign) => {
                                                        return (<li key={assign.value}>
                                                            <CPopover content={assign.label}>
                                                                <CLink
                                                                    className='text-muted'
                                                                    style={{ fontSize: '14px' }}
                                                                    onClick={() => { this.props.history.push(`/employees/view/${assign.value}`) }}
                                                                >
                                                                    {assign.label}
                                                                </CLink>
                                                            </CPopover>
                                                        </li>)
                                                    })}
                                                </ul>
                                            </div>}
                                            {!helps.isEmpty(areas) &&
                                                <CSelect
                                                    name={'area-' + project.id}
                                                    value={selected_area.area_id}
                                                    onChange={(e) => { this.handleChangeAreas(e, project.id) }}
                                                >
                                                    <option value={''}> - Chọn Khu Vực - </option>
                                                    {areas.length > 0 && areas.map(area => {
                                                        return (<option key={area.id} value={area.id}>{area.name}</option>)
                                                    })}
                                                </CSelect>}
                                        </CCallout>
                                    </td>
                                    <td>
                                        <table className='table' style={{ width: '100%' }}>
                                            <tbody>
                                                {!helps.isEmpty(project_checks) && project_checks.length > 0 && project_checks.map((check) => {
                                                    if (check.project_id !== project.id) {
                                                        return (<></>);
                                                    }
                                                    let employee = helps.getItemFromArr(employees, check.employee_id, 'employee_id');
                                                    let is_author = (check.added_by === user.id) ? true : false;
                                                    total_duration = total_duration + check.check_duration;
                                                    return (
                                                        <tr key={'employee_' + check.employee_id} id={check.id + '-' + check.employee_id}>
                                                            <td style={{ width: '30%' }}>
                                                                <CLink className="text-dark" onClick={() => { this.props.history.push(`/employees/view/${employee.employee_id}`) }}>
                                                                    <b style={{ fontSize: '16px' }}>{employee.fullname} ({employee.employee_id})</b>
                                                                </CLink>
                                                                {!helps.isEmpty(check.position) &&
                                                                    <div className='text-muted' id={check.position.id}>
                                                                        <span><small className='text-muted'><i>Vị Trí:</i></small> <b>{check.position.name}</b> {check.position.description && <> - {check.position.description}</>}</span>
                                                                        {!helps.isEmpty(check.shift) &&
                                                                            <div>
                                                                                <small className='text-muted'><i>Ca:</i></small> <b>{check.shift.label}</b>
                                                                                {" - "}
                                                                                <small className='text-muted'><i>từ:</i></small> <b>{check.shift.start} - {check.shift.end} ({check.shift.duration}/24)</b>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <CPopover content={check.id}>
                                                                    <CRow key={check.id} className='mb-2'>
                                                                        <CCol md={4}>
                                                                            {check.checked_in > 0 &&
                                                                                <div>
                                                                                    <span className='text-muted font-italic'>Check-in lúc: </span> <b>{helps.formatDateTime_from_Timestamp(check.checkin_time)}</b>
                                                                                    <div>
                                                                                        (<span className='text-muted font-italic'>Checkin tại: </span> <b>{check.position.name} </b>)
                                                                                    </div>
                                                                                    {!helps.isEmpty(check.note) && <div><span className='text-muted font-italic'><u>Ghi chú:</u> </span>{check.note}</div>}
                                                                                </div>
                                                                            }
                                                                        </CCol>
                                                                        <CCol md={4}>
                                                                            {check.checked_out > 0 &&
                                                                                <div>
                                                                                    <span className='text-muted font-italic'>Check-out lúc: </span> <b>{helps.formatDateTime_from_Timestamp(check.checkout_time)}</b>
                                                                                    <div>
                                                                                        (<span className='text-muted font-italic'>Checkout tại: </span> <b>{check.position.name} </b>)
                                                                                    </div>
                                                                                    {!helps.isEmpty(check.note) && <div><span className='text-muted font-italic'><u>Ghi chú:</u> </span>{check.note}</div>}
                                                                                </div>
                                                                            }
                                                                        </CCol>
                                                                        <CCol md={3}>
                                                                            <div>
                                                                                Giờ công: <b>{parseFloat(check.check_duration / 60).toFixed(2)}</b>
                                                                                {check.is_overtime && <i> (tăng ca)</i>}
                                                                                <div className='text-muted'>ID: <small>{check.id}</small></div>
                                                                            </div>
                                                                        </CCol>
                                                                        <CCol md={1}>
                                                                            <CButton
                                                                                color="danger"
                                                                                size="sm"
                                                                                title={"Xoá: thêm bởi " + check.added_by_alias}
                                                                                className="ml-1"
                                                                                disabled={!is_author}
                                                                                onClick={(e) => { this.handleDelete(e, check) }}
                                                                            >
                                                                                <CIcon name="cil-trash"></CIcon>
                                                                            </CButton>
                                                                        </CCol>
                                                                    </CRow>
                                                                </CPopover>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                {!helps.isEmpty(project_employees) && project_employees.length > 0 && project_employees.map((project_employee) => {
                                                    let employee = helps.getItemFromArr(employees, project_employee.employee_id, 'employee_id');
                                                    let employee_checks = project_employee.checks ? project_employee.checks : [];
                                                    let is_checked_out = true;
                                                    employee_checks.forEach(check => {
                                                        if (check.checked_out !== 1) is_checked_out = false;
                                                    })
                                                    var found_checked_out = [];
                                                    var default_shift = {};
                                                    if (!helps.isEmpty(project_employee.position)) {
                                                        default_shift = helps.getItemFromArr(project_employee.position.shifts, project_employee.shift_id);
                                                    }
                                                    if (helps.isEmpty(employee)) return (<></>);
                                                    //console.log(default_shift, project_employee.employee_id);
                                                    return (
                                                        <tr key={'employee_' + employee.employee_id} id={'employee_' + employee.employee_id} style={{ width: '500px' }}>
                                                            <td style={{ width: '30%' }}>
                                                                <CPopover content={employee.fullname + ' ' + employee.employee_id}>
                                                                    <CLink className="text-dark" onClick={() => { this.props.history.push(`/employees/view/${employee.employee_id}`) }}>
                                                                        <b style={{ fontSize: '16px' }}>{employee.fullname}</b> ({employee.employee_id})
                                                                    </CLink>
                                                                </CPopover>
                                                                {!helps.isEmpty(project_employee.position) &&
                                                                    <div className='text-muted' id={project_employee.position.id}>
                                                                        <span><small className='text-muted'><i>Vị Trí:</i></small> <b>{project_employee.position.name}</b> {project_employee.position.description && <> - {project_employee.position.description}</>}</span>
                                                                        {!helps.isEmpty(default_shift) &&
                                                                            <div>
                                                                                <small className='text-muted'><i>Ca:</i></small> <b>{default_shift.label}</b>
                                                                                {" - "}
                                                                                <small className='text-muted'><i>từ:</i></small> <b>{default_shift.start} - {default_shift.end} ({default_shift.duration}/24)</b>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {!helps.isEmpty(employee_checks) && employee_checks.length > 0 && employee_checks.map((check, index) => {
                                                                    if (check.project_id !== project.id) {
                                                                        return (<></>);
                                                                    }
                                                                    if (check.checked_out > 0) {
                                                                        found_checked_out.push(check.id);
                                                                    }
                                                                    let is_author = (check.added_by === user.id) ? true : false;
                                                                    total_duration = total_duration + check.check_duration;
                                                                    return (
                                                                        <CRow key={check.id} id={check.id} className='mb-2'>
                                                                            <CCol md={4}>
                                                                                {check.checked_in > 0 &&
                                                                                    <div>
                                                                                        <span className='text-muted font-italic'>Check-in lúc: </span> <b>{helps.formatDateTime_from_Timestamp(check.checkin_time)}</b>
                                                                                        <div>
                                                                                            (<span className='text-muted font-italic'>Checkin tại: </span> <b>{check.position.name} </b>)
                                                                                        </div>
                                                                                        {!helps.isEmpty(check.note) && <div><span className='text-muted font-italic'><u>Ghi chú:</u> </span>{check.note}</div>}
                                                                                    </div>
                                                                                }
                                                                            </CCol>
                                                                            <CCol md={4}>
                                                                                {check.checked_out > 0 &&
                                                                                    <div>
                                                                                        <span className='text-muted font-italic'>Check-out lúc: </span> <b>{helps.formatDateTime_from_Timestamp(check.checkout_time)}</b>
                                                                                        <div>
                                                                                            (<span className='text-muted font-italic'>Checkout tại: </span> <b>{check.position.name} </b>)
                                                                                        </div>
                                                                                        {!helps.isEmpty(check.note) && <div><span className='text-muted font-italic'><u>Ghi chú:</u> </span>{check.note}</div>}
                                                                                    </div>
                                                                                }
                                                                                {check.checked_out === 0 &&
                                                                                    <ThePermission {... this.props} component="projects" action="edit">
                                                                                        <CTooltip
                                                                                            content={'Check-out: ' + employee.fullname + ' tại: ' + project.name}
                                                                                        >
                                                                                            <CButton
                                                                                                color="danger"
                                                                                                size="sm"
                                                                                                onClick={() => { this.toggleForm(employee.employee_id + '-false') }}
                                                                                            >
                                                                                                <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                                                                                Check-out
                                                                                            </CButton>
                                                                                        </CTooltip>
                                                                                        <ChecksFormContainer
                                                                                            key={'checkout-' + employee.employee_id}
                                                                                            {... this.props}
                                                                                            action='checkout'
                                                                                            is_overtime={false}
                                                                                            curent_date={this.props.curent_date}
                                                                                            default_shift={default_shift}
                                                                                            default_position={project_employee.position}
                                                                                            employee={employee}
                                                                                            position={project_employee.position}
                                                                                            project={project}
                                                                                            check={check}
                                                                                            show_forms={this.state.show_forms}
                                                                                            toggleForm={(item) => { this.toggleForm(item) }}

                                                                                        />
                                                                                    </ThePermission>
                                                                                }
                                                                            </CCol>
                                                                            <CCol md={3}>
                                                                                <div>
                                                                                    Giờ công: <b>{parseFloat(check.check_duration / 60).toFixed(2)}</b>
                                                                                    {check.is_overtime && <i> (tăng ca)</i>}
                                                                                    <div className='text-muted'>ID: <small>{check.id}</small></div>
                                                                                </div>
                                                                            </CCol>
                                                                            <CCol md={1}>
                                                                                <CButton
                                                                                    color={is_author ? "danger" : 'secondary'}
                                                                                    size="sm"
                                                                                    title={"Xoá: " + check.id + " | bởi: " + check.added_by_alias}
                                                                                    className="ml-1"
                                                                                    disabled={!is_author}
                                                                                    onClick={(e) => { this.handleDelete(e, check) }}
                                                                                >
                                                                                    <CIcon name="cil-trash"></CIcon>
                                                                                </CButton>
                                                                            </CCol>
                                                                        </CRow>
                                                                    );
                                                                })}
                                                                {(helps.isEmpty(employee_checks) || (!helps.isEmpty(employee_checks) && is_checked_out)) &&
                                                                    <ThePermission {... this.props} component="projects" action="edit">
                                                                        <CTooltip
                                                                            content={'Check-in: ' + employee.fullname + ' tại: ' + project.name}
                                                                        >
                                                                            <CButton
                                                                                color="success"
                                                                                size="sm"
                                                                                onClick={() => { this.toggleForm(employee.employee_id + '-false') }}
                                                                            >
                                                                                <CIcon name="cil-check-circle" className="mfe-1 mt-0"></CIcon>
                                                                                Check-in
                                                                            </CButton>
                                                                        </CTooltip>
                                                                        <ChecksFormContainer
                                                                            key={'checkin-' + employee.employee_id}
                                                                            {... this.props}
                                                                            action='checkin'
                                                                            is_overtime={false}
                                                                            curent_date={this.props.curent_date}
                                                                            default_shift={default_shift}
                                                                            default_position={project_employee.position}
                                                                            employee={employee}
                                                                            position={project_employee.position}
                                                                            project={project}
                                                                            check={{}}
                                                                            show_forms={this.state.show_forms}
                                                                            toggleForm={(item) => { this.toggleForm(item) }}
                                                                        />
                                                                    </ThePermission>
                                                                }
                                                                <ThePermission {... this.props} component="projects" action="edit">
                                                                    <CTooltip
                                                                        content={'Tăng ca: ' + employee.fullname + ' tại: ' + project.name}
                                                                    >
                                                                        <CButton
                                                                            color="success"
                                                                            size="sm"
                                                                            className='mt-2'
                                                                            onClick={() => { this.toggleForm(employee.employee_id + '-true') }}
                                                                        >
                                                                            <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                                                            Tăng Ca
                                                                        </CButton>
                                                                    </CTooltip>
                                                                    <ChecksFormContainer
                                                                        key={'checkin-overtime-' + employee.employee_id}
                                                                        {... this.props}
                                                                        action='checkin'
                                                                        is_overtime={true}
                                                                        curent_date={this.props.curent_date}
                                                                        default_shift={default_shift}
                                                                        default_position={project_employee.position}
                                                                        employee={employee}
                                                                        position={project_employee.position}
                                                                        project={project}
                                                                        check={{}}
                                                                        show_forms={this.state.show_forms}
                                                                        toggleForm={(item) => { this.toggleForm(item) }}
                                                                    />
                                                                </ThePermission>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                    <td colSpan={5} className='bg-dark pr-5'>
                                                        <div className='text-right mr-5'>
                                                            <h5><i>Tổng Giờ Công: </i><b>{parseFloat(total_duration / 60).toFixed(2)}</b></h5>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div >
        )
    }
}


export default Checks;
