import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CFormGroup,
    CInput,
    CInvalidFeedback,
    CTextarea,
    CSelect,
    CInputCheckbox,
    CFormText,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';

class HousingForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            name: '',
            start_date: new Date(),
            contract_length: '',
            contract_type: 'month',
            create_by: this.props.user.id,
            create_date: new Date(),
            phone: '',
            email: '',
            contact_name: '',
            address: '',
            deposit: 0,
            amount: 0,
            active: true,
            bank_acc: '',
            bank_name: '',
            bank_note: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        const housing = this.props.housing;
        //console.log(housing);
        if (housing) {
            this.setState({
                id: housing.id,
                name: housing.name ? housing.name : this.state.name,
                start_date: housing.start_date ? housing.start_date : this.state.start_date,
                contract_length: housing.contract_length ? housing.contract_length : this.state.contract_length,
                create_by: housing.create_by ? housing.create_by : this.props.user.id,
                create_date: housing.create_date ? housing.create_date : new Date(),
                phone: housing.phone ? housing.phone : this.state.phone,
                contract_type: housing.contract_type ? housing.contract_type : this.state.contract_type,
                contact_name: housing.contact_name ? housing.contact_name : this.state.contact_name,
                address: housing.address ? housing.address : this.state.address,
                province_id: housing.province_id ? housing.province_id : this.state.province_id,
                deposit: housing.deposit ? housing.deposit : this.state.deposit,
                amount: housing.amount ? housing.amount : this.state.amount,
                active: housing.active,
                bank_acc: housing.bank_acc,
                bank_name: housing.bank_name,
                bank_note: housing.bank_note,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let housing = {
                id: this.state.id,
                name: this.state.name,
                start_date: this.state.start_date,
                contract_length: this.state.contract_length,
                create_by: this.state.create_by,
                create_date: new Date(this.state.create_date),
                phone: this.state.phone,
                contract_type: this.state.contract_type,
                contact_name: this.state.contact_name,
                address: this.state.address,
                province_id: this.state.province_id,
                deposit: this.state.deposit,
                amount: this.state.amount,
                active: this.state.active,
                bank_acc: this.state.bank_acc,
                bank_name: this.state.bank_name,
                bank_note: this.state.bank_note,
            }
            this.props.handleSubmit(housing);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeFilterDate(_date) {
        let fields = this.state.fields;
        fields['create_date'] = _date;
        this.setState({ create_date: _date });
        this.setState({ fields });
    }

    handleChangeActive(e) {
        this.setState({ active: e.target.checked });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập Tên";
        }

        if (helps.isEmpty(this.state.province_id)) {
            formIsValid = false;
            errors["province_id"] = "Vui lòng chọn Tỉnh thành/Khu Vực";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }


    render() {
        const provinces = this.props.provinces;

        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader>
                                {this.props.form_header}
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-name">Tên Nhà Đội:</CLabel>
                                            <CInput
                                                type="text"
                                                name="name"
                                                id="input-name"
                                                value={this.state.name}
                                                required
                                                className='font-weight-bold'
                                                onChange={(e) => { this.handleChange('name', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['name']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-start_date">Ngày Bắt Đầu:</CLabel>
                                                    <CInput
                                                        type="date"
                                                        name="start_date"
                                                        id="input-start_date"
                                                        value={this.state.start_date}
                                                        onChange={(e) => { this.handleChange('start_date', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['start_date']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CRow>
                                                        <CCol>
                                                            <CLabel htmlFor="input-contract_length">Thời Gian HĐ:</CLabel>
                                                            <CInput
                                                                type="number"
                                                                name="contract_length"
                                                                id="input-contract_length"
                                                                value={this.state.contract_length}
                                                                onChange={(e) => { this.handleChange('contract_length', e) }}
                                                            />
                                                            <small className="text-danger">{this.state.errors['contract_length']}</small>
                                                        </CCol>
                                                        <CCol>
                                                            <CLabel htmlFor="input-contract_type">Đơn vị:</CLabel>
                                                            <CSelect
                                                                name='contract_type'
                                                                value={this.state.contract_type}
                                                                onChange={(e) => { this.handleChange('contract_type', e) }}
                                                            >
                                                                <option value='month'> Tháng </option>
                                                                <option value='day'> Ngày </option>
                                                                <option value='year'> Năm </option>
                                                            </CSelect>
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-contact_name">Tên Người Liên Hệ:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="contact_name"
                                                        id="input-contact_name"
                                                        value={this.state.contact_name}
                                                        onChange={(e) => { this.handleChange('contact_name', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['contact_name']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-phone">Số ĐT:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="phone"
                                                        id="input-phone"
                                                        value={this.state.phone}
                                                        onChange={(e) => { this.handleChange('phone', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['phone']}</small>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-deposit">Đặt Cọc:</CLabel>
                                                    <CInput
                                                        type="number"
                                                        name="deposit"
                                                        id="input-deposit"
                                                        value={this.state.deposit}
                                                        onChange={(e) => { this.handleChange('deposit', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['deposit']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-amount">Chi Phí Thuê:</CLabel>
                                                    <CInput
                                                        type="number"
                                                        name="amount"
                                                        id="input-amount"
                                                        value={this.state.amount}
                                                        className='font-text-bold'
                                                        onChange={(e) => { this.handleChange('amount', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['deposit']}</small>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-bank_acc">TK Ngân Hàng:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="bank_acc"
                                                        id="input-bank_acc"
                                                        value={this.state.bank_acc}
                                                        onChange={(e) => { this.handleChange('bank_acc', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['bank_acc']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CFormGroup>
                                                        <CLabel htmlFor="input-bank_name">Ngân hàng:</CLabel>
                                                        <CInput
                                                            type="text"
                                                            name="bank_name"
                                                            id="input-bank_name"
                                                            value={this.state.bank_name}
                                                            onChange={(e) => { this.handleChange('bank_name', e) }}
                                                        />
                                                        <CFormText className="help-block">// Nhập mã BIN Ngân Hàng. VD: Ngân hàng Techcombank, có thể nhập: <b>Techcombank</b> hoặc <b>TCB</b> hoặc <b>970407</b> <a href='https://www.sbv.gov.vn/webcenter/portal/vi/menu/trangchu/ttvnq/htmtcqht' target='_blank' rel="noreferrer"><i>(tham khảo)</i></a></CFormText>
                                                        <small className="text-danger">{this.state.errors['bank_name']}</small>
                                                    </CFormGroup>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-bank_note">Ghi chú Ngân Hàng:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="bank_note"
                                                        id="input-bank_note"
                                                        value={this.state.bank_note}
                                                        onChange={(e) => { this.handleChange('bank_note', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['bank_note']}</small>
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </CCol>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-cccd">Hiệu lực?:</CLabel>
                                            <CFormGroup variant="custom-radio" inline>
                                                <CInputCheckbox
                                                    type="checkbox"
                                                    name="active"
                                                    id="input-active"
                                                    title='Nhà đội còn hiệu lực?'
                                                    className='mt-2 cursor-pointer'
                                                    checked={this.state.active}
                                                    value={1}
                                                    onChange={(e) => { this.handleChangeActive(e) }}

                                                />
                                            </CFormGroup>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-address">Địa chỉ:</CLabel>
                                            <CTextarea
                                                type="text"
                                                name="address"
                                                id="input-address"
                                                value={this.state.address}
                                                onChange={(e) => { this.handleChange('address', e) }}
                                            />
                                            <CInvalidFeedback className="help-block">{this.state.errors['address']}</CInvalidFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-province_id">Tỉnh Thành / Khu Vực:</CLabel>
                                            <CSelect
                                                name="province_id"
                                                id="input-province_id"
                                                value={this.state.province_id}
                                                onChange={(e) => { this.handleChange('province_id', e) }}
                                            >
                                                {provinces && provinces.map((item) => {
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    );
                                                })}
                                            </CSelect>
                                            <CInvalidFeedback className="help-block">{this.state.errors['province_id']}</CInvalidFeedback>
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default HousingForm;

