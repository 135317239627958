import { fileConstants } from '../_constants';
import { fileService } from '../_services';
import { alertActions } from '.';

export const fileActions = {
    getAll,
    uploadFile,
    deleteFile,
};

function getAll(_object, _object_id) {
    return dispatch => {
        dispatch(request(_object, _object_id));
        fileService.getAll(_object, _object_id)
            .then(
                files => dispatch(success(files)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(_object, _object_id) { return { type: fileConstants.GET_LIST_REQUEST, _object: _object, _object_id: _object_id } }
    function success(files) { return { type: fileConstants.GET_LIST_SUCCESS, files } }
    function failure(error) { return { type: fileConstants.GET_LIST_FAILURE, error } }
}

function uploadFile(files, object, object_id, user = {}) {
    return dispatch => {
        dispatch(request(files));
        fileService.uploadFile(files, object, object_id, user)
            .then(
                files => dispatch(success(files)),
                error => dispatch(failure(files, error.toString()))
            );
    };

    function request(files) { return { type: fileConstants.UPLOAD_REQUEST, files } }
    function success(files) { return { type: fileConstants.UPLOAD_SUCCESS, files } }
    function failure(files, error) { return { type: fileConstants.UPLOAD_FAILURE, files, error } }
}


function deleteFile(file) {
    return dispatch => {
        dispatch(request(file));

        fileService.deleteFile(file)
            .then(
                file => dispatch(success(file)),
                error => dispatch(failure(file, error.toString()))
            );
    };

    function request(file) { return { type: fileConstants.DELETE_REQUEST, file } }
    function success(file) { return { type: fileConstants.DELETE_SUCCESS, file } }
    function failure(file, error) { return { type: fileConstants.DELETE_FAILURE, file, error } }
}


