import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CPopover,
    CFormGroup,
    CSelect,
    CButton,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CLink,
    CTooltip,
    CCard,
    CCardBody,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import Loading from 'src/containers/_loading';
import Select from 'react-select';
import TimeKeepingExport from './EmployeeTimeKeepingExport';

class EmployeeTimekeeping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_employees: [],
            selected_group: '',
            selected_job_type: '',
            selected_position: '',
            selected_project: {},
            export_data: [],
            view_modals: [],
            total_day_hours: [],
        }
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {
    }

    renderHeaders() {
        const { month } = this.props;
        let today = month ? month : new Date();
        var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        let ths = [];
        for (let i = 1; i <= lastDay.getDate(); i++) {
            let cur_day = new Date(today.getFullYear(), today.getMonth(), i);
            let weekday = cur_day.getDay();
            ths.push(<th key={'th-' + i} title={configs.weekday_full[weekday] + ', ' + helps.formatDate_from_Timestamp(cur_day)}>
                {i}<div><small className='text-muted'><i className={weekday === 0 ? 'text-danger' : ''}>{configs.weekday[weekday]}</i></small></div>
            </th>);
        }
        ths.push(<th>Tổng</th>);
        return ths;
    }

    handleChangeSelect(selectedOptions, field) {
        if (selectedOptions) {
            this.setState({ [field]: selectedOptions });
        } else {
            this.setState({ [field]: '' });
        }
    }

    handleChange(field, e) {
        let value = e.target.value;
        this.setState({
            [field]: value
        });
    }

    renderEmployeeData(employee) {
        const { checks, month, projects } = this.props;
        let today = month ? month : new Date();
        var employee_checks = checks.filter(check => check.employee_id === employee.employee_id);
        const duplicates = employee_checks.reduce((acc, check) => {
            let newItem = acc.find((i) => {
                if (i.project_id === check.project_id && !check.is_overtime) {
                    if (i.position.id === check.position.id) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            });

            if (newItem) {
                newItem.project_duration += check.check_duration;
            } else {
                if (!check.is_overtime) {
                    acc.push({ project_id: check.project_id, position: check.position, project_duration: check.check_duration });
                }
            }

            return acc;
        }, []);
        const duplicates_OT = employee_checks.reduce((acc, check) => {
            let newItem = acc.find((i) => {
                if (i.project_id === check.project_id && check.is_overtime) {
                    if (i.position.id === check.position.id) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            });

            if (newItem) {
                newItem.project_duration += check.check_duration;
            } else {
                if (check.is_overtime) {
                    acc.push({ project_id: check.project_id, position: check.position, project_duration: check.check_duration });
                }
            }

            return acc;
        }, []);
        let total_hours = 0;
        let total_days = 0;
        return (
            <table className='table'>
                <thead>
                    <tr>
                        <th>Mục tiêu </th>
                        <th>Vị trí </th>
                        <th>Thời gian làm việc</th>
                        <th>Tổng giờ</th>
                        <th>
                            Ngày công
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!helps.isEmpty(duplicates) && duplicates.map((dup, dup_index) => {
                        let project = helps.getItemFromArr(projects, dup.project_id);
                        let project_duration = dup.project_duration / 60;
                        if (!Number.isInteger(project_duration)) {
                            project_duration = parseFloat(project_duration).toFixed(2);
                        } else {
                            project_duration = parseInt(project_duration);
                        }
                        let total_working_days = 0;
                        let work_shift = 12;
                        let shift = dup.position.shift;
                        if (shift && parseInt(shift.duration) > 0) {
                            work_shift = parseInt(dup.position.shift.duration);
                        }
                        total_working_days = parseFloat(project_duration / parseFloat(work_shift)).toFixed(2);
                        total_days += parseFloat(total_working_days);
                        total_hours += parseFloat(project_duration);
                        return (
                            <tr key={'dup_' + dup_index}>
                                <td>{project.name}</td>
                                <td>
                                    {dup.position.name}
                                    <div>
                                        <small><i>{dup.position.description}</i></small>
                                    </div>
                                </td>
                                <td>
                                    {shift && <>
                                        {shift.start} - {shift.end} <i>(<b>{shift.duration}</b>/24)</i>
                                    </>}
                                </td>
                                <td>{project_duration} <i className='text-muted'>(giờ)</i></td>
                                <td>{total_working_days}</td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td colSpan={5}>
                            <b><i>Tăng ca:</i></b>
                        </td>
                    </tr>
                    {!helps.isEmpty(duplicates_OT) && duplicates_OT.map((dup, dup_index) => {
                        let project = helps.getItemFromArr(projects, dup.project_id);
                        let project_duration = dup.project_duration / 60;
                        if (!Number.isInteger(project_duration)) {
                            project_duration = parseFloat(project_duration).toFixed(2);
                        } else {
                            project_duration = parseInt(project_duration);
                        }
                        let total_working_days = 0;
                        let work_shift = 12;
                        let shift = dup.position.shift;
                        if (shift && parseInt(shift.duration) > 0) {
                            work_shift = parseInt(dup.position.shift.duration);
                        }
                        total_working_days = parseFloat(project_duration / parseFloat(work_shift)).toFixed(2);
                        total_days += parseFloat(total_working_days);
                        total_hours += parseFloat(project_duration);
                        return (
                            <tr key={'dup_OT_' + dup_index}>
                                <td>{project.name}</td>
                                <td>
                                    {dup.position.name}
                                    <div>
                                        <small><i>{dup.position.description}</i></small>
                                    </div>
                                </td>
                                <td>
                                    {shift && <>
                                        {shift.start} - {shift.end} <i>(<b>{shift.duration}</b>/24)</i>
                                    </>}
                                </td>
                                <td>{project_duration} <i className='text-muted'>(giờ)</i></td>
                                <td>{total_working_days}</td>
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={3} className='text-right'><b><i>Tổng:</i></b></td>
                        <td><b>{total_hours}</b> <i className='text-muted'>(giờ)</i></td>
                        <td><b>{parseFloat(total_days).toFixed(2)}</b></td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    renderData(employee, project) {
        const { checks, month, projects, housings } = this.props;
        var employee_checks = checks.filter(check => check.project_id === project.id);
        var firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
        let today = month ? month : new Date();
        var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        let tds = [];
        var total_duration = 0;
        for (let i = 1; i <= lastDay.getDate(); i++) {
            let cur_day = new Date(firstDay.getFullYear(), firstDay.getMonth(), i);
            var day_checks = [];
            day_checks = employee_checks.filter((check) => {
                let check_date = check.check_date;
                if (check_date.getFullYear() === cur_day.getFullYear() && check_date.getMonth() === cur_day.getMonth() && check_date.getDate() === cur_day.getDate()) return true;
                else return false;
            });
            if (!helps.isEmpty(this.state.selected_project)) {
                day_checks = day_checks.filter(check => check.project_id === this.state.selected_project.value);
            }
            tds.push(
                <td key={'td-' + i} className={helps.isEmpty(day_checks) ? 'empty' : ''}>
                    {day_checks && day_checks.length > 0 && day_checks.map((check) => {
                        var duration = check.check_duration / 60;
                        if (!Number.isInteger(duration)) {
                            duration = parseFloat(check.check_duration / 60).toFixed(1);
                        } else {
                            duration = parseInt(duration);
                        }
                        total_duration = parseFloat(total_duration) + parseFloat(duration);
                        let project = helps.getItemFromArr(projects, check.project_id);
                        let position = check.position;
                        let housing = helps.getItemFromArr(housings, check.housing_id);
                        return (
                            <CPopover
                                content={<div>
                                    <div className='border-bottom mb-2'>
                                        <div><b>{employee.fullname}</b> <small className='text-muted'> | {helps.formatDate_from_Timestamp(cur_day)}</small></div>
                                        <small className='text-muted'><u>Mục tiêu:</u> <b>{project.name}</b></small>
                                        <div><small className='text-muted'><u>Vị trí:</u> <b>{position.name}</b>{!helps.isEmpty(position.description) && <i>{' - ' + position.description}</i>}  (<i><b>{position.shift.duration}</b>/24</i>)</small></div>
                                        <div><small className='text-muted'><u>Check ID:</u> {check.id}</small></div>
                                        <div><small className='text-muted'><u>Nhà Đội:</u> {housing && <b>{housing.name}</b>}</small></div>

                                    </div>
                                    {check.is_overtime && <b><i>Tăng ca</i></b>}
                                    <div>Check-in: <b>{helps.formatDateTime_from_Timestamp(check.checkin_time)}</b></div>
                                    <div>Check-out: <b>{!check.checkout_time && <span className='text-danger'>Chưa checkout</span>}{check.checkout_time && <>{helps.formatDateTime_from_Timestamp(check.checkout_time)}</>}</b></div>
                                    <div>Giờ công: <b>{duration}</b></div>
                                    {!helps.isEmpty(check.note) && <small className='text-muted'><b><u>Ghi chú:</u></b> {check.note}</small>}
                                    <div className='border-top mt-2 pt-1 mb-2'><small className='text-muted'><i><CIcon className='mr-1' name='cil-check' size='sm' />{check.added_by_alias}</i></small></div>
                                </div>}
                            >
                                <div key={check.id} id={check.id} >
                                    {!check.is_overtime && <b>{duration}</b>}
                                    {check.is_overtime && <i>({duration})</i>}
                                </div>

                            </CPopover>
                        )
                    })}
                </td>
            );
        }
        if (!Number.isInteger(total_duration)) {
            total_duration = parseFloat(total_duration).toFixed(1);
        } else {
            total_duration = parseInt(total_duration);
        }

        const duplicates = employee_checks.reduce((acc, check) => {
            let newItem = acc.find((i) => i.project_id === check.project_id);

            if (newItem) {
                newItem.project_duration += check.check_duration;
            } else {
                acc.push({ project_id: check.project_id, project_duration: check.check_duration });
            }

            return acc;
        }, []);
        let total_content = '<div>';
        if (!helps.isEmpty(duplicates)) {
            duplicates.map((dup) => {
                let project = helps.getItemFromArr(projects, dup.project_id);
                let project_duration = dup.project_duration / 60;
                if (!Number.isInteger(project_duration)) {
                    project_duration = parseFloat(project_duration).toFixed(1);
                } else {
                    project_duration = parseInt(project_duration);
                }
                total_content += '<div> - ' + project.name + ': <b>' + project_duration + '</b></div>'
            })
        }
        total_content += '</div>';
        tds.push(
            <td key={'total-duration-' + employee.employee_id}>
                <CPopover header="Tổng:" content={<div dangerouslySetInnerHTML={{ __html: total_content }} />}>
                    <b>{total_duration}</b>
                </CPopover>
            </td>
        );
        return tds;
    }

    renderTotalDayHours(employee) {
        let tds = [];
        const { checks, month } = this.props;
        var firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
        let today = month ? month : new Date();
        var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        var total_hours = 0;
        for (let i = 1; i <= lastDay.getDate(); i++) {
            let cur_day = new Date(firstDay.getFullYear(), firstDay.getMonth(), i);
            let day_checks = [];
            let total_day_hours = 0;
            let employee_checks = checks.filter(check => check.employee_id === employee.employee_id);
            day_checks = employee_checks.filter((check) => {
                let check_date = check.check_date;
                if (check_date.getFullYear() === cur_day.getFullYear() && check_date.getMonth() === cur_day.getMonth() && check_date.getDate() === cur_day.getDate()) return true;
                else return false;
            });
            if (!helps.isEmpty(this.state.selected_project)) {
                day_checks = day_checks.filter(check => check.project_id === this.state.selected_project.value);
            }
            if (!helps.isEmpty(day_checks) && day_checks.length > 0) {
                day_checks.map((check) => {
                    let duration = check.check_duration / 60;
                    if (!Number.isInteger(duration)) {
                        duration = parseFloat(check.check_duration / 60).toFixed(1);
                    } else {
                        duration = parseInt(duration);
                    }
                    total_day_hours = parseFloat(total_day_hours) + parseFloat(duration);
                })
            }
            if (!Number.isInteger(total_day_hours)) {
                total_day_hours = parseFloat(total_day_hours).toFixed(1);
            } else {
                total_day_hours = parseInt(total_day_hours);
            }
            total_hours += parseFloat(total_day_hours);
            tds.push(
                <td key={'td-totaldayhours' + i}>
                    <CPopover
                        content={<div>
                            Tổng giờ ngày {helps.formatDate_from_Timestamp(cur_day)}: <b>{total_day_hours}</b> giờ
                        </div>}
                    >
                        <div className='font-weight-bold'>
                            {total_day_hours}
                        </div>
                    </CPopover>
                </td>
            );

        }
        tds.push(
            <td className='bg-secondary text-dark'>
                <CPopover
                    content={<div>
                        Tổng giờ: <b>{total_hours}</b> giờ
                    </div>}
                >
                    <div className='font-weight-bold'>
                        {total_hours}
                    </div>
                </CPopover>

            </td>
        );
        return tds;
    }

    render() {
        const { projects, employees, positions, checks, employee } = this.props;

        if (helps.isEmpty(projects)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(positions)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(employees)) {
            return (
                <Loading></Loading>
            );
        }
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        let employee_projects = checks.reduce((acc, check) => {
            let newItem = acc.find((i) => {
                if (i.project_id === check.project_id) {
                    return true;
                } else {
                    return false;
                }
            });

            if (!newItem) {
                acc.push({ project_id: check.project_id });
            }
            return acc;
        }, []);
        employee_projects = helps.unigByKeepLast(employee_projects, it => it.project_id);
        return (
            <CRow>
                <CCol xl={12} md={12}>
                    <div className='timekeeping-container mb-3'>
                        <div className='table-responsive'>
                            <table className='timekeeping'>
                                <thead className='border-bottom'>
                                    <tr>
                                        <th colSpan={2} className='text-center'>Mục Tiêu:</th>
                                        {this.renderHeaders()}
                                    </tr>
                                </thead>
                                <tbody>
                                    {!helps.isEmpty(employee_projects) && employee_projects.map((project_id, index) => {
                                        let project = helps.getItemFromArr(projects, project_id.project_id);
                                        return (
                                            <tr key={project.id}>
                                                <th>{index + 1}</th>
                                                <th>
                                                    <b>{project.name}</b>
                                                </th>
                                                {this.renderData(employee, project)}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2} className='text-right'><i>Tổng giờ/ngày:</i></th>
                                        {this.renderTotalDayHours(employee)}
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div >
                    <div className='mt-3'>
                        <div className='table-responsive'>
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '20%', verticalAlign: 'middle' }} className='bg-secondary text-dark text-center'>
                                            <h5><b>Tổng hợp:</b></h5>
                                        </td>
                                        <td>
                                            {this.renderEmployeeData(employee)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CCol>
            </CRow>
        )
    }
}

export default EmployeeTimekeeping;
