import { db } from "src/dbConfigs";
import { helps } from "src/_helpers";
import { userService } from "src/_services/user.service";
export const taxonomyService = {
    getAll,
    _delete,
    add,
    update,
    updateField,
}
async function getAll(category = '', _limit = 1000) {
    try {
        let ref = db.collection('acc_taxonomies');
        var _query = {};
        if (!helps.isEmpty(category)) {
            _query = ref.where('category', '==', category.id)
                .orderBy("name", "asc")
                .limit(_limit);
        } else {
            _query = ref.orderBy("name", "asc")
                .limit(_limit);
        }

        const snapshot = await _query.get();

        const taxonomies = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        await Promise.all(
            taxonomies.map(async (taxonomy) => {
                let user = await userService.getById(taxonomy.added_by);
                taxonomy['added_by_alias'] = user.fullname + '-' + user.username;
                taxonomy['added_time'] = taxonomy['added_time'].toDate();
            })
        );
        return taxonomies;
    } catch (error) {
        return error;
    }
}

async function add(taxonomy) {
    try {
        const docRef = await db.collection("acc_taxonomies").add(taxonomy);
        taxonomy.id = docRef.id;
        update(taxonomy);
        console.log("Firebase: taxonomy has been added successfully!");
        return taxonomy;
    } catch (error) {
        console.error("Error adding taxonomy: ", error);
    }
}

async function update(taxonomy) {
    var Ref = db.collection("acc_taxonomies").doc(taxonomy.id);

    try {
        await Ref.update(taxonomy);
        console.log("Firebase: taxonomy has been updated successfully!");
        return taxonomy;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function updateField(document, field) {
    let dbRef = db.collection("acc_taxonomies").doc(document.id);
    try {
        await dbRef.update({
            [field]: document[field]
        }).then(() => {
            console.log("Firebase: document field has been updated successfully!");
        });
        return document;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function _delete(taxonomy) {
    var Ref = db.collection("acc_taxonomies").doc(taxonomy.id);

    try {
        await Ref.delete();
        console.log("Firebase: taxonomy has been deleted successfully!");
        return taxonomy;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}
