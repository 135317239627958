import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import Users from '../../views/users/Users';

import { userActions, generalActions } from '../../_actions';

class UsersContainer extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        if (!this.props.users)
            this.props.getUsers();
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        } else {
            return (
                <ThePermission {... this.props} component="users" action="view" alertOut={true}>
                    <Users {... this.props} users={this.props.users} />
                </ThePermission>

            );
        }
    }
}

function mapStateToProps(state) {
    const { loading, users } = state.userReducer;
    const { roles } = state.rolesReducer;
    return { loading, users, roles };
}

const actionCreators = {
    getUsers: userActions.getAll,
    getAllRoles: generalActions.getAllRoles
};

const connectedUsersContainer = connect(mapStateToProps, actionCreators)(UsersContainer);
export { connectedUsersContainer as UsersContainer };