import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CInput,
    CSelect,
    CFormText,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import Editor from 'src/_components/Editor';

class ItemsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            name: '',
            code: '',
            category: 'QTT',
            unit: 'C',
            dimension: '',
            description: '',
            status: 'new_in_use',
            price: 0,
            create_by: this.props.user.id,
            create_date: new Date(),
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEditor = this.handleChangeEditor.bind(this);
    }

    componentDidMount() {
        const item = this.props.item;
        //console.log(item);
        if (item) {
            this.setState({
                id: item.id,
                name: item.name ? item.name : this.state.name,
                code: item.code ? item.code : this.state.code,
                category: item.category ? item.category : this.state.category,
                create_by: item.create_by ? item.create_by : this.props.user.id,
                create_date: item.create_date ? item.create_date : new Date(),
                unit: item.unit ? item.unit : this.state.unit,
                dimension: item.dimension ? item.dimension : this.state.dimension,
                description: item.description ? item.description : this.state.description,
                status: item.status ? item.status : this.state.status,
                price: item.price ? item.price : this.state.price,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let item = {
                id: this.state.id,
                name: this.state.name,
                code: this.state.code,
                category: this.state.category,
                create_by: this.state.create_by,
                create_date: new Date(this.state.create_date),
                unit: this.state.unit,
                dimension: this.state.dimension,
                description: this.state.description,
                status: this.state.status,
                price: this.state.price,
            }
            this.props.handleSubmit(item);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeEditor(field, value) {
        console.log(field, value);
        this.setState({
            [field]: value
        });
    }


    handleChangeFilterDate(_date) {
        let fields = this.state.fields;
        fields['create_date'] = _date;
        this.setState({ create_date: _date });
        this.setState({ fields });
    }


    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập Tên";
        }

        if (helps.isEmpty(this.state.code)) {
            formIsValid = false;
            errors["code"] = "Vui lòng nhập Mã";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        var filter_date_val = '';
        if (this.state.create_date) {
            if (!helps.isEmpty(this.state.create_date) && typeof this.state.create_date === 'object') {
                filter_date_val = helps.formatDate_for_input(this.state.create_date.toDate(), 'YYYY-MM-DD');
            } else {
                filter_date_val = helps.formatDate_for_input(this.state.create_date, 'YYYY-MM-DD');
            }

        } else {
            filter_date_val = new Date().toISOString().slice(0, 10);
        }

        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader>
                                {this.props.form_header}
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-name">Tên:</CLabel>
                                            <CInput
                                                type="text"
                                                name="name"
                                                id="input-name"
                                                value={this.state.name}
                                                required
                                                className='font-weight-bold'
                                                onChange={(e) => { this.handleChange('name', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['name']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-name">Mã:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="code"
                                                        id="input-code"
                                                        value={this.state.code}
                                                        required
                                                        className='font-weight-bold'
                                                        onChange={(e) => { this.handleChange('code', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['code']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-price">Đơn giá:</CLabel>
                                                    <CInputGroup>
                                                        <CInput
                                                            type="text"
                                                            name="price"
                                                            id="input-price"
                                                            value={this.state.price}
                                                            required
                                                            className='font-weight-bold'
                                                            onChange={(e) => { this.handleChange('price', e) }}
                                                        />
                                                        <CInputGroupAppend>
                                                            <CInputGroupText className={'bg-danger text-white'}>
                                                                ₫
                                                            </CInputGroupText>
                                                        </CInputGroupAppend>
                                                    </CInputGroup>
                                                    <small className="text-danger">{this.state.errors['price']}</small>
                                                    <CFormText className="help-block">Theo đơn giá nhập/xuất hoặc đơn giá quy định (<i>định dạng số</i>).</CFormText>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-category">Phân loại:</CLabel>
                                                    <CSelect
                                                        id="input-category"
                                                        value={this.state.category}
                                                        required
                                                        onChange={(e) => { this.handleChange('category', e) }}
                                                    >
                                                        {configs.asset_categories && configs.asset_categories.map((category) => {
                                                            return (
                                                                <option key={category.id} value={category.id}>{category.label}</option>
                                                            );
                                                        })}
                                                    </CSelect>
                                                    <small className="text-danger">{this.state.errors['category']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-unit">Đơn vị tính:</CLabel>
                                                    <CSelect
                                                        id="input-unit"
                                                        defaultValue={this.state.unit}
                                                        required
                                                        onChange={(e) => { this.handleChange('unit', e) }}
                                                    >
                                                        {configs.units && configs.units.map((unit) => {
                                                            return (
                                                                <option key={unit.id} value={unit.id}>{unit.label}</option>
                                                            );
                                                        })}
                                                    </CSelect>
                                                    <small className="text-danger">{this.state.errors['category']}</small>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-dimension">Kích thước / Chủng loại / Mẫu mã:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="dimension"
                                                        id="input-dimension"
                                                        value={this.state.dimension}
                                                        onChange={(e) => { this.handleChange('dimension', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['dimension']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-status">Tình trạng:</CLabel>
                                                    <CSelect
                                                        id="input-status"
                                                        value={this.state.status}
                                                        required
                                                        onChange={(e) => { this.handleChange('status', e) }}
                                                    >
                                                        {configs.asset_statuses && configs.asset_statuses.map((status) => {
                                                            return (
                                                                <option key={status.id} value={status.id}>{status.label}</option>
                                                            );
                                                        })}
                                                    </CSelect>
                                                    <small className="text-danger">{this.state.errors['category']}</small>
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </CCol>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-description">Mô tả:</CLabel>
                                            <Editor
                                                {... this.props}
                                                id="input-description"
                                                name="description"
                                                defaultValue={this.state.description}
                                                handleChangeEditor={this.handleChangeEditor}
                                            />
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default ItemsForm;

