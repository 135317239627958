import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import Positions from 'src/views/positions/Positions';
import { positionActions } from '../../_actions';
import { helps } from 'src/_helpers';

class PositionsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project_id: '',
            positions: '',
        }
    }

    componentDidMount() {
        this.setState({ project_id: this.props.project_id });
        this.getPositions();
    }

    async getPositions() {
        const { positions } = this.props;
        if (helps.isEmpty(positions)) return await this.props.getPositions();
        else return positions;
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <Positions key={'positions_' + this.props.project_id} {... this.props} />
        );
    }
}

function mapStateToProps(state) {
    const { loading, positions } = state.positionReducer;
    const { user } = state.authentication;
    return { loading, user, positions };
}

const actionCreators = {
    getPositions: positionActions.getAll,
    add: positionActions.add,
    update: positionActions.update,
    _delete: positionActions._delete,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(PositionsContainer);
export { connectedContainer as PositionsContainer };