import React from 'react'
import CIcon from '@coreui/icons-react'

const _nav = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Sảnh',
    to: '/dashboard',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Tài Liệu',
    to: '/documents',
    icon: 'cil-library',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Công Việc',
    route: '/tasks',
    icon: 'cil-task',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Cần Làm',
        to: '/tasks#assign_to_me',
        icon: 'cil-list',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Tôi Đã Giao',
        to: '/tasks#own',
        icon: 'cil-list-numbered'
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Theo Dõi',
        to: '/tasks#follow',
        icon: 'cil-list-rich'
      }
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Khách Hàng',
    route: '/customers',
    icon: 'cil-factory',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Cơ Hội',
        to: '/customers/leads',
        icon: 'cil-list',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Tiềm Năng',
        to: '/customers/potentials',
        icon: 'cil-list-numbered'
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Khách Hàng',
        to: '/customers',
        icon: 'cil-factory'
      }
    ]
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Mục Tiêu',
    to: '/projects',
    icon: 'cil-monitor',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Nhà Đội',
    to: '/housings',
    icon: 'cil-house',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Nhân Sự',
    route: '/employees',
    icon: 'cil-people',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Quản Lý Ứng Viên',
        to: '/employees/candidates',
        icon: 'cil-people',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Quản Lý Nhân Sự',
        to: '/employees',
        icon: 'cil-people',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Thêm Nhân Viên',
        to: '/employees/add_edit',
        icon: 'cil-user-follow'
      }
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'HC-KT',
    to: '/accounting/books',
    icon: 'cil-address-book',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Thu/Chi',
        to: '/accounting/books',
        icon: 'cil-money',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Chấm Công',
        to: '/accounting/timekeeping',
        icon: 'cil-Calendar-check'
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Bảng Lương',
        to: '/accounting/salary',
        icon: 'cil-calculator'
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Tài Sản',
        to: '/assets',
        icon: 'cil-bank'
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Nhà Cung Cấp',
        to: '/vendors',
        icon: 'cil-factory'
      },
    ]
  },
  {
    _tag: 'CSidebarNavDivider',
    name: 'NavDivider',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Người Dùng',
    route: '/users',
    icon: 'cil-user',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Quản Lý Người Dùng',
        to: '/users',
        icon: 'cil-user',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Thêm Người Dùng',
        to: '/users/add_edit',
        icon: 'cil-plus'
      }
    ]
  },
  {
    _tag: 'CSidebarNavDivider',
    name: 'NavDivider',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Hệ Thống',
    to: '/settings',
    icon: 'cil-settings',
  },


]

export default _nav
