import { tagService, } from 'src/_services';
import { alertActions } from '.';
import { tagConstants } from 'src/_constants';

export const tagActions = {
    getAll,
    add,
    update,
    _delete,
};

function getAll(_object) {

    return dispatch => {
        dispatch(request(_object));
        tagService.getAll(_object)
            .then(
                tags => dispatch(success(tags)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(_object) { return { type: tagConstants.GET_LIST_REQUEST, _object: _object } }
    function success(tags) { return { type: tagConstants.GET_LIST_SUCCESS, tags } }
    function failure(error) { return { type: tagConstants.GET_LIST_FAILURE, error } }
}


function add(tag) {
    return dispatch => {
        dispatch(request(tag));
        tagService.add(tag)
            .then(
                (tag) => {
                    dispatch(success(tag));
                    dispatch(alertActions.success("Thêm Nhãn thành công!"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );

    }
    function request(tag) { return { type: tagConstants.ADD_REQUEST, tag: tag } }
    function success(tag) { return { type: tagConstants.ADD_SUCCESS, tag: tag } }
    function failure(error) { return { type: tagConstants.ADD_FAILURE, error } }
}

function update(tag) {
    return dispatch => {
        dispatch(request(tag));
        tagService.update(tag)
            .then(
                tag => dispatch(success(tag))
                ,
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );

    }
    function request(tag) { return { type: tagConstants.UPDATE_REQUEST, tag: tag } }
    function success(tag) { return { type: tagConstants.UPDATE_SUCCESS, tag: tag } }
    function failure(error) { return { type: tagConstants.UPDATE_FAILURE, error } }
}

function _delete(tag) {
    return dispatch => {
        dispatch(request(tag));
        tagService._delete(tag)
            .then(
                (tag) => {
                    dispatch(success(tag));
                    dispatch(alertActions.success("Xoá Nhãn thành công!"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );

    }
    function request(tag) { return { type: tagConstants.DELETE_REQUEST, tag } }
    function success() { return { type: tagConstants.DELETE_SUCCESS } }
    function failure(error) { return { type: tagConstants.DELETE_FAILURE, error } }
}