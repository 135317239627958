import { noteConstants } from "src/_constants";

export function noteReducer(state = {}, actions) {
    switch (actions.type) {
        // GET LATEST
        case noteConstants.GET_LATEST_REQUEST:
            return {
                ...state,
                latest_loading: true,
            }
        case noteConstants.GET_LATEST_SUCCESS:
            return {
                ...state,
                latest_notes: actions.notes,
                latest_loading: false,
            }
        case noteConstants.GET_LATEST_FAILURE:
            return {
                ...state,
                latest_loading: true,
                error: actions.error,
            }
        // GET LIST    
        case noteConstants.GET_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case noteConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                notes: actions.notes,
                loading: false,
            }
        case noteConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Add
        case noteConstants.ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case noteConstants.ADD_SUCCESS:
            state.notes.push(actions.note);
            return {
                ...state,
                loading: false,
            }
        case noteConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Edit
        case noteConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case noteConstants.EDIT_SUCCESS:
            var _notes = state.notes;
            var foundIndex = _notes.findIndex(note => note.id === actions.note.id);
            _notes[foundIndex] = actions.note;
            console.log(foundIndex);
            return {
                ...state,
                notes: _notes,
                loading: false,
            }
        case noteConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        // Delete 
        case noteConstants.DELETE_REQUEST:
            return {
                ...state,
                notes: state.notes.map(note =>
                    note.id === actions.note.id
                        ? { ...note, deleting: true }
                        : note
                )
            };
        case noteConstants.DELETE_SUCCESS:
            return {
                notes: state.notes.filter(note => note.id !== actions.note.id)
            };
        case noteConstants.DELETE_FAILURE:
            return {
                ...state,
                notes: state.notes.map(note => {
                    if (note.id === actions.note.id) {
                        const { deleting, ...noteCopy } = note;
                        return { ...noteCopy, deleteError: actions.error };
                    }
                    return note;
                })
            };

        default: return state;
    }
}