import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CModal, CModalBody, CModalHeader, CModalFooter, CContainer, CInput, CTextarea, CFormGroup, CSelect,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';

class ChecksForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            id: '',
            project_id: this.props.project.id,
            employee_id: this.props.employee.employee_id,
            checked_in: 0,
            checkin_time: '',
            checkin_diff: 0,
            checked_out: 0,
            checkout_time: '',
            checkout_diff: 0,
            employee_position: {},
            employee_shift: {},
            check_date: '',
            check_time: '',
            state_text: 'Check-in',
            added_by: this.props.user.id,
            note: '',
            max_date: new Date(),
            housing_id: '',
            check_duration: 0,

            position_id: '',
            shift_id: '',
            selected_position: {},
            selected_shift: {},
            position_shifts: [],
            default_housing: {},
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeTime = this.handleChangeTime.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { employee, default_shift, default_position, action, curent_date, check, is_overtime, housings } = this.props;
        var check_time = new Date(curent_date);
        var state_text = this.state.state_text;
        var shift_start = '';
        var shift_end = '';
        var max_date = new Date(curent_date);
        var min_date = new Date(curent_date);
        min_date = new Date(min_date.setHours(0, 0, 0));

        max_date = new Date(max_date.setDate(curent_date.getDate() + 1));

        if (default_position) {
            this.setState({
                position_shifts: default_position.shifts,
                selected_position: default_position,
                position_id: default_position.id,
            });
        }
        if (default_shift) {
            this.setState({
                selected_shift: default_shift,
                shift_id: default_shift.id,
                max_date: new Date(max_date.setHours(curent_date.getHours() + default_shift.duration)),
            });
        }
        //console.log(action)
        if (action === 'checkin') {
            if (default_shift) {
                if (!helps.isEmpty(default_shift.start)) {
                    check_time = helps.setTimetoDate(curent_date, default_shift.start);
                }
                shift_start = default_shift.start;
            }
            if (is_overtime) {
                state_text = state_text + ' - Tăng Ca';
            }
        } else {
            var checkin_date = moment(check.checkin_time);
            let check_position = check.position;
            let check_shift = !helps.isEmpty(check_position.shift) ? check_position.shift : default_shift;
            if (!helps.isEmpty(check_position)) {
                this.setState({
                    position_shifts: check_position.shifts,
                    selected_position: check_position,
                    position_id: check_position.id,
                });
            }
            if (check_shift) {
                if (checkin_date) {
                    check_time = new Date(checkin_date.add(check_shift.duration, 'h'));
                }
                shift_end = check_shift.end;
                this.setState({
                    selected_shift: check_shift,
                    shift_id: check_shift.id,
                    max_date: new Date(max_date.setHours(curent_date.getHours() + check_shift.duration)),
                });
            }
            state_text = 'Check-out';
            if (is_overtime) {
                state_text = state_text + ' - Tăng Ca';
            }


        }

        if (isNaN(check_time.getTime())) {
            check_time = new Date(curent_date);
        }

        var default_housings = housings.filter((housing) => {
            if (!helps.isEmpty(housing.employees)) {
                let found_employee = housing.employees.filter((_employee) => {
                    return _employee.value === employee.employee_id;
                })
                if (helps.isEmpty(found_employee)) return false;
                else return true;
            } else {
                return false;
            }
        })
        var default_housing = '';
        if (!helps.isEmpty(default_housings)) {
            default_housing = default_housings[0];
        }

        this.setState({
            check_time: check_time,
            state_text: state_text,
            check_date: curent_date,
            shift_start: shift_start,
            shift_end: shift_end,
            min_date: min_date,
            max_date: max_date,
            default_housing: default_housing,
            housing_id: default_housing.id,
        });

        if (!helps.isEmpty(check)) {
            this.setState({
                id: check.id,
                checked_in: check.checked_in,
                checkin_time: check.checkin_time,
                checkin_diff: check.checkin_diff,
                checked_out: check.checked_out,
                checkout_time: check.checkout_time,
                checkout_diff: check.checkout_diff,
                check_date: check.check_date,
                project_id: check.project_id,
                employee_id: check.employee_id,
                added_by: check.added_by,
                note: check.note,
                housing_id: !helps.isEmpty(check.housing_id) ? check.housing_id : default_housing.id,
                selected_position: check.position,
                selected_shift: check.position.shift,
            });
            if (check_time && check.checkin_time) {
                var diff = check_time.valueOf() - check.checkin_time.valueOf();
                diff = diff / 1000 / 60 / 60;

                let diffInH = parseFloat(diff.toFixed(10));
                this.setState({ check_duration: diffInH });
            }

        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const { check, action, is_overtime } = this.props;
        if (this.handleValidation()) {
            var _check = {};
            if (!helps.isEmpty(check)) {
                _check = check;
                _check['checked_out'] = 1;
                _check['checkout_time'] = this.state.checkout_time ? this.state.checkout_time : this.state.check_time;
                if (!is_overtime) {
                    _check['checkout_diff'] = this.state.checkout_diff;
                }
                _check['note'] = this.state.note;
                var diff = check.checkout_time.valueOf() - check.checkin_time.valueOf();
                diff = diff / 1000 / 60;

                var diffInM = parseFloat(diff.toFixed(10));
                _check['check_duration'] = diffInM;
            } else {
                let selected_position = this.state.selected_position;
                selected_position['shift'] = this.state.selected_shift;
                _check = {
                    project_id: this.state.project_id,
                    employee_id: this.state.employee_id,
                    checked_in: 1,
                    checkin_time: this.state.checkin_time ? this.state.checkin_time : this.state.check_time,
                    checkin_diff: !is_overtime ? this.state.checkin_diff : 0,
                    checked_out: 0,
                    checkout_time: '',
                    note: this.state.note,
                    check_date: this.state.check_date,
                    added_by: this.state.added_by,
                    position: selected_position,
                    is_overtime: is_overtime ? true : false,
                }
                _check['check_duration'] = 0;

            }
            _check['housing_id'] = this.state.housing_id;

            if (action === 'checkin') {
                this.props.add(_check);
            } else {
                this.props.update(_check);
            }
            this.props.toggleForm(this.props.employee.employee_id + '-' + is_overtime.toString());

        } else {
            return false;
        }
    }

    handleChangeTime(value) {
        const { action, curent_date } = this.props;
        //console.log(value)
        this.setState({
            check_time: value
        });
        if (action === 'checkin') {
            /*
            var start = helps.setTimetoDate(curent_date, this.state.selected_shift.start);
            var diff = start.valueOf() - value.valueOf();
            var diffInM = Math.floor(diff / 1000 / 60);
            console.log(diff, diffInM)
            */
            this.setState({
                checked_in: 1,
                checkin_time: value,
            });
        } else {
            /*
            var end = helps.setTimetoDate(curent_date, this.state.selected_shift.end);
            console.log(this.state)
            
            var end = helps.setTimetoDate(curent_date, this.state.selected_shift.end);
            var diff = end.valueOf() - value.valueOf();
            var diffInM = Math.floor(diff / 1000 / 60);
            */
            let checkout_time = value;
            let checkin_time = this.state.checkin_time;
            var diff = checkout_time.valueOf() - checkin_time.valueOf();
            diff = diff / 1000 / 60 / 60;

            let check_duration = parseFloat(diff.toFixed(10));
            this.setState({
                checked_out: 1,
                checkout_time: checkout_time,
                check_duration: check_duration,
            });
        }
        //console.log(this.state)
        // console.log(parseInt(helps.calDiffTimeToMinutes(value, this.state.shift_end)))
    }

    handleChange(field, e) {
        const { project } = this.props;
        let value = e.target.value;
        this.setState({
            [field]: value
        });
        if (field === 'position_id') {
            let position = helps.getItemFromArr(project.positions, value);
            this.setState({ selected_position: position, position_shifts: position.shifts });
            if (!helps.isEmpty(position.shifts)) {
                let selected_shift = position.shifts[0];
                if (!helps.isEmpty(selected_shift)) {
                    let check_time = new Date(this.state.check_time);
                    let shift_start = selected_shift.start.split(":");
                    check_time.setHours(shift_start[0], shift_start[1]);
                    this.setState({ selected_shift: selected_shift, check_time: check_time });
                }
            }
        }
        if (field === 'shift_id') {
            let shift = helps.getItemFromArr(this.state.selected_position.shifts, value);
            let check_time = new Date(this.state.check_time);
            let shift_start = shift.start.split(":");
            check_time.setHours(shift_start[0], shift_start[1]);
            this.setState({ selected_shift: shift, shift_id: value, check_time: check_time });
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        /*
        if (helps.isEmpty(this.state.check_time)) {
            formIsValid = false;
            errors["check_time"] = "Vui lòng chọn Thời gian " + this.state.state_text;
        }
        */
        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        const { employee, project, action, curent_date, show_forms, default_shift, default_position, is_overtime, housings } = this.props;

        var position_shifts = this.state.position_shifts;
        var positions = project.positions;
        var header = this.state.state_text;
        var default_housings = housings.filter((housing) => {
            if (!helps.isEmpty(housing.employees)) {
                let found_employee = housing.employees.filter((_employee) => {
                    return _employee.value === employee.employee_id;
                })
                if (helps.isEmpty(found_employee)) return false;
                else return true;
            } else {
                return false;
            }
        })
        var default_housing = '';
        if (!helps.isEmpty(default_housings)) {
            default_housing = default_housings[0];
        }
        return (
            <CRow>
                <CCol xl={12}>
                    <CForm >
                        <CModal
                            size='lg'
                            show={show_forms.includes(employee.employee_id + '-' + is_overtime.toString())}
                        >
                            <CModalHeader>
                                <h3>
                                    <b>{header}</b> _<small>{helps.formatDate_from_Timestamp(curent_date)}</small>
                                </h3>
                                <CIcon name="cil-x-circle" className="mfe-1 mt-0 cursor-pointer" onClick={(e) => { this.props.toggleForm(employee.employee_id + '-' + is_overtime.toString()) }}></CIcon>
                            </CModalHeader>
                            <CModalBody className='text-left'>
                                <CContainer>
                                    <CRow className='mb-2'>
                                        <CCol md={12}>
                                            <small className='text-muted'><i>Nhân Viên:</i></small> <b>{employee.fullname}</b> ({employee.employee_id})
                                            <div><small className='text-muted'><i>Tại mục tiêu:</i></small> <b>{project.name}</b></div>
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-3'>
                                        <CCol md={6} sm={12}>
                                            <CLabel htmlFor={'position_id'}>Vị trí:</CLabel>
                                            <CSelect
                                                name='position_id'
                                                value={this.state.position_id}
                                                disabled={action === 'checkout'}
                                                onChange={(e) => { this.handleChange('position_id', e) }}
                                            >
                                                {!helps.isEmpty(positions) && positions.length > 0 && positions.map((position) => {
                                                    let position_name = position.name;
                                                    if (!helps.isEmpty(position.description)) {
                                                        position_name = position_name + ' - ' + position.description;
                                                    }
                                                    return (
                                                        <option key={position.id} value={position.id}>{position_name}</option>
                                                    );
                                                })}
                                            </CSelect>
                                        </CCol>
                                        <CCol md={6} sm={12}>
                                            <CLabel htmlFor={'shift_id'}>Ca trực:</CLabel>
                                            <CSelect
                                                name='shift_id'
                                                value={helps.isEmpty(this.state.shift_id) ? default_shift.id : this.state.shift_id}
                                                disabled={action === 'checkout'}
                                                onChange={(e) => { this.handleChange('shift_id', e) }}
                                            >
                                                {!helps.isEmpty(position_shifts) && position_shifts.length > 0 && position_shifts.map((shift) => {
                                                    let shift_name = shift.label;
                                                    shift_name = shift_name + ' - ' + shift.start + '-' + shift.end + '(' + shift.duration + '/24)';
                                                    return (
                                                        <option key={shift.id} value={shift.id}>{shift_name}</option>
                                                    );
                                                })}
                                            </CSelect>
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-3 mt-1'>
                                        <CCol md={12}>
                                            <CLabel htmlFor={'check_time_' + employee.employee_id}>Thời gian {header}:</CLabel>
                                            <CFormGroup variant='custom-checkbox' inline>
                                                <DateTimePicker
                                                    value={this.state.check_time}
                                                    format={'dd/MM/yyyy HH:mm'}
                                                    className='font-weight-bold'
                                                    onChange={(value) => { this.handleChangeTime(value) }}
                                                />
                                                {this.state.check_duration > 0 && <span className='ml-3 mt-2 font-weight-bold text-danger'><i>({this.state.check_duration} giờ)</i></span>}
                                            </CFormGroup>
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-2'>
                                        <CCol md={12}>
                                            <CLabel htmlFor='housing_id'>Nhà Đội:</CLabel>
                                            <CFormGroup variant='custom-checkbox' inline>
                                                <CSelect
                                                    id='housing_id'
                                                    defaultValue={default_housing.id}
                                                    onChange={(e) => { this.handleChange('housing_id', e) }}
                                                >
                                                    <option value=''> - không - </option>
                                                    {!helps.isEmpty(housings) && housings.map((housing) => {
                                                        return (
                                                            <option key={'housing-' + housing.id} value={housing.id}>{housing.name}</option>
                                                        );
                                                    })}
                                                </CSelect>
                                            </CFormGroup>
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-3'>
                                        <CCol md={12}>
                                            <CLabel htmlFor='note'>Ghi chú:</CLabel>
                                            <CTextarea
                                                rows={4}
                                                name="note"
                                                id="note"
                                                defaultValue={this.state.content}
                                                onChange={(e) => { this.handleChange('note', e) }}
                                            >
                                            </CTextarea>
                                        </CCol>
                                    </CRow>
                                </CContainer>
                            </CModalBody>
                            <CModalFooter>
                                <CButton type="submit" size='sm' color={action === 'checkin' ? "success" : "danger"} onClick={(e) => this.handleSubmit(e)}>
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    {header}
                                </CButton>
                                <CButton type="button" size='sm' color="secondary" onClick={(e) => { this.props.toggleForm(employee.employee_id + '-' + is_overtime.toString()) }} className="ml-3">
                                    <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                    Huỷ
                                </CButton>
                            </CModalFooter>
                        </CModal>
                    </CForm>
                </CCol>
            </CRow>
        )
    }
}
export default ChecksForm;

