import { db, storage } from "src/dbConfigs";
import { helps } from "src/_helpers";
import { userService } from "./user.service";
import { employeeService } from "./employees/employee.service";
import { customerService } from "./customer.service";
import { projectService } from "./project.service";
import { notificationService } from "./notification.service";
import { fileService } from "./file.service";
import configs from "src/appConfigs";
import { candidateService } from "./employees";
import { housingService } from "./housing.service";
export const taskService = {
    getAll,
    _delete,
    add,
    update,
    getUserTasks,
    addFollower,
    removeFollower,
    addAssign,
    removeAssign,
    getComments,
    addComment,
    updateComment,
    deleteComment,
    deleteCollection,
}
async function getAll(params = {}, _limit = 1000) {
    try {
        let ref = db.collection('tasks');
        var curent_date = new Date();
        var start_date = new Date(curent_date.getFullYear(), curent_date.getMonth(), 1);
        start_date = new Date(start_date.setHours(0, 0, 0));
        var end_date = new Date(curent_date.getFullYear(), curent_date.getMonth() + 1, 0);
        end_date = new Date(end_date.setHours(23, 59, 59));
        //console.log(params);
        var _query = ref.where('deadline', '>=', new Date(start_date))
            .where('deadline', '<=', new Date(end_date))
            .orderBy("deadline", "desc")
            .limit(_limit);
        if (!helps.isEmpty(params)) {
            if (params.curent_date) {
                curent_date = params.curent_date;
            }
            start_date = new Date(curent_date.getFullYear(), curent_date.getMonth(), 1);
            start_date = new Date(start_date.setHours(0, 0, 0));
            end_date = new Date(curent_date.getFullYear(), curent_date.getMonth() + 1, 0);
            end_date = new Date(end_date.setHours(23, 59, 59));

            if (!helps.isEmpty(params.object) && !helps.isEmpty(params.object_id)) {
                _query = ref.where('object', '==', params.object)
                    .where('object_id', '==', params.object_id)
                    .orderBy("deadline", "desc")
                    .limit(_limit);
            } else {
                if (!helps.isEmpty(params.object) && helps.isEmpty(params.object_id)) {
                    _query = ref.where('object', '==', params.object)
                        .orderBy("deadline", "desc")
                        .limit(_limit);
                } else {
                    _query = ref.where('deadline', '>=', new Date(start_date))
                        .where('deadline', '<=', new Date(end_date))
                        .orderBy("deadline", "desc")
                        .limit(_limit);
                }
            }
        }

        const snapshot = await _query.get();

        const tasks = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        await Promise.all(
            tasks.map(async (task) => {
                prepareTaskMeta(task);
                task['assigns'] = await getTaskAttrs('assigns', task.id);
                task['followers'] = await getTaskAttrs('followers', task.id);
            })
        );
        //tasks.sort((a, b) => b.task_time - a.task_time);
        return tasks;
    } catch (error) {
        return error;
    }
}

async function prepareTaskMeta(task) {
    if (task.deadline) {
        task['deadline'] = task['deadline'].toDate();
    }

    if (task.object) {
        task['object_alias'] = configs.objects[task.object];
    }

    if (task.added_by) {
        let added_by = await userService.getById(task.added_by);
        task['added_by_alias'] = added_by.fullname + '-' + added_by.username;
    }

    task['comments'] = await getComments(task);

    task['added_time'] = task['added_time'].toDate();
    if (!helps.isEmpty(task.object) && !helps.isEmpty(task.object_id)) {
        switch (task.object) {
            case 'employees':
                let employee = await employeeService.getById(task.object_id);
                if (employee) {
                    task['object_name'] = employee.fullname;
                    task['object_link'] = '/employees/view/' + task.object_id;
                }

                break;
            case 'customers':
                let customer = await customerService.getById(task.object_id);
                task['object_name'] = customer.name;
                task['object_link'] = '/customers/view/' + customer.id;
                break;
            case 'projects':
                let project = await projectService.getById(task.object_id);
                task['object_name'] = project.name;
                task['object_link'] = '/projects/view/' + task.object_id;
                break;
            case 'candidates':
                let candidate = await candidateService.getById(task.object_id);
                task['object_name'] = candidate.fullname;
                task['object_link'] = '/employees/candidates/view/' + task.object_id;
                break;
            case 'housings':
                let housing = await housingService.getById(task.object_id);
                task['object_name'] = housing.name;
                task['object_link'] = '/housings/view/' + task.object_id;
                break;
            default:
        }
    }
    task['touched'] = true;
    return task;
}

async function getUserTasks(params = {}, _limit = 1000) {
    try {
        var tasks = [];
        //console.log(_user);
        if (!helps.isEmpty(params) && !helps.isEmpty(params.user)) {
            let user = params.user;
            let assigns = await getAssigns(user, _limit);
            let owns = await getOwns(user, _limit);
            let follows = await getFollows(user, _limit);
            tasks = tasks.concat(assigns, owns, follows);
            return Promise.all(tasks);
        } else {
            return [];
        }


    } catch (error) {
        return error;
    }
}

async function getAssigns(user, _limit) {
    try {
        let ref = db.collectionGroup("assigns");
        let _query = ref.where('value', "==", user.id)
            .limit(_limit);
        const snapshot = await _query.get();
        var tasks = new Array;
        var parents = new Array;
        snapshot.forEach(async (queryDocumentSnapshot) => {
            let documentParent = queryDocumentSnapshot.ref.parent;
            let parent_id = documentParent.parent.id;
            if (parent_id) parents.push(parent_id);
        })
        let tasksRef = db.collection('tasks');
        if (!helps.isEmpty(parents)) {
            const batches = [];
            while (parents.length) {
                // firestore limits batches to 10
                const batch = parents.splice(0, 10);
                // add the batch request to to a queue
                batches.push(
                    tasksRef
                        .where(
                            "id",
                            'in',
                            [...batch]
                        )
                        .orderBy("added_time", "desc")
                        .get()
                        .then(results => results.docs.map(result => ({ ...result.data() })))
                )
            }
            return Promise.all(batches)
                .then(content => {
                    let tasks = content.flat();
                    Promise.all(tasks.map(async (task) => {
                        prepareTaskMeta(task);
                        task['filter'] = 'assigns';
                        task['assigns'] = await getTaskAttrs('assigns', task.id);
                        task['followers'] = await getTaskAttrs('followers', task.id);
                    })
                    )
                    return tasks;
                });
        }
        return tasks;
    } catch (error) {
        return error;
    }
}

async function getFollows(user, _limit) {
    try {
        let ref = db.collectionGroup("followers");
        let _query = ref.where('value', "==", user.id)
            .limit(_limit);
        const snapshot = await _query.get();
        var tasks = new Array;
        var parents = new Array;
        snapshot.forEach(async (queryDocumentSnapshot) => {
            let documentParent = queryDocumentSnapshot.ref.parent;
            let parent_id = documentParent.parent.id;
            if (parent_id) parents.push(parent_id);
        })

        let tasksRef = db.collection('tasks');
        if (!helps.isEmpty(parents)) {
            const batches = [];
            while (parents.length) {
                // firestore limits batches to 10
                const batch = parents.splice(0, 10);
                // add the batch request to to a queue
                batches.push(
                    tasksRef
                        .where(
                            "id",
                            'in',
                            [...batch]
                        )
                        .orderBy("added_time", "desc")
                        .get()
                        .then(results => results.docs.map(result => ({ ...result.data() })))
                )
            }
            tasks = Promise.all(batches)
                .then(content => {
                    let tasks = content.flat();
                    Promise.all(tasks.map(async (task) => {
                        prepareTaskMeta(task);
                        task['filter'] = 'follows';
                        task['assigns'] = await getTaskAttrs('assigns', task.id);
                        task['followers'] = await getTaskAttrs('followers', task.id);
                    })
                    )
                    return tasks;
                });
        }
        return tasks;
    } catch (error) {
        return error;
    }
}


async function getOwns(user, _limit = 1000) {
    try {
        let ref = db.collection('tasks');
        var tasks = [];
        if (!helps.isEmpty(user)) {
            let _query = ref.where('added_by', '==', user.id)
                .orderBy("added_time", "desc")
                .limit(_limit);
            const snapshot = await _query.get();
            tasks = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
        }
        if (!helps.isEmpty(tasks) && tasks.length > 0) {
            Promise.all(tasks.map(async (task) => {
                prepareTaskMeta(task);
                task['filter'] = 'owns';
                task['assigns'] = await getTaskAttrs('assigns', task.id);
                task['followers'] = await getTaskAttrs('followers', task.id);
            })
            )
        }
        return tasks;
    } catch (error) {
        return error;
    }
}

async function getTaskAttrs(attr_name, task_id) {
    let ref = db.collection("tasks").doc(task_id).collection(attr_name);
    const snapshot = await ref.get();
    const attrs = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));
    attrs.map(async (attr) => {
        let _dup_snapshot = await ref.where('value', '==', attr.value).get();
        let _dup_attrs = _dup_snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        if (_dup_attrs && _dup_attrs.length > 0) {
            _dup_attrs.map(async (_dup_attr) => {
                let _dup_ref = ref.doc(_dup_attr.id);
                if (attr.id !== _dup_attr.id) {
                    console.log(attr_name, _dup_attrs)
                    await _dup_ref.delete();
                }
            })
        }
    })

    return attrs;
}

async function add(task) {
    try {
        var upload_files = task.attachment;
        task['attachment'] = '';
        const docRef = await db.collection("tasks").add(task);
        task.id = docRef.id;

        const assignsRef = db.collection("tasks/" + task.id + "/assigns");

        const user = await userService.getById(task.added_by);

        if (!helps.isEmpty(task.assigns)) {
            Promise.all(
                task.assigns.map(async (assign) => {
                    let docAssignRef = await assignsRef.add(assign);
                    assign.id = docAssignRef.id;
                    assignsRef.doc(assign.id).update(assign);

                    const content = '<b>' + user.fullname + '-' + user.username + '</b> đã Giao cho bạn công việc <b>' + task.title + '</b>';
                    let _notification = {
                        content: content,
                        added_time: new Date(),
                        object: 'tasks',
                        object_id: task.id,
                        object_link: 'tasks/view/' + task.id,
                        user_id: assign.value,
                    }
                    notificationService.add(_notification);
                })
            );
            delete task.assigns;
        }

        const followersRef = db.collection("tasks/" + task.id + "/followers");
        if (!helps.isEmpty(task.followers)) {
            Promise.all(
                task.followers.map(async (follower) => {
                    let docFollowerRef = await followersRef.add(follower);
                    follower.id = docFollowerRef.id;
                    followersRef.doc(follower.id).update(follower);
                })
            );
            delete task.followers;
        }

        if (!helps.isEmpty(upload_files) && upload_files instanceof FileList) {
            var _files = [];
            var _attachment = [];
            _files = await fileService.uploadFile(upload_files, 'tasks/', task.id);

            if (!helps.isEmpty(_files) && _files.length > 0) {
                _files.map((file) => {
                    let _file = {
                        name: file.name,
                        url: file.url,
                    }
                    _attachment.push(_file);
                })
            }
            task['attachment'] = _attachment;
        }

        update(task);
        console.log("Firebase: task has been added successfully!");
        return task;
    } catch (error) {
        console.error("Error adding task: ", error);
    }
}

async function update(task) {
    var Ref = db.collection("tasks").doc(task.id);

    try {
        var upload_files = task.attachment;
        if (!helps.isEmpty(upload_files) && upload_files instanceof FileList) {
            // Xoá các file cũ đi
            var storageRef = storage.ref('tasks').child(task.id);
            storageRef.listAll().then(function (result) {
                result.items.forEach(function (file) {
                    file.delete();
                });
            }).catch(function (error) {
                // Handle any errors
            });

            // upload files mới lên
            var _files = [];
            var _attachment = [];
            _files = await fileService.uploadFile(upload_files, 'tasks/', task.id);
            console.log(upload_files);
            if (!helps.isEmpty(_files) && _files.length > 0) {
                _files.map((file) => {
                    let _file = {
                        name: file.name,
                        url: file.url,
                    }
                    _attachment.push(_file);
                });
                console.log("Firebase: files uploaded successfully!");
            }
            task['attachment'] = _attachment;
        }
        await Ref.update(task);
        console.log("Firebase: task has been updated successfully!");
        return task;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _delete(task) {
    if (helps.isEmpty(task)) return;
    var Ref = db.collection("tasks").doc(task.id);

    try {
        if (!helps.isEmpty(task.attachment) && task.attachment.length > 0) {
            Promise.all(
                task.attachment.map(async (file) => {
                    file['object'] = 'tasks';
                    file['object_id'] = task.id;
                    let del_file = await fileService.deleteFile(file);
                    console.log(del_file);
                })
            );
        }
        await Ref.delete();
        await Ref.collection('asssigns').delete();
        await Ref.collection('followers').delete();
        await Ref.collection('comments').delete();
        console.log("Firebase: task has been deleted successfully!");
        return task;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function addFollower(task, follower) {
    try {
        const followersRef = db.collection("tasks/" + task.id + "/followers");
        const user = await userService.getById(task.added_by);
        let docFollowerRef = await followersRef.add(follower);
        follower.id = docFollowerRef.id;
        followersRef.doc(follower.id).update(follower);
        const content = '<b>' + user.fullname + '-' + user.username + '</b> đã thêm bạn vào Theo Dõi công việc <b>' + task.title + '</b>';
        let _notification = {
            content: content,
            added_time: new Date(),
            object: 'tasks',
            object_id: task.id,
            object_link: 'tasks/view/' + task.id,
            user_id: follower.value,
        }
        notificationService.add(_notification);
        console.log("Firebase: add follower successfully!");
        return task;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error: ", error);
    }
}

async function removeFollower(task, follower) {
    try {
        if (helps.isEmpty(follower.id)) {
            const snapshot = await db.collection("tasks/" + task.id + "/followers")
                .where('value', '==', follower.value)
                .get();
            const followers = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            const _follower = followers[0];
            follower['id'] = _follower.id;
        }
        const followersRef = db.collection("tasks/" + task.id + "/followers").doc(follower.id);
        console.log(follower)
        await followersRef.delete();
        console.log("Firebase: remove follower successfully!");
        return task;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error: ", error);
    }
}

async function addAssign(task, assign) {
    try {
        const Ref = db.collection("tasks/" + task.id + "/assigns");
        const existing_snapshot = await Ref.where('value', '==', assign.value).get();
        if (existing_snapshot.empty) {
            const user = await userService.getById(task.added_by);
            let docRef = await Ref.add(assign);
            assign.id = docRef.id;
            Ref.doc(assign.id).update(assign);
            const content = '<b>' + user.fullname + '-' + user.username + '</b> đã giao cho bạn công việc <b>' + task.title + '</b>';
            let _notification = {
                content: content,
                added_time: new Date(),
                object: 'tasks',
                object_id: task.id,
                object_link: 'tasks/view/' + task.id,
                user_id: assign.value,
            }
            notificationService.add(_notification);
            console.log("Firebase: add assign successfully!");
        }
        return task;
    } catch (error) {
        console.error("Error: ", error);
    }
}

async function removeAssign(task, assign) {
    try {
        if (helps.isEmpty(assign.id)) {
            const snapshot = await db.collection("tasks/" + task.id + "/assigns")
                .where('value', '==', assign.value)
                .get();
            const assigns = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            const _assign = assigns[0];
            assign['id'] = _assign.id;
        }
        const Ref = db.collection("tasks/" + task.id + "/assigns").doc(assign.id);
        await Ref.delete();
        console.log("Firebase: remove assign successfully!");
        return task;
    } catch (error) {
        console.error("Error: ", error);
    }
}
// Comments
async function getComments(task, _limit = 1000) {
    try {
        var comments = [];
        db.collection("tasks/" + task.id + "/comments").orderBy('added_time', 'desc')
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach(async (doc) => {
                    let comment = doc.data();
                    comment['added_time'] = comment['added_time'].toDate();
                    if (comment.added_by) {
                        let added_by = await userService.getById(comment.added_by);
                        comment['added_by_alias'] = added_by.fullname + '-' + added_by.username;
                    }
                    comments.push(comment);
                })
            });
        comments.sort((a, b) => b.added_time - a.added_time);
        return comments;
    } catch (error) {
        return error;
    }
}

async function addComment(task, comment) {
    var upload_files = comment.attachment;
    comment['attachment'] = '';
    const Ref = db.collection("tasks/" + task.id + "/comments");
    try {
        let docRef = await Ref.add(comment);
        comment.id = docRef.id;
        if (!helps.isEmpty(upload_files) && upload_files instanceof FileList) {
            var _files = [];
            var _attachment = [];
            _files = await fileService.uploadFile(upload_files, 'tasks/' + task.id + '/comments', comment.id);

            if (!helps.isEmpty(_files) && _files.length > 0) {
                _files.map((file) => {
                    let _file = {
                        name: file.name,
                        url: file.url,
                    }
                    _attachment.push(_file);
                })
            }
            comment['attachment'] = _attachment;
        }
        Ref.doc(comment.id).update(comment);
        console.log("Firebase: add comment successfully!");
        if (comment.added_by) {
            let added_by = await userService.getById(comment.added_by);
            comment['added_by_alias'] = added_by.fullname + '-' + added_by.username;
        }
        return comment;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error: ", error);
    }
}

async function updateComment(task, comment) {
    try {
        var upload_files = comment.attachment;
        const Ref = db.collection("tasks/" + task.id + "/comments");
        if (!helps.isEmpty(upload_files) && upload_files instanceof FileList) {
            // Xoá các file cũ đi
            var storageRef = storage.ref('tasks').child(task.id).child('comments').child(comment.id);
            storageRef.listAll().then(function (result) {
                result.items.forEach(function (file) {
                    file.delete();
                });
            }).catch(function (error) {
                // Handle any errors
            });

            // upload files mới lên
            var _files = [];
            var _attachment = [];
            _files = await fileService.uploadFile(upload_files, 'tasks/' + task.id + '/comments', comment.id);

            if (!helps.isEmpty(_files) && _files.length > 0) {
                _files.map((file) => {
                    let _file = {
                        name: file.name,
                        url: file.url,
                    }
                    _attachment.push(_file);
                })
            }
            comment['attachment'] = _attachment;
        }
        Ref.doc(comment.id).update(comment);
        console.log("Firebase: update comment successfully!");
        return comment;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error: ", error);
    }
}

async function deleteComment(task, comment) {
    if (helps.isEmpty(task)) return;
    if (helps.isEmpty(comment)) return;

    try {
        const Ref = db.collection("tasks/" + task.id + "/comments").doc(comment.id);
        if (!helps.isEmpty(comment.attachment) && comment.attachment.length > 0) {
            Promise.all(
                comment.attachment.map(async (file) => {
                    file['object'] = 'tasks/' + task.id + '/comments';
                    file['object_id'] = comment.id;
                    let del_file = await fileService.deleteFile(file);
                    console.log(del_file);
                })
            );
        }
        await Ref.delete();
        console.log("Firebase: comment has been deleted successfully!");
        return comment;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function deleteCollection(path) {
    await db.collection(path).get().then(val => {
        var chunks = []
        for (let i = 0; i < val.length; i += 500) {
            chunks.push(val.slice(i, i + 500))
        }

        for (var chunk of chunks) {
            // Get a new write batch
            var batch = db.batch()
            chunk.map((document) => {
                batch.delete(document)
            })
            batch.commit();
            console.log("Firebase: deleted: " + path + "/" + document.id)
        }
    })
}