import { candidateConstants } from "src/_constants";

export function candidateReducer(state = {}, actions) {
    switch (actions.type) {

        // get all candidate
        case candidateConstants.GETALL_REQUEST:
            return {
                loading: true
            }
        case candidateConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                candidates: actions.candidates
            }
        case candidateConstants.GETALL_FAILURE:
            return {
                ...state,
                error: actions.error
            }

        // get a candidate by ID    
        case candidateConstants.GET_REQUEST:
            return {
                loading: true
            }
        case candidateConstants.GET_SUCCESS:
            return {
                ...state,
                candidate: actions.candidate,
                loading: false
            }
        case candidateConstants.GET_FAILURE:
            return {
                error: actions.error
            };
        // add new candidate    
        case candidateConstants.ADD_REQUEST:
            return {
                loading: true
            }
        case candidateConstants.ADD_SUCCESS:
            state.candidates.push(actions.candidate);
            state.candidates.sort((a, b) => b.added_time - a.added_time);
            return {
                candidates: actions.candidates,
                loading: false
            }
        case candidateConstants.ADD_FAILURE:
            return {
                error: actions.error
            };
        // update candidate    
        case candidateConstants.UPDATE_REQUEST:
            return {
                ...state,
                candidate: actions.candidate,
                loading: true
            }
        case candidateConstants.UPDATE_SUCCESS:
            var _candidates = state.candidates;
            var foundIndex = _candidates.findIndex(item => item.id === actions.candidate.id);
            _candidates[foundIndex] = actions.candidate;
            _candidates.sort((a, b) => b.joint_date - a.joint_date);
            //console.log(foundIndex);
            return {
                ...state,
                loading: false,
                candidates: _candidates,
                candidate: actions.candidate,
            }
        case candidateConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // Delete 
        case candidateConstants.DELETE_REQUEST:
            var candidates = state.candidates;
            candidates = candidates.map(candidate =>
                candidate.id === actions.candidate.id
                    ? { ...candidate, deleting: true }
                    : candidate
            );
            return {
                ...state,
                candidate: actions.candidate,
                candidates: candidates
            };
        case candidateConstants.DELETE_SUCCESS:
            var candidates = state.candidates;
            candidates = candidates.filter(candidate => candidate.id !== actions.candidate.id);
            return {
                ...state,
                candidate: actions.candidate,
                candidates: candidates
            };
        case candidateConstants.DELETE_FAILURE:
            var candidates = state.candidates;
            candidates = candidates.map(candidate => {
                if (candidate.id === actions.candidate.id) {
                    const { deleting, ...candidateCopy } = candidate;
                    return { ...candidateCopy, deleteError: actions.error };
                }
                return candidate;
            });
            return {
                ...state,
                candidates: candidates
            };
        default:
            return state;
    }
}