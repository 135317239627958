import { assetConstants } from "src/_constants";
import { asset_itemConstants } from "src/_constants";

export function assetReducer(state = { assets: [], asset_items: [] }, actions) {
    switch (actions.type) {

        // get all asset
        case assetConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case assetConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                assets: actions.assets,
            }
        case assetConstants.GETALL_FAILURE:
            return {
                ...state,
                error: actions.error
            }

        // get a asset by ID    
        case assetConstants.GET_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assetConstants.GET_SUCCESS:
            return {
                ...state,
                asset: actions.asset,
                loading: false
            }
        case assetConstants.GET_FAILURE:
            return {
                error: actions.error
            };
        // add new asset    
        case assetConstants.ADD_REQUEST:
            return {
                loading: true
            }
        case assetConstants.ADD_SUCCESS:
            var assets = state.assets ? state.assets : [];
            //assets.push(actions.asset);
            return {
                ...state,
                assets: assets,
                loading: false
            }
        case assetConstants.ADD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update asset    
        case assetConstants.UPDATE_REQUEST:
            return {
                ...state,
                //loading: true
            }
        case assetConstants.UPDATE_SUCCESS:
            var _assets = state.assets;
            var foundIndex = _assets.findIndex(asset => asset.id === actions.asset.id);
            actions.asset['index'] = foundIndex;
            _assets[foundIndex] = actions.asset;
            console.log(foundIndex);
            return {
                ...state,
                //loading: false,
                assets: _assets,
                asset: actions.asset,
            }
        case assetConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update asset field    
        case assetConstants.UPDATE_FIELD_REQUEST:
            return {
                ...state,
                loading: false
            }
        case assetConstants.UPDATE_FIELD_SUCCESS:
            var _assets = state.assets;
            var foundIndex = _assets.findIndex(asset => asset.id === actions.asset.id);
            actions.asset['index'] = foundIndex;
            _assets[foundIndex] = actions.asset;
            console.log(foundIndex);
            return {
                ...state,
                loading: false,
                assets: _assets,
                asset: actions.asset,
            }
        case assetConstants.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // upload asset file    
        case assetConstants.UPLOAD_FILE_REQUEST:
            return {
                ...state,
                loading: false
            }
        case assetConstants.UPLOAD_FILE_SUCCESS:
            var _assets = state.assets;
            var foundIndex = _assets.findIndex(asset => asset.id === actions.asset.id);
            actions.asset['index'] = foundIndex;
            _assets[foundIndex] = actions.asset;
            console.log(foundIndex);
            return {
                ...state,
                loading: false,
                assets: _assets,
                asset: actions.asset,
            }
        case assetConstants.UPLOAD_FILE_FAILURE:
            return {
                ...state,
                error: actions.error
            };

        // get all asset_item
        case asset_itemConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case asset_itemConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                asset_items: actions.asset_items,
            }
        case asset_itemConstants.GETALL_FAILURE:
            return {
                ...state,
                error: actions.error
            }

        // get a asset_item by ID    
        case asset_itemConstants.GET_REQUEST:
            return {
                ...state,
                loading: true
            }
        case asset_itemConstants.GET_SUCCESS:
            return {
                ...state,
                asset_items: actions.asset_items,
                loading: false
            }
        case asset_itemConstants.GET_FAILURE:
            return {
                error: actions.error
            };
        // add new asset_item
        case asset_itemConstants.ADD_REQUEST:
            return {

                loading: true
            }
        case asset_itemConstants.ADD_SUCCESS:
            var asset_items = state.asset_items ? state.asset_items : [];
            return {
                ...state,
                asset_items: asset_items,
                loading: false
            }
        case asset_itemConstants.ADD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update asset_item
        case assetConstants.UPDATE_REQUEST:
            return {
                ...state,
                //loading: true
            }
        case asset_itemConstants.UPDATE_SUCCESS:
            var _asset_items = state.asset_items;
            var foundIndex = _asset_items.findIndex(item => item.id === actions.asset_item.id);
            actions.asset_item['index'] = foundIndex;
            _asset_items[foundIndex] = actions.asset_item;
            console.log(foundIndex);
            return {
                ...state,
                //loading: false,
                asset_items: _asset_items,
                asset_item: actions.asset_item,
            }
        case asset_itemConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update asset_item field
        case asset_itemConstants.UPDATE_FIELD_REQUEST:
            return {
                ...state,
                loading: false
            }
        case asset_itemConstants.UPDATE_FIELD_SUCCESS:
            var _asset_items = state.asset_items;
            var foundIndex = _asset_items.findIndex(item => item.id === actions.asset_item.id);
            actions.asset_item['index'] = foundIndex;
            _assets[foundIndex] = actions.asset_item;
            console.log(foundIndex);
            return {
                ...state,
                loading: false,
                asset_items: _asset_items,
                asset_item: actions.asset_item,
            }
        case assetConstants.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                error: actions.error
            };


        default:
            return state;
    }
}