import { helps } from 'src/_helpers';
import { storage } from '../dbConfigs';
import "firebase/storage";


export const fileService = {
    getAll,
    deleteFile,
    uploadFile,
};

async function getAll(_object, _object_id) {
    console.log(_object, _object_id);
    if (helps.isEmpty(_object)) return;
    if (helps.isEmpty(_object_id)) return;
    var _files = [];
    await storage.ref(_object).child(_object_id).listAll().then((res) => {
        _files = res.items;
    }).catch((error) => {
        console.log(error);
    });
    if (_files && _files.length > 0) {
        await Promise.all(
            _files.map(async (file) => {
                file['ext'] = helps.getExt(file.name);
                file['object'] = _object;
                file['object_id'] = _object_id;
                file['url'] = await file.getDownloadURL();
                file['_metadata'] = await file.getMetadata();
            })
        );
    }
    return _files;
}

async function uploadFile(files, object, object_id, user) {
    console.log(files);
    try {
        var _files = [];
        let keys = [];
        if (typeof files !== 'undefined' && files !== null) {
            keys = Object.keys(files);
        }
        await Promise.all(
            keys.map(async (_key) => {
                let file = files[_key];
                //console.log(file);
                var newMetadata = {
                    customMetadata: {
                        'user_id': (user) ? user.id : ''
                    }
                }
                await storage.ref(`/${object}/${object_id}/${file.name}`).put(file, newMetadata).then(async (snapshot) => {
                    //console.log(snapshot);
                    let _file = snapshot.ref;

                    await _file.getDownloadURL().then((url) => {
                        _file['url'] = url;
                    }).catch((error) => {
                        console.error(error);
                    });

                    await _file.getMetadata().then((metadata) => {
                        _file['_metadata'] = metadata;
                    });

                    _file['ext'] = helps.getExt(_file.name);
                    _file['object'] = object;
                    _file['object_id'] = object_id;
                    _files.push(_file);
                });
            })
        );
        return _files;
    } catch (error) {
        console.error("Error uploading files: ", error);
    }
}

async function deleteFile(file) {
    const Ref = storage.ref(file['object']).child(file['object_id']).child(file.name);
    try {
        await Ref.delete();
        console.log("Firebase: File has been deleted successfully!");
        return file;
    } catch (error) {
        console.error("Error deleting file: ", error);
    }
}
