import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CFormText,
    CCard,
    CLink,
    CCardBody,
    CCardFooter,
    CInput,
    CSelect,
    CFormGroup,
    CInputRadio,
    CInputCheckbox,
    CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import Editor from 'src/_components/Editor';

class TasksFormComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            id: '',
            object: '',
            object_id: '',
            added_time: new Date(),
            added_by: this.props.user.id,
            assign_by: this.props.user.employee_id,
            content: '',
            title: '',
            deadline: new Date(),
            assigns: [],
            repeat: 'no-repeat',
            priority: 'normal',
            status: 'unfinished',
            assign_status: 'unapproved',
            followers: [],
            attachment: '',
            old_attachment: '',

            is_disable_object: false,
            object_options: [],
            default_object_option: {},
            object_id_placeholder: '...',
            assign_to_options: [],
            default_assign_to_options: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDeadline = this.handleChangeDeadline.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeEditor = this.handleChangeEditor.bind(this);
        this.handleChangeFiles = this.handleChangeFiles.bind(this);
    }

    componentDidMount() {
        const { task, users } = this.props;
        const { object, object_id } = this.props;

        var default_assign_to_options = [];
        this.getObjectOptions();
        //console.log(task);
        if (!helps.isEmpty(task)) {
            this.setState({
                id: task.id,
                title: task.title,
                content: task.content,
                object: task.object,
                object_id: task.object_id,
                added_time: task.added_time,
                added_by: task.added_by,
                deadline: task.deadline,
                assigns: task.assigns,
                assign_by: task.assign_by,
                priority: task.priority,
                followers: task.followers,
                status: task.status,
                assign_status: task.assign_status,
                attachment: task.attachment,
            });
        } else {
            const added_by = this.props.user.id;

            this.setState({
                errors: {},
                added_time: new Date(),
                added_by: added_by,
                object: object,
                object_id: object_id,
                followers: [{ value: added_by, label: this.props.user.fullname + '-' + this.props.user.username }]
            });
        }

        var assign_to_options = [];
        if (!helps.isEmpty(users)) {
            users.map((item) => {
                let option = { value: item.id, label: item.fullname + ' (' + item.employee_id + ')' }
                assign_to_options.push(option);
            });
        }
        this.setState({ assign_to_options: assign_to_options, default_assign_to_options: default_assign_to_options });
    }

    getObjectOptions() {
        const { customers, employees, tasks, projects, object_id, object } = this.props;
        var object_options = [];
        var object_id_placeholder = '...';
        var default_object_option = {};
        var is_disable_object = false;
        switch (object) {
            case 'customers':
                var options = [];
                if (!helps.isEmpty(customers)) {
                    customers.map((item) => {
                        let option = { value: item.id, label: item.name }
                        options.push(option);
                    });
                }
                object_id_placeholder = 'Chọn Khách Hàng ...';
                object_options = options;
                break;
            case 'employees':
                var options = [];
                if (!helps.isEmpty(employees)) {
                    employees.map((item) => {
                        let option = { value: item.id, label: item.fullname + ' (' + item.employee_id + ')' }
                        options.push(option);
                    });
                }
                object_id_placeholder = 'Chọn Nhân Sự ...';
                object_options = options;
                break;
            case 'tasks':
                var options = [];
                if (!helps.isEmpty(tasks)) {
                    tasks.map((item) => {
                        let option = { value: item.id, label: item.title }
                        options.push(option);
                    });
                }
                object_options = options;
                break;
            case 'projects':
                var options = [];
                if (!helps.isEmpty(projects)) {
                    projects.map((item) => {
                        let option = { value: item.id, label: item.name }
                        options.push(option);
                    });
                }
                object_id_placeholder = 'Chọn Mục Tiêu ...';
                object_options = options;
                break;
            default:
                object_options = [];
                object_id_placeholder = '...';

        }
        if (!helps.isEmpty(object_options)) {
            default_object_option = helps.getItemFromArr(object_options, object_id, 'value');
        }
        if (!helps.isEmpty(object_options) && !helps.isEmpty(object_id)) {
            is_disable_object = true;
        }
        //console.log(default_object_option);
        this.setState({
            is_disable_object: is_disable_object,
            object_options: object_options,
            object_id_placeholder: object_id_placeholder,
            default_object_option: default_object_option
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        var item = 'add';
        if (this.handleValidation()) {
            let task = {
                id: this.state.id,
                object: this.state.object,
                object_id: this.state.object_id,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
                assign_by: this.state.assign_by,
                title: this.state.title,
                content: this.state.content,
                assigns: this.state.assigns,
                repeat: this.state.repeat,
                deadline: this.state.deadline,
                priority: this.state.priority,
                followers: this.state.followers,
                status: this.state.status,
                assign_status: this.state.assign_status,
                attachment: this.state.attachment,
            }
            if (helps.isEmpty(task.id)) {
                //task['followers'] = task.assigns;
                item = 'add';
            } else {
                item = task.id;
            }
            //console.log(task);
            this.props.handleSubmit(task, item);
        } else {
            return false;
        }
    }

    handleChangeDeadline(value) {
        console.log(value);
        this.setState({ 'deadline': value });
    }

    handleChange(field, e) {
        let value = e.target.value;
        this.setState({
            [field]: value
        });
        if (field === 'object') {
            this.getObjectOptions(value);
        }
    }

    handleChangeFiles(field, e) {
        let upload_files = e.target.files;
        this.setState({ [field]: upload_files });
        console.log(upload_files)
    }

    handleChangeEditor(field, value) {
        //console.log(field, value);
        this.setState({
            [field]: value
        });
    }

    handleChangeSelect(selectedOptions, field) {
        if (selectedOptions) {
            this.setState({ [field]: selectedOptions.value });
        }
    }

    handleChangeSelectAssigns(selectedOptions) {
        if (selectedOptions) {
            this.setState({ assigns: selectedOptions });
        }
    }

    handleChangeAssignToMe(e) {
        if (e.target.checked) {
            const { user } = this.props;
            var default_assign_to_options = this.state.assign_to_options.filter(option => option.value == user.id);
            this.setState({ default_assign_to_options: default_assign_to_options, assigns: default_assign_to_options });
            // console.log(default_assign_to_options, user.id);
        } else {
            this.setState({ default_assign_to_options: [], assigns: [] });
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.title)) {
            formIsValid = false;
            errors["title"] = "Vui lòng nhập Tiêu Đề công việc.";
        }

        if (helps.isEmpty(this.state.content)) {
            formIsValid = false;
            errors["content"] = "Vui lòng nhập Nội Dung công việc.";
        }

        if (helps.isEmpty(this.state.assigns)) {
            formIsValid = false;
            errors["assigns"] = "Vui lòng chọn người được giao việc.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCancel(e) {
        e.preventDefault();
        if (helps.isEmpty(this.state.id)) {
            this.props.handleCancelForm();
        } else {
            this.props.toggleEditForms();
        }

    }

    render() {
        //console.log(this.state.default_object_option);
        return (
            <CForm onSubmit={(e) => this.handleSubmit(e)}>
                <CCard>
                    <CCardBody>
                        <CRow>
                            <CCol xl={7} md={7} sm={12}>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-priority" className='mr-3'>Mức Độ Ưu Tiên:</CLabel>
                                        <div>
                                            {configs.task_priorities && configs.task_priorities.map((priority) => {
                                                return (
                                                    <CFormGroup key={priority.id} variant='custom-radio' inline>
                                                        <CInputRadio
                                                            custom
                                                            id={"priority_" + priority.id}
                                                            name="priority"
                                                            value={priority.id}
                                                            checked={this.state.priority === priority.id}
                                                            onChange={(e) => { this.handleChange('priority', e) }}
                                                        />
                                                        <CLabel variant="custom-checkbox" htmlFor={"priority_" + priority.id} className="cursor-pointer">
                                                            <CBadge color={priority.color} className="p-1">{priority.label}</CBadge>
                                                        </CLabel>
                                                    </CFormGroup>
                                                )
                                            })
                                            }
                                            <small className="help-block text-danger">{this.state.errors['priority']}</small>
                                        </div>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-title">Tiêu Đề:</CLabel>
                                        <CInput
                                            name="title"
                                            id="input-title"
                                            defaultValue={this.state.title}
                                            onChange={(e) => { this.handleChange('title', e) }}
                                        />
                                        <small className="help-block text-danger">{this.state.errors['title']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-content">Nội Dung Công Việc:</CLabel>
                                        <Editor
                                            {... this.props}
                                            name="content"
                                            defaultValue={this.state.content}
                                            handleChangeEditor={this.handleChangeEditor}
                                        />
                                        <small className="help-block text-danger">{this.state.errors['content']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-attachment">Tài liệu đính kèm:</CLabel>
                                        {this.state.old_attachment && this.state.old_attachment.length > 0 && this.state.old_attachment.map(file => {
                                            return (
                                                <ul key={'attachment-' + file.name} className='mt-2 mb-3'>
                                                    <li><CLink href={file.url} target='_blank'>{file.name}</CLink></li>
                                                </ul>
                                            )
                                        })}
                                        <CInput
                                            type='file'
                                            name='attachment'
                                            id='input-attachment'
                                            multiple
                                            onChange={(e) => { this.handleChangeFiles('attachment', e) }}
                                        />
                                        <small className="help-block text-danger">{this.state.errors['attachment']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-repeat">Loại Công Việc:</CLabel>
                                        <CFormGroup variant='custom-radio' className={'ml-2'} inline>
                                            <CInputRadio
                                                name='repeat'
                                                value={'no-repeat'}
                                                id="no-repeat"
                                                className={'cursor-pointer'}
                                                checked={this.state.repeat === 'no-repeat'}
                                                onChange={(e) => { this.handleChange('repeat', e) }}
                                            />
                                            <CLabel htmlFor='no-repeat' className={'cursor-pointer'}>Không Lặp Lại</CLabel>
                                        </CFormGroup>
                                        {configs.task_repeats && Object.keys(configs.task_repeats).map((_key) => {
                                            return (
                                                <CFormGroup key={'repeat-key-' + _key} variant='custom-radio' inline>
                                                    <CInputRadio
                                                        name='repeat'
                                                        value={_key}
                                                        id={'repeat-' + _key}
                                                        className={'cursor-pointer'}
                                                        checked={this.state.repeat === _key}
                                                        onChange={(e) => { this.handleChange('repeat', e) }}
                                                    />
                                                    <CLabel htmlFor={'repeat-' + _key} className={'cursor-pointer'}>{configs.task_repeats[_key]}</CLabel>
                                                </CFormGroup>
                                            )
                                        })}
                                        <small className="help-block text-danger">{this.state.errors['repeat']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-deadline">Thời hạn hoàn thành:</CLabel>
                                        <DateTimePicker
                                            name='deadline'
                                            id='input-deadline'
                                            className={'ml-2'}
                                            value={this.state.deadline}
                                            onChange={(value) => { this.handleChangeDeadline(value) }}
                                        />
                                        <small className="help-block text-danger">{this.state.errors['deadline']}</small>
                                        <CFormText className="help-block">Để trống, nếu công việc không cần thời hạn hoàn thành.</CFormText>
                                    </CCol>
                                </CRow>
                            </CCol>
                            <CCol xl={5} md={5} sm={12}>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-object">Công Việc liên quan tới:</CLabel>
                                        <CSelect
                                            name='object'
                                            id='input-object'
                                            value={this.state.object}
                                            disabled={this.state.is_disable_object}
                                            onChange={(e) => { this.handleChange('object', e) }}
                                        >
                                            <option value={''}>-- Chọn Đối Tượng --</option>
                                            {configs.objects && Object.keys(configs.objects).map((_key) => {
                                                return (
                                                    <option key={_key} value={_key}>{configs.objects[_key]}</option>
                                                )
                                            })}
                                        </CSelect>
                                        <small className="help-block text-danger">{this.state.errors['object']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <Select
                                            id="input-object_id"
                                            placeholder={this.state.object_id_placeholder}
                                            isClearable
                                            isDisabled={this.state.is_disable_object}
                                            options={this.state.object_options}
                                            value={this.state.default_object_option}
                                            defaultValue={this.state.default_object_option || this.state.object_id_placeholder}
                                            onChange={(choice) => { this.handleChangeSelect(choice, 'object_id') }}
                                        />
                                        <small className="text-danger">{this.state.errors['object_id']}</small>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-assign_to">Giao việc cho:</CLabel>
                                        <CFormGroup
                                            variant='custom-checkbox'
                                            inline
                                        >
                                            <CInputCheckbox
                                                id="input-assign_to_me"
                                                name="assign_to_me"
                                                className='cursor-pointer'
                                                onChange={(e) => { this.handleChangeAssignToMe(e) }}
                                            />
                                            <CLabel htmlFor="input-assign_to_me" className='cursor-pointer'>cho tôi</CLabel>
                                        </CFormGroup>
                                        <Select
                                            id="input-assigns"
                                            placeholder='Nhân viên ...'
                                            isClearable
                                            isMulti
                                            options={this.state.assign_to_options}
                                            defaultValue={this.state.default_assign_to_options}
                                            value={this.state.assigns}
                                            onChange={(choice) => { this.handleChangeSelectAssigns(choice) }}
                                        />
                                        <small className="text-danger">{this.state.errors['assigns']}</small>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CButton type="submit" color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                                <CButton type="button" color="secondary" onClick={(e) => { this.props.handleCancel(e) }} className="ml-3">
                                    <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                    Huỷ
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardFooter>
                </CCard >
            </CForm>
        )
    }
}
export default TasksFormComponent;

