import React, { Component } from 'react'
import {
    CCallout,
    CCol,
    CRow,
    CTooltip,
    CButton,
    CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';
import TaskStatus from './TaskStatus';
import TaskFollowStatus from './TaskFollowStatus';
import TaskFollowers from './TaskFollowers';
import TaskComments from './TaskComments';

class Task extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    componentDidMount() {

    }

    render() {
        const { task, user } = this.props;
        const now = new Date();
        var added_time = '';
        var deadline = '';
        var over_deadline = '';
        if (task.added_time) {
            added_time = helps.formatDateTime_from_Timestamp(task.added_time);
        }
        if (task.deadline) {
            deadline = helps.formatDateTime_from_Timestamp(task.deadline);
            if (task.status === 'unfinished' && now > task.deadline) {
                over_deadline = helps.secondsToDhm((now - task.deadline) / 1000);
            }
        }
        var is_author = false;
        if (user.id == task.added_by) {
            is_author = true;
        } else {
            if (user.role === 'administrator') {
                is_author = true;
            }
        }
        //console.log(task.object, customers);
        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol md={8} sm={12}>
                            {task.object &&
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <h5><b>{configs.objects[task.object]}</b>: {task.object_name}</h5>
                                    </CCol>
                                </CRow>
                            }
                            <CRow className="mb-3">
                                <CCol md={12}>
                                    <h5><b>Nội Dung: </b></h5>
                                    <div dangerouslySetInnerHTML={{ __html: task.content }} />
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={12}>
                                    {task.attachment && task.attachment.length > 0 && task.attachment.map(file => {
                                        return (
                                            <div>
                                                <small><CIcon name='cil-file' size='sm'></CIcon> <CLink href={file.url} target='_blank'>{file.name}</CLink></small>
                                            </div>
                                        )
                                    })}
                                </CCol>
                            </CRow>
                            <hr />
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Giao bởi: <b>{task.added_by_alias}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Ngày giao: <b>{added_time}</b>
                                </CCol>
                            </CRow>
                            <hr />
                            <TaskComments {... this.props} task={task} />
                        </CCol>
                        <CCol md={4} sm={12}>
                            <CCallout color='primary'>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <h5><b>Trạng Thái: </b></h5>
                                        <TaskStatus {... this.props} task={task} />
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <h5><b>Giao Cho: </b></h5>
                                        {!helps.isEmpty(task.assigns) && task.assigns.length > 0 && task.assigns.map(assign => {
                                            return (
                                                <div key={assign.value}>
                                                    {assign.label}
                                                </div>
                                            );
                                        })}
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <h5><b>Thời Hạn hoàn thành: </b></h5>
                                        <p>{deadline && <b>{deadline}</b>} {!helps.isEmpty(over_deadline) && <span className='text-danger'>(quá hạn: {over_deadline})</span>}</p>
                                    </CCol>
                                </CRow>
                                <hr />
                                <CRow className="mb-3">
                                    <CCol md={3}>
                                        <CTooltip content={"Chỉnh Sửa:" + task.title}>
                                            <CButton
                                                color={is_author ? "primary" : "secondary"}
                                                disabled={!is_author}
                                                size="sm"
                                                onClick={() => { this.props.history.push(`/tasks/add_edit/${task.id}`) }}
                                            >
                                                <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                Chỉnh Sửa
                                            </CButton>
                                        </CTooltip>
                                    </CCol>
                                    <CCol md={3}>
                                        <TaskFollowStatus {... this.props} task={task} />
                                    </CCol>
                                </CRow>
                                <hr />
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <TaskFollowers {... this.props} task={task} is_author={is_author} />
                                    </CCol>
                                </CRow>
                            </CCallout>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        )
    }
}
export default Task;
