export const bookConstants = {
    GET_LIST_REQUEST: 'ACCOUNTING_BOOKS_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'ACCOUNTING_BOOKS_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'ACCOUNTING_BOOKS_GET_LIST_FAILURE',

    GET_REQUEST: 'ACCOUNTING_BOOKS_GET_REQUEST',
    GET_SUCCESS: 'ACCOUNTING_BOOKS_GET_SUCCESS',
    GET_FAILURE: 'ACCOUNTING_BOOKS_GET_FAILURE',

    ADD_REQUEST: 'ACCOUNTING_BOOKS_ADD_REQUEST',
    ADD_SUCCESS: 'ACCOUNTING_BOOKS_ADD_SUCCESS',
    ADD_FAILURE: 'ACCOUNTING_BOOKS_ADD_FAILURE',

    EDIT_REQUEST: 'ACCOUNTING_BOOKS_EDIT_REQUEST',
    EDIT_SUCCESS: 'ACCOUNTING_BOOKS_EDIT_SUCCESS',
    EDIT_FAILURE: 'ACCOUNTING_BOOKS_EDIT_FAILURE',

    UPDATE_FIELD_REQUEST: 'ACCOUNTING_BOOKS_UPDATE_FIELD_REQUEST',
    UPDATE_FIELD_SUCCESS: 'ACCOUNTING_BOOKS_UPDATE_FIELD_SUCCESS',
    UPDATE_FIELD_FAILURE: 'ACCOUNTING_BOOKS_UPDATE_FIELD_FAILURE',

    DELETE_REQUEST: 'ACCOUNTING_BOOKS_DELETE_REQUEST',
    DELETE_SUCCESS: 'ACCOUNTING_BOOKS_DELETE_SUCCESS',
    DELETE_FAILURE: 'ACCOUNTING_BOOKS_DELETE_FAILURE',
}