import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
    CForm,
    CInputCheckbox,

} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';
import Loading from 'src/containers/_loading';

class RolesForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            _header: "User Roles",
            isChecked: [],
            counter: [],
            roles: [],
            role_objects: configs.role_objects,
            role_actions: configs.role_actions,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckAll = this.handleCheckAll.bind(this);
    }

    componentDidMount() {
        const roles = this.props.roles;
        this.setState({ roles: roles });


    }

    handleSubmit(e) {
        e.preventDefault();
        const roles = this.state.roles;
        this.props.updateRoles(roles);
        this.props.history.push('/settings');
    }

    handleCheckAll(e, _role, _action) {
        const { roles, role_objects, role_actions } = this.state;

        if (!roles || !role_objects || !role_actions) return;

        let isChecked = false
        if (e.target.checked) {
            isChecked = true;
        }
        role_objects.forEach((_object_name, _object_key) => {
            let _id = _role + "_" + _action + "_" + _object_key;
            document.getElementById(_id).checked = isChecked;

            let _new_roles = this._getUpdatedRoles(isChecked, _role, _object_name, _action);
            this.setState({ roles: _new_roles });

            this._verifyAction(isChecked, _role, _object_name, _action, _object_key);

        });
    }

    handleChange(e, _target_role, _target_object, _target_action, _ob_key) {
        let _new_roles = this._getUpdatedRoles(e.target.checked, _target_role, _target_object, _target_action);
        this.setState({ roles: _new_roles });

        if (_target_action === "view") {
            let { isChecked, counter } = this.state;
            isChecked[_ob_key] = e.target.checked;
            counter[_ob_key] = 0;
            this.setState({ isChecked: isChecked, counter: counter });
        }

        this._verifyAction(e.target.checked, _target_role, _target_object, _target_action, _ob_key);

        if (e.target.checked) e.target.checked = true;
        else e.target.checked = false;

        console.log(this.state.roles)
    }

    _getUpdatedRoles(_isChecked, _target_role, _target_object, _target_action) {
        let { roles } = this.state;
        let _new_roles = {};
        roles.forEach((_role, _key) => {
            let _new_role_actions = [];
            let _new_object = {};

            if (_role.id === _target_role) {
                _new_role_actions = _role[_target_object] || [];

                if (_isChecked) {
                    if (!_new_role_actions.includes(_target_action))
                        // push specified Action name
                        _new_role_actions.push(_target_action);
                } else {
                    if (_new_role_actions) {
                        for (var i = 0; i < _new_role_actions.length; i++) {
                            if (_new_role_actions[i] === _target_action) {
                                // remove specified Action name
                                _new_role_actions.splice(i, 1);
                            }
                        }
                    }
                }
                _new_object = { [_target_object]: _new_role_actions };

                _new_roles = Object.assign({}, _role, _new_object);
                roles[_key] = _new_roles;
            }
        });
        return roles;
    }

    _verifyAction(_target_checked, _target_role, _target_object, _target_action, _ob_key) {
        const actions = this.state.role_actions;
        const _defaultAction = "view";
        const _defaultActionId = _target_role + "_" + _defaultAction + "_" + _ob_key;
        const _defaultActionEl = document.getElementById(_defaultActionId);
        const isDefautActionChecked = _defaultActionEl.checked;

        let counter = this.state.counter;
        let isChecked = this.state.isChecked;
        if (counter[_ob_key] === 0) {
            isChecked[_ob_key] = isDefautActionChecked;
            this.setState({ isChecked: isChecked });
        }

        if (_target_action !== _defaultAction) {

            if (_target_checked) {
                if (!isDefautActionChecked) {
                    document.getElementById(_defaultActionId).checked = true;
                    this._getUpdatedRoles(true, _target_role, _target_object, _defaultAction);
                    counter[_ob_key] += 1;
                }

            } else {
                let _cntOtherActions = 0;
                for (let i = 0; i < actions.length; i++) {
                    if (document.getElementById(_target_role + "_" + actions[i] + "_" + _ob_key).checked && actions[i] !== _defaultAction) {
                        _cntOtherActions += 1;
                    }
                }

                if (!this.state.isChecked[_ob_key] && _cntOtherActions === 0) {
                    document.getElementById(_defaultActionId).checked = false;
                    this._getUpdatedRoles(false, _target_role, _target_object, _defaultAction);
                }
            }
        } else {
            if (!_target_checked && _target_action === _defaultAction) {
                for (var i = 0; i < actions.length; i++) {
                    if (actions[i] !== _defaultAction) {
                        let _id = _target_role + "_" + actions[i] + "_" + _ob_key;
                        document.getElementById(_id).checked = false;
                        this._getUpdatedRoles(false, _target_role, _target_object, actions[i]);
                    }
                }
            }
        }
        this.setState({ counter: counter });
    }

    _verifyCheckAll() {
        const { role_actions, role_objects } = this.state;
        var roles = this.props.roles;
        roles.forEach((role) => {
            role_actions.forEach((action) => {
                let counter = 0;
                role_objects.forEach((object, ob_key) => {
                    let _id = role.id + "_" + action + "_" + ob_key;
                    if (document.getElementById(_id).checked) {
                        counter += 1;
                    }
                });
                let id_all = "all_" + role.id + "_" + action;
                if (counter >= role_objects.length) {
                    document.getElementById(id_all).checked = true;
                } else {
                    document.getElementById(id_all).checked = false;
                }
            });

        });
    }

    render() {
        const { roles } = this.props;
        if (helps.isEmpty(roles) || roles.length === 0) {
            return (<Loading></Loading>);
        }
        const { role_objects, role_actions } = this.state;
        //this._verifyCheckAll();
        var config_roles = [];
        Object.keys(configs.role_groups).forEach((_key) => {
            config_roles.push({ id: _key });
        });
        const compair = config_roles.filter(({ id: id1 }) => !roles.some(({ id: id2 }) => id2 === id1));
        var _roles = roles.concat(compair);

        return (
            <CForm onSubmit={(e) => this.handleSubmit(e)}>
                <CRow>
                    {_roles && _roles.map((role) => {
                        return (
                            <CCol md={6} xs="12">
                                <CCard>
                                    <CCardHeader>
                                        <h5>{configs.role_groups[role.id]}</h5>
                                    </CCardHeader>
                                    <CCardBody>
                                        <table className="table settings-roles-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#Components</th>
                                                    {role_actions && role_actions.map((_action_name, _ac_key) => {
                                                        let cball = "all_" + role.id + "_" + _action_name;
                                                        return (
                                                            <th key={"_ac_th_key" + _ac_key} scope="col" className="text-center">
                                                                <div className="clearFix">{_action_name}</div>
                                                                <CInputCheckbox
                                                                    name={role.id + "_" + _action_name + "all"}
                                                                    id={cball}
                                                                    value='all'
                                                                    title="All"
                                                                    style={{ marginLeft: "-0.35rem" }}
                                                                    onChange={(e) => { this.handleCheckAll(e, role.id, _action_name) }}
                                                                />
                                                            </th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            {role_objects && role_objects.map((_object, _ob_key) => {
                                                return (
                                                    <tr key={'_ob_tr_key' + _ob_key}>
                                                        <th scope="row">{_object}</th>
                                                        {role_actions && role_actions.map((_action, _ac_key) => {
                                                            let _loadChecked = false;
                                                            let cb_name = (role.id + "_" + _action).toString();
                                                            let cb_id = cb_name + "_" + _ob_key;

                                                            if (role[_object] && role[_object].includes(_action))
                                                                _loadChecked = true;

                                                            return (
                                                                <td className="text-center">
                                                                    <CInputCheckbox
                                                                        name={cb_name + "[]"}
                                                                        id={cb_id}
                                                                        value={_object}
                                                                        checked={_loadChecked}
                                                                        style={{ marginLeft: "-0.35rem" }}
                                                                        onChange={(e) => { this.handleChange(e, role.id, _object, _action, _ob_key) }}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                );
                                            })

                                            }
                                        </table>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        );
                    })

                    }
                    <CCol xl={12}>
                        <CButton type="submit" color="primary">
                            <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                            Save
                        </CButton>
                    </CCol>
                </CRow >
            </CForm>

        )
    }
}
export default RolesForm;

