import { Component } from 'react';
import { connect } from 'react-redux';
import { CAlert, CContainer } from '@coreui/react';
import { generalActions, alertActions, userActions } from '../_actions'
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';


class PermissionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permission: false,
        }
    }

    getPermission() {
        const { component, action } = this.props;
        const user = JSON.parse(localStorage.getItem('user'));
        let _permission = true;


        if (!user.id || !user.role) {
            this.props.logout();
        }

        let roles = this.props.roles;

        if (roles) {
            let _getRole = [];
            roles.forEach((role) => {

                if (role.id === user.role) {
                    _getRole = role;
                }
            });
            if (!_getRole || _getRole.length === 0) _permission = false;
            else {
                let role_component = _getRole[component];
                if (role_component && role_component.length === 0) {
                    _permission = false;
                } else {
                    if (!role_component || !role_component.includes(action)) _permission = false;
                }

            }
        }
        //console.log(roles, user);
        return _permission;
    }

    render() {
        const _permission = this.getPermission();

        //console.log(_permission);

        if (!_permission) {
            const { component, alertOut } = this.props;
            const entries = Object.entries(configs.objects);
            console.log(configs.objects[component], component);
            if (alertOut) {
                return (
                    <CContainer className='mt-3'>
                        <CAlert color="danger">
                            <CIcon name="cil-lock-locked" className="mfe-1 mt-0" />
                            Bạn không có quyền truy cập vào phần: <b>{component}</b>
                        </CAlert>
                    </CContainer>
                );
            }
            return (<></>);
        }
        return (this.props.children);
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { roles } = state.rolesReducer;
    return { alert, roles };
}

const actionCreators = {
    getAllRoles: generalActions.getAllRoles,
    logout: userActions.logout,
    sendAlert: alertActions.error,
};

export const ThePermission = connect(mapStateToProps, actionCreators)(PermissionComponent);