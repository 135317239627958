import { db } from '../dbConfigs';


export const generalService = {
    toggleSidebar,
    getAllRoles,
    getAllRolesFromFirebase,
    updateRoles,

    getApprovals,
    addApproval,
    updateApproval,
}

function toggleSidebar(sidebarShow) {
    let settings = JSON.parse(localStorage.getItem('settings'));
    console.log(sidebarShow);
    if (sidebarShow === false) sidebarShow = 'responsive';
    else sidebarShow = false;
    settings = Object.assign({ general: { sidebarShow: sidebarShow } });
    localStorage.setItem('settings', JSON.stringify(settings));
    return sidebarShow;
}

async function getAllRoles(isForced = true) {
    /*
    let roles = JSON.parse(localStorage.getItem('roles'));
    if (isForced && roles) {
        return roles;
    } else {
        roles = await getAllRolesFromFirebase();
        localStorage.setItem('roles', JSON.stringify(roles));
    }
    */
    let roles = await getAllRolesFromFirebase();
    return roles;
}

async function getAllRolesFromFirebase() {
    const snapshot = await db.collection('roles').get();
    const roles = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));
    return roles;
}

async function updateRoles(roles) {
    try {
        if (roles) {
            console.log(roles);
            roles.forEach(role => {
                let new_role = role;
                //delete new_role.id;

                updateRole(role.id, new_role);

            });
        }

        return getAllRoles();
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Firebase:Error updating document: ", error);
    }
}

async function updateRole(id, role) {
    const dbRef = db.collection("roles").doc(id);

    try {
        await dbRef.update(role);
        console.log("Firebase: Role: " + id + " updated !");
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function getApprovals() {
    try {
        const snapshot = await db.collection('approvals').get();
        const approvals = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        return approvals;
    } catch (error) {
        console.error("Firebase: Error getting Approvals: ", error);
    }
}

async function addApproval(approval) {
    try {
        const docRef = await db.collection("approvals").add(approval);
        approval.id = docRef.id;
        updateApproval(approval);
        console.log("Firebase: Approval has been added successfully!");
        return approval;
    } catch (error) {
        console.error("Error adding Approval: ", error);
    }
}

async function updateApproval(approval) {
    const dbRef = db.collection("approvals").doc(approval.id);
    try {
        await dbRef.update(approval);
        console.log("Firebase: Approval: " + approval.id + " updated !");
        return approval;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating Approval: ", error);
    }
}