import { maneuverConstants } from "src/_constants";
import { maneuverService } from "src/_services/maneuver.service";
import { alertActions } from ".";

export const maneuverActions = {
    getAll,
    add,
    update,
    _delete,
}

function getAll(param, limit = 1000) {
    return dispatch => {
        dispatch(request(param, limit));
        return maneuverService.getAll(param, limit)
            .then(
                maneuvers => {
                    dispatch(success(maneuvers, param, limit));
                    return maneuvers;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(param, limit) { return { type: maneuverConstants.GET_LIST_REQUEST, param: param, limit: limit } }
    function success(maneuvers, param, limit) { return { type: maneuverConstants.GET_LIST_SUCCESS, maneuvers, param: param, limit: limit } }
    function failure(error) { return { type: maneuverConstants.GET_LIST_FAILURE, error } }
}

function add(maneuver) {
    return dispatch => {
        dispatch(request(maneuver));
        return maneuverService.add(maneuver)
            .then(
                maneuver => {
                    dispatch(success(maneuver));
                    dispatch(alertActions.success('Tạo Lệnh Điều Động thành công!'));
                    return maneuver;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(maneuver) { return { type: maneuverConstants.ADD_REQUEST, maneuver: maneuver } }
    function success(maneuver) { return { type: maneuverConstants.ADD_SUCCESS, maneuver: maneuver } }
    function failure(error) { return { type: maneuverConstants.ADD_FAILURE, error } }
}

function update(maneuver) {
    return dispatch => {
        dispatch(request(maneuver));
        maneuverService.update(maneuver)
            .then(
                maneuver => {
                    dispatch(success(maneuver));
                    dispatch(alertActions.success('Chỉnh sửa Lệnh Điều Động thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(maneuver) { return { type: maneuverConstants.EDIT_REQUEST, maneuver: maneuver } }
    function success(maneuver) { return { type: maneuverConstants.EDIT_SUCCESS, maneuver: maneuver } }
    function failure(error) { return { type: maneuverConstants.EDIT_FAILURE, error } }
}

function _delete(maneuver) {
    return dispatch => {
        dispatch(request(maneuver));
        maneuverService._delete(maneuver)
            .then(
                maneuver => {
                    dispatch(success(maneuver));
                    dispatch(alertActions.success('Xoá Lệnh Điều Động thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(maneuver) { return { type: maneuverConstants.DELETE_REQUEST, maneuver: maneuver } }
    function success(maneuver) { return { type: maneuverConstants.DELETE_SUCCESS, maneuver: maneuver } }
    function failure(error) { return { type: maneuverConstants.DELETE_FAILURE, error } }
}