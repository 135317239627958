import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CLink,
    CImg,
    CTooltip,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';

class Documents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            upload_documents: '',
            documents: '',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {

    }

    handleChange(e) {
        let upload_documents = e.target.documents;
        //console.log(upload_documents);
        this.setState({ upload_documents: upload_documents });
    }

    handleUpload() {
        let upload_documents = this.state.upload_documents;
        if (helps.isEmpty(upload_documents)) {
            alert("Vui lòng chọn document để tải lên!");
            return;
        }
        const object = this.props.object;
        const object_id = this.props.object_id;
        this.props.uploaddocument(upload_documents, object, object_id);
    }

    handleDelete(e, document) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Tài liệu: ' + document.name + ' ?');
        if (_confirm) {
            this.props.deletedocument(document);
        }
        return false;
    }

    render() {
        const { documents, folders, path } = this.props;
        const ext = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'svgz', 'heic', 'heif', 'webp', 'tiff', 'tif', 'bmp'];
        var trees = [];
        const paths = path.split("/");
        if (!helps.isEmpty(paths) && paths.length > 0) {
            paths.map((_path, index) => {
                let _cur_paths = paths.slice(0, index + 1);
                let _tree = {
                    name: _path,
                    path: _cur_paths.join('/'),
                }
                trees.push(_tree);
            });
        }

        console.log(trees);
        return (
            <CRow>
                <CCol xl={12}>
                    <CRow className={'mb-2'}>
                        <CCol xl={12} md={12}>
                            <CIcon name="cil-sitemap" size={'lg'} className='mr-2' color='dark'></CIcon>
                            {!helps.isEmpty(trees) && trees.map((_tree, index) => {
                                return (
                                    <span key={_tree.name}>
                                        <CPopover content={_tree.path}>
                                            <CLink onClick={() => { this.props.handleChangePath(_tree.path) }} className="ml-2">{_tree.name}</CLink>
                                        </CPopover>
                                        {index < (trees.length - 1) && <span className='text-muted ml-1'>/</span>}
                                    </span>
                                )
                            })}
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol xl={12} md={12}>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '30%' }}>Tên</th>
                                            <th>Loại</th>
                                            <th>Ext.</th>
                                            <th>Dung lượng</th>
                                            <th>Cập nhật</th>
                                            <th>Download</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.loading && <tr><td colSpan={10}><Loading></Loading></td></tr>}
                                        {!helps.isEmpty(folders) && folders.map((folder) => {
                                            //console.log(_document['_metadata']);
                                            return (
                                                <tr key={'document_' + helps.convertToSlug(folder.name)}>
                                                    <td>
                                                        <CIcon name="cil-folder" size={'lg'}></CIcon>
                                                        <CPopover content={folder.path}>
                                                            <CLink onClick={() => { this.props.handleChangePath(folder.path) }} className="ml-2">{folder.name}</CLink>
                                                        </CPopover>
                                                    </td>
                                                    <td>
                                                        folder
                                                    </td>
                                                    <td>
                                                        .
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        <CButton
                                                            color="secondary"
                                                            size="sm"
                                                            title={'Xoá: ' + folder.name}
                                                            className="ml-1"
                                                            disabled={true}
                                                            onClick={(e) => { this.handleDelete(e, folder) }}
                                                        >
                                                            <CIcon name="cil-trash"></CIcon>
                                                        </CButton>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {!helps.isEmpty(documents) && documents.map((_document) => {
                                            //console.log(_document['_metadata']);
                                            return (
                                                <tr key={'document_' + helps.convertToSlug(_document.name)}>
                                                    <td>
                                                        {ext.includes(_document['ext']) &&
                                                            <CPopover content={<CImg src={_document.url} width={150} height='auto' thumbnail={true} className='mr-1'></CImg>}>
                                                                <CImg src={_document.url} width={20} height={20} thumbnail={true} title={_document.name} className='mr-1'></CImg>
                                                            </CPopover>
                                                        }
                                                        {!ext.includes(_document['ext']) &&
                                                            <>
                                                                <CIcon name="cil-file" size={'lg'} className='mr-1'></CIcon>
                                                                <CLink href={_document.url} target='_blank'>{_document.name}</CLink>
                                                            </>
                                                        }
                                                        {ext.includes(_document['ext']) &&
                                                            <CPopover content={<CImg src={_document.url} width={150} height='auto' thumbnail={true} className='mr-1'></CImg>}>
                                                                <CLink href={_document.url} target='_blank'>{_document.name}</CLink>
                                                            </CPopover>
                                                        }
                                                    </td>
                                                    <td>
                                                        file
                                                    </td>
                                                    <td>
                                                        .{_document.ext}
                                                    </td>
                                                    <td>
                                                        {_document['_metadata'] && <>{helps.formatBytes(_document['_metadata'].size, 2)}</>}
                                                    </td>
                                                    <td>
                                                        {_document['_metadata'] && <>{helps.formatDateTime_from_Timestamp(new Date(_document['_metadata'].updated))}</>}
                                                    </td>
                                                    <td>
                                                        <CTooltip content={'Download: ' + _document.name}>
                                                            <CLink href={_document.url} target='_blank' title={'Download: ' + _document.name}>
                                                                <CIcon name="cil-cloud-download" size={'2xl'}></CIcon>
                                                            </CLink>
                                                        </CTooltip>
                                                    </td>
                                                    <td>
                                                        <CButton
                                                            color="secondary"
                                                            size="sm"
                                                            title={'Xoá: ' + _document.name}
                                                            className="ml-1"
                                                            disabled={true}
                                                            onClick={(e) => { this.handleDelete(e, _document) }}
                                                        >
                                                            <CIcon name="cil-trash"></CIcon>
                                                        </CButton>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {helps.isEmpty(documents) && <div className='text-secondary text-center font-weight-bold m-5'><h4>Chưa có tài liệu nào!</h4></div>}
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        )
    }
}
export default Documents;
