import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CRow, CCol,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import {
    assetActions,

} from 'src/_actions';
import { helps } from 'src/_helpers';

class AssetContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }


    async getUsers() {
        const { users } = this.props;
        if (helps.isEmpty(users)) await this.props.getUsers();
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <ThePermission {... this.props} component="assets" action="view">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol>
                                Quản Lý
                            </CCol>
                            <CCol className='text-right'>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>

                    </CCardBody>
                </CCard>
            </ThePermission>
        );
    }
}

function mapStateToProps(state) {
    const { loading, assets } = state.assetReducer;
    const { users } = state.userReducer;
    return { loading, users, assets };
}

const actionCreators = {
    getAll: assetActions.getAll,

};

const connectedContainer = connect(mapStateToProps, actionCreators)(AssetContainer);
export { connectedContainer as AssetContainer };