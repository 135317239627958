import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CLink,
    CImg,
    CTooltip,
    CInput,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { helps } from '../../_helpers';
import Dropzone from 'react-dropzone';

class Files extends Component {

    constructor(props) {
        super(props);

        this.state = {
            upload_files: '',
            files: '',
        }
        this.onDrop = (files) => {
            this.setState({ upload_files: files });
            console.log(this.state)
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {

    }

    handleChange(e) {
        let upload_files = e.target.files;
        //console.log(upload_files);
        this.setState({ upload_files: upload_files });
    }

    async handleUpload() {
        let upload_files = this.state.upload_files;
        if (helps.isEmpty(upload_files)) {
            alert("Vui lòng chọn file để tải lên!");
            return;
        }

        const { object, object_id, user } = this.props;
        await this.props.uploadFile(upload_files, object, object_id, user);
    }

    handleDelete(e, file) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Tài liệu: ' + file.name + ' ?');
        if (_confirm) {
            this.props.deleteFile(file);
        }
        return false;
    }

    render() {
        const { files, user } = this.props;
        //console.log(user)
        const ext = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'svgz', 'heic', 'heif', 'webp', 'tiff', 'tif', 'psd', 'bmp'];
        const uploading_files = this.state.upload_files;
        const dropzoneStyleActive = {
            borderColor: "#3399ff"
        };
        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol xl={8} md={8}>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "100px" }}></th>
                                            <th>Tên tài liệu</th>
                                            <th>Ext.</th>
                                            <th>Dung lượng</th>
                                            <th>Cập nhật</th>
                                            <th>Download</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.loading && <tr><td colSpan={10}><Loading></Loading></td></tr>}
                                        {!helps.isEmpty(files) && files.map((_file) => {
                                            let _metadata = _file['_metadata'];
                                            let customMetadata = _metadata.customMetadata ? _metadata.customMetadata : {};
                                            let author_id = customMetadata.user_id ? customMetadata.user_id : '';
                                            let right = false;
                                            if (user.role === 'administrator') right = true;
                                            else {
                                                if (author_id === user.id) right = true;
                                            }
                                            return (
                                                <tr key={'file_' + helps.convertToSlug(_file.name)}>
                                                    <td>
                                                        {ext.includes(_file['ext']) &&
                                                            <CPopover content={<CImg src={_file.url} width={150} thumbnail={true}></CImg>}>
                                                                <CImg src={_file.url} width={50} height={50} style={{ maxHeight: '50px' }} thumbnail={true}></CImg>
                                                            </CPopover>
                                                        }
                                                        {!ext.includes(_file['ext']) && <CIcon name="cil-file" size={'3xl'}></CIcon>}
                                                    </td>
                                                    <td className='align-middle'>
                                                        {ext.includes(_file['ext']) &&
                                                            <CPopover content={<CImg src={_file.url} width={150} thumbnail={true}></CImg>}>
                                                                <CLink href={_file.url} title={_file.name} target='_blank'>{_file.name}</CLink>
                                                            </CPopover>
                                                        }
                                                        {!ext.includes(_file['ext']) && <CLink href={_file.url} title={_file.name} target='_blank'>{_file.name}</CLink>}

                                                    </td>
                                                    <td className='align-middle'>
                                                        .{_file.ext}
                                                    </td>
                                                    <td className='align-middle'>
                                                        {_metadata && <>{helps.formatBytes(_metadata.size, 2)}</>}
                                                    </td>
                                                    <td className='align-middle'>
                                                        {_metadata && <>{helps.formatDateTime_from_Timestamp(new Date(_metadata.updated))}</>}
                                                    </td>
                                                    <td>
                                                        <CTooltip content={'Tải về: ' + _file.name}>
                                                            <CLink href={_file.url} target='_blank' title={'Download: ' + _file.name}>
                                                                <CIcon name="cil-cloud-download" size={'2xl'}></CIcon>
                                                            </CLink>
                                                        </CTooltip>
                                                    </td>
                                                    <td>
                                                        <CTooltip content={'Xoá: ' + _file.name}>
                                                            <CButton
                                                                color={!right ? "secondary" : "danger"}
                                                                size="sm"
                                                                title={'Xoá: ' + _file.name}
                                                                className="ml-1"
                                                                disabled={!right}
                                                                onClick={(e) => { this.handleDelete(e, _file) }}
                                                            >
                                                                <CIcon name="cil-trash"></CIcon>
                                                            </CButton>
                                                        </CTooltip>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {helps.isEmpty(files) && <div className='text-secondary text-center font-weight-bold m-5'><h4>Chưa có tài liệu nào!</h4></div>}
                        </CCol>
                        <CCol xl={4} md={4} className='mt-4'>
                            <div>
                                <CLabel htmlFor="input-files"><b>Tải Tài Liệu Lên:</b></CLabel>
                            </div>
                            <Dropzone
                                onDrop={this.onDrop}
                                activeStyle={dropzoneStyleActive}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section className="container">
                                        <div {...getRootProps({ className: 'dropzone' })} style={{ padding: '40px' }}>
                                            <input {...getInputProps()} id='input-files' />
                                            <p>Chọn hoặc kéo thả Files tài liệu tại đây.</p>
                                        </div>
                                        <div className='text-muted mb-3 text-right'><small><i>// Có thể chọn nhiều file tài liệu cho mỗi lần tải lên.</i></small></div>
                                        {!helps.isEmpty(uploading_files) && <aside className='mb-3'>
                                            <h5>Đã chọn: </h5>
                                            <ul>{uploading_files.map((file => {
                                                return (
                                                    <li key={file.name}>
                                                        {file.name} <span className='text-muted font-italic'>- {helps.formatBytes(file.size, 2)}</span>
                                                    </li>
                                                )
                                            }))}</ul>
                                            {this.props.upload_loading && <div className='mt-4'><Loading></Loading></div>}
                                        </aside>
                                        }
                                    </section>
                                )}
                            </Dropzone>

                            <CTooltip content={'Tải lên'}>
                                <CButton
                                    type="button"
                                    color="primary"
                                    onClick={() => { this.handleUpload() }}
                                    disabled={this.props.upload_loading}
                                >
                                    <CIcon name="cil-cloud-upload" className="mfe-1 mt-0"></CIcon>
                                    Tải Lên
                                </CButton>
                            </CTooltip>
                        </CCol>
                    </CRow>
                </CCol >
            </CRow >
        )
    }
}
export default Files;
