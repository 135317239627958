import React, { Component } from 'react'
import {
    CBadge,
    CCol,
    CImg,
    CLink,
    CModal,
    CModalBody,
    CModalHeader,
    CRow,
    CTooltip,
} from '@coreui/react';
import configs from 'src/appConfigs';
import EmployeeAccount from './EmployeeAccount';
import { helps } from 'src/_helpers';
import { ThePermission } from 'src/containers/ThePermission';
import EmployeeManeuvers from './EmployeeManeuvers';
import { EmployeeContractsContainer, EmployeeCertificatesContainer } from 'src/containers/employees';
import Hint from 'src/_components/Hint';

class Employee extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show_qr: false,
        }

    }

    componentDidMount() {

    }


    render() {
        const { employee, employees, users, projects } = this.props;
        //console.log(employee)
        var joint_date = '';
        var joint_during_date = '';
        if (employee.joint_date) {
            joint_date = employee.joint_date.toLocaleDateString();
            joint_during_date = helps.secondsToYmd(employee.joint_date, new Date());
        }

        var birthdate = '';
        if (employee.birthdate) {
            birthdate = employee.birthdate.toLocaleDateString();
        }

        var id_issuance_date = '';
        if (!helps.isEmpty(this.state.id_issuance_date) && typeof employee.id_issuance_date === 'object') {
            id_issuance_date = employee.id_issuance_date.toLocaleDateString();
        } else {
            id_issuance_date = new Date(employee.id_issuance_date).toLocaleDateString();
        }

        let added_by_alias = helps.getUserAlias(users, employee.added_by);
        var added_date = '';
        if (employee.added_date) {
            added_date = employee.added_date.toLocaleDateString();

        }
        var recruited_by_alias = '';
        var recruited_by_employee = helps.getItemFromArr(employees, employee.recruited_by, 'employee_id');
        if (!helps.isEmpty(recruited_by_employee)) {
            recruited_by_alias = recruited_by_employee.fullname + ' (' + recruited_by_employee.employee_id + ')';
        }
        var basic_salary = '';
        if (employee.basic_salary) {
            basic_salary = parseInt(employee.basic_salary).toLocaleString('vn-VN', {
                style: 'currency',
                currency: 'VND',
            });
        }

        var job_type_color = 'primary';
        switch (employee.job_type) {
            case 'DNV':
                job_type_color = 'danger';
                break;
            case 'NVTV':
                job_type_color = 'warning';
                break;
            case 'CTV':
                job_type_color = 'secondary';
                break;
            default:
                job_type_color = 'primary';
        }
        if (helps.isEmpty(employee['project'])) {
            var employee_projects = [];
            employee_projects = projects.filter((project) => {
                if (project.employees) {
                    let found = helps.getItemFromArr(project.employees, employee.employee_id, 'employee_id');
                    if (!helps.isEmpty(found)) return true;
                    else return false;
                } else {
                    return false;
                }
            })
            if (!helps.isEmpty(employee_projects)) {
                let employee_project = employee_projects[0];
                employee['project'] = employee_project.name;
                employee['project_id'] = employee_project.id;
            }
        }

        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol md={6}>
                            <CRow className="mb-3">
                                <CCol md={6}>
                                    Họ & Tên: <b>{employee.fullname}</b>
                                </CCol>
                                <CCol md={6}>
                                    MSNV: <b>{employee.employee_id}</b>
                                </CCol>

                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={6} sm={12}>
                                    Ngày/tháng/năm sinh: <b>{birthdate}</b>
                                </CCol>
                                <CCol md={6} sm={12}>
                                    Mục tiêu: <b><CLink className="text-dark" onClick={() => { this.props.history.push(`/projects/view/${employee.project_id}`) }}>{employee.project}</CLink></b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={6}>
                                    Số CCCD/CMT: <b>{employee.id_number}</b>{employee.id_type && <i> ({employee.id_type})</i>}
                                    <div>
                                        Ngày cấp: <b>{id_issuance_date}</b>
                                    </div>
                                </CCol>
                                <CCol md={6}>
                                    Số ĐT: <b>{employee.phone}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Lưu CCCD/CMT?: <b>{employee.cccd && <CBadge color='success'>Có</CBadge>}{!employee.cccd && <CBadge color='danger'>Không</CBadge>}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Ngày vào cty: <b>{joint_date}</b> <i>({joint_during_date})</i>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Chi nhánh: <b>{configs.branches[employee.branch]}</b>
                                </CCol>
                                <CCol>
                                    Khối: <b>{configs.groups[employee.group]}</b>
                                </CCol>
                                <CCol>
                                    Bộ phận: <b>{configs.departments[employee.department]}</b>
                                </CCol>
                                <CCol>
                                    Chức vụ: <b>{configs.positions[employee.position]}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Loại hình: <CBadge color={job_type_color}>{configs.job_types[employee.job_type]}</CBadge>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Lương cơ bản: <b>{basic_salary}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    TK Ngân hàng: <b>{employee.bank_account_number && <>{employee.bank_account_number}</>}{employee.bank_name && <em> ({employee.bank_name})</em>}</b>
                                    {employee.bank_account_number && employee.bank_name && <>
                                        <CTooltip content={<>BANK QR CODE: <b className='ml-2'> {employee.fullname} ({employee.employee_id})</b></>}>
                                            <CImg
                                                src={'https://img.vietqr.io/image/' + employee.bank_name + '-' + employee.bank_account_number + '-compact.png'}
                                                width={20}
                                                onClick={(e) => { e.preventDefault(); this.setState({ show_qr: true }) }}
                                                className={'cursor-pointer border ml-1'}
                                            />
                                        </CTooltip>
                                        {employee.bank_note && <span className='ml-2 font-italic text-muted'>({employee.bank_note})</span>}
                                        <CModal
                                            show={this.state.show_qr}
                                            size='lg'
                                            className='text-left'
                                            onClose={() => { this.setState({ show_qr: false }); }}
                                        >
                                            <CModalHeader closeButton>
                                                BANK QR CODE: <b className='ml-2'> {employee.fullname} ({employee.employee_id})</b>
                                            </CModalHeader>
                                            <CModalBody className='text-center'>
                                                <CImg src={'https://img.vietqr.io/image/' + employee.bank_name + '-' + employee.bank_account_number + '-compact.png'} />
                                            </CModalBody>
                                        </CModal>
                                    </>}

                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Chiều cao: {employee.height && <><b>{employee.height}</b>cm</>}
                                </CCol>
                                <CCol>
                                    Cân nặng: {employee.weight && <><b>{employee.weight}</b>kg</>}
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Địa chỉ thường trú: <b>{employee.permanent_address}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Nguyên quán: <b>{employee.home_town}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Người Tham khảo<Hint content='Họ & Tên người thân, người tham chiếu' />: <b>{employee.reference_name}</b>
                                </CCol>
                                <CCol>
                                    Quan hệ:<Hint content='Quan hệ với nhân sự' />: <b>{employee.reference_relationship}</b>
                                </CCol>
                                <CCol>
                                    Số ĐT Người Tham khảo:<Hint content='Số điện thoại người thân, người tham chiếu' />: <b>{employee.reference_phone}</b>
                                </CCol>
                            </CRow>
                            <hr />
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Tuyển dụng vào bởi: <b>{recruited_by_alias}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Ngày Tạo: <b>{added_date}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Tạo bởi: <b>{added_by_alias}</b>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol md={6}>
                            <ThePermission {... this.props} component="settings" action="view">
                                <EmployeeAccount {... this.props} employee={employee} />
                            </ThePermission>
                            <EmployeeContractsContainer {... this.props} />
                            <EmployeeCertificatesContainer {... this.props} />
                            <EmployeeManeuvers {... this.props} />
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        )
    }
}
export default Employee;
