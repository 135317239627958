import { taskConstants } from "src/_constants";
import { taskService } from "src/_services/task.service";
import { alertActions } from ".";

export const taskActions = {
    getUserTasks,
    getAll,
    add,
    update,
    _delete,
    getComments,
    addComment,
    updateComment,
    deleteComment,
}

function getUserTasks(params = {}, _limit = 1000) {
    return dispatch => {
        dispatch(request(params));
        return taskService.getUserTasks(params, _limit)
            .then(
                tasks => {
                    dispatch(success(tasks, params));
                    return tasks;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: taskConstants.GET_USER_TASKS_REQUEST, limit: _limit, params } }
    function success(tasks, params) { return { type: taskConstants.GET_USER_TASKS_SUCCESS, tasks, limit: _limit, params } }
    function failure(error) { return { type: taskConstants.GET_USER_TASKS_FAILURE, error, _limit } }
}

function getAll(params = {}, _limit = 1000) {
    return dispatch => {
        dispatch(request(params));
        return taskService.getAll(params, _limit)
            .then(
                tasks => {
                    dispatch(success(tasks));
                    return tasks
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(params) { return { type: taskConstants.GET_LIST_REQUEST, params } }
    function success(tasks) { return { type: taskConstants.GET_LIST_SUCCESS, tasks } }
    function failure(error) { return { type: taskConstants.GET_LIST_FAILURE, error } }
}

function add(task) {
    return dispatch => {
        dispatch(request(task));
        taskService.add(task)
            .then(
                task => {
                    dispatch(success(task));
                    dispatch(alertActions.success('Thêm mới Công Việc thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(task) { return { type: taskConstants.ADD_REQUEST, task: task } }
    function success(task) { return { type: taskConstants.ADD_SUCCESS, task: task } }
    function failure(error) { return { type: taskConstants.ADD_FAILURE, error } }
}

function update(task) {
    return dispatch => {
        dispatch(request(task));
        taskService.update(task)
            .then(
                task => {
                    dispatch(success(task));
                    dispatch(alertActions.success('Chỉnh sửa Công Việc thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(task) { return { type: taskConstants.EDIT_REQUEST, task: task } }
    function success(task) { return { type: taskConstants.EDIT_SUCCESS, task: task } }
    function failure(error) { return { type: taskConstants.EDIT_FAILURE, error } }
}

function _delete(task) {
    return dispatch => {
        dispatch(request());
        return taskService._delete(task)
            .then(
                task => {
                    dispatch(success());
                    dispatch(alertActions.success('Xoá Công Việc thành công!'));
                    return task;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: taskConstants.DELETE_REQUEST, task: task } }
    function success() { return { type: taskConstants.DELETE_SUCCESS, task: task } }
    function failure(error) { return { type: taskConstants.DELETE_FAILURE, error } }
}
// Comments
function getComments(task, _limit = 1000) {
    return dispatch => {
        dispatch(request());
        return taskService.getComments(task, _limit)
            .then(
                comments => {
                    dispatch(success(comments));
                    return comments;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: taskConstants.GET_COMMENTS_REQUEST, limit: _limit, task: task } }
    function success(comments) { return { type: taskConstants.GET_COMMENTS_SUCCESS, limit: _limit, task: task, comments: comments } }
    function failure(error) { return { type: taskConstants.GET_COMMENTS_FAILURE, error, task, _limit } }
}


function addComment(task, comment) {
    return dispatch => {
        dispatch(request(comment));
        return taskService.addComment(task, comment)
            .then(
                comment => {
                    dispatch(success(comment));
                    dispatch(alertActions.success('Thêm Thảo Luận thành công!'));
                    return comment;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(comment) { return { type: taskConstants.ADD_COMMENT_REQUEST, task: task, comment: comment } }
    function success(comment) { return { type: taskConstants.ADD_COMMENT_SUCCESS, task: task, comment: comment } }
    function failure(error) { return { type: taskConstants.ADD_COMMENT_FAILURE, error } }
}

function updateComment(task, comment) {
    return dispatch => {
        dispatch(request(task, comment));
        return taskService.updateComment(task, comment)
            .then(
                comment => {
                    dispatch(success(task, comment));
                    dispatch(alertActions.success('Chỉnh sửa Thảo Luận thành công!'));
                    return comment;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(task, comment) { return { type: taskConstants.EDIT_COMMENT_REQUEST, task: task, comment: comment } }
    function success(task, comment) { return { type: taskConstants.EDIT_COMMENT_SUCCESS, task: task, comment: comment } }
    function failure(error) { return { type: taskConstants.EDIT_COMMENT_FAILURE, error } }
}

function deleteComment(task, comment) {
    return dispatch => {
        dispatch(request(task, comment));
        return taskService.deleteComment(task, comment)
            .then(
                comment => {
                    dispatch(success(task, comment));
                    dispatch(alertActions.success('Xoá Thảo Luận thành công!'));
                    return comment;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(task, comment) { return { type: taskConstants.DELETE_COMMENT_REQUEST, task: task, comment: comment } }
    function success(task, comment) { return { type: taskConstants.DELETE_COMMENT_SUCCESS, task: task, comment: comment } }
    function failure(error) { return { type: taskConstants.DELETE_COMMENT_FAILURE, error } }
}