import React, { Component } from 'react'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CInput,
    CRow,
} from '@coreui/react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';

class Item extends Component {

    constructor(props) {
        super(props);

        this.state = {
            item: {},
        }
        this.handleChangeInventory = this.handleChangeInventory.bind(this);
    }

    componentDidMount() {
        this.setState({ item: this.props.item })
    }

    handleChangeInventory = async (store_id, e) => {
        var item = this.state.item;
        var inventory = item.inventory ? item.inventory : [];
        var foundIndex = inventory.findIndex(inventory_item => inventory_item.store_id === store_id);
        let value = parseInt(e.target.value);

        if (foundIndex < 0) {
            inventory.push({
                store_id: store_id,
                current_inventory: value,
            });
        } else {
            inventory[foundIndex] = {
                store_id: store_id,
                current_inventory: value,
            }
        }
        item['inventory'] = inventory;
        this.setState({ item: item });
        //console.log(item, foundIndex)
        await this.props.updateField(item, 'inventory');
    };

    render() {
        const { item, user, users, stores } = this.props;
        if (helps.isEmpty(item)) {
            return (
                <Loading></Loading>
            );
        }

        var create_date = '';
        if (item.create_date) {
            create_date = item.create_date.toLocaleDateString();
        }
        item['create_by_alias'] = helps.getUserAlias(users, item.create_by);

        let category = helps.getItemFromArr(configs.asset_categories, item.category);
        //console.log(user);
        return (
            <CRow className="mt-3">
                <CCol xl={12}>
                    <CRow>
                        <CCol md={6}>
                            <CRow className="mb-3">
                                <CCol>
                                    Mã: <b>{item.code}</b>
                                </CCol>
                                <CCol>
                                    Tên: <b>{item.name}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Loại: <b>{category.label}</b>
                                </CCol>
                                <CCol>
                                    Đơn giá: <b className='text-danger'>{helps.formatCurrency(item.price)}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Kích thước: <b>{item.dimension}</b>
                                </CCol>
                                <CCol>
                                    Đơn vị tính: <b>{item.dimension}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    <b>Mô tả:</b>
                                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                </CCol>
                            </CRow>
                            <hr />
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Ngày tạo: <b>{create_date}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Tạo bởi: <b>{item.create_by_alias}</b>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol md={6}>
                            <CCard>
                                <CCardHeader>
                                    Tồn Kho: <b>{item.name}</b>
                                </CCardHeader>
                                <CCardBody>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '70%' }}>Kho</th>
                                                <th>Tồn</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!helps.isEmpty(stores) && stores.map((store) => {
                                                let inventory = helps.getItemFromArr(this.state.item.inventory, store.id, 'store_id');
                                                if (!inventory || !inventory.current_inventory) {
                                                    inventory['current_inventory'] = 0;
                                                }
                                                return (
                                                    <tr key={'inventory-' + store.id}>
                                                        <td>{store.name}</td>
                                                        <td>
                                                            <CInput
                                                                type='number'
                                                                className={'font-weight-bold'}
                                                                disabled={user.role !== 'administrator'}
                                                                value={inventory.current_inventory}
                                                                onChange={(e) => { this.handleChangeInventory(store.id, e) }}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        )
    }
}
export default Item;
