import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CCallout,
    CCollapse,
    CCard,
    CCardHeader,
    CCardBody,
    CTooltip,
    CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import PositionsForm from './PositionsForm';


class Positions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            showAddForm: false,
            show_list: true,
            edit_forms: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancelForm = this.handleCancelForm.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {

    }

    handleCancelForm() {
        this.setState({ showAddForm: false });
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
    }

    handleDelete(e, position) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Vị Trí: ' + position.name + ' ?');
        if (_confirm) {
            this.props._delete(position);
        }
        return false;
    }

    handleSubmit(position) {
        if (helps.isEmpty(position.id)) {
            this.props.add(position);
            this.handleCancelForm();
        } else {
            this.props.update(position);
            this.toggleEditForms(position);
        }
    }

    toggleEditForms = (item) => {
        let forms = this.state.edit_forms;
        const position = forms.indexOf(item.id);
        let newForms = forms.slice();
        if (position !== -1) {
            newForms.splice(position, 1)
        } else {
            newForms = [...forms, item.id]
        }
        this.setState({ edit_forms: newForms });
    }

    render() {
        const { positions, project, user } = this.props;
        let areas = !helps.isEmpty(project.areas) ? project.areas : [];
        var total_employees = 0;
        let _positions = !helps.isEmpty(positions) ? positions : [];
        if (!helps.isEmpty(project)) {
            _positions = _positions.filter(position => position.project_id === project.id);
        }
        if (_positions && _positions.length > 0) {
            _positions.map((position) => {
                if (!helps.isEmpty(position.shifts)) {
                    position.shifts.map((shift) => {
                        total_employees += parseInt(shift.number_employees);
                    })
                }
            })
        }

        return (
            <CRow>
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol xl={6} md={6}>
                                    Vị Trí <small><i>(Tổng số lượng nhân viên: <b>{total_employees}</b>)</i></small>
                                </CCol>
                                <CCol xl={6} md={6} className="text-right">
                                    <CTooltip content={'Thêm Vị trí'}>
                                        <CButton type="button" color="primary" size="sm" onClick={() => { this.setState({ showAddForm: !this.state.showAddForm }) }}>
                                            <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                            Thêm Vị Trí
                                        </CButton>
                                    </CTooltip>
                                    <CTooltip content={this.state.show_list ? 'Ẩn' : 'Hiện'}>
                                        <CButton type="button" color="secondary" size="sm" onClick={() => { this.setState({ show_list: !this.state.show_list }) }} title={this.state.show_list ? 'Ẩn' : 'Hiện'} className='ml-2'>
                                            <CIcon name={this.state.show_list ? 'cil-chevron-top' : 'cil-chevron-bottom'} className="mfe-1 mt-0"></CIcon>
                                        </CButton>
                                    </CTooltip>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCollapse show={this.state.show_list}>
                            <CCardBody>
                                {this.state.showAddForm && <PositionsForm {...this.props} handleSubmit={this.handleSubmit} handleCancelForm={() => { this.handleCancelForm() }} />}
                                <div className='positions-container'>
                                    {!helps.isEmpty(_positions) && _positions.map((position) => {
                                        let area = helps.getItemFromArr(areas, position.area_id);
                                        //console.log(position)
                                        return (
                                            <div key={position.id}>
                                                <CCallout key={position.id} color="warning" className={'bg-white'}>
                                                    <CRow className="mb-2">
                                                        <CCol>
                                                            <h5 className='font-weight-bold'>{position.name}</h5>
                                                            {!helps.isEmpty(area) && <div className='font-weight-normal'><i className='text-muted'>Khu vực:</i> <b>{area.name}</b></div>}
                                                            {position.description && <div><i className='text-muted'>Mô tả:</i> {position.description}</div>}
                                                        </CCol>
                                                    </CRow>
                                                    <CRow className="mb-2">
                                                        <CCol xl={9} md={9} sm={12}>
                                                            {!helps.isEmpty(position['shifts']) && position.shifts.map((shift, index) => {
                                                                let _color = 'warning';
                                                                if (shift.id === 'night') _color = 'dark';
                                                                if (shift.id === '24') _color = 'primary';
                                                                return (
                                                                    <CRow key={'shift-' + shift.id + '-' + index} className='border mb-2 ml-1 text-muted'>
                                                                        <CCol md={12}>
                                                                            <CRow className='mt-2 mb-2'>
                                                                                <CCol md={3}>
                                                                                    <i>Ca: <CBadge color={_color} className='mr-1'>{shift.label}</CBadge></i>
                                                                                </CCol>
                                                                                <CCol md={4}>
                                                                                    <i>Thời gian: </i> <b>{shift.start} - {shift.end}</b> <i> (<b className='text-dark'>{shift.duration}</b>/24)</i>
                                                                                </CCol>
                                                                                <CCol md={4}>
                                                                                    <i className='pl-1'>Số lượng Nhân viên:</i> <b>{shift.number_employees}</b>
                                                                                </CCol>
                                                                            </CRow>
                                                                        </CCol>
                                                                    </CRow>
                                                                );
                                                            })}
                                                        </CCol>
                                                        <CCol xl={3} md={3} sm={12}>
                                                            <div className='text-right mt-2 mb-2'>
                                                                <CTooltip content="Sửa Vị Trí">
                                                                    <CButton type="button" size="sm" color="primary" onClick={() => { this.toggleEditForms(position); }}>
                                                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                                    </CButton>
                                                                </CTooltip>
                                                                <CTooltip content="Hiện tại không xoá vị trí">
                                                                    <CButton
                                                                        color="danger"
                                                                        size="sm"
                                                                        className="ml-1"
                                                                        title="Hiện tại không xoá vị trí."
                                                                        disabled
                                                                        onClick={(e) => { this.handleDelete(e, position) }}
                                                                    >
                                                                        <CIcon name="cil-trash"></CIcon>
                                                                    </CButton>
                                                                </CTooltip>
                                                            </div>
                                                        </CCol>
                                                    </CRow>
                                                    <CCollapse show={this.state.edit_forms.includes(position.id)} key={"details_" + position.id} style={{ overflow: 'hidden' }}>
                                                        <PositionsForm
                                                            {...this.props}
                                                            position={position}
                                                            handleSubmit={this.handleSubmit}
                                                            toggleEditForms={() => { this.toggleEditForms(position) }}
                                                            handleCancelForm={() => { this.handleCancelForm() }}
                                                        />
                                                    </CCollapse>
                                                </CCallout>
                                                <hr />
                                            </div>
                                        );
                                    })}
                                </div>
                            </CCardBody>
                        </CCollapse>
                    </CCard>
                </CCol>
            </CRow>
        )
    }
}


export default Positions;
