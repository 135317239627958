import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CCardFooter,
} from '@coreui/react';
import { ThePermission } from '../ThePermission';
import Vendors from 'src/views/vendors/Vendors';
import { vendorActions, noteActions, userActions, provinceActions, tagActions } from 'src/_actions';
import { helps } from 'src/_helpers';

class VendorsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_header: 'Quản Lý Nhà Cung Cấp',
        }
    }

    componentDidMount() {
        if (!this.props.provinces) {
            this.props.getProvinces();
        }

        if (helps.isEmpty(this.props.notes)) {
            this.props.getNotes('vendors');
        }

        if (helps.isEmpty(this.props.users)) {
            this.props.getUsers();
        }

        this.getTags();

        if (helps.isEmpty(this.props.vendors)) {
            this.props.getAll();
        }
    }

    async getTags() {
        await this.props.getTags('vendors');
    }

    render() {
        return (
            <ThePermission {... this.props} component="vendors" action="view" alertOut={true}>
                <CRow key={helps.convertToSlug(this.state.page_header)}>
                    <CCol xl={12}>
                        <CCard>
                            <CCardHeader>
                                {this.state.page_header}
                            </CCardHeader>
                            <CCardBody className='vendors-table'>
                                <Vendors {... this.props} />
                            </CCardBody >
                            <CCardFooter>
                                Tổng: <b>{this.props.vendors && <>{this.props.vendors.length}</>}</b>
                            </CCardFooter>
                        </CCard >
                    </CCol >
                </CRow >
            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { loading, vendors } = state.vendorReducer;
    const { provinces } = state.provinceReducer;
    const { users } = state.userReducer;
    const { notes } = state.noteReducer;
    const { tags } = state.tagReducer;
    return { loading, vendors, provinces, users, notes, tags };
}

const actionCreators = {
    getAll: vendorActions.getAll,
    getProvinces: provinceActions.getAll,
    getNotes: noteActions.getAll,
    getUsers: userActions.getAll,
    getTags: tagActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(VendorsContainer);
export { connectedContainer as VendorsContainer };