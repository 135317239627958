import { housingConstants } from "src/_constants";

export function housingReducer(state = { housings: [] }, actions) {
    switch (actions.type) {

        // get all housing
        case housingConstants.GETALL_REQUEST:
            return {
                loading: true,
            }
        case housingConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                housings: actions.housings,
            }
        case housingConstants.GETALL_FAILURE:
            return {
                ...state,
                error: actions.error
            }

        // get a housing by ID    
        case housingConstants.GET_REQUEST:
            return {
                loading: true
            }
        case housingConstants.GET_SUCCESS:
            return {
                ...state,
                housing: actions.housing,
                loading: false
            }
        case housingConstants.GET_FAILURE:
            return {
                error: actions.error
            };
        // add new housing    
        case housingConstants.ADD_REQUEST:
            return {
                loading: true
            }
        case housingConstants.ADD_SUCCESS:
            var housings = state.housings ? state.housings : [];
            housings.push(actions.housing);
            return {
                ...state,
                housings: housings,
                loading: false
            }
        case housingConstants.ADD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update housing    
        case housingConstants.UPDATE_REQUEST:
            return {
                ...state,
                //loading: true
            }
        case housingConstants.UPDATE_SUCCESS:
            var _housings = state.housings;
            var foundIndex = _housings.findIndex(item => item.id === actions.housing.id);
            actions.housing['index'] = foundIndex;
            _housings[foundIndex] = actions.housing;
            console.log(foundIndex);
            return {
                ...state,
                //loading: false,
                housings: _housings,
                housing: actions.housing,
            }
        case housingConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update housing field    
        case housingConstants.UPDATE_FIELD_REQUEST:
            return {
                ...state,
                loading: false
            }
        case housingConstants.UPDATE_FIELD_SUCCESS:
            var _housings = state.housings;
            var foundIndex = _housings.findIndex(item => item.id === actions.housing.id);
            actions.housing['index'] = foundIndex;
            _housings[foundIndex] = actions.housing;
            console.log(foundIndex);
            return {
                ...state,
                loading: false,
                housings: _housings,
                housing: actions.housing,
            }
        case housingConstants.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        default:
            return state;
    }
}