import { projectConstants } from "src/_constants";

export function projectReducer(state = { projects: [] }, actions) {
    switch (actions.type) {

        // get all project
        case projectConstants.GETALL_REQUEST:
            return {
                loading: true,
            }
        case projectConstants.GETALL_SUCCESS:
            return {
                ...state,
                projects: actions.projects,
                loading: false,
            }
        case projectConstants.GETALL_FAILURE:
            return {
                ...state,
                error: actions.error
            }

        // get a project by ID    
        case projectConstants.GET_REQUEST:
            return {
                loading: true
            }
        case projectConstants.GET_SUCCESS:
            return {
                ...state,
                project: actions.project,
                loading: false
            }
        case projectConstants.GET_FAILURE:
            return {
                error: actions.error
            };
        // add new project    
        case projectConstants.ADD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case projectConstants.ADD_SUCCESS:
            state.projects.push(actions.project);
            return {
                ...state,
                loading: false
            }
        case projectConstants.ADD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update project    
        case projectConstants.UPDATE_REQUEST:
            return {
                ...state,
                update_loading: true
            }
        case projectConstants.UPDATE_SUCCESS:
            var _projects = state.projects;
            var foundIndex = _projects.findIndex(item => item.id === actions.project.id);
            actions.project['index'] = foundIndex;
            _projects[foundIndex] = actions.project;
            console.log(foundIndex);
            return {
                ...state,
                update_loading: false,
                projects: _projects,
                project: actions.project,
            }
        case projectConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update project field   
        case projectConstants.UPDATE_FIELD_REQUEST:
            return {
                ...state,
                update_loading: true
            }
        case projectConstants.UPDATE_FIELD_SUCCESS:
            var _projects = state.projects;
            var foundIndex = _projects.findIndex(item => item.id === actions.project.id);
            actions.project['index'] = foundIndex;
            _projects[foundIndex] = actions.project;
            return {
                ...state,
                update_loading: false,
                projects: _projects,
                project: actions.project,
            }
        case projectConstants.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        default:
            return state;
    }
}