import firebase, { db } from "src/dbConfigs";
import { fileService } from 'src/_services';
import { userService } from "src/_services";
import { helps } from "src/_helpers";

export const checkService = {
    getAll,
    _delete,
    add,
    update,
}
async function getAll(params = {}, _limit = 10000) {
    try {
        let ref = db.collection('checks');
        var _query = {};
        var curent_date = new Date();
        //console.log(params)
        if (!helps.isEmpty(params)) {
            //console.log(params);
            if (params.curent_date) {
                curent_date = params.curent_date;
            }
            var start_date = new Date(curent_date.getFullYear(), curent_date.getMonth(), 1);
            start_date = new Date(start_date.setHours(0, 0, 0));
            var end_date = new Date(curent_date.getFullYear(), curent_date.getMonth() + 1, 0);
            end_date = new Date(end_date.setHours(23, 59, 59));
            //console.log(curent_date, start_date, end_date);

            _query = ref.where('check_date', '>=', new Date(start_date))
                .where('check_date', '<=', new Date(end_date));

            if (!helps.isEmpty(params.employee)) {
                _query = ref.where('employee_id', '==', params.employee.employee_id).where('check_date', '>=', new Date(start_date))
                    .where('check_date', '<=', new Date(end_date))
                    .limit(_limit);
            }
            if (!helps.isEmpty(params.project)) {
                _query = ref.where('project_id', '==', params.project.id).where('check_date', '>=', new Date(start_date))
                    .where('check_date', '<=', new Date(end_date))
                    .limit(_limit);
            }
        } else {
            _query = ref.limit(_limit);
        }
        //_query = ref.limit(_limit);
        const snapshot = await _query.get();

        const checks = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        await Promise.all(
            checks.map(async (check) => {
                check['check_date'] = check['check_date'].toDate();
                if (check.checkin_time) {
                    check['checkin_time'] = check['checkin_time'].toDate();
                }
                if (check.checkout_time) {
                    check['checkout_time'] = check['checkout_time'].toDate();
                }
                if (check.added_by) {
                    let addedby = await userService.getById(check.added_by);
                    check['added_by_alias'] = addedby.fullname + '-' + addedby.username;
                }
            })
        );
        console.log(checks);
        return checks;
    } catch (error) {
        return error;
    }
}

async function add(check) {
    try {
        const Ref = db.collection("checks");
        let curent_date = check.check_date;
        var start_date = curent_date;
        start_date = new Date(start_date.setHours(0, 0, 0));
        var end_date = curent_date;
        end_date = new Date(end_date.setHours(23, 59, 59));
        /*
        let found_snapshot = await Ref.where('project_id', '==', check.project_id)
            .where('employee_id', '==', check.employee_id)
            .where('check_date', '>=', start_date)
            .where('check_date', '<=', end_date)
            .where('is_overtime', '==', false)
            .get();
        const found_checks = found_snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        */
        if (check.is_overtime) {
            const docRef = await db.collection("checks").add(check);
            check.id = docRef.id;
            if (!helps.isEmpty(check.attachment)) {
                const attachment = fileService.uploadFile(this.attachment, 'checks', check.id);
                check['attachment'] = attachment.url;
            }
            console.log("Firebase: check has been added successfully!");
            await update(check);
            return check;
        } else {
            const docRef = await db.collection("checks").add(check);
            check.id = docRef.id;
            if (!helps.isEmpty(check.attachment)) {
                const attachment = fileService.uploadFile(this.attachment, 'checks', check.id);
                check['attachment'] = attachment.url;
            }
            console.log("Firebase: check has been added successfully!");
            await update(check);
            return check;
        }

    } catch (error) {
        console.error("Error adding check: ", error);
    }
}

async function update(check) {
    var Ref = db.collection("checks").doc(check.id);

    try {
        await Ref.update(check);
        console.log("Firebase: check has been updated successfully!");
        return check;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _delete(check) {
    var Ref = db.collection("checks").doc(check.id);
    try {
        await Ref.delete();
        console.log("Firebase: check has been deleted successfully!");
        return check;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}
