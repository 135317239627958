import { contractConstants } from "src/_constants";
import { contractService } from "src/_services/contract.service";
import { alertActions } from ".";

export const contractActions = {
    getAll,
    add,
    update,
    _delete,
}

function getAll(customer, limit = 100) {
    return dispatch => {
        dispatch(request());
        return contractService.getAll(customer, limit)
            .then(
                contracts => {
                    dispatch(success(contracts, customer));
                    return contracts;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(customer) { return { type: contractConstants.GET_LIST_REQUEST, customer: customer } }
    function success(contracts, customer) { return { type: contractConstants.GET_LIST_SUCCESS, contracts, customer } }
    function failure(error) { return { type: contractConstants.GET_LIST_FAILURE, error } }
}

function add(contract) {
    return dispatch => {
        dispatch(request(contract));
        contractService.add(contract)
            .then(
                contract => {
                    dispatch(success(contract));
                    dispatch(alertActions.success('Thêm mới Hợp Đồng thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(contract) { return { type: contractConstants.ADD_REQUEST, contract: contract } }
    function success(contract) { return { type: contractConstants.ADD_SUCCESS, contract: contract } }
    function failure(error) { return { type: contractConstants.ADD_FAILURE, error } }
}

function update(contract) {
    return dispatch => {
        dispatch(request(contract));
        contractService.update(contract)
            .then(
                contract => {
                    dispatch(success(contract));
                    dispatch(alertActions.success('Chỉnh sửa Hợp Đồng thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(contract) { return { type: contractConstants.EDIT_REQUEST, contract: contract } }
    function success(contract) { return { type: contractConstants.EDIT_SUCCESS, contract: contract } }
    function failure(error) { return { type: contractConstants.EDIT_FAILURE, error } }
}

function _delete(contract) {
    return dispatch => {
        dispatch(request(contract));
        contractService._delete(contract)
            .then(
                contract => {
                    dispatch(success(contract));
                    dispatch(alertActions.success('Xoá Hợp Đồng thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(contract) { return { type: contractConstants.DELETE_REQUEST, contract: contract } }
    function success(contract) { return { type: contractConstants.DELETE_SUCCESS, contract: contract } }
    function failure(error) { return { type: contractConstants.DELETE_FAILURE, error } }
}