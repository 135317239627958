import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CToaster, CToast, CToastBody, CToastHeader, CAlert, CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';

import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index';

const TheLayout = (props) => {

  const alert = useSelector((state) => state.alert);

  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader {...props} key='TheHeader' />
        <div className="c-body">
          {alert.message && <MyAlert {...props} alert={alert} />}
          <TheContent />
        </div>
        <TheFooter />
      </div>
    </div>
  )
}

const MyAlert = (props) => {

  const [myAlert, setMyAlert] = useState({ type: '', message: '' });

  useEffect(() => {
    const alert = props.alert;
    setMyAlert({ type: alert.type, message: alert.message });
  }, [myAlert.message]);

  let toast = {
    position: 'top-center',
    type: myAlert.type,
    autohide: 3000,
    show: myAlert.message ? true : false,
    fade: true,
    message: myAlert.message
  };

  return (
    <CToaster position='top-center'>
      <CToast
        show={myAlert.message ? true : false}
        autohide={3000}
        fade={toast.fade}
      >
        <CToastHeader closeButton={toast.closeButton} className={`text-${toast.type} text-uppercase`}>
          <CIcon name="cil-bell-exclamation" />
        </CToastHeader>
        <CToastBody>
          <div className={`text-${toast.type}`}>{toast.message}</div>
        </CToastBody>
      </CToast>
    </CToaster>
  )
}

export default TheLayout
