import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../_loading';
import { userActions, generalActions } from '../../_actions';

import UserForm from '../../views/users/UserForm';
import { helps } from 'src/_helpers';


class UserEditContainer extends Component {

    constructor(props) {

        super(props);
        this.state = {
            user: {},
        }
    }

    componentDidMount() {
        const { users } = this.props;
        let id = this.props.match.params.id;

        let user = helps.getItemFromArr(users, id);
        this.setState({ user: user });
        //console.log(user);

        // if have not loaded Roles to State, so do load
        if (!this.props.roles) this.props.getAllRoles();
    }

    handleSubmit = (new_user) => {
        let id = this.props.match.params.id;
        this.props.updateUser(id, new_user);
        this.props.history.push('/users');
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        } else {
            return (
                <UserForm
                    key={(!helps.isEmpty(this.state.user) ? this.state.user.id : 'add_form')}
                    {... this.props}
                    user={this.state.user}
                    formHeader={'Cập Nhật: ' + this.state.user.username}
                    roles={this.props.roles}
                    handleSubmit={this.handleSubmit}
                />
            );
        }
    }
}

function mapStateToProps(state) {
    const { loading, user, users } = state.userReducer;
    const { roles } = state.rolesReducer;
    return { loading, user, roles, users };
}

const actionCreators = {
    getUser: userActions.getUser,
    updateUser: userActions.updateUser,
    getAllRoles: generalActions.getAllRoles
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(UserEditContainer);
export { connectedFormContainer as UserEditContainer };