import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CCallout,
    CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import Loading from 'src/containers/_loading';


class Maneuvers extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {

    }

    handleDelete(e, maneuver) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Lệnh Điều Động: ' + maneuver.id + ' ?');
        if (_confirm) {
            this.props._delete(maneuver);
        }
        return false;
    }

    render() {
        const { maneuvers, projects, employees, users } = this.props;

        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        //console.log(maneuvers);
        return (
            <CRow>
                <CCol xl={12}>
                    <div className='maneuvers-container'>
                        {!helps.isEmpty(maneuvers) && maneuvers.map((maneuver, index) => {
                            let maneuver_employee = helps.getItemFromArr(employees, maneuver.employee_id, 'employee_id');
                            let maneuver_from_project = helps.getItemFromArr(projects, maneuver.from_project_id);
                            let maneuver_project = helps.getItemFromArr(projects, maneuver.project_id);
                            return (
                                <CCallout key={maneuver_from_project.id + maneuver.id + maneuver_project.id + '-' + index} id={maneuver.id} color="success" className={'bg-white'}>
                                    <CRow className="mb-2">
                                        <CCol id={maneuver.id}>
                                            <i className='text-muted'><u>Điều:</u> </i> <b>{maneuver_employee.fullname}</b>({maneuver.employee_id})
                                            {maneuver.from_project_id &&
                                                <>
                                                    <i> <u>từ</u> </i>
                                                    <b>{maneuver_from_project.name}</b>
                                                </>
                                            }
                                            <i> <u>đến</u> </i>
                                            <b>{maneuver_project.name}</b>
                                            {maneuver.note && <div><small className='text-muted'>( <i className='font-weight-bold'><u>Ghi Chú:</u></i> {maneuver.note} )</small></div>}
                                            {!helps.isEmpty(maneuver.attachment) && maneuver.attachment.length > 0 && maneuver.attachment.map((file) => {
                                                return (
                                                    <div key={file.name}>
                                                        <small><CIcon name='cil-file' size='sm'></CIcon> <CLink href={file.url} target='_blank'>{file.name}</CLink></small>
                                                    </div>
                                                );
                                            })
                                            }
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol xl={12} md={12}>
                                            <div className='text-right'>
                                                <small className='text-muted'>
                                                    <i>Điều bởi: </i><b>{helps.getUserAlias(users, maneuver.maneuver_by)} </b>
                                                    |
                                                    <i> Ngày: </i><b>{helps.formatDateTime_from_Timestamp(maneuver.maneuver_time)}</b>
                                                </small>
                                            </div>
                                        </CCol>
                                    </CRow>
                                    <hr />
                                </CCallout>
                            );
                        })}
                    </div>
                </CCol>
            </CRow>
        )
    }
}
export default Maneuvers;
