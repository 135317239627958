import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CInvalidFeedback,
    CTextarea,
    CFormGroup,
    CInputRadio,
    CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';

class NotesForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            form_header: 'Thêm Ghi Chú',
            id: '',
            object: '',
            object_id: '',
            note_time: new Date(),
            note_by: '',
            content: '',
            color: 'warning',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEditor = this.handleChangeEditor.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {

        const { note } = this.props;
        //console.log(note);
        if (!helps.isEmpty(note)) {
            this.setState({
                form_header: 'Sửa Ghi Chú',
                id: note.id,
                content: note.content,
                color: note.color,
                object: note.object,
                object_id: note.object_id,
                note_time: note.note_time,
                note_by: note.note_by,
            });
        } else {
            const object = this.props.object;
            const object_id = this.props.object_id;
            const note_by = this.props.user.id;

            if (object && object_id) {
                this.setState({
                    errors: {},
                    object: object,
                    object_id: object_id,
                    note_time: new Date(),
                    note_by: note_by,
                });
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let note = {
                id: this.state.id,
                object: this.state.object,
                object_id: this.state.object_id,
                note_time: this.state.note_time,
                note_by: this.state.note_by,
                content: this.state.content,
                color: this.state.color,
            }
            this.props.handleSubmit(note);
        } else {
            return false;
        }
    }

    handleChangeEditor(field, value) {
        //console.log(field, value);
        this.setState({ [field]: value });
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.content)) {
            formIsValid = false;
            errors["content"] = "Vui lòng nhập nội dung Ghi Chú";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCancel(e) {
        e.preventDefault();
        if (helps.isEmpty(this.state.id)) {
            this.props.handleCancelForm();
        } else {
            this.props.toggleEditForms();
        }

    }

    render() {
        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CLabel htmlFor="input-content">{this.state.form_header}:</CLabel>
                                <CTextarea
                                    rows={5}
                                    name="content"
                                    id="input-content"
                                    defaultValue={this.state.content}
                                    onChange={(e) => { this.handleChange('content', e) }}
                                >
                                </CTextarea>
                                <small className="text-danger">{this.state.errors['content']}</small>
                            </CCol>
                        </CRow>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CLabel htmlFor="input-content" className={'mr-2'}>Màu biểu trưng:</CLabel>
                                {configs.colors && Object.keys(configs.colors).map((_key) => {
                                    return (
                                        <CFormGroup variant="custom-radio" className='mb-2' inline>
                                            <CInputRadio
                                                custom
                                                id={"color_" + _key}
                                                name="color"
                                                value={_key}
                                                checked={this.state.color === _key}
                                                onChange={(e) => { this.handleChange('color', e) }}
                                            />
                                            <CLabel variant="custom-checkbox" htmlFor={"color_" + _key} className="cursor-pointer">
                                                <CBadge color={_key} className="p-2">{configs.colors[_key]}</CBadge>
                                            </CLabel>
                                        </CFormGroup>
                                    )
                                }
                                )}
                                <CInvalidFeedback className="help-block">{this.state.errors['content']}</CInvalidFeedback>
                            </CCol>
                        </CRow>
                        <CRow className="mb-3">
                            <CCol md={12}>
                                <CButton type="submit" size='sm' color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                                <CButton type="button" size='sm' color="secondary" onClick={(e) => { this.handleCancel(e) }} className="ml-3">
                                    <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                    Huỷ
                                </CButton>
                            </CCol>
                        </CRow>
                    </CForm>
                </CCol>
            </CRow>
        )
    }
}
export default NotesForm;

