import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { contactActions, userActions } from '../../_actions';
import Contacts from 'src/views/contacts/Contacts';

class ContactsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer_id: '',
            project_id: '',
            contacts: '',
        }
    }

    componentDidMount() {
        //console.log(this.props.object, this.props.object_id);
        this.setState({ customer_id: this.props.customer_id, project_id: this.props.project_id });
        this.getContacts().then((contacts) => {
            this.setState({ contacts: contacts });
        });

    }

    async getContacts() {
        return await this.props.getAll(this.props.customer_id, this.props.project_id);
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <Contacts
                key={this.props.customer_id + '-' + this.props.project_id}
                {... this.props}
                contacts={this.props.contacts}
                contact={this.props.contact}
                user={this.props.user}
            />
        );
    }
}

function mapStateToProps(state) {
    const { loading, contacts, contact } = state.contactReducer;
    const { user } = state.authentication;
    return { loading, user, contacts, contact };
}

const actionCreators = {
    getAll: contactActions.getAll,
    add: contactActions.add,
    update: contactActions.update,
    _delete: contactActions._delete,

    addUser: userActions.addUser,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ContactsContainer);
export { connectedContainer as ContactsContainer };