import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
    CDataTable,
    CBadge,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';

class Approvals extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount() {

    }

    handleEdit(approval) {
        this.props.handleEdit(approval);
    }

    render() {
        const { approvals, users } = this.props;
        var _approvals = approvals.filter(function (element) {
            return element !== undefined;
        });
        const fields = [
            { key: 'id', _style: { width: '15%' } },
            { key: 'name', label: 'Phê Duyệt', _classes: 'font-weight-bold', _style: { width: '25%' } },
            { key: 'key', label: 'Khóa', _classes: 'text-center', _style: { width: '25%' } },
            { key: 'approvers', label: 'Người Duyệt', _classes: 'text-center', _style: { width: '25%' } },
            { key: 'actions', label: 'Thao Tác', _style: { width: '10%', align: 'center' }, filter: false, sorter: false },
        ]

        return (
            <CRow>
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader>
                            <h3>Quản Lý Phê Duyệt</h3>
                        </CCardHeader>
                        <CCardBody>
                            <CDataTable
                                items={_approvals}
                                fields={fields}
                                columnFilter
                                tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                                itemsPerPageSelect={configs.itemsPerPageSelect}
                                itemsPerPage={configs.per_page}
                                hover
                                sorter
                                pagination
                                scopedSlots={{
                                    'approvers':
                                        (item) => {
                                            return (
                                                <td className="py-2">
                                                    {!helps.isEmpty(item.approvers) && item.approvers.map((approver, index) => {
                                                        return (
                                                            <>
                                                                {index + 1}. {approver.label}
                                                            </>
                                                        )
                                                    })}
                                                </td>
                                            );
                                        },
                                    'actions':
                                        (item) => {
                                            return (
                                                <td className="py-2 text-center">
                                                    <ThePermission {... this.props} component="users" action="edit">
                                                        <CTooltip content={'Sửa: ' + item.name}>
                                                            <CButton
                                                                type="button"
                                                                color="primary"
                                                                size="sm mr-1"
                                                                title="Sửa"
                                                                onClick={() => { this.handleEdit(item); }}
                                                            >
                                                                <CIcon name="cil-pen-nib"></CIcon>
                                                            </CButton>
                                                        </CTooltip>
                                                    </ThePermission>
                                                </td>
                                            )
                                        },
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        )
    }
}
export default Approvals;
