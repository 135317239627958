export const projectConstants = {
    SET_PARAMS_REQUEST: 'PROJECTS_SET_PARAMS_REQUEST',

    GETALL_REQUEST: 'PROJECTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PROJECTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PROJECTS_GETALL_FAILURE',

    DELETE_REQUEST: 'PROJECTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'PROJECTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'PROJECTS_DELETE_FAILURE',

    GET_REQUEST: 'PROJECTS_GET_REQUEST',
    GET_SUCCESS: 'PROJECTS_GET_SUCCESS',
    GET_FAILURE: 'PROJECTS_GET_FAILURE',

    ADD_REQUEST: 'PROJECTS_ADD_REQUEST',
    ADD_SUCCESS: 'PROJECTS_ADD_SUCCESS',
    ADD_FAILURE: 'PROJECTS_ADD_FAILURE',

    UPDATE_REQUEST: 'PROJECTS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PROJECTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PROJECTS_UPDATE_FAILURE',

    UPDATE_FIELD_REQUEST: 'PROJECTS_UPDATE_FIELD_REQUEST',
    UPDATE_FIELD_SUCCESS: 'PROJECTS_UPDATE_FIELD_SUCCESS',
    UPDATE_FIELD_FAILURE: 'PROJECTS_UPDATE_FIELD_FAILURE',
}