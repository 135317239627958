import React, { Component } from 'react'
import {
    CCol,
    CRow, CTooltip, CImg, CModal, CModalHeader, CModalBody,
} from '@coreui/react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import { TagsComponentContainer } from 'src/containers/tags/TagsComponentContainer';

class Vendor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            employees: [],
            show_qr: false,
        }

    }

    componentDidMount() {

    }

    handleEdit(e, tag) {
        e.preventDefault();
        this.props.handleEdit(tag);
    }

    handleChangeSelect = async (newValue, actionMeta) => {
        const { vendor } = this.props;
        vendor['employees'] = newValue;
        console.group("Value Changed");
        console.log(newValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        this.props.updatevendor(vendor);
    };

    render() {
        const { vendor, users, provinces, tags } = this.props;
        if (helps.isEmpty(vendor)) {
            return (
                <Loading></Loading>
            );
        }
        var province = helps.getItemFromArr(provinces, vendor.province_id);
        var province_name = '';
        if (province) {
            province_name = province.name;
        }

        var create_date = '';
        if (vendor.create_date) {
            create_date = vendor.create_date.toLocaleDateString();
        }
        vendor['create_by_alias'] = helps.getUserAlias(users, vendor.create_by);


        return (
            <CRow className="mt-3">
                <CCol xl={12}>
                    <CRow>
                        <CCol md={6}>
                            <CRow className="mb-3">
                                <CCol>
                                    Tên: <b>{vendor.name}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Điện thoại: <b>{vendor.phone}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Website: <b>{vendor.website}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Thông tin Liên hệ:
                                    <div className='ml-5'><b>{vendor.contact_info}</b></div>
                                </CCol>

                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Địa Chỉ: <b>{vendor.address}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={4}>
                                    Tỉnh Thành / Khu Vực: <b>{province_name}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    TK Ngân hàng: <b>{vendor.bank_acc && <>{vendor.bank_acc}</>}{vendor.bank_name && <em> ({vendor.bank_name})</em>}</b>
                                    {vendor.bank_acc && vendor.bank_name && <>
                                        <CTooltip content={<>BANK QR CODE: <b className='ml-2'> {vendor.name}</b></>}>
                                            <CImg
                                                src={'https://img.vietqr.io/image/' + vendor.bank_name + '-' + vendor.bank_acc + '-compact.png'}
                                                width={20}
                                                onClick={(e) => { e.preventDefault(); this.setState({ show_qr: true }) }}
                                                className={'cursor-pointer border ml-1'}
                                            />
                                        </CTooltip>
                                        {vendor.bank_note && <span className='ml-2 font-italic text-muted'>({vendor.bank_note})</span>}
                                        <CModal
                                            show={this.state.show_qr}
                                            size='lg'
                                            className='text-left'
                                            onClose={() => { this.setState({ show_qr: false }); }}
                                        >
                                            <CModalHeader closeButton>
                                                BANK QR CODE: <b className='ml-2'> {vendor.name}</b>
                                            </CModalHeader>
                                            <CModalBody className='text-center'>
                                                <CImg src={'https://img.vietqr.io/image/' + vendor.bank_name + '-' + vendor.bank_account_number + '-compact.png'} />
                                            </CModalBody>
                                        </CModal>
                                    </>}

                                </CCol>
                            </CRow>
                            <hr />
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Ngày Khởi Tạo: <b>{create_date}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Tạo bởi: <b>{vendor.create_by_alias}</b>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol md={6}>
                            <b>Nhãn: </b>
                            <TagsComponentContainer {... this.props} object='vendors' object_id={vendor.id} object_value={vendor} />
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        )
    }
}
export default Vendor;
