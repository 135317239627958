import { db, storage } from "src/dbConfigs";
import { helps } from "src/_helpers";
import { userService } from "../user.service";
import { fileService } from "../file.service";
import { employeeService } from "./employee.service";
export const violationService = {
    getAll,
    _delete,
    add,
    update,
    getAllTypes,
    addType,
    updateType,
    _deleteType,
    getTerms,
    addTerm,
    updateTerm,
    _deleteTerm,
}
async function getAll(employee = {}) {
    //console.log(employee);
    try {
        const ref = db.collection('violations');
        var snapshot = {};
        if (helps.isEmpty(employee)) {
            snapshot = await ref.orderBy("violation_date", "desc")
                .get();
        } else {
            snapshot = await ref.where('employee_id', '==', employee.employee_id)
                .orderBy("violation_date", "desc")
                .get();
        }

        const violations = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        if (violations && violations.length > 0) {
            Promise.all(
                violations.map(async (violation) => {
                    violation['added_time'] = violation['added_time'].toDate();
                    violation['violation_date'] = violation['violation_date'].toDate();
                    let user = await userService.getById(violation.added_by);
                    violation['added_by_alias'] = user.fullname + '-' + user.username;
                    violation['employee'] = await employeeService.getById(violation.employee_id);
                })
            )
        }
        return violations;
    } catch (error) {
        return error;
    }
}

async function add(violation) {
    try {
        var upload_files = violation.attachment;
        if (!helps.isEmpty(upload_files) && upload_files instanceof FileList) {
            var _files = [];
            var _attachment = [];
            _files = await fileService.uploadFile(upload_files, 'employees/' + violation.employee_id, 'violations');
            if (!helps.isEmpty(_files) && _files.length > 0) {
                _files.map((file) => {
                    let _file = {
                        name: file.name,
                        url: file.url,
                    }
                    _attachment.push(_file);
                })
            }
            violation['attachment'] = _attachment;
        }
        const docRef = await db.collection("violations").add(violation);
        violation.id = docRef.id;
        update(violation);
        console.log("Firebase: violation has been added successfully!");
        return violation;
    } catch (error) {
        console.error("Error adding violation: ", error);
    }
}

async function update(violation) {
    try {
        var Ref = db.collection("violations").doc(violation.id);
        var upload_files = violation.attachment;
        if (!helps.isEmpty(upload_files) && upload_files instanceof FileList) {
            // Xoá các file cũ đi
            var storageRef = storage.ref('employees').child(violation.employee_id).child('violations');
            storageRef.listAll().then(function (result) {
                result.items.forEach(function (file) {
                    file.delete();
                });
            }).catch(function (error) {
                // Handle any errors
            });

            // upload files mới lên
            var _files = [];
            var _attachment = [];
            _files = await fileService.uploadFile(upload_files, 'employees/' + violation.employee_id, 'violations');

            if (!helps.isEmpty(_files) && _files.length > 0) {
                _files.map((file) => {
                    let _file = {
                        name: file.name,
                        url: file.url,
                    }
                    _attachment.push(_file);
                })
            }
            violation['attachment'] = _attachment;
        }
        await Ref.update(violation);
        console.log("Firebase: violation has been updated successfully!");
        return violation;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _delete(violation) {
    var Ref = db.collection("violations").doc(violation.id);
    try {
        await Ref.delete();
        console.log("Firebase: violation has been deleted successfully!");
        return violation;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}
// Types
async function getAllTypes() {
    try {
        const ref = db.collection('violation_types');
        const snapshot = await ref.orderBy('name', 'asc').get();
        const types = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        await Promise.all(
            types.map(async (type) => {
                if (type.added_time) {
                    type['added_time'] = type.added_time.toDate();
                }
                if (type.added_by) {
                    let user = await userService.getById(type.added_by);
                    type['added_by_alias'] = user.fullname + '-' + user.username;
                }
                type['terms'] = await getTerms(type);
            })
        );
        return types;
    } catch (error) {
        return error;
    }
}

async function addType(type) {
    try {
        const docRef = await db.collection("violation_types").add(type);
        type.id = docRef.id;
        updateType(type);
        console.log("Firebase: violation type has been added successfully!");
        return type;
    } catch (error) {
        console.error("Error adding violation type: ", error);
    }
}

async function updateType(type) {
    var Ref = db.collection("violation_types").doc(type.id);
    try {
        await Ref.update(type);
        console.log("Firebase: violation type has been updated successfully!");
        return type;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _deleteType(type) {
    var Ref = db.collection("violation_types").doc(type.id);
    try {
        await Ref.delete();
        console.log("Firebase: violation type has been deleted successfully!");
        return type;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}
// Terms
async function getTerms(type) {
    try {
        const snapshot = await db.collection("violation_types/" + type.id + "/terms").orderBy('count', 'asc').get();
        const terms = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        if (!helps.isEmpty(terms) && terms.length > 0) {
            Promise.all(
                terms.map(async (term) => {
                    term['added_time'] = term['added_time'].toDate();
                    term['count'] = parseInt(term.count);
                    term['fines'] = parseInt(term.fines);
                    if (term.added_by) {
                        let added_by = await userService.getById(term.added_by);
                        term['added_by_alias'] = added_by.fullname + '-' + added_by.username;
                    }
                })
            );
        }
        return terms;
    } catch (error) {
        return error;
    }
}

async function addTerm(type, term) {
    try {
        const Ref = db.collection("violation_types/" + type.id + "/terms");
        let docRef = await Ref.add(term);
        term.id = docRef.id;
        Ref.doc(term.id).update(term);
        console.log("Firebase: add term successfully!");
        if (term.added_by) {
            let added_by = await userService.getById(term.added_by);
            term['added_by_alias'] = added_by.fullname + '-' + added_by.username;
        }
        return term;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error: ", error);
    }
}

async function updateTerm(type, term) {
    try {
        const Ref = db.collection("violation_types/" + type.id + "/terms");
        Ref.doc(term.id).update(term);
        console.log("Firebase: update Term successfully!");
        return term;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error: ", error);
    }
}

async function _deleteTerm(type, term) {
    if (helps.isEmpty(type)) return;
    if (helps.isEmpty(term)) return;

    try {
        const Ref = db.collection("violation_types/" + type.id + "/terms").doc(term.id);
        await Ref.delete();
        console.log("Firebase: Term has been deleted successfully!");
        return term;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}