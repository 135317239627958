import { contactConstants } from "src/_constants";
import { contactService } from "src/_services/contact.service";
import { alertActions } from ".";

export const contactActions = {
    getAll,
    add,
    update,
    _delete,
}

function getAll(customer_id, project_id) {
    return dispatch => {
        dispatch(request(customer_id, project_id));
        contactService.getAll(customer_id, project_id)
            .then(
                contacts => dispatch(success(contacts)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(customer_id, project_id) { return { type: contactConstants.GET_LIST_REQUEST, customer_id: customer_id, project_id: project_id } }
    function success(contacts) { return { type: contactConstants.GET_LIST_SUCCESS, contacts } }
    function failure(error) { return { type: contactConstants.GET_LIST_FAILURE, error } }
}

function add(contact) {
    return dispatch => {
        dispatch(request(contact));
        contactService.add(contact)
            .then(
                contact => {
                    dispatch(success(contact));
                    dispatch(alertActions.success('Thêm mới Liên Hệ thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(contact) { return { type: contactConstants.ADD_REQUEST, contact: contact } }
    function success(contact) { return { type: contactConstants.ADD_SUCCESS, contact: contact } }
    function failure(error) { return { type: contactConstants.ADD_FAILURE, error } }
}

function update(contact) {
    return dispatch => {
        dispatch(request(contact));
        contactService.update(contact)
            .then(
                contact => {
                    dispatch(success(contact));
                    dispatch(alertActions.success('Chỉnh sửa Liên Hệ thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(contact) { return { type: contactConstants.EDIT_REQUEST, contact: contact } }
    function success(contact) { return { type: contactConstants.EDIT_SUCCESS, contact: contact } }
    function failure(error) { return { type: contactConstants.EDIT_FAILURE, error } }
}

function _delete(contact) {
    return dispatch => {
        dispatch(request(contact));
        contactService._delete(contact)
            .then(
                contact => {
                    dispatch(success(contact));
                    dispatch(alertActions.success('Xoá Liên Hệ thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(contact) { return { type: contactConstants.DELETE_REQUEST, contact: contact } }
    function success(contact) { return { type: contactConstants.DELETE_SUCCESS, contact: contact } }
    function failure(error) { return { type: contactConstants.DELETE_FAILURE, error } }
}