import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CTextarea,
    CInput,
    CModal,
    CModalHeader,
    CModalBody,
    CFormGroup,
    CInputRadio,
    CBadge,
    CModalFooter,
    CTooltip,
    CSelect,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import Editor from 'src/_components/Editor';

class ViolationTermsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            id: '',
            added_time: new Date(),
            added_by: '',
            title: '',
            count: 1,
            color: 'secondary',
            fines: 0,
            description: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEditor = this.handleChangeEditor.bind(this);
    }

    componentDidMount() {
        const { term } = this.props;
        if (!helps.isEmpty(term)) {
            this.setState(Object.assign(this.state, term));
        } else {
            const added_by = this.props.user.id;
            this.setState({
                errors: {},
                added_time: new Date(),
                added_by: added_by,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let term = {
                id: this.state.id,
                title: this.state.title,
                description: this.state.description,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
                count: parseInt(this.state.count),
                color: this.state.color,
                fines: parseInt(this.state.fines),
            }
            this.props.handleSubmit(term);
        } else {
            return false;
        }
    }

    handleChangeEditor(field, value) {
        this.setState({
            [field]: value
        });
    }

    handleChange(field, e) {
        let value = e.target.value;
        this.setState({
            [field]: value
        });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.title)) {
            formIsValid = false;
            errors["title"] = "Vui lòng nhập Tiêu đề Hình thức Xử lý Vi Phạm.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        const { violation_type, term } = this.props;
        var form_id = violation_type.id;
        if (!helps.isEmpty(term) && !helps.isEmpty(term.id)) {
            form_id = form_id + '-' + term.id;
        } else {
            form_id = form_id + '-add';
        }
        return (
            <CModal
                size='lg'
                show={this.props.modals.includes(this.props.item)}
                onClose={this.props.toggleModal}
                className='text-left'
                id={form_id}
            >
                <CModalHeader closeButton>
                    <div>
                        <h3>{this.props.form_header}</h3>
                        <div className='font-italic text-muted'>{violation_type.name}</div>
                    </div>
                </CModalHeader>
                <CModalBody>
                    <CRow className="mb-3">
                        <CCol md={12}>
                            <CLabel htmlFor="input-title">Tiêu đề Hình thức Xử lý Vi Phạm:</CLabel>
                            <CInput
                                type='text'
                                id='input-title'
                                name='title'
                                defaultValue={this.state.title}
                                onChange={(e) => this.handleChange('title', e)}
                            />
                            <div><small className="text-danger">{this.state.errors['title']}</small></div>
                            <div><small className='text-muted'><i>VD: Nhắc Nhở, Khiển Trách, Cảnh Cáo, Kỷ Luật, ...</i></small></div>
                        </CCol>
                    </CRow>
                    <CRow className="mb-3">
                        <CCol md={12}>
                            <CLabel htmlFor="input-color" className='mr-1'>Màu cờ:</CLabel>
                            <CFormGroup variant="custom-radio" className='mb-1' inline>
                                <CSelect
                                    id={form_id + "_colors"}
                                    value={this.state.color}
                                    onChange={(e) => { this.handleChange('color', e) }}
                                >
                                    {configs.colors && Object.keys(configs.colors).map((_key) => {
                                        return (
                                            <option key={form_id + _key} value={_key}>{_key}</option>
                                        )
                                    }
                                    )}
                                </CSelect>
                                <CLabel variant="checkbox" htmlFor={"color_" + this.state.color}>
                                    <CBadge color={this.state.color} className="p-2 ml-2 mt-1">{this.state.color}</CBadge>
                                </CLabel>
                            </CFormGroup>
                            <small className="text-danger">{this.state.errors['color']}</small>
                        </CCol>
                    </CRow>
                    <CRow className="mb-3">
                        <CCol md={6}>
                            <CLabel htmlFor="input-count">Thứ tự:</CLabel>
                            <CInput
                                type='number'
                                id='input-count'
                                name='count'
                                value={this.state.count}
                                onChange={(e) => this.handleChange('count', e)}
                            />
                            <small className="text-danger">{this.state.errors['count']}</small>
                            <small className='text-muted'><i>VD: 1,2,3,4, ...</i></small>
                        </CCol>
                        <CCol md={6}>
                            <CLabel htmlFor="input-fines">Mức phạt:</CLabel>
                            <CInput
                                type='number'
                                id='input-fines'
                                name='fines'
                                value={this.state.fines}
                                onChange={(e) => this.handleChange('fines', e)}
                            />
                            <small className="text-danger">{this.state.errors['fines']}</small>
                            <small className='text-muted'><i>Mức phạt theo Chế tài của công ty.</i></small>
                        </CCol>
                    </CRow>
                    <CRow className="mb-3">
                        <CCol md={12}>
                            <CLabel htmlFor="input-description">Mô tả:</CLabel>
                            <Editor
                                {... this.props}
                                id="input-description"
                                name="description"
                                defaultValue={this.state.description}
                                handleChangeEditor={this.handleChangeEditor}
                            />
                            <small className="text-danger">{this.state.errors['description']}</small>
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CRow className="mb-3">
                        <CCol md={12}>
                            <CTooltip content={'Lưu'}>
                                <CButton type="button" size='sm' color="primary" onClick={(e) => this.handleSubmit(e)}>
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                            </CTooltip>
                            <CTooltip content={'Đóng'}>
                                <CButton type="button" size='sm' color="secondary" onClick={this.props.toggleModal} className="ml-3">
                                    <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                    Huỷ
                                </CButton>
                            </CTooltip>
                        </CCol>
                    </CRow>
                </CModalFooter>
            </CModal>
        )
    }
}
export default ViolationTermsForm;

