import { contractConstants } from "src/_constants";

export function contractReducer(state = {}, actions) {
    switch (actions.type) {
        // GET LIST    
        case contractConstants.GET_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case contractConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                contracts: actions.contracts,
                loading: false,
            }
        case contractConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Add
        case contractConstants.ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case contractConstants.ADD_SUCCESS:
            state.contracts.push(actions.contract);
            return {
                ...state,
                loading: false,
            }
        case contractConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Edit
        case contractConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case contractConstants.EDIT_SUCCESS:
            var _contracts = state.contracts;
            var foundIndex = _contracts.findIndex(contract => contract.id === actions.contract.id);
            _contracts[foundIndex] = actions.contract;
            console.log(foundIndex);
            return {
                ...state,
                contracts: _contracts,
                loading: false,
            }
        case contractConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        // Delete 
        case contractConstants.DELETE_REQUEST:
            return {
                ...state,
                contracts: state.contracts.map(contract =>
                    contract.id === actions.contract.id
                        ? { ...contract, deleting: true }
                        : contract
                )
            };
        case contractConstants.DELETE_SUCCESS:
            return {
                contracts: state.contracts.filter(contract => contract.id !== actions.contract.id)
            };
        case contractConstants.DELETE_FAILURE:
            return {
                ...state,
                contracts: state.contracts.map(contract => {
                    if (contract.id === actions.contract.id) {
                        const { deleting, ...contractCopy } = contract;
                        return { ...contractCopy, deleteError: actions.error };
                    }
                    return contract;
                })
            };

        default: return state;
    }
}