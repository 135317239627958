import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CFormGroup,
    CInput,
    CInvalidFeedback,
    CTextarea,
    CSelect,
    CInputRadio,
    CBadge,
    CInputCheckbox
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';

class ProjectForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            name: '',
            code: '',
            customer_id: '',
            province_id: '',
            create_by: this.props.user.id,
            create_date: new Date(),
            deploy_date: new Date(),
            address: '',
            description: '',
            color: 'primary',
            active: 1,
            assigns: [],
            assign_options: [],
            default_customer_option: {},
            customer_options: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTime = this.handleChangeTime.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeSelectCustomer = this.handleChangeSelectCustomer.bind(this);
    }

    componentDidMount() {
        const project = this.props.project;
        const { customers, employees } = this.props;
        //console.log(project);
        if (project) {
            this.setState({
                id: project.id,
                name: project.name ? project.name : this.state.name,
                code: project.code ? project.code : this.state.code,
                customer_id: project.customer_id ? project.customer_id : this.state.customer_id,
                province_id: project.province_id ? project.province_id : this.state.province_id,
                create_by: project.create_by ? project.create_by : this.props.user.id,
                create_date: project.create_date ? project.create_date : new Date(),
                deploy_date: project.deploy_date ? project.deploy_date : new Date(),
                address: project.address ? project.address : this.state.address,
                description: project.description ? project.description : this.state.description,
                color: project.color ? project.color : this.state.color,
                active: project.active ? project.active : this.state.active,
                assigns: project.assigns,
            });
        }
        var customer_options = [];
        var default_customer_option = {};
        if (customers && customers.length > 0) {
            customers.map((customer, index) => {
                customer_options[index] = { value: customer.id, label: customer.name };
            });
            default_customer_option = customer_options.find((option) => option.value === project.customer_id);
            this.setState({ customer_options: customer_options, default_customer_option: default_customer_option });
        }

        var assign_options = [];
        if (employees && employees.length > 0) {
            employees.map((employee) => {
                assign_options.push({ value: employee.employee_id, label: employee.fullname + ' (' + employee.employee_id + ')' });
            });
            this.setState({ assign_options: assign_options });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let project = {
                id: this.state.id,
                name: this.state.name,
                code: this.state.code,
                customer_id: this.state.customer_id,
                province_id: this.state.province_id,
                create_by: this.state.create_by,
                create_date: new Date(this.state.create_date),
                deploy_date: new Date(this.state.deploy_date),
                address: this.state.address,
                description: this.state.description,
                color: this.state.color,
                active: this.state.active,
                assigns: this.state.assigns,
            }
            //console.log(project);
            this.props.handleSubmit(project);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        this.setState({
            [field]: e.target.value
        });
        if (field === 'active') {
            let _active = e.target.checked ? 1 : 0;
            this.setState({ active: _active });
            console.log(_active)
        }

    }

    handleChangeTime(value, field) {
        this.setState({
            [field]: value
        });
    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [[field]]: selectedOptions });
    }

    handleChangeSelectCustomer(selectedOptions) {
        this.setState({ 'customer_id': selectedOptions.value, default_customer_option: selectedOptions });
    }

    handleChangeFilterDate(_date) {
        this.setState({ deploy_date: _date });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập Tên";
        }
        if (helps.isEmpty(this.state.province_id)) {
            formIsValid = false;
            errors["customer_id"] = "Vui lòng chọn Khách Hàng";
        }
        if (helps.isEmpty(this.state.province_id)) {
            formIsValid = false;
            errors["province_id"] = "Vui lòng chọn Tỉnh thành/Khu Vực";
        }
        this.setState({ errors: errors });

        return formIsValid;
    }


    render() {
        const { provinces } = this.props;

        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader>
                                {this.props.form_header}
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-name">Tên Mục Tiêu:</CLabel>
                                            <CInput
                                                type="text"
                                                name="name"
                                                id="input-name"
                                                value={this.state.name}
                                                required
                                                className='font-weight-bold'
                                                onChange={(e) => { this.handleChange('name', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['name']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-code">Ký Hiệu / Mã Số:</CLabel>
                                            <CInput
                                                type="text"
                                                name="code"
                                                id="input-code"
                                                value={this.state.code}
                                                className='font-weight-bold'
                                                onChange={(e) => { this.handleChange('code', e) }}
                                            />
                                            <CInvalidFeedback className="help-block">{this.state.errors['code']}</CInvalidFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-customer_id">Khách Hàng:</CLabel>
                                            <Select
                                                id="input-customer_id"
                                                options={this.state.customer_options}
                                                value={this.state.default_customer_option}
                                                onChange={(choice) => { this.handleChangeSelectCustomer(choice) }}
                                            />
                                            <small className="text-danger">{this.state.errors['customer_id']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-joint_date">Ngày Triển Khai:</CLabel>
                                            <DateTimePicker
                                                value={this.state.deploy_date}
                                                className='ml-2'
                                                format={'dd/MM/yyyy HH:mm'}
                                                onChange={(value) => { this.handleChangeTime(value, 'deploy_date') }}
                                            />
                                            <small className="text-danger">{this.state.errors['deploy_date']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-address">Địa chỉ:</CLabel>
                                            <CTextarea
                                                type="text"
                                                name="address"
                                                id="input-address"
                                                value={this.state.address}
                                                onChange={(e) => { this.handleChange('address', e) }}
                                            />
                                            <CInvalidFeedback className="help-block">{this.state.errors['address']}</CInvalidFeedback>
                                        </div>
                                    </CCol>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CFormGroup variant='custom-checkbox' inline>
                                                <CInputCheckbox
                                                    custom
                                                    size={'lg'}
                                                    id='input-active'
                                                    name='active'
                                                    value={1}
                                                    checked={this.state.active === 1}
                                                    onChange={(e) => { this.handleChange('active', e) }}
                                                />
                                                <CLabel htmlFor="input-active" variant='custom-checkbox'>Hoạt Động ?</CLabel>
                                                <small className="text-danger">{this.state.errors['active']}</small>
                                            </CFormGroup>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-assigns">Phụ trách Theo dõi, Giám sát:</CLabel>
                                            <Select
                                                id="input-assigns"
                                                placeholder="Nhân sự ..."
                                                options={this.state.assign_options}
                                                value={this.state.assigns}
                                                isMulti
                                                onChange={(choice) => { this.handleChangeSelect(choice, 'assigns') }}
                                            />
                                            <small className="text-danger">{this.state.errors['assigns']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-province_id">Tỉnh Thành / Khu Vực:</CLabel>
                                            <CSelect
                                                name="province_id"
                                                id="input-province_id"
                                                value={this.state.province_id}
                                                onChange={(e) => { this.handleChange('province_id', e) }}
                                                required
                                            >
                                                {provinces && provinces.map((item) => {
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    );
                                                })}
                                            </CSelect>
                                            <small className="text-danger">{this.state.errors['province_id']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <div>
                                                <CLabel>Màu Biểu Trưng:</CLabel>
                                            </div>
                                            {configs.colors && Object.keys(configs.colors).map((_key) => {
                                                return (
                                                    <CFormGroup variant="custom-radio" key={_key} inline>
                                                        <CInputRadio
                                                            custom
                                                            id={"color_" + _key}
                                                            name="color"
                                                            value={_key}
                                                            checked={this.state.color === _key}
                                                            onChange={(e) => { this.handleChange('color', e) }}
                                                        />
                                                        <CLabel variant="custom-checkbox" htmlFor={"color_" + _key} className="cursor-pointer">
                                                            <CBadge color={_key} className="p-2">{configs.colors[_key]}</CBadge>
                                                        </CLabel>
                                                    </CFormGroup>
                                                )
                                            }
                                            )}

                                        </div>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default ProjectForm;

