import React from 'react';
import { connect } from 'react-redux';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {
  CHeader,
  CToggler,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CButton,
  CTooltip,
  CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider,
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import { ThePermission } from './ThePermission';
import { generalActions } from '../_actions/general.actions';
import { noteActions, notificationActions, taskActions } from 'src/_actions';

// routes config
import routes from '../routes'

import {
  TheHeaderDropdown,
  TheHeaderDropdownMssg,
  TheHeaderDropdownNotif,
  TheHeaderDropdownTasks
} from './index';

import { subNav } from './_subnav';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';

class TheHeader extends React.Component {

  constructor(props) {
    super(props);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.toggleSidebarMobile = this.toggleSidebarMobile.bind(this);
  }

  componentDidMount() {
    this.getLatestNotes();
    this.getTasks();
    this.getNotifications();
  }

  toggleSidebar = () => {
    const sidebarShow = this.props.sidebarShow;
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive';
    generalActions.toggleSidebar(val);
  }

  toggleSidebarMobile = () => {
    const sidebarShow = this.props.sidebarShow;
    const val = [true, 'mobile'].includes(sidebarShow) ? false : 'mobile'
    console.log(val)
    generalActions.toggleSidebar(val);
  }

  renderSubNav() {

    var path = this.props.history.location.pathname;
    var res = path.split("/");

    let parent_path = res[1];
    let child_path = res[2];
    let isChild = false;
    let curSubNav = {};
    if (child_path && subNav.hasOwnProperty(child_path)) {
      curSubNav = subNav[child_path];
      isChild = true;
    } else if (parent_path && subNav.hasOwnProperty(parent_path)) {
      curSubNav = subNav[parent_path];
    }

    if (curSubNav) {
      return (
        Object.keys(curSubNav).map((_nav) => {
          let _link = curSubNav[_nav].link;
          return (
            <span key={'subnav-' + _nav}>
              <CTooltip content={curSubNav[_nav].title}>
                <CButton color={curSubNav[_nav].color} onClick={() => { this.props.history.push(_link) }} title={curSubNav[_nav].title} className="mr-2" size="sm">
                  <CIcon name={curSubNav[_nav].icon} className="mfe-1 mt-0"></CIcon>
                  {curSubNav[_nav].title}
                </CButton>
              </CTooltip>
            </span>
          )
        })
      )
    } else {
      return (
        <>
        </>
      );
    }
  }

  async getNotifications() {
    const { user } = this.props;
    return await this.props.getNotifications(user, false, 100);
  }

  async getLatestNotes() {
    await this.props.getLatestNotes('', '', configs.per_page);
  }

  async getTasks() {
    const { tasks, user } = this.props;
    if (helps.isEmpty(tasks)) {
      let tasks = await this.props.getTasks(user);
      return tasks;
    }
  }

  render() {
    //console.log(this.props.roles);

    return (
      <CHeader fixed={isMobile ? false : true} withSubheader>
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none"
          onClick={() => { this.toggleSidebarMobile(); }}
        />
        <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={this.props.toggleSidebar}
        />
        <CHeaderNav className="mr-auto navbar">

          <CHeaderNavItem className="px-3" >
            <CHeaderNavLink to="/dashboard" title='Sảnh'>Sảnh</CHeaderNavLink>
          </CHeaderNavItem>

          <ThePermission {... this.props} component="employees" action="view">
            <CHeaderNavItem className="px-1 py-1 rounded border-warning bg-warning ">
              <CHeaderNavLink to="/checks" className='text-white' title='Quản Lý Checkin-Checkout'>Checkin-Checkout</CHeaderNavLink>
            </CHeaderNavItem>
          </ThePermission>

          <CHeaderNavItem className="px-3">
            <CHeaderNavLink to="/tasks" title='Công Việc'>Công Việc</CHeaderNavLink>
          </CHeaderNavItem>

          <ThePermission {... this.props} component="accounting" action="view">
            <CDropdown variant="nav-item" className='mr-2'>
              <CDropdownToggle title='HC-KT'>HC-KT</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem to="/accounting/books" title='Thu / Chi'><CIcon name="cil-money" className="mfe-2" /> Thu / Chi</CDropdownItem>
                <CDropdownItem to="/accounting/timekeeping" title='Bảng Chấm Công'><CIcon name="cil-Calendar-check" className="mfe-2" /> Bảng Chấm Công</CDropdownItem>
                <CDropdownItem to="/accounting/salary" title='Bảng Lương'><CIcon name="cil-calculator" className="mfe-2" /> Bảng Lương</CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem to="/assets" title='Quản Lý Tài Sản'><CIcon name="cil-bank" className="mfe-2" /> Quản Lý Tài Sản</CDropdownItem>
                <CDropdownItem to="/assets/items" title='Quản Lý Danh Mục Tài Sản'><CIcon name="cil-list" className="mfe-2" /> Quản Lý Danh Mục Tài Sản</CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem to="/vendors" title='Quản Lý Nhà Cung Cấp'><CIcon name="cil-factory" className="mfe-2" /> Quản Lý Nhà Cung Cấp</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </ThePermission>

          <ThePermission {... this.props} component="customers" action="view">
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/customers" title='Quản Lý Khách Hàng'>Khách Hàng</CHeaderNavLink>
            </CHeaderNavItem>
          </ThePermission>

          <ThePermission {... this.props} component="projects" action="view">
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/projects" title='Quản Lý Mục Tiêu'>Mục Tiêu</CHeaderNavLink>
            </CHeaderNavItem>
          </ThePermission>

          <ThePermission {... this.props} component="housings" action="view">
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/housings" title='Quản Lý Nhà Đội'>Nhà Đội</CHeaderNavLink>
            </CHeaderNavItem>
          </ThePermission>

          <ThePermission {... this.props} component="employees" action="view">
            <CDropdown variant="nav-item" className='mr-2'>
              <CDropdownToggle title='Quản Lý Nhân Sự'>Nhân Sự</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem to="/employees/candidates" title='Quản Lý Ứng Viên'><CIcon name="cil-people" className="mfe-2" /> Quản Lý Ứng Viên</CDropdownItem>
                <CDropdownItem to="/employees" title='Quản Lý Nhân Viên'><CIcon name="cil-list" className="mfe-2" /> Quản Lý Nhân Viên</CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem to="/employees/violations" title='Quản Lý Vi Phạm'><CIcon name="cil-list" className="mfe-2" /> Danh Sách Vi Phạm</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </ThePermission>

          <ThePermission {... this.props} component="users" action="view">
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/users" title='Quản Lý Người Dùng'>Người Dùng</CHeaderNavLink>
            </CHeaderNavItem>
          </ThePermission>

          <CHeaderNavItem className="px-3">
            <CHeaderNavLink to="/documents" title='Quản Lý Tài Liệu'>Tài Liệu</CHeaderNavLink>
          </CHeaderNavItem>

          <ThePermission {... this.props} component="settings" action="view">
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/settings" title='Quản Lý Hệ Thống'>Hệ Thống</CHeaderNavLink>
            </CHeaderNavItem>
          </ThePermission>

        </CHeaderNav>

        <CHeaderNav className="px-3">
          <TheHeaderDropdownNotif {... this.props} />
          <TheHeaderDropdownMssg {... this.props} latest_notes={this.props.latest_notes} />
          <TheHeaderDropdownTasks {... this.props} />
          <TheHeaderDropdown />
        </CHeaderNav>

        <CSubheader className="px-3 justify-content-between">
          <CBreadcrumbRouter
            className="border-0 c-subheader-nav m-0 px-0 px-md-3"
            routes={routes}
          />
          <div className="d-md-down-none mfe-2 c-subheader-nav">
            {this.renderSubNav()}
          </div>
        </CSubheader>
      </CHeader>
    )
  }
}

function mapStateToProps(state) {
  const { location, sidebarShow } = state;
  const { user } = state.authentication;
  const { roles } = state.rolesReducer;
  const { latest_notes } = state.noteReducer;
  const { tasks } = state.taskReducer;
  const { notifications } = state.notificationReducer;
  return { user, location, sidebarShow, roles, latest_notes, tasks, notifications };
}

const actionCreators = {
  toggleSidebar: generalActions.toggleSidebar,
  getPermission: generalActions.getPermission,
  getLatestNotes: noteActions.getLatest,
  getTasks: taskActions.getAll,
  getNotifications: notificationActions.getAll,
  _read: notificationActions._read,
};

export default connect(mapStateToProps, actionCreators)(TheHeader);

//export default TheHeader;
