import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThePermission } from 'src/containers/ThePermission';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import { violationActions } from 'src/_actions';
import ViolationSettings from 'src/views/employees/violations/ViolationSettings';
import { CCard, CCardBody, CCardFooter, CCardHeader } from '@coreui/react';

class ViolationSettingsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.getTypes();
    }

    async getTypes() {
        let violation_types = await this.props.getAllTypes();
        return violation_types;
    }

    render() {
        if (this.props.loading_type) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <CCard>
                <CCardHeader>
                    Cài Đặt Chế Tài Vi Phạm
                </CCardHeader>
                <CCardBody>
                    <ThePermission {... this.props} component="employees" action="edit">
                        <ViolationSettings {... this.props} />
                    </ThePermission>
                </CCardBody>
                <CCardFooter>

                </CCardFooter>
            </CCard>
        );
    }
}

function mapStateToProps(state) {
    const { loading_type, violation_types } = state.violationReducer;
    const { user } = state.authentication;
    return { loading_type, user, violation_types };
}

const actionCreators = {
    getAllTypes: violationActions.getAllTypes,
    addType: violationActions.addType,
    updateType: violationActions.updateType,
    _deleteType: violationActions._deleteType,
    addTerm: violationActions.addTerm,
    updateTerm: violationActions.updateTerm,
    _deleteTerm: violationActions._deleteTerm,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ViolationSettingsContainer);
export { connectedContainer as ViolationSettingsContainer };