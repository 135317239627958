import { itemConstants } from "src/_constants";

export function itemReducer(state = { items: [] }, actions) {
    switch (actions.type) {

        // get all item
        case itemConstants.GETALL_REQUEST:
            return {
                loading: true,
            }
        case itemConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: actions.items,
            }
        case itemConstants.GETALL_FAILURE:
            return {
                ...state,
                error: actions.error
            }

        // get a item by ID    
        case itemConstants.GET_REQUEST:
            return {
                loading: true
            }
        case itemConstants.GET_SUCCESS:
            return {
                ...state,
                item: actions.item,
                loading: false
            }
        case itemConstants.GET_FAILURE:
            return {
                error: actions.error
            };
        // add new item    
        case itemConstants.ADD_REQUEST:
            return {
                loading: true
            }
        case itemConstants.ADD_SUCCESS:
            var items = state.items ? state.items : [];
            items.push(actions.item);
            return {
                ...state,
                items: items,
                loading: false
            }
        case itemConstants.ADD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update item    
        case itemConstants.UPDATE_REQUEST:
            return {
                ...state,
                //loading: true
            }
        case itemConstants.UPDATE_SUCCESS:
            var _items = state.items;
            var foundIndex = _items.findIndex(item => item.id === actions.item.id);
            actions.item['index'] = foundIndex;
            _items[foundIndex] = actions.item;
            console.log(foundIndex);
            return {
                ...state,
                //loading: false,
                items: _items,
                item: actions.item,
            }
        case itemConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update item field    
        case itemConstants.UPDATE_FIELD_REQUEST:
            return {
                ...state,
                loading: false
            }
        case itemConstants.UPDATE_FIELD_SUCCESS:
            var _items = state.items;
            var foundIndex = _items.findIndex(item => item.id === actions.item.id);
            actions.item['index'] = foundIndex;
            _items[foundIndex] = actions.item;
            console.log(foundIndex);
            return {
                ...state,
                loading: false,
                items: _items,
                item: actions.item,
            }
        case itemConstants.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        default:
            return state;
    }
}