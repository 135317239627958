import { userConstants } from '../_constants';

export function userReducer(state = {}, action) {
  switch (action.type) {
    case userConstants.GETUSER_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETUSER_SUCCESS:
      return {
        loading: false,
        user: action.user
      };
    case userConstants.GETUSER_FAILURE:
      return {
        error: action.error
      }
    case userConstants.ADDUSER_REQUEST:
      return { adding: true };
    case userConstants.ADDUSER_SUCCESS:
      return {};
    case userConstants.ADDUSER_FAILURE:
      return {};
    case userConstants.UPDATEUSER_REQUEST:
      return { updating: true };
    case userConstants.UPDATEUSER_SUCCESS:
      return {
        loading: false,
        user: action.user
      };
    case userConstants.UPDATEUSER_FAILURE:
      return {
        loading: false,
        user: action.user
      };
      
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        loading: false,
        users: action.users
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        users: state.users.filter(user => user.id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        users: state.users.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    default:
      return state
  }
}