import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { generalActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import { CCol, CContainer, CRow } from '@coreui/react';
import Approvals from 'src/views/settings/Approvals';
import ApprovalsForm from 'src/views/settings/ApprovalsForm';


class ApprovalsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            approval: {},
            approval_id: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount() {
        this.doGetApprovals();
    }

    async doGetApprovals() {
        try {
            const approvals = await this.props.getApprovals();
            this.setState({ approvals: approvals });
            return approvals;
        } catch (error) {
            console.log(error);
        }
    }

    handleSubmit(approval) {
        let approvals = this.props.approvals;
        let _approval = approval;

        if (!helps.isEmpty(approval.id)) {
            _approval = this.props.updateApproval(approval);
            approvals.push(_approval);
        } else {
            _approval = this.props.addApproval(approval);
        }
        this.setState({ approval: _approval, approvals: approvals });
    }

    handleEdit(approval) {
        console.log(approval)
        this.setState({ approval: approval, approval_id: approval.id });
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        return (
            <CRow>
                <CCol md={8}>
                    <ThePermission {... this.props} component="settings" action="view">
                        {!helps.isEmpty(this.props.approvals) &&
                            <Approvals
                                key={this.props.approvals.length}
                                {... this.props}
                                handleEdit={this.handleEdit}
                            />
                        }
                    </ThePermission>

                </CCol>
                <CCol md={4}>
                    <ThePermission {... this.props} component="settings" action="edit">
                        <CContainer fluid>
                            <ApprovalsForm
                                key={Date.now()}
                                {... this.props}
                                approval={this.state.approval}
                                handleSubmit={this.handleSubmit}
                            />
                        </CContainer>
                    </ThePermission>
                </CCol>
            </CRow>
        );
    }
}

function mapStateToProps(state) {
    const { loading, approvals } = state.generalReducer;
    return { loading, approvals };
}

const actionCreators = {
    getApprovals: generalActions.getApprovals,
    addApproval: generalActions.addApproval,
    updateApproval: generalActions.updateApproval,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ApprovalsContainer);
export { connectedContainer as ApprovalsContainer };