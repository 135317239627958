import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThePermission } from '../ThePermission';
import { documentActions } from 'src/_actions';
import Documents from 'src/views/documents/Documents';
import { CCard, CCardBody, CCardFooter, CCardHeader } from '@coreui/react';

class DocumentsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            path: 'documents',
        }
        this.handleChangePath = this.handleChangePath.bind(this);
    }

    componentDidMount() {
        this.getDocuments(this.state.path);
    }

    handleChangePath(path) {
        console.log(path);
        this.setState({ path: path });
        this.getDocuments(path);
    }

    async getDocuments(path = '') {
        return await this.props.getAll(path);
    }

    render() {
        return (
            <ThePermission {... this.props} component="documents" action="view">
                <CCard>
                    <CCardHeader>
                        Quản Lý Tài Liệu
                    </CCardHeader>
                    <CCardBody>
                        <Documents {... this.props} handleChangePath={(path) => { this.handleChangePath(path) }} path={this.state.path} />
                    </CCardBody>
                    <CCardFooter>

                    </CCardFooter>
                </CCard>
            </ThePermission>
        );
    }
}

function mapStateToProps(state) {
    const { loading, upload_loading, documents, folders } = state.documentReducer;
    const { user } = state.authentication;
    return { loading, upload_loading, user, documents, folders };
}

const actionCreators = {
    getAll: documentActions.getAll,
    upload: documentActions.upload,
    _delete: documentActions._delete,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(DocumentsContainer);
export { connectedContainer as DocumentsContainer };