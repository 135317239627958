import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CTooltip,
    CButton,
    CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';

class TaskStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            is_assigned: false,
        }

    }

    componentDidMount() {
        const { user, task } = this.props;
        if (!helps.isEmpty(task) && !helps.isEmpty(task.assigns)) {
            let found_me = helps.getItemFromArr(task.assigns, user.id, 'value');
            if (!helps.isEmpty(found_me)) {
                this.setState({ is_assigned: true });
            }
        }
    }

    doApprove() {
        const { task } = this.props;
        task['assign_status'] = 'approved';
        this.props.update(task);
    }

    doFinish() {
        const { task } = this.props;
        task['status'] = 'finished';
        this.props.update(task);
    }

    doUnFinish() {
        const { task } = this.props;
        task['status'] = 'unfinished';
        this.props.update(task);
    }

    render() {
        const { task, user } = this.props;
        var is_author = false;
        if (user.id === task.added_by) {
            is_author = true;
        }
        var is_dofinish_auth = false;
        if (this.state.is_assigned && task && task.assign_status === 'approved' && task.status === 'unfinished') {
            is_dofinish_auth = true;
        }
        if (is_author && task && task.assign_status === 'approved' && task.status === 'unfinished') {
            is_dofinish_auth = true;
        }
        var is_dounfinish_auth = false;
        if (this.state.is_assigned && task && task.assign_status === 'approved' && task.status === 'finished') {
            is_dounfinish_auth = true;
        }
        if (is_author && task && task.assign_status === 'approved' && task.status === 'finished') {
            is_dounfinish_auth = true;
        }
        var assign_status_color = 'danger';
        if (task.assign_status === 'approved') {
            assign_status_color = 'primary'
        }
        var status_color = 'danger';
        if (task.status === 'finished') {
            status_color = 'success';
        }
        return (
            <CRow>
                <CCol xl={12}>
                    <div className='bt-2'>
                        <CBadge color={assign_status_color}>{configs.task_assign_statuses[task.assign_status]}</CBadge> - <CBadge color={status_color}>{configs.task_statuses[task.status]}</CBadge>
                    </div>
                    {this.state.is_assigned && task && task.assign_status === 'unapproved' &&
                        <CTooltip content="Nhận">
                            <CButton
                                size="sm"
                                className="btn btn-primary btn-sm mr-2 mb-2"
                                onClick={() => { this.doApprove() }}
                            >
                                <CIcon name="cil-task" className="mfe-1 mt-0"></CIcon>
                                Nhận
                            </CButton>
                        </CTooltip>
                    }
                    {is_dofinish_auth &&
                        < CTooltip content="Đã hoàn thành công việc">
                            <CButton
                                size="sm"
                                className="btn btn-success btn-sm mt-1"
                                onClick={() => { this.doFinish() }}
                            >
                                <CIcon name="cil-task" className="mfe-1 mt-0"></CIcon>
                                Hoàn Thành
                            </CButton>
                        </CTooltip>
                    }
                    {is_dounfinish_auth &&
                        < CTooltip content="Chưa hoàn thành công việc">
                            <CButton
                                size="sm"
                                className="btn btn-warning btn-sm mt-1"
                                onClick={() => { this.doUnFinish() }}
                            >
                                <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                Chưa Hoàn Thành
                            </CButton>
                        </CTooltip>
                    }
                </CCol>
            </CRow >
        )
    }
}
export default TaskStatus;
