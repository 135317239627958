import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { helps } from 'src/_helpers';
import { bookActions, taxonomyActions } from 'src/_actions';
import BookOutForm from 'src/views/accounting/BookOutForm';

class BookOutFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
    }

    async getBooks() {
        const books = this.props.books;
        if (helps.isEmpty(books)) await this.props.getAll();
    }

    handleSubmit = async (book) => {
        const { taxonomies, books } = this.props;
        let taxonomy = helps.getItemFromArr(taxonomies, book.book_account);
        let taxonomy_stock = taxonomy.stock ? parseInt(taxonomy.stock) : 0;


        if (helps.isEmpty(book.id)) {
            let updated_stock = parseInt(taxonomy_stock) - parseInt(book.amount);
            let prev_book = await this.getPrevBook(book.book_date);
            let prev_stock = (prev_book && prev_book.stock) ? parseInt(prev_book.stock) : 0;

            if (!book.approval_required) {
                book['stock'] = prev_stock - parseInt(book.amount);
                taxonomy['stock'] = updated_stock;
                this.props.updateTaxonomyField(taxonomy, 'stock');
            } else {
                book['stock'] = prev_stock;
            }

            await this.props.add(book);
        } else {
            if (!book.approval_required || (book.approval_required && !book.approved)) {
                let prev_state_book = helps.getItemFromArr(books, book.id);
                let prev_state_amount = parseInt(prev_state_book.amount);
                let diff_amount = parseInt(book.amount) - prev_state_amount;
                let updated_stock = parseInt(prev_state_book.stock) - parseInt(diff_amount);
                let updated_taxonomy_stock = parseInt(taxonomy_stock) - parseInt(diff_amount);

                this.props.update(book);

                if (diff_amount !== 0) {
                    book['stock'] = updated_stock;
                    this.props.updateField(book, 'stock');

                    taxonomy['stock'] = updated_taxonomy_stock;
                    this.props.updateTaxonomyField(taxonomy, 'stock');

                    this.updateStocks(books, book);
                }
            }
        }
        this.props.history.push('/accounting/books#out');
    }

    async getPrevBook(book_date) {
        const { books } = this.props;
        let prev_book = {};
        if (helps.isEmpty(books) || books.length === 0) {
            let prev_month = new Date(book_date);
            prev_month = prev_month.setDate(0);
            let prev_books = this.props.getAll({ curent_date: prev_month });
            if (!helps.isEmpty(prev_books) && Array.isArray(prev_books)) {
                prev_book = prev_books.reduce(function (prev, current) {
                    return (prev && prev.count > current.count) ? prev : current
                });
            } else return {};
        } else {
            let sorted_books = books.sort(function (a, b) {
                let b_count = b.count ? b.count : 0;
                let a_count = a.count ? a.count : 0;
                return a_count - b_count;
            });
            prev_book = sorted_books.reduce(function (prev, current) {
                return (prev && prev.count > current.count) ? prev : current
            });
        }
        return prev_book;
    }

    async updateStocks(books, book) {
        let sorted_books = books.sort(function (a, b) {
            let b_count = b.count ? b.count : 0;
            let a_count = a.count ? a.count : 0;
            return a_count - b_count;
        });
        let need_updated_books = sorted_books.filter(_book => _book['count'] >= book['count']);
        await Promise.all(
            need_updated_books.map(async (book, index) => {
                let amount = parseInt(book.amount);

                let prev_book = sorted_books[index - 1];
                let prev_stock = (prev_book && prev_book.stock) ? parseInt(prev_book.stock) : 0;

                if (book.type === 'out') {
                    book['stock'] = prev_stock - amount;
                } else if (book.type === 'in') {
                    book['stock'] = prev_stock + amount;
                }
                this.props.updateField(book, 'stock');
            })
        );
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <ThePermission {... this.props} component="accounting" action="edit">
                <BookOutForm
                    {... this.props}
                    book={this.props.book}
                    handleSubmit={this.handleSubmit}
                />
            </ThePermission>
        );
    }
}
function mapStateToProps(state) {
    const { loading, books } = state.bookReducer;
    return { loading, books };
}

const actionCreators = {
    getAll: bookActions.getAll,
    getCountBooks: bookActions.getCountBooks,
    add: bookActions.add,
    update: bookActions.update,
    updateTaxonomyField: taxonomyActions.updateField,
};

const connectedFormContainer = connect(mapStateToProps, actionCreators)(BookOutFormContainer);
export { connectedFormContainer as BookOutFormContainer };