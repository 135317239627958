import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fileActions } from 'src/_actions';
import Files from 'src/views/files/Files';

class FilesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            object: '',
            object_id: '',
        }
    }

    componentDidMount() {
        this.setState({ object: this.props.object, object_id: this.props.object_id });
        this.getFiles();

    }

    async getFiles() {
        return await this.props.getAll(this.props.object, this.props.object_id);
    }

    render() {
        return (
            <Files key={this.props.object + '-' + this.props.object_id} {... this.props} files={this.props.files} user={this.props.user} />
        );
    }
}

function mapStateToProps(state) {
    const { loading, upload_loading, files } = state.fileReducer;
    const { user } = state.authentication;
    return { loading, upload_loading, user, files };
}

const actionCreators = {
    getAll: fileActions.getAll,
    uploadFile: fileActions.uploadFile,
    deleteFile: fileActions.deleteFile,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(FilesContainer);
export { connectedContainer as FilesContainer };