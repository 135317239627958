import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CCardFooter,
} from '@coreui/react';
import { ThePermission } from '../ThePermission';
import Stores from 'src/views/assets/Stores';
import { storeActions, noteActions, userActions, provinceActions } from 'src/_actions';
import { helps } from 'src/_helpers';

class StoresContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_header: 'Quản Lý Danh Sách Kho',
        }
    }

    componentDidMount() {
        if (helps.isEmpty(this.props.notes)) {
            this.props.getNotes('stores');
        }

        if (helps.isEmpty(this.props.users)) {
            this.props.getUsers();
        }

        this.props.getAll();
    }

    render() {
        return (
            <ThePermission {... this.props} component="assets" action="view" alertOut={true}>
                <CRow key={helps.convertToSlug(this.state.page_header)}>
                    <CCol xl={12}>
                        <CCard>
                            <CCardHeader>
                                <CRow>
                                    <CCol>
                                        {this.state.page_header}
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCardBody className='stores-table'>
                                <Stores {... this.props} />
                            </CCardBody >
                            <CCardFooter>
                                Tổng: <b>{this.props.stores && <>{this.props.stores.length}</>}</b>
                            </CCardFooter>
                        </CCard >
                    </CCol >
                </CRow >
            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { loading, stores } = state.storeReducer;
    const { provinces } = state.provinceReducer;
    const { users } = state.userReducer;
    const { notes } = state.noteReducer;
    return { loading, stores, provinces, users, notes };
}

const actionCreators = {
    getAll: storeActions.getAll,
    getProvinces: provinceActions.getAll,
    getNotes: noteActions.getAll,
    getUsers: userActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(StoresContainer);
export { connectedContainer as StoresContainer };