import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTooltip,
    CButton,
    CLabel,
    CFormGroup,
    CInputCheckbox,
    CCollapse,

} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Hint from 'src/_components/Hint';
import { AssetsComponentContainer } from 'src/containers/assets/AssetsComponentContainer';

class ExitHandover extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            sh_s2: true,
            sh_assets: false,
        }
    }


    componentDidMount() {

    }

    render() {
        const { employee, user, exit } = this.props;

        return (
            <CRow>
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader style={{ backgroundColor: '#EEE' }}>
                            <CRow>
                                <CCol>
                                    II. CÔNG TÁC BÀN GIAO / THU HỒI
                                    {this.state.errors['handover'] && <div className='mb-1 mt-1'><small className="text-danger">// {this.state.errors['handover']}</small></div>}
                                </CCol>
                                <CCol className={'text-right'}>
                                    <CTooltip content={this.state.sh_s2 ? 'Ẩn' : 'Hiện'}>
                                        <CButton type="button" color="secondary" size="sm" onClick={() => { this.setState({ sh_s2: !this.state.sh_s2 }) }} title={this.state.sh_s2 ? 'Ẩn' : 'Hiện'} className='ml-2'>
                                            <CIcon name={this.state.sh_s2 ? 'cil-chevron-top' : 'cil-chevron-bottom'} className="mfe-1 mt-0"></CIcon>
                                        </CButton>
                                    </CTooltip>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCollapse show={this.state.sh_s2}>
                            <CCardBody>
                                <CRow>
                                    <CCol>
                                        <div className='mb-2'>
                                            <CFormGroup variant='custom-checkbox' inline>
                                                <CInputCheckbox
                                                    size={'lg'}
                                                    id='input-work_handovered'
                                                    name='work_handovered'
                                                    value={1}
                                                    className={'cursor-pointer'}
                                                    checked={exit.handover.work_handovered}
                                                    onChange={(e) => { this.props.handleCheckboxTF('work_handovered', 'handover') }}
                                                    disabled={exit.exit_completed}
                                                />
                                                <CLabel htmlFor="input-work_handovered">Đã bàn giao công việc việc<Hint content={'P.NV / Quản lý trực tiếp'} /></CLabel>
                                                <small className="text-danger">{this.state.errors['work_handovered']}</small>
                                            </CFormGroup>
                                        </div>
                                        <div className='mb-2'>
                                            <CFormGroup variant='custom-checkbox' inline>
                                                <CInputCheckbox
                                                    size={'lg'}
                                                    id='input-assets_handovered'
                                                    name='assets_handovered'
                                                    value={1}
                                                    className={'cursor-pointer'}
                                                    checked={exit.handover.assets_handovered}
                                                    onChange={(e) => { this.props.handleCheckboxTF('assets_handovered', 'handover') }}
                                                    disabled={exit.exit_completed}
                                                />
                                                <CLabel htmlFor="input-assets_handovered">Đã thu hồi QTT, DCHT, CCHT<Hint content={'P.HC-KT'} /></CLabel>
                                                <small className="text-danger">{this.state.errors['assets_handovered']}</small>
                                            </CFormGroup>
                                        </div>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol>
                                        <b>Danh sách Bàn giao/Thu hồi của nhân sự:</b>
                                        <CTooltip content={this.state.sh_assets ? 'Ẩn' : 'Hiện'}>
                                            <CButton type="button" size="sm" onClick={() => { this.setState({ sh_assets: !this.state.sh_assets }) }} title={this.state.sh_assets ? 'Ẩn' : 'Hiện'} className='ml-2'>
                                                <CIcon name={this.state.sh_assets ? 'cil-chevron-top' : 'cil-chevron-bottom'} className="mfe-1 mt-0"></CIcon>
                                            </CButton>
                                        </CTooltip>
                                    </CCol>
                                </CRow>
                                <CCollapse show={this.state.sh_assets}>
                                    <CRow>
                                        <CCol>
                                            <AssetsComponentContainer {... this.props} object='employee' object_data={employee} />
                                        </CCol>
                                    </CRow>
                                </CCollapse>

                            </CCardBody>
                        </CCollapse>
                    </CCard>
                </CCol>
            </CRow >
        )
    }
}
export default ExitHandover;

