import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CInput,
    CFormText,

} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';

class ProvinceForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            fields: {},
            form_header: 'Thêm Mới',
            id: '',
            name: '',
            code: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDatePicker = this.handleChangeDatePicker.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        const { province } = this.props;
        if (!helps.isEmpty(province)) {
            this.setState({
                form_header: 'Cập Nhật',
                id: province.id,
                store: province.store,
                name: province.name,
                code: province.code,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        let province = {
            id: this.state.id,
            name: this.state.name,
            code: this.state.code,
        }

        if (this.handleValidation()) {
            this.props.handleSubmit(province);
            if (!helps.isEmpty(province.id)) {
                this.props.toggleEditForms();
            } else {
                this.props.handleCancelAddForm();
            }

        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeDatePicker(_date) {
        console.log(_date);
        this.setState({ exp_date: _date });
    }

    handleCancel(e) {
        e.preventDefault();
        if (helps.isEmpty(this.state.id)) {
            this.props.handleCancelAddForm();
        } else {
            this.props.toggleEditForms();
        }

    }

    handleValidation() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(fields["name"])) {
            formIsValid = false;
            errors["name"] = "Điền tên Tỉnh thành/Khu vực.";
        }
        if (helps.isEmpty(fields["code"])) {
            formIsValid = false;
            errors["code"] = "Điền tên viết tắt.";
        }
        this.setState({ errors: errors });
        console.log(errors);
        return formIsValid;
    }


    render() {
        const { province } = this.props;
        var form_id = '';
        if (helps.isEmpty(this.state.id)) {
            form_id = "add-new-from";
        } else {
            form_id = "edit-" + this.state.id;
        }

        return (
            <CRow>
                <CCol xl={12}>
                    <CForm
                        id={form_id} onSubmit={(e) =>
                            this.handleSubmit(e)}
                    >
                        <CCard>
                            <CCardHeader>
                                {this.state.form_header}
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={4}>
                                        <CLabel htmlFor="input-name">Tên:</CLabel>
                                        <CInput
                                            type="text"
                                            name="name"
                                            size="sm"
                                            id="input-name"
                                            defaultValue={this.state.name}
                                            onChange={(e) => { this.handleChange('name', e) }}
                                        />
                                        {(!helps.isEmpty(this.state.errors['name'])) && <div className="help-block text-danger">{this.state.errors['name']}</div>}
                                    </CCol>
                                    <CCol md={3}>
                                        <CLabel htmlFor="input-code">Viết Tắt: </CLabel>
                                        <CInput
                                            type="text"
                                            name="code"
                                            id="input-code"
                                            size="sm"
                                            value={this.state.code}
                                            onChange={(e) => { this.handleChange('code', e) }}
                                        />
                                        <CFormText className="help-block">VD: <i className="text-dark font-weight-bold">HN, HCM, ...</i></CFormText>
                                        {(!helps.isEmpty(this.state.errors['code'])) && <div className="help-block text-danger">{this.state.errors['code']}</div>}
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" color="primary" title="Lưu" size="sm">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                                <CButton type="button" color="light" className="ml-2" title="Huỷ" size="sm" onClick={(e) => { this.handleCancel(e) }}>
                                    <CIcon name="cil-x-circle" className="mfe-1 mt-0 "></CIcon>
                                    Huỷ
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow>
        )
    }
}
export default ProvinceForm;

