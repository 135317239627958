import React, { Component } from 'react'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import configs from 'src/appConfigs';


class EmployeeAccount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hasAcount: false,
            user: {},
        }

    }

    componentDidMount() {
        this.checkUserAccount();
    }

    checkUserAccount() {
        const { employee, users } = this.props;
        let existing_user = users.filter((_user) => { return _user.employee_id == employee.employee_id });
        //console.log(existing_user)
        if (helps.isEmpty(existing_user)) {
            var user = {};
            user['fullname'] = employee.fullname;
            user['username'] = helps.generateUsername(employee.fullname, employee.phone);
            user['password'] = helps.generatePassword();
            user['group'] = configs.app_name;
            user['role'] = 'staff';
            user['employee_id'] = employee.employee_id;
            this.props.addUser(user);
            this.setState({ user: user });
        }
        else {
            this.setState({ user: existing_user[0] });
        }
    }

    render() {
        return (
            <CRow>
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader>
                            Tài Khoản Đăng Nhập
                        </CCardHeader>
                        <CCardBody>
                            <CRow className="mb-2">
                                <CCol>
                                    Tên TK: <b>{this.state.user.username}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-2">
                                <CCol>
                                    Mật khẩu: <b>{this.state.user.password}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-2">
                                <CCol>
                                    Đăng nhập gần đây: <b>{this.state.user.last_logged && <>{helps.formatDateTime_from_Timestamp(this.state.user.last_logged)}</>}</b>
                                    {!this.state.user.last_logged && <span className='text-muted font-italic'>Chưa đăng nhập lần nào!</span>}
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        )
    }
}
export default EmployeeAccount;
