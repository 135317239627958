import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CCardFooter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import configs from 'src/appConfigs';

class Users extends Component {

  constructor(props) {
    super(props);

    this.pageChange = this.pageChange.bind(this);

  }

  componentDidMount() {
    const queryPage = this.props.location.search.match(/page=([0-9]+)/, '');
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
    this.setState({ currentPage: currentPage });
  }

  pageChange = (newPage) => {
    var currentPage = this.state.currentPage;
    currentPage !== newPage && this.props.history.push(`/users?page=${newPage}`)
  }

  render() {

    const { users } = this.props;
    var totalPages = 1;
    if (users && users.length > 0) {
      totalPages = users.length;
    }
    const fields = [
      { key: 'id', _style: { width: '15%' } },
      { key: 'username', label: 'Tên Đăng Nhập', _classes: 'font-weight-bold' },
      { key: 'fullname', label: 'Họ & Tên' },
      { key: 'group', label: 'Nhóm' },
      { key: 'role', label: 'Phân Quyền' },
      { key: 'last_logged', label: 'Đăng Nhập', filter: false, sorter: false },
      { key: 'actions', label: 'Thao Tác', _style: { width: '10%' }, _classes: 'text-center', filter: false, sorter: false },
    ]

    return (
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              Users
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={users}
                fields={fields}
                columnFilter
                tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                itemsPerPageSelect={configs.itemsPerPageSelect}
                itemsPerPage={configs.per_page}
                hover
                sorter
                pagination
                striped
                scopedSlots={{
                  'last_logged':
                    (item) => {
                      return (
                        <td className="py-2">
                          {item.last_logged && <>{helps.formatDateTime_from_Timestamp(item.last_logged)}</>}
                        </td>
                      )
                    },
                  'actions':
                    (item) => {
                      return (
                        <td className="py-2 text-center">
                          <ThePermission {... this.props} component="users" action="edit">
                            <CButton
                              color="primary"
                              size="sm mr-1"
                              title="Edit"
                              onClick={() => { this.props.history.push(`/users/add_edit/${item.id}`) }}
                            >
                              <CIcon name="cil-pen-nib"></CIcon>
                            </CButton>
                          </ThePermission>
                          <ThePermission {... this.props} component="users" action="delete">
                            <CButton
                              color="danger"
                              size="sm"
                              title="Delete"
                              onClick={() => { this.props.history.push(`/users/add_edit/${item.id}`) }}
                            >
                              <CIcon name="cil-trash"></CIcon>
                            </CButton>
                          </ThePermission>

                        </td>
                      )
                    },
                }}
              />
              {/*onRowClick={(item) => this.props.history.push(`/users/${item.id}`)}*/}
              {/*
              <CPagination
                activePage={page}
                onActivePageChange={this.pageChange}
                doubleArrows={false}
                align="center"
              />
              */}
            </CCardBody>
            <CCardFooter>
              Tổng: {users && <b>{users.length}</b>}
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    )
  }
}


export default Users;
