import { db } from 'src/dbConfigs';

export const storeService = {
    getAll,
    add,
    getById,
    update,
    updateField,
    _delete,
};

async function getAll() {
    var ref = db.collection('stores');
    var query = ref.orderBy('create_date', 'desc');
    const snapshot = await query.get();
    const stores = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));

    if (stores && stores.length > 0) {
        Promise.all(
            stores.map(async (store, index) => {
                if (store.create_date) {
                    var _date = store['create_date'];
                    store['create_date'] = _date.toDate();
                }
                store['index'] = index + 1;
            })
        );
    }

    return stores;
}

async function getById(id) {
    try {
        const doc = await db.collection("stores").doc(id)
            .get();
        if (doc.exists) {
            return doc.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        console.log("Error getting document:", error);
    }
}

async function add(store) {
    try {
        const docRef = await db.collection("stores").add(store);
        store.id = docRef.id;
        update(store);
        console.log("Firebase: store has been added successfully!");
        return store;
    } catch (error) {
        console.error("Error adding store: ", error);
    }
}

async function update(store) {
    //console.log(store);
    let dbRef = db.collection("stores").doc(store['id']);

    try {
        //console.log(store);
        await dbRef.update(store).then(() => {
            console.log("Firebase: store has been updated successfully!");
        });

        return store;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function updateField(store, field) {
    //console.log(store);
    let dbRef = db.collection("stores").doc(store['id']);

    try {
        console.log(field, store[field]);
        await dbRef.update({
            [field]: store[field]
        }).then(() => {
            console.log("Firebase: store field has been updated successfully!");
        });

        return store;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        return error;
    }
}

async function _delete(store) {
    try {
        await db.collection("stores").doc(store.id).delete().then((store) => {
            return store;
        });
    } catch (error) {
        console.error("Error: ", error);
    }
}
