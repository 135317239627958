import { CButton, CModal, CModalBody, CModalHeader, CTooltip } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assetActions } from 'src/_actions';
import UploadBB from 'src/views/assets/UploadBB';

class UploadBBContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            object: '',
            object_id: '',
            view_modals: [],
        }
        this.toggleViewModal = this.toggleViewModal.bind(this);
    }

    componentDidMount() {
        this.setState({ object: this.props.object, object_id: this.props.object_id });
    }

    toggleViewModal = (item) => {
        let modals = this.state.view_modals;
        const position = modals.indexOf(item);
        let newItems = modals.slice();
        if (position !== -1) {
            newItems.splice(position, 1)
        } else {
            newItems = [...modals, item]
        }
        this.setState({ view_modals: newItems });
    }

    render() {
        const { asset_item } = this.props;
        return (
            <>
                <CModal
                    size='md'
                    show={this.state.view_modals.includes(asset_item.object_id)}
                    onClose={() => { this.toggleViewModal(asset_item.object_id) }}
                    className='text-left'
                >
                    <CModalHeader closeButton>
                        <h4><b>Tải Biên Bản lên</b></h4>
                    </CModalHeader>
                    <CModalBody>
                        <div className='pb-5 pt-3'>
                            <UploadBB key={asset_item.object + '-' + asset_item.object_id} {... this.props} form_label={"Tải Biên Bản lên:"} />
                        </div>
                    </CModalBody>
                </CModal>
                <CTooltip content={'Tải Biên Bản lên'}>
                    <CButton
                        name='upload'
                        id={'upload-' + asset_item.object_id}
                        onClick={() => { this.toggleViewModal(asset_item.object_id) }}
                    >
                        <CIcon name="cil-cloud-upload" className="mfe-1 mt-0"></CIcon>
                    </CButton>
                </CTooltip>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { loading, upload_loading } = state.fileReducer;
    const { user } = state.authentication;
    return { loading, upload_loading, user };
}

const actionCreators = {
    uploadFile: assetActions.uploadFile,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(UploadBBContainer);
export { connectedContainer as UploadBBContainer };