import React, { Component } from 'react'
import {
    CRow,
    CCol,
    CButton,
    CLink,
    CTooltip,
    CBadge,
    CPopover,
    CCard,
    CCardHeader,
    CCardBody,
    CCollapse,
    CLabel,
    CForm,
    CInput,
    CFormGroup,
    CSelect,
    CInputGroup,
    CImg,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import Loading from 'src/containers/_loading';
import configs from 'src/appConfigs';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import Select from 'react-select';
import BookOutExport from './BookOutExport';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import locale from 'date-fns/locale/vi';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';

class Books extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show_filter: false,
            type: '',
            book_date: '',
            book_id: '',
            book_object: '',
            book_object_ids: [],
            book_period_from: '',
            book_period_to: '',
            book_account: '',
            book_section: '',
            amount: '',
            show_qr: false,
        }

        this.pageChange = this.pageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {

        const queryPage = this.props.location.search.match(/page=([0-9]+)/, '');
        const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
        this.setState({ currentPage: currentPage });
        //this.correctBooks();
    }

    pageChange = (newPage) => {
        var currentPage = this.state.currentPage;
        currentPage !== newPage && this.props.history.push(`/users?page=${newPage}`)
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeDate(_date, field) {
        this.setState({ [field]: _date });
    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [field]: selectedOptions });
    }

    handleSelectObject(object, object_id) {
        this.setState({
            book_object: object,
            book_object_ids: new Array(object_id),
        });
    }

    handleChangeMonth(value) {

        var firstDay = new Date(value.getFullYear(), value.getMonth(), 1);
        var lastDay = new Date(value.getFullYear(), value.getMonth() + 1, 0);
        this.setState({ month: value, book_period_from: firstDay, book_period_to: lastDay });

    }

    doProcess(e, book) {
        const { user } = this.props;
        e.preventDefault();
        book['processed'] = true;
        this.props.updateField(book, 'processed');
        book['processed_by'] = user.id;
        this.props.updateField(book, 'processed_by');
        book['processed_date'] = new Date();
        this.props.updateField(book, 'processed_date');
        this.correctBooks();
    }

    async correctBooks() {
        const { taxonomies, books } = this.props;
        if (!helps.isEmpty(books)) {
            let _books = books.filter(book => !book.approval_required || (book.approval_required && book.approved));
            _books = _books.sort(function (a, b) {
                let b_count = b.count ? b.count : 0;
                let a_count = a.count ? a.count : 0;
                return a_count - b_count;

                //return new Date(a.added_time).getTime() - new Date(b.added_time).getTime();
            });

            await Promise.all(
                _books.forEach(async (book, index) => {
                    let amount = parseFloat(book.amount);
                    let prev_stock = 0;
                    let prev_book = _books[index - 1] ? _books[index - 1] : await this.props.getLastMonthBook();

                    if (prev_book) {
                        prev_stock = parseFloat(prev_book.stock)
                    }
                    //console.log(index, book, prev_book)
                    if (book.type === 'out') {
                        book['stock'] = prev_stock - amount;
                        this.props.updateField(book, 'stock');
                    } else if (book.type === 'in') {
                        book['stock'] = prev_stock + amount;
                        this.props.updateField(book, 'stock');
                    }
                    let taxonomy_stock = book['stock'];
                    let taxonomy = helps.getItemFromArr(taxonomies, book.book_account);
                    if (!helps.isEmpty(taxonomy)) {
                        taxonomy['stock'] = taxonomy_stock;
                        this.props.updateTaxonomyField(taxonomy, 'stock');
                    }
                })
            );
        }

    }

    render() {
        const { books, taxonomies, user, users, employees, projects, customers, vendors, housings, approvals, type } = this.props;
        //console.log(books)
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        let employees_options = [];
        if (!helps.isEmpty(employees)) {
            employees.forEach((employee) => {
                employees_options.push({ value: employee.employee_id, label: employee.fullname + ' (' + employee.employee_id + ')' });
            })
        }

        let customers_options = [];
        if (!helps.isEmpty(customers)) {
            customers.forEach((customer) => {
                customers_options.push({ value: customer.id, label: customer.name });
            })
        }

        let housings_options = [];
        if (!helps.isEmpty(housings)) {
            housings.forEach((housing) => {
                housings_options.push({ value: housing.id, label: housing.name + ' (' + housing.address + ')' });
            })
        }

        let vendors_options = [];
        if (!helps.isEmpty(vendors)) {
            vendors.forEach((vendor) => {
                vendors_options.push({ value: vendor.id, label: vendor.name });
            })
        }

        let projects_options = [];
        if (!helps.isEmpty(projects)) {
            projects.forEach((project) => {
                projects_options.push({ value: project.id, label: project.name });
            })
        }

        var _books = [];
        if (!helps.isEmpty(books) && books.length > 0) {
            _books = books.filter(book => (!book.approval_required) || (book.type === type && book.approval_required && book.approved));
        }

        // book type
        if (!helps.isEmpty(this.state.type)) {
            _books = _books.filter((book) => book.type === this.state.type);
        }

        // book date
        if (this.state.book_date) {
            _books = _books.filter((book) => book.book_date.getDate() === this.state.book_date.getDate());
        }

        // book id
        if (!helps.isEmpty(this.state.book_id)) {
            _books = _books.filter((book) => book.id === this.state.book_id);
        }

        // book account
        if (!helps.isEmpty(this.state.book_account)) {
            _books = _books.filter((book) => book.book_account === this.state.book_account);
        }

        // book section
        if (!helps.isEmpty(this.state.book_section)) {
            _books = _books.filter((book) => book.book_section === this.state.book_section);
        }

        // book amount
        if (this.state.amount && parseInt(this.state.amount) > 0) {
            _books = _books.filter((book) => parseInt(book.amount) === parseInt(this.state.amount));
        }

        // book object
        if (!helps.isEmpty(this.state.book_object)) {
            _books = _books.filter((book) => book.book_object === this.state.book_object);
        }

        // book object ids
        if (!helps.isEmpty(this.state.book_object_ids)) {
            _books = _books.filter((book) => {
                if (helps.isEmpty(book.book_object_ids)) return false;
                else {
                    let result = book.book_object_ids.filter(({ value }) => this.state.book_object_ids.some(x => x.value === value));
                    //console.log(result)
                    if (!helps.isEmpty(result)) return true;
                    else return false;
                }
            });
        }

        // book period
        if (this.state.book_period_from && this.state.book_period_to) {
            //console.log(this.state.book_period_from, this.state.book_period_to)
            _books = _books.filter((book) => {
                if (book.book_period_from && book.book_period_to)
                    return book.book_period_from.getTime() >= this.state.book_period_from.getTime() && book.book_period_to.getTime() <= this.state.book_period_to.getTime();
                else return false;
            });
        }

        _books = _books.sort(function (a, b) {
            let b_count = b.count ? b.count : 0;
            let a_count = a.count ? a.count : 0;
            return b_count - a_count;
        });

        let total_amount_out = 0;
        let total_amount_in = 0;

        return (
            <CRow>
                <CCol xl={12} md={12}>
                    <CForm>
                        <CCard>
                            <CCardHeader style={{ backgroundColor: '#ECECEC' }}>
                                <CRow>
                                    <CCol>
                                        <CIcon name='cil-filter' className='mr-2' />
                                        Lọc:
                                    </CCol>
                                    <CCol className={'text-right'}>
                                        <CTooltip content={this.state.show_filter ? 'Ẩn bộ Lọc' : 'Hiện bộ Lọc'}>
                                            <CButton type="button" color="secondary" size="sm" onClick={() => { this.setState({ show_filter: !this.state.show_filter }) }} title={this.state.show_filter ? 'Ẩn' : 'Hiện'} className='ml-2'>
                                                <CIcon name={this.state.show_filter ? 'cil-chevron-top' : 'cil-chevron-bottom'} className="mfe-1 mt-0"></CIcon>
                                            </CButton>
                                        </CTooltip>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCollapse show={this.state.show_filter}>
                                <CCardBody style={{ position: 'relative', zIndex: 99, backgroundColor: 'rgba(238, 238, 238, 0.25)' }}>
                                    <CRow>
                                        <CCol md={3}>
                                            <CRow className={'mb-1'}>
                                                <CCol className='text-right'>
                                                    <CLabel htmlFor='filter-type' className='mt-1'>Loại chứng từ:</CLabel>
                                                </CCol>
                                                <CCol>
                                                    <CSelect
                                                        id={"filter-type"}
                                                        name="type"
                                                        value={this.state.type}
                                                        onChange={(e) => { this.handleChange('type', e) }}
                                                    >
                                                        <option value={''}>- Tất cả -</option>
                                                        <option value={'in'}>Thu</option>
                                                        <option value={'out'}>Chi</option>
                                                    </CSelect>
                                                </CCol>
                                            </CRow>
                                            <CRow className={'mb-1'}>
                                                <CCol className='text-right'>
                                                    <CLabel htmlFor='filter-book_id' className='mt-1'>Số chứng từ:</CLabel>
                                                </CCol>
                                                <CCol>
                                                    <CInput
                                                        type='text'
                                                        id='filter-book_id'
                                                        name='book_id'
                                                        value={this.state.book_id}
                                                        placeholder='...'
                                                        className="form-control-sm"
                                                        onChange={(e) => { this.handleChange('book_id', e) }}
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow className={'mb-1'}>
                                                <CCol className='mb-2 text-right'>
                                                    <CLabel htmlFor='filter-book_date' className='mt-1'>Ngày chứng từ:</CLabel>
                                                </CCol>
                                                <CCol>
                                                    <DateTimePicker
                                                        id='filter-book_date'
                                                        value={this.state.book_date}
                                                        format={'dd/MM/yyyy'}
                                                        onChange={(value) => { this.handleChangeDate(value, 'book_date') }}
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow className={'mb-1'}>
                                                <CCol className='mb-2 text-right'>
                                                    <CLabel htmlFor='filter-book_account' className='mt-1'>Tài khoản:</CLabel>
                                                </CCol>
                                                <CCol>
                                                    <CSelect
                                                        id='filter-book_account'
                                                        name='book_account'
                                                        value={this.state.book_account}
                                                        className="form-control-sm"
                                                        onChange={(e) => { this.handleChange('book_account', e) }}
                                                    >
                                                        <option value={''}> - chọn Tài khoản - </option>
                                                        {!helps.isEmpty(taxonomies) && taxonomies.map((taxonomy) => {
                                                            if (taxonomy.category_id === 'account') {
                                                                return (
                                                                    <option key={'book_account_' + taxonomy.id} value={taxonomy.id}>{taxonomy.code} {taxonomy.name}</option>
                                                                )
                                                            } else {
                                                                return (<></>)
                                                            }
                                                        })}
                                                    </CSelect>
                                                </CCol>
                                            </CRow>
                                            <CRow className={'mb-1'}>
                                                <CCol className='mb-2 text-right'>
                                                    <CLabel htmlFor='filter-book_section' className='mt-1'>Khoản:</CLabel>
                                                </CCol>
                                                <CCol>
                                                    <CSelect
                                                        id='filter-book_section'
                                                        name='book_section'
                                                        className="form-control-sm"
                                                        value={this.state.book_section}
                                                        onChange={(e) => { this.handleChange('book_section', e) }}
                                                    >
                                                        <option value={''}> - chọn Khoản - </option>
                                                        {!helps.isEmpty(taxonomies) && taxonomies.map((taxonomy) => {
                                                            if (taxonomy.category_id === 'section') {
                                                                return (
                                                                    <option key={'book_section_' + taxonomy.id} value={taxonomy.id}>{taxonomy.code} {taxonomy.name}</option>
                                                                )
                                                            } else return (<></>);
                                                        })}
                                                    </CSelect>
                                                </CCol>
                                            </CRow>
                                            <CRow className={'mb-1'}>
                                                <CCol className='mb-2 text-right'>
                                                    <CLabel htmlFor='filter-amount' className='mt-1'>Số tiền:</CLabel>
                                                </CCol>
                                                <CCol>
                                                    <CInputGroup>
                                                        <CurrencyInput
                                                            name="amount"
                                                            id="filter-amount"
                                                            required
                                                            className='form-control form-control-sm text-dark'
                                                            value={this.state.amount}
                                                            decimalsLimit={2}
                                                            onValueChange={(value, name) => { this.setState({ [name]: value }) }}
                                                        />
                                                    </CInputGroup>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        <CCol md={5}>
                                            <CRow className={'mb-1'}>
                                                <CCol className='mb-2 text-right' md={4}>
                                                    <CLabel htmlFor='filter-book_object' className='mt-1'>Đối tượng:</CLabel>
                                                </CCol>
                                                <CCol md={8}>
                                                    <CSelect
                                                        id='filter-book_object'
                                                        name='book_object'
                                                        className="form-control-sm"
                                                        value={this.state.book_object}
                                                        onChange={(e) => { this.handleChange('book_object', e) }}
                                                    >
                                                        <option value={''}> - chọn Đối tượng - </option>
                                                        {configs.acc_objects && Object.keys(configs.acc_objects).map((_key) => {
                                                            return (
                                                                <option key={_key} value={_key}>{configs.acc_objects[_key]}</option>
                                                            )
                                                        }
                                                        )}
                                                    </CSelect>

                                                </CCol>
                                            </CRow>
                                            {this.state.book_object === 'employees' &&
                                                <CRow className={'mb-1'}>
                                                    <CCol className='mb-2 text-right' md={4}>
                                                        <CLabel htmlFor='filter-book_object_ids' className='mt-1'>Nhân Sự:</CLabel>
                                                    </CCol>
                                                    <CCol>
                                                        <Select
                                                            id="input-book_object_ids"
                                                            options={employees_options}
                                                            value={this.state.book_object_ids}
                                                            isMulti
                                                            placeholder='chọn Nhân viên'
                                                            onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids'); }}
                                                        />
                                                    </CCol>
                                                </CRow>
                                            }
                                            {this.state.book_object === 'customers' &&
                                                <CRow className={'mb-1'}>
                                                    <CCol className='mb-2 text-right' md={4}>
                                                        <CLabel htmlFor='filter-book_object_ids' className='mt-1'>Khách hàng:</CLabel>
                                                    </CCol>
                                                    <CCol>
                                                        <Select
                                                            id="input-customer_id"
                                                            options={customers_options}
                                                            value={this.state.book_object_ids}
                                                            isMulti
                                                            placeholder='chọn Khách hàng'
                                                            onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                        />
                                                    </CCol>
                                                </CRow>
                                            }
                                            {this.state.book_object === 'housings' &&
                                                <CRow className={'mb-1'}>
                                                    <CCol className='mb-2 text-right' md={4}>
                                                        <CLabel htmlFor='filter-book_object_ids' className='mt-1'>Nhà đội:</CLabel>
                                                    </CCol>
                                                    <CCol>
                                                        <Select
                                                            id="input-customer_id"
                                                            options={housings_options}
                                                            value={this.state.book_object_ids}
                                                            isMulti
                                                            placeholder='chọn Nhà đội'
                                                            onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                        />
                                                    </CCol>
                                                </CRow>
                                            }

                                            {this.state.book_object === 'projects' &&
                                                <CRow className={'mb-1'}>
                                                    <CCol className='mb-2 text-right' md={4}>
                                                        <CLabel htmlFor='filter-book_object_ids' className='mt-1'>Mục tiêu:</CLabel>
                                                    </CCol>
                                                    <CCol>
                                                        <Select
                                                            id="input-customer_id"
                                                            options={projects_options}
                                                            value={this.state.book_object_ids}
                                                            isMulti
                                                            placeholder='chọn Mục tiêu'
                                                            onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                        />
                                                    </CCol>
                                                </CRow>
                                            }

                                            {this.state.book_object === 'vendors' &&
                                                <CRow className={'mb-1'}>
                                                    <CCol className='mb-2 text-right' md={4}>
                                                        <CLabel htmlFor='filter-book_object_ids' className='mt-1'>Nhà cung cấp:</CLabel>
                                                    </CCol>
                                                    <CCol>
                                                        <Select
                                                            id="input-customer_id"
                                                            options={vendors_options}
                                                            value={this.state.book_object_ids}
                                                            isMulti
                                                            placeholder='chọn Nhà cung cấp'
                                                            onChange={(choice) => { this.handleChangeSelect(choice, 'book_object_ids') }}
                                                        />
                                                    </CCol>
                                                </CRow>
                                            }
                                        </CCol>
                                        <CCol md={4}>
                                            <div className="mb-3">
                                                <CLabel htmlFor="input-book_period_from">Kỳ Thanh Toán:</CLabel>
                                            </div>
                                            <CRow className="mb-3">
                                                <CCol>
                                                    <CFormGroup variant='custom-radio' inline>
                                                        <CLabel htmlFor='filter-month' className='mt-1'>Tháng:</CLabel>
                                                        <DatePicker
                                                            id='filter-month'
                                                            locale={locale}
                                                            selected={this.state.month}
                                                            onChange={(date) => { this.handleChangeMonth(date) }}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                            className='ml-2 form-control'
                                                        />
                                                    </CFormGroup>
                                                </CCol>
                                            </CRow>
                                            <CRow className="mb-3">
                                                <CCol className='mb-2 text-right'>
                                                    <CFormGroup variant='custom-radio' inline>
                                                        <CLabel htmlFor='filter-book_period_from' className='mt-1'>từ ngày:</CLabel>
                                                        <DateTimePicker
                                                            id='filter-book_period_from'
                                                            value={this.state.book_period_from}
                                                            format={'dd/MM/yyyy'}
                                                            className='ml-2'
                                                            maxDate={this.state.book_period_to ? this.state.book_period_to : null}
                                                            onChange={(value) => { this.handleChangeDate(value, 'book_period_from') }}
                                                        />
                                                    </CFormGroup>
                                                </CCol>
                                                <CCol>
                                                    <CFormGroup variant='custom-radio' inline>
                                                        <CLabel htmlFor='filter-book_period_to' className='mt-1'>đến ngày:</CLabel>
                                                        <DateTimePicker
                                                            id='filter-book_period_to'
                                                            value={this.state.book_period_to}
                                                            minDate={this.state.book_period_from ? this.state.book_period_from : null}
                                                            format={'dd/MM/yyyy'}
                                                            className='ml-2'
                                                            onChange={(value) => { this.handleChangeDate(value, 'book_period_to') }}
                                                        />
                                                    </CFormGroup>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCollapse>
                        </CCard>
                    </CForm>
                    <div className="table-responsive accounting_books-container">
                        <table className='accounting_books'>
                            <thead>
                                <tr>
                                    <th rowSpan="2" style={{ width: '20px' }}>#</th>
                                    <th className='text-center' rowSpan="2" style={{ width: '40px' }}>Duyệt</th>
                                    <th colSpan={2} className='text-center'>Chứng Từ</th>
                                    <th className='text-center' rowSpan="2" >Tài Khoản</th>
                                    <th className='text-center' rowSpan="2" >Nội Dung</th>
                                    <th className='text-center' rowSpan="2" >Khoản</th>
                                    <th className='text-center' colSpan={2} > Số Tiền</th>
                                    <th className='text-center' rowSpan="2" >Tồn</th>
                                    <th className='text-center' rowSpan={2} style={{ width: '20%' }}>Đối Tượng</th>
                                    <th className='text-center' rowSpan={2} style={{ width: '100px' }}>Kỳ Thanh Toán</th>
                                    <th className='text-center' rowSpan={2} >Ghi Chú</th>
                                    <th className='text-center' rowSpan={2} >Đã Chi</th>
                                    <th className='text-center' rowSpan={2} >Tạo Bởi</th>
                                    <th className='text-center' rowSpan={2} style={{ width: '5%' }}>Thao Tác</th>
                                </tr>
                                <tr>
                                    <th className='text-center font-italic' style={{ width: '100px' }}>Số</th>
                                    <th className='text-center font-italic'>Ngày</th>
                                    <th className='text-center font-italic'>Thu</th>
                                    <th className='text-center font-italic'>Chi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!helps.isEmpty(_books) && _books.map((book, index) => {
                                    let book_account = helps.getItemFromArr(taxonomies, book.book_account);
                                    let book_section = helps.getItemFromArr(taxonomies, book.book_section);
                                    if (book.type === 'in') {
                                        total_amount_in += parseFloat(book.amount);
                                    } else {
                                        total_amount_out += parseFloat(book.amount);
                                    }
                                    let book_objects = [];
                                    let object_color = 'secondary';
                                    switch (book.book_object) {
                                        case 'employees':
                                            book.book_object_ids.forEach((object_id) => {
                                                let item = helps.getItemFromArr(employees, object_id.value, 'employee_id');
                                                book_objects.push(Object.assign({ value: item.employee_id, label: item.fullname + ' (' + item.employee_id + ')' }, item));
                                            })
                                            object_color = 'warning';
                                            break;
                                        case 'projects':
                                            book.book_object_ids.forEach((object_id) => {
                                                let item = helps.getItemFromArr(projects, object_id.value);
                                                book_objects.push(Object.assign(object_id, item));
                                            })
                                            object_color = 'success';
                                            break;
                                        case 'customers':
                                            book.book_object_ids.forEach((object_id) => {
                                                let item = helps.getItemFromArr(customers, object_id.value);
                                                book_objects.push(Object.assign(object_id, item));
                                            })
                                            object_color = 'primary';
                                            break;
                                        case 'housings':
                                            book.book_object_ids.forEach((object_id) => {
                                                let item = helps.getItemFromArr(housings, object_id.value);
                                                book_objects.push(Object.assign(object_id, item));
                                            })
                                            object_color = 'primary';
                                            break;
                                        default:

                                    }
                                    //console.log(book.approved_time)
                                    return (
                                        <tr key={book.id}>
                                            <td id={book.id} className='text-center'><CPopover content={book.id + ' - ' + book.count}><span>{index + 1}</span></CPopover></td>
                                            <td className='text-center'>
                                                {book.approval_required && !book.approved && helps.checkApproval(approvals, user, 'expenses_approver') && <>
                                                    <CTooltip content={'Duyệt Chi'}>
                                                        <CButton
                                                            color="primary"
                                                            size="sm mr-1"
                                                            onClick={() => { this.handleApprove(book) }}
                                                        >
                                                            <CIcon name="cil-check" className='mr-1'></CIcon>
                                                            Duyệt
                                                        </CButton>
                                                    </CTooltip>
                                                </>}
                                                {book.approval_required && !book.approved && !helps.checkApproval(approvals, user, 'expenses_approver') && <>
                                                    <CPopover content={'Chưa Duyệt Chi'}>
                                                        <CBadge color='secondary'>
                                                            <CIcon name="cil-x-circle" size='sm'></CIcon>
                                                        </CBadge>
                                                    </CPopover>
                                                </>}
                                                {book.approval_required && book.approved && <>
                                                    <CPopover header={'Đã Duyệt'} content={<div><div><i><u>Người duyệt:</u></i> {helps.getUserAlias(users, book.approved_by)}</div><div><i><u>Thời gian:</u></i> {helps.formatDateTime_from_Timestamp(book.approved_time)}</div></div>}>
                                                        <CBadge color='success'>
                                                            <CIcon name="cil-check" size='sm'></CIcon>
                                                        </CBadge>
                                                    </CPopover>
                                                </>}
                                            </td>
                                            <td><CPopover content={book.book_id}><span>{book.book_id}</span></CPopover></td>
                                            <td>{helps.formatDate_from_Timestamp(book.book_date)}</td>
                                            <td>
                                                <CPopover content={<div>{helps.formatCurrency(book_account.stock)}</div>}>
                                                    <div>{book_account.code && <>{book_account.code} </>}{book_account.name}</div>
                                                </CPopover>
                                            </td>
                                            <td>{book.description}</td>
                                            <td>
                                                {book_section.name}
                                            </td>
                                            <td className='text-right'>{book.type === 'in' && <CPopover content={helps.DocTienBangChu(book.amount)}><b>{helps.formatCurrency(book.amount)}</b></CPopover>}</td>
                                            <td className='text-right'>{book.type === 'out' && <CPopover content={helps.DocTienBangChu(book.amount)}><b>{helps.formatCurrency(book.amount)}</b></CPopover>}</td>
                                            <td className='text-right'>
                                                <CPopover content={helps.DocTienBangChu(book.stock)}><div>{helps.formatCurrency(book.stock)}</div></CPopover>
                                            </td>
                                            <td>
                                                <CBadge color={object_color} className={'mr-1'}>{configs.acc_objects[[book.book_object]]}:</CBadge>
                                                {!helps.isEmpty(book_objects) && book_objects.map((object_id) => {
                                                    let bank_acc_number = '';
                                                    let bank_name = '';
                                                    let _object = {};
                                                    var employee_projects = [];
                                                    let employee_project = {};
                                                    if (book.book_object === 'employees') {
                                                        _object = helps.getItemFromArr(employees, object_id.value, 'employee_id');
                                                        if (!helps.isEmpty(_object.bank_account_number) && !helps.isEmpty(_object.bank_name)) {
                                                            bank_acc_number = _object.bank_account_number;
                                                            bank_name = _object.bank_name;
                                                        }
                                                        employee_projects = projects.filter((project) => {
                                                            if (project.employees) {
                                                                let found = helps.getItemFromArr(project.employees, _object.employee_id, 'employee_id');
                                                                if (!helps.isEmpty(found)) return true;
                                                                else return false;
                                                            } else {
                                                                return false;
                                                            }
                                                        })
                                                        if (!helps.isEmpty(employee_projects)) {
                                                            employee_project = employee_projects[0];
                                                        }
                                                    }
                                                    let object_key = 'object_' + book.book_object + '-' + object_id.value ? object_id.value : "" + new Date.getTime();
                                                    return (
                                                        <span key={object_key} className='ml-1'>
                                                            <CPopover content={<div>
                                                                {book.book_object === 'employees' && <small>
                                                                    <div><u><i>Họ & tên:</i></u> <b>{_object.fullname}</b></div>
                                                                    <div><u><i>MSNV:</i></u> <b>{_object.employee_id}</b></div>
                                                                    <div><u><i>Chi nhánh:</i></u> <b>{configs.branches[_object.branch]}</b></div>
                                                                    <div><u><i>Khối:</i></u> <b>{configs.groups[_object.group]}</b></div>
                                                                    <div><u><i>Bộ phận:</i></u> <b>{configs.departments[_object.department]}</b></div>
                                                                    <div><u><i>Chức vụ:</i></u> <b>{configs.positions[_object.position]}</b></div>
                                                                    <div><u><i>Loại hình:</i></u> <b>{configs.job_types[_object.job_type]}</b></div>
                                                                    <div><u><i>Mục tiêu:</i></u> <b>{!helps.isEmpty(employee_project) && <>{employee_project.name}</>}</b></div>
                                                                </small>}
                                                                {book.book_object === 'housings' && <small>
                                                                    <div><u><i>Tên:</i></u> <b>{object_id.name}</b></div>
                                                                    <div><u><i>Địa chỉ:</i></u> <b>{object_id.address}</b></div>
                                                                    <div><u><i>Sđt:</i></u> <b>{object_id.phone}</b></div>
                                                                </small>}
                                                            </div>}>
                                                                <CLink key={book.id + '-' + object_id.value} onClick={() => { this.handleSelectObject(book.book_object, object_id) }} title={'Lọc theo: ' + object_id.label}>{object_id.label}</CLink>
                                                            </CPopover>
                                                            {!helps.isEmpty(bank_acc_number) && !helps.isEmpty(bank_name) && <>
                                                                <CPopover
                                                                    header={<><b>{object_id.label}</b></>}
                                                                    content={<CImg src={'https://img.vietqr.io/image/' + bank_name + '-' + bank_acc_number + '-compact.png'} width={180} />}
                                                                >
                                                                    <CImg
                                                                        src={'https://img.vietqr.io/image/' + bank_name + '-' + bank_acc_number + '-compact.png'}
                                                                        width={20}
                                                                        onClick={() => { this.props.toggleModal(bank_name + '-' + bank_acc_number); }}
                                                                        className={'border mr-1'}
                                                                    />
                                                                </CPopover>
                                                            </>
                                                            }
                                                        </span>
                                                    );
                                                })}
                                                {book.book_object === 'other' && book.book_object_content && <span className='ml-1'>{book.book_object_content}</span>}
                                            </td>
                                            <td className='text-center'>
                                                {book.book_period_from && <span>{helps.formatDate_from_Timestamp(book.book_period_from)}</span>}
                                                <span> - </span>
                                                {book.book_period_to && <span>{helps.formatDate_from_Timestamp(book.book_period_to)}</span>}
                                            </td>
                                            <td>
                                                {book.note}
                                            </td>
                                            <td className='text-center'>
                                                {book.type === 'out' && book.processed && <>
                                                    <CPopover
                                                        content={<small>
                                                            <h6><b>Đã Chi:</b></h6>
                                                            <div><u><i>Số chứng từ:</i></u> <b>{book.book_id}</b></div>
                                                            <div><u><i>Số tiền:</i></u> <b>{helps.formatCurrency(book.amount)}</b></div>
                                                            {book.processed_by && <div><u><i>Bởi:</i></u> <b>{helps.getUserAlias(users, book.processed_by)}</b></div>}
                                                            {book.processed_date && <div><u><i>Ngày:</i></u> <b>{helps.formatDateTime_from_Timestamp(book.processed_date)}</b></div>}
                                                        </small>}
                                                    >
                                                        <CIcon name="cil-check" className='text-success'></CIcon>
                                                    </CPopover>
                                                </>}
                                                {book.type === 'out' && !book.processed && <>
                                                    <CTooltip content={'Chi: ' + book.book_id + ' ' + helps.formatCurrency(book.amount)}>
                                                        <CButton
                                                            type='button'
                                                            color="danger"
                                                            size="sm"
                                                            className={'mr-1'}
                                                            title={'Chưa chi'}
                                                            onClick={(e) => { this.doProcess(e, book) }}
                                                        >
                                                            <CIcon name="cil-check-circle"></CIcon>
                                                        </CButton>
                                                    </CTooltip>
                                                </>}
                                            </td>
                                            <td>
                                                <small>{helps.getUserAlias(users, book.added_by)}</small>
                                                <div><small><i>{helps.formatDateTime_from_Timestamp(book.added_time)}</i></small></div>
                                            </td>
                                            <td className='text-center'>
                                                {((!book.approved && book.type === 'out') || user.role === 'administrator') && <>
                                                    <CTooltip content={'Cập nhật thông tin: ' + book.book_id}>
                                                        <CButton
                                                            color="primary"
                                                            size="sm mr-1"
                                                            title={'Cập nhật thông tin: ' + book.book_id}
                                                            onClick={() => { this.props.toggleModal((book.type === 'out') ? 'out_form' : 'in_form', book) }}
                                                        >
                                                            <CIcon name="cil-pen-nib"></CIcon>
                                                        </CButton>
                                                    </CTooltip>
                                                </>}
                                                <BookOutExport {... this.props} book={book} />
                                            </td>
                                        </tr>
                                    )
                                })}
                                {helps.isEmpty(_books) && <tr><td colSpan={20} className='text-center m-3'><h5 className='text-secondary m-3 p-3'><b>Không tìm thấy dữ liệu!</b></h5></td></tr>}
                            </tbody>
                            <tfoot className='bg-secondary text-dark'>
                                <tr>
                                    <th colSpan={7} className='text-right'>Tổng:</th>
                                    <th className='text-right'>{helps.formatCurrency(total_amount_in)}</th>
                                    <th className='text-right'>{helps.formatCurrency(total_amount_out)}</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </CCol>
            </CRow >
        )
    }
}


export default Books;
