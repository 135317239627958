import React, { Component } from 'react';
import { connect } from 'react-redux';
import { customerActions, noteActions, projectActions, provinceActions, tagActions, userActions } from '../../_actions';
import { helps } from 'src/_helpers';
import { CCol, CRow } from '@coreui/react';
import Crawl from 'src/views/customers/Crawl';

class CustomersCrawlContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_header: 'Danh Sách Khách Hàng',
            customer_type: 'customer'
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <CRow>
                <CCol>
                    <Crawl {... this.props} />
                </CCol>
            </CRow>

        );
    }
}

function mapStateToProps(state) {
    const { loading, customers } = state.customerReducer;
    const { provinces } = state.provinceReducer;
    const { projects } = state.projectReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    const { tags } = state.tagReducer;
    const { notes } = state.noteReducer;
    return { loading, customers, provinces, user, users, projects, tags, notes };
}

const actionCreators = {
    getAll: customerActions.getAll,
    setParams: customerActions.setParams,
    getProvinces: provinceActions.getAll,
    getUsers: userActions.getAll,
    getProjects: projectActions.getAll,
    getTags: tagActions.getAll,
    getNotes: noteActions.getAll,
    add: customerActions.add,
    _delete: customerActions._delete,
    update: customerActions.update,
    addTag: tagActions.add,
    deleteTag: tagActions._delete,
    addNote: noteActions.add,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(CustomersCrawlContainer);
export { connectedContainer as CustomersCrawlContainer };