import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CInvalidFeedback,
    CTextarea,
    CInput,
    CCard,
    CCardHeader,
    CCardBody,
    CInputCheckbox,
    CFormText,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';

class ContactsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            form_header: 'Thêm Liên Hệ',
            auto_generate_user: false,
            id: '',
            customer_id: '',
            project_id: '',
            name: '',
            phone: '',
            email: '',
            position: '',
            description: '',
            added_time: new Date(),
            added_by: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEditor = this.handleChangeEditor.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {

        const { contact } = this.props;
        //console.log(contact);
        if (!helps.isEmpty(contact)) {
            this.setState({
                form_header: 'Sửa Liên Hệ',
                id: contact.id,
                name: contact.name,
                phone: contact.phone,
                email: contact.email,
                position: contact.position,
                description: contact.description,
                customer_id: contact.customer_id,
                project_id: contact.project_id,
                added_time: contact.added_time,
                added_by: contact.added_by,
            });
        } else {
            const customer_id = this.props.customer_id;
            const project_id = this.props.project_id;
            const added_by = this.props.user.id;
            this.setState({
                status: 'add',
                errors: {},
                added_time: new Date(),
                added_by: added_by,
            });
            if (customer_id) {
                this.setState({
                    customer_id: customer_id,
                });
            }
            if (project_id) {
                this.setState({
                    project_id: project_id,
                });
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            var customer_id = this.state.customer_id;
            var project_id = this.state.project_id;
            var contact = {
                id: this.state.id,
                customer_id: customer_id,
                project_id: project_id,
                name: this.state.name,
                phone: this.state.phone,
                email: this.state.email,
                position: this.state.position,
                description: this.state.description,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
                auto_generate_user: this.state.auto_generate_user,
            }
            this.props.handleSubmit(contact);
            if (this.props.status == 'add') {
                this.setState({
                    id: '',
                    name: '',
                    phone: '',
                    email: '',
                    position: '',
                    description: '',
                });
            }
        } else {
            return false;
        }
    }

    handleChangeEditor(field, value) {
        //console.log(field, value);
        this.setState({ [field]: value });
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập tên Liên Hệ";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCancel(e) {
        e.preventDefault();
        if (this.props.status == 'add') {
            this.props.handleCancelForm();
        } else {
            this.props.toggleEditForms();
        }

    }

    render() {
        return (
            <CRow>
                <CCol xl={12} md={12}>
                    <CCard>
                        <CCardHeader>
                            {this.state.form_header}:
                        </CCardHeader>
                        <CCardBody>
                            <CForm id={'form_' + this.state.id} onSubmit={(e) => this.handleSubmit(e)}>
                                <CRow className="mb-3">
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-name">Họ & Tên:</CLabel>
                                        <CInput
                                            type='text'
                                            name="name"
                                            id="input-name"
                                            defaultValue={this.state.name}
                                            required
                                            onChange={(e) => { this.handleChange('name', e) }}
                                        />
                                        <CInvalidFeedback className="help-block">{this.state.errors['name']}</CInvalidFeedback>
                                    </CCol>
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-position">Vị trí / Chức vụ:</CLabel>
                                        <CInput
                                            type='text'
                                            name="position"
                                            id="input-position"
                                            defaultValue={this.state.position}
                                            onChange={(e) => { this.handleChange('position', e) }}
                                        />
                                        <CInvalidFeedback className="help-block">{this.state.errors['position']}</CInvalidFeedback>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-phone">Số ĐT:</CLabel>
                                        <CInput
                                            type='text'
                                            name="phone"
                                            id="input-phone"
                                            defaultValue={this.state.phone}
                                            onChange={(e) => { this.handleChange('phone', e) }}
                                        />
                                        <CInvalidFeedback className="help-block">{this.state.errors['phone']}</CInvalidFeedback>
                                    </CCol>
                                    <CCol md={6}>
                                        <CLabel htmlFor="input-email">Email:</CLabel>
                                        <CInput
                                            type='text'
                                            name="email"
                                            id="input-email"
                                            defaultValue={this.state.email}
                                            onChange={(e) => { this.handleChange('email', e) }}
                                        />
                                        <CInvalidFeedback className="help-block">{this.state.errors['email']}</CInvalidFeedback>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-auto_generate_user">Tự động tạo TK Người Dùng:</CLabel>
                                        <CInputCheckbox
                                            name='auto_generate_user'
                                            id='input-auto_generate_user'
                                            value={true}
                                            className="ml-3"
                                            onChange={(e) => { this.handleChange('auto_generate_user', e) }}
                                        />
                                        <CFormText className="help-block font-italic">Tạo tự động TK Người Dùng cho Liên Hệ này. TK Người Dùng sẽ được tạo từ Số ĐT của Liên Hệ.</CFormText>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CLabel htmlFor="input-description">Thông tin thêm:</CLabel>
                                        <CTextarea
                                            rows={5}
                                            name="description"
                                            id="input-description"
                                            defaultValue={this.state.description}
                                            onChange={(e) => { this.handleChange('description', e) }}
                                        >
                                        </CTextarea>
                                        <CInvalidFeedback className="help-block">{this.state.errors['description']}</CInvalidFeedback>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-3">
                                    <CCol md={12}>
                                        <CButton type="submit" size='sm' color="primary">
                                            <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                            Lưu
                                        </CButton>
                                        <CButton type="button" size='sm' color="secondary" onClick={(e) => { this.handleCancel(e) }} className="ml-3">
                                            <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                            Huỷ
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        )
    }
}
export default ContactsForm;

