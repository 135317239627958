export const contractConstants = {
    GET_LIST_REQUEST: 'CONTRACTS_GET_LIST_REQUEST',
    GET_LIST_SUCCESS: 'CONTRACTS_GET_LIST_SUCCESS',
    GET_LIST_FAILURE: 'CONTRACTS_GET_LIST_FAILURE',

    GET_LATEST_REQUEST: 'CONTRACTS_GET_LATEST_REQUEST',
    GET_LATEST_SUCCESS: 'CONTRACTS_GET_LATEST_SUCCESS',
    GET_LATEST_FAILURE: 'CONTRACTS_GET_LATEST_FAILURE',

    ADD_REQUEST: 'CONTRACTS_ADD_REQUEST',
    ADD_SUCCESS: 'CONTRACTS_ADD_SUCCESS',
    ADD_FAILURE: 'CONTRACTS_ADD_FAILURE',

    EDIT_REQUEST: 'CONTRACTS_EDIT_REQUEST',
    EDIT_SUCCESS: 'CONTRACTS_EDIT_SUCCESS',
    EDIT_FAILURE: 'CONTRACTS_EDIT_FAILURE',

    DELETE_REQUEST: 'CONTRACTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'CONTRACTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'CONTRACTS_DELETE_FAILURE',
}