import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CInput,
    CFormGroup,
    CFormText,
    CTextarea,
    CSelect,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';

class VendorForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            name: '',
            create_by: this.props.user.id,
            create_date: new Date(),
            phone: '',
            email: '',
            contact_info: '',
            address: '',
            website: '',
            bank_acc: '',
            bank_name: '',
            bank_note: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        const vendor = this.props.vendor;
        //console.log(vendor);
        if (vendor) {
            this.setState({
                id: vendor.id,
                name: vendor.name ? vendor.name : this.state.name,
                website: vendor.website ? vendor.website : this.state.website,
                create_by: vendor.create_by ? vendor.create_by : this.props.user.id,
                create_date: vendor.create_date ? vendor.create_date : new Date(),
                phone: vendor.phone ? vendor.phone : this.state.phone,
                contact_info: vendor.contact_info ? vendor.contact_info : this.state.contact_info,
                address: vendor.address ? vendor.address : this.state.address,
                province_id: vendor.province_id ? vendor.province_id : this.state.province_id,
                bank_acc: vendor.bank_acc,
                bank_name: vendor.bank_name,
                bank_note: vendor.bank_note,
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let vendor = {
                id: this.state.id,
                name: this.state.name,
                create_by: this.state.create_by,
                create_date: new Date(this.state.create_date),
                contact_info: this.state.contact_info,
                address: this.state.address,
                province_id: this.state.province_id,
                website: this.state.website,
                phone: this.state.phone,
                bank_acc: this.state.bank_acc,
                bank_name: this.state.bank_name,
                bank_note: this.state.bank_note,
            }
            this.props.handleSubmit(vendor);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeFilterDate(_date) {
        let fields = this.state.fields;
        fields['create_date'] = _date;
        this.setState({ create_date: _date });
        this.setState({ fields });
    }

    handleChangeActive(e) {
        this.setState({ active: e.target.checked });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.name)) {
            formIsValid = false;
            errors["name"] = "Vui lòng nhập Tên";
        }

        if (helps.isEmpty(this.state.province_id)) {
            formIsValid = false;
            errors["province_id"] = "Vui lòng chọn Tỉnh thành/Khu Vực";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }


    render() {
        const { provinces } = this.props;

        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader>
                                {this.props.form_header}
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-name">Tên Nhà Cung Cấp:</CLabel>
                                            <CInput
                                                type="text"
                                                name="name"
                                                id="input-name"
                                                value={this.state.name}
                                                required
                                                className='font-weight-bold'
                                                onChange={(e) => { this.handleChange('name', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['name']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-phone">Số ĐT:</CLabel>
                                            <CInput
                                                type="text"
                                                name="phone"
                                                id="input-phone"
                                                value={this.state.phone}
                                                onChange={(e) => { this.handleChange('phone', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['phone']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-website">Website:</CLabel>
                                            <CInput
                                                type="text"
                                                name="website"
                                                id="input-website"
                                                value={this.state.website}
                                                onChange={(e) => { this.handleChange('website', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['website']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-contact_info">Thông Tin Liên Hệ:</CLabel>
                                            <CTextarea
                                                type="text"
                                                name="contact_info"
                                                id="input-contact_info"
                                                rows={5}
                                                value={this.state.contact_info}
                                                onChange={(e) => { this.handleChange('contact_info', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['contact_info']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-bank_acc">TK Ngân Hàng:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="bank_acc"
                                                        id="input-bank_acc"
                                                        value={this.state.bank_acc}
                                                        onChange={(e) => { this.handleChange('bank_acc', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['bank_acc']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CFormGroup>
                                                        <CLabel htmlFor="input-bank_name">Ngân hàng:</CLabel>
                                                        <CInput
                                                            type="text"
                                                            name="bank_name"
                                                            id="input-bank_name"
                                                            value={this.state.bank_name}
                                                            onChange={(e) => { this.handleChange('bank_name', e) }}
                                                        />
                                                        <CFormText className="help-block">// Nhập mã BIN Ngân Hàng. VD: Ngân hàng Techcombank, có thể nhập: <b>Techcombank</b> hoặc <b>TCB</b> hoặc <b>970407</b> <a href='https://www.sbv.gov.vn/webcenter/portal/vi/menu/trangchu/ttvnq/htmtcqht' target='_blank' rel="noreferrer"><i>(tham khảo)</i></a></CFormText>
                                                        <small className="text-danger">{this.state.errors['bank_name']}</small>
                                                    </CFormGroup>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-bank_note">Ghi chú Ngân Hàng:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="bank_note"
                                                        id="input-bank_note"
                                                        value={this.state.bank_note}
                                                        onChange={(e) => { this.handleChange('bank_note', e) }}
                                                    />
                                                    <small className="text-danger">{this.state.errors['bank_note']}</small>
                                                </CCol>
                                            </CRow>
                                        </div>
                                    </CCol>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-address">Địa chỉ:</CLabel>
                                            <CTextarea
                                                type="text"
                                                name="address"
                                                id="input-address"
                                                rows={5}
                                                value={this.state.address}
                                                onChange={(e) => { this.handleChange('address', e) }}
                                            />
                                            <small className="text-danger">{this.state.errors['address']}</small>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-province_id">Tỉnh Thành / Khu Vực:</CLabel>
                                            <CSelect
                                                name="province_id"
                                                id="input-province_id"
                                                value={this.state.province_id}
                                                onChange={(e) => { this.handleChange('province_id', e) }}
                                            >
                                                {provinces && provinces.map((item) => {
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    );
                                                })}
                                            </CSelect>
                                            <small className="text-danger">{this.state.errors['province_id']}</small>
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" color="primary">
                                    <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                    Lưu
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow >
        )
    }
}
export default VendorForm;

