import { customerConstants } from "src/_constants";

export function customerReducer(state = { customers: [] }, actions) {
    switch (actions.type) {

        // get all customer
        case customerConstants.GETALL_REQUEST:
            return {
                loading: true,
                customer_type: actions.customer_type,
            }
        case customerConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: actions.customers,
                customer_type: actions.customer_type,
            }
        case customerConstants.GETALL_FAILURE:
            return {
                ...state,
                error: actions.error
            }

        // get a customer by ID    
        case customerConstants.GET_REQUEST:
            return {
                loading: true
            }
        case customerConstants.GET_SUCCESS:
            return {
                ...state,
                customer: actions.customer,
                loading: false
            }
        case customerConstants.GET_FAILURE:
            return {
                error: actions.error
            };
        // add new customer    
        case customerConstants.ADD_REQUEST:
            return {
                loading: true
            }
        case customerConstants.ADD_SUCCESS:
            var customers = state.customers ? state.customers : [];
            customers.push(actions.customer);
            return {
                ...state,
                customers: customers,
                loading: false
            }
        case customerConstants.ADD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update customer    
        case customerConstants.UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case customerConstants.UPDATE_SUCCESS:
            var _customers = state.customers;
            var foundIndex = _customers.findIndex(item => item.id === actions.customer.id);
            actions.customer['index'] = foundIndex;
            _customers[foundIndex] = actions.customer;
            console.log(foundIndex);
            return {
                ...state,
                loading: false,
                customers: _customers,
                customer: actions.customer,
            }
        case customerConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        // update customer field    
        case customerConstants.UPDATE_FIELD_REQUEST:
            return {
                ...state,
                loading: false
            }
        case customerConstants.UPDATE_FIELD_SUCCESS:
            var _customers = state.customers;
            var foundIndex = _customers.findIndex(item => item.id === actions.customer.id);
            actions.customer['index'] = foundIndex;
            _customers[foundIndex] = actions.customer;
            console.log(foundIndex);
            return {
                ...state,
                loading: false,
                customers: _customers,
                customer: actions.customer,
            }
        case customerConstants.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                error: actions.error
            };
        default:
            return state;
    }
}