import React, { Component } from 'react';
import {
    CCol,
    CRow,
    CButton,
    CLabel,
    CTextarea,
    CInput,
    CModal,
    CModalHeader,
    CModalBody,
    CFormGroup,
    CInputRadio,
    CBadge,
    CPopover,
    CLink,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import Editor from 'src/_components/Editor';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';
import CurrencyInput from 'react-currency-input-field';
import configs from 'src/appConfigs';

class ViolationsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            id: '',
            employee_id: '',
            added_time: new Date(),
            added_by: '',
            title: '',
            content: '',
            violation_date: new Date(),
            reason: '',
            damages: '',
            fines: 0,
            note: '',
            violation_type: {},
            violation_term: {},
            terms: [],
            attachment: '',
            old_attachment: '',
            project_id: '',
            project: {},
            selected_project: {},
            default_type_option: {},
            term_id: '',
            employee: {},
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFiles = this.handleChangeFiles.bind(this);
        this.handleChangeEditor = this.handleChangeEditor.bind(this);
        this.handleChangeSelectType = this.handleChangeSelectType.bind(this);
    }

    componentDidMount() {
        const { violation, employee, projects, violation_types } = this.props;
        //console.log(violation);
        var employee_projects = [];
        let employee_project = {};

        if (!helps.isEmpty(violation)) {
            employee_project = helps.getItemFromArr(projects, violation.project_id);
            this.setState({ project: employee_project, project_id: employee_project.id });
            this.setState(Object.assign(this.state, violation));
            this.setState({ terms: violation.violation_type.terms, term_id: violation.violation_term.id });
            this.setState({ old_attachment: violation.attachment });
            if (violation.type) {
                let type = helps.getItemFromArr(violation_types, violation.violation_type.id, 'value');
                this.setState({ default_type_option: { value: type.id, label: type.name } });
            }
        } else {
            const added_by = this.props.user.id;
            if (!helps.isEmpty(employee)) {
                employee_projects = projects.filter((project) => {
                    if (project.employees) {
                        var found = '';
                        found = helps.getItemFromArr(project.employees, employee.employee_id, 'employee_id');
                        if (!helps.isEmpty(found)) return true;
                        else return false;
                    } else {
                        return false;
                    }
                })
                if (!helps.isEmpty(employee_projects)) {
                    employee_project = employee_projects[0];
                }
                this.setState({ project: employee_project });
                this.setState({
                    employee_id: employee.employee_id,
                    project_id: employee_project.id,
                });
            }
            this.setState({
                errors: {},
                added_time: new Date(),
                added_by: added_by,
                violation_date: new Date(),
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let violation = {
                id: this.state.id,
                employee_id: this.state.employee_id,
                added_time: this.state.added_time,
                added_by: this.state.added_by,
                title: this.state.title,
                content: this.state.content,
                reason: this.state.reason,
                damages: this.state.damages,
                fines: this.state.fines,
                note: this.state.note,
                violation_date: this.state.violation_date,
                violation_type: this.state.violation_type,
                violation_term: this.state.violation_term,
                attachment: this.state.attachment,
                project_id: this.state.project_id,
            }
            this.props.handleSubmit(violation);
        } else {
            return false;
        }
    }

    handleChangeEditor(field, value) {
        //console.log(field, value);
        this.setState({
            [field]: value
        });
    }

    handleChange(field, e) {
        let value = e.target.value;
        this.setState({
            [field]: value
        });

        if (field === 'term_id') {
            let term = helps.getItemFromArr(this.state.terms, value);
            this.setState({ fines: term.fines, violation_term: term });
        }
    }

    handleChangeFiles(field, e) {
        let upload_files = e.target.files;
        this.setState({ [field]: upload_files });
    }

    handleChangeDate(field, value) {
        this.setState({ [field]: value });
    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [field]: selectedOptions.value });
        if (field === 'employee_id') {
            const { employees, projects } = this.props;
            let employee = helps.getItemFromArr(employees, selectedOptions.value, 'employee_id');
            var employee_projects = [];
            let employee_project = {};
            employee_projects = projects.filter((project) => {
                if (project.employees) {
                    var found = '';
                    found = helps.getItemFromArr(project.employees, employee.employee_id, 'employee_id');
                    if (!helps.isEmpty(found)) return true;
                    else return false;
                } else {
                    return false;
                }
            })
            if (!helps.isEmpty(employee_projects)) {
                employee_project = employee_projects[0];
            }
            this.setState({ project: employee_project, project_id: employee_project.id });
        }
    }

    handleChangeSelectType = (newValue, actionMeta) => {
        const { violation_types } = this.props;
        console.group("Value Changed");
        console.log(newValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        if (newValue) {
            var type_id = newValue.value;
            let type = helps.getItemFromArr(violation_types, type_id);
            this.setState({ violation_type: type, terms: type.terms ? type.terms : [] });
            this.setState({ default_type_option: newValue });
        }
    };

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.employee_id)) {
            formIsValid = false;
            errors["employee_id"] = "(Vui lòng chọn Nhân viên.)";
        }

        if (!this.state.violation_date) {
            formIsValid = false;
            errors["violation_date"] = "(Vui lòng chọn Ngày Vi phạm.)";
        }

        if (helps.isEmpty(this.state.violation_type)) {
            formIsValid = false;
            errors["violation_type"] = "(Vui lòng chọn Danh mục Vi phạm.)";
        }

        if (helps.isEmpty(this.state.violation_term)) {
            formIsValid = false;
            errors["violation_term"] = "(Vui lòng chọn Hình thức Xử lý Vi phạm.)";
        }

        if (helps.isEmpty(this.state.title)) {
            formIsValid = false;
            errors["title"] = "(Vui lòng nhập Tiêu đề.)";
        }

        if (helps.isEmpty(this.state.content)) {
            formIsValid = false;
            errors["content"] = "(Vui lòng nhập Nội dung vi phạm.)";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        const { violation_types, employee, projects, employees } = this.props;
        if (helps.isEmpty(projects)) {
            return (<></>);
        }
        var type_options = [];
        if (!helps.isEmpty(violation_types)) {
            violation_types.map((type) => {
                type_options.push({
                    value: type.id,
                    label: type.name,
                });
            });
        }

        var project_options = [];
        var default_project_option = {};
        if (!helps.isEmpty(projects)) {
            projects.map((project) => {
                project_options.push({
                    value: project.id,
                    label: project.name,
                });
            });
        }
        if (!helps.isEmpty(this.state.project)) {
            default_project_option = helps.getItemFromArr(project_options, this.state.project_id, 'value');
        }

        var employee_options = [];
        var default_employee_option = {};
        if (!helps.isEmpty(employees)) {
            employees.map((employee) => {
                employee_options.push({
                    value: employee.employee_id,
                    label: employee.fullname + ' (' + employee.employee_id + ')',
                });
            });
        }
        if (!helps.isEmpty(this.state.employee_id)) {
            default_employee_option = helps.getItemFromArr(employee_options, this.state.employee_id, 'value');
        }

        let item = !helps.isEmpty(this.props.item) ? this.props.item : 'add';
        return (
            <CModal
                size='xl'
                show={this.props.modals.includes(item)}
                onClose={() => { this.props.toggleModal(item) }}
                className='text-left'
            >
                <CModalHeader closeButton>
                    <div>
                        <h3>{this.props.form_header}</h3>
                        <div className='clearfix'>
                            <CRow>
                                <CCol>
                                    {!helps.isEmpty(employee) && <small className='text-muted'>Nhân viên: <b>{employee.fullname} {employee.employee_id}</b></small>}
                                </CCol>
                            </CRow>
                        </div>
                    </div>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol xl={6} md={6} sm={12}>
                            {helps.isEmpty(employee) && <CRow className="mb-3">
                                <CCol md={12}>
                                    <CLabel htmlFor="input-employee_id">Nhân Viên:</CLabel>
                                    <Select
                                        id='input-employee_id'
                                        placeholder='Chọn Nhân viên...'
                                        options={employee_options}
                                        value={default_employee_option}
                                        onChange={(selectedOption) => { this.handleChangeSelect(selectedOption, 'employee_id') }}
                                    />
                                    <small className="help-block text-danger font-italic">{this.state.errors['employee_id']}</small>
                                </CCol>
                            </CRow>}
                            <CRow className="mb-3">
                                <CCol md={6}>
                                    <CLabel htmlFor="input-deadline">Ngày Vi phạm:</CLabel>
                                    <DateTimePicker
                                        name='violation_date'
                                        id='input-violation_date'
                                        className={'ml-2 font-weight-bold'}
                                        value={this.state.violation_date}
                                        onChange={(value) => { this.handleChangeDate('violation_date', value) }}
                                    />
                                    <small className="help-block text-danger font-italic">{this.state.errors['violation_date']}</small>
                                </CCol>
                                <CCol>
                                    <CLabel htmlFor="input-project_id">Mục tiêu:</CLabel>
                                    <Select
                                        id="input-project_id"
                                        placeholder='Mục tiêu ...'
                                        options={project_options}
                                        value={default_project_option}
                                        onChange={(selectedOption) => { this.handleChangeSelect(selectedOption, 'project_id') }}
                                    >
                                    </Select>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={12}>
                                    <CLabel htmlFor="input-type">Vi phạm về:</CLabel>
                                    <Select
                                        id="input-type"
                                        placeholder='Danh mục Vi phạm ...'
                                        options={type_options}
                                        value={this.state.default_type_option}
                                        onChange={this.handleChangeSelectType}
                                    >
                                    </Select>
                                    <small className="help-block text-danger font-italic">{this.state.errors['violation_type']}</small>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={12}>
                                    <CLabel htmlFor="input-type">Hình thức Xử lý:</CLabel>
                                    {!helps.isEmpty(this.state.terms) && this.state.terms.map((term) => {
                                        return (
                                            <CFormGroup key={term.id} variant='custom-radio' className='m-1'>
                                                <CInputRadio
                                                    custom
                                                    id={"input-" + term.id}
                                                    name="term_id"
                                                    value={term.id}
                                                    checked={this.state.term_id === term.id}
                                                    onChange={(e) => { this.handleChange('term_id', e) }}
                                                />
                                                <CPopover
                                                    content={<div dangerouslySetInnerHTML={{ __html: term.description }} />}
                                                >
                                                    <CLabel variant="custom-checkbox" htmlFor={"input-" + term.id} className="cursor-pointer">
                                                        <CBadge color={term.color} className="p-1">{term.title}</CBadge>
                                                        <span className='text-muted ml-1'>Mức phạt: <b>{helps.formatCurrency(term.fines)}</b></span>
                                                    </CLabel>
                                                </CPopover>
                                            </CFormGroup>
                                        )
                                    })}
                                    {helps.isEmpty(this.state.terms) && <div className='text-danger'>Chưa cài đặt Hình thức Xử lý Vi Phạm!</div>}
                                    <small className="text-danger font-italic">{this.state.errors['violation_term']}</small>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={12}>
                                    <CLabel htmlFor="input-fines">Mức phạt:</CLabel>
                                    <CInputGroup>
                                        <CurrencyInput
                                            name="fines"
                                            id="input-fines"
                                            required
                                            className='form-control font-weight-bold lead text-dark'
                                            value={this.state.fines}
                                            decimalsLimit={2}
                                            onValueChange={(value, name) => { this.setState({ [name]: value }) }}
                                        />
                                        <CInputGroupAppend>
                                            <CInputGroupText className={'bg-secondary text-dark'}>
                                                ₫
                                            </CInputGroupText>
                                        </CInputGroupAppend>
                                    </CInputGroup>
                                    <small className="help-block text-danger font-italic">{this.state.errors['fines']}</small>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={12}>
                                    <CLabel htmlFor="input-title">Tiêu đề:</CLabel>
                                    <CInput
                                        type='text'
                                        id='input-title'
                                        name='title'
                                        value={this.state.title}
                                        onChange={(e) => this.handleChange('title', e)}
                                    />
                                    <small className="help-block text-danger font-italic">{this.state.errors['title']}</small>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={12}>
                                    <CLabel htmlFor="input-content">Nội dung vi phạm:</CLabel>
                                    <Editor
                                        {... this.props}
                                        id="input-content"
                                        name="content"
                                        defaultValue={this.state.content}
                                        handleChangeEditor={this.handleChangeEditor}
                                    />
                                    <small className="help-block text-danger font-italic">{this.state.errors['content']}</small>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={12}>
                                    <CLabel htmlFor="input-attachment">Biên bản vi phạm / Biên bản sự việc / Các văn bản QĐ:</CLabel>
                                    {this.state.old_attachment && this.state.old_attachment.length > 0 && this.state.old_attachment.map(file => {
                                        return (
                                            <div>
                                                <small><CIcon name='cil-file' size='sm'></CIcon> <CLink href={file.url} target='_blank'>{file.name}</CLink></small>
                                            </div>
                                        )
                                    })}
                                    <CInput
                                        type='file'
                                        id='input-attachment'
                                        multiple
                                        name='attachment'
                                        onChange={(e) => this.handleChangeFiles('attachment', e)}
                                    />
                                    <small className="help-block text-danger font-italic">{this.state.errors['attachment']}</small>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol xl={6} md={6} sm={12}>
                            <CRow className="mb-3">
                                <CCol md={12}>
                                    <CLabel htmlFor="input-reason">Nguyên nhân / Lý do <i>(nếu có)</i>:</CLabel>
                                    <CTextarea
                                        id="input-reason"
                                        name="reason"
                                        rows={5}
                                        value={this.state.reason}
                                        onChange={(e) => { this.handleChange('reason', e) }}
                                    >
                                    </CTextarea>
                                    <small className="help-block text-danger font-italic">{this.state.errors['reason']}</small>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={12}>
                                    <CLabel htmlFor="input-damages">Hậu quả / Thiệt hại <i>(nếu có)</i>:</CLabel>
                                    <CTextarea
                                        id="input-damages"
                                        name="damages"
                                        rows={5}
                                        value={this.state.damages}
                                        onChange={(e) => { this.handleChange('damages', e) }}
                                    >
                                    </CTextarea>
                                    <small className="help-block text-danger font-italic">{this.state.errors['damages']}</small>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={12}>
                                    <CLabel htmlFor="input-note">Ghi chú:</CLabel>
                                    <Editor
                                        {... this.props}
                                        id="input-note"
                                        name="note"
                                        defaultValue={this.state.note}
                                        handleChangeEditor={this.handleChangeEditor}
                                    />
                                    <small className="help-block text-danger font-italic">{this.state.errors['note']}</small>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                    <hr />
                    <CRow className="mb-3">
                        <CCol md={12}>
                            <CButton type="button" size='sm' color="primary" onClick={(e) => this.handleSubmit(e)}>
                                <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                Lưu
                            </CButton>
                            <CButton type="button" size='sm' color="secondary" onClick={this.props.toggleModal} className="ml-3">
                                <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                Huỷ
                            </CButton>
                        </CCol>
                    </CRow>
                </CModalBody>
            </CModal>
        )
    }
}
export default ViolationsForm;

