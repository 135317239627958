import { documentConstants } from "src/_constants";

export function documentReducer(state = { documents: [] }, actions) {
    switch (actions.type) {
        case documentConstants.GET_LIST_REQUEST:
            return {
                loading: true,
            }
        case documentConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                documents: actions.res.documents,
                folders: actions.res.folders,
                loading: false,
            }
        case documentConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Upload
        case documentConstants.UPLOAD_REQUEST:
            return {
                ...state,
                upload_loading: true,
            }
        case documentConstants.UPLOAD_SUCCESS:
            let documents = [];

            if (state.documents && state.documents.length > 0) {
                documents = state.documents.concat(actions.documents);
            } else {
                documents = actions.documents;
            }
            return {
                ...state,
                upload_loading: false,
                documents: documents,
            }
        case documentConstants.UPLOAD_FAILURE:
            return {
                ...state,
                upload_loading: false,
                error: actions.error,
            }
        // Delete 
        case documentConstants.DELETE_REQUEST:
            return {
                ...state,
                documents: state.documents.map(document =>
                    document._metadata.name === actions.document._metadata.name
                        ? { ...document, deleting: true }
                        : document
                )
            };
        case documentConstants.DELETE_SUCCESS:
            return {
                documents: state.documents.filter(document => document._metadata.name !== actions.document._metadata.name)
            };
        case documentConstants.DELETE_FAILURE:
            return {
                ...state,
                documents: state.documents.map(document => {
                    if (document._metadata.name === actions.document._metadata.name) {
                        const { deleting, ...noteCopy } = document;
                        return { ...noteCopy, deleteError: actions.error };
                    }
                    return document;
                })
            };

        default: return state;
    }
}