import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CRow, CCol,
} from '@coreui/react';
import TasksComponent from 'src/views/tasks/TasksComponent';
import { customerActions, employeeActions, projectActions, taskActions, userActions } from 'src/_actions';
import Loading from '../_loading';

class TasksComponentContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        const { customers, users, projects } = this.props;

        this.getTasks();

        if (!customers) {
            this.props.getCuscomers();
        }

        if (!users) {
            this.props.getUsers();
        }

        if (!projects) {
            this.props.getProjects();
        }

    }

    async getTasks() {
        const { object, object_id } = this.props;
        return await this.props.getTasks({ object: object, object_id: object_id }, 1000);
    }

    render() {
        if (this.props.loading) {
            return (<Loading></Loading>)
        }
        return (
            <CRow>
                <CCol xl={12}>
                    <TasksComponent {... this.props} />
                </CCol>
            </CRow>
        );
    }
}

function mapStateToProps(state) {
    const { loading, tasks } = state.taskReducer;
    const { customers } = state.customerReducer;
    const { projects } = state.projectReducer;
    const { employees } = state.employeeReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    return { loading, user, users, tasks, customers, projects, employees };
}

const actionCreators = {
    getTasks: taskActions.getAll,
    add: taskActions.add,
    update: taskActions.update,
    _delete: taskActions._delete,
    getProjects: projectActions.getAll,
    getCuscomers: customerActions.getAll,
    getEmployees: employeeActions.getAll,
    getUsers: userActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(TasksComponentContainer);
export { connectedContainer as TasksComponentContainer };