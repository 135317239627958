import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CRow, CCol,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import Customer from 'src/views/customers/Customer';
import { customerActions, fileActions, provinceActions } from '../../_actions';
import { userActions } from '../../_actions';
import { helps } from 'src/_helpers';
import { ContactsContainer } from '../contacts';
import { NotesContainer } from '../notes';
import { FilesContainer } from '../files';
import { ContractsContainer } from './contracts';
import { TasksComponentContainer } from '../tasks/TasksComponentContainer';


class CustomerContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: {},
            object: 'customers',
        }
    }

    componentDidMount() {
        this.getCustomers().then(cutomers => {
            this.getCustomer().then(() => {
                this.getFiles().then((files) => {
                    var customer = this.state.customer;
                    customer['files'] = files;
                    this.setState({ customer: customer });
                });
            });
        });
        this.getUsers();
    }

    async getCustomer() {
        let customer_id = this.props.match.params.id;
        const { customers, provinces } = this.props;
        var customer = {};
        if (customer_id && customers) {
            let customer = helps.getItemFromArr(customers, customer_id);
            var province = helps.getItemFromArr(provinces, customer.province_id);
            customer['province'] = province.name;
            this.setState({ customer: customer });
        }
        return customer;
    }

    async getFiles() {
        if (!helps.isEmpty(this.state.customer)) {
            const files = await this.props.getFiles('customers', this.state.customer.id);

            return files;
        }
    }

    async getCustomers() {
        return await this.props.getAll('all');
    }

    async getUsers() {
        const { users } = this.props;
        if (helps.isEmpty(users)) await this.props.getUsers();
    }

    updateObjectTags(customer) {
        this.props.updateField(customer, 'tags');
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.props.customers)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.state.customer)) {
            return (
                <Loading></Loading>
            );
        }
        const customer = this.state.customer;
        var header_text = '';
        switch (customer.type) {
            case 'potential':
                header_text = 'Khách Hàng Tiềm Năng';
                break;
            case 'lead':
                header_text = 'Cơ Hội';
                break;
            default:
                header_text = 'Khách Hàng';
        }
        return (
            <ThePermission {... this.props} component="customers" action="view">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md={7} sm={12} className='mb-2'>
                                {header_text}: <b>{customer.name}{customer.tax_number && <> - {customer.tax_number}</>}</b>
                                {customer.address && <div><small className='text-muted' style={{ fontSize: '13px' }}><CIcon name="cil-location-pin"></CIcon> <i>{customer.address}</i></small></div>}
                            </CCol>
                            <CCol md={5} sm={12} className='mb-2 text-right'>
                                <ThePermission {... this.props} component="customers" action="edit">
                                    <CButton
                                        color="primary"
                                        size="sm"
                                        title="Chỉnh Sửa"
                                        onClick={() => { this.props.history.push(`/customers/add_edit/${customer.id}`) }}
                                    >
                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                        Chỉnh Sửa Khách Hàng
                                    </CButton>
                                </ThePermission>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CTabs activeTab="info">
                            <CNav variant="tabs">
                                <CNavItem>
                                    <CNavLink data-tab="info">
                                        <h6>Thông Tin</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="tasks">
                                        <h6>Công Việc</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="contracts">
                                        <h6>Hợp Đồng</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="contacts">
                                        <h6>Liên Hệ</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="notes">
                                        <h6>Ghi Chú</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="files">
                                        <h6>Tài Liệu</h6>
                                    </CNavLink>
                                </CNavItem>
                            </CNav>
                            <CTabContent className="py-3">
                                <CTabPane data-tab="info">
                                    <Customer {... this.props} customers={this.props.customers} customer={customer} updateObjectTags={(object_value) => { this.updateObjectTags(object_value) }} />
                                </CTabPane>
                                <CTabPane data-tab="tasks">
                                    <TasksComponentContainer {... this.props} object={this.state.object} object_id={customer.id} />
                                </CTabPane>
                                <CTabPane data-tab="contracts">
                                    <ContractsContainer {... this.props} customers={this.props.customers} customer={customer} />
                                </CTabPane>
                                <CTabPane data-tab="contacts">
                                    <ContactsContainer {... this.props} customer_id={customer.id} project_id={''} />
                                </CTabPane>
                                <CTabPane data-tab="notes">
                                    <NotesContainer {... this.props} object={this.state.object} object_id={customer.id} />
                                </CTabPane>
                                <CTabPane data-tab="files">
                                    <FilesContainer {... this.props} object={this.state.object} object_id={customer.id} />
                                </CTabPane>
                            </CTabContent>
                        </CTabs>
                    </CCardBody>
                </CCard>

            </ThePermission >

        );
    }
}

function mapStateToProps(state) {
    const { loading, customers } = state.customerReducer;
    const { users } = state.userReducer;
    const { provinces } = state.provinceReducer;
    const { contacts } = state.contactReducer;
    const { notes } = state.noteReducer;
    const { files } = state.fileReducer;
    return { loading, customers, users, contacts, notes, files, provinces };
}

const actionCreators = {
    getAll: customerActions.getAll,
    updateField: customerActions.updateField,
    getUser: userActions.getUser,
    addUser: userActions.addUser,
    getUsers: userActions.getAll,
    getProvinces: provinceActions.getAll,
    uploadFile: fileActions.uploadFile,
    deleteFile: fileActions.deleteFile,
    getFiles: fileActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(CustomerContainer);
export { connectedContainer as CustomerContainer };