import React from 'react'
import {
  CBadge,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';


const TheHeaderDropdownMssg = (props) => {
  const { latest_notes } = props;
  //console.log(roles, user);
  let _notes = [];
  if (!helps.isEmpty(latest_notes)) {
    for (let cnt = 0; cnt < props.latest_notes.length; cnt++) {
      let note = props.latest_notes[cnt];
      /*
      if (helps.checkPermission(roles, user.role, note.object, 'view') || helps.checkPermission(roles, user.role, note.object, 'edit')) {
        _notes.push(props.latest_notes[cnt]);
      }
      */
      _notes.push(note);
    }
  }
  _notes.sort((a, b) => b.note_time - a.note_time);
  return (
    <CDropdown
      inNav
      className="c-header-nav-item mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CIcon name="cil-bell" /><CBadge shape="pill" color="warning">{!helps.isEmpty(_notes) && <>{_notes.length}</>}</CBadge>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0 header-nav-notes" placement="bottom-end">
        <CDropdownItem
          header
          tag="div"
          color="light"
        >
          <h4>Ghi Chú Mới</h4>
        </CDropdownItem>

        {!helps.isEmpty(_notes) && _notes.map(note => {
          if (helps.isEmpty(note)) return (<></>);
          else {
            let _note_time = helps.formatDateTime_from_Timestamp(note.note_time);
            return (
              <CDropdownItem key={'note_' + note.id} onClick={() => { props.history.push(note.note_object_link) }} className='border-bottom'>
                <CRow style={{ width: '600px' }} >
                  <CCol md={8}>
                    <div className='text-wrap'>{note.content}</div>
                    <div><small className="text-muted font-italic">{_note_time} | {note.note_by_alias}</small></div>
                  </CCol>
                  <CCol md={4}>
                    <div>{note.note_object_alias}</div>
                    <div><small className="text-muted font-italic text-wrap">{note.note_object_name}</small></div>
                  </CCol>
                </CRow>
              </CDropdownItem>
            );
          }
        })
        }

        {/*<CDropdownItem href="#" className="text-center border-top"><CLink to="/">.</CLink></CDropdownItem>*/}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdownMssg;