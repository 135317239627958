import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CRow, CCol, CCard, CCardHeader, CCardBody, CCardFooter, CLabel, CFormGroup,
} from '@coreui/react';
import { checkActions, housingActions, positionActions, projectActions } from '../../_actions';
import { employeeActions } from '../../_actions';
import { helps } from 'src/_helpers';
import Timekeeping from 'src/views/accounting/Timekeeping';
import DatePicker from 'react-datepicker';
import locale from 'date-fns/locale/vi';


class TimekeepingContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            curent_date: new Date(),
            project_options: [],
            project_default_option: {},
            project_id: '',
            projects: [],
            filter_projects: [],
            month: new Date(),
        }
        this.handleChangeMonth = this.handleChangeMonth.bind(this);
    }

    componentDidMount() {
        this.getProjects().then((projects) => {
            var project_options = [];
            //console.log(projects);
            if (!helps.isEmpty(projects) && projects.length > 0) {
                projects.map((project) => {
                    project_options.push({
                        value: project.id,
                        label: project.name,
                    });
                });
                this.setState({ project_options: project_options, projects: projects, filter_projects: projects });
            }
        });
        this.getEmployees();
        this.getPositions();
        this.getHousings();
        this.getChecks({ curent_date: this.state.month });
    }

    async getChecks(params = {}) {
        let _checks = await this.props.getChecks(params, 10000);
        return _checks;
    }

    async getProjects() {
        const { projects } = this.props;
        if (helps.isEmpty(projects)) {
            let _projects = await this.props.getProjects();
            return _projects;
        } else {
            return projects;
        }
    }

    async getEmployees() {
        const { employees } = this.props;
        if (helps.isEmpty(employees)) {
            let _employees = await this.props.getEmployees();
            return _employees;
        }
    }

    async getPositions() {
        const { positions } = this.props;
        if (helps.isEmpty(positions)) {
            let _positions = await this.props.getPositions();
            return _positions;
        }
    }

    async getHousings() {
        const { housings } = this.props;
        if (helps.isEmpty(housings)) {
            let _housings = await this.props.getHousings();
            return _housings;
        }
    }

    handleChangeMonth(value) {
        this.setState({ month: value });
        this.getChecks({ curent_date: value });
    }

    render() {
        let today = new Date().toISOString().slice(0, 10);
        var date_val = today;
        if (this.state.curent_date) {
            date_val = helps.formatDate_for_input(this.state.curent_date.getTime(), 'YYYY-MM-DD');
        }
        return (
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol md={6} sm={12} className='mb-2'>
                            Quản Lý Chấm Công
                        </CCol>
                        <CCol md={6} sm={12} className='mb-2 text-right'>
                            <CFormGroup variant='custom-radio' inline>
                                <CLabel htmlFor="curent_date" className='mt-1'>Tháng: </CLabel>
                                <DatePicker
                                    locale={locale}
                                    selected={this.state.month}
                                    onChange={(date) => { this.handleChangeMonth(date) }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className='ml-2 form-control form-control-bold'
                                />
                            </CFormGroup>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <Timekeeping
                        key={'list-' + this.state.month.toDateString()}
                        {... this.props}
                        user={this.props.user}
                        projects={this.state.filter_projects}
                        month={this.state.month}
                    />
                </CCardBody>
                <CCardFooter>

                </CCardFooter>
            </CCard>
        );
    }
}

function mapStateToProps(state) {
    const { loading, checks } = state.checkReducer;
    const { projects } = state.projectReducer;
    const { employees } = state.employeeReducer;
    const { positions } = state.positionReducer;
    const { user } = state.authentication;
    const { housings } = state.housingReducer;
    return { loading, user, checks, projects, employees, positions, housings };
}

const actionCreators = {
    getChecks: checkActions.getAll,
    getProjects: projectActions.getAll,
    getEmployees: employeeActions.getAll,
    getPositions: positionActions.getAll,
    getHousings: housingActions.getAll,
    add: checkActions.add,
    _delete: checkActions._delete,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(TimekeepingContainer);
export { connectedContainer as TimekeepingContainer };