import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
} from '@coreui/react';
import { ThePermission } from '../ThePermission';
import { userActions, storeActions, employeeActions, vendorActions, projectActions, itemActions, assetActions, housingActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import OUT from 'src/views/assets/OUT';
import configs from 'src/appConfigs';

class OutContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_header: 'Xuất / Bàn Giao',
            categoty_id: 'QTT',
        }
        this.handleOut = this.handleOut.bind(this);
    }

    componentDidMount() {
        let category_id = this.props.match.params.category_id;
        let category = helps.getItemFromArr(configs.asset_categories, category_id);
        this.setState({ page_header: category.label + ": " + this.state.page_header, categoty_id: category_id });
        if (!this.props.users) {
            this.props.getUsers();
        }

        this.getEmployees();
        this.getStores();
        this.getVendors();
        this.getHousings();
        this.getItems();
        this.getProjects();
    }

    async getEmployees() {
        if (helps.isEmpty(this.props.employees)) {
            await this.props.getEmployees();
        }
    }

    async getStores() {
        if (helps.isEmpty(this.props.stores)) {
            await this.props.getStores();
        }
    }

    async getVendors() {
        if (helps.isEmpty(this.props.vendors)) {
            await this.props.getVendors();
        }
    }

    async getHousings() {
        if (helps.isEmpty(this.props.housings)) {
            await this.props.getHousings();
        }
    }

    async getItems() {
        await this.props.getItems();
    }

    async getProjects() {
        if (helps.isEmpty(this.props.projects)) {
            await this.props.getProjects();
        }
    }

    handleOut(record) {
        const { items, stores } = this.props;
        console.log(items);
        var url = '/assets/QTT';
        let asset = {
            asset_date: record.asset_date,
            create_by: this.props.user.id,
            create_date: new Date(),
            from_to: record.from_to,
            state: record.state,
            store_id: record.store_id,
            project_id: record.project_id,
            housing_id: record.housing_id,
            employee_id: record.employee_id,
            vendor_id: record.vendor_id,
            category_id: record.category_id,
        }
        if (helps.isEmpty(record.id)) {
            if (!helps.isEmpty(record.selected_items) && record.selected_items.length > 0) {
                let asset_items = [];
                record.selected_items.map((selected_item) => {
                    let asset_item = {
                        create_by: this.props.user.id,
                        create_date: new Date(),
                        item_id: selected_item.item_id,
                        dimension: selected_item.dimension,
                        quantity: selected_item.quantity,
                    }
                    var item = helps.getItemFromArr(items, selected_item.item_id);
                    let item_inventory = !helps.isEmpty(item.inventory) ? item.inventory : [];
                    if (helps.isEmpty(item_inventory)) {
                        asset_item['pre'] = 0;
                        asset_item['inventory'] = asset_item['pre'] - parseInt(asset_item.quantity);
                        if (!helps.isEmpty(stores)) {
                            stores.map((store) => {
                                item_inventory.push({
                                    store_id: store.id,
                                    current_inventory: asset_item['inventory'],
                                });
                            })
                        }
                    } else {
                        let found_index = item_inventory.findIndex(_inventory => _inventory.store_id === asset.store_id);
                        let item_store = helps.getItemFromArr(item_inventory, asset.store_id, 'store_id');
                        if (helps.isEmpty(item_store)) {
                            asset_item['pre'] = 0;
                            asset_item['inventory'] = asset_item['pre'] - parseInt(asset_item.quantity);
                            item_inventory.push({
                                store_id: asset.store_id,
                                current_inventory: asset_item.inventory,
                            });
                        } else {
                            let updated_inventory = parseInt(item_store.current_inventory) - parseInt(asset_item.quantity);
                            item_inventory[found_index] = { store_id: asset.store_id, current_inventory: updated_inventory };
                            asset_item['pre'] = parseInt(item_store.current_inventory);
                            asset_item['inventory'] = updated_inventory;
                        }
                    }
                    item['inventory'] = helps.unigByKeepLast(item_inventory, it => it.store_id);
                    this.props.updateItemField(item, 'inventory');
                    //console.log(item);
                    asset_items.push(asset_item);
                });
                asset['asset_items'] = asset_items;
                //console.log(asset);
                this.props.addAsset(asset);
            }
        } else {
            this.props.update(record);
        }
        this.props.history.push(url);
    }

    render() {

        return (
            <ThePermission {... this.props} component="assets" action="edit">
                <CRow key={helps.convertToSlug(this.state.page_header)}>
                    <CCol xl={12}>
                        <CCard>
                            <CCardHeader>
                                <CRow>
                                    <CCol md={4} sm={12}>
                                        <h1 className='mb-1'>{this.state.page_header}</h1>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCardBody>
                                <OUT {... this.props} handleOut={this.handleOut} category_id={this.state.categoty_id} />
                            </CCardBody >
                        </CCard >
                    </CCol >
                </CRow >
            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { users } = state.userReducer;
    const { vendors } = state.vendorReducer;
    const { stores } = state.storeReducer;
    const { employees } = state.employeeReducer;
    const { projects } = state.projectReducer;
    const { items } = state.itemReducer;
    const { housings } = state.housingReducer;
    return { user, users, vendors, stores, employees, projects, items, housings };
}

const actionCreators = {
    addAsset: assetActions.add,
    getUsers: userActions.getAll,
    getEmployees: employeeActions.getAll,
    getVendors: vendorActions.getAll,
    getStores: storeActions.getAll,
    getProjects: projectActions.getAll,
    getProjects: housingActions.getAll,
    getItems: itemActions.getAll,
    updateItemField: itemActions.updateField,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(OutContainer);
export { connectedContainer as OutContainer };