import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CCol,
    CRow,
} from '@coreui/react';
import { helps } from 'src/_helpers';
import CreatableSelect from 'react-select/creatable';

class TagsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount() {

    }

    handleEdit(e, tag) {
        e.preventDefault();
        this.props.handleEdit(tag);
    }

    handleChangeSelect = async (newValue, actionMeta) => {
        const { object_value } = this.props;
        console.group("Value Changed");
        console.log(newValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        if (newValue) {
            var tag_id = '';
            if (actionMeta.action === 'create-option') {
                let _new = newValue[newValue.length - 1];
                let tag = {
                    name: _new.value,
                    color: 'primary',
                    object: this.props.object,
                }
                tag_id = await this.props.addTag(tag);
                newValue[newValue.length - 1] = {
                    value: tag_id,
                    label: _new.label,
                }
            }

            object_value['tags'] = newValue;
            console.log(newValue);
            this.props.updateObjectTags(object_value);
        }

    };

    handleDelete(e, tag) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Nhãn: "' + tag.name + '"?');
        if (_confirm) {
            this.props.handleDelete(tag);
        }
        return false;
    }

    render() {
        const { tags, object_value } = this.props;

        var default_options = object_value.tags;

        var tag_options = [];
        if (!helps.isEmpty(tags)) {
            tags.map((tag) => {
                tag_options.push({
                    value: tag.id,
                    label: tag.name,
                });
            });
        }
        return (
            <CRow>
                <CCol xl={12}>
                    <ThePermission {... this.props} component={this.props.object} action="edit">
                        <CreatableSelect
                            id="input-tag"
                            placeholder='Thêm Nhãn ...'
                            isMulti
                            createOptionPosition={"first"}
                            formatCreateLabel={(inputValue) => '+ Thêm: ' + inputValue}
                            options={tag_options}
                            defaultValue={default_options}
                            onChange={this.handleChangeSelect}
                        >

                        </CreatableSelect>
                    </ThePermission>
                </CCol>
            </CRow>
        )
    }
}
export default TagsComponent;
