import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';

import { CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CRow, CCard, CCardHeader, CCardBody, CCol } from '@coreui/react';
import { ThePermission } from '../ThePermission';
import RolesForm from '../../views/settings/RolesForm';
import { TagsContainer } from '../tags';
import { generalActions } from '../../_actions';
import { ProvincesContainer } from '../provinces/ProvincesContainer';
import { ApprovalsContainer } from './ApprovalsContainer';

class SettingsContainer extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.roles)
            this.props.getAllRoles();
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        return (
            <CRow>
                <CCol xs="12" md="12" className="mb-4">
                    <ThePermission {... this.props} component="settings" action="view" alertOut={true}>
                        <CCard>
                            <CCardHeader>
                                <h2>Hệ Thống</h2>
                            </CCardHeader>
                            <CCardBody>
                                <CTabs activeTab="home">
                                    <CNav variant="tabs">
                                        <CNavItem>
                                            <CNavLink data-tab="home">
                                                <h5>Phân Quyền</h5>
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink data-tab="approvals">
                                                <h5>Phê Duyệt</h5>
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink data-tab="provinces">
                                                <h5>Tỉnh Thành</h5>
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink data-tab="tags">
                                                <h5>Nhãn</h5>
                                            </CNavLink>
                                        </CNavItem>
                                    </CNav>
                                    <CTabContent className="py-3">
                                        <CTabPane data-tab="home">
                                            <RolesForm {... this.props} roles={this.props.roles} />
                                        </CTabPane>
                                        <CTabPane data-tab="approvals">
                                            <ApprovalsContainer {... this.props} />
                                        </CTabPane>
                                        <CTabPane data-tab="provinces">
                                            <ProvincesContainer {... this.props} provinces={this.props.provinces} />
                                        </CTabPane>
                                        <CTabPane data-tab="tags">
                                            <TagsContainer {... this.props} tags={this.props.tags} />
                                        </CTabPane>
                                    </CTabContent>
                                </CTabs>
                            </CCardBody>
                        </CCard>
                    </ThePermission>
                </CCol>
            </CRow>

        )
    }
}

function mapStateToProps(state) {
    const { loading, roles } = state.rolesReducer;
    const { tags } = state.tagReducer;
    const { provinces } = state.provinceReducer;
    const { users } = state.userReducer;
    return { loading, roles, tags, provinces, users };
}

const actionCreators = {
    getAllRoles: generalActions.getAllRoles,
    updateRoles: generalActions.updateRoles
};

const connectedContainer = connect(mapStateToProps, actionCreators)(SettingsContainer);
export { connectedContainer as SettingsContainer };