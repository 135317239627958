import { candidateConstants } from 'src/_constants';
import { candidateService } from 'src/_services';
import { alertActions } from '..';

export const candidateActions = {
    setParams,
    getAll,
    add,
    getById,
    update,
    _delete,
};

function setParams(params = {}) {
    return dispatch => {
        dispatch({ type: candidateConstants.SET_PARAMS_REQUEST, params });
    }
}

function getAll() {
    return dispatch => {
        dispatch(request());
        return candidateService.getAll()
            .then(
                candidates => {
                    dispatch(success(candidates));
                    return candidates;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: candidateConstants.GETALL_REQUEST } }
    function success(candidates) { return { type: candidateConstants.GETALL_SUCCESS, candidates } }
    function failure(error) { return { type: candidateConstants.GETALL_FAILURE, error } }
}

/**
 * 
 * @param {string} candidate_id 
 * @returns {Object} candidate 
 */
function getById(candidate_id) {

    return dispatch => {
        dispatch(request());
        return candidateService.getById(candidate_id)
            .then(
                candidate => {
                    dispatch(success(candidate));
                    return candidate;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: candidateConstants.GET_REQUEST } }
    function success(candidate) { return { type: candidateConstants.GET_SUCCESS, candidate } }
    function failure(error) { return { type: candidateConstants.GET_FAILURE, error } }
}

function add(candidate) {
    return dispatch => {
        dispatch(request(candidate));
        return candidateService.add(candidate)
            .then(
                candidate => {
                    dispatch(success(candidate));
                    dispatch(alertActions.success('Thêm mới Ứng viên thành công!'));
                    return candidate;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(candidate) { return { type: candidateConstants.ADD_REQUEST, candidate } }
    function success(candidate) { return { type: candidateConstants.ADD_SUCCESS, candidate } }
    function failure(error) { return { type: candidateConstants.ADD_FAILURE, error } }
}

function update(candidate) {
    return dispatch => {
        dispatch(request(candidate));
        return candidateService.update(candidate)
            .then(
                candidate => {
                    dispatch(success(candidate));
                    dispatch(alertActions.success('Cập nhật thông tin Ứng viên thành công!'));
                    return candidate;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(candidate) { return { type: candidateConstants.UPDATE_REQUEST, candidate } }
    function success(candidate) { return { type: candidateConstants.UPDATE_SUCCESS, candidate } }
    function failure(error) { return { type: candidateConstants.UPDATE_FAILURE, error } }
}

function _delete(candidate) {
    return dispatch => {
        dispatch(request(candidate));
        return candidateService._delete(candidate)
            .then(
                candidate => {
                    dispatch(success(candidate));
                    dispatch(alertActions.success('Xoá Ứng Viên thành công!'));
                    return candidate;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(candidate) { return { type: candidateConstants.DELETE_REQUEST, candidate: candidate } }
    function success(candidate) { return { type: candidateConstants.DELETE_SUCCESS, candidate: candidate } }
    function failure(error) { return { type: candidateConstants.DELETE_FAILURE, error } }
}
