import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
    CCol,
    CRow,
    CBadge,
    CPopover,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import allLocales from '@fullcalendar/core/locales-all'


class InterviewCalendar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            object: '',
            object_id: '',
            filter_by_priority: '',
        }
        this.handleEventClick = this.handleEventClick.bind(this);
    }

    componentDidMount() {

    }

    handleChange(e, field) {
        let value = e.target.value;
        this.setState({ [field]: value });
    }

    handleDelete(e, candidate) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá: ' + candidate.title + ' ?');
        if (_confirm) {
            this.props._delete(candidate);
        }
        return false;
    }

    handleEventClick(clickInfo) {
        let event = clickInfo.event;
        console.log(clickInfo.event.id);
        this.props.history.push('/employees/candidates/view/' + event.id);
    }

    renderEventContent(eventInfo) {
        let candidate = eventInfo.event.extendedProps;
        var added_date = '';
        if (candidate.added_date) {
            added_date = helps.formatDateTime_from_Timestamp(candidate.added_date);
        }
        let interview_result = candidate.interview_result ? candidate.interview_result : 'not_interviewed_yet';
        let _the_result = helps.getItemFromArr(configs.interview_results, interview_result);

        let priority = helps.getItemFromArr(configs.candidate_priorities, candidate.priority);
        var _class = 'text-wrap';
        if (candidate.status === 'finished') {
            _class = 'text-success text-wrap';
        }
        return (
            <div className='ml-2'>
                <CPopover
                    content={
                        <div>
                            <h6 className='border-bottom pb-1'>UV: <b>{candidate.fullname}</b></h6>
                            {candidate.birthyear &&
                                <div className='mt-1'>
                                    <u><i>Năm sinh: </i></u>{candidate.birthyear && <span>: {candidate.birthyear}</span>}
                                </div>
                            }
                            {candidate.position &&
                                <div className='mt-1'>
                                    <u><i>Vị trí: </i></u>{candidate.position && <span>: {candidate.position}</span>}
                                </div>
                            }
                            {candidate.interview_result &&
                                <div>
                                    <div className='mt-1'>
                                        <u><i>Lịch PV:</i></u><b> {helps.formatDateTime_from_Timestamp(candidate.interview_time)}</b>
                                    </div>
                                    <div className='mt-1'>
                                        <u><i>Kết quả PV:</i></u><small> <CBadge color={_the_result.color} className='p-2'>{_the_result.label}</CBadge></small>
                                    </div>
                                    <div className='mt-1'>
                                        <u><i>Người PV:</i></u>
                                        <div>
                                            {!helps.isEmpty(candidate.interview_by) && candidate.interview_by.length > 0 && candidate.interview_by.map((_by, index) => {
                                                return (<div key={_by.value} className='mt-1 ml-1'>{_by.label}</div>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }
                            <hr />
                            <div>
                                <small className='text-muted'><i><u>Bởi:</u></i> <b>{candidate.added_by_alias}</b> {added_date && <><div><i><u>Ngày:</u></i> {added_date}</div></>}</small>
                            </div>
                        </div>
                    }
                >
                    <span className={'cursor-pointer mt-1 mb-1 swap-text' + ' text-' + _the_result.color}>
                        <CBadge color={priority.color}>{priority.label}</CBadge> <span className={_class} style={{
                            textDecoration: (candidate.status === 'finished') ? 'line-through' : 'none',
                        }}>{eventInfo.event.title}</span>
                    </span>
                </CPopover>
            </div>
        );
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        const { candidates, user, filter } = this.props;
        const now = new Date();
        var _candidates = !helps.isEmpty(candidates) ? candidates : [];
        if (_candidates && _candidates.length > 0) {
            _candidates.map((candidate) => {
                candidate['date'] = candidate.interview_time ? candidate.interview_time : null;
                candidate['title'] = candidate.fullname;
            })
        }
        //console.log(_candidates)
        return (
            <CRow>
                <CCol xl={12}>
                    <FullCalendar
                        locales={allLocales}
                        locale={'vn'}
                        themeSystem="bootstrap"
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        weekends={true}
                        events={_candidates}
                        eventContent={this.renderEventContent}
                        eventBackgroundColor={'#FFF'}
                        eventColor={'#FFF'}
                        eventTextColor={'#333'}
                        eventClick={this.handleEventClick}
                    />
                </CCol>
            </CRow >
        )
    }
}

export default InterviewCalendar;
