import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
} from '@coreui/react';
import { ThePermission } from '../ThePermission';
import { assetActions, userActions, storeActions, itemActions, projectActions, vendorActions, employeeActions, housingActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import QTTs from 'src/views/assets/QTTs';
import configs from 'src/appConfigs';
import { asset_itemActions } from 'src/_actions/assets/asset_item.actions';

class QTTContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_header: 'Quản Lý Quân Tư Trang',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {
        if (!this.props.users) {
            this.props.getUsers();
        }
        let category_id = this.props.match.params.category_id;
        let category = helps.getItemFromArr(configs.asset_categories, category_id);
        this.setState({ page_header: "Quản Lý Nhập/Xuất: " + category.label });
        let params = {};
        this.getStores();
        this.getItems();
        this.getEmployees();
        this.getVendors();
        this.getHousings();
        this.getProjects();
        this.getAssets().then(() => {
            let assets = this.props.assets;
            let assets_ids = [];
            if (!helps.isEmpty(assets)) {
                assets.map((asset) => {
                    assets_ids.push(asset.id);
                });
            }
            if (!helps.isEmpty(assets_ids)) {
                params['asssets_ids'] = assets_ids;
            }
            this.getAssetItems(params);
        });
    }

    async getAssets(params = {}) {
        await this.props.getAssets(params);
    }

    async getAssetItems(params = {}) {
        await this.props.getAssetItems(params);
    }

    async getStores() {
        if (helps.isEmpty(this.props.stores)) {
            await this.props.getStores();
        }
    }

    async getItems() {
        await this.props.getItems();
    }

    async getEmployees() {
        if (helps.isEmpty(this.props.employees)) {
            await this.props.getEmployees();
        }
    }

    async getProjects() {
        if (helps.isEmpty(this.props.projects)) {
            await this.props.getProjects();
        }
    }

    async getVendors() {
        if (helps.isEmpty(this.props.vendors)) {
            await this.props.getVendors();
        }
    }

    async getHousings() {
        if (helps.isEmpty(this.props.housings)) {
            await this.props.getHousings();
        }
    }

    handleChange(field, e) {
        this.setState({
            [field]: e.target.value
        });
    }

    handleChangeSelect(selectedOptions) {
        if (selectedOptions) {
            this.setState({ 'province_id': selectedOptions.value });
        } else {
            this.setState({ province_id: '' });
        }

    }

    render() {

        return (
            <ThePermission {... this.props} component="assets" action="view">
                <CRow key={helps.convertToSlug(this.state.page_header)}>
                    <CCol xl={12}>
                        <CCard>
                            <CCardHeader>
                                <CRow>
                                    <CCol md={4} sm={12}>
                                        <h1 className='mb-1'>{this.state.page_header}</h1>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCardBody>
                                <QTTs {... this.props} />
                            </CCardBody >
                        </CCard >
                    </CCol >
                </CRow >
            </ThePermission>
        );
    }
}

function mapStateToProps(state) {
    const { loading, assets, asset_items } = state.assetReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    const { stores } = state.storeReducer;
    const { items } = state.itemReducer;
    const { employees } = state.employeeReducer;
    const { projects } = state.projectReducer;
    const { vendors } = state.vendorReducer;
    const { housings } = state.housingReducer;
    return { user, users, loading, assets, asset_items, stores, items, employees, projects, vendors, housings };
}

const actionCreators = {
    getAssets: assetActions.getAll,
    getAssetItems: asset_itemActions.getAll,
    getUsers: userActions.getAll,
    getStores: storeActions.getAll,
    getItems: itemActions.getAll,
    getProjects: projectActions.getAll,
    getEmployees: employeeActions.getAll,
    getVendors: vendorActions.getAll,
    getHousings: housingActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(QTTContainer);
export { connectedContainer as QTTContainer };