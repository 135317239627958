import React, { Component } from 'react';
import { connect } from 'react-redux';
import "firebase/auth";
import "firebase/firestore";

import './appConfigs';
import './dbConfigs';

import { history } from './_helpers';

import { Route, Switch, Redirect, Router } from 'react-router-dom';
import './scss/style.scss';
import { LoginPageContainer } from './containers/pages/LoginPageContainer';
import TheLayout from './containers/TheLayout';
import { alertActions, generalActions, userActions } from './_actions';
import { helps } from './_helpers';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

console.warn = () => { };

// Containers
//const TheLayout = React.lazy(() => import('./containers/TheLayout'));


// Pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));



class App extends Component {

  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      if (this.props.alert.message)
        this.props.clearAlert();
    });

  }

  componentDidMount() {
    this.prepareData();
  }

  async prepareData() {
    if (helps.isEmpty(this.props.roles)) await this.props.getAllRoles(true);
    if (helps.isEmpty(this.props.users)) await this.props.getUsers();
    if (helps.isEmpty(this.props.approvals)) await this.props.getApprovals();
  }

  render() {

    const isLoggedIn = this.props.loggedIn;
    return (
      <Router history={history}>
        <React.Suspense fallback={loading}  >
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <LoginPageContainer {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route
              path="/"
              name="Home"
              render={props => isLoggedIn ? (<TheLayout {...props} clearAlert={this.props.clearAlert} history={history} />) : (<Redirect from="/" to={{ pathname: "/login" }} />)}
            />

          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}
function mapStateToProps(state) {
  const { alert } = state;
  const { loggedIn } = state.authentication;
  const { roles } = state.rolesReducer;
  const { users } = state.userReducer;
  const { approvals } = state.generalReducer;
  return { alert, loggedIn, roles, users, approvals };
}

const actionCreators = {
  clearAlert: alertActions.clear,
  getAllRoles: generalActions.getAllRoles,
  getUsers: userActions.getAll,
  getApprovals: generalActions.getApprovals,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(App);
export { connectedContainer as App };

