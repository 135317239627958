export const employeeConstants = {
    SET_PARAMS_REQUEST: 'EMPLOYEES_SET_PARAMS_REQUEST',

    GETALL_REQUEST: 'EMPLOYEES_GETALL_REQUEST',
    GETALL_SUCCESS: 'EMPLOYEES_GETALL_SUCCESS',
    GETALL_FAILURE: 'EMPLOYEES_GETALL_FAILURE',

    DELETE_REQUEST: 'EMPLOYEES_DELETE_REQUEST',
    DELETE_SUCCESS: 'EMPLOYEES_DELETE_SUCCESS',
    DELETE_FAILURE: 'EMPLOYEES_DELETE_FAILURE',

    GET_REQUEST: 'EMPLOYEES_GET_REQUEST',
    GET_SUCCESS: 'EMPLOYEES_GET_SUCCESS',
    GET_FAILURE: 'EMPLOYEES_GET_FAILURE',

    ADD_REQUEST: 'EMPLOYEES_ADD_REQUEST',
    ADD_SUCCESS: 'EMPLOYEES_ADD_SUCCESS',
    ADD_FAILURE: 'EMPLOYEES_ADD_FAILURE',

    UPDATE_REQUEST: 'EMPLOYEES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'EMPLOYEES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'EMPLOYEES_UPDATE_FAILURE',

    UPDATE_FIELD_REQUEST: 'EMPLOYEES_UPDATE_FIELD_REQUEST',
    UPDATE_FIELD_SUCCESS: 'EMPLOYEES_UPDATE_FIELD_SUCCESS',
    UPDATE_FIELD_FAILURE: 'EMPLOYEES_UPDATE_FIELD_FAILURE',

    UPLOAD_LETTER_REQUEST: 'EMPLOYEES_UPLOAD_LETTER_REQUEST',
    UPLOAD_LETTER_SUCCESS: 'EMPLOYEES_UPLOAD_LETTER_SUCCESS',
    UPLOAD_LETTER_FAILURE: 'EMPLOYEES_UPLOAD_LETTER_FAILURE',

    DELETE_LETTER_REQUEST: 'EMPLOYEES_DELETE_LETTER_REQUEST',
    DELETE_LETTER_SUCCESS: 'EMPLOYEES_DELETE_LETTER_SUCCESS',
    DELETE_LETTER_FAILURE: 'EMPLOYEES_DELETE_LETTER_FAILURE',
}