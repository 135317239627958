import React, { Component } from 'react'
import {
    CCol,
    CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { ThePermission } from 'src/containers/ThePermission';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import { TagsComponentContainer } from 'src/containers/tags/TagsComponentContainer';

class Customer extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }

    }

    componentDidMount() {

    }


    render() {
        const { customer, users } = this.props;
        //console.log(customer)
        if (helps.isEmpty(customer)) {
            return (
                <Loading></Loading>
            );
        }
        var create_date = '';
        if (customer.create_date) {
            create_date = customer.create_date.toLocaleDateString();
        }
        let create_by_alias = '';
        if (!helps.isEmpty(customer.create_by)) {
            create_by_alias = helps.getUserAlias(users, customer.create_by);
        }
        return (
            <CRow className="mt-3">
                <CCol xl={12}>
                    <CRow>
                        <CCol md={6}>
                            <CRow className="mb-3">
                                <CCol>
                                    Tên: <b>{customer.name}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    MST: <b>{customer.tax_number}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Đại diện Pháp Luật / GĐ: <b>{customer.legal_representative}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Điện thoại: <b>{customer.phone}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Email: <b>{customer.email}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Website: <b>{customer.website}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Địa Chỉ: <b>{customer.address}</b>
                                </CCol>
                            </CRow>
                            <hr />
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Ngày Khởi Tạo: <b>{create_date}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Tạo bởi: <b>{create_by_alias}</b>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol md={6}>
                            <CRow className="mb-3">
                                <CCol md={4}>
                                    Tỉnh Thành / Khu Vực: <b>{customer.province}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol xl={12}>
                                    <b>Nhãn: </b>
                                    <TagsComponentContainer {... this.props} object='customers' object_id={customer.id} object_value={customer} />
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        )
    }
}
export default Customer;
