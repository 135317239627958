import { generalConstants } from '../_constants';

const initialState = {
    sidebarShow: 'responsive',
}

export function toggleSidebar(state = initialState, action) {
    switch (action.type) {
        case generalConstants.SET_SIDEBAR:
            return {
                sidebarShow: !state.sidebarShow
            }

        default:
            return state;
    }
}

export function rolesReducer(state = {}, actions) {
    switch (actions.type) {
        case generalConstants.ROLES_GET_ALL_REQUEST:
            return {
                loading: true
            }
        case generalConstants.ROLES_GET_ALL_SUCCESS:
            return {
                loading: false,
                roles: actions.roles
            }
        case generalConstants.ROLES_GET_ALL_FAILURE:
            return {
                error: actions.error
            };

        case generalConstants.ROLES_UPDATE_ROLES_REQUEST:
            return {
                loading: true
            }
        case generalConstants.ROLES_UPDATE_ROLES_SUCCESS:
            return {
                loading: false,
                roles: actions.roles
            }
        case generalConstants.ROLES_UPDATE_ROLES_FAILURE:
            return {
                error: actions.error
            };

        default:
            return state;
    }
}

export function generalReducer(state = {}, actions) {
    switch (actions.type) {
        // GET APPROVALS
        case generalConstants.APPROVALS_GET_ALL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case generalConstants.APPROVALS_GET_ALL_SUCCESS:
            return {
                ...state,
                approvals: actions.approvals,
                loading: false,
            }
        case generalConstants.APPROVALS_GET_ALL_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Add Approval
        case generalConstants.APPROVALS_ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case generalConstants.APPROVALS_ADD_SUCCESS:
            state.approvals.push(actions.approval);
            return {
                ...state,
                loading: false,
            }
        case generalConstants.APPROVALS_ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Update Approval
        case generalConstants.APPROVALS_UPDATE_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case generalConstants.APPROVALS_UPDATE_SUCCESS:
            var _approvals = state.approvals;
            var foundIndex = _approvals.findIndex(approval => approval.id === actions.approval.id);
            _approvals[foundIndex] = actions.approval;
            return {
                ...state,
                approvals: _approvals,
                loading: false,
            }
        case generalConstants.APPROVALS_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        default: return state;
    }
}