import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CLink,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from 'src/_helpers';
import EmployeeCertificatesForm from './EmployeeCertificatesForm';
import Loading from 'src/containers/_loading';
import { fileService } from 'src/_services';
import configs from 'src/appConfigs';


class EmployeeCertificates extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleActive = this.handleActive.bind(this);
    }

    componentDidMount() {
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
    }

    handleDelete(e, certificate) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Chứng chỉ: ' + certificate.name + ' ?');
        if (_confirm) {
            const { employee } = this.props;
            let certificates = !helps.isEmpty(employee.certificates) ? employee.certificates : [];
            let _certificates = certificates.filter(_certificate => _certificate.id !== certificate.id);
            employee['certificates'] = _certificates;

            this.props.updateField(employee, 'certificates');
        }
        return false;
    }

    async handleSubmit(certificate, action = 'add') {
        const { employee, user } = this.props;
        let certificates = !helps.isEmpty(employee.certificates) ? employee.certificates : [];
        var uploaded_files = [];

        if (typeof certificate.upload_files !== 'undefined' && certificate.upload_files !== null) {
            uploaded_files = await fileService.uploadFile(certificate.upload_files, 'employees', employee.employee_id, user)
        }

        let attachment = !helps.isEmpty(certificate.attachment) ? certificate.attachment : [];
        if (!helps.isEmpty(uploaded_files) && uploaded_files.length > 0) {
            uploaded_files.forEach((uploaded_file) => {
                attachment.push({
                    name: uploaded_file.name,
                    url: uploaded_file.url,
                });
            });
        }
        certificate['attachment'] = attachment;
        delete certificate.upload_files;
        if (action === 'edit') {
            var foundIndex = certificates.findIndex(item => item.id === certificate.id);
            certificates[foundIndex] = certificate;
        } else {
            certificate['id'] = helps.convertToSlug(helps.toNonAccentVietnamese(certificate.name) + '-' + new Date().getTime());
            certificates.push(certificate);
        }

        employee.certificates = certificates;
        this.props.updateField(employee, 'certificates');

        if (action === 'add') {
            this.props.toggleEditForms({ id: 'add_form' });
        } else {
            this.props.toggleEditForms(certificate);
        }
    }

    handleActive(e) {
        const { certificates } = this.props;
        let cur_active_certificates = certificates.filter(certificate => certificate.active > 0);
        if (!helps.isEmpty(cur_active_certificates) && cur_active_certificates.length > 0) {
            cur_active_certificates.forEach((certificate) => {
                certificate['active'] = 0;
                this.props.update(certificate);
            });
        }
        var active_certificate = helps.getItemFromArr(certificates, e.target.value);
        if (active_certificate) {
            active_certificate['active'] = 1;
            this.props.update(active_certificate);
        }
    }



    render() {
        const { employee, user, users } = this.props;
        let certificates = employee.certificates ? employee.certificates : [];
        let count = certificates.length ? certificates.length : 0;

        return (
            <CRow>
                <CCol xl={12}>
                    <EmployeeCertificatesForm
                        {...this.props}
                        certificates={certificates}
                        handleSubmit={this.handleSubmit}
                        toggleEditForms={this.props.toggleEditForms}
                        item={{ id: 'add_form' }}
                        action='add'
                        certificate={{}}
                    />
                    <div className='certificates-container table-responsive'>
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th style={{ width: '25%' }}>Loại</th>
                                    <th>Tên</th>
                                    <th>Đính Kèm</th>
                                    <th>Ghi Chú</th>
                                    <th>Bởi</th>
                                    <th className='text-center' style={{ width: '15%' }}>Thao Tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {helps.isEmpty(certificates) && <tr><td colSpan={8}><h5 className='text-secondary text-center mt-5'> Không tìm thấy Chứng chỉ nào!</h5></td></tr>}
                                {this.props.loading && <tr><td colSpan={8}><Loading></Loading></td></tr>}
                                {certificates && certificates.map((certificate, index) => {
                                    let category = helps.getItemFromArr(configs.employee_certificates, certificate.category_id);
                                    return (
                                        <tr key={certificate.id}>
                                            <td>
                                                {count - index}
                                            </td>
                                            <td>
                                                {category.label}
                                            </td>
                                            <td>{certificate.name}</td>
                                            <td>
                                                {!helps.isEmpty(certificate.attachment) && certificate.attachment.map(file => {
                                                    return (
                                                        <li>
                                                            <CLink href={file.url} target='_blank'>{file.name}</CLink>
                                                        </li>
                                                    );
                                                })}
                                            </td>
                                            <td>{certificate.note}</td>
                                            <td>
                                                <small className='text-muted'>
                                                    <span >{helps.getUserAlias(users, certificate.added_by)}</span>
                                                    <div>
                                                        {helps.formatDateTime_from_Timestamp(certificate.added_time)}
                                                    </div>
                                                </small>
                                            </td>
                                            <td className='text-center'>
                                                <CTooltip content={'Sửa Chứng chỉ: ' + certificate.id}>
                                                    <CButton type="button" size="sm" color="primary" title='Sửa' onClick={() => { this.props.toggleEditForms(certificate) }}>
                                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                                    </CButton>
                                                </CTooltip>
                                                {(user.id === certificate.added_by || user.role === 'administrator') && <>
                                                    <CTooltip content={'Xóa Chứng chỉ: ' + certificate.id}>
                                                        <CButton
                                                            color="danger"
                                                            size="sm"
                                                            title="Xoá"
                                                            className="ml-1"
                                                            onClick={(e) => { this.handleDelete(e, certificate) }}
                                                        >
                                                            <CIcon name="cil-trash"></CIcon>
                                                        </CButton>
                                                    </CTooltip>
                                                </>}
                                                <EmployeeCertificatesForm
                                                    {...this.props}
                                                    certificate={certificate}
                                                    item={certificate}
                                                    action='edit'
                                                    handleSubmit={this.handleSubmit}
                                                    toggleEditForms={this.props.toggleEditForms}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </CCol>
            </CRow>
        )
    }
}


export default EmployeeCertificates;
