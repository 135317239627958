import { itemConstants } from 'src/_constants';
import { itemService } from 'src/_services';
import { alertActions } from 'src/_actions';

export const itemActions = {
    getAll,
    add,
    getById,
    update,
    updateField,
    _delete,
};


function getAll() {
    return dispatch => {
        dispatch(request());
        return itemService.getAll()
            .then(
                items => {
                    dispatch(success(items));
                    return items;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: itemConstants.GETALL_REQUEST } }
    function success(items) { return { type: itemConstants.GETALL_SUCCESS, items } }
    function failure(error) { return { type: itemConstants.GETALL_FAILURE, error } }
}

function getById(id) {

    return dispatch => {
        dispatch(request());
        itemService.getById(id)
            .then(
                item => dispatch(success(item)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: itemConstants.GET_REQUEST } }
    function success(item) { return { type: itemConstants.GET_SUCCESS, item } }
    function failure(error) { return { type: itemConstants.GET_FAILURE, error } }
}

function add(item) {
    return dispatch => {
        dispatch(request(item));

        return itemService.add(item)
            .then(
                item => {
                    dispatch(success(item));
                    dispatch(alertActions.success('Thêm mới Tài Sản thành công!'));
                    return item;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(item) { return { type: itemConstants.ADD_REQUEST, item } }
    function success(item) { return { type: itemConstants.ADD_SUCCESS, item } }
    function failure(error) { return { type: itemConstants.ADD_FAILURE, error } }
}

function update(item) {
    return dispatch => {
        dispatch(request(item));
        itemService.update(item)
            .then(
                item => {
                    dispatch(success(item));
                    dispatch(alertActions.success('Cập nhật thông tin Tài Sản thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(item) { return { type: itemConstants.UPDATE_REQUEST, item } }
    function success(item) { return { type: itemConstants.UPDATE_SUCCESS, item } }
    function failure(error) { return { type: itemConstants.UPDATE_FAILURE, error } }
}

function updateField(item, field) {
    return dispatch => {
        dispatch(request(item, field));
        itemService.updateField(item, field)
            .then(
                item => {
                    dispatch(success(item));
                    dispatch(alertActions.success('Cập nhật thông tin Tài Sản thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(item, field) { return { type: itemConstants.UPDATE_FIELD_REQUEST, item, field } }
    function success(item) { return { type: itemConstants.UPDATE_FIELD_SUCCESS, item } }
    function failure(error) { return { type: itemConstants.UPDATE_FIELD_FAILURE, error } }
}

function _delete(item) {
    return dispatch => {
        dispatch(request(item));
        return itemService._delete(item)
            .then(
                item => {
                    dispatch(success(item));
                    dispatch(alertActions.success('Xoá Tài Sản thành công!'));
                    return item;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(item) { return { type: itemConstants.DELETE_REQUEST, item } }
    function success(item) { return { type: itemConstants.DELETE_SUCCESS, item } }
    function failure(error) { return { type: itemConstants.DELETE_FAILURE, error } }
}


