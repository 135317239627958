import React, { Component } from 'react'
import {
    CBadge,
    CCol,
    CLink,
    CRow,
    CTooltip,
    CFormGroup,
    CLabel,
    CCard, CCardHeader, CCardBody,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CPopover,
} from '@coreui/react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';
import DatePicker from 'react-datepicker';
import locale from 'date-fns/locale/vi';

class RecruitmentKPI extends Component {

    constructor(props) {
        super(props);

        this.state = {
            month: new Date(),
            modals: [],
        }
        this.handleChangeMonth = this.handleChangeMonth.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {

    }

    handleChangeMonth(value) {
        //console.log(value)
        this.setState({ month: value });
    }

    toggleModal = (item) => {
        let modals = this.state.modals;
        const position = modals.indexOf(item);
        let newItems = modals.slice();
        if (position !== -1) {
            newItems.splice(position, 1)
        } else {
            newItems = [...modals, item]
        }
        this.setState({ modals: newItems });
    }

    render() {
        const { employees } = this.props;
        let scored_employees = [];
        let month_employees = [];
        let month = this.state.month ? new Date(this.state.month) : new Date();
        var firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
        firstDay = new Date(firstDay.setHours(0, 0, 0));
        var lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
        lastDay = new Date(lastDay.setHours(23, 59, 59));
        //console.log(firstDay, lastDay)
        if (!helps.isEmpty(employees)) {
            scored_employees = employees.filter(employee => employee.department === 'PNV' && employee.job_type === 'NVCT');
            month_employees = employees.filter(employee => new Date(employee.joint_date) >= firstDay && new Date(employee.joint_date) <= lastDay);
            if (!helps.isEmpty(scored_employees) && scored_employees.length > 0) {
                scored_employees.forEach(recruiter => {
                    let recruit_by_nvct = month_employees.filter(_employee => _employee.recruited_by === recruiter.employee_id && _employee.job_type !== 'NVTV' && _employee.job_type !== 'CTV');
                    let recruit_by_nvtv = month_employees.filter(_employee => _employee.recruited_by === recruiter.employee_id && _employee.job_type === 'NVTV');
                    recruiter['count_recruit_nvct'] = recruit_by_nvct.length;
                    recruiter['count_recruit_nvtv'] = recruit_by_nvtv.length;
                })
            }
        }

        let number_recruiter = 0;
        let number_none_recruiter = 0;
        let total_nvct = 0;
        let total_nvtv = 0;
        let total = 0;
        let team_result = false;
        if (!helps.isEmpty(scored_employees) && scored_employees.length > 0) {
            scored_employees.forEach(recruiter => {
                let recruiter_total = recruiter.count_recruit_nvct + recruiter.count_recruit_nvtv / 2;
                if (recruiter.recruiter) {
                    number_recruiter += 1;
                } else {
                    number_none_recruiter += 1;
                }
                total_nvct += recruiter.count_recruit_nvct;
                total_nvtv += recruiter.count_recruit_nvtv;
                total += recruiter_total;
            });
        }
        let team_kpi_result = parseFloat((number_recruiter * configs.recruitment_kpi.recruiter_kpi + number_none_recruiter * configs.recruitment_kpi.none_recruiter_kpi) * configs.recruitment_kpi.ratio).toFixed(1);
        team_result = total >= team_kpi_result ? true : false;
        return (
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol>
                            <b>KPI Tuyển Dụng</b>
                        </CCol>
                        <CCol>
                            <CFormGroup variant='custom-radio' inline>
                                <CLabel className='font-weight-bold mt-1'>Tháng:</CLabel>
                                <DatePicker
                                    locale={locale}
                                    selected={this.state.month}
                                    onChange={(date) => { this.handleChangeMonth(date) }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className='ml-2 form-control font-weight-bold'
                                />
                            </CFormGroup>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <div className='table-responsive'>
                        <table className='table table-hover recruitmentkpi'>
                            <thead>
                                <tr>
                                    <th rowSpan={2} className='text-center'>#</th>
                                    <th rowSpan={2}>Họ & Tên</th>
                                    <th rowSpan={2}>MSNV</th>
                                    <th className='text-center' colSpan={3}>Kết Quả Tuyển Dụng</th>
                                    <th rowSpan={2} className='text-center'>
                                        Mức KPI tối thiểu Quy định
                                        <div><small className='text-muted font-italic'>(nhân sự/tháng)</small></div>
                                    </th>
                                    <th rowSpan={2} className='text-center'>Kết Quả</th>
                                    <th rowSpan={2} style={{ width: '20%' }}>Ghi Chú</th>
                                </tr>
                                <tr>
                                    <th className='text-center'><CPopover content={'Số lượng Nhân viên Chính thức tuyển dụng được'}><span>NVCT</span></CPopover></th>
                                    <th className='text-center'><CPopover content={'Số lượng Nhân viên Thời vụ tuyển dụng được'}><span>NVTV</span></CPopover></th>
                                    <th className='text-center'>Tổng</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!helps.isEmpty(scored_employees) && scored_employees.map((recruiter, index) => {
                                    let kpi = recruiter.recruiter ? configs.recruitment_kpi.recruiter_kpi : configs.recruitment_kpi.none_recruiter_kpi;
                                    let recruiter_total = recruiter.count_recruit_nvct + recruiter.count_recruit_nvtv / 2;
                                    let result = recruiter_total < kpi ? false : true;
                                    let recruit_by_nvct = month_employees.filter(_employee => _employee.recruited_by === recruiter.employee_id && _employee.job_type !== 'NVTV' && _employee.job_type !== 'CTV');
                                    let recruit_by_nvtv = month_employees.filter(_employee => _employee.recruited_by === recruiter.employee_id && _employee.job_type === 'NVTV');
                                    let modal_nvct_id = recruiter.employee_id + '_nvct';
                                    let modal_nvtv_id = recruiter.employee_id + '_nvtv';
                                    return (
                                        <tr key={recruiter.employee_id}>
                                            <td className='text-center'>{index + 1}</td>
                                            <td>{recruiter.fullname}</td>
                                            <td>{recruiter.employee_id}</td>
                                            <td className='text-center'>
                                                <CPopover content={'1 NVCT = 1 điểm KPI'}>
                                                    <CLink className={'font-weight-bold'} onClick={(e) => { e.preventDefault(); this.toggleModal(modal_nvct_id) }}>{recruiter.count_recruit_nvct}</CLink>
                                                </CPopover>
                                                <CModal
                                                    show={this.state.modals.includes(modal_nvct_id)}
                                                    size='lg'
                                                    onClose={() => { this.toggleModal(modal_nvct_id) }}
                                                    className='text-left'
                                                >
                                                    <CModalHeader closeButton>
                                                        <h6 className='font-weight-bold'>
                                                            <span className='text-muted'>Danh Sách Nhân Viên Chính Thức Tuyển Dụng Bởi: </span> <span> {recruiter.fullname}</span>
                                                            <div>Tháng: {month.getMonth() + 1}/{month.getFullYear()}</div>
                                                        </h6>
                                                    </CModalHeader>
                                                    <CModalBody>
                                                        <div className='table-responsive'>
                                                            <table className='table table-hover'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Họ & Tên</th>
                                                                        <th>MSNV</th>
                                                                        <th>Loại Hình</th>
                                                                        <th>Ngày vào Cty</th>
                                                                        <th>Tuyển dụng bởi</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {!helps.isEmpty(recruit_by_nvct) && recruit_by_nvct.map((_employee, index) => {
                                                                        var color = 'primary';
                                                                        switch (_employee.job_type) {
                                                                            case 'DNV':
                                                                                color = 'danger';
                                                                                break;
                                                                            case 'NVTV':
                                                                                color = 'warning';
                                                                                break;
                                                                            case 'CTV':
                                                                                color = 'secondary';
                                                                                break;
                                                                            default:
                                                                                color = 'primary';
                                                                        }
                                                                        return (
                                                                            <tr key={'recruit_by_' + _employee.employee_id}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{_employee.fullname}</td>
                                                                                <td>{_employee.employee_id}</td>
                                                                                <td><CBadge color={color}>{configs.job_types[_employee.job_type]}</CBadge></td>
                                                                                <td>{helps.formatDate_from_Timestamp(_employee.joint_date)}</td>
                                                                                <td>{recruiter.fullname}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </CModalBody>
                                                    <CModalFooter>
                                                        <b><span className='text-muted'>Tổng: </span> {recruit_by_nvct.length}</b>
                                                    </CModalFooter>
                                                </CModal>
                                            </td>
                                            <td className='text-center'>
                                                <CPopover content={'1 NVTV = 0.5 điểm KPI'}>
                                                    <CLink className={'font-weight-bold'} onClick={(e) => { e.preventDefault(); this.toggleModal(modal_nvtv_id) }}>{recruiter.count_recruit_nvtv}</CLink>
                                                </CPopover>
                                                <CModal
                                                    show={this.state.modals.includes(modal_nvtv_id)}
                                                    size='lg'
                                                    onClose={() => { this.toggleModal(modal_nvtv_id) }}
                                                    className='text-left'
                                                >
                                                    <CModalHeader closeButton>
                                                        <h6 className='font-weight-bold'>
                                                            <span className='text-muted'>Danh Sách Nhân Viên Thời Vụ Tuyển Dụng Bởi: </span> <span> {recruiter.fullname}</span>
                                                            <div>Tháng: {month.getMonth() + 1}/{month.getFullYear()}</div>
                                                        </h6>
                                                    </CModalHeader>
                                                    <CModalBody>
                                                        <div className='table-responsive'>
                                                            <table className='table table-hover'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Họ & Tên</th>
                                                                        <th>MSNV</th>
                                                                        <th>Loại Hình</th>
                                                                        <th>Ngày vào Cty</th>
                                                                        <th>Tuyển dụng bởi</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {!helps.isEmpty(recruit_by_nvtv) && recruit_by_nvtv.map((_employee, index) => {
                                                                        var color = 'primary';
                                                                        switch (_employee.job_type) {
                                                                            case 'DNV':
                                                                                color = 'danger';
                                                                                break;
                                                                            case 'NVTV':
                                                                                color = 'warning';
                                                                                break;
                                                                            case 'CTV':
                                                                                color = 'secondary';
                                                                                break;
                                                                            default:
                                                                                color = 'primary';
                                                                        }
                                                                        return (
                                                                            <tr key={'recruit_by_' + _employee.employee_id}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{_employee.fullname}</td>
                                                                                <td>{_employee.employee_id}</td>
                                                                                <td><CBadge color={color}>{configs.job_types[_employee.job_type]}</CBadge></td>
                                                                                <td>{helps.formatDate_from_Timestamp(_employee.joint_date)}</td>
                                                                                <td>{recruiter.fullname}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </CModalBody>
                                                    <CModalFooter>
                                                        <b><span className='text-muted'>Tổng: </span> {recruit_by_nvtv.length}</b>
                                                    </CModalFooter>
                                                </CModal>
                                            </td>
                                            <td className='text-center font-weight-bold'>{recruiter_total}</td>
                                            <td className='text-center font-weight-bold'>{kpi}</td>
                                            <td className='text-center'>
                                                {result && <CBadge color='success'>Đạt</CBadge>}
                                                {!result && <CBadge color='danger'>Không Đạt</CBadge>}
                                            </td>
                                            <td className='font-italic text-muted'>áp dụng trên mỗi cá nhân</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td className='text-center'>{scored_employees.length + 1}</td>
                                    <td>KPI Phòng</td>
                                    <td></td>
                                    <td className='text-center font-weight-bold'>{total_nvct}</td>
                                    <td className='text-center font-weight-bold'>{total_nvtv}</td>
                                    <td className='text-center font-weight-bold'>{total}</td>
                                    <td className='text-center font-weight-bold'><CPopover content={'([Số CB/CV Chuyên trách]x[KPI cá nhân] + [Số CB/CV Không chuyên trách]x[KPI cá nhân])x0.8'}><span>{team_kpi_result}</span></CPopover></td>
                                    <td className='text-center'>
                                        {team_result && <CBadge color='success'>Đạt</CBadge>}
                                        {!team_result && <CBadge color='danger'>Không Đạt</CBadge>}
                                    </td>
                                    <td className='font-italic text-muted'>
                                        <div>- áp dụng đối với cả phòng;</div>
                                        <div>- Trong đó: X là Số CB/CV Chuyên trách, CN-CT là KPI của cá nhân Chuyên trách, Y là số CB/CV Không chuyên trách, CN-KCT là KPI của cá nhân không chuyên trách, 0.8 là chỉ số sai số;</div>
                                        <div>- Theo công thức: ([Số CB/CV Chuyên trách]x[KPI cá nhân] + [Số CB/CV Không chuyên trách]x[KPI cá nhân])x0.8</div>
                                        <div>- VD: có 1 CB/CV Chuyên trách, 4 CB/CV Không chuyên trách; thì KPI của cả phòng = (1*10 + 4*3)*0.8 = 19.6</div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className='mt-3 font-italic'>
                        <h5><i><u>Ghi Chú:</u></i></h5>
                        <div>
                            <div>- Chỉ số KPI(nhân sự tuyển dụng được) chỉ được tính khi nhân sự đã vượt qua vòng phỏng vấn, sát hạch, hoàn thành các thủ tục đầu vào của công ty(nộp hồ sơ, ký kết HĐLĐ,...).</div>
                            <div>- Nhân viên thời vụ được tính 0,5 chỉ số KPI: 02 nhân viên thời vụ được 01 điểm KPI(tương đương 1 nhân viên chính thức).</div>
                            <div>- Trong trường hợp KPI của cả phòng đạt mức KPI tối thiểu; nhưng cá nhân không đạt KPI của riêng mình, thì cá nhân đó không có thưởng KPI.</div>
                            <div>- Trong trường hợp cá nhân đạt KPI, nhưng cả phòng không đạt KPI, thì tất cả các cá nhân không có thưởng KPI.</div>
                            <div>- Trong trường hợp các cá nhân chuyên trách công tác tuyển dụng không đạt mức KPI tối thiểu, các cá nhân đó phải chịu phạt KPI; mức phạt KPI được tính như sau: số lượng KPI còn thiếu để đạt mức KPI tối thiểu, số tiền phạt tương đương với số tiền thưởng theo quy định.</div>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        )
    }
}
export default RecruitmentKPI;
