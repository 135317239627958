import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCol,
    CRow,
} from '@coreui/react';
import { assetActions, userActions, storeActions, itemActions, projectActions, vendorActions, employeeActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import AssetsComponent from 'src/views/assets/AssetsComponent';
import { asset_itemActions } from 'src/_actions/assets/asset_item.actions';

class AssetsComponentContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {
        let object_id = '';
        let object_id_value = '';
        let source_id = '';
        switch (this.props.object) {
            case 'employee':
                object_id = 'employee_id';
                source_id = 'employee_id';
                break;
            case 'project':
                object_id = 'project_id';
                source_id = 'id';
                break;
            case 'housing':
                object_id = 'housing_id';
                source_id = 'id';
                break;
            default:
                object_id = 'vendor_id';
                source_id = 'id';
        }
        object_id_value = this.props.object_data[[source_id]];
        let params = {};
        this.getUsers();
        this.getStores();
        this.getItems();
        this.getEmployees();
        this.getVendors();
        this.getProjects();
        this.getAssets({ [object_id]: object_id_value }).then(() => {
            let assets = this.props.assets;
            let assets_ids = [];
            if (!helps.isEmpty(assets)) {
                assets.map((asset) => {
                    assets_ids.push(asset.id);
                });
            }
            params['assets_ids'] = assets_ids;
            this.getAssetItems(params);
        });

    }

    async getAssets(params = {}) {
        await this.props.getAssets(params);
    }

    async getAssetItems(params = {}) {
        await this.props.getAssetItems(params);
    }

    async getStores() {
        if (helps.isEmpty(this.props.stores)) {
            await this.props.getStores();
        }
    }

    async getItems() {
        if (helps.isEmpty(this.props.items)) {
            await this.props.getItems();
        }
    }

    async getEmployees() {
        if (helps.isEmpty(this.props.employees)) {
            await this.props.getEmployees();
        }
    }

    async getProjects() {
        if (helps.isEmpty(this.props.projects)) {
            await this.props.getProjects();
        }
    }

    async getVendors() {
        if (helps.isEmpty(this.props.vendors)) {
            await this.props.getVendors();
        }
    }

    async getUsers() {
        if (helps.isEmpty(this.props.users)) {
            await this.props.getUsers();
        }
    }

    handleChange(field, e) {
        this.setState({
            [field]: e.target.value
        });
    }

    handleChangeSelect(selectedOptions) {
        if (selectedOptions) {
            this.setState({ 'province_id': selectedOptions.value });
        } else {
            this.setState({ province_id: '' });
        }

    }

    render() {

        return (
            <CRow>
                <CCol xl={12}>
                    <AssetsComponent {... this.props} />
                </CCol >
            </CRow >
        );
    }
}

function mapStateToProps(state) {
    const { loading, assets, asset_items } = state.assetReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    const { stores } = state.storeReducer;
    const { items } = state.itemReducer;
    const { employees } = state.employeeReducer;
    const { projects } = state.projectReducer;
    const { vendors } = state.vendorReducer;
    return { user, users, loading, assets, asset_items, stores, items, employees, projects, vendors };
}

const actionCreators = {
    getAssets: assetActions.getAll,
    getAssetItems: asset_itemActions.getAllforComponent,
    getUsers: userActions.getAll,
    getStores: storeActions.getAll,
    getItems: itemActions.getAll,
    getProjects: projectActions.getAll,
    getEmployees: employeeActions.getAll,
    getVendors: vendorActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(AssetsComponentContainer);
export { connectedContainer as AssetsComponentContainer };