import { positionConstants } from "src/_constants";

export function positionReducer(state = { positions: [] }, actions) {
    switch (actions.type) {
        case positionConstants.GET_LIST_REQUEST:
            return {
                loading: true,
            }
        case positionConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                positions: actions.positions,
                loading: false,
            }
        case positionConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Add
        case positionConstants.ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case positionConstants.ADD_SUCCESS:
            state.positions.push(actions.position);
            return {
                ...state,
                loading: false,
            }
        case positionConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Edit
        case positionConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case positionConstants.EDIT_SUCCESS:
            var _positions = state.positions;
            var foundIndex = _positions.findIndex(position => position.id === actions.position.id);
            _positions[foundIndex] = actions.position;
            console.log(foundIndex);
            return {
                ...state,
                positions: _positions,
                loading: false,
            }
        case positionConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        // Delete 
        case positionConstants.DELETE_REQUEST:
            return {
                ...state,
                positions: state.positions.map(position =>
                    position.id === actions.position.id
                        ? { ...position, deleting: true }
                        : position
                )
            };
        case positionConstants.DELETE_SUCCESS:
            return {
                positions: state.positions.filter(position => position.id !== actions.position.id)
            };
        case positionConstants.DELETE_FAILURE:
            return {
                ...state,
                positions: state.positions.map(position => {
                    if (position.id === actions.position.id) {
                        const { deleting, ...positionCopy } = position;
                        return { ...positionCopy, deleteError: actions.error };
                    }
                    return position;
                })
            };

        default: return state;
    }
}