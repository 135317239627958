import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CRow, CCol,
    CButton,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import Item from 'src/views/assets/Item';
import { itemActions, storeActions, userActions } from 'src/_actions';
import { helps } from 'src/_helpers';


class ItemContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
        }
    }

    componentDidMount() {
        this.getUsers();
        this.getStores();
        this.getItems().then(items => {
            this.getItem();
        });
    }

    async getItem() {
        let item_id = this.props.match.params.id;
        const { items } = this.props;
        var item = {};
        if (item_id && items) {
            let item = helps.getItemFromArr(items, item_id);
            this.setState({ item: item });
        }
        return item;
    }

    async getItems() {
        return await this.props.getAll();
    }

    async getUsers() {
        const { users } = this.props;
        if (helps.isEmpty(users)) await this.props.getUsers();
    }

    async getStores() {
        const { stores } = this.props;
        if (helps.isEmpty(stores)) await this.props.getStores();
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.props.items)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.state.item)) {
            return (
                <Loading></Loading>
            );
        }
        const item = this.state.item;
        return (
            <ThePermission {... this.props} component="assets" action="view">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md={7} sm={12} className='mb-2'>
                                <b>{item.code} - {item.name}</b>
                            </CCol>
                            <CCol md={5} sm={12} className='mb-2 text-right'>
                                <ThePermission {... this.props} component="assets" action="edit">
                                    <CTooltip content={'Chỉnh sửa: ' + item.code + ' - ' + item.name}>
                                        <CButton
                                            color="primary"
                                            size="sm"
                                            title={'Chỉnh sửa: ' + item.code + ' - ' + item.name}
                                            onClick={() => { this.props.history.push(`/assets/items/add_edit/${item.id}`) }}
                                        >
                                            <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                            Chỉnh Sửa
                                        </CButton>
                                    </CTooltip>
                                </ThePermission>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <Item {... this.props} item={item} />
                    </CCardBody>
                </CCard>

            </ThePermission >

        );
    }
}

function mapStateToProps(state) {
    const { loading, items } = state.itemReducer;
    const { users } = state.userReducer;
    const { stores } = state.storeReducer;
    const { user } = state.authentication;
    return { loading, items, user, users, stores };
}

const actionCreators = {
    getAll: itemActions.getAll,
    getUsers: userActions.getAll,
    getStores: storeActions.getAll,
    updateField: itemActions.updateField,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(ItemContainer);
export { connectedContainer as ItemContainer };