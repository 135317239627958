import { contactConstants } from "src/_constants";

export function contactReducer(state = {}, actions) {
    switch (actions.type) {
        case contactConstants.GET_LIST_REQUEST:
            return {
                loading: true,
            }
        case contactConstants.GET_LIST_SUCCESS:
            return {
                ...state,
                contacts: actions.contacts,
                loading: false,
            }
        case contactConstants.GET_LIST_FAILURE:
            return {
                ...state,
                loading: true,
                error: actions.error,
            }

        // Add
        case contactConstants.ADD_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case contactConstants.ADD_SUCCESS:
            state.contacts.push(actions.contact);
            return {
                ...state,
                contact: actions.contact,
                loading: false,
            }
        case contactConstants.ADD_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }

        // Edit
        case contactConstants.EDIT_REQUEST:
            return {
                ...state,
                loading: false,
            }
        case contactConstants.EDIT_SUCCESS:
            var _contacts = state.contacts;
            var foundIndex = _contacts.findIndex(contact => contact.id === actions.contact.id);
            _contacts[foundIndex] = actions.contact;
            console.log(foundIndex);
            return {
                ...state,
                contact: actions.contact,
                contacts: _contacts,
                loading: false,
            }
        case contactConstants.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: actions.error,
            }
        // Delete 
        case contactConstants.DELETE_REQUEST:
            return {
                ...state,
                contacts: state.contacts.map(contact =>
                    contact.id === actions.contact.id
                        ? { ...contact, deleting: true }
                        : contact
                )
            };
        case contactConstants.DELETE_SUCCESS:
            return {
                contacts: state.contacts.filter(contact => contact.id !== actions.contact.id)
            };
        case contactConstants.DELETE_FAILURE:
            return {
                ...state,
                contacts: state.contacts.map(contact => {
                    if (contact.id === actions.contact.id) {
                        const { deleting, ...contactCopy } = contact;
                        return { ...contactCopy, deleteError: actions.error };
                    }
                    return contact;
                })
            };

        default: return state;
    }
}