import React, { Component } from 'react'
import {
    CDataTable,
    CSelect,
    CLink,
    CBadge,
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CTooltip,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import TaskStatus from '../tasks/TaskStatus';
import TasksCalendar from '../tasks/TasksCalendar';

class Tasks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            object: '',
            object_id: '',
            filter_by_priority: '',
            view: 'list',
        }

    }

    componentDidMount() {
        let views = JSON.parse(localStorage.getItem('views'));
        if (views && views.tasks) {
            this.setState({ view: views.tasks });
        } else {
            views = {
                'tasks': this.state.view
            }
            localStorage.setItem('views', JSON.stringify(views));
        }
    }

    handleChange(e, field) {
        let value = e.target.value;
        this.setState({ [field]: value });
    }

    handleDelete(e, task) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Công Việc: ' + task.title + ' ?');
        if (_confirm) {
            this.props._delete(task);
        }
        return false;
    }

    handleView(view) {
        this.setState({ view: view });
        let views = JSON.parse(localStorage.getItem('views'));
        views['tasks'] = view;
        localStorage.setItem('views', JSON.stringify(views));
        console.log(localStorage.getItem('views'));
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        const { tasks, user } = this.props;
        var _tasks = [];
        var _tasks_assigns = [];
        var _tasks_owns = [];
        var _tasks_follows = [];
        if (!helps.isEmpty(tasks) && tasks.length > 0) {
            _tasks_assigns = tasks.filter(task => task.filter === 'assigns');
            _tasks_owns = tasks.filter(task => task.filter === 'owns');
            _tasks_follows = tasks.filter(task => task.filter === 'follows');
        }
        _tasks = _tasks.concat(_tasks_assigns, _tasks_owns, _tasks_follows);
        _tasks = _tasks.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
        if (_tasks && _tasks.length > 0) {
            _tasks.map((task) => {
                task['is_author'] = false;
                if (user.id == task.added_by) {
                    task['is_author'] = true;
                }
            })
        }

        if (!helps.isEmpty(this.state.filter_by_priority)) {
            _tasks = _tasks.filter(task => task.priority == this.state.filter_by_priority);
        }

        const fields = [
            { key: 'title', label: 'Công Việc', _classes: 'font-weight-bold', _style: { width: '25%' } },
            { key: 'priority', label: 'Ưu Tiên', sorter: true },
            { key: 'added_by', label: 'Người Giao' },
            { key: 'assigns', label: 'Giao Cho' },
            { key: 'assign_status', label: 'Trạng Thái' },
            { key: 'deadline', label: 'Thời Hạn' },
            { key: 'object', label: 'Liên Quan' },
        ]
        const view = this.state.view;
        return (
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol>
                            Công Việc
                        </CCol>
                        <CCol className='text-right'>
                            <CTooltip content={"Xem dạng danh sách"}>
                                <CButton
                                    color={(view === 'list') ? 'primary' : "secondary"}
                                    size="sm"
                                    className="mr-2"
                                    onClick={() => { this.handleView('list') }}
                                >
                                    <CIcon name="cil-list" className="mfe-1 mt-0"></CIcon>
                                </CButton>
                            </CTooltip>
                            <CTooltip content={"Xem dạng lịch"}>
                                <CButton
                                    color={(view === 'calendar') ? 'primary' : "secondary"}
                                    size="sm"
                                    className="mr-2"
                                    onClick={() => { this.handleView('calendar') }}
                                >
                                    <CIcon name="cil-calendar" className="mfe-1 mt-0"></CIcon>
                                </CButton>
                            </CTooltip>
                            <CLink onClick={() => { this.props.history.push('/tasks') }}><small>Quản Lý Công Việc</small></CLink>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    {view === 'calendar' &&
                        <TasksCalendar {... this.props} />
                    }
                    {view === 'list' &&
                        <CDataTable
                            items={_tasks}
                            fields={fields}
                            columnFilter
                            tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                            itemsPerPageSelect={configs.itemsPerPageSelect}
                            itemsPerPage={configs.per_page}
                            hover
                            sorter
                            pagination
                            striped
                            columnFilterSlot={{
                                priority: (
                                    <>
                                        <CSelect
                                            name="group"
                                            id="input-group"
                                            className="form-control form-control-sm"
                                            value={this.state.group}
                                            onChange={(e) => { this.handleChange(e, 'filter_by_priority') }}
                                        >
                                            <option value="">-Tất cả-</option>
                                            {configs.task_priorities && configs.task_priorities.map((priority) => {
                                                return (
                                                    <option key={priority.id} value={priority.id}>{priority.label}</option>
                                                )
                                            }
                                            )}
                                        </CSelect>
                                    </>
                                ),
                            }}
                            scopedSlots={{
                                'title': (item) => {
                                    var _class = 'font-weight-bold text-dark';
                                    if (item.status === 'finished') {
                                        _class = 'font-weight-bold text-success';
                                    }
                                    return (
                                        <td className="py-2">
                                            <CTooltip
                                                content={item.title}
                                            >
                                                <CLink
                                                    className={_class}
                                                    onClick={() => { this.props.history.push(`/tasks/view/${item.id}`) }}
                                                    style={{
                                                        textDecoration: (item.status === 'finished') ? 'line-through' : 'none',
                                                    }}
                                                >
                                                    {item.title}
                                                </CLink>
                                            </CTooltip>
                                        </td>
                                    )
                                },
                                'priority': (item) => {
                                    let _priority = helps.getItemFromArr(configs.task_priorities, item.priority);
                                    return (
                                        <td className="py-2">
                                            <CBadge color={_priority.color}>{_priority.label}</CBadge>
                                        </td>
                                    )
                                },
                                'added_by': (item) => {
                                    let added_time_alias = helps.formatDateTime_from_Timestamp(item.deadline);
                                    return (
                                        <td className="py-2">
                                            {item.added_by_alias}
                                            <div><span className="text-muted font-italic">{added_time_alias}</span></div>
                                        </td>
                                    )
                                },
                                'assigns': (item) => {
                                    return (
                                        <td className="py-2">
                                            {!helps.isEmpty(item.assigns) && item.assigns.length > 0 && item.assigns.map(assign => {
                                                return (
                                                    <div key={assign.value}>
                                                        {assign.label}
                                                    </div>
                                                );
                                            })}
                                        </td>
                                    )
                                },
                                'assign_status': (item) => {
                                    return (
                                        <td className="py-2">
                                            <TaskStatus {... this.props} task={item} />
                                        </td>
                                    )
                                },
                                'deadline': (item) => {
                                    let deadline_alias = helps.formatDateTime_from_Timestamp(item.deadline);
                                    return (
                                        <td className="py-2">
                                            <b>{deadline_alias}</b>
                                        </td>
                                    )
                                },
                                'object': (item) => {
                                    return (
                                        <td className="py-2">
                                            <b>{item.object_alias}</b>
                                            {item.object_link &&
                                                <div><CLink onClick={() => { this.props.history.push(item.object_link) }}>{item.object_name}</CLink></div>
                                            }
                                            {!item.object_link &&
                                                <div>{item.object_name}</div>
                                            }
                                        </td>
                                    )
                                }
                            }}
                        />
                    }
                </CCardBody>
                <CCardFooter>
                    Tổng: {_tasks && <b>{_tasks.length}</b>}
                </CCardFooter>
            </CCard>
        )
    }
}


export default Tasks;
