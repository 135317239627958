import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CValidFeedback,
  CInvalidFeedback,
  CAlert,
} from '@coreui/react'

import CIcon from '@coreui/icons-react';
import { helps } from '../../../_helpers';


class LoginPage extends Component {

  constructor(props) {
    super(props);

    // reset login status
    this.props.logout();

    this.state = {
      username: '',
      password: '',
      submitted: false,
      validated: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;

    const { username, password } = this.state;

    if (username && password) {
      let hashed_password = helps.hashPassword(password);
      this.props.login(username, hashed_password);

    }
  }

  render() {
    const alert = this.props.alert;
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="4">
              <CCardGroup>
                <CCard className="p-4">
                  <CCardHeader>
                    <h3>ĐĂNG NHẬP</h3>
                  </CCardHeader>
                  <CCardBody>
                    <CForm noValidate onSubmit={this.handleSubmit}>
                      <p className="text-muted">ĐĂNG NHẬP HỆ THỐNG</p>
                      {alert.message && <CAlert color={alert.type}>{alert.message}</CAlert>}
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="text" name="username" placeholder="TK Đăng Nhập" autoComplete="username" required onChange={this.handleChange} />
                        <CInvalidFeedback>TK Đăng Nhập không đúng.</CInvalidFeedback>
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-lock-locked" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="password" name="password" placeholder="Mật Khẩu" autoComplete="current-password" required onChange={this.handleChange} />
                        <CValidFeedback valid>Looks good!</CValidFeedback>
                      </CInputGroup>
                      <CRow>
                        <CCol xs="6">
                          <CButton type="submit" color="info" className="px-6">ĐĂNG NHẬP</CButton>
                        </CCol>
                        {/*
                        <CCol xs="6" className="text-right">
                          <CButton color="link" className="px-0">Forgot password?</CButton>
                        </CCol>
                        */}
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard className="text-white bg-info py-2 d-md-down-none" style={{ width: '10%', maxWidth: '10%' }}>
                  <CCardBody>
                    <p>SEKIN SECURITY</p>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
          <CRow className="justify-content-center">
            <p className="text-muted mt-3">
              &copy; by SEKIN SECURITY
            </p>
          </CRow>
        </CContainer>
      </div>
    )
  }
}

export default LoginPage;




