import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CDataTable,
    CRow,
    CButton,
    CLink,
    CSelect,
    CCardFooter,
    CPopover,
    CBadge,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CTooltip,
    CFormGroup,
    CInputCheckbox,
    CLabel,
    CDropdownHeader,
    CDropdownDivider,
    CImg,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { helps } from '../../_helpers';
import configs from 'src/appConfigs';
import Select from 'react-select'

class Employees extends Component {

    constructor(props) {
        super(props);

        this.state = {
            job_type: '',
            group: '',
            position: '',
            department: '',
            gen: '',
            cccd: 'all',
            over_6month: false,
            over_months: 0,
            process_exit: false,
            no_contracts: false,
            expired_contracts: false,
            hide_cols: [],
            selected_recruited_by_employee: {},
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.handleChangeCols = this.handleChangeCols.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {
        let settings = JSON.parse(localStorage.getItem('settings'));
        if (settings && settings.employees) {
            let hide_cols = settings.employees.hide_cols;
            if (!helps.isEmpty(hide_cols)) {
                this.setState({ hide_cols: hide_cols });
            }
        }
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        if (fields[field] === "true" || fields[field] === "false") {
            fields[field] = JSON.parse(fields[field]);
        }
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [field]: selectedOptions });

    }

    handleChangeFilter(field, e) {
        let fields = this.state;
        switch (field) {
            case 'over_6month':
                this.setState({ over_6month: e.target.checked ? true : false })
                break;
            case 'process_exit':
                this.setState({ process_exit: e.target.checked ? true : false })
                break;
            case 'no_contracts':
                this.setState({ no_contracts: e.target.checked ? true : false })
                break;
            case 'expired_contracts':
                this.setState({ expired_contracts: e.target.checked ? true : false })
                break;
            default:
                fields[field] = e.target.value;
                if (fields[field] === "true" || fields[field] === "false") {
                    fields[field] = JSON.parse(fields[field]);
                }
                this.setState({
                    [field]: fields[field]
                });
                this.setState({ fields });
        }
    }

    handleChangeCols(field, e) {
        let hide_cols = this.state.hide_cols;
        let settings = JSON.parse(localStorage.getItem('settings'));
        if (e.target.checked) {
            let col = helps.getItemFromArr(configs.employees_columns, field);
            hide_cols.push(col)
        } else {
            hide_cols = hide_cols.filter(el => el.id !== field);
        }
        settings = Object.assign({ employees: { hide_cols: hide_cols } });
        localStorage.setItem('settings', JSON.stringify(settings));
        this.setState({ hide_cols: hide_cols });
    }

    render() {
        const { employees, projects, notes } = this.props;
        let today = new Date();
        const customStyles = {
            control: base => ({
                ...base,
                height: 30,
                minHeight: 30
            })
        };
        var employee_options = [];

        if (employees && employees.length > 0) {
            var _employees = [];

            employees.forEach((employee, index) => {
                employee_options.push({ value: employee.employee_id, label: employee.fullname });
                if (employee.joint_date) {
                    employee['_joint_date'] = helps.formatDate_from_Timestamp(employee.joint_date);
                    employee['joint_during_date'] = helps.secondsToYmd(employee.joint_date, new Date());
                }
                if (employee.birthdate) {
                    employee['_birthdate'] = helps.formatDate_from_Timestamp(employee.birthdate);
                }
                if (employee.bank_account_number) {
                    employee['_bank'] = employee.bank_account_number;//.'<br>('.
                    if (employee.bank_name) {
                        employee['_bank'] = employee['_bank'] + ' (' + employee.bank_name + ')';
                    }
                } else {
                    employee['_bank'] = '';
                }
                if (employee.basic_salary) {
                    employee['_basic_salary'] = parseInt(employee.basic_salary).toLocaleString('vn-VN', {
                        style: 'currency',
                        currency: 'VND',
                    });
                } else {
                    employee['_basic_salary'] = '';
                }

                let recruited_by_employee = helps.getItemFromArr(employees, employee.recruited_by, 'employee_id');
                if (!helps.isEmpty(recruited_by_employee)) {
                    employee['recruited_by_employee'] = recruited_by_employee;
                } else {
                    employee['recruited_by_employee'] = '';
                }

                if (employee.cccd === undefined) {
                    employee.cccd = false;
                }
                var employee_projects = [];
                if (!helps.isEmpty(projects)) {
                    employee_projects = projects.filter((project) => {
                        if (project.employees) {
                            let found = helps.getItemFromArr(project.employees, employee.employee_id, 'employee_id');
                            if (!helps.isEmpty(found)) return true;
                            else return false;
                        } else {
                            return false;
                        }
                    })
                }
                //console.log(employee_projects)
                employee['project'] = '';
                if (!helps.isEmpty(employee_projects)) {
                    let employee_project = employee_projects[0];
                    employee['project'] = employee_project.name;
                    employee['project_id'] = employee_project.id;
                }
                _employees[index] = employee;
            });
        }
        //console.log(_employees)
        if (!helps.isEmpty(this.state.group)) {
            _employees = _employees.filter((item) => {
                return item.group === this.state.group;
            });
        }
        if (!helps.isEmpty(this.state.department)) {
            _employees = _employees.filter((item) => {
                return item.department === this.state.department;
            });
        }
        if (!helps.isEmpty(this.state.gen)) {
            _employees = _employees.filter((item) => {
                return item.gen === this.state.gen;
            });
        }
        if (!helps.isEmpty(this.state.position)) {
            _employees = _employees.filter((item) => {
                return item.position === this.state.position;
            });
        }
        if (!helps.isEmpty(this.state.job_type)) {
            _employees = _employees.filter((item) => {
                return item.job_type === this.state.job_type;
            });
        }
        if (this.state.cccd !== 'all') {
            _employees = _employees.filter((item) => {
                return this.state.cccd === item.cccd;
            });
        }

        if (this.state.over_6month && this.state.over_months > 0) {
            let date_6month_ago = helps.addMonths(new Date(), -parseInt(this.state.over_months));
            _employees = _employees.filter((item) => {
                return item.joint_date <= date_6month_ago && item.job_type === 'NVCT';
            });
        }

        if (this.state.process_exit) {
            _employees = _employees.filter((item) => {
                return item.exit_status > 0 && item.exit && !item.exit.exit_completed;
            });
        }

        if (this.state.no_contracts) {
            _employees = _employees.filter((item) => {
                return helps.isEmpty(item.contracts);
            });
        }

        if (this.state.expired_contracts) {
            _employees = _employees.filter((item) => {
                let is_filtered = false;
                let contracts = item.contracts ? item.contracts : [];
                let contract = {};
                let end_date = today;
                if (!helps.isEmpty(contracts) && contracts.length > 0) {
                    contracts = contracts.sort(function (a, b) {
                        let b_end_date = b.end_date ? new Date(b.end_date).getTime() : today.getTime();
                        let a_end_date = a.end_date ? new Date(a.end_date).getTime() : today.getTime();
                        return b_end_date - a_end_date;
                    });
                    contract = contracts[0];
                    end_date = contract.end_date ? new Date(contract.end_date) : today;
                    if (new Date(contract.end_date).getTime() < today.getTime()) {
                        is_filtered = true;
                    }
                }
                return is_filtered;
            });
        }

        if (!helps.isEmpty(this.state.selected_recruited_by_employee)) {
            _employees = _employees.filter((item) => {
                return item.recruited_by === this.state.selected_recruited_by_employee.value;
            });
        }

        let hide_cols = this.state.hide_cols;
        let fields = [
            { key: 'index', label: '#', _style: { width: '20px', maxWidth: '20px' }, filter: false, sorter: false },
            { key: 'fullname', label: 'Họ & Tên', _classes: 'font-weight-bold', _style: { width: '20%' } },
            { key: 'employee_id', label: 'MSNV', _classes: 'font-weight-bold', _style: { width: '15%' } },
        ]
        configs.employees_columns.map((col) => {
            let hide_col = helps.getItemFromArr(hide_cols, col.id)
            if (helps.isEmpty(hide_col)) {
                col.key = col.id;
                fields.push(col);
            }
        });

        fields.push({ key: 'actions', label: 'Thao Tác', _style: { width: '10%', minWidth: '100px', align: 'center' }, filter: false, sorter: false });

        return (
            <CRow>
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol>
                                    Quản Lý Nhân Sự
                                </CCol>
                                <CCol className={'text-right'}>
                                    <CDropdown tag={'span'} className='mr-1 ml-1'>
                                        <CTooltip content='Ẩn cột'>
                                            <CDropdownToggle caret color="secondary" size='sm'>
                                                <CIcon name="cil-low-vision" />
                                            </CDropdownToggle>
                                        </CTooltip>
                                        <CDropdownMenu className="pt-0" placement="bottom-end">
                                            <CDropdownHeader>
                                                <b>Ẩn cột:</b>
                                            </CDropdownHeader>
                                            <CDropdownDivider></CDropdownDivider>
                                            <CDropdownItem header>
                                                {configs.employees_columns && configs.employees_columns.map((col) => {
                                                    let hide_col = helps.getItemFromArr(hide_cols, col.id);
                                                    //console.log(hide_col);
                                                    return (
                                                        <CFormGroup variant='custom-radio' key={'col-' + col.id}>
                                                            <CInputCheckbox
                                                                id={'col-' + col.id}
                                                                name={col.id}
                                                                className='cursor-pointer'
                                                                value={1}
                                                                checked={!helps.isEmpty(hide_col)}
                                                                onChange={(e) => { this.handleChangeCols(col.id, e) }}
                                                            />
                                                            <CLabel htmlFor={'col-' + col.id} className='cursor-pointer mt-1' title={col.label}>{col.label}</CLabel>
                                                        </CFormGroup>
                                                    )
                                                })}
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                    <CDropdown tag={'span'} className='mr-3 ml-1'>
                                        <CTooltip content='Bộ lọc'>
                                            <CDropdownToggle caret color="secondary" size='sm'>
                                                <CIcon name="cil-filter" />
                                            </CDropdownToggle>
                                        </CTooltip>
                                        <CDropdownMenu className="pt-0" placement="bottom-end">
                                            <CDropdownItem header>
                                                <CFormGroup variant='custom-radio' inline>
                                                    <CInputCheckbox
                                                        id='input-process_exit'
                                                        name='process_exit'
                                                        className='cursor-pointer'
                                                        value={1}
                                                        onClick={(e) => { this.handleChangeFilter('process_exit', e) }}
                                                    />
                                                    <CLabel htmlFor='input-process_exit' className='cursor-pointer mt-1' title='Nhân sự đang làm thủ tục Thôi việc.'>Nhân sự đang làm thủ tục Thôi việc.</CLabel>
                                                </CFormGroup>
                                            </CDropdownItem>
                                            <CDropdownItem header>
                                                <CFormGroup variant='custom-radio' inline>
                                                    <CInputCheckbox
                                                        id='input-no_contracts'
                                                        name='no_contracts'
                                                        className='cursor-pointer'
                                                        value={1}
                                                        onClick={(e) => { this.handleChangeFilter('no_contracts', e) }}
                                                    />
                                                    <CLabel htmlFor='input-no_contracts' className='cursor-pointer mt-1' title='Nhân sự chưa có HĐLĐ.'>Nhân sự chưa có HĐLĐ.</CLabel>
                                                </CFormGroup>
                                            </CDropdownItem>
                                            <CDropdownItem header>
                                                <CFormGroup variant='custom-radio' inline>
                                                    <CInputCheckbox
                                                        id='input-expired_contracts'
                                                        name='expired_contracts'
                                                        className='cursor-pointer'
                                                        value={1}
                                                        onClick={(e) => { this.handleChangeFilter('expired_contracts', e) }}
                                                    />
                                                    <CLabel htmlFor='input-expired_contracts' className='cursor-pointer mt-1' title='Nhân sự hết hạn HĐLĐ.'>Nhân sự hết hạn HĐLĐ.</CLabel>
                                                </CFormGroup>
                                            </CDropdownItem>
                                            <CDropdownItem header>
                                                <CFormGroup variant='custom-radio' inline>
                                                    <CInputCheckbox
                                                        id='input-over_6month'
                                                        name='over_6month'
                                                        className='cursor-pointer'
                                                        value={1}
                                                        onClick={(e) => { this.handleChangeFilter('over_6month', e) }}
                                                    />

                                                    <CLabel htmlFor='input-over_6month' className='cursor-pointer mt-1' title={'Nhân sự chính thức ' + '>' + ' 6 tháng'}>Nhân sự chính thức trên: </CLabel>
                                                    <CSelect
                                                        size='sm'
                                                        className={'ml-1'}
                                                        value={this.state.over_months}
                                                        onChange={(e) => { this.handleChangeFilter('over_months', e) }}
                                                    >
                                                        <option value={1} title='Trên 01 tháng'>01 tháng</option>
                                                        <option value={3} title='Trên 03 tháng'>03 tháng</option>
                                                        <option value={6} title='Trên 06 tháng'>06 tháng</option>
                                                        <option value={12} title='Trên 12 tháng'>12 tháng</option>
                                                        <option value={24} title='Trên 24 tháng'>24 tháng</option>
                                                        <option value={36} title='Trên 36 tháng'>36 tháng</option>
                                                    </CSelect>
                                                </CFormGroup>
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            <CDataTable
                                items={_employees}
                                fields={fields}
                                columnFilter
                                tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                                itemsPerPageSelect={configs.itemsPerPageSelect}
                                itemsPerPage={configs.per_page}
                                hover
                                sorter
                                pagination
                                striped
                                responsive
                                columnFilterSlot={{
                                    group: (
                                        <>
                                            <CSelect
                                                name="group"
                                                id="input-group"
                                                className="form-control form-control-sm"
                                                value={this.state.group}
                                                onChange={(e) => { this.handleChange('group', e) }}
                                            >
                                                <option value="">-Tất cả-</option>
                                                {configs.groups && Object.keys(configs.groups).map((_key) => {
                                                    return (
                                                        <option value={_key} key={'group_' + _key}>{configs.groups[_key]}</option>
                                                    )
                                                }
                                                )}
                                            </CSelect>
                                        </>
                                    ),
                                    department: (
                                        <>
                                            <CSelect
                                                name="department"
                                                id="input-department"
                                                className="form-control form-control-sm"
                                                value={this.state.department}
                                                onChange={(e) => { this.handleChange('department', e) }}
                                            >
                                                <option value="">-Tất cả-</option>
                                                {configs.departments && Object.keys(configs.departments).map((_key) => {
                                                    return (
                                                        <option value={_key} key={'department' + _key}>{configs.departments[_key]}</option>
                                                    )
                                                }
                                                )}
                                            </CSelect>
                                        </>
                                    ),
                                    position: (
                                        <>
                                            <CSelect
                                                name="position"
                                                id="input-position"
                                                className="form-control form-control-sm"
                                                value={this.state.position}
                                                onChange={(e) => { this.handleChange('position', e) }}
                                            >
                                                <option value="">-Tất cả-</option>
                                                {configs.positions && Object.keys(configs.positions).map((_key) => {
                                                    return (
                                                        <option value={_key} key={'positions' + _key}>{configs.positions[_key]}</option>
                                                    )
                                                }
                                                )}
                                            </CSelect>
                                        </>
                                    ),
                                    gen: (
                                        <>
                                            <CSelect
                                                name="gen"
                                                id="input-gen"
                                                className="form-control form-control-sm"
                                                value={this.state.gen}
                                                onChange={(e) => { this.handleChange('gen', e) }}
                                            >
                                                <option value="">-Tất cả-</option>
                                                {configs.gens && Object.keys(configs.gens).map((_key) => {
                                                    return (
                                                        <option value={_key} key={'gen' + _key}>{configs.gens[_key]}</option>
                                                    )
                                                }
                                                )}
                                            </CSelect>
                                        </>
                                    ),
                                    job_type: (
                                        <>
                                            <CSelect
                                                name="job_type"
                                                id="input-job_type"
                                                className="form-control form-control-sm"
                                                value={this.state.job_type}
                                                onChange={(e) => { this.handleChange('job_type', e) }}
                                            >
                                                <option value="">-Tất cả-</option>
                                                {configs.job_types && Object.keys(configs.job_types).map((_key) => {
                                                    return (
                                                        <option value={_key} key={'job_type' + _key}>{configs.job_types[_key]}</option>
                                                    )
                                                }
                                                )}
                                            </CSelect>
                                        </>
                                    ),
                                    cccd: (
                                        <>
                                            <CSelect
                                                name="cccd"
                                                id="input-cccd"
                                                className="form-control form-control-sm"
                                                value={this.state.cccd}
                                                onChange={(e) => { this.handleChange('cccd', e) }}
                                            >
                                                <option value="all">-Tất cả-</option>
                                                <option value={true} >Có</option>
                                                <option value={false} >Không</option>
                                            </CSelect>
                                        </>
                                    ),
                                    recruited_by_employee: (
                                        <>
                                            <Select
                                                id="input-recruited_by_employee"
                                                name="recruited_by_employee"
                                                placeholder="..."

                                                isClearable
                                                options={employee_options}
                                                defaultValue={employee_options.find(({ value }) => value === this.state.selected_recruited_by_employee)}
                                                onChange={(choice) => { this.handleChangeSelect(choice, 'selected_recruited_by_employee') }}
                                                styles={customStyles}
                                            />
                                        </>
                                    )
                                }}
                                scopedSlots={{
                                    'index': (item, index) => {
                                        return (
                                            <td className="py-2">
                                                {index + 1}
                                            </td>
                                        )
                                    },
                                    'fullname': (item) => {
                                        var _notes = [];
                                        if (!helps.isEmpty(notes) && notes.length > 0) {
                                            _notes = notes.filter(note => note.object_id === item.id);
                                        }
                                        var note_content = '';
                                        if (!helps.isEmpty(_notes) && _notes.length > 0) {
                                            note_content = _notes.map((note) => {
                                                return (
                                                    <div className='border-bottom mb-2 pb-2'>
                                                        {note.content} <small>_<i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                                    </div>
                                                );
                                            });
                                        }
                                        return (
                                            <td className="py-2">
                                                {!helps.isEmpty(note_content) &&
                                                    <CPopover
                                                        header='Ghi chú: '
                                                        content={note_content}
                                                    >
                                                        <CLink className="text-dark" onClick={() => { this.props.history.push(`/employees/view/${item.id}`) }}>
                                                            <b>{item.fullname}</b>
                                                        </CLink>
                                                    </CPopover>
                                                }
                                                {helps.isEmpty(note_content) &&
                                                    <CLink className="text-dark" onClick={() => { this.props.history.push(`/employees/view/${item.id}`) }}>
                                                        <b>{item.fullname}</b>
                                                    </CLink>
                                                }
                                            </td>
                                        )
                                    },
                                    'employee_id': (item) => {
                                        return (
                                            <td className="py-2 text-nowrap">
                                                <CLink className="text-dark" onClick={() => { this.props.history.push(`/employees/view/${item.id}`) }}>
                                                    <b>{item.employee_id}</b>
                                                </CLink>
                                            </td>
                                        )
                                    },
                                    '_joint_date': (item) => {
                                        return (
                                            <td className="py-2 text-nowrap">
                                                <CPopover content={item.joint_during_date}>
                                                    <span>{item._joint_date}</span>
                                                </CPopover>
                                            </td>
                                        )
                                    },
                                    'department': (item) => {
                                        return (
                                            <td className="py-2">
                                                {configs.departments[item.department]}
                                            </td>
                                        )
                                    },
                                    'position': (item) => {
                                        return (
                                            <td className="py-2">
                                                {configs.positions[item.position]}
                                            </td>
                                        )
                                    },
                                    'gen': (item) => {
                                        return (
                                            <td className="py-2">
                                                {configs.gens[item.gen]}
                                            </td>
                                        )
                                    },
                                    'project': (item) => {
                                        return (
                                            <td className="py-2">
                                                <CLink className="text-dark" onClick={() => { this.props.history.push(`/projects/view/${item.project_id}`) }}>
                                                    <b>{item.project}</b>
                                                </CLink>
                                            </td>
                                        )
                                    },
                                    'cccd': (item) => {
                                        var color = item.cccd ? 'success' : 'danger';
                                        var status = item.cccd ? 'Có' : 'Không';
                                        return (
                                            <td className="py-2">
                                                <CBadge color={color}>{status}</CBadge>
                                            </td>
                                        )
                                    },
                                    '_bank': (item) => {
                                        let bank_acc_number = '';
                                        let bank_name = '';
                                        if (!helps.isEmpty(item.bank_account_number) && !helps.isEmpty(item.bank_name)) {
                                            bank_acc_number = item.bank_account_number;
                                            bank_name = item.bank_name;
                                        }
                                        return (
                                            <td className="py-2">
                                                {!helps.isEmpty(bank_acc_number) && !helps.isEmpty(bank_name) && <>
                                                    <CPopover
                                                        header={<><b>{item.fullname} ({item.employee_id})</b></>}
                                                        content={<CImg src={'https://img.vietqr.io/image/' + bank_name + '-' + bank_acc_number + '-compact.png'} width={180} />}
                                                    >
                                                        <span>
                                                            {bank_acc_number} <span className='text-muted font-italic'>({bank_name})</span>
                                                        </span>
                                                    </CPopover>
                                                </>
                                                }
                                            </td>
                                        )
                                    },
                                    'job_type': (item) => {
                                        var color = 'primary';
                                        switch (item.job_type) {
                                            case 'DNV':
                                                color = 'danger';
                                                break;
                                            case 'NVTV':
                                                color = 'warning';
                                                break;
                                            case 'CTV':
                                                color = 'secondary';
                                                break;
                                            default:
                                                color = 'primary';
                                        }
                                        return (
                                            <td className="py-2">
                                                <CBadge color={color}>{configs.job_types[item.job_type]}</CBadge>
                                            </td>
                                        )
                                    },
                                    'contracts': (item) => {
                                        let contracts = item.contracts ? item.contracts : [];
                                        let contract = {};
                                        let remaining = '';
                                        let end_date = today;
                                        let color = 'dark';
                                        let expired = false;
                                        if (!helps.isEmpty(contracts) && contracts.length > 0) {
                                            contracts = contracts.sort(function (a, b) {
                                                let b_end_date = b.end_date ? new Date(b.end_date).getTime() : today.getTime();
                                                let a_end_date = a.end_date ? new Date(a.end_date).getTime() : today.getTime();
                                                return b_end_date - a_end_date;
                                            });
                                            contract = contracts[0];
                                            end_date = contract.end_date ? new Date(contract.end_date) : today;
                                            remaining = helps.secondsToYmd(today, end_date);
                                            if (new Date(contract.end_date).getTime() <= today.getTime()) {
                                                color = 'danger';
                                                remaining = 'đã hết hạn!';
                                                expired = true;
                                            }
                                        }

                                        return (
                                            <td className="py-2">
                                                {!helps.isEmpty(contract) && <span>
                                                    <CPopover content={<span><i><u>hạn còn:</u></i> {remaining}</span>}>
                                                        <span className={'text-' + color}>{contract.id}<div><small className={'text-muted text-' + color}>{helps.formatDate_from_Timestamp(end_date)}</small></div></span>
                                                    </CPopover>
                                                </span>}
                                            </td>
                                        )
                                    },
                                    'recruited_by_employee': (item) => {
                                        return (
                                            <td className="py-2">
                                                {!helps.isEmpty(item.recruited_by_employee) && <CPopover content={item.recruited_by_employee.fullname + ' (' + item.recruited_by_employee.employee_id + ')'}>
                                                    <CLink className="text-dark" onClick={() => { this.props.history.push(`/employees/view/${item.recruited_by_employee.employee_id}`) }}>
                                                        {item.recruited_by_employee.fullname}
                                                    </CLink></CPopover>}
                                                {helps.isEmpty(item.recruited_by_employee) && <></>}
                                            </td>
                                        )
                                    },
                                    'actions':
                                        (item) => {
                                            var _notes = [];
                                            if (!helps.isEmpty(notes) && notes.length > 0) {
                                                _notes = notes.filter(note => note.object_id === item.id);
                                            }
                                            var note_content = '';
                                            if (!helps.isEmpty(_notes) && _notes.length > 0) {
                                                note_content = _notes.map((note) => {
                                                    return (
                                                        <div className='border-bottom mb-2 pb-2'>
                                                            {note.content} <small>_<i className='text-muted'>{helps.formatDateTime_from_Timestamp(note.note_time) + ' | ' + note.note_by_alias}</i></small>
                                                        </div>
                                                    );
                                                });
                                            }
                                            let exit_color = 'secondary';
                                            let exit_text = 'Thủ tục Thôi việc: ' + item.fullname;

                                            if (item.exit && item.exit.exit_completed) {
                                                exit_color = 'success';
                                                exit_text = 'Đã hoàn tất Thủ tục Thôi việc: ' + item.fullname;
                                            } else if (item.exit_status >= 1) {
                                                exit_color = 'danger';
                                                exit_text = 'Chưa hoàn tất Thủ tục Thôi việc: ' + item.fullname;
                                            }
                                            return (
                                                <td className="py-2 text-center">
                                                    {/*
                                                    <ThePermission {... this.props} component="employees" action="view">
                                                        <CTooltip content={'Xem chi tiết: ' + item.fullname}>
                                                            <CButton
                                                                color="warning"
                                                                size="sm mr-1"
                                                                title={'Xem chi tiết: ' + item.fullname}
                                                                onClick={() => { this.props.history.push(`/employees/view/${item.id}`) }}
                                                            >
                                                                <CIcon name="cil-face"></CIcon>
                                                            </CButton>
                                                        </CTooltip>
                                                    </ThePermission>
                                                    */}
                                                    <ThePermission {... this.props} component="employees" action="edit">
                                                        <CTooltip content={'Cập nhật thông tin: ' + item.fullname}>
                                                            <CButton
                                                                color="primary"
                                                                size="sm mr-1"
                                                                title={'Cập nhật thông tin: ' + item.fullname}
                                                                onClick={() => { this.props.history.push(`/employees/add_edit/${item.id}`) }}
                                                            >
                                                                <CIcon name="cil-pen-nib"></CIcon>
                                                            </CButton>
                                                        </CTooltip>
                                                    </ThePermission>
                                                    <ThePermission {... this.props} component="employees" action="edit">
                                                        <CTooltip content={exit_text}>
                                                            <CButton
                                                                color={exit_color}
                                                                size="sm mr-1"
                                                                title={exit_text}
                                                                onClick={() => { this.props.history.push(`/employees/view/${item.id}#exit`) }}
                                                            >
                                                                <CIcon name="cil-exit-to-app"></CIcon>
                                                            </CButton>
                                                        </CTooltip>
                                                    </ThePermission>
                                                </td>
                                            )
                                        },
                                }}
                            />
                        </CCardBody>
                        <CCardFooter>
                            Tổng: <b>{_employees && <>{_employees.length}</>}</b>
                        </CCardFooter>
                    </CCard>
                </CCol>
            </CRow >
        )
    }
}


export default Employees;
