import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoginPage from '../../views/pages/login/Login';

import { userActions } from '../../_actions';

class LoginPageContainer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if(this.props.loggedIn) {
            this.props.history.push('/');
        }
        return (
            <LoginPage {... this.props} alert={this.props.alert} />
        );
    }
}

function mapState(state) {
    const { loggingIn, loggedIn } = state.authentication;
    const { alert } = state;
    return { loggingIn, loggedIn, alert };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPageContainer);
export { connectedLoginPage as LoginPageContainer };