import { noteConstants } from "src/_constants";
import { noteService } from "src/_services/note.service";
import { alertActions } from ".";

export const noteActions = {
    getLatest,
    getAll,
    add,
    update,
    _delete,
}

function getLatest(_object = '', _object_id = '', _limit = 10) {
    return dispatch => {
        dispatch(request());
        return noteService.getLatest(_limit)
            .then(
                notes => {
                    dispatch(success(notes));
                    return notes;
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(_object, _object_id, _limit) { return { type: noteConstants.GET_LATEST_REQUEST, limit: _limit, object: _object, object_id: _object_id } }
    function success(notes) { return { type: noteConstants.GET_LATEST_SUCCESS, notes } }
    function failure(error) { return { type: noteConstants.GET_LIST_FAILURE, error } }
}

function getAll(_object, _object_id) {
    return dispatch => {
        dispatch(request());
        noteService.getAll(_object, _object_id)
            .then(
                notes => dispatch(success(notes)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: noteConstants.GET_LIST_REQUEST } }
    function success(notes) { return { type: noteConstants.GET_LIST_SUCCESS, notes } }
    function failure(error) { return { type: noteConstants.GET_LIST_FAILURE, error } }
}

function add(note) {
    return dispatch => {
        dispatch(request(note));
        noteService.add(note)
            .then(
                note => {
                    dispatch(success(note));
                    dispatch(alertActions.success('Thêm mới Ghi Chú thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) { return { type: noteConstants.ADD_REQUEST, note: note } }
    function success(note) { return { type: noteConstants.ADD_SUCCESS, note: note } }
    function failure(error) { return { type: noteConstants.ADD_FAILURE, error } }
}

function update(note) {
    return dispatch => {
        dispatch(request(note));
        noteService.update(note)
            .then(
                note => {
                    dispatch(success(note));
                    dispatch(alertActions.success('Chỉnh sửa Ghi Chú thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) { return { type: noteConstants.EDIT_REQUEST, note: note } }
    function success(note) { return { type: noteConstants.EDIT_SUCCESS, note: note } }
    function failure(error) { return { type: noteConstants.EDIT_FAILURE, error } }
}

function _delete(note) {
    return dispatch => {
        dispatch(request(note));
        noteService._delete(note)
            .then(
                note => {
                    dispatch(success(note));
                    dispatch(alertActions.success('Xoá Ghi Chú thành công!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(note) { return { type: noteConstants.DELETE_REQUEST, note: note } }
    function success(note) { return { type: noteConstants.DELETE_SUCCESS, note: note } }
    function failure(error) { return { type: noteConstants.DELETE_FAILURE, error } }
}