import React from 'react'
import { CFooter } from '@coreui/react';

const TheFooter = (props) => {

  return (
    <CFooter fixed={false}>
      <div>
        <b>MANAGEMENT SECTION</b>
        <span className="ml-1">&copy; 2023 <a href="https://sekin.vn" target="_blank" rel="noopener noreferrer">SEKIN</a>.</span>
      </div>
      <div className="mfs-auto">
        <span className="mr-1">Based on</span>
        <a href="https://react-redux.js.org" target="_blank" rel="noopener noreferrer">React Redux</a>
      </div>
    </CFooter>
  )
}

export default TheFooter;
