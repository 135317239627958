import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { ThePermission } from '../ThePermission';
import { helps } from 'src/_helpers';
import { customerActions, projectActions } from 'src/_actions';
import { provinceActions } from 'src/_actions';
import ProjectForm from 'src/views/projects/ProjectForm';


class ProjectFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project: {},
            form_header: "Thêm Mục Tiêu Mới",
            action_status: 'add',
        }
    }

    componentDidMount() {
        this.getProjects().then(() => {
            this.getProject();
        });
        if (!this.props.provinces) {
            this.props.getProvinces();
        }
        this.props.getCustomers();
    }

    getProject() {
        let project_id = this.props.match.params.id;
        const projects = this.props.projects;
        if (project_id) {
            let project = projects.filter((_project) => {
                return _project.id === project_id;
            });
            this.setState({ form_header: "Chỉnh Sửa Thông Tin Mục Tiêu: " + project[0].name, action_status: 'edit', project: project[0] });
        }
    }

    async getProjects() {
        var projects = await this.props.getAll();
        return projects;
    }

    async getCustomers() {
        await this.props.getCustomers('customer');
    }

    handleSubmit = (project) => {
        let project_id = this.props.match.params.id;
        if (helps.isEmpty(project_id)) {
            this.props.add(project);
            this.props.history.push('/projects');
        } else {
            this.props.update(project);
            this.props.history.push('/projects/view/' + project_id);
        }

    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.props.customers)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.props.provinces)) {
            return (
                <Loading></Loading>
            );
        }
        let project_id = this.props.match.params.id;
        const { project } = this.state;
        if (!helps.isEmpty(project_id) && helps.isEmpty(project)) return (<Loading></Loading>);

        return (
            <ThePermission {... this.props} component="projects" action="edit">
                <ProjectForm
                    {... this.props}
                    project={this.state.project}
                    form_header={this.state.form_header}
                    action_status={this.state.action_status}
                    handleSubmit={this.handleSubmit}
                />
            </ThePermission>
        );
    }
}
function mapStateToProps(state) {
    const { loading, projects } = state.projectReducer;
    const { provinces } = state.provinceReducer;
    const { user } = state.authentication;
    const { customers } = state.customerReducer;
    const { employees } = state.employeeReducer;
    return { loading, projects, customers, provinces, user, employees };
}

const actionCreators = {
    getAll: projectActions.getAll,
    add: projectActions.add,
    update: projectActions.update,
    getProvinces: provinceActions.getAll,
    getCustomers: customerActions.getAll,
};
const connectedFormContainer = connect(mapStateToProps, actionCreators)(ProjectFormContainer);
export { connectedFormContainer as ProjectFormContainer };