import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fileActions } from 'src/_actions';
import UploadFile from 'src/views/files/UploadFile';

class UploadContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            object: '',
            object_id: '',
        }
    }

    componentDidMount() {
        this.setState({ object: this.props.object, object_id: this.props.object_id });
    }

    render() {
        return (
            <UploadFile key={this.props.object + '-' + this.props.object_id} {... this.props} files={this.props.files} user={this.props.user} />
        );
    }
}

function mapStateToProps(state) {
    const { loading, upload_loading } = state.fileReducer;
    const { user } = state.authentication;
    return { loading, upload_loading, user };
}

const actionCreators = {
    uploadFile: fileActions.uploadFile,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(UploadContainer);
export { connectedContainer as UploadContainer };