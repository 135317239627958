import React, { Component } from 'react'
import {
    CRow,
    CCol,
    CButton,
    CForm,
    CTooltip,
    CInputRadio,
    CFormGroup,
    CLabel,
    CCallout,
    CSelect,
    CCard,
    CCardHeader,
    CCardBody,
    CCardFooter,
    CInput,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText,
    CPopover,
    CTextarea,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import Select from 'react-select';
import { helps } from 'src/_helpers';
import PUTexport from './PUTexport';

class PUT extends Component {

    constructor(props) {
        super(props);

        this.state = {
            head_text: {},
            from_to: 'put',
            store_id: '',
            employee_id: '',
            vendor_id: '',
            put_by: '',
            asset_date: new Date(),
            selected_receiver: {},
            employee: {},
            vendor: {},
            errors: [],
            selected_items: [],
            record: {},
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeSelectItems = this.handleChangeSelectItems.bind(this);
        this.handleChangeItems = this.handleChangeItems.bind(this);
    }

    componentDidMount() {
        const { user } = this.props;
        this.setState({ put_by: user.employee_id });
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [[field]]: selectedOptions });
        console.log(selectedOptions);
    }

    handleChangeSelectItems(selectedOptions, index) {
        const { items } = this.props;
        var selected_items = this.state.selected_items;
        let item = helps.getItemFromArr(items, selectedOptions.value);
        selected_items[index].item_id = selectedOptions.value;
        selected_items[index].dimension = item.dimension;
        selected_items[index].status = item.status;
        selected_items[index].put_reason = item.put_reason;
        selected_items[index].put_solution = item.put_solution;
        this.setState({ selected_items: selected_items });
        console.log(selected_items);
    }

    handleChangeItems(field, index, e) {
        var selected_items = this.state.selected_items;
        var selected_item = selected_items[index];
        selected_item[[field]] = e.target.value;
        if (field === 'quantity') selected_item[[field]] = parseInt(e.target.value);
        selected_items[index] = selected_item;
        this.setState({ selected_items: selected_items });
        console.log(selected_items);
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let record = {
                id: this.state.id,
                asset_date: this.state.asset_date,
                category_id: this.props.category_id,
                from_to: this.state.from_to,
                state: 'put',
                put_by: this.state.put_by,
                store_id: this.state.store_id,
                project_id: this.state.project_id,
                vendor_id: this.state.vendor_id,
                employee_id: this.state.employee_id,
                receiver: this.state.receiver,
                selected_items: this.state.selected_items,
            }
            this.props.handleSubmit(record);
        } else {
            return false;
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.store_id)) {
            formIsValid = false;
            errors["store_id"] = "Vui lòng chọn Kho.";
        }

        if (helps.isEmpty(this.state.selected_items)) {
            formIsValid = false;
            errors["selected_items"] = "Vui lòng thêm danh sách Tài sản.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    addMoreItem() {
        var selected_items = this.state.selected_items;
        selected_items.push({
            item_id: '',
            dimension: '',
            quantity: 1,
            status: 'new_in_use',
        });
        this.setState({ selected_items: selected_items });
        console.log(selected_items)
    }

    removeItem(index) {
        var selected_items = this.state.selected_items;
        if (index > -1) { // only splice array when item is found
            selected_items.splice(index, 1); // 2nd parameter means remove one item only
        }
        this.setState({ selected_items: selected_items });
        console.log(selected_items)
    }

    renderItems() {
        const { items } = this.props;
        var item_options = [];
        if (items && items.length > 0) {
            items.map((item) => {
                item_options.push({ value: item.id, label: item.code + ' - ' + item.name });
            });
        }

        var trs = [];
        if (!helps.isEmpty(this.state.selected_items)) {
            this.state.selected_items.map((item, index) => {
                let found_item = helps.getItemFromArr(items, item.item_id);
                let inventory = found_item.inventory ? found_item.inventory : [];
                let found_inventory = helps.getItemFromArr(inventory, this.state.store_id, 'store_id');
                let recent_inventory = 0;
                //console.log(found_item);
                if (!helps.isEmpty(found_inventory)) {
                    recent_inventory = found_inventory.current_inventory;
                }
                let inventory_color = 'danger';
                if (recent_inventory > 0) {
                    inventory_color = 'success';
                }
                let selected_option = {
                    value: found_item.id,
                    label: found_item.name,
                }
                if (helps.isEmpty(item.put_solution)) {
                    item['put_solution'] = 'storage';
                }
                if (!item.put_reason) {
                    item['put_reason'] = '';
                }
                trs.push(
                    <tr key={'item-' + index}>
                        <td style={{ textAlign: 'center' }}>{index + 1}</td>
                        <td>
                            <Select
                                id={"input-items-" + item.id}
                                placeholder="Tài sản ..."
                                options={item_options}
                                value={selected_option}
                                onChange={(choice) => { this.handleChangeSelectItems(choice, index) }}
                            />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <CInput
                                type='text'
                                name='dimension'
                                value={item.dimension}
                                onChange={(e) => { this.handleChangeItems('dimension', index, e) }}
                            />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <CInputGroup>
                                <CInput
                                    type='number'
                                    name='quantity'
                                    value={item.quantity}
                                    onChange={(e) => { this.handleChangeItems('quantity', index, e) }}
                                />
                                <CInputGroupAppend>
                                    <CPopover content={'Tồn kho hiện tại: ' + recent_inventory}>
                                        <CInputGroupText className={'bg-' + inventory_color + ' text-white'}>
                                            {recent_inventory}
                                        </CInputGroupText>
                                    </CPopover>
                                </CInputGroupAppend>
                            </CInputGroup>

                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <CSelect
                                name='status'
                                value={item.status}
                                onChange={(e) => { this.handleChangeItems('status', index, e) }}
                            >
                                {configs.asset_statuses && configs.asset_statuses.map((status) => {
                                    return (
                                        <option key={index + '-' + status.id} value={status.id}>{status.label}</option>
                                    )
                                })}
                            </CSelect>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <CTextarea
                                name='put_reason'
                                value={item.put_reason}
                                placeholder='Lý do hủy...'
                                onChange={(e) => { this.handleChangeItems('put_reason', index, e) }}
                            >

                            </CTextarea>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <CSelect
                                name='put_solution'
                                value={item.put_solution}
                                onChange={(e) => { this.handleChangeItems('put_solution', index, e) }}
                            >
                                {configs.asset_put_solutions && configs.asset_put_solutions.map((solution) => {
                                    return (
                                        <option key={index + '-' + solution.id} value={solution.id}>{solution.label}</option>
                                    )
                                })}
                            </CSelect>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <CTooltip content={'Xóa: ' + index}>
                                <CButton
                                    type='button'
                                    size='sm'
                                    color="danger"
                                    onClick={() => { this.removeItem(index) }}
                                >
                                    <CIcon name="cil-x" className="mfe-1 mt-0"></CIcon>
                                </CButton>
                            </CTooltip>
                        </td>
                    </tr>
                );
            })
        }
        return trs;
    }

    render() {
        const { employees, stores, user } = this.props;
        let employee = helps.getItemFromArr(employees, user.employee_id, 'employee_id');


        var filter_date_val = '';
        let asset_date = this.state.asset_date;
        if (this.state.asset_date) {
            if (!helps.isEmpty(asset_date) && typeof asset_date === 'object') {
                filter_date_val = helps.formatDate_for_input(asset_date.toDate(), 'YYYY-MM-DD');
            } else {
                filter_date_val = helps.formatDate_for_input(asset_date, 'YYYY-MM-DD');
            }

        } else {
            filter_date_val = new Date().toISOString().slice(0, 10);
        }

        return (
            <CForm onSubmit={(e) => this.handleSubmit(e)}>
                <CRow>
                    <CCol md={12}>
                        <CCard>
                            <CCardHeader>
                                Biên Bản Hủy / Lưu Kho Tài Sản
                            </CCardHeader>
                            <CCardBody>
                                <div className='mb-3'>
                                    <div className='mb-3'>
                                        <CRow>
                                            <CCol>
                                                <CLabel htmlFor='input-put_by'>Người Hủy:</CLabel>
                                                <CInput
                                                    type='text'
                                                    name='put_by'
                                                    id='input-put_by'
                                                    value={employee.fullname + ' (' + employee.employee_id + ')'}
                                                    readOnly
                                                />
                                                <small className="text-danger">{this.state.errors['put_by']}</small>
                                            </CCol>
                                            <CCol>
                                                <CLabel htmlFor='input-stores'>Kho:</CLabel>
                                                <CSelect
                                                    id='input-stores'
                                                    onChange={(e) => { this.handleChange('store_id', e) }}
                                                    required
                                                >
                                                    <option value=''> - Chọn Kho - </option>
                                                    {!helps.isEmpty(stores) && stores.map((store) => {
                                                        return (
                                                            <option key={store.id} value={store.id}>{store.name}</option>
                                                        );
                                                    })}
                                                </CSelect>
                                                <small className="text-danger">{this.state.errors['store_id']}</small>
                                            </CCol>
                                            <CCol>
                                                <CLabel htmlFor='asset-date'>Ngày Hủy / Lưu Kho:</CLabel>
                                                <CInput
                                                    type='date'
                                                    id='asset-date'
                                                    name='asset_date'
                                                    value={filter_date_val}
                                                    onChange={(e) => { this.handleChange('asset_date', e) }}
                                                />
                                            </CCol>
                                        </CRow>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className='mb-3'>
                                        <CRow>
                                            <CCol>
                                                <b>Danh sách tài sản:</b>
                                            </CCol>
                                        </CRow>
                                        <small className="text-danger">{this.state.errors['selected_items']}</small>
                                    </div>
                                    <table className="table table-bordered">
                                        <thead>
                                            <th style={{ width: '5%', textAlign: 'center' }}>#</th>
                                            <th style={{ width: '30%' }}>
                                                Tài sản
                                            </th>
                                            <th style={{ width: '15%', textAlign: 'center' }}>
                                                Kích thước/chủng loại
                                            </th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>
                                                Số lượng
                                            </th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>
                                                Tình trạng
                                            </th>
                                            <th style={{ width: '20%', textAlign: 'center' }}>
                                                Lý do hủy
                                            </th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>
                                                Phương thức hủy
                                            </th>
                                            <th style={{ width: '5%', textAlign: 'center' }}>
                                                X
                                            </th>
                                        </thead>
                                        <tbody>
                                            {this.renderItems()}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={8} className='text-right'>
                                                    <CTooltip content={'Thêm'}>
                                                        <CButton
                                                            type='button'
                                                            size='sm'
                                                            color="primary"
                                                            onClick={() => { this.addMoreItem() }}
                                                        >
                                                            <CIcon name="cil-plus" className="mfe-1 mt-0"></CIcon>
                                                            Thêm
                                                        </CButton>
                                                    </CTooltip>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </CCardBody>
                            <CCardFooter>
                                <CRow>
                                    <CCol>
                                        <CTooltip content={'Hủy / Lưu Kho'}>
                                            <CButton type="submit" color="primary">
                                                <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                                Hủy / Lưu Kho
                                            </CButton>
                                        </CTooltip>
                                    </CCol>
                                    <CCol className={'text-right'}>
                                        <PUTexport {... this.props} data={this.state} />
                                    </CCol>
                                </CRow>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                </CRow>
            </CForm>
        )
    }
}

export default PUT;
