import React, { Component } from 'react'
import {
    CCol,
    CRow,
    CButton,
    CDataTable,
    CSelect,
    CTooltip,
    CLink,
    CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { helps } from 'src/_helpers';
import configs from 'src/appConfigs';
import TaskStatus from './TaskStatus';

class Tasks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            object: '',
            object_id: '',
            filter_by_priority: '',
            filter_by_status: '',
        }
    }

    componentDidMount() {

    }

    handleChange(e, field) {
        let value = e.target.value;
        this.setState({ [field]: value });
    }

    handleDelete(e, task) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Công Việc: ' + task.title + ' ?');
        if (_confirm) {
            this.props._delete(task);
        }
        return false;
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        const { tasks, user, filter, users } = this.props;
        const now = new Date();
        var _tasks = [];
        if (!helps.isEmpty(tasks) && tasks.length > 0) {
            _tasks = tasks.filter(task => task.filter === filter);
        }

        if (_tasks && _tasks.length > 0) {
            _tasks.map((task) => {
                task['is_author'] = false;
                if (user.id == task.added_by) {
                    task['is_author'] = true;
                } else {
                    if (user.role === 'administrator') {
                        task['is_author'] = true;
                    }
                }
                task['over_deadline'] = '';
                if (task.deadline) {
                    if (task.status === 'unfinished' && now > task.deadline) {
                        task['over_deadline'] = helps.secondsToDhm((now - task.deadline) / 1000);
                    }
                }
            })
        }

        if (!helps.isEmpty(this.state.filter_by_priority)) {
            _tasks = _tasks.filter(task => task.priority == this.state.filter_by_priority);
        }

        if (!helps.isEmpty(this.state.filter_by_status)) {
            _tasks = _tasks.filter(task => task.status == this.state.filter_by_status);
        }

        const fields = [
            { key: 'title', label: 'Công Việc', _classes: 'font-weight-bold', _style: { width: '20%' } },
            { key: 'priority', label: 'Ưu Tiên', sorter: true },
            { key: 'added_by', label: 'Người Giao' },
            { key: 'assigns', label: 'Giao Cho' },
            { key: 'assign_status', label: 'Trạng Thái' },
            { key: 'deadline', label: 'Thời Hạn' },
            { key: 'object', label: 'Liên Quan' },
            { key: 'actions', label: 'Thao Tác', _style: { width: '10%' }, filter: false, sorter: false },
        ]
        return (
            <CRow>
                <CCol xl={12}>
                    <CDataTable
                        items={_tasks}
                        fields={fields}
                        columnFilter
                        tableFilter={{ label: 'Tìm Kiếm: ', placeholder: '...', _classes: 'font-weight-bold' }}
                        itemsPerPageSelect={configs.itemsPerPageSelect}
                        itemsPerPage={configs.per_page}
                        hover
                        sorter
                        pagination
                        striped
                        columnFilterSlot={{
                            priority: (
                                <>
                                    <CSelect
                                        name="group"
                                        id="input-group"
                                        className="form-control form-control-sm"
                                        value={this.state.group}
                                        onChange={(e) => { this.handleChange(e, 'filter_by_priority') }}
                                    >
                                        <option value="">-Tất cả-</option>
                                        {configs.task_priorities && configs.task_priorities.map((priority) => {
                                            return (
                                                <option key={priority.id} value={priority.id}>{priority.label}</option>
                                            )
                                        }
                                        )}
                                    </CSelect>
                                </>
                            ),
                            assign_status: (
                                <>
                                    <CSelect
                                        name="group"
                                        id="input-group"
                                        className="form-control form-control-sm"
                                        value={this.state.group}
                                        onChange={(e) => { this.handleChange(e, 'filter_by_status') }}
                                    >
                                        <option value="">-Tất cả-</option>
                                        {configs.task_statuses && Object.keys(configs.task_statuses).map((key) => {
                                            return (
                                                <option key={key} value={key}>{configs.task_statuses[key]}</option>
                                            )
                                        }
                                        )}
                                    </CSelect>
                                </>
                            ),
                        }}
                        scopedSlots={{
                            'title': (item) => {
                                var _class = 'font-weight-bold text-dark';
                                if (item.status === 'finished') {
                                    _class = 'font-weight-bold text-success';
                                }
                                return (
                                    <td className="py-2">
                                        <CTooltip content={item.title}>
                                            <CLink
                                                className={_class}
                                                onClick={() => { this.props.history.push(`/tasks/view/${item.id}`) }}
                                                style={{
                                                    textDecoration: (item.status === 'finished') ? 'line-through' : 'none',
                                                }}
                                            >
                                                {item.title}
                                            </CLink>
                                        </CTooltip>
                                    </td>
                                )
                            },
                            'priority': (item) => {
                                let _priority = helps.getItemFromArr(configs.task_priorities, item.priority);
                                return (
                                    <td className="py-2">
                                        <CBadge color={_priority.color}>{_priority.label}</CBadge>
                                    </td>
                                )
                            },
                            'added_by': (item) => {
                                let added_time_alias = helps.formatDateTime_from_Timestamp(item.added_time);
                                let added_by_user = helps.getItemFromArr(users, item.added_by);
                                return (
                                    <td className="py-2">
                                        {added_by_user.fullname + '-' + added_by_user.username}
                                        <div><span className="text-muted font-italic">{added_time_alias}</span></div>
                                    </td>
                                )
                            },
                            'assigns': (item) => {
                                return (
                                    <td className="py-2">
                                        <ul>
                                            {!helps.isEmpty(item.assigns) && item.assigns.length > 0 && item.assigns.map(assign => {
                                                return (
                                                    <li key={filter + '_' + item.id + '_' + assign.value}>
                                                        {assign.label}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </td>
                                )
                            },
                            'assign_status': (item) => {
                                return (
                                    <td className="py-2">
                                        <TaskStatus {... this.props} task={item} />
                                    </td>
                                )
                            },
                            'deadline': (item) => {
                                let deadline_alias = helps.formatDateTime_from_Timestamp(item.deadline);
                                return (
                                    <td className="py-2">
                                        <b>{deadline_alias}</b>
                                        {!helps.isEmpty(item.over_deadline) &&
                                            <CTooltip content={"Quá hạn: " + item.over_deadline}>
                                                <div><small className='text-danger'>( {item.over_deadline})</small></div>
                                            </CTooltip>
                                        }
                                    </td>
                                )
                            },
                            'object': (item) => {
                                return (
                                    <td className="py-2">
                                        <b>{item.object_alias}</b>
                                        {item.object_link &&
                                            <div><CLink onClick={() => { this.props.history.push(item.object_link) }}>{item.object_name}</CLink></div>
                                        }
                                        {!item.object_link &&
                                            <div>{item.object_name}</div>
                                        }
                                    </td>
                                )
                            },
                            'actions':
                                (item) => {
                                    return (
                                        <td className="py-2">
                                            <CTooltip content={"Xem:" + item.title}>
                                                <CButton
                                                    color="warning"
                                                    size="sm mr-1"
                                                    onClick={() => { this.props.history.push(`/tasks/view/${item.id}`) }}
                                                >
                                                    <CIcon name="cil-face"></CIcon>
                                                </CButton>
                                            </CTooltip>
                                            <CTooltip content={"Chỉnh Sửa:" + item.title}>
                                                <CButton
                                                    disabled={!item.is_author}
                                                    color={item.is_author ? "primary" : "secondary"}
                                                    size="sm"
                                                    onClick={() => { this.props.history.push(`/tasks/add_edit/${item.id}`) }}
                                                >
                                                    <CIcon name="cil-pen-nib"></CIcon>
                                                </CButton>
                                            </CTooltip>
                                            <CTooltip content={"Xoá:" + item.title}>
                                                <CButton
                                                    disabled={!item.is_author}
                                                    color={item.is_author ? "danger" : "secondary"}
                                                    size="sm"
                                                    className="ml-1"
                                                    onClick={(e) => { this.handleDelete(e, item) }}
                                                >
                                                    <CIcon name="cil-trash"></CIcon>
                                                </CButton>
                                            </CTooltip>
                                        </td>
                                    )
                                },
                        }}
                    />
                </CCol>
            </CRow >
        )
    }
}


export default Tasks;
