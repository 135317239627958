import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from 'src/views/dashboard/Dashboard';
import { checkActions, noteActions, projectActions, taskActions } from '../../_actions';
import { employeeActions } from '../../_actions';
import { helps } from 'src/_helpers';


class DashboardContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            curent_date: new Date(),
        }
    }

    componentDidMount() {
        this.getProjects();
        this.getEmployees();
        this.getUserTasks();
    }

    async getUserTasks() {
        const { user, tasks } = this.props;
        if (helps.isEmpty(tasks)) {
            let _tasks = await this.props.getUserTasks(user, 1000);
            return _tasks;
        }
    }

    async getProjects() {
        const { projects } = this.props;
        if (helps.isEmpty(projects)) {
            let _projects = await this.props.getProjects();
            return _projects;
        } else {
            return projects;
        }
    }

    async getEmployees() {
        const { employees } = this.props;
        if (helps.isEmpty(employees)) {
            let _employees = await this.props.getEmployees();
            return _employees;
        }
    }

    handleChangeCurentDate(e) {
        let curent_date = new Date(e.target.value);
        this.setState({ curent_date: curent_date });
        this.getChecks({ curent_date: curent_date });
        //console.log(curent_date);
    }

    render() {
        let today = new Date().toISOString().slice(0, 10);
        var date_val = today;
        if (this.state.curent_date) {
            date_val = helps.formatDate_for_input(this.state.curent_date.getTime(), 'YYYY-MM-DD');
        }
        return (
            <Dashboard {... this.props} />
        );
    }
}

function mapStateToProps(state) {
    const { projects } = state.projectReducer;
    const { employees } = state.employeeReducer;
    const { latest_notes } = state.noteReducer;
    const { tasks } = state.taskReducer;
    const { user } = state.authentication;
    return { user, projects, employees, latest_notes, tasks };
}

const actionCreators = {
    getChecks: checkActions.getAll,
    getProjects: projectActions.getAll,
    getEmployees: employeeActions.getAll,
    getLatestNotes: noteActions.getLatest,
    getUserTasks: taskActions.getUserTasks,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(DashboardContainer);
export { connectedContainer as DashboardContainer };