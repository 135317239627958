import React, { Component } from 'react'
import { ThePermission } from 'src/containers/ThePermission';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
    CDataTable,
    CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';

class Tags extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount() {

    }

    handleEdit(e, tag) {
        e.preventDefault();
        this.props.handleEdit(tag);
    }

    handleDelete(e, tag) {
        e.preventDefault();
        let _confirm = window.confirm('Bạn muốn xoá Nhãn: "' + tag.name + '"?');
        if (_confirm) {
            this.props.handleDelete(tag);
        }
        return false;
    }

    render() {
        const { tags } = this.props;
        var _tags = tags.filter(function (element) {
            return element !== undefined;
        });
        const fields = [
            { key: 'id', _style: { width: '15%' } },
            { key: 'name', label: 'Tên Nhãn', _classes: 'font-weight-bold', _style: { width: '25%' } },
            { key: 'color', label: 'Màu Nhãn', _classes: 'text-center', _style: { width: '15%' } },
            { key: 'object', label: 'Đối Tượng', _classes: 'text-center', _style: { width: '15%' } },
            { key: 'actions', label: 'Thao Tác', _style: { width: '10%' }, filter: false, sorter: false },
        ]

        return (
            <CRow>
                <CCol xl={12}>
                    <CCard>
                        <CCardHeader>
                            <h3>Quản Lý Nhãn</h3>
                        </CCardHeader>
                        <CCardBody>
                            <CDataTable
                                items={_tags}
                                fields={fields}
                                columnFilter
                                tableFilter
                                itemsPerPageSelect
                                itemsPerPage={configs.per_page}
                                hover
                                sorter
                                pagination
                                scopedSlots={{
                                    'object':
                                        (item) => {
                                            return (
                                                <td className="py-2 text-center">
                                                    {configs.objects[item.object]}
                                                </td>
                                            );
                                        },
                                    'color':
                                        (item) => {
                                            return (
                                                <td className="py-2 text-center">
                                                    <CBadge color={item.color} className="p-2">{configs.colors[item.color]}</CBadge>
                                                </td>
                                            );
                                        },
                                    'actions':
                                        (item) => {
                                            return (
                                                <td className="py-2">
                                                    <ThePermission {... this.props} component="users" action="edit">
                                                        <CButton
                                                            type="button"
                                                            color="primary"
                                                            size="sm mr-1"
                                                            title="Sửa"
                                                            onClick={(e) => { e.preventDefault(); this.props.handleEdit(item); }}
                                                        >
                                                            <CIcon name="cil-pen-nib"></CIcon>
                                                        </CButton>
                                                    </ThePermission>
                                                    <ThePermission {... this.props} component="users" action="delete">
                                                        <CButton
                                                            color="danger"
                                                            size="sm"
                                                            title="Xoá"
                                                            onClick={(e) => { this.handleDelete(e, item) }}
                                                        >
                                                            <CIcon name="cil-trash"></CIcon>
                                                        </CButton>
                                                    </ThePermission>

                                                </td>
                                            )
                                        },
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        )
    }
}
export default Tags;
