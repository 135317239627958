import React, { Component } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCol,
    CRow,
    CButton,
    CLabel,
    CForm,
    CFormGroup,
    CFormText,
    CInput,
    CInvalidFeedback,
    CInputRadio,
    CTooltip,
    CTextarea,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import configs from 'src/appConfigs';
import { helps } from 'src/_helpers';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';

class CandidatesForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            id: '',
            fullname: '',
            phone: '',
            height: '',
            weight: '',
            id_type: 'CCCD',
            gen: 'NAM',
            id_number: '',
            position: '',
            birthyear: '',
            basic_salary: '',
            candidate_source: '',
            candidate_from: '',
            permanent_address: '',
            home_town: '',
            aspiration: '',
            interview_time: '',
            interview_by: '',
            added_by: this.props.user.id,
            added_date: new Date(),
            information: '',
            from_options: [],

        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeDateTime = this.handleChangeDateTime.bind(this);
    }

    componentDidMount() {
        const { candidate, user } = this.props;
        this.setState({ added_by: user.id });

        if (!helps.isEmpty(candidate)) {
            this.setState(Object.assign(this.state, candidate));
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            let candidate = {
                id: this.state.id,
                fullname: this.state.fullname,
                gen: this.state.gen,
                phone: this.state.phone,
                height: this.state.height,
                weight: this.state.weight,
                id_number: this.state.id_number,
                id_type: this.state.id_type,
                position: this.state.position,
                permanent_address: this.state.permanent_address,
                home_town: this.state.home_town,
                birthyear: this.state.birthyear,
                basic_salary: this.state.basic_salary,
                added_by: this.state.added_by,
                added_date: this.state.added_date,
                candidate_from: this.state.candidate_from,
                candidate_source: this.state.candidate_source,
                interview_time: this.state.interview_time,
                interview_by: this.state.interview_by,
                information: this.state.information,
            }
            //console.log(employee);
            this.props.handleSubmit(candidate);
        } else {
            return false;
        }
    }

    handleChange(field, e) {
        this.setState({
            [field]: e.target.value
        });
    }

    handleChangeDateTime(field, value) {
        this.setState({
            [field]: value
        });
    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [field]: selectedOptions });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (helps.isEmpty(this.state.fullname)) {
            formIsValid = false;
            errors["fullname"] = "Vui lòng nhập Họ & Tên.";
        }

        if (helps.isEmpty(this.state.phone)) {
            formIsValid = false;
            errors["phone"] = "Vui lòng nhập Số Điện Thoại.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }


    render() {
        const { employees } = this.props;
        var from_options = [];
        if (!helps.isEmpty(employees)) {
            employees.map((employee) => {
                let option = {
                    value: employee.employee_id,
                    label: employee.fullname + ' (' + employee.employee_id + ')'
                }
                from_options.push(option);
            });
        }
        return (
            <CRow>
                <CCol xl={12}>
                    <CForm onSubmit={(e) => this.handleSubmit(e)}>
                        <CCard>
                            <CCardHeader>
                                {this.props.form_header}
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol>
                                                    <CLabel htmlFor="input-fullname">Họ & Tên:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="fullname"
                                                        id="input-fullname"
                                                        value={this.state.fullname}
                                                        required
                                                        className='font-weight-bold'
                                                        onChange={(e) => { this.handleChange('fullname', e) }}
                                                    />
                                                    <small className="help-block text-danger font-italic">{this.state.errors['fullname']}</small>
                                                </CCol>
                                                <CCol>
                                                    <CLabel htmlFor="input-position">Vị Trí Ứng Tuyển:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="position"
                                                        id="input-position"
                                                        value={this.state.position}
                                                        required
                                                        onChange={(e) => { this.handleChange('position', e) }}
                                                    />
                                                    <small className="help-block text-danger font-italic">{this.state.errors['position']}</small>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol md={6}>
                                                    <CLabel htmlFor="input-id_number">Số CCCD/CMT:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="id_number"
                                                        id="input-id_number"
                                                        value={this.state.id_number}
                                                        onChange={(e) => { this.handleChange_Get_ID('id_number', e) }}
                                                        onBlur={(e) => { this.handleChange_Get_ID('id_number', e) }}

                                                    />
                                                    <CInvalidFeedback className="help-block">{this.state.errors['id_number']}</CInvalidFeedback>
                                                </CCol>
                                                <CCol md={6}>
                                                    <div>
                                                        <CLabel htmlFor="id_type">
                                                            Loại:
                                                        </CLabel>
                                                    </div>
                                                    {configs.id_types && Object.keys(configs.id_types).map((_key) => {
                                                        return (
                                                            <CFormGroup key={_key} variant="custom-radio" inline>
                                                                <CInputRadio
                                                                    custom
                                                                    id={"id_type" + _key}
                                                                    name="id_type"
                                                                    value={_key}
                                                                    checked={this.state.id_type === _key}
                                                                    onChange={(e) => { this.handleChange('id_type', e) }}
                                                                />
                                                                <CLabel variant="custom-checkbox" htmlFor={"id_type_" + _key} className="cursor-pointer">
                                                                    {configs.id_types[_key]}
                                                                </CLabel>
                                                            </CFormGroup>
                                                        )
                                                    }
                                                    )}
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-birthyear">Năm sinh:</CLabel>
                                            <input
                                                type="text"
                                                value={this.state.birthyear}
                                                placeholder="1990"
                                                onChange={e => this.handleChange('birthyear', e)}
                                                className="form-control"
                                            />
                                            <CInvalidFeedback className="help-block">{this.state.errors['birthyear']}</CInvalidFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-gen" className="mr-3">Giới tính:</CLabel>
                                            {configs.gens && Object.keys(configs.gens).map((_key) => {
                                                return (
                                                    <CFormGroup variant="custom-radio" key={"gen_" + _key} inline>
                                                        <CInputRadio
                                                            custom
                                                            id={"gen_" + _key}
                                                            name="gen"
                                                            value={_key}
                                                            checked={this.state.gen === _key}
                                                            onChange={(e) => { this.handleChange('gen', e) }}
                                                        />
                                                        <CLabel variant="custom-checkbox" htmlFor={"gen_" + _key} className="cursor-pointer">
                                                            {configs.gens[_key]}
                                                        </CLabel>
                                                    </CFormGroup>
                                                )
                                            }
                                            )}
                                            <CInvalidFeedback className="help-block">{this.state.errors['gen']}</CInvalidFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-interview_time" className="mr-3">Lịch Phỏng Vấn:</CLabel>
                                            <DateTimePicker
                                                value={this.state.interview_time}
                                                id='input-interview_time'
                                                format={'dd/MM/yyyy HH:mm'}
                                                onChange={(value) => { this.handleChangeDateTime('interview_time', value) }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-information" className="mr-3">Thông Tin Thêm:</CLabel>
                                            <CTextarea
                                                name='information'
                                                id='input-information'
                                                value={this.state.information}
                                                onChange={(e) => { this.handleChange('information', e) }}
                                            >
                                            </CTextarea>
                                            <CFormText className="help-block">Nguyện vọng về công việc, mong muốn về công việc,...</CFormText>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-interview_by" className="mr-3">Người phỏng vấn:</CLabel>
                                            <Select
                                                id="input-interview_by"
                                                options={from_options}
                                                isClearable
                                                isMulti
                                                value={this.state.interview_by}
                                                onChange={(choice) => { this.handleChangeSelect(choice, 'interview_by') }}
                                            />
                                            <small className="text-danger">{this.state.errors['interview_by']}</small>
                                            <CFormText className="help-block">Người được phân công, chỉ định phỏng vấn ứng viên.</CFormText>
                                        </div>
                                    </CCol>
                                    <CCol md={6}>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol md={6}>
                                                    <CLabel htmlFor="input-phone">Số Điện Thoại:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="phone"
                                                        id="input-phone"
                                                        required
                                                        value={this.state.phone}
                                                        onChange={(e) => { this.handleChange('phone', e) }}
                                                    />
                                                    <small className="help-block text-danger font-italic">{this.state.errors['phone']}</small>
                                                </CCol>
                                                <CCol md={6}>
                                                    <CLabel htmlFor="input-basic_salary">Lương CB:</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="basic_salary"
                                                        id="input-basic_salary"
                                                        value={this.state.basic_salary}
                                                        onChange={(e) => { this.handleChange('basic_salary', e) }}
                                                    />
                                                    <CInvalidFeedback className="help-block">{this.state.errors['basic_salary']}</CInvalidFeedback>
                                                </CCol>
                                            </CRow>

                                        </div>
                                        <div className="mb-3">
                                            <CRow>
                                                <CCol md={6}>
                                                    <CLabel htmlFor="input-id_number">Chiều cao(cm):</CLabel>
                                                    <CInput
                                                        type="number"
                                                        name="height"
                                                        id="input-height"
                                                        value={this.state.height}
                                                        onChange={(e) => { this.handleChange('height', e) }}
                                                    />
                                                    <CInvalidFeedback className="help-block">{this.state.errors['height']}</CInvalidFeedback>
                                                </CCol>
                                                <CCol md={6}>
                                                    <CLabel htmlFor="input-id_number">Cân nặng(kg):</CLabel>
                                                    <CInput
                                                        type="number"
                                                        name="weight"
                                                        id="input-weight"
                                                        value={this.state.weight}
                                                        onChange={(e) => { this.handleChange('weight', e) }}
                                                    />
                                                    <CInvalidFeedback className="help-block">{this.state.errors['weight']}</CInvalidFeedback>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-permanent_address">Địa chỉ thường trú:</CLabel>
                                            <CInput
                                                type="text"
                                                name="permanent_address"
                                                id="input-permanent_address"
                                                defaultValue={this.state.permanent_address}
                                                onChange={(e) => { this.handleChange('permanent_address', e) }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-home_town">Nguyên quán:</CLabel>
                                            <CInput
                                                type="text"
                                                name="home_town"
                                                id="input-home_town"
                                                defaultValue={this.state.home_town}
                                                onChange={(e) => { this.handleChange('home_town', e) }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-birthyear">Kênh Tuyển Dụng:</CLabel>
                                            <input
                                                type="text"
                                                value={this.state.candidate_source}
                                                onChange={e => this.handleChange('candidate_source', e)}
                                                className="form-control"
                                            />
                                            <small className="help-block">{this.state.errors['candidate_source']}</small>
                                            <CFormText className="help-block">VD: FB, Giới thiệu, Trực tiếp, Cộng tác viên,...</CFormText>
                                        </div>
                                        <div className="mb-3">
                                            <CLabel htmlFor="input-candidate_from">Nguồn Ứng Viên từ:</CLabel>
                                            <Select
                                                id="input-candidate_from"
                                                options={from_options}
                                                value={this.state.candidate_from}
                                                onChange={(choice) => { this.handleChangeSelect(choice, 'candidate_from') }}
                                            />
                                            <small className="text-danger">{this.state.errors['candidate_from']}</small>
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CTooltip content={'Lưu'}>
                                    <CButton type="submit" color="primary">
                                        <CIcon name="cil-save" className="mfe-1 mt-0"></CIcon>
                                        Lưu
                                    </CButton>
                                </CTooltip>
                                <CTooltip content={'Huỷ'}>
                                    <CButton type="button" color="secondary" onClick={() => { this.props.history.push('/employees/candidates') }} className='ml-3 '>
                                        <CIcon name="cil-x-circle" className="mfe-1 mt-0"></CIcon>
                                        Huỷ
                                    </CButton>
                                </CTooltip>
                            </CCardFooter>
                        </CCard>
                    </CForm>
                </CCol>
            </CRow>
        )
    }
}
export default CandidatesForm;

