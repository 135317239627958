import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CRow, CCol,
    CButton,
    CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Loading from 'src/containers/_loading';
import { ThePermission } from 'src/containers/ThePermission';
import Employee from 'src/views/employees/Employee';
import { employeeActions, fileActions, userActions, maneuverActions, violationActions, projectActions } from 'src/_actions';
import { helps } from 'src/_helpers';
import { NotesContainer } from '../notes';
import { FilesContainer } from '../files';
import { TasksComponentContainer } from '../tasks/TasksComponentContainer';
import { ViolationsContainer } from './violations';
import { AssetsComponentContainer } from '../assets/AssetsComponentContainer';
import { EmployeeExitContainer } from './EmployeeExitContainer';
import { EmployeeTimekeepingContainer } from './EmployeeTimekeepingContainer';

class EmployeeContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            employee: {},
            object: 'employees',
            object_id: '',
            activeTab: 'info',
        }
        this.handleChangeActiveTab = this.handleChangeActiveTab.bind(this);
    }

    componentDidMount() {
        this.getProjects();
        this.getUsers();
        this.getEmployees().then(() => {
            this.getEmployee().then(() => {
                this.getFiles().then((files) => {
                    var employee = this.state.employee;
                    employee['files'] = files;
                    this.setState({ employee: employee });
                });
                this.getManeuvers();
            });

        });

        let hash = window.location.hash.substring(1);
        //console.log(hash);
        if (!helps.isEmpty(hash)) this.setState({ activeTab: hash });
    }

    async getEmployee() {
        let employee_id = this.props.match.params.id;
        const { employees } = this.props;
        var employee = {};
        if (employee_id && employees) {
            let employee = helps.getItemFromArr(employees, employee_id);

            this.setState({ employee: employee });
        }

        return employee;
    }

    async getFiles() {
        if (!helps.isEmpty(this.state.employee)) {
            const files = await this.props.getFiles('employees', this.state.employee.id);
            return files;
        }
    }

    async getEmployees() {
        const { employees } = this.props;
        if (helps.isEmpty(employees)) return await this.props.getAll();
        else return employees;
    }

    async getUsers() {
        const { users } = this.props;
        if (helps.isEmpty(users)) await this.props.getUsers();
    }

    async getManeuvers() {
        const { employee } = this.state;
        let maneuvers = this.props.getManeuvers({ employee: employee }, 1000);
        return maneuvers;
    }

    async getProjects() {
        if (helps.isEmpty(this.props.projects)) await this.props.getProjects();
    }

    handleChangeActiveTab(tab) {
        this.setState({ activeTab: tab });
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.props.employees)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.props.users)) {
            return (
                <Loading></Loading>
            );
        }
        if (helps.isEmpty(this.state.employee)) {
            return (
                <Loading></Loading>
            );
        }
        const employee = this.state.employee;
        let exit_color = 'dark';
        if (employee.exit_status > 1) {
            exit_color = 'successs';
        }
        if (employee.exit_status === 1) {
            exit_color = 'danger';
        }
        return (
            <ThePermission {... this.props} component="employees" action="view">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol>
                                Nhân Viên: <b>{employee.fullname} {employee.employee_id}</b>
                            </CCol>
                            <CCol className='text-right'>
                                <ThePermission {... this.props} component="customers" action="edit">
                                    <CButton
                                        color="primary"
                                        size="sm"
                                        title="Cập nhật thông tin"
                                        onClick={() => { this.props.history.push(`/employees/add_edit/${employee.id}`) }}
                                    >
                                        <CIcon name="cil-pen-nib" className="mfe-1 mt-0"></CIcon>
                                        Chỉnh Sửa
                                    </CButton>
                                </ThePermission>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CTabs activeTab={this.state.activeTab} onActiveTabChange={this.handleChangeActiveTab}>
                            <CNav variant="tabs">
                                <CNavItem>
                                    <CNavLink data-tab="info">
                                        <h6>Thông Tin</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="timekeeping">
                                        <h6>Bảng Chấm Công</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="tasks">
                                        <h6>Công Việc</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="violations">
                                        <h6>Quản Lý Vi Phạm</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="assets">
                                        <h6>Bàn Giao / Thu Hồi</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="notes">
                                        <h6>Ghi Chú</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="files">
                                        <h6>Tài Liệu</h6>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="exit" >
                                        <CTooltip content={'BB Xác Nhận Thôi Việc'}>
                                            <h6 className={'text-' + exit_color}>
                                                Thủ Tục Thôi Việc
                                                <CIcon name="cil-exit-to-app" className={'ml-1'}></CIcon>
                                            </h6>
                                        </CTooltip>
                                    </CNavLink>
                                </CNavItem>
                            </CNav>
                            <CTabContent className="py-3">
                                <CTabPane data-tab="info">
                                    <Employee {... this.props} employees={this.props.employees} employee={this.state.employee} />
                                </CTabPane>
                                <CTabPane data-tab="timekeeping">
                                    {this.state.activeTab === 'timekeeping' && <EmployeeTimekeepingContainer {... this.props} employee={this.state.employee} />}
                                </CTabPane>
                                <CTabPane data-tab="tasks">
                                    {this.state.activeTab === 'tasks' && <TasksComponentContainer {... this.props} object={this.state.object} object_id={employee.id} />}
                                </CTabPane>
                                <CTabPane data-tab="violations">
                                    {this.state.activeTab === 'violations' && <ViolationsContainer {... this.props} employee={this.state.employee} />}
                                </CTabPane>
                                <CTabPane data-tab="assets">
                                    {this.state.activeTab === 'assets' && <AssetsComponentContainer {... this.props} object='employee' object_data={this.state.employee} />}
                                </CTabPane>
                                <CTabPane data-tab="notes">
                                    {this.state.activeTab === 'notes' && <NotesContainer {... this.props} object={this.state.object} object_id={employee.id} />}
                                </CTabPane>
                                <CTabPane data-tab="files">
                                    {this.state.activeTab === 'files' && <FilesContainer key={'files_' + this.state.object + '_' + this.state.object_id} {... this.props} object={this.state.object} object_id={employee.id} />}
                                </CTabPane>
                                <CTabPane data-tab="exit">
                                    {this.state.activeTab === 'exit' && <EmployeeExitContainer {... this.props} employee={this.state.employee} />}
                                </CTabPane>
                            </CTabContent>
                        </CTabs>
                    </CCardBody>
                </CCard>
            </ThePermission>

        );
    }
}

function mapStateToProps(state) {
    const { loading, employees } = state.employeeReducer;
    const { users } = state.userReducer;
    const { notes } = state.noteReducer;
    const { user } = state.authentication;
    const { maneuvers } = state.maneuverReducer;
    const { violations } = state.violationReducer;
    const { projects } = state.projectReducer;
    return { loading, employees, users, user, notes, maneuvers, violations, projects };
}

const actionCreators = {
    getAll: employeeActions.getAll,
    setParams: employeeActions.setParams,
    updateField: employeeActions.updateField,
    getUser: userActions.getUser,
    addUser: userActions.addUser,
    getUsers: userActions.getAll,
    deleteFile: fileActions.deleteFile,
    getFiles: fileActions.getAll,
    getManeuvers: maneuverActions.getAll,
    getViolations: violationActions.getAll,
    getProjects: projectActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(EmployeeContainer);
export { connectedContainer as EmployeeContainer };