import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CRow, CCol,
    CTooltip,
    CButton,
    CFormGroup,
    CInputCheckbox,
    CLabel,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,

} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Tasks from 'src/views/tasks/Tasks';
import { customerActions, employeeActions, projectActions, taskActions, userActions } from 'src/_actions';
import TasksCalendar from 'src/views/tasks/TasksCalendar';

class TasksContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list_view: 'list',
            hide_finished: false,
            curent_date: new Date(),
        }
    }

    componentDidMount() {
        const { users, projects } = this.props;
        this.setState({ object: this.props.object, object_id: this.props.object_id });
        this.getTasks({ curent_date: this.state.curent_date });
        this.props.getCuscomers('all');

        if (!users) {
            this.props.getUsers();
        }

        if (!projects) {
            this.props.getProjects();
        }

        var settings = JSON.parse(localStorage.getItem('settings')) ? JSON.parse(localStorage.getItem('settings')) : {};
        if (settings && settings.tasks && settings.tasks.list_view) {
            this.setState({ list_view: settings.tasks.list_view });
        } else {
            var settings_tasks = settings.tasks ? settings.tasks : {};
            settings_tasks['list_view'] = this.state.list_view;
            settings = Object.assign({ tasks: settings_tasks });
            localStorage.setItem('settings', JSON.stringify(settings));
        }

    }

    async getTasks(params) {
        const { user } = this.props;
        params['user'] = user;
        return await this.props.getUserTasks(params, 1000);
    }

    handleView(list_view) {
        this.setState({ list_view: list_view });
        let settings = JSON.parse(localStorage.getItem('settings'));
        settings.tasks['list_view'] = list_view;
        localStorage.setItem('settings', JSON.stringify(settings));
    }

    hideFinished() {
        let hide_finished = JSON.parse(localStorage.getItem('views'));
        this.setState({ hide_finished: !this.state.hide_finished });
    }

    render() {
        const view = this.state.list_view;
        return (
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol md={6} sm={12}>
                            Quản Lý Công Việc
                        </CCol>
                        <CCol md={6} sm={12} className='text-right'>
                            <CTooltip content={"Xem dạng danh sách"}>
                                <CButton
                                    color={(view === 'list') ? 'primary' : "secondary"}
                                    size="sm"
                                    className="mr-2"
                                    onClick={() => { this.handleView('list') }}
                                >
                                    <CIcon name="cil-list" className="mfe-1 mt-0"></CIcon>
                                </CButton>
                            </CTooltip>
                            <CTooltip content={"Xem dạng lịch"}>
                                <CButton
                                    color={(view === 'calendar') ? 'primary' : "secondary"}
                                    size="sm"
                                    className="mr-2"
                                    onClick={() => { this.handleView('calendar') }}
                                >
                                    <CIcon name="cil-calendar" className="mfe-1 mt-0"></CIcon>
                                </CButton>
                            </CTooltip>
                            <CDropdown tag={'span'} className='mr-3 ml-3'>
                                <CTooltip content='Cài đặt'>
                                    <CDropdownToggle caret color="secondary" size='sm'>
                                        <CIcon name="cil-settings" />
                                    </CDropdownToggle>
                                </CTooltip>
                                <CDropdownMenu className="pt-0" placement="bottom-end">
                                    <CDropdownItem>
                                        <CFormGroup variant='custom-radio' inline>
                                            <CInputCheckbox
                                                id='input-hide_finished'
                                                name='hide_finished'
                                                className='cursor-pointer'
                                            />
                                            <CLabel htmlFor='input-hide_finished' className='cursor-pointer'>Ẩn các Công việc đã hoàn thành</CLabel>
                                        </CFormGroup>
                                    </CDropdownItem>
                                </CDropdownMenu>
                            </CDropdown>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody key={this.state.list_view}>
                    {view === 'list' &&
                        <CTabs activeTab="assigns">
                            <CNav variant="tabs">
                                <CNavItem>
                                    <CNavLink data-tab="assigns">
                                        <h5>Cần Làm</h5>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="owns">
                                        <h5>Tôi Đã Giao</h5>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink data-tab="follows">
                                        <h5>Theo Dõi</h5>
                                    </CNavLink>
                                </CNavItem>
                            </CNav>
                            <CTabContent className="py-3">
                                <CTabPane data-tab="assigns">
                                    <Tasks {... this.props} filter={'assigns'} />
                                </CTabPane>
                                <CTabPane data-tab="owns">
                                    <Tasks {... this.props} filter={'owns'} />
                                </CTabPane>
                                <CTabPane data-tab="follows">
                                    <Tasks {... this.props} filter={'follows'} />
                                </CTabPane>
                            </CTabContent>
                        </CTabs>
                    }
                    {view === 'calendar' &&
                        <TasksCalendar {... this.props} />
                    }
                </CCardBody>
            </CCard>
        );
    }
}

function mapStateToProps(state) {
    const { loading, tasks } = state.taskReducer;
    const { customers } = state.customerReducer;
    const { projects } = state.projectReducer;
    const { employees } = state.employeeReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    return { loading, user, users, tasks, customers, projects, employees };
}

const actionCreators = {
    getUserTasks: taskActions.getUserTasks,
    add: taskActions.add,
    update: taskActions.update,
    _delete: taskActions._delete,
    getProjects: projectActions.getAll,
    getCuscomers: customerActions.getAll,
    getEmployees: employeeActions.getAll,
    getUsers: userActions.getAll,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(TasksContainer);
export { connectedContainer as TasksContainer };