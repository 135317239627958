import { db } from "src/dbConfigs";
import { helps } from "src/_helpers";
import { userService } from "./user.service";
export const contactService = {
    getAll,
    _delete,
    add,
    update,
}

async function getAll(customer_id, project_id) {
    try {
        let ref = db.collection('contacts');
        var _query = {};
        if (!helps.isEmpty(project_id)) {
            _query = ref.where('project_id', '==', project_id);
        } else {
            if (!helps.isEmpty(customer_id)) {
                _query = ref.where('customer_id', '==', customer_id);
            } else {
                return [];
            }
        }

        const snapshot = await _query.get();
        const contacts = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        await Promise.all(
            contacts.map(async (contact) => {
                let user = await userService.getById(contact.added_by);
                contact['added_by_alias'] = user.fullname + '-' + user.username;
                contact['added_time'] = contact['added_time'].toDate();
            })
        );

        return contacts;
    } catch (error) {
        return error;
    }
}

async function add(contact) {
    try {
        const docRef = await db.collection("contacts").add(contact);
        contact.id = docRef.id;
        update(contact);
        console.log("Firebase: contact has been added successfully!");
        return contact;
    } catch (error) {
        console.error("Error adding contact: ", error);
    }
}

async function update(contact) {
    var Ref = db.collection("contacts").doc(contact.id);

    try {
        await Ref.update(contact);
        console.log("Firebase: contact has been updated successfully!");
        return contact;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

async function _delete(contact) {
    var Ref = db.collection("contacts").doc(contact.id);

    try {
        await Ref.delete();
        console.log("Firebase: contact has been deleted successfully!");
        return contact;
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}
