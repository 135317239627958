import React, { Component } from 'react'
import {
    CBadge,
    CCol,
    CJumbotron,
    CLink,
    CRow,
} from '@coreui/react';
import { helps } from 'src/_helpers';
import { ThePermission } from 'src/containers/ThePermission';
import configs from 'src/appConfigs';

class Candidate extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    componentDidMount() {

    }


    render() {

        const { candidate } = this.props;
        console.log(candidate)
        var added_date = '';
        if (candidate.added_date) {
            added_date = candidate.added_date.toLocaleDateString();
        }
        var interview_result_id = candidate.interview_result ? candidate.interview_result : 'not_interviewed_yet';
        var interview_result = helps.getItemFromArr(configs.interview_results, interview_result_id);
        return (
            <CRow>
                <CCol xl={12}>
                    <CRow>
                        <CCol md={6}>
                            <CRow className="mb-3">
                                <CCol md={6}>
                                    Họ & Tên: <b>{candidate.fullname}</b>
                                </CCol>
                                <CCol md={6}>
                                    Vị trí Ứng tuyển: <b>{candidate.position}</b>
                                </CCol>

                            </CRow>
                            <CRow className="mb-3">
                                <CCol md={6}>
                                    Số CCCD/CMT: <b>{candidate.id_number}</b>{candidate.id_type && <i> ({candidate.id_type})</i>}
                                </CCol>
                                <CCol md={6}>
                                    Số ĐT: <b>{candidate.phone}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Lương cơ bản: <b>{helps.formatCurrency(candidate.basic_salary)}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Năm sinh: <b>{candidate.birthyear}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Chiều cao: {candidate.height && <><b>{candidate.height}</b>cm</>}
                                </CCol>
                                <CCol>
                                    Cân nặng: {candidate.weight && <><b>{candidate.weight}</b>kg</>}
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Địa chỉ thường trú: <b>{candidate.permanent_address}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    Nguyên quán: <b>{candidate.home_town}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-3">
                                <CCol>
                                    <b>Thông tin thêm:</b>
                                    <div>{candidate.information}</div>
                                </CCol>
                            </CRow>
                            <hr />
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Kênh Tuyển Dụng: <b>{candidate.candidate_source}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Nguồn Ứng Viên từ: <b>{!helps.isEmpty(candidate.candidate_from) && <CLink onClick={() => { this.props.history.push('/employees/view/' + candidate.candidate_from.value) }} key={candidate.candidate_from.value} className='mr-2'>{candidate.candidate_from.label}</CLink>}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Ngày Tạo: <b>{added_date}</b>
                                </CCol>
                            </CRow>
                            <CRow className="mb-1 font-italic text-muted">
                                <CCol>
                                    Tạo bởi: <b>{candidate.added_by_alias}</b>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol md={6}>
                            <CJumbotron>
                                <h4>Ứng Viên: <b>{candidate.fullname} - {candidate.phone}</b></h4>
                                <h4>Lịch Phỏng Vấn: <b>{helps.formatDateTime_from_Timestamp(candidate.interview_time)}</b></h4>
                                <hr />
                                <h5>Người Phỏng Vấn: <ul className='ml-5'>
                                    {!helps.isEmpty(candidate.interview_by) && candidate.interview_by.length > 0 && candidate.interview_by.map((_by) => {
                                        return (<li key={_by.value} className='mt-1 ml-5'><b>{_by.label}</b></li>)
                                    })}</ul></h5>
                                <h5>Kết quả Phỏng Vấn: <CBadge color={interview_result.color} className='p-2'>{interview_result.label}</CBadge></h5>
                            </CJumbotron>

                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        )
    }
}
export default Candidate;
