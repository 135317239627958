export const subNav = {
    'accounting': {
        'books': {
            'color': 'info',
            'title': 'Quản Lý Thu/Chi',
            'icon': 'cil-list',
            'link': '/accounting/books'
        },
        'timekeeping': {
            'color': 'info',
            'title': 'Bảng Chấm Công',
            'icon': 'cil-calendar-check',
            'link': '/accounting/timekeeping'
        },
        'payroll': {
            'color': 'info',
            'title': 'Bảng Lương',
            'icon': 'cil-calculator',
            'link': '/accounting/payroll'
        },
        'taxonomies': {
            'color': 'info',
            'title': 'Quản Lý Khoản Mục',
            'icon': 'cil-list-numbered',
            'link': '/accounting/taxonomies'
        },
    },
    'users': {
        'add': {
            'color': 'primary',
            'title': 'Thêm Người Dùng',
            'icon': 'cil-plus',
            'link': '/users/add_edit'
        }
    },
    'tasks': {
        'add': {
            'color': 'primary',
            'title': 'Thêm Công Việc',
            'icon': 'cil-plus',
            'link': '/tasks/add_edit'
        },
    },
    'candidates': {
        'add': {
            'color': 'primary',
            'title': 'Thêm Ứng Viên mới',
            'icon': 'cil-plus',
            'link': '/employees/candidates/add_edit'
        },
        'employees': {
            'color': 'info',
            'title': 'Quản Lý Nhân Sự',
            'icon': 'cil-list',
            'link': '/employees'
        },
        'recruitmentkpi': {
            'color': 'info',
            'title': 'KPI Tuyển Dụng',
            'icon': 'cil-list-filter',
            'link': '/employees/recruitmentkpi'
        },
        'violations': {
            'color': 'danger',
            'title': 'Danh Sách Vi Phạm',
            'icon': 'cil-list',
            'link': '/employees/violations'
        },
        'violation-settings': {
            'color': 'warning',
            'title': 'Cài đặt Vi Phạm',
            'icon': 'cil-settings',
            'link': '/employees/violation-settings'
        },
    },
    'employees': {
        'add': {
            'color': 'primary',
            'title': 'Thêm mới Nhân viên',
            'icon': 'cil-plus',
            'link': '/employees/add_edit'
        },
        'candidates': {
            'color': 'info',
            'title': 'Quản Lý Ứng Viên',
            'icon': 'cil-people',
            'link': '/employees/candidates'
        },
        'recruitmentkpi': {
            'color': 'info',
            'title': 'KPI Tuyển Dụng',
            'icon': 'cil-list-filter',
            'link': '/employees/recruitmentkpi'
        },
        'violations': {
            'color': 'danger',
            'title': 'Danh Sách Vi Phạm',
            'icon': 'cil-list',
            'link': '/employees/violations'
        },
        'violation-settings': {
            'color': 'warning',
            'title': 'Cài đặt Chế Tài',
            'icon': 'cil-settings',
            'link': '/employees/violation-settings'
        },
    },
    'customers': {
        'add': {
            'color': 'primary',
            'title': 'Thêm mới Khách Hàng',
            'icon': 'cil-plus',
            'link': '/customers/add_edit'
        },
        'leads': {
            'color': 'success',
            'title': 'Cơ Hội',
            'icon': 'cil-list',
            'link': '/customers/leads'
        },
        'potentials': {
            'color': 'warning',
            'title': 'Tiềm Năng',
            'icon': 'cil-list-numbered',
            'link': '/customers/potentials'
        },
        'projects': {
            'color': 'info',
            'title': 'Mục Tiêu',
            'icon': 'cil-list',
            'link': '/projects'
        },
    },
    'projects': {
        'add': {
            'color': 'primary',
            'title': 'Thêm Mục Tiêu Mới',
            'icon': 'cil-plus',
            'link': '/projects/add_edit'
        },
    },
    'housings': {
        'add': {
            'color': 'primary',
            'title': 'Thêm Nhà Đội Mới',
            'icon': 'cil-plus',
            'link': '/housings/add_edit'
        },
    },
    'vendors': {
        'add': {
            'color': 'primary',
            'title': 'Thêm Nhà Cung Cấp',
            'icon': 'cil-plus',
            'link': '/vendors/add_edit'
        },
    },
    'assets': {
        'items': {
            'color': 'info',
            'title': 'Quản Lý Danh Mục Tài Sản',
            'icon': 'cil-list',
            'link': '/assets/items'
        },
        'stores': {
            'color': 'info',
            'title': 'Danh Sách Kho',
            'icon': 'cil-list',
            'link': '/assets/stores'
        },
    },
    'QTT': {
        'out': {
            'color': 'primary',
            'title': 'Xuất / Bàn Giao',
            'icon': 'cil-minus',
            'link': '/assets/QTT/out'
        },
        'in': {
            'color': 'warning',
            'title': 'Nhập / Thu Hồi',
            'icon': 'cil-plus',
            'link': '/assets/QTT/in'
        },
        'put': {
            'color': 'danger',
            'title': 'Hủy / Lưu Kho',
            'icon': 'cil-x',
            'link': '/assets/QTT/put'
        },
        'tranfer': {
            'color': 'success',
            'title': 'Luân Chuyển Kho',
            'icon': 'cil-loop',
            'link': '/assets/QTT/transfer'
        },
        'items': {
            'color': 'info',
            'title': 'Quản Lý Danh Mục',
            'icon': 'cil-list',
            'link': '/assets/items'
        },
        'stores': {
            'color': 'info',
            'title': 'Danh Sách Kho',
            'icon': 'cil-list',
            'link': '/assets/stores'
        },
    },
    'items': {
        'add': {
            'color': 'primary',
            'title': 'Thêm Danh Mục Tài Sản',
            'icon': 'cil-plus',
            'link': '/assets/items/add_edit'
        },
    },
    'stores': {
        'add': {
            'color': 'primary',
            'title': 'Thêm Kho Mới',
            'icon': 'cil-plus',
            'link': '/assets/stores/add_edit'
        },
    },
}
