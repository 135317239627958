import React, { Component } from 'react'
import {
    CDataTable,
    CRow,
    CCol,
    CCard,
    CCardHeader,
    CCardBody,
    CPopover,
} from '@coreui/react';
import { helps } from 'src/_helpers';
import Loading from 'src/containers/_loading';


class AssetsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            item_id: '',
            store_id: '',
            item: {},
            from_to: '',
            asset_date: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

    }

    handleChangeSelect(selectedOptions, field) {
        this.setState({ [[field]]: selectedOptions });
    }

    handleChange(field, e) {
        let fields = this.state;
        fields[field] = e.target.value;
        this.setState({
            [field]: fields[field]
        });
        this.setState({ fields });
    }

    render() {
        const { loading, assets, asset_items, users, stores, items, projects, vendors, employees } = this.props;
        if (loading) {
            return (
                <Loading></Loading>
            );
        }
        var _asset_items_in = [];
        var _asset_items_out = [];
        if (asset_items && asset_items.length > 0) {
            let index_in = 0;
            let index_out = 0;
            asset_items.forEach((asset_item, index) => {
                let asset = helps.getItemFromArr(assets, asset_item.asset_id);
                asset_item = Object.assign(asset_item, asset);
                asset_item['create_by_alias'] = helps.getUserAlias(users, asset.create_by);
                let item = helps.getItemFromArr(items, asset_item.item_id);
                asset_item['item'] = item;

                let store = helps.getItemFromArr(stores, asset.store_id);
                asset_item['store_name'] = store.name;

                asset_item['project_name'] = '';
                if (!helps.isEmpty(asset.project_id)) {
                    let project = helps.getItemFromArr(projects, asset.project_id);
                    asset['project_name'] = project.name;
                    asset_item['project'] = project;
                }
                asset_item['employee_name'] = '';
                if (!helps.isEmpty(asset.employee_id)) {
                    let employee = helps.getItemFromArr(employees, asset.employee_id);
                    asset_item['employee_name'] = employee.fullname;
                    asset_item['employee'] = employee;
                }
                asset_item['vendor_name'] = '';
                if (!helps.isEmpty(asset.vendor_id)) {
                    let vendor = helps.getItemFromArr(vendors, asset.vendor_id);
                    asset_item['vendor_name'] = vendor.name;
                    asset_item['vendor'] = vendor;
                }
                if (asset_item.state == 'in') {
                    index_in += 1;
                    asset_item['index'] = index_in;
                    _asset_items_in.push(asset_item);
                } else {
                    index_out += 1;
                    asset_item['index'] = index_out;
                    _asset_items_out.push(asset_item);
                }
            });
        }
        const duplicates_in = _asset_items_in.reduce((acc, item) => {
            let newItem = acc.find((i) => i.asset_id === item.asset_id);
            if (newItem) {
                newItem.count += 1;
                if (newItem.count === 1) {
                    newItem.start_count = item.index;
                }
            } else {
                acc.push({ asset_id: item.asset_id, count: 1, start_count: item.index });
            }
            return acc;
        }, []);
        _asset_items_in.map((asset_item) => {
            let _duplicate = helps.getItemFromArr(duplicates_in, asset_item.asset_id, 'asset_id');
            asset_item['count'] = _duplicate.count;
            asset_item['start_count'] = _duplicate.start_count;
        })
        const duplicates_out = _asset_items_out.reduce((acc, item) => {
            let newItem = acc.find((i) => i.asset_id === item.asset_id);
            if (newItem) {
                newItem.count += 1;
                if (newItem.count === 1) {
                    newItem.start_count = item.index;
                }
            } else {
                acc.push({ asset_id: item.asset_id, count: 1, start_count: item.index });
            }
            return acc;
        }, []);
        _asset_items_out.map((asset_item) => {
            let _duplicate = helps.getItemFromArr(duplicates_out, asset_item.asset_id, 'asset_id');
            asset_item['count'] = _duplicate.count;
            asset_item['start_count'] = _duplicate.start_count;
        });

        let compare = [];
        let items_for_compare = _asset_items_out;
        if (!helps.isEmpty(items_for_compare) && items_for_compare.length > 0) {
            items_for_compare.forEach((item) => {
                let compare_item = item;
                let _found_in = helps.getItemFromArr(_asset_items_in, item.item_id, 'item_id');
                let quantity = _found_in.item_id ? _found_in.quantity : 0;
                compare_item.remain = quantity - parseInt(item.quantity);
                compare.push(compare_item);
            })
        }


        const fields_out = [
            { key: 'asset_date', label: 'Ngày', _style: { width: '15%' } },
            { key: 'index', label: '#', _style: { width: '10px' }, filter: false, sorter: false },
            { key: 'item_code', label: 'Mã', _style: { width: '15%' } },
            { key: 'item_name', label: 'Tài Sản', _style: { width: '35%' } },
            { key: 'quantity', label: 'SL', _classes: 'font-weight-bold', _style: { textAlign: 'center' }, filter: false, sorter: false },
            { key: 'create_by_alias', label: 'Bởi', _style: { textAlign: 'right' }, filter: false },
        ];
        const fields_in = [
            { key: 'asset_date', label: 'Ngày', },
            { key: 'index', label: '#', _style: { width: '10px' }, filter: false, sorter: false },
            { key: 'item_code', label: 'Mã', _style: { width: '15%' } },
            { key: 'item_name', label: 'Tài Sản', _style: { width: '20%' } },
            { key: 'quantity', label: 'SL', _classes: 'font-weight-bold', _style: { textAlign: 'center' }, filter: false, sorter: false },
            { key: 'create_by_alias', label: 'Bởi', _style: { textAlign: 'right' }, filter: false },
        ];
        const fields_compare = [
            { key: 'index', label: '#', _style: { width: '10px' }, filter: false, sorter: false },
            { key: 'item_code', label: 'Mã', _style: { width: '15%' } },
            { key: 'item_name', label: 'Tài Sản', _style: { width: '50%' } },
            { key: 'remain', label: 'SL', _style: { textAlign: 'center', width: '20%' }, filter: false, sorter: false },
        ];

        return (
            <CRow>
                <CCol xl={4} md={4} sm={12}>
                    <CCard>
                        <CCardHeader>
                            Bàn Giao
                        </CCardHeader>
                        <CCardBody>
                            <CDataTable
                                key={'assets_' + _asset_items_out.length}
                                items={_asset_items_out}
                                fields={fields_out}
                                columnFilter={false}
                                tableFilter={false}
                                itemsPerPageSelect={false}
                                itemsPerPage={50}
                                hover
                                sorter
                                pagination
                                _style={{ minHeight: '500px' }}
                                scopedSlots={{
                                    'asset_date': (item, index) => {
                                        let rowSpan = 1;
                                        if (item.index === item.start_count) {
                                            rowSpan = item.count;
                                        } else {
                                            return (<></>);
                                        }
                                        return (
                                            <td className="py-2 align-middle" rowSpan={rowSpan}>
                                                {helps.formatDate_from_Timestamp(item.asset_date)}
                                                <div><small className='font-italic text-muted'>{item.asset_id}</small></div>
                                            </td>
                                        )
                                    },
                                    'index': (item, index) => {
                                        return (
                                            <td className="py-2 font-weight-bold">
                                                {index + 1}
                                            </td>
                                        )
                                    },
                                    'item_code': (item) => {
                                        return (
                                            <td className="py-2 font-weight-bold">
                                                {item.item.code}
                                            </td>
                                        )
                                    },
                                    'item_name': (item) => {
                                        return (
                                            <td className="py-2 font-weight-bold">
                                                {item.item.name}
                                            </td>
                                        )
                                    },
                                    'quantity': (item) => {
                                        return (
                                            <td className="py-2 text-center font-weight-bold" title={'Xuất/Bàn giao: ' + item.quantity}>
                                                {item.quantity}
                                            </td>
                                        )
                                    },
                                    'create_by_alias': (item) => {
                                        let rowSpan = 1;
                                        if (item.index === item.start_count) {
                                            rowSpan = item.count;
                                        } else {
                                            return (<></>);
                                        }
                                        return (
                                            <td className="py-2 text-right align-middle" rowSpan={rowSpan}>
                                                <small>
                                                    {item.create_by_alias}<br />
                                                    <i>{helps.formatDateTime_from_Timestamp(item.create_date)}</i>
                                                </small>
                                            </td>
                                        )
                                    },
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol xl={4} md={4} sm={12}>
                    <CCard>
                        <CCardHeader>
                            Thu Hồi
                        </CCardHeader>
                        <CCardBody>
                            <CDataTable
                                key={'assets_' + _asset_items_in.length}
                                items={_asset_items_in}
                                fields={fields_in}
                                columnFilter={false}
                                tableFilter={false}
                                itemsPerPageSelect={false}
                                itemsPerPage={50}
                                hover
                                sorter
                                pagination
                                _style={{ minHeight: '500px' }}
                                scopedSlots={{
                                    'asset_date': (item) => {
                                        let rowSpan = 1;
                                        if (item.index === item.start_count) {
                                            rowSpan = item.count;
                                        } else {
                                            return (<></>);
                                        }
                                        return (
                                            <td className="py-2 align-middle" rowSpan={rowSpan}>
                                                {helps.formatDate_from_Timestamp(item.asset_date)}
                                                <div><small className='font-italic text-muted'>{item.asset_id}</small></div>
                                            </td>
                                        )
                                    },
                                    'index': (item, index) => {
                                        return (
                                            <td className="py-2 font-weight-bold">
                                                {index + 1}
                                            </td>
                                        )
                                    },
                                    'item_code': (item) => {
                                        return (
                                            <td className="py-2 font-weight-bold">
                                                {item.item.code}
                                            </td>
                                        )
                                    },
                                    'item_name': (item) => {
                                        return (
                                            <td className="py-2 font-weight-bold">
                                                {item.item.name}
                                            </td>
                                        )
                                    },
                                    'quantity': (item) => {
                                        return (
                                            <td className="py-2 text-center font-weight-bold" title={'Nhập/Thu hồi: ' + item.quantity}>
                                                {item.quantity}
                                            </td>
                                        )
                                    },
                                    'create_by_alias': (item) => {
                                        let rowSpan = 1;
                                        if (item.index === item.start_count) {
                                            rowSpan = item.count;
                                        } else {
                                            return (<></>);
                                        }
                                        return (
                                            <td className="py-2 text-right align-middle" rowSpan={rowSpan}>
                                                <small>
                                                    {item.create_by_alias}<br />
                                                    <i>{helps.formatDateTime_from_Timestamp(item.create_date)}</i>
                                                </small>
                                            </td>
                                        )
                                    },
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol xl={4} md={4} sm={12}>
                    <CCard>
                        <CCardHeader>
                            Đối Soát Bàn giao - Thu hồi
                        </CCardHeader>
                        <CCardBody>
                            <CDataTable
                                key={'assets_compare_' + compare.length}
                                items={compare}
                                fields={fields_compare}
                                columnFilter={false}
                                tableFilter={false}
                                itemsPerPageSelect={false}
                                itemsPerPage={50}
                                hover
                                sorter
                                pagination
                                _style={{ minHeight: '500px' }}
                                scopedSlots={{
                                    'index': (item, index) => {
                                        return (
                                            <td className="py-2 font-weight-bold">
                                                {index + 1}
                                            </td>
                                        )
                                    },
                                    'item_code': (item) => {
                                        return (
                                            <td className="py-2 font-weight-bold">
                                                {item.item.code}
                                            </td>
                                        )
                                    },
                                    'item_name': (item) => {
                                        return (
                                            <td className="py-2 font-weight-bold">
                                                {item.item.name}
                                            </td>
                                        )
                                    },
                                    'remain': (item) => {
                                        let title = (item.remain === 0) ? 'Đủ số lượng' : 'Thiếu: ' + Math.abs(item.remain);
                                        let _class = (item.remain === 0) ? 'text-dark' : 'text-danger font-weight-bold';
                                        return (
                                            <td className="py-2 text-center">
                                                <CPopover content={title}>
                                                    <b className={_class}>{item.remain}</b>
                                                </CPopover>
                                            </td>
                                        )
                                    },
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        )
    }
}


export default AssetsComponent;
