import { db } from '../dbConfigs';

export const userService = {
    login,
    logout,
    getAll,
    getById,
    addUser,
    updateUser,
    delete: _delete
};

async function login(username, password) {
    console.log(username, password);
    return await db.collection('users')
        .where("username", '==', username)
        .where("password", '==', parseInt(password))
        .get()
        .then((snapshot) => {
            if (snapshot.docs.length === 0) {
                return Promise.reject(new Error("Username or password not valid.")).then();
            } else {
                const users = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                const user = users[0];
                // store user to local storage
                localStorage.setItem('user', JSON.stringify(user));

                //update last logged date time
                user.last_logged = new Date();
                var userRef = db.collection("users").doc(user.id);
                userRef.update(user);
                return user;
            }

        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

async function getAll() {
    const snapshot = await db.collection('users')
        .get();

    const users = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));

    if (users && users.length > 0) {
        Promise.all(
            users.map(async (user) => {
                if (user.last_logged) {
                    var _date = user.last_logged;
                    user['last_logged'] = _date.toDate();
                }
            })
        );
    }

    return users;
}

async function getById(id) {
    return await db.collection("users").doc(id)
        .get()
        .then((doc) => {
            if (doc.exists) {
                return doc.data();
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        })
        .catch((error) => {
            console.log("Error getting document:", error);
        });
}

async function addUser(user) {
    try {
        const docRef = await db.collection("users").add(user);
        return docRef.id;
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

async function updateUser(id, user) {
    var userRef = db.collection("users").doc(id);

    try {
        await userRef.update(user);
        console.log("Firebase: User has been updated successfully!");
    } catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {

}

