import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'src/containers/_loading';
import { taxonomyActions } from 'src/_actions';
import Taxonomies from 'src/views/accounting/Taxonomies';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { ThePermission } from '../ThePermission';


class TaxonomiesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            category: '',
            taxonomies: '',
        }
    }

    componentDidMount() {
        this.setState({ category: this.props.category });
        this.getTaxonomies().then((taxonomies) => {
            this.setState({ taxonomies: taxonomies });
        });

    }

    async getTaxonomies() {
        return await this.props.getAll(this.props.category);
    }

    render() {
        if (this.props.loading) {
            return (
                <Loading></Loading>
            );
        }

        return (
            <ThePermission {... this.props} component="accounting" action="view">
                <CCard>
                    <CCardHeader>
                        Quản Lý Khoản Mục
                    </CCardHeader>
                    <CCardBody>
                        <Taxonomies {... this.props} />
                    </CCardBody>
                </CCard>
            </ThePermission>
        );
    }
}

function mapStateToProps(state) {
    const { loading, taxonomies } = state.taxonomyReducer;
    const { user } = state.authentication;
    const { users } = state.userReducer;
    return { loading, user, users, taxonomies };
}

const actionCreators = {
    getAll: taxonomyActions.getAll,
    add: taxonomyActions.add,
    update: taxonomyActions.update,
    _delete: taxonomyActions._delete,
    updateField: taxonomyActions.updateField,
};

const connectedContainer = connect(mapStateToProps, actionCreators)(TaxonomiesContainer);
export { connectedContainer as TaxonomiesContainer };